<template>
    <v-container id="overlay" class="grey lighten-3 pa-0 ma-0" fluid>
        <v-row class="d-flex appPrimary py-2" no-gutters>
            <v-col cols="12">
                <v-btn v-if="$route.params.id" @click="$router.push(`/home`)" class="ml-4 white--text" text depressed small><v-icon>oomph-back</v-icon>Back</v-btn>
                <v-btn
                    v-else-if="readingMode === true"
                    @click="
                        readingMode = false;
                        instructionPage = true;
                    "
                    class="ml-4 white--text"
                    text
                    depressed
                    small
                    ><v-icon>oomph-back</v-icon>Back</v-btn
                >
                <v-btn v-else @click="$emit('close')" class="ml-4 white--text" text depressed small><v-icon>oomph-back</v-icon>Back</v-btn>
            </v-col>
        </v-row>
        <!-- <v-row v-if="!readingMode" class="d-flex primary lighten-2 py-2" no-gutters>
            <v-col cols="12">
                <v-btn v-if="$route.query.home && !$route.query.search" @click="$router.push(`/home`)" class="ml-4 black--text" text depressed small><v-icon>icons8-back-arrow</v-icon>Back</v-btn>
                <v-btn v-else-if="$route.query.search" @click="$router.push(`/search`)" class="ml-4 black--text" text depressed small><v-icon>icons8-back-arrow</v-icon>Back</v-btn>
                <v-btn
                    v-else-if="readingMode === true"
                    @click="
                        readingMode = false;
                        ingredientPage = true;
                    "
                    class="ml-4 black--text"
                    text
                    depressed
                    small
                    ><v-icon>icons8-back-arrow</v-icon>Back</v-btn
                >
                <v-btn v-else @click="$emit('close')" class="ml-4 black--text" text depressed small><v-icon>icons8-back-arrow</v-icon>Back</v-btn>
            </v-col>
        </v-row> -->
        <v-row no-gutters>
            <v-col cols="2"></v-col>
            <v-col cols="8" class="header-border">
                <v-card class="white rounded-t-0 elevation-0" :img="contentItem.coverImageURL" height="30vh">
                    <v-card
                        style="position: absolute !important; right: 0 !important"
                        class="d-flex flex-column align-center rounded-0 primary white--text pa-1 elevation-0"
                        width="58"
                        height="80"
                    >
                        <span class="oomph-font-body-1 text-center text-uppercase mt-2"
                            ><b>{{ $moment.unix(contentItem.contentAvailableFrom).format('MMM') }}</b></span
                        >
                        <span class="oomph-font-h5 mt-3 primary--text white pa-5"
                            ><b>{{ $moment.unix(contentItem.contentAvailableFrom).format('DD') }}</b
                            ><br
                        /></span>
                    </v-card>
                </v-card>
            </v-col>
            <v-col cols="2"></v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="2"></v-col>
            <v-col cols="8">
                <v-card class="white elevation-0 rounded-0 px-4" height="700" style="overflow-y: scroll !important">
                    <v-card-text class="white black--text rounded-xl">
                        <v-row class="d-flex align-center pa-4" no-gutters>
                            <v-col cols="6">
                                <div class="caption  font-weight-bold" v-html="sourceText"></div>
                                <div class="caption  " v-html="publishDateText"></div>
                                <v-card-title class="text-h4 mb-4 px-0" style="word-break: break-word !important">{{ contentItem.contentTitle }}</v-card-title>
                            </v-col>
                            <v-col cols="6" v-if="contentItem.contentShowPartnerLogo === '1'" class="d-flex align-center justify-end rounded-0">
                                <v-col cols="3" class="pa-0">
                                    <div class="text-left oomph-font-body-2 black--text"><b>Content Partner:</b><br /> {{ contentItem.orgName }}</div>
                                </v-col>
                                <v-col cols="3" class="d-flex justify-end pa-0">
                                    <v-img v-if="contentItem.orgLogo" :src="contentItem.orgLogo" max-width="100" contain></v-img>
                                    <v-img v-else max-width="100" src="require('@/assets/logo.png')" contain></v-img>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-divider class="secondary"></v-divider>
                    </v-card-text>
                    <v-card-text class="my-4 text-justify" v-html="newsText"></v-card-text>
                    <v-card-text v-if="urlText !== '' && contentType === 'News with Link'" class="white black--text rounded-xl pb-0">
                        For more information please visit:<br>
                        <v-btn class="primary rounded-lg white--text my-4" :href="urlText" target="_blank" text small>Resource</v-btn>
                    </v-card-text>
                    <v-card-text v-if="video.playbackId && contentType === 'News with Video' || contentType === 'News with Media'" class="d-flex justify-center">
                        <mux-video
                            :playback-id="video.playbackId"
                            :metadata-video-title="video.title"
                            :poster="video.poster"
                            :env-key="video.envKey"
                            stream-type="on-demand"
                            height="700"
                            preload
                            prefer-mse
                            controls
                        ></mux-video>
                    </v-card-text>
                    <v-card-text v-if="podcast.playbackId && contentType === 'News with Podcast'" class="d-flex flex-column justify-center pt-0">
                        <div class="oomph-font-h5 primary--text pt-6 pb-2">Podcast</div>
                        <v-col cols="12" class="d-flex justify-center">
                            <mux-video :playback-id="podcast.playbackId" :env-key="podcast.envKey" :metadata-video-title="podcast.title" stream-type="on-demand" controls></mux-video>
                        </v-col>
                    </v-card-text>
                    <v-card-actions v-if="contentType === 'News with PDF'" class="d-flex justify-end">
                        <v-btn
                            color="primary"
                            class="white--text"
                            @click="
                                pdfPage = true;
                                videoPage = false;
                            "
                            text
                            >View PDF<v-icon size="40">icons8-next-page</v-icon></v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="8" v-if="pdfPage || contentType === 'News with PDF'">
                <v-card class="tertiary elevation-0 rounded-xl px-4" height="78vh" style="overflow-y: scroll !important">
                    <v-card-text class="d-flex flex-column justify-center">
                        <v-col cols="12" class="d-flex justify-space-between align-center py-0">
                            <v-btn
                                color="primary"
                                class="white--text"
                                @click="
                                    pdfPage = false;
                                    videoPage = true;
                                "
                                text
                                ><v-icon size="40">icons8-back-to</v-icon>Back to Article</v-btn
                            >
                        </v-col>
                        <v-card-text v-if="pdfURL" class="d-flex justify-center grey lighten-3">  
                            <FlipBook :pdfurl="pdfURL" :key="flipbookkey" />   
                        </v-card-text>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="2"></v-col>
        </v-row>
        <!-- <Review :showReviewOverlay="showReviewOverlay" :contentItem="contentItem" @closeOverlay="showReviewOverlay = false" /> -->
    </v-container>
</template>

<script src="/js/jquery.min.js"></script>
<script src="/js/html2canvas.min.js"></script>
<script src="/js/three.min.js"></script>
<script src="/js/pdf.min.js"></script>
<script src="/js/3dflipbook.min.js"></script>

<script>
import { mapGetters } from 'vuex';

// import Review from "../../components/content/ContentReview.vue";
import schema from '@/schema.js';
// import FlipBook from '@/views/FlipBook.vue'

export default {
    name: 'NewsTemplate',
    props: {
        showContentItem: {
            type: Boolean,
            default: false,
        },
        contentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        contentItems: {
            type: Array,
            default: () => {
                return [];
            },
        },
        contentType: {
            type: String,
            default: '',
        },
    },
    components: {
        // Review,
        // FlipBook
    },
    data: () => ({
        adaption: '',
        addedToMyList: false,
        appUrl: process.env.VUE_APP_URL,
        coverImage: '',
        like: {},
        loading: false,
        flipbookkey: 0,
        myList: [],
        myLists: [],
        newsText: '',
        normalMode: true,
        page: 1,
        pageCount: 1,
        pdfPage: false,
        pdfURL: '',
        publishDateText: '',
        podcast: {
            title: '',
            playbackId: '',
            envKey: process.env.VUE_APP_MUX,
        },
        readingMode: false,
        showReviewOverlay: false,
        sourceText: '',
        urlText: '',
        tags: [],
        videoPage: true,
        video: {
            title: '',
            poster: '',
            playbackId: '',
            envKey: process.env.VUE_APP_MUX,
        },
    }),
    watch: {
        pdfPage() {
            let t = this;
            $(document).ready(function () {
                var template = {
                    script: 'flipbook/default-book-view.js',
                };
                $('#flipbook').FlipBook({
                    controlsProps: { actions: { cmdPrint: { enabled: false, enabledInNarrow: false }, cmdSmartPan: { enabled: false }, cmdSinglePage: { enabled: false }, cmdSounds: { enabled: false }, cmdStats: { enabled: false }, cmdLightingUp: { enabled: true }, cmdLightingDown: { enabled: true }, },},
                    pdf: t.pdfURL,
                    template: template,
                });
            })
        }
    },
    computed: {
        ...mapGetters({
            GET_firebase_userAuth: 'GET_firebase_userAuth',
            GET_firebase_user: 'GET_firebase_user',
            GET_brand: 'GET_brand',
        }),
    },
    methods: {
        handleDocumentRender() {
            this.isLoading = false;
            this.pageCount = this.$refs.pdfRef.pageCount;
        },
        filterContentItems() {
            let t = this;
            //* Filter all content items
            t.contentItems.filter((item) => {
                //* Get cover image
                if (item.value.itemMarker === 'Cover Image') {
                    t.coverImage = item.value.itemURL;
                }
                //* Get text items
                if (item.value.itemMarker === 'Publish Date Text') {
                    t.publishDateText = item.value.itemTextHTML;
                }
                if (item.value.itemMarker === 'Author Text') {
                    t.sourceText = item.value.itemTextHTML;
                }
                if (item.value.itemMarker === 'News Text') {
                    t.newsText = item.value.itemTextHTML;
                }
                if (item.value.itemMarker === 'URL') {
                    t.urlText = item.value.itemTextHTML.replace('<p>', '').replace('</p>', '');
                }
                //* Get PDF item
                if (item.value.itemType === 'PDF') {
                    t.pdfURL = item.value.itemURL;
                }
                //* Get video item
                if (item.value.itemMarker === 'Video') {
                    t.video.playbackId = item.value.itemPlaybackId;
                    t.video.title = item.value.itemName;
                    t.video.poster = `https://image.mux.com/${item.value.itemPlaybackId}/thumbnail.jpg`;
                }
                //* Get audio only item (podcast)
                if (item.value.itemMarker === 'Audio') {
                    t.podcast.playbackId = item.value.itemPlaybackId;
                    t.podcast.title = item.value.itemName;
                }
            });
        },
        async getTags() {
            let t = this;
            let excludedTags = await t.MIX_redisReadJSON('settings:excludedTags', '.');
            const tagsArray = t.contentItem.contentTags.split(',');
            const exlusions = tagsArray.filter((tag) => {
                return excludedTags.data.indexOf(tag) === -1;
            });
            return (t.tags = exlusions);
        },
        async addToMyList() {
            let t = this;
            t.myList.id = await t.MIX_generateId();
            t.myList.status = '1';
            t.myList.contentId = t.contentItem.id;
            t.myList.orgId = t.GET_firebase_user.orgId;
            t.myList.orgName = t.GET_firebase_user.orgName;
            t.myList.createdDateTime = t.$moment().format('x');
            t.myList.createdUserId = t.GET_firebase_user.id;
            t.myList.createdUserName = t.GET_firebase_user.userName;
            t.myList.createdUserEmail = t.GET_firebase_user.userEmail;
            let addToMyList = await t.MIX_redisCreateHash(`myList:${t.myList.id}`, t.myList);
            if (addToMyList.code === 1) {
                t.addedToMyList = true;
                t.getMyList();
                t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Added item to your list', show: true });
            } else {
                t.addedToMyList = false;
                t.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Error saving to your list, please try again.', show: true });
            }
        },
        async removeFromMyList() {
            let t = this;
            let result = await this.MIX_destroyItem(t.myList.key, t.myLists[0].value.id);
            if (result.code === 1) {
                t.addedToMyList = false;
                t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Removed item from your list', show: true });
            } else {
                t.addedToMyList = true;
                t.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Error removing item from your list, please try again.', show: true });
            }
        },
        async getMyList() {
            let t = this;
            let result = await t.MIX_redisSearch('idx:myLists', `@contentId:${t.contentItem.id}`, 0, 9999);
            t.myLists = result.data.documents;
            t.myLists.forEach((list) => {
                list.value.status !== 1 ? (t.addedToMyList = true) : (t.addedToMyList = false);
            });
        },
        async addView() {
            let t = this;
            const view = {};
            view.userId = t.GET_firebase_user.id;
            view.contentId = t.contentItem.id;
            const addView = await t.MIX_addView (view);
            if (addView.code === 1) {
                return
            }
        },
        async createPdf(url) {
            let t = this;
            t.loading = true;
            t.addStat('download');
            return await t.MIX_generatePDF(url).then((response) => {
                response
                    .arrayBuffer()
                    .then((res) => {
                        const blob = new Blob([res], { type: 'application/pdf' });
                        t.$saveAs(blob, t.contentItem.id + '.pdf');
                        t.loading = false;
                    })
                    .catch((e) => alert(e));
            });
        },
        addStat(type) {
            let t = this;
            const stat = schema.stat;
            stat.contentId = t.contentItem.id;
            stat.contentTitle = t.contentItem.contentTitle;
            stat.contentArea = t.contentItem.contentArea;
            stat.contentAlias = t.contentItem.contentAlias;
            stat.contentPartnerOrgId = t.contentItem.orgId;
            stat.contentTags = JSON.stringify(t.contentItem.contentTags);
            stat.statType = type;
            t.MIX_addStat("stat", stat); 
        },

        async getContentItems(item) {
            let t = this;
            t.contentPreviewOverlay = true;
            if (JSON.stringify(item) !== '{}') {
                let result = await t.MIX_redisSearch('idx:contentItems', `@itemContentId:{${item.id}}`, 0, 9999);
                if (result.code === 1) {
                    t.contentItem = item;
                    if (t.contentItem.contentTags !== '') {
                        t.contentItem.contentTags.split(',').forEach((item) => {
                            t.tags.push(item);
                        });
                    }
                    t.contentItems = result.data.documents;
                    t.contentItems.filter((item) => {
                        if (item.value.itemMarker === 'Cover Image') {
                            t.coverImage = item.value.itemURL;
                        }
                    });
                    t.filterContentItems()
                }
            }
        },
    },
    created() {
        let t = this;
        setTimeout(() => {
            t.myList = schema.myList;
            t.getContentItems(t.contentItem);
            t.getTags();
            // t.getMyList();
            // t.getLikes();
            // t.getDislikes();
            // t.addView();
            // t.addStat('view'); 
        }, 1000);
    },
};
</script>

<style>
#overlay {
    position: absolute;
    top: 0;
    z-index: 10;
}

.flipbook-container {
    height: 75vh;
    width: 100vw;
}

strong {
    font-weight: 900 !important;
}

.header-border {
    border-bottom: 5px solid var(--v-accent-base) !important;
}
</style>