<template>
    <div class=""
        style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow-y: scroll !important; overflow-x: hidden !important">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="accent"></v-progress-circular>
                    <div
                        class="ml-3 text-h6 accent--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">
                        Loading</div>
                </div>
                <div :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn v-if="advancedEditMode" class="grey lighten-1 ml-3 white--text font-weight-bold" depressed
                        @click="cancelItem"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                    <!-- <v-btn outlined class="primary white--text ml-3" v-if="!advancedEditMode && !newItem && contentItem.contentTemplate !== '' && contentItem.contentTemplate !== null" @click="preview = true" ><v-icon class="mr-2"></v-icon>Preview</v-btn> -->
                    <v-btn v-if="contentItem.deleted === '0'" class="grey lighten-1 ml-3 white--text font-weight-bold"
                        depressed @click="deleteItem()"> <v-icon class="mr-2">oomph-trash-can</v-icon><span>Delete</span>
                    </v-btn>
                </div>
                <v-spacer />
                <div :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn outlined
                        v-if="['Pending', 'Live', 'Approved', 'Rejected', 'Archived'].includes(contentItem.contentStatus)"
                        @click="updateStatus('Draft')" class="grey darken-3 white--text mr-3">Draft</v-btn>
                    <v-btn outlined v-if="['Draft'].includes(contentItem.contentStatus)" @click="updateStatus('Pending')"
                        class="warning white--text mr-3">Submit For Approval</v-btn>
                    <v-btn outlined v-if="['Approved'].includes(contentItem.contentStatus)" @click="updateStatus('Live')"
                        class="success white--text mr-3">Publish</v-btn>
                    <v-btn outlined v-if="['Pending'].includes(contentItem.contentStatus)" @click="updateStatus('Approved')"
                        class="info white--text mr-3">Approve</v-btn>
                    <v-btn outlined v-if="['Pending'].includes(contentItem.contentStatus)" @click="updateStatus('Rejected')"
                        class="error white--text mr-3">Reject</v-btn>
                    <v-btn outlined v-if="['Live'].includes(contentItem.contentStatus)" @click="updateStatus('Archived')"
                        class="black white--text mr-3">Archive</v-btn>

                    <v-btn outlined
                        :disabled="['Approved', 'Live'].includes(contentItem.contentStatus) && !['StaffManager', 'StaffAdmin', 'DeveloperAdmin'].includes(userLevel)"
                        class="primary white--text mr-3" v-if="!advancedEditMode"
                        @click="advancedEditMode = !advancedEditMode"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn outlined
                        :disabled="['Approved', 'Live'].includes(contentItem.contentStatus) && !['StaffManager', 'StaffAdmin', 'DeveloperAdmin'].includes(userLevel)"
                        class="primary white--text mr-3" v-if="advancedEditMode" @click="saveContent()"><v-icon
                            class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <!-- <pre>{{ contentItem }}</pre> -->
        <v-row class="apptext--text pt-3 px-3" dense v-if="!advancedEditMode">
            <v-col v-if="!advancedEditMode" cols="12" class="d-flex align-center justify-start rounded">
                <v-row style="width: 100% !important" dense>
                    <v-spacer />
                    <div class="caption text-right pb-2 pr-2"><strong>ID:</strong> {{ contentItem.id }}</div>
                    <!-- Full Name / Job Title / Department / Marketing -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left  primary--text text--darken-2 text-h6 font-weight-light">Details</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important;">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold body-2 white">Title:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        contentItem.contentTitle }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold body-2 white">Status:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        contentItem.contentStatus }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Subtitle:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        contentItem.contentSubtitle }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Short Description:
                                                </td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        contentItem.contentDescription }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Area:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        contentItem.contentArea }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Alias:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        contentItem.contentAlias }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Template:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        contentItem.contentTemplate }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Duration Mins:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        contentItem.contentDurationMins }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Difficulty:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        contentItem.contentDifficulty }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Available From:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        MIX_formatDateTime(contentItem.contentAvailableFrom, 'X', 'DD-MMM-YYYY')
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Available To:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        MIX_formatDateTime(contentItem.contentAvailableTo, 'X', 'DD-MMM-YYYY')
                                                    }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Content Partner -->
                    <v-col cols="12" sm="12" md="6">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left  primary--text text--darken-2 text-h6 font-weight-light">Content
                                        Partner</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important;">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Organisation:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        contentItem.orgName }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Content Access -->
                    <v-col cols="12" sm="12" md="6">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left  primary--text text--darken-2 text-h6 font-weight-light">Content
                                        Access</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important;">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold body-2 white">Allow Download:
                                                </td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    <span v-if="contentItem.contentAllowDownload == '1'">Yes</span>
                                                    <span v-else>No</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold body-2 white">Allow
                                                    Accessibility:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    <span v-if="contentItem.contentAllowPrint == '1'">Yes</span>
                                                    <span v-else>No</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold body-2 white">Allow Offline:
                                                </td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    <span v-if="contentItem.contentAllowOffline == '1'">Yes</span>
                                                    <span v-else>No</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold body-2 white">Allow PDF
                                                    Convert:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    <span v-if="contentItem.contentAllowPDFConvert == '1'">Yes</span>
                                                    <span v-else>No</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Tags -->
                    <v-col cols="12" sm="12" md="6">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Tags</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-price-tag</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" style="height: 100% !important" class="pt-2"
                                v-if="contentItem.contentTags !== '' && contentItem.contentTags !== undefined">
                                <v-chip label class="mr-1 mt-1" small v-for="tag in contentItem.contentTags.split(',')"
                                    :key="tag">{{ tag }}</v-chip>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Metadata -->
                    <v-col cols="12" sm="12" md="6">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Metadata</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-info-squared</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Owner:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        contentItem.ownerUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Created by:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        contentItem.createdUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Created:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ MIX_formatDateTime(contentItem.createdDateTime, 'x', 'DD-MMM-YYYY
                                                                                                        HH: mm') }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Modified by:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        contentItem.modifiedUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Modified:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ MIX_formatDateTime(contentItem.modifiedDateTime, 'x', 'DD-MMM-YYYY
                                                                                                        HH: mm') }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-else class="px-3">
            <v-col cols="12" class="d-flex align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="12" class="d-flex py-0 body-2 grey--text text--darken-2 text-left">
                                    <div>* Required Fields</div>
                                    <v-spacer />
                                    <div class="caption text-right"><strong>ID:</strong> {{ contentItem.id }}</div>
                                </v-col>
                                <v-col cols="12" sm="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                    <v-spacer />
                                    <v-checkbox v-if="!selectedArea.includes('News')" class="" hide-details="auto"
                                        v-model="contentItem.contentPremium" :true-value="1" :false-value="0"
                                        label="Premium Content"></v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="6" class="my-1">
                                    <v-text-field label="Title" v-model="contentItem.contentTitle" :rules="rules" outlined
                                        dense hide-details="auto" background-color="white" color="primary" clearable
                                        @click:clear="clearContactName('contentTitle', '')"></v-text-field>
                                </v-col>
                                <v-col cols="6" class="my-1">
                                    <v-select label="Content Area" background-color="white" :rules="rules" class="pr-1"
                                        v-model="selectedArea" multiple :items="contentAreaItems" outlined dense
                                        color="primary" hide-details="auto" clearable
                                        :menu-props="{ top: false, offsetY: true }" :attach="true"
                                        @click:clear="clearContactName('contentArea', '')"></v-select>
                                </v-col>
                                <v-col cols="12" class="my-1">
                                    <v-text-field label="Subtitle" background-color="white" :rules="rules"
                                        v-model="contentItem.contentSubtitle" outlined dense color="primary"
                                        hide-details="auto" clearable
                                        @click:clear="clearContactName('contentSubtitle', '')"></v-text-field>
                                </v-col>
                                <v-col v-if="!selectedArea.includes('News')" cols="12" class="my-1">
                                    <v-textarea label="Short Description" :rules="descriptionRules" counter=280 rows="2"
                                        background-color="white" v-model="contentItem.contentDescription" outlined dense
                                        color="primary" hide-details="auto" clearable
                                        @click:clear="clearItem('contentDescription', '')"></v-textarea>
                                </v-col>
                                <v-col cols="6" class="my-1">
                                    <v-select label="Content Alias" background-color="white" :rules="rules" class="pr-1"
                                        v-model="selectedAlias" :items="contentAlias" item-text="alias" outlined dense
                                        color="primary" hide-details="auto" clearable
                                        :menu-props="{ top: false, offsetY: true }" :attach="true"
                                        @click:clear="clearContactName('contentArea', '')"></v-select>
                                </v-col>
                                <v-col v-if="selectedArea.includes('News')" cols="6" class="my-1 align-center">
                                    <v-row class="d-flex justify-start pl-2">
                                        <!--                                      <pre>Pinned: {{ contentItem.contentPinned }}</pre>-->
                                        <v-checkbox class="" hide-details="auto" v-model="pinCheckBox"
                                            label="Pin Content"></v-checkbox>
                                    </v-row>
                                </v-col>
                                <v-col cols="6" class="my-1 d-flex align-center justify-end">
                                    <div class="mr-3 grey--text text--darken-3">Content Type:</div>
                                    <v-spacer />
                                    <v-radio-group v-model="contentItem.contentType" row hide-details="auto"
                                        class="pa-0 ma-0">
                                        <v-radio value="Simple" label="Simple">Simple</v-radio>
                                        <v-radio value="Advanced" label="Advanced">Advanced</v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col v-if="!selectedArea.includes('News')" cols="6" class="my-1">
                                    <v-select label="Duration (Mins)" background-color="white" class="pr-1"
                                        v-model="contentItem.contentDurationMins" :items="contentDurationMins" outlined
                                        dense color="primary" hide-details="auto" clearable
                                        :menu-props="{ top: false, offsetY: true }" :attach="true"
                                        @click:clear="clearContactName('contentDurationMins', '')"></v-select>
                                </v-col>
                                <!-- <v-col cols="6" class="my-1">
                                    <v-select label="Rating" background-color="white" class="pr-1" v-model="contentItem.contentRating" :items="contentRatings" outlined dense color="primary" hide-details="auto" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearContactName('contentRatings', '')"></v-select>
                                </v-col> -->
                                <v-col v-if="!selectedArea.includes('News')" cols="6" class="my-1">
                                    <!-- <v-select label="Difficulty Rating" background-color="white" class="rounded-0" v-model="contentItem.contentDifficultyRating" :items="difficulty" outlined dense color="primary" hide-details="auto" clearable @click:clear="clearContactName('contentTitle', '')" ></v-select> -->

                                    <v-btn-toggle v-model="selectedDifficulty" style="width: 100% !important" dense
                                        class="flex-wrap">
                                        <v-btn style="width: 33.3% !important" value="easy">
                                            <div class="mx-3">Easy</div>
                                        </v-btn>
                                        <v-btn style="width: 33.3% !important" value="medium">
                                            <div class="mx-3">Medium</div>
                                        </v-btn>
                                        <v-btn style="width: 33.3% !important" value="difficult">
                                            <div class="mx-3">Difficult</div>
                                        </v-btn>
                                    </v-btn-toggle>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="pr-2">
                                    <v-menu v-model="dateMenu1" :close-on-content-click="false"
                                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Available From" v-model="computedDate1" outlined dense
                                                hide-details="auto" background-color="white" color="primary" v-bind="attrs"
                                                v-on="on" clearable
                                                @click:clear="clearItem('contentAvailableFrom')"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date1" full-width no-title color="secondary"
                                            :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-menu v-model="dateMenu2" :close-on-content-click="false"
                                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Available To" v-model="computedDate2" outlined dense
                                                hide-details="auto" background-color="white" color="primary" v-bind="attrs"
                                                v-on="on" clearable
                                                @click:clear="clearItem('contentAvailableTo')"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date2" full-width no-title color="secondary"
                                            :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <!-- Content Tags -->
                                <v-col cols="12" class="my-1">
                                    <v-row dense>
                                        <v-col cols="12" sm="6" class="d-flex align-center">
                                            <div class="text-left  primary--text text--darken-2 text-h6 font-weight-light">
                                                Tags</div>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <v-divider class="grey lighten-1 mb-1"></v-divider>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="12">
                                            <v-autocomplete multiple small-chips hide-selected label="Tags"
                                                v-model="selectedTags" :search-input.sync="tagAutocomplete"
                                                @change="tagAutocomplete = ''" :items="contentTags" outlined dense
                                                hide-details="auto" background-color="white" color="primary"
                                                :menu-props="{ top: false, offsetY: true }" :attach="true" clearable
                                                @click:clear="clearItem('quoteChannel')">
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                                    <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected"
                                                        label small>
                                                        <span class="pr-2">
                                                            {{ formatTag(item) }}
                                                        </span>
                                                        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                                                    </v-chip>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col class="pb-0 mb-0" cols="12">
                                            <div class="grey--text text--darken-1 pb-0 mb-0">Please select if suitable:
                                            </div>
                                        </v-col>
                                        <v-col cols="12" class="d-flex justify-space-between">
                                            <v-btn-toggle v-model="selectedTags" style="width: 100% !important" multiple
                                                dense class="flex-wrap">
                                                <v-btn v-if="!selectedArea.includes('News')"
                                                    :style="selectedArea.includes('News') ? 'width: 25% !important' : 'width: 25% !important'"
                                                    value="dementia friendly">
                                                    <div class="mx-3">Dementia Friendly</div>
                                                </v-btn>
                                                <v-btn v-if="!selectedArea.includes('News')"
                                                    :style="selectedArea.includes('News') ? 'width: 25% !important' : 'width: 25% !important'"
                                                    value="reduced mobility">
                                                    <div class="mx-3">Reduced Mobility</div>
                                                </v-btn>
                                                <v-btn v-if="!selectedArea.includes('News')"
                                                    :style="selectedArea.includes('News') ? 'width: 25% !important' : 'width: 25% !important'"
                                                    value="visual impairment">
                                                    <div class="mx-3">Visual Impairment</div>
                                                </v-btn>
                                                <v-btn v-if="!selectedArea.includes('News')"
                                                    :style="selectedArea.includes('News') ? 'width: 25% !important' : 'width: 25% !important'"
                                                    value="hearing impairment">
                                                    <div class="mx-3">Hearing Impairment</div>
                                                </v-btn>
                                                <v-btn v-if="selectedArea.includes('News')" style="width: 100% !important"
                                                    value="featured">
                                                    <div class="mx-3">Featured</div>
                                                </v-btn>
                                            </v-btn-toggle>
                                        </v-col>
                                        <v-col cols="12" class="d-flex justify-space-between">
                                            <v-btn-toggle v-model="selectedTags" style="width: 100% !important" multiple
                                                dense class="flex-wrap">
                                                <v-btn style="width: 25% !important" value="independent">
                                                    <div class="mx-3">Independent</div>
                                                    <!-- <v-icon class="grey--text text--darken-3">oomph-edit</v-icon> -->
                                                </v-btn>
                                                <v-btn title="1 Resident" style="width: 25% !important" value="one to one">
                                                    <div class="mx-3">One to One</div>
                                                    <!-- <v-icon class="grey--text text--darken-3">oomph-edit</v-icon> -->
                                                </v-btn>
                                                <v-btn title="1-6 Resident" style="width: 25% !important"
                                                    value="small group">
                                                    <div class="mx-3">Small Group</div>
                                                    <!-- <v-icon class="grey--text text--darken-3">oomph-delete-trash</v-icon> -->
                                                </v-btn>
                                                <v-btn title="6+ Resident" style="width: 25% !important" value="group">
                                                    <div class="mx-3">Group</div>
                                                    <!-- <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon> -->
                                                </v-btn>
                                            </v-btn-toggle>

                                        </v-col>
                                    </v-row>
                                </v-col>
                                <!-- <pre>{{ contentItem§.contentTags }}</pre> -->
                                <v-col cols="12" sm="12" class="mb-3">
                                    <v-row dense>
                                        <v-col cols="12" sm="6" class="d-flex align-center">
                                            <div class="text-left  primary--text text--darken-2 text-h6 font-weight-light">
                                                Content Partner</div>
                                            <!-- <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleJobRole = !toggleJobRole">
                                                <span v-if="!toggleJobRole">Show</span>
                                                <span v-if="toggleJobRole">Hide</span>
                                            </v-btn> -->
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <v-divider class="grey lighten-1 mb-1"></v-divider>
                                        </v-col>
                                        <!-- <v-col cols="12" sm="12">
                                            <pre>{{ selectedOrganisation }}</pre>
                                            <pre>orgId: {{ contentItem.orgId }}</pre>
                                            <pre>orgName: {{ contentItem.orgName }}</pre>
                                            <pre>orgLogo: {{ contentItem.orgLogo }}</pre>
                                        </v-col> -->
                                        <v-col cols="12" xs="12" md="6" class="my-1">
                                            <v-autocomplete label="Partner" v-model="selectedOrganisation"
                                                :items="contentPartners" item-text="orgName" return-object outlined dense
                                                hide-details="auto" background-color="white" color="primary" clearable
                                                @click:clear="clearSelectedOrganisation()"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="6" class="my-1 pl-2">
                                            <v-row dense class="d-flex align-end pt-2">
                                                <!-- <v-col cols="6" xs="4" sm="5"> -->
                                                <div class="text-left grey--text body-2 font-weight-regular pr-3">Show
                                                    Partner Logo: </div>
                                                <v-radio-group v-model="contentItem.contentShowPartnerLogo"
                                                    class="ma-0 pa-0" hide-details="auto" row dense>
                                                    <v-radio class="grey--text" label="Yes" value="1"></v-radio>
                                                    <v-radio class="grey--text" label="No" value="0"></v-radio>
                                                </v-radio-group>
                                                <!-- </v-col> -->
                                            </v-row>
                                            <!-- <v-autocomplete label="Department" v-model="item.contactJobDepartment" :items="jobDepartments" auto-select-first outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearselectedJobDepartment()" autocomplete="donotautocomplete"></v-autocomplete> -->
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-h6 font-weight-thin primary--text">Content Access</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-row class="d-flex justify-start ">
                                        <v-checkbox class="mx-3" v-model="contentItem.contentAllowDownload"
                                            :true-value="'1'" :false-value="'0'" label="Allow Download"></v-checkbox>
                                        <v-checkbox class="mx-3" v-model="contentItem.contentAllowPrint" :true-value="'1'"
                                            :false-value="'0'" label="Allow Accessibility"></v-checkbox>
                                        <v-checkbox class="mx-3" v-model="contentItem.contentAllowOffline" :true-value="'1'"
                                            :false-value="'0'" label="Allow Offline"></v-checkbox>
                                        <v-checkbox class="mx-3" v-model="contentItem.contentAllowPDFConvert"
                                            :true-value="'1'" :false-value="'0'" label="Allow PDF Convert"></v-checkbox>
                                    </v-row>
                                </v-col>
                                <!-- ASSIGNED TO  -->
                                <v-col cols="12" sm="12" class="mb-3">
                                    <v-row dense>
                                        <v-col cols="12" sm="6" class="d-flex align-center">
                                            <div class="text-left primary--text text-h6 font-weight-light">Owner</div>
                                            <v-btn
                                                class="ml-2 grey lighten-5 primary--text  text--darken-2 font-weight-bold"
                                                depressed x-small @click="ownerToggle = !ownerToggle">
                                                <span v-if="!ownerToggle">Show</span>
                                                <span v-if="ownerToggle">Hide</span>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <v-divider class="grey lighten-1 mb-1"></v-divider>
                                        </v-col>
                                        <v-col v-if="ownerToggle" cols="12" xs="12" :md="inputCols">
                                            <v-autocomplete label="Owner *" v-model="selectedOwner" :rules="rules"
                                                :items="users" item-text="ownerUserName" auto-select-first return-object
                                                outlined dense hide-details="auto" background-color="white" color="primary"
                                                clearable @click:clear="clearSelectedOwner()">
                                                <template v-slot:selection="{ item }">
                                                    {{ item.ownerUserName }}
                                                    <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    {{ item.ownerUserName }}
                                                    <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" class="mb-3">
                                    <v-row dense>
                                        <v-col cols="12" sm="6" class="d-flex align-center">
                                            <div class="text-left primary--text text-h6 font-weight-light">Content Only
                                                Available to</div>
                                            <v-btn
                                                class="ml-2 grey lighten-5 primary--text  text--darken-2 font-weight-bold"
                                                depressed x-small @click="onlyAvailableToToggle = !onlyAvailableToToggle">
                                                <span v-if="!onlyAvailableToToggle">Show</span>
                                                <span v-if="onlyAvailableToToggle">Hide</span>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <v-divider class="grey lighten-1"></v-divider>
                                        </v-col>
                                        <v-col v-if="onlyAvailableToToggle" class="pb-0 mb-0" cols="12">
                                            <div class="grey--text text--darken-1 body-2 pa-0 ma-0 mb-1">If the content is
                                                specific to an organisation then set the organisation below and this content
                                                will not be available to others:</div>
                                        </v-col>
                                        <v-col v-if="onlyAvailableToToggle" cols="12" xs="12" :md="inputCols">
                                            <v-autocomplete label="Content Only Available to"
                                                v-model="selectedAvailableToOrg" :items="organisations" item-text="orgName"
                                                auto-select-first return-object outlined dense hide-details="auto"
                                                background-color="white" color="primary" clearable
                                                @click:clear="clearSelectedAvailableToOrg()">
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" v-if="['StaffManager', 'StaffAdmin', 'DeveloperAdmin'].includes(userLevel)">
                                    <div class="text-h6 font-weight-thin primary--text">Content Status</div>
                                </v-col>
                                <v-col cols="12" sm="12"
                                    v-if="['StaffManager', 'StaffAdmin', 'DeveloperAdmin'].includes(userLevel)">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" sm="6" class="my-1"
                                    v-if="['StaffManager', 'StaffAdmin', 'DeveloperAdmin'].includes(userLevel)">
                                    <v-select label="Status" background-color="white" v-model="contentItem.contentStatus"
                                        :items="contentStatus" :rules="rules" outlined dense color="primary"
                                        hide-details="auto" clearable :menu-props="{ top: true, offsetY: true }"
                                        :attach="true" @click:clear="clearContactName('contentStatus', '')"></v-select>
                                </v-col>

                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="height: 100px !important" />
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="pl-1">
            <v-col v-if="advancedEditMode" cols="12" class="d-flex justify-end grey darken-1 py-4 px-4 pr-7"
                style="position: fixed; bottom: 0 !important;"
                :style="listshrunk ? 'width: 73.5vw !important' : 'width: 49.2vw !important'">
                <v-btn class="grey lighten-1 white--text font-weight-bold" depressed @click="cancelItem"> <v-icon
                        class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                <v-spacer />
                <v-btn class="primary white--text font-weight-bold" depressed @click="saveContent"> <v-icon
                        class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="preview" width="1366px" height="1024px" style="overflow: none !important">
            <videoComp v-if="contentItem.contentTemplate === 'Videos' || contentItem.contentTemplate === 'Video'"
                :contentItem="contentItem" @close="close()" />
            <recipe v-else-if="contentItem.contentTemplate === 'Recipe with Video'" :contentItem="contentItem"
                @close="close()" />
            <recipeWithPdf v-else-if="contentItem.contentTemplate === 'Recipe with PDF'" :contentItem="contentItem"
                @close="close()" />
            <pdf v-else-if="contentItem.contentTemplate === 'PDF'" :contentItem="contentItem" @close="close()" />
            <podcast v-else-if="contentItem.contentTemplate === 'Podcast'" :contentItem="contentItem" @close="close()" />
            <exercise v-else-if="contentItem.contentTemplate === 'Exercise'" :contentItem="contentItem" @close="close()" />
            <craft v-else-if="contentItem.contentTemplate === 'Craft'" :contentItem="contentItem" @close="close()" />
            <simple v-else :contentItem="contentItem" @close="close()" />
        </v-dialog>
    </div>
</template>

<script>
import simplePreview from './SimplePreview.vue';
import videoPreview from './VideoPreview.vue';
import recipePreview from './RecipeWithVideoPreview.vue';
import recipeWithPDFPreview from './RecipeWithPDFPreview.vue';
import pdfPreview from './PDFPreview.vue';
import podcastPreview from './PodcastPreview.vue';
import exercisePreview from './ExercisePreview.vue';
import craftPreview from './CraftPreview.vue';
// import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    name: 'ContentDetails',
    components: {
        simple: simplePreview,
        videoComp: videoPreview,
        recipe: recipePreview,
        recipeWithPdf: recipeWithPDFPreview,
        pdf: pdfPreview,
        podcast: podcastPreview,
        exercise: exercisePreview,
        craft: craftPreview,
    },
    props: {
        contentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        newItem: {
            type: Boolean,
            default: false,
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        key: 'content:details',
        advancedEditMode: false,
        contentStatus: ['Draft', 'Pending', 'Approved', 'Rejected', 'Deleted', 'Archived', 'Live'],
        contentAlias: [],
        contentAreaItems: [],
        contentTemplates: [],
        contentDurationMins: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90', '95', '100', '105', '110', '115', '120'],
        contentRatings: ['1', '2', '3', '4', '5'],
        dateMenuFrom: false,
        dateMenuTo: false,
        datePickerFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        datePickerTo: '',
        rules: [(v) => !!v || 'Required'],
        descriptionRules: [v => v.length <= 280 || 'Max 280 characters'],
        toggleAdditionalInfo: false,
        preview: false,
        inputCols: 12,

        pinCheckKey: 0,
        pinCheckBox: false,

        // Organisation Dropdown
        organisations: [],
        contentPartners: [],
        selectedOrganisation: {
            id: '',
            name: '',
            orgLogo: '',
        },
        selectedAvailableToOrg: {
            id: '',
            name: '',
        },
        selectedOwner: null,
        tagAutocomplete: '',

        // Users
        users: [],

        loading: false,

        date1: '',
        dateMenu1: false,
        date2: '',
        dateMenu2: false,

        // Form items
        selectedAlias: [],
        selectedTags: [],
        selectedArea: [],
        selectedDifficulty: '',

        // toggles
        onlyAvailableToToggle: false,
        ownerToggle: false,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        userLevel() { return this.GET_FIREBASE_userAccessToken.user_level },
        userGroup() { return this.GET_FIREBASE_userAccessToken.user_group },
        userStatus() { return this.GET_FIREBASE_userAccessToken.user_status },
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
        computedDate2() {
            return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
        },
    },
    watch: {
        selectedOrganisation: {
            handler: function () {
                this.contentItem.orgId = this.selectedOrganisation.id;
                this.contentItem.orgName = this.selectedOrganisation.orgName;
                if (this.selectedOrganisation.orgLogo !== null) {
                    this.contentItem.orgLogo = this.selectedOrganisation.orgLogo;
                } else {
                    this.contentItem.orgLog = ""
                }
            },
            deep: true,
        },
        selectedAvailableToOrg: {
            handler: function () {
                this.contentItem.availableOnlyOrgId = this.selectedAvailableToOrg.id;
                this.contentItem.availableOnlyOrgName = this.selectedAvailableToOrg.orgName;
            },
            deep: true,
        },
        selectedArea: {
            handler: function () {
                this.contentItem.contentArea = this.selectedArea.join(',');
            },
        },
        newItem() {
            let t = this;
            if (t.newItem === true) {
                t.advancedEditMode = true;
                t.contentItem.id = t.MIX_generateId();
            }
        },
        editMode() {
            let t = this;
            t.advancedEditMode = t.editMode;
        },
        // Selected Tags
        selectedTags: {
            handler: async function () {
                for (let i = 0; i < this.selectedTags.length; i++) {
                    this.selectedTags[i] = this.selectedTags[i].toLowerCase();
                    this.selectedTags[i] = this.selectedTags[i].replace(/[\W_]+/g, ' ');
                    if (this.selectedTags[i] === ' ') {
                        this.selectedTags.splice(i, 1);
                    } else {
                        this.selectedTags[i] = this.selectedTags[i].trim();
                    }
                }
                // Update Content Tags and split out array with commas
                this.contentItem.contentTags = this.selectedTags.join(',');

            },
            deep: true,
        },
        selectedDifficulty: {
            handler: async function () {
                switch (this.selectedDifficulty) {
                    case 'easy':
                        if (this.selectedTags.includes('easy')) {
                            // do nothing
                        } else {
                            this.selectedTags.push('easy');
                        }
                        if (this.selectedTags.includes('medium')) {
                            let index = this.selectedTags.indexOf('medium');
                            this.selectedTags.splice(index, 1);
                        }
                        if (this.selectedTags.includes('difficult')) {
                            let index = this.selectedTags.indexOf('difficult');
                            this.selectedTags.splice(index, 1);
                        }
                        break;
                    case 'medium':
                        if (this.selectedTags.includes('medium')) {
                            // do nothing
                        } else {
                            this.selectedTags.push('medium');
                        }
                        if (this.selectedTags.includes('easy')) {
                            let index = this.selectedTags.indexOf('easy');
                            this.selectedTags.splice(index, 1);
                        }
                        if (this.selectedTags.includes('difficult')) {
                            let index = this.selectedTags.indexOf('difficult');
                            this.selectedTags.splice(index, 1);
                        }
                        break;
                    case 'difficult':
                        if (this.selectedTags.includes('difficult')) {
                            // do nothing
                        } else {
                            this.selectedTags.push('difficult');
                        }
                        if (this.selectedTags.includes('easy')) {
                            let index = this.selectedTags.indexOf('easy');
                            this.selectedTags.splice(index, 1);
                        }
                        if (this.selectedTags.includes('medium')) {
                            let index = this.selectedTags.indexOf('medium');
                            this.selectedTags.splice(index, 1);
                        }
                        break;
                }
                this.contentItem.contentDifficulty = this.selectedDifficulty;
            },
            deep: true,
        },
        dateMenu1: {
            handler: async function () {
                this.contentItem.contentAvailableFrom = this.MIX_formatDateTime(this.date1, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
        dateMenu2: {
            handler: async function () {
                this.contentItem.contentAvailableTo = this.MIX_formatDateTime(this.date2, 'YYYY-MM-DD', 'X');
            },
            deep: true,
        },
    },
    methods: {
        updateStatus(status) {
            this.contentItem.contentStatus = status;
            this.$emit('updatecontent', this.contentItem);
        },
        // Clear Item Property
        clearItem(key, value) {
            setTimeout(() => {
                this.item[key] = value;
            }, 1);
        },
        formatTag: function (item) {
            if (item !== null) {
                return item.toLowerCase(); //.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            } else {
                return '';
            }
        },
        loadDataDown() {
            if (this.contentItem.contentTags !== '' && this.contentItem.contentTags !== undefined && this.contentItem.contentTags !== null) {
                this.selectedTags = this.contentItem.contentTags.split(',');
            }
            if (this.contentItem.contentAlias !== '') {
                this.selectedAlias = this.contentItem.contentAlias;
            }
            if (this.contentItem.contentArea !== '') {
                this.selectedArea = this.contentItem.contentArea.split(',');
            }
            if (this.contentItem.orgId !== '') {
                this.selectedOrganisation = {
                    id: this.contentItem.orgId,
                    orgName: this.contentItem.orgName,
                    orgLogo: this.contentItem.orgLogo,
                };
            } else {
                this.selectedOrganisation = {
                    id: 'OOMPHWELLNESS0000001',
                    orgName: 'Oomph Wellness',
                    orgLogo: '',
                };
            }
            if (this.contentItem.availableOnlyOrgId !== '') {
                this.selectedAvailableToOrg = {
                    id: this.contentItem.availableOnlyOrgId,
                    orgName: this.contentItem.availableOnlyOrgName,
                };
            }
            if (this.contentItem.contentAvailableFrom !== '') {
                this.date1 = this.MIX_formatDateTime(this.contentItem.contentAvailableFrom, 'X', 'YYYY-MM-DD');
            }
            if (this.contentItem.contentAvailableTo !== '') {
                this.date2 = this.MIX_formatDateTime(this.contentItem.contentAvailableTo, 'X', 'YYYY-MM-DD');
            }
            if (this.contentItem.ownerUserId !== '' && this.contentItem.ownerUserId !== undefined && this.contentItem.ownerUserId !== null) {
                this.selectedOwner = { ownerUserId: this.contentItem.ownerUserId, ownerUserName: this.contentItem.ownerUserName, ownerUserEmail: this.contentItem.ownerUserEmail };
            } else {
                this.selectedOwner = this.MIX_getCurrentOwner();
            }

            this.pinCheckBox = this.contentItem.contentPinned == 1;

            // console.log(this.contentItem.contentPinned)
        },
        checkDateFields() {
            var t = this;
            if (t.contentItem.contentAvailableFrom == '') {
                t.contentItem.contentAvailableFrom = '0'
            }

            if (t.contentItem.contentAvailableTo == '') {
                t.contentItem.contentAvailableTo = '0'
            }
        },
        async saveContent() {
            this.checkDateFields()
            this.contentItem.ownerUserId = this.selectedOwner.ownerUserId;
            this.contentItem.ownerUserName = this.selectedOwner.ownerUserName;
            this.contentItem.ownerUserEmail = this.selectedOwner.ownerUserEmail;
            this.contentItem.contentFirstName = this.contentItem.contentTitle.split(' ')[0];
            this.contentItem.contentAlias = this.selectedAlias;

            if (this.$refs.form.validate()) {
                let t = this;
                if (t.newItem) {
                    t.itemNew = true;
                    t.contentItem.contentViews = '0';
                    t.contentItem.contentLikes = '0';
                    t.contentItem.contentDislikes = '0';
                    t.contentItem.contentRating = '0';
                    t.contentItem.contentReviews = '0';
                    t.contentItem.contentDownloads = '0';
                }
                t.contentItem.contentKey = 'content:details';

                if (this.pinCheckBox) {
                    t.contentItem.contentPinned = 1;
                } else {
                    t.contentItem.contentPinned = 0;
                }

                const update = await t.MIX_createItem(t.contentItem, t.contentItem.contentKey, t.itemNew);
                if (update.code === 1) {
                    t.$emit('updatecontent', this.contentItem);
                    t.MIX_alertBox({ color: 'success', message: 'Created content item successfully', timeout: 4000, show: true });
                    t.$emit('contentDialog', false);
                }
                t.advancedEditMode = false;
            }
        },

        // * DELETE ITEM
        deleteItem() {
            this.$emit('delete')
        },
        // Clear Selected Organisation
        clearSelectedOrganisation() {
            setTimeout(() => {
                this.selectedOrganisation = { id: '', orgName: '', orgLogo: '' };
            }, 1);
        },
        // Clear Available To Organisation
        clearSelectedAvailableToOrg() {
            setTimeout(() => {
                this.selectedAvailableToOrg = { id: '', orgName: '' };
            }, 1);
        },
        // Clear Selected Owner
        clearSelectedOwner() {
            setTimeout(() => {
                this.selectedOwner = null;
            }, 1);
        },
        cancelItem() {
            this.advancedEditMode = false;
            this.$emit('cancel');
        },
        editContent(item) {
            let t = this;
            t.contentDialog = true;
            t.contentItem = item;
        },
        removeTag(item) {
            let t = this;
            t.contentItem.contentTags.splice(t.contentItem.contentTags.indexOf(item), 1);
            t.contentItem.contentTags = [...t.contentItem.contentTags];
        },
        close() {
            let t = this;
            t.preview = false;
        },
        async getContentAlias() {
            // Get Content Template - JSON
            let t = this;
            let contentAlias = await t.MIX_redisReadSet('set:contentAlias', false);
            if (contentAlias.code === 1) {
                t.contentAlias = contentAlias.data;
            }
        }
    },
    async mounted() {
        var t = this;
        // Get Organisations
        t.loading = true;

        let organisations = await t.MIX_organisations();
        this.organisations = organisations.map((x) => {
            return {
                id: x.id,
                orgName: x.orgName,
                orgAccountType: x.orgAccountType,
                orgLogo: x.orgLogo,
            };
        });

        let contentPartners = t.organisations.filter(function (item) {
            return item.orgAccountType.includes('Partner')
        })
        this.contentPartners = contentPartners
        let contentTags = await t.MIX_redisReadSet('set:contentTags', false);
        if (contentTags.code === 1) {
            t.contentTags = contentTags.data;
        }

        let contentAreaItems = await t.MIX_redisReadSet('set:contentAreas', false);
        if (contentAreaItems.code === 1) {
            t.contentAreaItems = contentAreaItems.data;
        }
        if (t.newItem === true) {
            t.advancedEditMode = true;
        }
        // Get Users
        let users = await this.MIX_users(''); // Add Users to Dropdown for Owner
        this.users = users.map((x) => {
            return {
                ownerUserId: x.id,
                ownerUserName: x.userName,
                ownerUserEmail: x.userEmail,
            };
        });
        t.loadDataDown();
        t.getContentAlias();
        t.loading = false;
    },
};
</script>
