<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field label="Search" v-model="search" outlined dense hide-details="auto" background-color="white"
                    color="primary" clearable prepend-inner-icon="oomph-search"
                    @click:clear="clearSearchQuery()"></v-text-field>
            </div>
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important"
                class="ml-2 white grey--text text--darken-2" @click="refreshList">
                <v-icon>oomph-available-updates</v-icon>
            </v-btn>
            <div class="ml-2">
                <v-menu offset-y class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important"
                            class="white grey--text text--darken-2" v-bind="attrs" v-on="on">
                            <v-icon>oomph-table-properties</v-icon>
                        </v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto"
                                v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false"
                                :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <v-row>
            <v-col cols="12">
                <v-btn-toggle dense multiple v-model="filter.status"
                    class="mt-4 appbackground d-flex align-center flex-wrap">
                    <v-btn value="active" small active-class="success white--text" depressed>
                        <v-icon class="mr-2">oomph-ok</v-icon>Active
                    </v-btn>
                    <v-btn value="idle" small active-class="error white--text" depressed>
                        <v-icon class="mr-2">oomph-unavailable</v-icon>Idle
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row class="pa-3 mt-1">
            <!-- {{ computedQuizzes.length }} -->
            <!-- TABLE BASED LIST -->
            <v-data-table :item-class="itemRowBackground" sortable dense class="" style="width: 100%"
                :headers="computedHeaders" :items="computedQuizzes" :server-items-length="itemsTotal"
                :options.sync="datatableOptions" :search="search">
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span>
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                    </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-icon v-if="item.status === 'active'" class="font-weight-bold success--text">oomph-ok</v-icon>
                    <v-icon v-else class="font-weight-bold error--text">oomph-unavailable</v-icon>
                </template>
                <!-- <template v-slot:[`item.itemLengthMins`]="{ item }">
                   <span v-if="item.itemLengthMins !== ''">{{ Number(item.itemLengthMins).toFixed() }}</span> 
                   <span v-else>-</span>
                </template> -->
                <template v-slot:[`item.createdDateTime`]="{ item }">
                    <span v-if="item.createdDateTime !== ''">{{ MIX_formatDateTime(item.createdDateTime, 'x', 'DD-MMM-YY |
                                            HH: mm') }}</span>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <!-- <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyQuiz(item.id)">oomph-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteQuiz(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreQuiz(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon> -->
                    <!-- <v-icon class="primary--text ml-5" @click="getVideoAssetInfo(item)">oomph-reboot</v-icon> -->
                    <!-- <v-icon v-if="item.status === 'active'" class="primary--text ml-5" @click="openVideo(item)">oomph-tv-show</v-icon> -->
                    <v-icon class="primary--text ml-5" @click="openVideo(item)">oomph-tv-show</v-icon>

                </template>
            </v-data-table>
        </v-row>
        <v-dialog persistant v-model="dialogVideoPreview" max-width="1024px" class="primary">
            <mux-video :key="muxVideoKey" class="primary" style="max-width: 1024px !important;" v-if="!videoLoading"
                :playback-id="video.playbackId" :metadata-video-title="video.title" :poster="video.poster"
                stream-type="live" preload prefer-mse controls></mux-video>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    // * PROPS
    props: {
        refreshlist: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        API_URL: process.env.VUE_APP_OOMPHSERVER,
        search: '',
        headers: [
            { text: 'ID', value: 'id', sortable: false, hidden: true, expanded: true },
            { text: 'Playback ID', value: 'playbackId', sortable: false, hidden: false, expanded: false, filterable: true },
            { text: 'Status', value: 'status', sortable: false, hidden: false, expanded: false },
            { text: 'Created', value: 'createdDateTime', sortable: false, hidden: false, expanded: false },
            { text: '', value: 'action', width: '120px', align: 'end', sortable: false, hidden: false, expanded: false },

        ],
        datatableOptions: {
            page: 1,
            itemsPerPage: 15,
            sortBy: ['status'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        filter: {
            status: [],
        },
        video: {
            title: '',
            poster: '',
            playbackId: '',
        },
        livestreams: [],
        itemsFrom: 0,
        itemsTo: 14,
        itemsTotal: null,
        videoLoading: false,
        muxVideoKey: 0,
        dialogVideoPreview: false,
    }),
    computed: {
        computedSearchQuery() {
            const filter = this.filter.status
            return filter
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (this.listshrunk) {
                headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            } else {
                headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            }
            return headers;
        },
        // Computed Quizzes
        computedQuizzes() {
            var livestreams = this.livestreams;
            return livestreams;
        },
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_firebase_userAccess: 'firebase_auth_store/GET_firebase_userAccess',
            GET_FIREBASE_apiHeaderToken: 'firebase_auth_store/GET_FIREBASE_apiHeaderToken',
        }),
        apiOptions() {
            return {
                headers: {
                    Authorization: `Bearer ${this.GET_FIREBASE_apiHeaderToken}`,
                },
            };
        },
    },
    // * METHODS
    methods: {
        // Get Videos
        async getLivestreams() {
            if (this.search.length !== 1) {
                const status = this.computedSearchQuery
                let livestreamResult = (await this.$axios.get(`${this.API_URL}/video/live-streams/`, this.apiOptions)).data.data
                livestreamResult = livestreamResult.map(e => ({ id: e.id, playbackId: e.playback_ids[0].id, status: e.status, createdDateTime: moment.unix(e.created_at).format('x'), url: `https://stream.mux.com/${e.playback_ids[0].id}.m3u8` }))

                if (status !== null && status.length > 0) {
                    let tempResult = []

                    for (let i = 0; i < livestreamResult.length; i++) {
                        if (status.includes(livestreamResult[i].status)) tempResult.push(livestreamResult[i])
                    }
                    livestreamResult = tempResult
                }

                this.itemsTotal = livestreamResult.length
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                (itemsTo < this.itemsTotal)
                    ? this.itemsTo = itemsTo
                    : this.itemsTo = this.itemsTotal

                this.livestreams = livestreamResult.slice(this.itemsFrom, this.itemsTo)
            }

        },
        async openVideo(item) {
            this.videoLoading = true;
            // let videoResult = await this.MIX_readItem(item.id, 'contentItem');
            // if (videoResult.code === 1) {
            this.selectedVideo = item;
            this.video.playbackId = item.playbackId
            this.video.title = item.id;
            this.video.poster = `https://image.mux.com/${item.playbackId}/thumbnail.jpg`;
            // }

            //console.log(item)
            this.dialogVideoPreview = true;
            this.videoLoading = false;
        },
        customFilter(value, search, item) {
            return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().indexOf(search) !== -1
        },
        refreshList() {
            this.getLivestreams();
        },
        itemRowBackground(item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        clearSearchQuery() {
            this.search = ''
        },

    },
    // * WATCH
    watch: {
        // Watch Refresh List
        refreshlist() {
            this.refreshList();
        },
        // Watch Computed Search Query
        computedSearchQuery() {
            this.itemsFrom = 0;
            this.getLivestreams();
        },
        // Watch Computed Fields
        computedFields() {
            this.getLivestreams();
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getLivestreams();
            },
            deep: true,
        },
    },
    mounted: function () {
        window.setInterval(() => {
            this.getLivestreams()
        }, 30000)
    }
};
</script>
