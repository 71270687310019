<template>
    <v-card :key="cardKey">
        <v-toolbar class="pa-0 ma-0" color="primary" elevation="0">
            <v-card-title class="white--text">Add PDF</v-card-title>
            <v-spacer />
            <div>
                <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeOverlay()" depressed>
                    <v-icon>oomph-multiply</v-icon>
                </v-btn>
            </div>
        </v-toolbar>
        <v-card-text class="grey lighten-3 px-4">
            <v-row class="py-2 pt-4 px-0" dense>
                <v-col cols="12">
                    <form>
                        <v-row dense v-if="showPreview === false">
                            <v-col cols="12" class="d-flex align-center">
                                <div class="text-left primary--text text-h7">Please use the form below to add a PDF item:
                                </div>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" class="pb-0 mb-0">
                                <v-text-field label="Name" class="primary--text" background-color="white"
                                    v-model="item.itemName" outlined hide-details="auto" dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" class="pb-0 mb-0">
                                <v-select label="Marker" class="" background-color="white" v-model="item.itemMarker"
                                    :items="markers" item-text="name" item-value="type" hide-details="auto" outlined
                                    :menu-props="{ top: false, offsetY: true }" dense></v-select>
                            </v-col>
                            <v-col v-if="showPreview === false" cols="12" sm="12" md="4" class="pb-0 mb-0">
                                <v-combobox class="" small-chips hide-selected label="Content Source"
                                    v-model="item.contentSource" :search-input.sync="contentSourceAutocomplete" @change="
                                        contentSourceAutocomplete = '';
                                    updateContentSources(item.contentSource);
                                    " :items="contentSources" outlined dense hide-details="auto"
                                    background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }"
                                    :attach="true" clearable>
                                    <template v-slot:selection="{ attrs, item, selected }">
                                        <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                            <span class="pr-2">
                                                {{ item }}
                                            </span>
                                        </v-chip>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <span class="subheading">Create</span>
                                            <v-chip color="grey lighten-3" label small>
                                                {{ contentSourceAutocomplete }}
                                            </v-chip>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </v-col>
                            <v-col v-if="pdfUploadPanel && showPreview === false" cols="12" class="pa-4 py-6">
                                <v-row>
                                    <v-card v-show="pdfUploadPanel" style="width: 100% !important" v-model="panel"
                                        class="white elevation-0">
                                        <v-row class="pt-3 px-3">
                                            <v-col cols="6">
                                                <div class="grey--text text--darken-1 pl-1">Please use the box below to
                                                    upload your PDF.</div>
                                            </v-col>
                                        </v-row>
                                        <v-card-text>
                                            <div className="page-container">
                                                <FileUpload accept=".pdf" label="Select file" buttonClass="primary"
                                                    progressClass="pa-2" folderLocation="content/pdf"
                                                    :fileName="MIX_generateId()" :selectedItem="contentItem"
                                                    :compressionMaxSizeMB="1" :compressionmaxWidthOrHeight="1920"
                                                    :private="true" :isProfile="false" @update="uploadPDF" />
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="showPreview === true">
                            <v-col cols="12" class="d-flex align-end">
                                <v-spacer />
                                <v-btn class="primary white--text" @click="showPreview = !showPreview" outlined depressed>
                                    <div v-if="!showPreview">Show Image</div>
                                    <div v-else-if="showPreview">Edit PDF</div>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="showPreview === true">
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table class="white rounded pa-3 fill-height" dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Name:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{ item.itemName
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Marker:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{ item.itemMarker
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Status:</td>
                                                <td width="50%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{ item.itemStatus
                                                    }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="showPreview === true">
                            <v-col cols="12" class="grey lighten-3 justify-center align-center" v-if="item.itemURL">
                                <v-card height="65" elevation="0" width="100%">
                                    <v-divider></v-divider>
                                    <v-card-text style="postion: absolute !important" class="pb-0">
                                        <v-col cols="12" class="d-flex justify-end align-center py-0 pb-2">
                                            <v-row>
                                                <v-col cols="12" class="d-flex justify-end align-center">
                                                    <div class="body-1 font-weight-thin">{{ page }} / {{ pageCount }}</div>
                                                    <v-btn :disabled="page <= 1" @click="page--"
                                                        text><v-icon>oomph-back</v-icon></v-btn>
                                                    <v-btn :disabled="page >= pageCount" @click="page++"
                                                        text><v-icon>oomph-forward</v-icon></v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-card-text>
                                </v-card>
                                <v-card height="470" elevation="0" style="overflow-y: scroll; overflow-x: hidden"
                                    width="100%">
                                    <v-card-text class="d-flex justify-center grey lighten-3">
                                        <VuePdfEmbed ref="pdfRef" :source="item.itemURL" :page="page" height="1000"
                                            @rendered="handleDocumentRender" />
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </form>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="grey lighten-1 pa-3 pr-4 d-flex justify-right align-center">
            <v-spacer />
            <div>
                <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="closeOverlay"> <v-icon
                        class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
            </div>
            <div class="ml-2">
                <v-btn v-if="!showPreview === true" class="primary font-weight-bold white--text"
                    @click="showPreview = !showPreview" outlined depressed>
                    <div v-if="showPreview">Show Image</div>
                    <div v-else-if="!showPreview">Edit PDF</div>
                </v-btn>
                <v-btn depressed v-if="showPreview === true" class="primary font-weight-bold" @click="saveContentItem()">
                    <v-icon size="30" class="white--text">oomph-save</v-icon>
                    Save Content
                </v-btn>
                <!-- <v-btn depressed v-else class="primary" @click="imageEditMode = false"><v-icon size="30" class="white--text" depressed>oomph-edit</v-icon>Edit Content</v-btn> -->
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'AddEditPdfOverlay',
    props: {
        addPdfOverlay: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        markers: {
            type: Array,
            default: () => {
                return [];
            },
        },
        contentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        originalContentId: {
            type: String,
            default: () => {
                return '';
            },
        },
        editMode: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        extendWidth: {
            type: Number,
            default: () => {
                return 400;
            },
        },
        newItem: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
    },
    data: () => ({
        currentPage: 0,
        pageCount: 0,
        item: {
            id: '',
            key: '',
            itemContentId: '',
            itemAssetId: '',
            itemName: '',
            itemMarker: '',
            itemStatus: 'Live',
            itemType: '',
            itemURL: '',
            itemFileSizeKb: '',
            itemFormat: '',
            itemWidth: '',
            itemHeight: '',
            itemLengthMins: '',
            itemEncodeStatus: '',
            itemTextHTML: '',
            itemUploadId: '',
            createdDateTime: '',
            createdUserId: '',
            createdUserName: '',
            createdUserEmail: '',
            modifiedDateTime: '',
            modifiedUserId: '',
            modifiedUserName: '',
            modifiedUserEmail: '',
            deletedDateTime: '',
            deletedUserId: '',
            deletedUserName: '',
            deletedUserEmail: '',
            deleted: '',
        },
        contentSourceAutocomplete: '',
        contentSources: [],
        cardKey: 0,
        itemNew: false,
        markerItems: ['VIDEO', 'AUDIO', 'IMAGE', 'PDF', 'DOCUMENT', 'OTHER'],
        panel: [0],
        pdfEditMode: false,
        pdfUploadPanel: true,
        showPreview: false,
        statusItems: ['Draft', 'Live', 'Deleted', 'Archived'],
        page: 1,
        pageCount: 1,
    }),
    watch: {
        editMode() {
            let t = this;
            if (t.editMode === true) {
                t.item = t.contentItem;
                // t.pdfEditMode = t.editMode;
                t.showPreview = true;
            }
        },
        contentItem: {
            handler: function () {
                let t = this;
                t.item = t.contentItem;
                t.showPreview = true;
                t.pdfEditMode = true;
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_firebase_userAccess: 'firebase_auth_store/GET_firebase_userAccess',
            GET_FIREBASE_apiHeaderToken: 'firebase_auth_store/GET_FIREBASE_apiHeaderToken',
        }),
        apiOptions() {
            return {
                headers: {
                    Authorization: `Bearer ${this.GET_FIREBASE_apiHeaderToken}`,
                },
            };
        },
    },
    async created() {
        var t = this;
        let sources = await t.MIX_redisReadSet('set:contentSources', true);
        if (sources.code === 1) {
            t.contentSources = sources.data;
        }

        if (t.contentItem.id !== null && t.contentItem.id !== '' && t.contentItem.id !== undefined) {
            if (this.contentItem.contentSources !== '' && this.contentItem.contentSources !== undefined && this.contentItem.contentSources !== null) {
                this.contentSources = this.contentItem.contentSources.split(',');
            }
            t.item = t.contentItem;
            t.showPreview = true;
            t.pdfEditMode = true;
        }
    },
    methods: {
        // Update Event Groups
        async updateContentSources(group) {
            // console.log("### EventContentAddLink ### updateContentSources ### group", group);
            // If Tag does not exist in array then add it
            if (this.contentSources.indexOf(group) === -1) {
                this.contentSources.push(group);
            }
            // Remove any blank groups from array
            this.contentSources.forEach((item, index) => {
                if (item === '') {
                    this.contentSources.splice(index, 1);
                }
            });
            // // console.log('contentSources = ' + JSON.stringify(this.contentSources, null, 2))
            this.item.contentSources = this.contentSources.join(',');

            let addToSetResult = await this.MIX_redisAddToSet(`set:contentSources`, group);
            if (addToSetResult.code === -1) {
                // return;
            }
        },
        uploadPDF(url, file) {
            let t = this;
            if (t.pdfEditMode === true) {
                t.item.itemFormat = file.type;
                t.item.itemFileSizeKb = t.MIX_bytesToSize(file.size);
            }
            t.item.itemURL = url;
            t.showPreview = true;
        },
        async saveContentItem() {
            let t = this;
            t.item.key = 'contentItem';
            t.item.itemType = 'PDF';
            t.item.itemContentId = t.originalContentId;
            if (t.pdfEditMode === true) {
                t.itemNew = false;
                t.item.modifiedDateTime = t.$moment().format('x');
                let updateContentItemResult = await this.MIX_redisUpdateHash('contentItem:' + t.item.id, t.item);
                if (updateContentItemResult.code === 1) {
                    t.closeOverlay();
                    t.$emit('updateItems');
                    t.MIX_alertBox({ color: 'success', message: 'Created content item successfully', timeout: 4000, show: true });
                }
            } else {
                t.itemNew = true;
                t.item.id = t.MIX_generateId();
                t.item.createdDateTime = t.$moment().format('x');
                //* Saves file data to redis
                const update = await this.MIX_createItem(t.item, t.item.key, t.itemNew);
                if (update.code === 1) {
                    t.closeOverlay();
                    t.$emit('updateItems');
                    t.MIX_alertBox({ color: 'success', message: 'Created content item successfully', timeout: 4000, show: true });
                }
            }
        },
        async handleUpload() {
            let t = this;
            t.loading = true;
            try {

                const response = await t.$axios.post(`${process.env.VUE_APP_OOMPHSERVER}/video/uploads`, {}, t.apiOptions)
                // const response = await fetch(process.env.VUE_APP_OOMPHSERVER + '/video/uploads', { method: 'POST' });
                // const uploadUrl = await response.json();
                const uploadUrl = await response.data;
                t.item.itemUploadId = uploadUrl.data.id;
                t.item.itemType = 'Video';
                t.item.itemFileSizeKb = t.MIX_bytesToSize(t.file.size);
                t.item.itemFormat = t.file.type;
                const upload = UpChunk.createUpload({
                    endpoint: uploadUrl.data.url, //* Authenticated url
                    file: t.file, //* File object with the video file’s properties
                    chunkSize: 30720, //* Uploads the file in ~30 MB chunks to cater for large files
                });
                upload.on('error', (error) => {
                    t.loading = false;
                    t.statusMessage = error.detail;
                });
                upload.on('progress', (progress) => {
                    t.statusMessage = progress.detail;
                });
                upload.on('success', async () => {
                    t.loading = false;
                    //* Calls mux to get the status of the video and id
                    await t.$axios.get(`${process.env.VUE_APP_OOMPHSERVER}/video/uploads/${t.item.itemUploadId}`, t.apiOptions).then((response) => {
                        t.item.itemAssetId = response.data.data.asset_id;
                        if (response.data.data.status === 'asset_created') {
                            t.item.itemEncodeStatus = 'processing';
                            t.videoUploadPanel = false;
                        }
                    });
                });
            } catch (error) {
                t.loading = false;
                t.MIX_alertBox({ color: 'error', message: 'Error uploading items', timeout: 4000, show: true });
            }
        },
        handleDocumentRender() {
            this.isLoading = false;
            this.pageCount = this.$refs.pdfRef.pageCount;
        },
        closeOverlay() {
            let t = this;
            t.$emit('closeOverlay', false);
            t.cardKey++;
        },
    },
};
</script>
