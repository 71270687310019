<template>
    <v-container class="pa-0 animate__animated animate__fadeIn" fluid style="height: calc(100vh - 40px) !important;">
        <v-row class="d-flex align-end appbackground pl-4"
            :style="orgDivisions.length > 0 ? 'height: 120px' : 'height: 80px'" no-gutters>
            <v-col cols="6">
                <v-autocomplete v-model="selectedOrgGroup" class="pr-4" :label="`${selectedOrgGroup?.orgName}`"
                    hide-details="auto" background-color="white" color="primary" prepend-inner-icon="oomph-search"
                    :items="orgGroups" item-text="orgName" @click:clear="clearSelectedOrgGroupsFilter()" return-object
                    outlined dense clearable></v-autocomplete>
            </v-col>
            <v-col cols="6" v-show="selectedOrgGroup.orgName !== 'Oomph Wellness'">
                <v-checkbox :label="`Select All Sub Organisations - ${selectedSubOrg.length} selected`"
                    class="ml-0 pa-0 ma-0 my-1" hide-details="auto" v-model="checkAllSubOrgs"
                    @change="selectAllSubOrgs()"></v-checkbox>
                <v-autocomplete v-model="selectedSubOrg" class="mr-4" label="Filter by Sub Organisation" hide-details="auto"
                    background-color="white" color="primary" prepend-inner-icon="oomph-search" :items="orgSubGroups"
                    item-text="orgName" @click:clear="clearSelectedSubOrgsFilter()" single-line return-object multiple
                    outlined dense clearable chips small-chips>

                    <template v-slot:selection="dataSub">
                        <v-chip v-if="selectedSubOrg.length < 4" v-bind="dataSub.attrs" small>
                            {{ dataSub.item.orgName }}
                        </v-chip>
                        <div v-else-if="dataSub.index === 0">
                            {{ `${selectedSubOrg.length} sub organisations selected` }}
                        </div>
                    </template>

                </v-autocomplete>
            </v-col>
            <v-col cols="6" class="mt-2" v-if="orgDivisions.length > 0">
                <v-autocomplete class="mr-4" label="Filter by Division" hide-details="auto" background-color="white"
                    color="primary" prepend-inner-icon="oomph-search" item-text="orgDivision" single-line return-object
                    multiple outlined dense clearable :items="orgDivisions" v-model="selectedDivisions"
                    @click:clear="clearSelectedDivisionsFilter()" @change="filterByDivisions()">
                    <template v-slot:selection="dataDivision">
                        <v-chip v-if="selectedDivisions.length < 6" v-bind="dataDivision.attrs" small>
                            {{ dataDivision.item }}
                        </v-chip>
                        <div v-else-if="dataDivision.index === 0">
                            {{ `${selectedDivisions.length} divsions selected` }}
                        </div>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="6" class="mt-2" v-if="orgRegions.length > 0 && selectedDivisions.length > 0">
                <v-autocomplete class="mr-4" label="Filter by Region" hide-details="auto" background-color="white"
                    color="primary" prepend-inner-icon="oomph-search" item-text="orgRegion" single-line return-object
                    multiple outlined dense clearable :items="orgRegions" v-model="selectedRegions"
                    @click:clear="clearSelectedRegionsFilter()" @change="filterByRegions()">
                    <template v-slot:selection="dataRegion">
                        <v-chip v-if="selectedRegions.length < 6" v-bind="dataRegion.attrs" small>
                            {{ dataRegion.item }}
                        </v-chip>
                        <div v-else-if="dataDivision.index === 0">
                            {{ `${selectedDivisions.length} divsions selected` }}
                        </div>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="appbackground" no-gutters>
            <v-col cols="9" class="d-flex flex-column px-4">
                <div class="d-flex flex-column align-start">
                    <!--                    <div class="mt-2">-->
                    <!--                    <v-btn depressed class="primary white&#45;&#45;text mr-1" @click="report = 'home'"><v-icon class="mr-2">oomph-calendar</v-icon>Home</v-btn>-->
                    <!--                    </div>-->
                    <!--                    <div class="mt-2">-->
                    <!--                    <v-btn depressed class="primary white&#45;&#45;text mr-1" @click="report = 'gr1'"><v-icon class="mr-2">oomph-calendar</v-icon>Reports</v-btn>-->
                    <!--                    </div>-->
                    <div class="mt-2" v-if="!showFilters"><v-btn depressed @click="showFilters = true">Enable
                            Filters</v-btn></div>
                    <div class="mt-2" v-else><v-btn depressed @click="showFilters = false">Disable Filters</v-btn></div>

                    <div class="mt-5" v-if="showFilters">
                        <v-dialog ref="dialog" v-model="modal" :return-value.sync="dateRange" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="dateRangeText" label="Date Range" prepend-icon="mdi-calendar"
                                    v-bind="attrs" v-on="on" readonly solo outlined flat></v-text-field>
                            </template>
                            <v-date-picker v-model="dateRange" range scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                                <v-btn text color="primary" @click="$refs.dialog.save(dateRange)"> OK </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </div>
                    <!-- <div class="mt-2">
                        <v-btn depressed class="primary white--text mr-1" @click="report = 'gr2'"><v-icon class="mr-2">oomph-calendar</v-icon>Group Report 2</v-btn>
                    </div> -->
                </div>
                <div class="d-flex align-center">
                    <div v-for="(item, index) in dynamicComponents" :key="`dynamic-${index}`" class="mt-2">
                        <v-btn v-if="item.access.includes(report)" depressed class="primary white--text mr-1"
                            @click="() => { dynamicComponent = item.component; filter = item.filter }"><v-icon
                                class="mr-2">{{
                                    item.icon }}</v-icon>{{ item.name }}</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <div class="mt-5">
            <transition name="custom-classes" enter-active-class="animate__animated animate__fadeIn animate__faster"
                leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
                <component :is="dynamicComponent" :key="dynamicComponentKey" :selectedOrgGroup="selectedOrgGroup"
                    :selectedSubOrg="selectedSubOrg" :daterange="computedDateRange" :filterBy="filter"
                    :divisions="orgDivisions.length > 0" :orgGroups="orgGroups" />
            </transition>
        </div>
        <v-overlay v-model="loading" style="z-index: 9999999999 !important">
            <div class="d-flex flex-column align-center">
                <v-progress-circular :size="100" color="primary" class="mb-4" indeterminate></v-progress-circular>
                <div class="oomph-font-h4">Generating PDF...</div>
            </div>
        </v-overlay>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

import Dashboard from '@/views/reporting/Dashboard.vue'
import Logins from '@/views/reporting/Logins.vue'
import Views from '@/views/reporting/Views.vue'
import Downloads from '@/views/reporting/Downloads.vue'
import Reviews from '@/views/reporting/Reviews.vue'
import ResidentInclusion from '@/views/reporting/ResidentInclusion.vue'
import ActivitySchedule from '@/views/reporting/ActivitySchedule.vue'
import TotalInteractions from '@/views/reporting/TotalInteractions.vue'
import BarchesterGroupReportOne from '@/views/reporting/barchester/BarchesterGroupReportOne.vue'
import BarchesterGroupReportTwo from '@/views/reporting/barchester/BarchesterGroupReportTwo.vue'

export default {
    name: 'Reporting',
    components: {
        Dashboard: Dashboard,
        Logins: Logins,
        Views: Views,
        Downloads: Downloads,
        Reviews: Reviews,
        ResidentInclusion,
        ActivitySchedule,
        TotalInteractions,
        BarchesterGroupReportOne,
        BarchesterGroupReportTwo
    },
    data: () => ({
        subOrgsFilteredByRegion: [],
        subOrgsFilteredByDivision: [],
        checkAllOrgs: false,
        checkAllSubOrgs: true,
        date: new Date().toISOString().substr(0, 7),
        modal: false,
        appUrl: process.env.VUE_APP_URL,
        dateRange: [moment().subtract(1, 'month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        dynamicComponent: 'Dashboard',
        dynamicComponentKey: 0,
        loading: false,
        orgGroups: [],
        selectedOrgGroup: {
            id: '',
            orgAccountType: '',
            orgLogo: '',
            orgName: ''
        },
        orgSubGroups: [],
        orgSubGroupsBackup: [],
        orgDivisions: [],
        orgRegions: [],
        selectedSubOrg: [],
        selectedDivisions: [],
        selectedRegions: [],
        seriesdata: [],
        tab: 'logins',
        report: "home",
        filter: [],
        showFilters: false,
        dynamicComponents: [
            { name: "Dashboard", component: "dashboard", icon: "oomph-chart", access: ["home"], filter: [] },
            { name: "Logins", component: "logins", icon: "oomph-landlord", access: ["home"], filter: [] },
            // { name: "Logins", component: "logins", icon: "oomph-landlord", access: ["home", "gr1"], filter: [] },
            { name: "Views", component: "views", icon: "oomph-eye", access: ["home"], filter: [] },
            { name: "Downloads", component: "downloads", icon: "oomph-export-pdf", access: ["home"], filter: [] },
            { name: "Reviews", component: "reviews", icon: "oomph-star", access: ["home"], filter: [] },
            { name: "Resident Inclusion", component: "ResidentInclusion", icon: "oomph-people", access: ["home"], filter: [] },
            { name: "Activity Schedule", component: "ActivitySchedule", icon: "oomph-calendar", access: ["home"], filter: [] },
            { name: "Total Logins", component: "BarchesterGroupReportOne", icon: "oomph-eye", access: ["home"], filter: [] },
            { name: "Content Interactions", component: "BarchesterGroupReportTwo", icon: "oomph-export-pdf", access: ["home"], filter: ["views_Activities", "views_Exercise", "downloads_Exercise", "downloads_Activities", "views_Oomph TV"] },

            // { name: "Total Interactions", component: "TotalInteractions", icon: "oomph-calendar", access: ["home"], filter: [] },
            // { name: "Total Interactions", component: "TotalInteractions", icon: "oomph-calendar", access: ["home", "gr1"], filter: [] },
            // { name: "Activities Views", component: "views", icon: "oomph-eye", access: ["gr2"], filter: ["Activities"]},
            // { name: "Activities Downloads", component: "downloads", icon: "oomph-eye", access: ["gr2"], filter: ["Activities"]},
            // { name: "Exercise Views", component: "views", icon: "oomph-eye", access: ["gr2"], filter: ["Exercise"]},
            // { name: "Oomph TV Plays", component: "views", icon: "oomph-eye", access: ["gr2"], filter: ["Oomph TV"]},
            // { name: "Total", component: "TotalInteractions", icon: "oomph-eye", access: ["gr2"], filter: ["views_Activities", "views_Exercise", "downloads_Activities","views_Oomph TV"]},
        ]
    }),
    watch: {
        selectedOrgGroup: function (val) {
            this.dynamicComponentKey++
            this.getSubOrgs(val.id)
        },
        selectedSubOrg: function () {
            this.dynamicComponentKey++
        },
        // selectedDivisions: function () {
        //     this.dynamicComponentKey++
        // }
    },
    computed: {
        ...mapGetters({
            GET_firebase_userAuth: 'GET_firebase_userAuth',
            GET_firebase_user: 'GET_firebase_user',
            GET_brand: 'GET_brand'
        }),
        dateRangeText() {
            return this.$moment(this.dateRange[0], 'YYYY-MM-DD').format('DD-MMM-YYYY') + ' ~ ' + this.$moment(this.dateRange[1], 'YYYY-MM-DD').format('DD-MMM-YYYY')
        },
        computedViews() {
            let t = this
            return t.contentStats.filter((item) => item.statType === 'view')
        },
        computedDateRange() {
            if (!this.showFilters) {
                return {
                    startDate: "",
                    endDate: ""
                }
            }
            return {
                startDate: this.$moment(this.dateRange[0]).startOf('day').format('X'),
                endDate: this.$moment(this.dateRange[1]).endOf('day').format('X')
            }
        },
    },
    methods: {
        async getOrgs() {
            let t = this
            //* Oomph ID
            if (t.GET_FIREBASE_userAccessToken.org_id === '3RTRBM9dqrkC4WEsIvhx') {
                const getOrgsGroups = await this.MIX_redisSearch('idx:orgs', `@deleted:0 @orgGroupStructure:{Group|Parent Group}`, 0, 9999)
                const data = getOrgsGroups.data.documents.map((x) => {
                    return {
                        id: x.value.id,
                        orgName: x.value.orgName,
                        orgAccountType: x.value.orgAccountTypes,
                        orgLogo: x.value.orgLogo,
                        orgDivision: ""
                    }
                })
                this.orgGroups = data
                this.orgGroups.sort((a, b) => a.orgName.localeCompare(b.orgName))
                this.selectedOrgGroup = {
                    id: '3RTRBM9dqrkC4WEsIvhx',
                    orgAccountType: 'Partner,Customer"',
                    orgLogo: 'https://firebasestorage.googleapis.com/v0/b/oomph-portal-private/o/organisationLogo%2F7aCe8dY8cqaK5Jev3Ffb%2FvDheDxZyy7NWt6BxYuAr.jpg?alt=media&token=c8e612a1-8d27-4cf8-bc47-8bc49de06b67',
                    orgName: 'Oomph Wellness',
                    orgDivision: "",
                    orgRegion: ""
                }
                this.orgGroups.push(this.selectedOrgGroup)
            } else {
                const getOrgsGroups = await this.MIX_redisSearch('idx:orgs', `@deleted:0 @id:${t.GET_FIREBASE_userAccessToken.org_id}`, 0, 9999)
                const data = getOrgsGroups.data.documents.map((x) => {
                    return {
                        id: x.value.id,
                        orgName: x.value.orgName,
                        orgAccountType: x.value.orgAccountTypes,
                        orgLogo: x.value.orgLogo,
                        orgDivision: "",
                        orgRegion: ""
                    }
                })
                this.orgGroups = data
                this.orgGroups.sort((a, b) => a.orgName.localeCompare(b.orgName))
                this.selectedOrgGroup = this.orgGroups[0]
            }
        },
        async getSubOrgs(id) {
            // const getSubOrgs = await this.MIX_redisSearch('idx:orgs', `@deleted:0 @orgGroupId:{${id}}`, 0, 9999)
            const getSubOrgs = await this.MIX_redisSearch('idx:orgs', `(@deleted:0 @orgGroupId:{${id}}) | (@deleted:0 @orgParentGroupId:{${id}})`, 0, 9999)
            let data = getSubOrgs.data.documents.map((x) => {
                return {
                    id: x.value.id,
                    orgName: x.value.orgName,
                    orgAccountType: x.value.orgAccountTypes,
                    orgLogo: x.value.orgLogo,
                    orgDivision: x.value.orgDivisionName,
                    orgRegion: x.value.orgRegionName
                }
            })
            data.sort((a, b) => a.orgName.localeCompare(b.orgName))

            this.orgSubGroups = data
            this.orgSubGroupsBackup = data
            this.selectedSubOrg = data

            this.subOrgsFilteredByRegion = data
            this.subOrgsFilteredByDivision = data

            this.getDivisions()
            this.getRegions()
        },
        async getDivisions() {
            let divisions = Array.from(new Set(this.orgSubGroupsBackup.map(x => x.orgDivision).filter(Boolean)))
            divisions.sort((a, b) => a.localeCompare(b))

            this.orgDivisions = divisions
            this.selectedDivisions = divisions
        },
        async getRegions() {
            let regions = Array.from(new Set(this.orgSubGroups.map(x => x.orgRegion).filter(Boolean)))
            regions.sort((a, b) => a.localeCompare(b))
            this.orgRegions = regions
            this.selectedRegions = regions
        },
        async filterByDivisions() {
            let groups = []
            let selectedDivisions = this.selectedDivisions

            if (this.selectedDivisions.length <= 0) {
                this.selectedSubOrg = []
                this.orgSubGroups = this.orgSubGroupsBackup
                return
            }

            if (this.orgDivisions.length > 0) {
                this.orgSubGroupsBackup.forEach((item) => {
                    if (selectedDivisions.includes(item.orgDivision)) {
                        groups.push({
                            id: item.id,
                            orgName: item.orgName,
                            orgAccountType: item.orgAccountType,
                            orgLogo: item.orgLogo,
                            orgDivision: item.orgDivision,
                            orgRegion: item?.orgRegion
                        })
                    }
                })
            } else {
                this.selectedSubOrg.forEach((item) => {
                    groups.push({
                        id: item.id,
                        orgName: item.orgName,
                        orgAccountType: item.orgAccountType,
                        orgLogo: item.orgLogo
                    })
                })
            }

            // this.orgSubGroups = groups
            // this.selectedSubOrg = groups
            this.subOrgsFilteredByDivision = groups
            this.runFilter();
        },
        async filterByRegions() {
            let groups = []

            // if (this.selectedRegions.length <= 0) {
            //   this.selectedSubOrg = []
            //   this.orgSubGroups = this.orgSubGroupsBackup
            //   return
            // }
            if (this.selectedRegions.length > 0) {
                this.orgSubGroupsBackup.forEach((item) => {
                    if (this.selectedRegions.includes(item.orgRegion)) {
                        groups.push({
                            id: item.id,
                            orgName: item.orgName,
                            orgAccountType: item.orgAccountType,
                            orgLogo: item.orgLogo,
                            orgDivision: item.orgDivision,
                            orgRegions: item.orgRegion
                        })
                    }
                })
            } else {
                this.selectedSubOrg.forEach((item) => {
                    groups.push({
                        id: item.id,
                        orgName: item.orgName,
                        orgAccountType: item.orgAccountType,
                        orgLogo: item.orgLogo
                    })
                })
            }

            // this.orgSubGroups = groups
            // this.selectedSubOrg = groups
            this.subOrgsFilteredByRegion = groups
            this.runFilter()
        },
        runFilter() {
            const subOrgsFilteredByRegionIds = new Set(this.subOrgsFilteredByRegion.map((org) => org.id))
            const commonSubOrgsFromFilters = this.subOrgsFilteredByDivision.filter(
                DivisionFilteredOrg => subOrgsFilteredByRegionIds.has(DivisionFilteredOrg.id)
            );
            this.selectedSubOrg = commonSubOrgsFromFilters;
        },
        selectAllOrgs() {
            let t = this
            if (t.checkAllOrgs === true) {
                t.orgGroups.forEach((item) => {
                    t.selectedOrgGroup.push({
                        id: item.id,
                        orgName: item.orgName,
                        orgAccountType: item.orgAccountType,
                        orgLogo: item.orgLogo
                    })
                })
            } else {
                t.selectedOrgGroup = []
                t.checkAllOrgs = false
            }
        },
        selectAllSubOrgs() {
            let t = this
            if (t.checkAllSubOrgs === true) {
                t.selectedSubOrg = t.orgSubGroups
            } else {
                t.selectedSubOrg = []
                t.selectedDivisions = []
                t.checkAllSubOrgs = false
            }
        },
        clearSelectedOrgGroupsFilter() {
            let t = this
            t.getOrgs()
        },
        clearSelectedSubOrgsFilter() {
            setTimeout(() => {
                this.selectedSubOrg = []
            }, 1)
        },
        clearSelectedDivisionsFilter() {
            this.selectedDivisions = []
        }
    },
    mounted() {
        this.getOrgs()
    }
}
</script>

<style scoped>
.chart {
    height: 100vh;
}
</style>

<style>
body {
    margin: 0;
}
</style>
