<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width:100% !important" >
        <v-row class="" dense style="z-index: 0 !important">
            <!-- Main Content -->
            <v-col  cols="12" class=" align-start rounded">
                <v-row class="pa-6 justify-center align-center pb-0">
                    <div class="flex-grow-1 pr-1">
                        <v-text-field
                            label="Search Actions "
                            v-model="searchQuery"
                            outlined
                            dense
                            hide-details="auto"
                            :disabled="addTask"
                            background-color="white"
                            color="primary"
                            clearable
                            prepend-inner-icon="oomph-search"
                            @click:clear="searchQuery = ''"
                        ></v-text-field>
                    </div>
                    <!-- <v-btn
                        @click="addNewTask()"
                        :disabled="addTask"
                        class="elevation-0 primary">+ Add</v-btn> -->
                </v-row>
                <v-row v-if="addTask == true" dense class="px-4 pt-3">
                    <v-card class="elevation-0 grey lighten-4 py-3 px-3" outlined>
                        <v-row dense>
                            <v-col class="d-flex align-center justify-center" cols="12" xs="12">
                                <div class="pl-1 font-weight-medium body-1 primary--text">New Task</div>
                                <v-spacer />
                                <v-btn
                                @click="saveNewTask()"
                                class="elevation-0 primary">Save</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-select label="Type" v-model="newAction.actionType" :items="actionTypes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('actionType')"></v-select>
                            </v-col>

                            <v-col cols="12" xs="12">
                                <v-text-field label="Subject" v-model="newAction.actionSubject" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('actionSubject')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-text-field label="Description" v-model="newAction.actionDescription" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('actionDescription')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" md="12">
                                <v-select label="Priority" v-model="newAction.actionPriority"  :items="priority" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('actionPriority')"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                   <template v-slot:activator="{ on, attrs }">
                                       <v-text-field label="Action Start Date" v-model="computedDate1"  outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('actionStartDate')"></v-text-field>
                                   </template>
                                   <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field label="Action End Date" v-model="computedDate2"  outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('actionEndDate')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="2" @input="dateMenu2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-menu v-model="dateMenu3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field label="Action Close Date" v-model="computedDate3"  outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('actionCloseDate')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date3" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu3 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card>
                    <!-- </v-col> -->
                </v-row>
                <v-row v-if="addTask == false" class="px-3 pt-0">
                    <v-col cols="12">
                        <v-btn-toggle multiple v-model="type" class="  d-flex align-center flex-row">
                            <v-btn class="flex-grow-1" value="Call" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Call' : ''"  size="20" color="grey" class="oomph-call"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Call</span>
                            </v-btn>
                            <v-btn class="flex-grow-1" value="Call Back" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Call Back' : ''" size="20" color="grey" class="oomph-callback"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Call Back</span>
                            </v-btn>
                            <v-btn class="flex-grow-1" value="Case" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Case' : ''"  size="20" color="grey" class="oomph-complaint"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Case</span>
                            </v-btn>
                            <v-btn class="flex-grow-1" value="Email" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Email' : ''"  size="20" color="grey" class="oomph-mail"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Email</span>
                            </v-btn>
                            <v-btn class="flex-grow-1" value="Event" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Event' : ''"  size="20" color="grey" class="oomph-calendar"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Event</span>
                            </v-btn>
                            <v-btn class="flex-grow-1" value="Meeting" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Meeting' : ''"  size="20" color="grey" class="oomph-meeting"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Meeting</span>
                            </v-btn>
                            <v-btn class="flex-grow-1" value="Task" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Task' : ''"  size="20" color="grey" class="oomph-tasklist"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Task</span>
                            </v-btn>
                            <v-btn class="flex-grow-1" value="Note" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Note' : ''"  size="20" color="grey" class="oomph-note"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Note</span>
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <!-- //* VIEW MODE -->
                <v-row v-if="addTask == false" class="pt-3" style="width: 100% !important" no-gutters>
                    <!-- <pre>{{ orgIsGroup }}</pre> -->
                    <v-col cols="12">
                        <v-card v-if="computedMonths.length > 0" class="elevation-0 grey lighten-3 pr-3" style="height: 83vh !important; overflow-y: scroll !important ">
                            <v-timeline
                                align-top
                                dense
                                v-for="(month, i) in computedMonths"
                                :key="i"
                                >
                                <v-timeline-item
                                    hide-dot
                                    class="pb-0 mb-0"
                                    v-if="JSON.stringify(computedMonthItems(month)) !== '[]'"
                                >
                                    <div class="font-weight-bold text-h6 grey--text">{{ month }}</div>
                                </v-timeline-item>
                                <v-timeline-item
                                    v-for="(item, i) in computedMonthItems(month)"
                                    :key="i"
                                    fill-dot
                                    flat
                                    :icon="item.icon"
                                    class="pb-2 elevation-0"
                                >
                                    <actionCard :datadown="item" :orgIsGroup="orgIsGroup" />
                                </v-timeline-item>
                            </v-timeline>
                        </v-card>
                        <div class="grey--text d-flex font-weight-bold text-h5 align-center justify-center" v-if="computedMonths.length == 0">
                            NO ACTIONS
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import actionCard from '@/views/sales/ActionCard.vue'
import companiesHouse from '@/views/organisations/CompaniesHouse.vue';
import schema from "@/schema.js";
import moment from 'moment';

export default {
    name: 'OrganisationDetails',
    components: {
        'companies-house': companiesHouse,
        'actionCard': actionCard,
    },
    props: {
        datadown: {
            type: Object,
			default: function () {
				return {};
			},
        },
        dense: {
            type: Boolean,
            default: false,
        },
        itemnew: {
            type: Boolean,
            default: false,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        orgtype: {
            type: String,
            default: '',
        },
        refreshItems: {
            type: Number,
            default: 0,
        },
        orgIsGroup: {
            type: Boolean, 
            default: false,
        },
        organisations: {
            type: Array, 
            default: () => [],
        }
    },
    // * DATA
    data: () => ({
        // Item
        actions: {},
        items: {},
        key: 'action',
        index: 'idx:actions',
        items: [],
        type: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        datatableOptions: {
            page: 1,
            itemsPerPage: 999,
            sortBy: ['actionStartDate'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        sortBy: '@actionStartDate',
        addTask: false,
        actionTypes: [],

        // Headers to display in the Data Table
        headers: [
            { text: 'Action', value: 'actionType', sortable: false, hidden: false, expanded: false },
            { text: 'Subject', value: 'actionSubject', sortable: false, hidden: false, expanded: false },
            { text: 'Description', value: 'actionDescription', sortable: false, hidden: false, expanded: false },
            { text: 'Status', value: 'actionStatus', sortable: false, hidden: false, expanded: false },
            { text: 'Priority', value: 'actionPriority', sortable: false, hidden: false, expanded: false },
            { text: 'Start Date', value: 'actionDate', sortable: false, hidden: false, expanded: true },
            { text: 'Start Date', value: 'actionCompletedDateTime', sortable: false, hidden: false, expanded: true },

            { text: 'Organisation', value: 'actionStageId', sortable: false, hidden: false, expanded: false },
            { text: 'Organisation', value: 'actionStageName', sortable: false, hidden: false, expanded: false },
            { text: 'Organisation', value: 'actionCompleted', sortable: false, hidden: false, expanded: false },
            { text: 'Organisation', value: 'orgId', sortable: false, hidden: false, expanded: false },
            { text: 'Organisation', value: 'orgName', sortable: false, hidden: false, expanded: false },
            { text: 'End Date', value: 'actionEndDate', sortable: false, hidden: false, expanded: false },
            { text: 'Organisation', value: 'actionTags', sortable: false, hidden: false, expanded: false },
            { text: 'Contact Tags', value: 'contactTags', sortable: false, hidden: true, expanded: true },
            { text: 'Opportunity', value: 'opportunityId', sortable: false, hidden: true, expanded: true },
            { text: 'Lead', value: 'leadId', sortable: false, hidden: true, expanded: false },
            { text: 'Call Type', value: 'actionCallType', sortable: false, hidden: true, expanded: false },
            { text: 'Call Duration', value: 'actionCallDuration', sortable: false, hidden: true, expanded: false },
            { text: 'Call Purpose', value: 'actionCallPurpose', sortable: false, hidden: true, expanded: false },
            { text: 'Call Result', value: 'actionCallResult', sortable: false, hidden: true, expanded: true },
            { text: 'Meeting Host', value: 'actionMeetingHost', sortable: false, hidden: true, expanded: false },
            { text: 'Meeting Participants', value: 'actionMeetingParticipants', sortable: false, hidden: true, expanded: false },
            { text: 'Meeting Type', value: 'actionMeetingType', sortable: false, hidden: true, expanded: false },
            { text: 'Meeting Location', value: 'actionMeetingLocation', sortable: false, hidden: true, expanded: true },
            { text: 'Event Host', value: 'actionEventHost', sortable: false, hidden: true, expanded: true },
            { text: 'Event Participants', value: 'actionEventParticipants', sortable: false, hidden: true, expanded: false },
            { text: 'Event Type', value: 'actionEventType', sortable: false, hidden: true, expanded: false },
            { text: 'Event Location', value: 'actionEventLocation', sortable: false, hidden: true, expanded: false },
            { text: 'Case Type', value: 'actionCaseType', sortable: false, hidden: true, expanded: false },

            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Created Name', value: 'createdUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Name', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Name', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Email', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        newAction: {},
        priority: ['Urgent', 'High', 'Medium', 'Low'],

		date1: moment().format("YYYY-MM-DD"),
		dateMenu1: false,
		date2: moment().add(1, "days").format("YYYY-MM-DD"),
		dateMenu2: false,
		date3: moment().add(1, "days").format("YYYY-MM-DD"),
		dateMenu3: false,

    }),
    computed: {
        ...mapGetters({
            GET_dense: 'GET_dense',
        }),
        inputCols() {
            if (this.listshrunk) {
                return '3';
            } else {
                return '6';
            }
        },
        computedMonths() {
            var t = this;


            const months = [];

            // ////console.log("items = " + JSON.stringify(this.items, null, 2))
            ////console.log("t.actions = " + JSON.stringify(t.actions, null, 2))
            // this.items = this.items.filter((action) => {
            //     return action.orgId === t.actions.id
            // })

            this.items = this.items.filter((action) => {
                return action.actionCompleted === '1'
            })

            this.items.sort((a, b) => b.actionCompletedDateTime.localeCompare(a.actionCompletedDateTime));
            
            ////console.log("items = " + JSON.stringify(this.items, null, 2))

            this.items.filter((item) => {
                var month = []
                month = t.$moment(item.actionCompletedDateTime, 'X').format('MMM')
                if (month !== 'Invalid date') {
                    return months.push(month);
                }
            });
            // // //console..log("motnhs = " + JSON.stringify(months, null, 2))

            let filteredMonths = [...new Set(months)];
            ////console.log("filteredMonths = " + JSON.stringify(filteredMonths, null, 2));
            
            return filteredMonths
        },
        computedSearchQuery() {
            var t = this
            let searchQuery = this.searchQuery;
            var orgs = {}

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                //     searchQuery = '*';
                // } else {
                    searchQuery = '@deleted:0';
                // }
            } else {
                if (searchQuery.startsWith('@')) {
                    // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    //     searchQuery = `${searchQuery}*`;
                    // } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    // }
                } else {
                    // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    //     searchQuery = `${searchQuery}*`;
                    // } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    // }
                }
            }
            if (this.orgIsGroup == true && this.organisations.length > 0) {
                orgs = this.organisations 
                orgs = orgs.map((y) => y.id).join("|")
                //console.log('orgs = ' + JSON.stringify(orgs, null, 2))
                searchQuery = `${searchQuery} @orgId:${orgs}`;
            } else {
                searchQuery = `${searchQuery} @orgId:${t.datadown.id}` 
            }

            //console.log('searchQuery actions = ' + searchQuery)



            return searchQuery;
        },
		computedDate1() {
			return this.$moment(this.date1, "YYYY-MM-DD").format("DD-MMM-YYYY");
		},
		computedDate2() {
			return this.$moment(this.date2, "YYYY-MM-DD").format("DD-MMM-YYYY");
		},   
		computedDate3() {
			return this.$moment(this.date3, "YYYY-MM-DD").format("DD-MMM-YYYY");
		},    
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            if (!computedFields.includes('@actionId')) {
                computedFields.push('@actionId');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
    },
    // * METHODS
    methods: {
        addNewTask() {
            var t= this;
            t.newAction = schema.action
            t.newAction.orgId = t.datadown.id
            t.newAction.orgName = t.datadown.orgName
            t.newAction.actionStageName = t.datadown.orgStageName
            t.newAction.actionStageId = t.datadown.orgStageId
            this.newAction.actionStartDate = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
            this.newAction.actionEndDate = this.$moment(this.date2, 'YYYY-MM-DD').format('X');
            this.newAction.actionCloseDate = this.$moment(this.date3, 'YYYY-MM-DD').format('X');
            
            
            t.addTask = true
        },
        async saveNewTask() {
            var t = this;

            var action = t.newAction

            // // // //console..log("saving action + " + JSON.stringify(action, null, 2))
            
			let result = await t.MIX_createItem(action, t.key, true);
			if (result.code === 1) {
				// t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
				// t.readItem(item.id);
				// t.$emit("refreshItems");
                // t.closeDialog()
                // // // //console..log('saved action')
                t.newAction = []
                t.addTask = false
                this.getItems()
			}

        },
        computedMonthItems(month) {
            var t = this;

            var items = t.items

            items.forEach((item) => {
                switch (true) {
                    case item.actionType === 'Call':
                        item.icon = 'oomph-call'
                        break;
                    case item.actionType === 'Call Back':
                        item.icon = 'oomph-callback'
                        break;
                    case item.actionType === 'Case':
                        item.icon = 'oomph-complaint'
                        break;
                    case item.actionType === 'Email':
                        item.icon = 'oomph-mail'
                        break;
                    case item.actionType === 'Event':
                        item.icon = 'oomph-calendar'
                        break;
                    case item.actionType === 'Meeting':
                        item.icon = 'oomph-meeting'
                        break;
                    case item.actionType === 'Task':
                        item.icon = 'oomph-tasklist'
                        break;
                    case item.actionType === '':
                        item.icon = ''
                        break;

                }
            })
            
            if (this.type !== null && this.type !== undefined && JSON.stringify(this.type) !== '[]') {
                items = this.items.filter((item) => this.type.includes(item.actionType));
            }

            // items = items.filter((action) => {
            //     return action.orgId === t.actions.id
            // })
            ////console.log("computedMonthItems = " + JSON.stringify(items, null, 2))

            items = items.filter((action) => {
                if ((t.$moment(action.actionCompletedDateTime, 'X').format('MMM')) === month) {
                    return action
                }
            })
            // items.sort((a, b) => a.actionDate.localeCompare(b.actionDate));

            return items
        },
		// * READ ITEM
		async readItem() {
			let t = this;
            var id = {...t.datadown}
            // // // // // //console..log("reading item " + JSON.stringify(id, null, 2))
			let result = await t.MIX_readItem(id.orgId, 'org');
			if (result.code === 1) {
				this.item = result.data;
                // // // // // //console..log("item = " + JSON.stringify(t.item, null, 2))
				t.itemNew = false;
				t.loading = false;
			}
		},
        // * GET ITEMS
        async getItems() {
			// // // // // //console..log('getItems running')
			// // // // // //console..log(this.index)
			// // // // // //console..log(this.itemsFrom)
			// // // // // //console..log(this.datatableOptions)
			// // // // // //console..log(this.computedFields)
			// // // // // //console..log(this.sortBy)
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                // //console..log("items = " + JSON.stringify(this.items, null, 2))
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            } else {
                //// // // // // //console..log("items = " + JSON.stringify(itemsResult, null, 2));
            }
        },
    },
    // * WATCH
    watch: {
        dateMenu1() {
            this.newAction.actionStartDate = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
           
        },
        dateMenu2() {
            this.newAction.actionEndDate = this.$moment(this.date2, 'YYYY-MM-DD').format('X');
            // this.checkDates();
        },
        dateMenu3() {
            this.newAction.actionCloseDate = this.$moment(this.date3, 'YYYY-MM-DD').format('X');
            // this.checkDates();
        },
        refreshItems() {
            this.getItems();
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
		datadown() {
            var t = this;
            t.actions = { ...t.datadown };
            t.getItems()
            // // // // // // //console..log("item customer = " + JSON.stringify(t.item, null, 2))
		},
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        organisations() {
            var t = this;
            t.getItems()
        }
    },
    // * CREATED
    async created() {
        let t = this;
        t.actions = { ...t.datadown };
		this.getItems();
        let actionTypes = await t.MIX_redisReadSet('set:actionTypes', false);
        if (actionTypes.code === 1) {
            t.actionTypes = actionTypes.data;
        }

    },
};
</script>

<style></style>
