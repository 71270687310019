<template>
    <v-row fluid fill-height class="d-flex flex-column align-start appbackground"
        style="width: calc(100vw - 56px) !important; position: fixed; height: 100vh !important; overflow-y: scroll !important"
        no-gutters>
        <v-col class="align-center pa-3 mt-1" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm"
            :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            <div class="d-flex align-center mb-1">
                <div class="d-flex align-center">
                    <div>
                        <v-icon class="primary--text" x-large>{{ orgIcon }}</v-icon>
                    </div>
                    <div class="text-left primary--text pl-1 text-h4 font-weight-thin">{{ orgtype }}s</div>
                </div>
                <v-spacer></v-spacer>
                <div class="ml-1">
                    <v-btn depressed block class="primary darken-2 white--text" @click="openItem({})">
                        <v-icon>{{ orgIcon }}</v-icon>
                        <span v-if="!listShrunk" class="ml-3">New</span>
                    </v-btn>
                </div>
                <div>
                    <v-btn class="white--text primary ml-2" @click="listShrunk = false" depressed v-if="listShrunk">
                        <v-icon color="white">oomph-show-sidepanel</v-icon>
                    </v-btn>
                    <v-btn class="white--text primary ml-2" @click="listShrunk = true" depressed
                        v-if="!listExpanded && !listShrunk">
                        <v-icon color="white">oomph-hide-sidepanel</v-icon>
                    </v-btn>
                    <v-btn class="primary--text ml-2" depressed v-if="!listShrunk" @click="downloadCSV">
                        <v-icon color="primary">oomph-export-csv</v-icon>
                    </v-btn>
                </div>
            </div>
            <organisation-list :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:openItem="openItem"
                :refreshitems="refreshItems" :orgtype="orgtype" @exportItems="exportCSV" />
        </v-col>
        <!-- Organisation Component -->
        <transition v-if="activeItem && fullAccountDialog == false" name="custom-classes-transition"
            enter-active-class="animate__animated animate__fadeIn" mode="out-in">
            <v-col style="z-index: 99 !important; border-left: #239eaf solid 1px !important; height: 100% !important"
                v-if="!listExpanded" class="grey lighten-3 pa-0 ma-0" cols="12" :xs="itemCols.xs" :sm="itemCols.sm"
                :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
                <Organisation v-on:refreshitems="refreshitems" :listshrunk="listShrunk" :datadown="activeItem"
                    v-on:closeitem="closeItem" v-on:fullAccount="openFullAccount" :orgtype="orgtype" />
            </v-col>
        </transition>
        <transition v-if="activeFullAccount" name="custom-classes-transition"
            enter-active-class="animate__animated animate__fadeIn" mode="out-in">
            <v-col v-if="fullAccountDialog" style="position: fixed !important" class="grey lighten-3 pa-0 ma-0" cols="12">
                <FullLead v-on:refreshItems="refreshitems" :listshrunk="listShrunk" :account="true"
                    :datadown="activeFullAccount" v-on:close="closeLeadDialog()" />
            </v-col>
        </transition>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Organisation from '@/views/organisations/Organisation.vue'
import FullLead from '@/views/sales/FullLead.vue'
import organisationList from '@/views/organisations/OrganisationList.vue'
export default {
    name: 'Organisations',
    components: {
        Organisation: Organisation,
        OrganisationList: organisationList,
        FullLead: FullLead
    },
    data: () => ({
        editMode: false,
        orgtype: '',
        orgIcon: '',
        headers: [
            { text: 'ID', align: 'start', value: 'id', hidden: true },
            { text: 'User', align: 'start', sortable: true, value: 'photoURL', hidden: false },
            { text: 'Name', value: 'userName', hidden: false },
            { text: 'Email', value: 'email', hidden: false },
            { text: 'User Level', value: 'userLevel', hidden: false },
            { text: 'User Status', value: 'userStatus', hidden: false },
            { text: 'Actions', value: 'actions', hidden: false }
        ],
        search: '',
        show: false,
        tab: '',
        orgs: [],
        orgDialog: false,
        refreshItems: 0,
        activeItem: {},
        extendWidth: '',
        routeId: '',
        // Item Columns
        itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
        taskCols: { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
        listExpanded: true,
        listShrunk: false,
        fullAccountDialog: false,
        activeFullAccount: {},
        downloadData: []
    }),
    computed: {
        ...mapGetters({
            GET_firebase_userAuth: 'GET_firebase_userAuth'
        }),
        computedHeaders() {
            return this.headers.filter((header) => !header.hidden)
        }
    },
    methods: {

        async downloadCSV() {
            // orgName,orgTelephone,orgContactName,orgCounty,orgPostcode,orgGroupStructure,id,deleted

            const content = this.downloadData.map((item) => {
                return {
                    orgName: item.orgName,
                    orgTelephone: item.orgTelephone,
                    orgContactName: item.orgContactName,
                    orgCounty: item.orgCounty,
                    orgPostcode: item.orgPostcode,
                    orgGroupStructure: item.orgGroupStructure
                }
            })

            const contentCSV = this.$papa.unparse(content);
            const dateNow = Math.floor(Date.now() / 1000)
            this.$papa.download(contentCSV, `${this.orgtype}_${dateNow}`, "text/csv");
        },
        async exportCSV(data) {
            this.downloadData = data
        },
        refreshCols() {
            switch (true) {
                // Expanded List with No Task Bar
                case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 } // 12
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 } // 0
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 } // 0
                    break
                // Expanded List with Task Bar
                case this.listExpanded && !this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 } // 10
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 } // 0
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 } // 2
                    break
                // List with Product Page (No Task Bar)
                case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 } // 6
                    this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 } // 6
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 } // 0
                    break
                // List with Product Page and Task Bar
                case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 } // 4
                    this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 } // 6
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 } // 2
                    break
                // Shrunk List with Product Page (No Task Bar)
                case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 } // 2
                    this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 } // 10
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 } // 0
                    break
                // Shrunk List with Product Page and Task Bar)
                case !this.listExpanded && this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 } // 2
                    this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 } // 8
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 } // 2
                    break
                default: // 12
                    // 8
                    this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }
                    this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 } // 0
            }
        },
        openItem(item) {
            this.activeItem = item
            this.listExpanded = false
        },
        openFullAccount(item) {
            this.activeFullAccount = item
            this.fullAccountDialog = true
        },
        closeLeadDialog() {
            let t = this
            t.fullAccountDialog = false
            this.activeFullAccount = {}
        },
        closeItem() {
            this.listExpanded = true
            this.listShrunk = false
        },
        refreshitems() {
            this.refreshItems++
            this.closeItem()
        },
    },
    watch: {
        listExpanded() {
            this.refreshCols()
        },
        listShrunk() {
            this.refreshCols()
        }
    },
    mounted() {
        this.routeId = this.$route.params.id
        this.orgtype = this.$route.name.slice(0, -1)
        switch (this.orgtype) {
            case 'Prospect':
                this.orgIcon = 'oomph-business-group'
                break
            case 'Customer':
                this.orgIcon = 'oomph-hotel-building'
                break
            case 'Supplier':
                this.orgIcon = 'oomph-factory'
                break
            default:
                this.orgIcon = 'oomph-city'
                break
        }
        if (this.routeId === 'new') {
            this.openItem({})
        } else if (this.routeId !== 'new' && this.routeId !== '' && this.routeId !== undefined) {
            this.openItem({ id: this.routeId })
        }
    }
}
</script>
