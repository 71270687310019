<template>
    <v-container class="pa-0 ma-0" fluid fill-height>
        <v-row>
            <v-col cols="12" class="d-flex align-center white pa-6">
                <v-col cols="6">
                    <div class="text-h4 font-weight-thin">{{ contentItem.contentTitle }}</div>
                </v-col>
                <v-col cols="2">
                    <v-rating
                        empty-icon="oomph-star"
                        full-icon="oomph-star-2"
                        length="5"
                        readonly
                        size="25"
                        background-color="grey"
                        color="primary"
                        :value="parseInt(contentItem.contentDifficultyRating)"
                        dense
                    ></v-rating>
                </v-col>
                <v-col cols="2">
                    <v-row class="d-flex justify-center align-center">
                        <v-icon v-if="contentItem.contentDifficultyRating === 'Beginner'" size="28" color="success">oomph-speedometer</v-icon>
                        <v-icon v-if="contentItem.contentDifficultyRating === 'Intermediate'" size="28" color="warning">oomph-speedometer</v-icon>
                        <v-icon v-if="contentItem.contentDifficultyRating === 'Advanced'" size="28" color="error">oomph-speedometer</v-icon>
                        <div v-if="contentItem.contentDifficultyRating === 'Beginner'" class="body-1 px-2 success--text">{{ contentItem.contentDifficultyRating }}</div>
                        <div v-if="contentItem.contentDifficultyRating === 'Intermediate'" class="body-1 px-2 warning--text">{{ contentItem.contentDifficultyRating }}</div>
                        <div v-if="contentItem.contentDifficultyRating === 'Advanced'" class="body-1 px-2 error--text">{{ contentItem.contentDifficultyRating }}</div>
                    </v-row>
                </v-col>
                <v-col cols="2" class="d-flex justify-center align-center">
                    <v-icon size="28" color="success">oomph-good-quality</v-icon>
                    <div class="text-h6 px-2">{{ contentItem.contentLikes }}</div>
                    <v-icon size="28" color="error">oomph-unlike</v-icon>
                    <div class="text-h6 px-2">{{ contentItem.contentDislikes }}</div>
                </v-col>
            </v-col>
        </v-row>
        <v-col class="d-flex pa-0 ma-0" cols="12" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <v-card class="elevation-0 rounded-0" width="100%">
                <v-divider></v-divider>
                    <v-card-text class="d-flex justify-center">
                        <mux-video
                            :playback-id="video.playbackId"
                            :metadata-video-title="video.title"
                            :poster="video.poster"
                            height="530"
                            stream-type="on-demand"
                            preload
                            prefer-mse
                            controls
                        ></mux-video>
                    </v-card-text>
                <!-- <v-card-text class="pa-0">
                    <v-col cols="12" class="d-flex justify-start align-center pt-1"> 
                        <v-btn @click="$emit('close')" depressed small><v-icon>oomph-back</v-icon></v-btn>
                    </v-col>
                </v-card-text> -->
            </v-card>
        </v-col>
        <v-col cols="12" class="ma-0 pa-0">
            <v-img max-height="10vh" :src="coverImage">
                <v-row class="d-flex justify-start">
                    <!-- <v-img max-height="180" max-width="180" :src="require('@/assets/oomphlogoalt.png')" contain></v-img> -->
                </v-row>
            </v-img>
        </v-col>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Video',
    props: {
        showContentItem: {
            type: Boolean,
            default: false,
        },
        contentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        // contentItems: {
        //     type: Array,
        //     default: () => {
        //         return [];
        //     },
        // },
    },
    data: () => ({
        coverImage: '',
        instructions: '',
        player: null,
        tags: [],
        video: {
            title: '',
            poster: '',
            playbackId: '',
            envKey: process.env.VUE_APP_MUX,
        }
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
            GET_brand: 'GET_brand',
        }),
    },
    methods: {
        async getContentItems() {
            let t = this;
            //* Filter all content items
            let result = await t.MIX_redisSearch('idx:contentItems', `@itemContentId:{${t.contentItem.id}}`, 0, 9999);
            if (result.code === 1) {
                t.contentItems = result.data.documents;
            }
            t.contentItems.filter((item) => {
                //* Get video
                if (item.value.itemType === 'Video') {
                    t.video.playbackId = item.value.itemPlaybackId;
                    t.video.title = item.value.itemName;
                    t.video.poster = `https://image.mux.com/${item.value.itemPlaybackId}/thumbnail.jpg`;
                }
                //* Get cover images
                if (item.value.itemMarker === 'Cover Image') {
                    t.coverImage = item.value.itemURL;
                }
                //* Get PDF items
                if (item.value.itemType === 'PDF') {
                    t.pdfURL = item.value.itemURL;
                }
            });
        },
        getTags() {
            let t = this;
            const array = t.contentItem.contentTags.split(',');
            return (t.tags = array);
        },
        handleDocumentRender() {
            this.isLoading = false;
            this.pageCount = this.$refs.pdfRef.pageCount;
        },
    },
    mounted() {
        let t = this;
        t.getContentItems();
        t.getTags();
    },
};
</script>