<template>
    <div>
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field 
                    label="Search release notes" 
                    v-model="searchQuery" 
                    outlined 
                    dense 
                    hide-details="auto" 
                    background-color="white" 
                    color="primary" 
                    clearable 
                    prepend-inner-icon="oomph-search" 
                    @click:clear="clearSearchQuery()"></v-text-field>
            </div>          
            <div v-if="!listshrunk" class="ml-2">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="refreshItems">
                    <v-icon>oomph-available-updates</v-icon>
                </v-btn>
            </div>
            <div v-if="!listshrunk" class="ml-2">
                <v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>oomph-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <div class="mt-2">
            <v-data-table 
                v-if="!listshrunk" 
                dense
                :headers="computedHeaders" 
                :items="computedItems" 
                :server-items-length="itemsTotal" 
                :options.sync="datatableOptions" 
                :search="search">
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:[`item.releaseDate`]="{ item }">
                    <span>{{ $moment.unix(item.releaseDate).format('DD/MM/YYYY') }}</span>
                </template>
                <template v-slot:[`item.releaseNote`]="{ item }">
                    <span v-html="item.releaseNote.slice(0, 50)"></span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
        homeLogin: {
            type: Boolean,
            default: false,
        },
        homeUser: {
            type: Boolean,
            default: false,
        },
        groupUser: {
            type: Boolean,
            default: false,
        },
        streamUser: {
            type: Boolean,
            default: false,
        },
        orgtype: {
            type: String,
            default: "",
        },
        orgid: {
            type: String,
            default: "",
        },
    },
    data: () => ({
        key: "releaseNote",
        index: "idx:releaseNotes",
        toggleDeletedItems: false,
        tagOrgAccountTypes: "",
        tagOrgTags: "",
        filter: {
            orgId: "",
            userLevel: "",
            userStatus: "",
            userGroup: ""
        },
        toggleFeatures: [],
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: "",
        itemsFrom: 0,
        itemsTo: 9,
        headers: [
            { text: "ID", align: "start", value: "id", hidden: true, expandable: true },
            { text: "Release Date", align: "start", value: "releaseDate", hidden: false, sortable: true, expandable: true },
            { text: "Release Version", align: "start", value: "releaseVersion", hidden: false, sortable: true, expandable: true },
            { text: "Release Note", align: "start", value: "releaseNote", hidden: false, sortable: true, expandable: true },
            { text: 'Action', value: 'action', align: 'end', sortable: false, hidden: false, expandable: true, width: '155px' },
        ],
        sortDesc: false,
        datatableOptions: {
            page: 1,
            itemsPerPage: 12,
            sortBy: ["id"],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        search: "",
        showDeleted: false,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
        }),
        computedSortBy() {
            return "@" + this.datatableOptions.sortBy[0];
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
                if (this.showDeleted == true) {
                    searchQuery = "*";
                } else {
                    searchQuery = "@deleted:0";
                }
            } else {
                if (searchQuery.startsWith("@")) {
                    if (this.showDeleted == true) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.showDeleted == true) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }
            if (this.homeUser == true) {
                searchQuery = `${searchQuery} @orgId:{${this.orgid}} @userLevel:{HomeUser|HomeManager|HomeAdmin}`;
            }
            if (this.homeLogin == true) {
                searchQuery = `${searchQuery} @orgId:{${this.orgid}} @userLevel:{HomeLogin}`;
            }
            if (this.groupUser == true) {
                searchQuery = `${searchQuery} @orgId:{${this.orgid}} @userLevel:{GroupUser}`;
            }
            if (this.streamUser == true) {
                searchQuery = `${searchQuery} @orgId:{${this.orgid}} @userLevel:{StreamUser}`;
            }

            if (this.filter.orgId !== "" && this.filter.orgId !== null && this.filter.orgId !== undefined) {
                searchQuery = `${searchQuery}  @orgId:{${this.filter.orgId}}`;
            }
            if (this.filter.userLevel !== "" && this.filter.userLevel !== null && this.filter.userLevel !== undefined) {
                searchQuery = `${searchQuery}  @userLevel:{${this.filter.userLevel}}`;
            }
            if (this.filter.userStatus !== "" && this.filter.userStatus !== null && this.filter.userStatus !== undefined) {
                searchQuery = `${searchQuery}  @userStatus:{${this.filter.userStatus}}`;
            }
            if (this.filter.userGroup !== "" && this.filter.userGroup !== null && this.filter.userGroup !== undefined) {
                searchQuery = `${searchQuery}  @userGroup:{${this.filter.userGroup}}`;
            }
            return searchQuery;
        },
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden);
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expandable !== false);
            }
            return headers;
        },
        computedItems() {
            var items = this.items;
            return items;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => "@" + y.value);
            if (!computedFields.includes("@id")) {
                computedFields.push("@id");
            }
            if (!computedFields.includes("@deleted")) {
                computedFields.push("@deleted");
            }
            computedFields = computedFields.join(",");
            return computedFields;
        },
    },
    methods: {
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        openItem(item) {
            this.$emit("openItem", item);
        },
        refreshItems: function () {
            this.getItems();
        },
        async getItems() {
            this.loading = true;
            if (this.datatableOptions.itemsPerPage === -1) {
                this.datatableOptions.itemsPerPage = this.itemsTotal;
            }
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.computedSortBy, this.sortDesc);
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                if (this.datatableOptions.itemsPerPage === "-1") {
                    this.datatableOptions.itemsPerPage = this.itemsTotal;
                }
                this.items = itemsResult.data.results;
                let itemsTo = Number(this.datatableOptions.page) * Number(this.datatableOptions.itemsPerPage);
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            }
            this.loading = false;
        },
        clearSearchQuery() {
            this.search = "";
        },
    },
    watch: {
        refreshitems() {
            this.getItems();
        },
        computedSearchQuery() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === "productMainImage");
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
        listexpanded() {
            if (this.listexpanded === false) {
                this.datatableOptions.itemsPerPage = 15;
                this.itemsFrom = 0;
                this.getItems();
            } else if (this.listexpanded === true) {
                this.datatableOptions.itemsPerPage = 15;
                this.itemsFrom = 0;
                this.getItems();
            }
        },
    },
    async created() {
        let t = this;
        this.getItems();
    },
};
</script>
