<template>
	<v-row class="pa-0 ma-0" fill-height>
        <v-row no-gutters class="pb-3 px-3 pt-3 primary darken-2">
            <v-col class="font-weight-bold white--text text-h6 d-flex align-center" v-if="JSON.stringify(item) !== '{}'">
                <div class="mx-3 mb-1"><v-icon class="white--text">oomph-index</v-icon></div>
				<div>
					<span class="font-weight-light"><strong>Review Questions: </strong>{{ item.reviewQuestionsTemplate }}</span>
				</div>
				<div class="ml-2 font-weight-light" v-if="newReviewQuestions">New</div>
				<v-spacer></v-spacer>
				<div>
					<v-btn class="ml-2 white--text" plain @click="closeReviewQuestion" depressed>
						<v-icon>oomph-multiply</v-icon>
					</v-btn>
				</div>
            </v-col>
        </v-row>
		<v-col class="pa-0" fill-height cols="auto" style="width: 100% !important">
			<v-tabs background-color="white" v-model="tab" show-arrows>
				<v-tab href="#details">Details</v-tab>
				<v-tab :disabled="newReviewQuestions" href="#questions">Questions ({{ item.questionTotal }})</v-tab>
			</v-tabs>
			<!-- newReviewQuestions {{newReviewQuestions}} -->
			<v-tabs-items v-model="tab" fill-height>
				<!-- Details Tab -->
				<v-tab-item value="details" class="grey lighten-4">
					<reviewquestion-details :reviewquestion="item" v-on:save="saveReviewQuestion" v-on:update="updateReviewQuestion" :newreviewquestions="newReviewQuestions" v-on:close="closeReviewQuestion" v-on:cancel="cancelReviewQuestions" />
				</v-tab-item>
				<!-- Details Tab -->
				<v-tab-item value="questions" class="grey lighten-4">
					<questions :reviewquestion="item" v-on:save="saveReviewQuestion" v-on:update="updateReviewQuestion" :newreviewquestions="newReviewQuestions" v-on:close="closeReviewQuestion" v-on:cancel="cancelReviewQuestions" />
				</v-tab-item>
			</v-tabs-items>
		</v-col>
	</v-row>
</template>

<script>
	import { mapGetters } from "vuex";
	import ReviewQuestionDetails from "@/views/reviews/ReviewQuestionDetails.vue";
	import Questions from "@/views/reviews/Questions.vue"
	import schema from "@/schema.js";
	export default {
		// * COMPONENTS
		components: {
			"reviewquestion-details": ReviewQuestionDetails,
			"questions": Questions
		},
		// * PROPS
		props: {
			activereviewquestion: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			key: "reviewQuestions",
			tab: "details",
			schema: null,
			// Item
			item: {},
			newReviewQuestions: false,
			editMode: false,

			// Form items
			accountTypes: [],
			addressTypes: [],
			industries: [],

			// Tags
			computedOrgTags: [],

			// Validation
			rules: {},

			// Toggles
			addAddressToggle: false,
			addManualAddress: false,
			addOtherAddressToggle: false,
			addLeadStatusToggle: false,
			addOrgInfoToggle: false,
			addSocialInfoToggle: false,
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
			}),
		},
		// * METHODS
		methods: {
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				// //console.log('t.activereviewquestion = ', JSON.stringify(t.activereviewquestion, null, 2));
				if (JSON.stringify(t.activereviewquestion) !== "{}") {
					this.readItem(t.activereviewquestion.id);
					t.editMode = false;
					t.newReviewQuestions = false;
				} else {
					t.tab = 'details';
					t.newReviewQuestions = true;
					t.editMode = true;
					t.item = { ...t.schema };
					//t.item.id = t.MIX_generateId();
				}
			},
			// * CREATE ITEM
			async saveReviewQuestion(item) {
				let t = this;                
				// //console.log('saveReviewQuestion', item);
				let result = await t.MIX_createItem(item, t.key, true);
				// //console.log('saveReviewQuestion', JSON.stringify(result, null, 2));
				if (result.code === 1) {
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
					t.readItem(item.id);
					t.$emit("refreshlist");
				}
			},
			async updateReviewQuestion(item) {
				// //console.log('updateReviewQuestion');
				let t = this;                
				// //console.log('updateReviewQuestion', JSON.stringify(item, null, 2));
				let result = await t.MIX_createItem(item, t.key, false);
				// //console.log('result = ' + JSON.stringify(result, null, 2))
				if (result.code === 1) {
					t.readItem(item.id);
					t.$emit("refreshlist");
				}
			},
			// * READ ITEM
			async readItem(itemId) {
				let t = this;
				let result = await t.MIX_readItem(itemId, t.key);
				if (result.code === 1) {
					this.item = {};
					this.item = result.data;
					t.newReviewQuestions = false;
					t.loading = false;
				}
			},
			// * DESTROY ITEM
			async destroyItem() {
				let result = await this.MIX_destroyItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * DELETE ITEM
			async deleteItem() {
				let result = await this.MIX_deleteItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * RESTORE ITEM
			async restoreItem() {
				let result = await this.MIX_restoreItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// Close Item
			closeReviewQuestion() {
				this.$emit("close");
			},
			cancelReviewQuestions() {
				// //console.log('cancelReviewQuestions')
				this.initialiseItem();
//				this.readItem(this.item.id);
			}
		},
		// * WATCH
		watch: {
			// reviewquestion Changes
			activereviewquestion() {
				this.initialiseItem();
			},
			computedOrgTags(val) {
				var t = this;
				var splitVal = [];
				splitVal = t.item.orgTags.split(",");

				// //console.log(JSON.stringify(val, null, 2));
				return splitVal;
			},
		},
		// * CREATED
		async created() {
			let t = this;
			t.loading = true;
			t.schema = schema.reviewQuestions;
			// //console.log('t.schema = ', JSON.stringify(t.schema, null, 2))
			t.initialiseItem();
		},
		// * MOUNTED
		async mounted() {},
	};
</script>

<style scoped>
	.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
		height: 22px;
	}
</style>
