<template>
    <div class="" style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="mx-3 d-flex align-center justify-end" style="width: 100% !important">
                    <v-btn text class="primary white--text" @click="dialogComponent = true"><v-icon class="mr-2">oomph-to-do</v-icon>Add Component</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row dense class="mx-2">
            <v-col cols="12">
                <component-list :datadown="packageItem" v-on:openItem="openItem" :listshrunk="listshrunk" :refreshitems="refreshitems" />
            </v-col>
        </v-row>
        <v-dialog v-model="dialogComponent" max-width="1024px" >
            <component-item :datadown="packageItem" :activeitem="activeItem" :key="dialogComponentKey" v-on:close="closeComponent"/>
        </v-dialog>
    </div>
</template>

<script>
import component from "@/views/components/ComponentItem.vue";
import componentList from "@/views/components/ComponentItemList.vue";
export default {
    // * COMPONENTS
    components: {
        "component-item": component,
        "component-list": componentList
    },
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        key: 'action',
        // toggles

        activeItem: {},

        // dialogs
        dialogComponent: false,
        dialogComponentKey: 0,

        packageItem: {},

        // dropdowns
        actionTypes: [],

        refreshitems: 0

    }),
    // * COMPUTED
    computed: {
    },
    // * METHODS
    methods: {
        closeComponent() { 
            this.activeItem = {};
            this.dialogComponentKey++
            this.dialogComponent = false; 
            this.refreshitems++
        },
        openItem(value) {
            this.activeItem = value;
            this.dialogComponent = true;
        },
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.packageItem = { ...this.datadown };
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        this.packageItem = { ...this.datadown };
    },
};
</script>