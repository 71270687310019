<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important"
        :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div
                        class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">
                        Loading</div>
                </div>
                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="editmode" @click="editmode = true"><v-icon
                            class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="!editmode" @click="saveItem()"><v-icon
                            class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text px-3" dense style="z-index: 0 !important">
            <v-col v-if="!loading" cols="12" class="d-flex pt-4 pb-4 align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- DETAILS EDIT -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Release Version</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-text-field label="Release Version" v-model="item.releaseVersion"
                                        :rules="rules.releaseVersion" outlined dense hide-details="auto"
                                        background-color="white" color="primary" clearable
                                        @click:clear="clearItem('releaseVersion')"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Release Date</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-menu v-model="datePicker" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="computedDate" label="Release Date" outlined dense
                                                hide-details="auto" background-color="white" color="primary" readonly
                                                v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date"
                                            @input="datePicker = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Release Notes</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <vue-editor
                                        style="max-height: 370px; min-height: 200px; overflow-y: scroll"
                                        v-model="item.releaseNote" :editorToolbar="customToolbar"
                                        class="white"></vue-editor>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import schema from '@/schema.js';
import moment from 'moment';
import { VueEditor } from 'vue2-editor';

export default {
    name: 'ReleaseDetails',
    components: {
        VueEditor,
    },
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        activeitem: {
            Type: Object,
            Default: null,
        },
    },
    data: () => ({
        customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'align': '' }, { 'align': 'justify' }, { 'align': 'right' }],
            [{ 'header': [false, 1, 2, 3, 4, 5, 6,] }],
            ['link'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean'],
        ],
        date: moment().format("YYYY-MM-DD"),
        datePicker: false,
        editmode: false,
        item: {},
        itemNew: true,
        key: 'releaseNote',
        loading: true,
        rules: {},
        schema: {},
    }),
    computed: {
        computedDate() {
			return this.$moment(this.date, "YYYY-MM-DD").format("DD-MMM-YYYY");
		},
    },
    watch: {
        datadown: {
            handler: function () {
                this.item = { ...this.datadown };
                this.item.releaseDate = this.$moment.unix(this.item.releaseDate).format('DD/MM/YYYY');
            },
            deep: true,
        },
        datePicker() {
            this.item.releaseDate = this.$moment(this.date, 'YYYY-MM-DD').format('X');
        },
    },
    methods: {
        updateItem() {
            this.$emit('update', this.item);
        },
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        async saveItem() {
            this.$emit('save', this.item);
        },
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    async created() {
        let t = this;
        t.schema = schema.package;
        t.loading = true
        t.item = { ...t.datadown };
        t.loading = false
    },
};
</script>