import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from "@/store.js";
import { doc, getDoc } from "firebase/firestore";
import vuetify from './plugins/vuetify/vuetify';
import "animate.css";

import './registerServiceWorker'
import '@/registerServiceWorker';
import '@mux/mux-audio';
import '@mux/mux-video';
import "@/assets/css/styles.min.css";
import * as VueGoogleMaps from "vue2-google-maps";
import AlertBox from "@/components/alert/AlertBox.vue";
import appCopyright from "@/views/base/appCopyright.vue";
import AppUpdate from '@/components/appupdate/AppUpdate.vue';
import appupdate_mixin from '@/components/appupdate/appupdate_mixin.js';
import axios from "axios";
import Clipboard from 'v-clipboard'
import confirmDialog from "@/views/base/confirmDialog.vue";
import FileUpload from "@/components/fileupload/FileUpload";
import firebase_auth_mixin from "@/plugins/firebase/firebase_auth_mixin.js";
import firebase_firestore_mixin from "@/plugins/firebase/firebase_firestore_mixin.js";
import mixin from "./mixin.js";
import moment from "moment";
import redisMixin from "@/plugins/redis/mixin.js";
import VueApexCharts from 'vue-apexcharts'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

require('./components/appupdate/appupdate_import.js');
const firebase = require("@/plugins/firebase/firebase-config.js");
import { saveAs } from 'file-saver';
import VuePapaParse from 'vue-papa-parse'
Vue.component('apexchart', VueApexCharts)
Vue.component('AppUpdate', AppUpdate);
Vue.component("AlertBox", AlertBox);
Vue.component("appCopyright", appCopyright);
Vue.component("confirm-dialog", confirmDialog);
Vue.component("FileUpload", FileUpload);
Vue.component("VuePdfEmbed", VuePdfEmbed);
Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.$firebase = firebase;
Vue.prototype.$firebase = firebase;
Vue.prototype.$moment = moment;
Vue.prototype.$saveAs = saveAs;
Vue.use(appupdate_mixin);
Vue.use(Clipboard);
Vue.use(firebase_auth_mixin);
Vue.use(firebase_firestore_mixin);
Vue.use(redisMixin);
Vue.use(mixin);
Vue.use(VueApexCharts)
Vue.use(VuePapaParse)
Vue.use(VuetifyGoogleAutocomplete, {
    vueGoogleMapsCompatibility: true,
});
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDixX6ZmVfJCu2e2vdGwOGQwv2i1uEXna0",
        libraries: "places",
    },
});

// let tokenRefreshInterval;


function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

firebase.auth.onAuthStateChanged(async (user) => {
    if (user) {
        await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAuth", user);
        
        // if (user !== null) {
        //     user.getIdToken(true).then(async (idToken) => {
        //         await store.dispatch("firebase_auth_store/ACT_FIREBASE_apiHeaderToken", idToken);
        //         var token = parseJwt(idToken)
        //         await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAccessToken", token);
        //     })
        // }

        // add
        if (user !== null) {
            const refreshToken = async () => {
                user.getIdToken(true).then(async (idToken) => {
                    await store.dispatch("firebase_auth_store/ACT_FIREBASE_apiHeaderToken", idToken);
                    var token = parseJwt(idToken)
                    // console.log("refreshToken: ", token)
                    // console.log("refreshToken: ", idToken)
                    await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAccessToken", token);
                })
            }
            refreshToken();
            tokenRefreshInterval = setInterval(async () => {
                await refreshToken();
            }, 1000 * 3500)

            store.dispatch('setTokenRefreshInterval', tokenRefreshInterval);

        }
        
        await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAuth", user.multiFactor.user);
        const docRef = doc(firebase.db, "users", user.multiFactor.user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            // await store.dispatch("firebase_auth_store/ACT_FIREBASE_userAuth", docSnap.status);
        }
    }
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
