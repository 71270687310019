<template>
	<v-row class="pa-0 ma-0" fill-height>
        <v-row no-gutters class="pb-3 px-3 pt-3" :class="item.orgAccountOnHold === '1' ? 'warning' : 'primary darken-2'">
            <v-col class="font-weight-bold white--text text-h6 d-flex align-center" v-if="JSON.stringify(item) !== '{}'">
                <div class="mx-3 mb-1"><v-icon class="white--text">oomph-calendar</v-icon></div>
				<div>
					<span class="font-weight-light"><strong>Event: </strong>{{ item.eventTitle }}</span>
				</div>
				<div class="ml-2 font-weight-light" v-if="item.eventTitle === ''">New</div>
				<div>
					<v-chip class="font-weight-light ml-2">{{ item.eventStatus }}</v-chip>
				</div>
				<v-spacer></v-spacer>
				<div>
					<v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
						<v-icon>oomph-multiply</v-icon>
					</v-btn>
				</div>
            </v-col>
        </v-row>
		<v-col class="pa-0" fill-height cols="auto" style="width: 100% !important">
			<v-tabs background-color="white" v-model="tab" show-arrows>
				<v-tab href="#details">Details</v-tab>
				<v-tab :disabled="itemNew" href="#images">Images</v-tab>
				<v-tab :disabled="itemNew" href="#content">Content Links</v-tab>
				<v-tab v-if="item.eventLiveStream === '1'" :disabled="itemNew" href="#downloads">Downloads</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab" fill-height>
				<!-- Details Tab -->
				<v-tab-item value="details" class="grey lighten-4">
					<event-details dense :datadown="item" :newItem="itemNew" :editmode="editMode" v-on:save="createItem" :listshrunk="listShrunk" />
				</v-tab-item>
				<!-- Images Tab -->
				<v-tab-item value="images" class="grey lighten-4">
                    <event-images :originalEventId="item.id" :eventItem="item" :newItem="itemNew" :editMode="editMode" />
                </v-tab-item>
				<!-- Content Links Tab -->
				<v-tab-item value="content" class="grey lighten-4">
					<event-content-links :datadown="item" v-on:save="createItem" :listshrunk="listShrunk" v-on:updatevent="updateItem" />
				</v-tab-item>
				<v-tab-item value="downloads" class="grey lighten-4">
					<event-downloads :key="eventDownloadsKey" :datadown="item" v-on:updatevent="updateItem" />
				</v-tab-item>
			</v-tabs-items>
		</v-col>
	</v-row>
</template>

<script>
	import { mapGetters } from "vuex";
	import eventDetails from "@/views/events/EventDetails.vue";
	import eventImages from "@/views/events/EventImages.vue";
	import eventContentLinks from "@/views/events/EventContentLinks.vue";
	import eventDownloads from "@/views/events/EventDownloads.vue";
	import schema from "@/schema.js";
	export default {
		name: "Event",
		components: {
			"event-details": eventDetails,
			"event-images": eventImages,
			"event-content-links": eventContentLinks,
			"event-downloads": eventDownloads,
		},
		props: {
			datadown: {
				type: Object,
				default: function () {
					return {};
				},
			},
            listShrunk: {
                type: Boolean,
                default: false,
            },
		},
		// * DATA
		data: () => ({
			accountTypes: [],
			addAddressToggle: false,
			addLeadStatusToggle: false,
			addManualAddress: false,
			addOrgInfoToggle: false,
			addOtherAddressToggle: false,
			addressTypes: [],
			addSocialInfoToggle: false,
			computedOrgTags: [],
			eventDownloadsKey: 0,
			editMode: false,
			industries: [],
			item: {},
			itemNew: false,
			key: "event",
			rules: {},
			schema: null,
			tab: "details",
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
			}),
			computedFullName() {
				return this.item.contactFirstName + " " + this.item.contactLastName;
			},
		},
		// * METHODS
		methods: {
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				if (JSON.stringify(t.datadown) !== "{}") {
					this.readItem(t.datadown.id);
				} else {
					t.itemNew = true;
					t.editMode = true;
					t.item = { ...t.schema };
					t.item.id = t.MIX_generateId();
				}
			},
			// * VALIDATION RULES
			// TODO - RG - I think this needs to be moved to contactDetails.vue
			validateItem() {
				let t = this;
				t.rules = {
					// GENERAL DETAILS
					orgName: [(v) => !!v || "Organisation Name Required"],
				};
				t.$nextTick(() => {
					if (t.$refs.form.validate()) {
						this.createItem();
						this.editMode = false;
					} else {
						this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
					}
				});
			},
			// * CREATE ITEM
			async createItem(item) {
				let t = this;
				let result = await t.MIX_createItem(item, t.key, t.itemNew);
				if (result.code === 1) {
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
					t.readItem(item.id);
                    t.editMode = false
					t.$emit("refreshitems");
				}
			},
			// * READ ITEM
			async readItem(itemId) {
				let t = this;
				let result = await t.MIX_readItem(itemId, t.key);
				if (result.code === 1) {
					this.item = result.data;
					t.itemNew = false;
					t.loading = false;
				}
			},
			// * Update ITEM
			async updateItem(item) {
				let t = this;
				let result = await t.MIX_createItem(item, t.key, false);
				if (result.code === 1) {
					t.readItem(item.id);
					t.eventDownloadsKey++;
					t.$emit("refreshitems");
				}
			},
			// * DESTROY ITEM
			async destroyItem() {
				let result = await this.MIX_destroyItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * DELETE ITEM
			async deleteItem() {
				let result = await this.MIX_deleteItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * RESTORE ITEM
			async restoreItem() {
				let result = await this.MIX_restoreItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// Close Item
			closeItem() {
				this.$emit("closeitem");
			},
		},
		// * WATCH
		watch: {
			// Datadown Changes
			datadown() {
				this.initialiseItem();
			},
			computedOrgTags() {
				var t = this;
				var splitVal = [];
				splitVal = t.item.orgTags.split(",");

				//// //console.log(JSON.stringify(val, null, 2));
				return splitVal;
			},
		},
		// * CREATED
		async created() {
			let t = this;
			t.loading = true;
			t.schema = schema.event;
			t.initialiseItem();

			let accountTypes = await t.MIX_redisReadSet("set:orgAccountTypes", false);
			if (accountTypes.code === 1) {
				t.accountTypes = accountTypes.data;
			}

			let addressTypes = await t.MIX_redisReadSortedSet("sset:addressTypes", 0, 9999);
			if (addressTypes.code === 1) {
				t.addressTypes = addressTypes.data;
			}

			let industries = await t.MIX_redisReadSet("set:industries", true);
			if (industries.code === 1) {
				t.industries = industries.data;
			}
		},
		// * MOUNTED
		async mounted() {},
	};
</script>

<style scoped>
	.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
		height: 22px;
	}
</style>
