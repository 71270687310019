<template>
    <div class="" style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow-y: scroll !important">
        <!-- Menu Buttons -->
         <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey lighten-1 py-4">
                <div class="mx-3 d-flex align-center justify-end" style="width: 100% !important;">
                    <v-btn text class=" lighten-2 secondary--text mr-2" v-if="editmode" @click="cancelItem"><v-icon class="mr-2">oomph-multiply</v-icon>Cancel</v-btn>
					<div v-if="org.orgGroupStructure == 'Home' || org.orgGroupStructure == 'Independent home'" class="d-flex justify-end pr-3">
						<v-btn depressed outlined class="primary white--text" @click="addHomeUserDialog = true"><v-icon class="mr-2">oomph-business-group</v-icon>Add Home User</v-btn>
					</div>
                    <div v-if="org.orgAccountTypes.includes('Partner')" class="d-flex justify-end">
						<v-btn depressed outlined class="primary white--text" @click="addStreamUserDialog = true"><v-icon class="mr-2">oomph-business-group</v-icon>Add Stream User</v-btn>
					</div>
                    <div v-if="org.orgGroupStructure == 'Home' || org.orgGroupStructure == 'Independent home'" class="d-flex justify-end ">
						<v-btn depressed outlined class="primary white--text" @click="addHomeLoginDialog = true"><v-icon class="mr-2">oomph-business-group</v-icon>Add Home Login</v-btn>
					</div>
                    <div v-if="org.orgGroupStructure == 'Group' || org.orgGroupStructure == 'Parent Group'" class="d-flex justify-end ">
						<v-btn depressed outlined class="primary white--text" @click="addGroupUserDialog = true"><v-icon class="mr-2">oomph-business-group</v-icon>Add Group User</v-btn>
					</div>
                </div>
            </v-col>
        </v-row>
		<v-row class="pa-3" no-gutters>
        <!-- <pre>{{ org }}</pre> -->
			<v-col v-if="org.orgGroupStructure == 'Home' || org.orgGroupStructure == 'Independent home'" cols="12">
				<user-list :orgid="org.id" :homeUser="true" :key="tableKey" v-on:openItem="openHomeUserItem" />
			</v-col>
			<v-col v-if="org.orgGroupStructure == 'Home' || org.orgGroupStructure == 'Independent home'" cols="12">
				<user-list :orgid="org.id" :homeLogin="true" :key="tableKey" v-on:openItem="openHomeLoginItem" />
			</v-col>
			<v-col v-if="org.orgGroupStructure == 'Group' || org.orgGroupStructure == 'Parent Group'" cols="12">
				<user-list :orgid="org.id" :groupUser="true" :key="tableKey" v-on:openItem="openGroupUserItem" />
			</v-col>
			<v-col v-if="org.orgAccountTypes.includes('Partner')" cols="12">
				<user-list :orgid="org.id" :streamUser="true" :key="tableKey" v-on:openItem="openStreamUserItem" />
			</v-col>


            <v-dialog v-model="addStreamUserDialog" :key="addStreamUserDialogKey" persistent width="1066px" style="overflow: hidden !important" >
                <div class="px-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mb-1">
                        <v-icon class="white--text" large>oomph-menu-squared</v-icon>
                    </div>
                    <div class="white--text text-h6 d-flex align-center">
                        <span class="font-weight-normal ml-2">
                            New Stream User
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeItem()" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div min-height="800px" class="pa-0 ma-0 white">
                    <organisation-user :streamUser="true" :pu="true" :newItem="newItem" :orgid="org.id" v-on:save="closeItem" v-on:cancel="closeItem()" :datadown="item" :editMode="true"/>
                </div>
            </v-dialog>



            <v-dialog v-model="addHomeUserDialog" :key="addHomeUserDialogKey" persistent width="1066px" style="overflow: hidden !important" >
                <div class="px-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mb-1">
                        <v-icon class="white--text" large>oomph-menu-squared</v-icon>
                    </div>
                    <div class="white--text text-h6 d-flex align-center">
                        <span class="font-weight-normal ml-2">
                            New Home User
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeItem()" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div min-height="800px" class="pa-0 ma-0 white">
                    <organisation-user :homeUser="true" :pu="true" :newItem="newItem" :orgid="org.id" v-on:save="closeItem" :datadown="item" v-on:cancel="closeItem()" :editMode="true"/>
                </div>
            </v-dialog>

            <v-dialog v-model="addHomeLoginDialog" :key="addHomeLoginDialogKey" persistent width="1066px" style="overflow: hidden !important" >
                <div class="px-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mb-1">
                        <v-icon class="white--text" large>oomph-menu-squared</v-icon>
                    </div>
                    <div class="white--text text-h6 d-flex align-center">
                        <span class="font-weight-normal ml-2">
                            New Home Login
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeItem()" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div class="pa-0 ma-0 white">
                    <organisation-user :homeLogin="true" :pu="true" :newItem="newItem" :orgid="org.id" v-on:save="closeItem" :datadown="item" v-on:cancel="closeItem()" :editMode="true"/>
                </div>
            </v-dialog>

            <v-dialog v-model="addGroupUserDialog" :key="addGroupUserDialogKey" persistent width="1066px" style="overflow: hidden !important" >
                <div class="px-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mb-1">
                        <v-icon class="white--text" large>oomph-menu-squared</v-icon>
                    </div>
                    <div class="white--text text-h6 d-flex align-center">
                        <span class="font-weight-normal ml-2">
                            New Group User
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeItem()" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div class="pa-0 ma-0 white">
                    <organisation-user :groupUser="true" :pu="true" :newItem="newItem" :orgid="org.id" v-on:save="closeItem" :datadown="item" v-on:cancel="closeItem()" :editMode="true"/>
                </div>
            </v-dialog>
		</v-row>
	</div>
</template>

<script>
	import schema from "@/schema.js";
    import organisationUser from "@/views/users/UserDetails.vue"
    import userList from "@/views/users/UserListRedis.vue"
	import { mapGetters } from "vuex";
	export default {
		// * COMPONENTS
		components: {
			"organisation-user": organisationUser,
            "user-list": userList
		},
		// * PROPS
		props: {
			editmode: {
				type: Boolean,
				default: false,
			},
			org: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			item: {},
			newItem: true,
			key: "org",
			addImageToggle: false,
			costsToggle: false,
			index: "idx:orgs",
			showDeletedItems: false,
			schema: {},
			showImages: false,
			items: [],
			itemsTotal: null,
			searchQuery: "",
			itemsFrom: 0,
			itemsTo: 9,
			activeItem: {},
            addHomeLoginDialogKey: 0,
            addGroupUserDialogKey: 0,
            addHomeUserDialogKey: 0,
            addStreamUserDialogKey: 0,
            tableKey: 0,

			options: [],
			optionSelected: {},

            addHomeUserDialog: false,
            addStreamUserDialog: false,
            addHomeLoginDialog: false,
            addGroupUserDialog: false,

			// Headers to display in the Data Table
			headers: [
				{ text: "Id", value: "id", hidden: true },
				{ text: "Code", value: "attCode", hidden: false },
				{ text: "Name", value: "attName", hidden: false },
				{ text: "Type", value: "attType", hidden: true },
				{ text: "MTO", value: "attMTO", hidden: false },
				{ text: "Price Band", value: "attPriceBand", hidden: true },
				{ text: "Add Cost", value: "attAddCost", hidden: false },
				{ text: "Add Sell", value: "attAddSell", hidden: false },
				{ text: "Enabled", value: "enabled", hidden: true },
				{ text: "Parent ID", value: "attParentId", hidden: true },
				{ text: "Description", value: "attDescription", hidden: true },
				{ text: "Hex Color", value: "attHexColor", hidden: false },
				{ text: "Image", value: "attImage", hidden: true },
				{ text: "Option Id", value: "optionId", hidden: true },
				{ text: "Option Name", value: "optionName", hidden: false },
				{ text: "Supplier ID", value: "orgId", hidden: true },
				{ text: "Supplier Name", value: "orgName", hidden: true },
				{ text: "Date Time (C)", value: "createdDateTime", hidden: true },
				{ text: "User ID (C)", value: "createdUserId", hidden: true },
				{ text: "User Name (C)", value: "createdUserName", hidden: true },
				{ text: "User Email (C)", value: "createdUserEmail", hidden: true },
				{ text: "Date Time (M)", value: "modifiedDateTime", hidden: true },
				{ text: "User ID (M)", value: "modifiedUserId", hidden: true },
				{ text: "User Name (M)", value: "modifiedUserName", hidden: true },
				{ text: "User Email (M)", value: "modifiedUserEmail", hidden: true },
				{ text: "Deleted", value: "deleted", hidden: true },
				{ text: "Date Time (D)", value: "deletedDateTime", hidden: true },
				{ text: "User ID (D)", value: "deletedUserId", hidden: true },
				{ text: "User Name (D)", value: "deletedUserName", hidden: true },
				{ text: "User Email (D)", value: "deletedUserEmail", hidden: true }, 
				{ text: "Action", value: "action", align: "end", hidden: false, width: "155px" },
			],

			sortBy: "@contactFirstName",
			datatableOptions: {
				page: 1,
				itemsPerPage: 10,
				sortBy: ["orgName"],
				sortDesc: [false],
				groupBy: [],
				groupDesc: [],
				mustSort: false,
				multiSort: false,
			},


		}),
		// * COMPUTED
		computed: {
			...mapGetters({
				GET_dense: "GET_dense",
				GET_FIREBASE_userData: "firebase_auth_store/GET_FIREBASE_userData",
			}),
			computedFields() {
				let computedFields = this.headers.filter(x => x.hidden === false).map(y => '@'+ y.value);
				if (!computedFields.includes("@id")) { computedFields.push ("@id") }
				if (!computedFields.includes("@optionId")) { computedFields.push ("@optionId") }
				if (!computedFields.includes("@optionName")) { computedFields.push ("@optionName") }
				computedFields = computedFields.join(',');
				return computedFields
			},
			computedSearchQuery() {
				let searchQuery = this.searchQuery;
				if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
					if (this.showDeletedItems) {
						searchQuery = `@orgId:${this.org.id}`;
					} else {
						searchQuery = `@deleted:0 @orgId:${this.org.id}`;
					}
				} else {
					if (searchQuery.startsWith("@")) {
						if (this.showDeletedItems) {
							searchQuery = `${searchQuery}* @orgId:${this.org.id}`;
						} else {
							searchQuery = `${searchQuery}* @deleted:0 @orgId:${this.org.id}`;
						}
					} else {
						if (this.showDeletedItems) {
							searchQuery = `${searchQuery}* @orgId:${this.org.id}`;
						} else {
							searchQuery = `${searchQuery}* @deleted:0 @orgId:${this.org.id}`;
						}
					}
				}
				// Filter By optionId
				if (this.optionSelected?.optionId) {
					searchQuery = `${searchQuery} @optionId:${this.optionSelected.optionId}`;
				}
				return searchQuery;
			},
			computedHeaders() {
				return this.headers.filter((header) => !header.hidden); // Only return headers that are not hidden
			},
			computedItems() {
				var items = this.items; //.filter(x => x.);
				return items; //.slice(this.organisationsFrom, this.organisationsTo);
			},
			computedFilterByOption() {
				var items = this.items;
				const key = 'optionId';
				items = items.map(x => ({ optionId: x.optionId, optionName: x.optionName }))
				items = [...new Map(items.map(item => [item[key], item])).values()];
				return items
			}
		},
		// * METHODS
		methods: {
			// * INITIALISE ITEM
			initialiseItem() {
				let t = this;
				t.item = { ...t.schema };
			},
			// * CREATE ITEM
			async createItem() {
				let result = await this.MIX_createItem(this.item, this.key, this.newItem);
				if (result.code === 1) {
					this.getItems();
					this.initialiseItem();
				}
			},
            openItem(item) {
                this.item = item;
                this.addHomeUserDialog = true;
            },

            openHomeUserItem(item) {
                this.item = item 
                //console.log('item = ' + JSON.stringify(this.item, null, 2))
                this.addHomeUserDialog = true 
                this.newItem = false
            },
            openHomeLoginItem(item) {
                this.item = item 
                //console.log('item = ' + JSON.stringify(this.item, null, 2))
                this.addHomeLoginDialog = true 
                this.newItem = false
            },
            openGroupUserItem(item) {
                this.item = item 
                //console.log('item = ' + JSON.stringify(this.item, null, 2))
                this.addGroupUserDialog = true 
                this.newItem = false
            },
            openStreamUserItem(item) {
                this.item = item 
                //console.log('item = ' + JSON.stringify(this.item, null, 2))
                this.addStreamUserDialog = true 
                this.newItem = false
            },
			// * EDIT ITEM
			async editItem(itemId) {
				let result = await this.MIX_readItem(itemId, this.key);
				if (result.code === 1) {
					this.item = result.data;
				}
			},
            closeItem() {
                var t = this;

                t.addHomeUserDialog = false,
                t.addHomeLoginDialog = false,
                t.addGroupUserDialog = false,
                t.addStreamUserDialog = false,

                t.newItem = true
                t.item = {}

                t.addHomeUserDialogKey++
                t.addHomeLoginDialogKey++
                t.addGroupUserDialogKey++
                t.addStreamUserDialogKey++
                t.tableKey++
            },
			// * DESTROY ITEM
			async destroyItem(itemId) {
				let result = await this.MIX_destroyItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			// * DELETE ITEM
			async deleteItem(itemId) {
				let result = await this.MIX_deleteItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			// * RESTORE ITEM
			async restoreItem(itemId) {
				let result = await this.MIX_restoreItem(itemId, this.key);
				if (result.code === 1) {
					this.getItems();
				}
			},
			async getItems() {
				//// //console.log("getItems");
				//// //console.log("this.index = " + this.index);
				//// //console.log("this.this.computedFields = " + this.computedFields);
				let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
				//// //console.log("itemsResult = " + JSON.stringify(itemsResult, null, 2));
				if (itemsResult.code === 1) {
					this.itemsTotal = itemsResult.data.total;
					this.items = itemsResult.data.results;
					let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
				}
			},
			
            
		},
		// * WATCH
		watch: {
			// If the search query changes then get updated dataset based on search query
			computedSearchQuery() {
				this.getItems();
			},
			showDeletedItems() {
				this.getItems();
			},
			computedFields: {
				handler() {
					this.getItems();
				},deep: true
			},
			datatableOptions: {
				handler() {
					this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
					let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
					if (itemsTo < this.itemsTotal) {
						this.itemsTo = itemsTo;
					} else {
						this.itemsTo = this.itemsTotal;
					}
					this.getItems();
				},
				deep: true,
			},
		},
		// * CREATED
		async created() {
            let t = this
			this.schema = schema.attribute;
			this.getItems();
			this.initialiseItem();

			let activities = await t.MIX_redisReadSortedSet('sset:activities', 0, 9999);
			if (activities.code === 1) { t.activities = activities.data }	

		},
	};
</script>
