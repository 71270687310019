<template>
    <v-card>
        <v-toolbar class="pa-0 ma-0" color="primary" elevation="0">
            <div >
                <v-icon class="white--text">oomph-map</v-icon>
            </div>
            <div class="text-left white--text text-h5 pl-2 font-weight-light">Add New Action</div>
            <v-spacer />
            <div>
                <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="cancelItem" depressed>
                    <v-icon>oomph-multiply</v-icon>
                </v-btn>
            </div>
        </v-toolbar>
        <v-card-text class="grey lighten-3 px-4">
            <v-row class="py-2 pt-4 px-0" dense>
                <v-col cols="12">
                    <form>
                        <v-row dense>
                            <!-- <pre>{{ org }}</pre> -->
                            <v-col cols="12" class="d-flex align-center">
                                <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select label="Type *" v-model="item.actionType" :rules="rules.actionType" :items="actionTypes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearContactName('contactTwitter', '')"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select label="Priority *" v-model="item.actionPriority" :rules="rules.actionPriority" :items="priority" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearContactName('contactTwitter', '')"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-text-field label="Subject *" v-model="item.actionSubject" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('addressLine1')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12">
                                <v-text-field label="Description" v-model="item.actionDescription" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('addressLine2')"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" md="12">
                                <v-select label="Assigned to" v-model="item.assignedTo" :items="assignedTo" :item-text="item => item.userName" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearContactName('contactTwitter', '')"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field label="Action Start Date" v-model="actionStartDate" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="actionStartDate" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field label="Action End Date" v-model="actionEndDate" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="actionEndDate" full-width no-title color="secondary" :first-day-of-week="2" @input="dateMenu2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-menu v-model="dateMenu3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field label="Action Close Date" v-model="actionCloseDate" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="actionCloseDate" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu3 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select label="Status" v-model="item.actionStatus" :rules="rules.actionStatus" :items="status" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearContactName('contactTwitter', '')"></v-select>
                            </v-col>
                            <!-- <v-col cols="12" class="d-flex align-center">
                                <div class="text-left primary--text text-h6 font-weight-light">Related</div>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col> -->
                            <!-- <v-col cols="12" xs="12" md="6">
                                <v-autocomplete label="Organisations *" v-on:change="getAllOrganisationContacts()" v-model="selectedOrganisation" :rules="rules.actionType" :items="organisations" :item-text="item => item.orgName" required outlined dense hide-details="auto" background-color="white" color="primary" :attach="true" :menu-props="{ top: true, offsetY: true }" clearable return-object></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-autocomplete label="Contacts *" :disabled="!selectedOrganisation" v-model="selectedContact" :rules="rules.actionType" :items="organisationContacts"  required outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: true, offsetY: true }" clearable return-object></v-autocomplete>
                            </v-col> -->
                        </v-row>
                        <!-- <pre>{{ selectedOrganisation }}, {{ selectedContact }}</pre> -->
                        <v-row v-if="item.actionType === 'Call'" dense>
                            <v-col cols="12" class="d-flex align-center">
                                <div class="text-left primary--text text-h6 font-weight-light">Related</div>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select label="Call Type" v-model="selectedCallType" :rules="rules.actionStatus" :items="callType" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearContactName('contactTwitter', '')"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select label="Call Duration" v-model="selectedCallDuration" :rules="rules.actionStatus" :items="minutes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearContactName('contactTwitter', '')"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select label="Call Purpose" v-model="selectedCallPurpose" :rules="rules.actionStatus" :items="callPurpose" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearContactName('contactTwitter', '')"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select label="Call Result" v-model="selectedCallResult" :rules="rules.actionStatus" :items="callResult" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearContactName('contactTwitter', '')"></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="item.actionType === 'Meeting'" dense>
                            <v-col cols="12" class="d-flex align-center">
                                <div class="text-left primary--text text-h6 font-weight-light">Meeting</div>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select label="Meeting Host" v-model="item.actionMeetingHost" :rules="rules.actionMeetingHost" :items="assignedTo" :item-text="item => item.userName" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearContactName('contactTwitter', '')"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select label="Meeting Participants" v-model="item.actionMeetingParticipants" :rules="rules.actionMeetingParticipants" :items="assignedTo" :item-text="item => item.userName"  required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearContactName('contactTwitter', '')"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select label="Meeting Type" v-model="item.actionMeetingType" :rules="rules.actionMeetingType" :items="meetingTypes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearContactName('contactTwitter', '')"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select label="Meeting Location" v-model="item.actionMeetingLocation" :rules="rules.actionMeetingLocation" :items="callResult" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearContactName('contactTwitter', '')"></v-select>
                            </v-col>
                        </v-row>
                    </form>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="grey lighten-1 pa-3 pr-4 d-flex justify-right align-center">
                <v-spacer />
                <div>
                    <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>
                <div class="ml-2">
                    <v-btn class="primary white--text font-weight-bold" depressed @click="saveItem(item)"> <v-icon class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
                </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import schema from '@/schema.js';

export default {
    name: 'actionItemPU',
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        activeitem: {
            Type: Object,
            Default: null,
        },
    },
    // * DATA
    data: () => ({
        assignedTo: [],
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        // dropdowns
        addressTypes: [],
        priority: ['Urgent', 'High', 'Medium', 'Low'],
        actionTypes: [],
        status: ['Open', 'Pending', 'Closed'],

        //org dropdown
        organisations: [],
        index: 'idx:orgs',
        itemsFrom: 0,
        itemsTo: 9,
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['orgName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        sortBy: "@orgName",
        fields: "@id,@orgName",
        selectedOrganisation: {},
        selectedContact: {},
        organisationContacts: [],

        //call dropodwons
        callType: ['Inbound', 'Outbound', 'Missed'],
        minutes: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60'],
        callPurpose: ['None', 'Prospecting', 'Administrative', 'Negotiotiation', 'Demo', 'Project', 'Desk'],
        callResult: ['None', 'Interested', 'Not Interested', 'No Response', 'Busy', 'Requested More Info', 'Requested Call Back', 'Invalid Number'],

        //meeting dropdowns
        users: [],
        contacts: [],
        meetingTypes: ['In Person', 'Remote', 'Conference Call'],

        // new action object
        item: {},

        selectedCallType: '',
        selectedCallDuration: '',
        selectedCallPurpose: '',
        selectedCallResult: '',

        rules: {},

        //date pickers
		actionStartDate: '',
		dateMenu1: false,
		actionEndDate: '',
		dateMenu2: false,
		actionCloseDate: '',
		dateMenu3: false,
    }),
    methods: {
        updateItem() {
            this.$emit('update', this.item);
        },
        getAddressData: function (addressData, placeResultData) {
            let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
            this.item.addressName = placeResult.name;
            this.item.addressLine1 = placeResult.addressLine1;
            this.item.addressLine2 = placeResult.addressLine2;
            this.item.addressLine3 = placeResult.addressLine3;
            this.item.addressTown = placeResult.town;
            this.item.addressCounty = placeResult.county;
            this.item.addressPostcode = placeResult.postcode;
            this.item.addressCountry = placeResult.country;
            this.item.addressLat = placeResult.lat;
            this.item.addressLng = placeResult.lng;
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem(item) {
            let t = this;
            item.actionStartDate = t.actionStartDate;
            item.actionEndDate = t.actionEndDate;
            item.actionCloseDate = t.actionCloseDate;
            item.actionCallType = t.selectedCallType;
            item.actionCallDuration = t.selectedCallDuration;
            item.actionCallPurpose = t.selectedCallPurpose;
            item.actionCallResult = t.selectedCallResult;
            // if no id then generate one
            if (item.id !== undefined && item.id !== null && item.id !== '') {
                // do nothing
            } else {
                item.id = t.MIX_generateId();
            }
            // console.log("item = " + JSON.stringify(item, null, 2));
            // console.log("key = " + JSON.stringify(t.key, null, 2));
            let result = await this.MIX_createItem(item, this.key, this.itemNew);
            if (result.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Error - Please retry', show: true });
            }
            this.item = this.schema;
            this.$emit('close');
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        getUsers() {
            let t = this;
            t.MIX_firestore_readMany('users', 1).then((users) => {
                t.assignedTo = users.data;
            });
        },
        async getAllOrganisations() {
            let t = this;
            let itemsResult = await t.MIX_redisAggregateSearch(t.index, '*', t.itemsFrom, 999, t.fields, t.sortBy, false);
            if (itemsResult.code === 1) {
                t.itemsTotal = itemsResult.data.total;
                t.organisations = itemsResult.data.results;
                // let itemsTo = t.datatableOptions.page * t.datatableOptions.itemsPerPage;
                // if (itemsTo < t.itemsTotal) {
                //     t.itemsTo = itemsTo;
                // } else {
                //     t.itemsTo = t.itemsTotal;
                // }
            }
        },
        async getAllOrganisationContacts() {
            let t = this;
            let result = await t.MIX_redisSearch('idx:contacts', `@orgId:{${t.selectedOrganisation.id}}`, 0, 9999);
            if (result.code === 1) {
                t.organisationContacts = result.data.documents;
            }
        },
        // * READ ITEM
        async readItem(itemId) {
            let t = this;
            let result = await t.MIX_readItem(itemId, t.key);
            if (result.code === 1) {
                this.item = result.data;
                console.log("got item")
                t.itemNew = false;

                if (t.item.orgId !== '') {
                    t.selectedOrganisation = {
                        id: t.item.orgId,
                        userName: t.item.orgName
                    }
                }
                if (t.item.contactId !== '') {
                    t.selectedContact = {
                        id: t.item.contactId,
                        userName: t.item.contactName
                    }
                }
                // t.loading = false;
            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                let t = this;
                this.org = { ...this.datadown };
                if (JSON.stringify(t.activeitem) === '{}') {
                    t.item = { ...t.schema };
                    this.item.orgId = this.org.id;
                    this.item.orgName = this.org.orgName;
                } else {
                    t.item = { ...t.activeItem };
                    t.itemNew = false
                }
            },
            deep: true,
        },
        // selectedContact: {
        //     handler: function () {
        //         this.item.contactId = this.selectedContact.value.id
        //         this.item.contactName = this.selectedContact.value.userName
        //     }
        // },
        // selectedOrganisation: {
        //     handler: function () {
        //         this.item.orgId = this.selectedOrganisation.id
        //         this.item.orgName = this.selectedOrganisation.userName
        //     }
        // },
        activeitem: {
            handler: function () {
                let t = this;
                t.item = { ...t.activeitem };
            }, deep: true
        },
        dateMenu1() {
            this.item.actionStartDate = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
        },
        dateMenu2() {
            this.item.actionEndDate = this.$moment(this.date2, 'YYYY-MM-DD').format('X');
        },
        dateMenu3() {
            this.item.actionCloseDate = this.$moment(this.date3, 'YYYY-MM-DD').format('X');
        },
    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.action;
        t.org = { ...t.datadown };
        if (JSON.stringify(t.activeitem) === '{}') {
            t.item = { ...t.schema };
            t.item.orgId = t.org.id;
            t.item.orgName = t.org.orgName;
        } else {
            this.readItem(t.activeitem.id);
        }
        let actionTypes = await t.MIX_redisReadSet('set:actionTypes', false);
        if (actionTypes.code === 1) {
            t.actionTypes = actionTypes.data;
        }
        // Get Users
        let users = await this.MIX_users(''); // Add Users to Dropdown for Owner
        this.users = users.map((x) => {
            return {
                ownerUserId: x.id,
                ownerUserName: x.userName,
                ownerUserEmail: x.userEmail,
            };
        });
        // Get Users
        let contacts = await this.MIX_contacts(''); // Add Users to Dropdown for Owner
        this.contacts = contacts.map((x) => {
            return {
                contactUserId: x.id,
                contactUserName: x.userName,
                contactUserEmail: x.userEmail,
            };
        });
    },
    mounted() {
        let t = this;
        t.getUsers();
        t.getAllOrganisations();
    },
};
</script>