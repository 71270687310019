<template>
    <div class=""
        style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow-y: scroll !important; overflow-x: hidden !important">

        <v-col cols="12" class="d-flex grey lighten-2 py-4 px-3">
            <v-btn text v-if="markerTypes.includes('Video')" class="primary white--text mr-1"
                @click="addVideoOverlay = true"><v-icon class="mr-1">oomph-instagram</v-icon>Add Video</v-btn>
            <v-btn text v-if="markerTypes.includes('Image')" class="primary white--text mr-1"
                @click="addImageOverlay = true"><v-icon class="mr-1">oomph-picture</v-icon>Add Image</v-btn>
            <v-btn text v-if="markerTypes.includes('Text')" class="primary white--text mr-1"
                @click="addTextOverlay = true"><v-icon class="mr-1">oomph-edit</v-icon>Add Text</v-btn>
            <v-btn text v-if="markerTypes.includes('PDF')" class="primary white--text mr-1"
                @click="addPdfOverlay = true"><v-icon class="mr-1">oomph-pdf</v-icon>Add PDF</v-btn>
            <v-btn text v-if="markerTypes.includes('Audio') || markerTypes.includes('Podcast')" class="primary white--text "
                @click="addPodcastOverlay = true"><v-icon class="mr-1">oomph-pdf</v-icon>Add Podcast</v-btn>
        </v-col>
        <v-row class="pt-4 px-4" dense>
            <v-col cols="12" sm="12" class="d-flex align-center">
                <div class="text-left primary--text text-h6 font-weight-light">Content Items</div>
                <!-- <v-spacer />
                <div class="caption text-right"><strong>ID:</strong> {{ contentItem.id }}</div> -->
            </v-col>
            <v-col cols="12" sm="12">
                <v-divider class="grey lighten-1 mb-1"></v-divider>
            </v-col>
        </v-row>
        <!-- <pre>{{ content }}</pre> -->
        <!-- <pre>{{ computedTemplateNames }}</pre> -->

        <v-row class="px-4 pb-4" dense>
            <!-- <v-col cols="12">
                <v-select label="Content Template" background-color="white" class="pr-1" v-model="content.contentTemplate" :items="computedTemplateNames" @change="updateTemplate" outlined dense color="primary" hide-details="auto" clearable @click:clear="clearContactName('contentTemplate', '')" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
            </v-col> -->
            <v-col cols="12" sm="12">
                This item requires the following Content:<br />
                <li v-for="marker in markers" :key="marker.name" class="body-2">{{ marker.name }} ({{ marker.type }})</li>
            </v-col>
            <v-col cols="12" sm="12" v-if="content.contentTemplate === 'Quiz'">
                Use the Quiz Tab to add Questions and Answers
            </v-col>


        </v-row>
        <!-- <v-row class="px-4">
            <v-col cols="3">
                <v-text-field label="Search" background-color="white" v-model="search" outlined dense></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-select label="Filter by Type" background-color="white" v-model="type" :items="computedType" outlined dense clearable></v-select>
            </v-col>
            <v-col cols="3">
                <v-select label="Filter by Created" background-color="white" v-model="created" :items="computedCreated" outlined dense clearable></v-select>
            </v-col>
            <v-col cols="3">
                <v-select label="Filter by Status" background-color="white" v-model="status" :items="computedStatus" outlined dense clearable></v-select>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
                <v-btn style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2 mr-2" @click="getContentItems" depressed>
                    <v-icon>oomph-available-updates</v-icon>
                </v-btn>
            </v-col>
        </v-row> -->

        <!-- <div class="d-flex align-start mx-3">
            <div class="flex-grow-1">
                <v-text-field label="Search" background-color="white" v-model="search" outlined dense></v-text-field>
            </div>
            <div class="ml-2">
                <v-select label="Filter by Type" background-color="white" v-model="type" :items="computedType" outlined dense clearable></v-select>
            </div>
            <div class="ml-2">
                <v-select label="Filter by Created" background-color="white" v-model="created" :items="computedCreated" outlined dense clearable></v-select>
            </div>
            <div class="ml-2">
                <v-select label="Filter by Status" background-color="white" v-model="status" :items="computedStatus" outlined dense clearable></v-select>
            </div>
            <div class="ml-2">
                <v-btn style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="getContentItems" depressed>
                    <v-icon>oomph-available-updates</v-icon>
                </v-btn>
            </div>
        </div> -->
        <div class="mx-3">
            <v-data-table :headers="computedHeaders" :items="computedItems" :items-per-page="15" :search="search"
                class="elevation-0" style="border: 1px solid lightgrey !important" dense>
                <template v-slot:[`item.value.createdDateTime`]="{ item }">
                    <span>{{ $moment(parseInt(item.value.createdDateTime)).format('DD-MM-YYYY') }}</span>
                </template>
                <template v-slot:[`item.value.itemType`]="{ item }">
                    <span v-if="item.value.itemType === 'Image'"><v-icon>oomph-picture</v-icon></span>
                    <span v-else-if="item.value.itemType === 'Video'"><v-icon>oomph-video</v-icon></span>
                    <span v-else-if="item.value.itemType === 'Text'"><v-icon>oomph-multiline-text</v-icon></span>
                    <span v-else-if="item.value.itemType === 'PDF'"><v-icon>oomph-pdf</v-icon></span>
                </template>
                <template v-slot:[`item.value.itemEncodeStatus`]="{ item }">
                    <span v-if="item.value.itemEncodeStatus === 'asset_created'">Pending</span>
                    <span v-else-if="item.value.itemEncodeStatus === 'ready'">Ready</span>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon class="primary--text" @click="editItem(item)">oomph-edit</v-icon>
                    <v-icon class="primary--text" @click="
                        deleteOverlay = true;
                    deleteItemData = item;
                    ">oomph-delete-trash</v-icon>
                </template>
            </v-data-table>
        </div>
        <!-- <pre>{{ contentItemVideo }}</pre> -->

        <!-- videoMarkers: <pre>{{ videoMarkers }}</pre>
        markerTypes: <pre>{{ markerTypes }}</pre> -->

        <v-overlay v-model="deleteOverlay">
            <v-card class="white rounded-0">
                <v-card-title class="primary">Delete Content Item</v-card-title>
                <v-card-text class="primary--text"> Are you sure you want to delete this content item? </v-card-text>
                <v-card-actions class="primary--text">
                    <v-btn @click="deleteItem()">CONFIRM</v-btn>
                    <v-btn @click="deleteOverlay = false">CANCEL</v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>

        <v-dialog persistant v-model="addVideoOverlay" max-width="1024px">
            <AddEditVideoOverlay :markers="videoMarkers" :contentItem="contentItemVideo" :newItem="newItem"
                :key="dialogVideoKey" :editMode="editMode" :originalContentId="originalContentId"
                @closeOverlay="closeOverlay" @updateItems="getContentItems" />
        </v-dialog>
        <v-dialog persistant v-model="addImageOverlay" max-width="1024px">
            <AddEditImageOverlay :markers="imageMarkers" :contentItem="contentItemImage" :newItem="newItem"
                :key="dialogImageKey" :editMode="editMode" :originalContentId="originalContentId"
                @closeOverlay="closeOverlay" @updateItems="getContentItems" />
        </v-dialog>
        <v-dialog persistant v-model="addTextOverlay" max-width="1024px">
            <AddEditTextOverlay :markers="textMarkers" :datadown="contentItemText" :newItem="newItem" :key="dialogTextKey"
                :editMode="editMode" :originalContentId="originalContentId" @closeOverlay="closeOverlay"
                @updateItems="getContentItems" />
        </v-dialog>
        <v-dialog persistant v-model="addPdfOverlay" max-width="1024px">
            <AddEditPdfOverlay :markers="pdfMarkers" :contentItem="contentItemPDF" :newItem="newItem" :key="dialogPdfKey"
                :editMode="editMode" :originalContentId="originalContentId" @closeOverlay="closeOverlay"
                @updateItems="getContentItems" />
        </v-dialog>
        <v-dialog persistant v-model="addPodcastOverlay" max-width="1024px">
            <AddEditPodcastOverlay :markers="podcastMarkers" :addPodcastOverlay="addPodcastOverlay" :key="dialogPodcastKey"
                :contentItem="contentItemPodcast" :newItem="newItem" :editMode="editMode"
                :originalContentId="originalContentId" @closeOverlay="closeOverlay" @updateItems="getContentItems" />
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddEditVideoOverlay from '@/views/content/AddEditVideoOverlay.vue';
import AddEditImageOverlay from '@/views/content/AddEditImageOverlay.vue';
import AddEditTextOverlay from '@/views/content/AddEditTextOverlay.vue';
import AddEditPdfOverlay from '@/views/content/AddEditPdfOverlay.vue';
import AddEditPodcastOverlay from '@/views/content/AddEditPodcastOverlay.vue';

export default {
    name: 'ContentItems',
    components: {
        AddEditVideoOverlay,
        AddEditImageOverlay,
        AddEditTextOverlay,
        AddEditPdfOverlay,
        AddEditPodcastOverlay
    },
    props: {
        contentDoc: {
            type: Object,
            default: () => {
                return {}
            }
        },
        contentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        originalContentId: {
            type: String,
            default: () => {
                return '';
            },
        },
        newItem: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
    },
    data: () => ({
        contentItemVideo: {},
        contentItemImage: {},
        contentItemText: {},
        contentItemPDF: {},
        contentItemPodcast: {},

        dialogPdfKey: 0,
        dialogVideoKey: 0,
        dialogTextKey: 0,
        dialogImageKey: 0,
        dialogPodcastKey: 0,

        addVideoOverlay: false,
        addImageOverlay: false,
        addTextOverlay: false,
        addPdfOverlay: false,
        addPodcastOverlay: false,
        created: '',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['itemName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        editMode: false,
        dateMenuFrom: false,
        dateMenuTo: false,
        datePickerFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        datePickerTo: '',
        deleteOverlay: false,
        deleteItemData: {},
        fields: '@id,@itemName,@itemStatus,@itemMarker,@itemType,@itemURL,@itemFileSizeKb,@itemFormat,@itemEncodeStatus,@createdDateTime,@itemUploadId,@itemAssetId@itemContentId,@itemPlaybackId',
        headers: [
            { text: 'ID', align: 'start', value: 'id', hidden: true },
            { text: 'Type', value: 'value.itemType', hidden: false },
            { text: 'Name', value: 'value.itemName', hidden: false },
            { text: 'Marker', value: 'value.itemMarker', hidden: false },
            { text: 'Created', value: 'value.createdUserName', hidden: false },
            { text: 'Created Date', value: 'value.createdDateTime', hidden: false },
            { text: 'Status', value: 'value.itemStatus', hidden: true },
            { text: 'Format', value: 'value.itemFormat', hidden: true },
            { text: 'Encode Status', value: 'value.itemEncodeStatus', hidden: false },
            { text: 'Size', value: 'value.itemFileSizeKb', hidden: false },
            { text: 'Actions', value: 'actions', align: 'end', hidden: false },
        ],
        index: 'idx:contentItems',
        items: [],
        itemsFrom: 0,
        itemsTo: 9,
        search: '',
        sortBy: '@itemName',
        status: '',
        type: '',
        contentTemplates: [],
        content: {},
        templates: [],
        templateMarkers: [],
        markerTypes: [],
        markers: [],
        videoMarkers: [],
        imageMarkers: [],
        textMarkers: [],
        pdfMarkers: [],
        podcastMarkers: [],
    }),
    watch: {
        contentItem() {
            let t = this;
            t.getContentItems();
        },
        newItem() {
            let t = this;
            t.items = [];
            document
                .querySelector(
                    '#app > div > main > div > div > div > nav > div.v-navigation-drawer__content > div.pa-0.col.col-12 > div.v-tabs.theme--light > div > div.v-slide-group__wrapper > div > a:nth-child(2)'
                )
                .click();
        },
        content: {
            handler: function () {
                let t = this;
                t.handleMarkers();
                t.getContentItems();
                // t.$emit("updatecontent", t.content);
            }, deep: true
        }
    },
    computed: {
        computedItems() {
            var items = this.items;
            if (this.type) {
                items = this.items.filter((item) => item.value.itemType === this.type);
            }
            if (this.created) {
                items = this.items.filter((item) => item.value.createdUserName === this.created);
            }
            if (this.status) {
                items = this.items.filter((item) => item.value.itemStatus === this.status);
            }
            return items;
        },
        computedHeaders() {
            return this.headers.filter((header) => !header.hidden);
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.value.itemType);
            });
            return types;
        },
        computedCreated() {
            const users = [];
            this.items.filter((item) => {
                return users.push(item.value.createdUserName);
            });
            return users;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.value.itemStatus);
            });
            return status;
        },
        computedTemplateNames() {
            if (JSON.stringify(this.template) !== '[]') {
                var templates = this.templates
                if (this.content.contentArea == 'News') {
                    templates = templates.filter(function (item) {
                        return item.alias == 'News'
                    })
                }
                return templates.map((template) => template.name);
            } else {
                return [];
            }
        },
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_firebase_userAccess: 'firebase_auth_store/GET_firebase_userAccess',
            GET_FIREBASE_apiHeaderToken: 'firebase_auth_store/GET_FIREBASE_apiHeaderToken',
        }),
        apiOptions() {
            return {
                headers: {
                    Authorization: `Bearer ${this.GET_FIREBASE_apiHeaderToken}`,
                },
            };
        },
    },
    methods: {
        updateTemplate() {
            var t = this;
            // // //console.log("updating template")
            t.handleMarkers();
            t.getContentItems();
            //* HERE NEEDS TO GET THE LATEST CONTENT DETAILS DATA
            t.$emit("getlatestdata", t.content.id);
            t.$emit("updatecontent", t.content);
        },
        handleMarkers() {
            let t = this;
            t.templateMarkers = t.templates.find(function (template) {
                return template.name === t.content.contentTemplate;
            });
            // // //console.log("templateMakers = " + JSON.stringify(t.templateMarkers, null, 2));
            if (t.templateMarkers !== undefined) {
                t.markers = this.templateMarkers.markers.map((marker) => marker);
                // //console.log("markers = " + JSON.stringify(t.markers, null, 2));
                t.markerTypes = this.templateMarkers.markers.map((marker) => marker.type);
                t.videoMarkers = this.markers.filter(function (marker) {
                    return marker.type === 'Video';
                });
                t.imageMarkers = this.markers.filter(function (marker) {
                    return marker.type === 'Image';
                });
                t.textMarkers = this.markers.filter(function (marker) {
                    return marker.type === 'Text';
                });
                t.pdfMarkers = this.markers.filter(function (marker) {
                    return marker.type === 'PDF';
                });
                t.podcastMarkers = this.markers.filter(function (marker) {
                    return marker.type === 'Audio';
                });
            }
        },
        async initialise() {
            let t = this;
            // Get Content Templates - Replace with JSON
            let contentTemplates = await t.MIX_redisReadSet('set:contentTemplate', false);
            if (contentTemplates.code === 1) {
                t.contentTemplates = contentTemplates.data;
            }
            // Get Content Template - JSON
            let templatesResult = await t.MIX_redisReadJSON("settings:contentTemplates", ".");
            if (templatesResult.code === 1) {
                t.templates = templatesResult.data;
            }
            // Set Content based on contentDoc
            t.content = { ...t.contentDoc };
        },
        async getContentItems() {
            let t = this;
            // //console.log("advanced getting items")
            let result = await t.MIX_redisSearch('idx:contentItems', `@itemContentId:{${t.originalContentId}}`, 0, 9999);
            if (result.code === 1) {
                t.items = result.data.documents;
            }
            // t.$emit("updatecontent", t.content);
        },
        editItem(item) {
            let t = this;
            // //console.log("opening item : " + JSON.stringify(item, null, 2));
            if (item.value.itemType === 'Video') {
                t.contentItemVideo = item.value;
                t.editMode = true;
                t.getVideo(item.value.itemAssetId, item.value);
                t.addVideoOverlay = true;
            }
            if (item.value.itemType === 'Image') {
                t.contentItemImage = item.value;
                // //console.log("contentItemImage : " + JSON.stringify(t.contentItemImage, null, 2));
                t.editMode = true;
                t.addImageOverlay = true;
            }
            if (item.value.itemType === 'Text') {
                t.contentItemText = item.value;
                t.editMode = true;
                t.addTextOverlay = true;
            }
            if (item.value.itemType === 'PDF') {
                t.contentItemPDF = item.value;
                t.editMode = true;
                t.addPdfOverlay = true;
            }
            if (item.value.itemType === 'Podcast' || item.value.itemType === 'Audio') {
                t.contentItemPodcast = item.value;
                t.editMode = true;
                t.addPodcastOverlay = true;
            }
        },
        async deleteItem() {
            let t = this;
            let result = await this.MIX_destroyItem(t.deleteItemData.value.id, t.deleteItemData.value.key);
            if (result.code === 1) {
                t.deleteOverlay = false;
                t.getContentItems();
            }
        },
        getVideo(itemAssetId, item) {
            let t = this;
            t.$axios.get(`${process.env.VUE_APP_OOMPHSERVER}/video/assets/${itemAssetId}`, this.apiOptions).then(async (response) => {
                item.itemEncodeStatus = response.data.data.status;
                item.itemURL = `https://stream.mux.com/${response.data.data.playback_ids[0].id}.m3u8`;
                item.itemPlaybackId = response.data.data.playback_ids[0].id;
                let updateContentItemResult = await this.MIX_redisUpdateHash('contentItem:' + itemAssetId, item);
                if (updateContentItemResult.code === 1) {
                    t.getContentItems();
                }
            });
        },
        removeTag(item) {
            let t = this;
            t.item.tags.splice(t.item.tags.indexOf(item), 1);
            t.item.tags = [...t.item.tags];
        },
        closeOverlay(value) {
            let t = this;
            // t.getContentItems();
            // //console.log("closing")
            t.addVideoOverlay = value;
            t.addImageOverlay = value;
            t.addTextOverlay = value;
            t.addPdfOverlay = value;
            t.addPodcastOverlay = value;

            t.contentItemVideo = {},
                t.contentItemImage = {},
                t.contentItemText = {},
                t.contentItemPDF = {},
                t.contentItemPodcast = {},

                t.dialogPdfKey++
            t.dialogVideoKey++
            t.dialogTextKey++
            t.dialogImageKey++
            t.dialogPodcastKey++
        },
    },
    async created() {
        let t = this;
        t.initialise();
    },
    mounted() {
        let t = this;
        if (!t.newItem) {
            t.getContentItems();
        }
        t.handleMarkers();
    },
};
</script>

<style>iframe {
    height: 500px;
    width: 750px;
}</style>
