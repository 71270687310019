<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important"
        :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important;'">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div
                        class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">
                        Loading</div>
                </div>
                <div v-else class="ml-3 body-2 white--text">* Required Fields</div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"
                        :large="!dense"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode" @click="validateForm()"
                        :large="!dense"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <!-- Main Content -->
            <!-- <pre>{{ item.ownerUserName }}</pre> -->
            <v-col v-if="!editmode && !loading" cols="12" class="d-flex align-start grey lighten-5 rounded">
                <!-- //* VIEW MODE -->
                <v-row style="width: 100% !important" no-gutters>
                    <!-- //* LOGO VIEW -->
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">{{
            orgtype }} Logo</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-picture</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center mt-">
                                <v-img max-height="200" :src="item.orgLogo" class="greyscale" contain></v-img>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* GENERAL DETAILS VIEW -->
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">{{
            orgtype }} Details</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold body-2 white">Name:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">{{
            item.orgName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold body-2 white">Account
                                                    Type:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">{{
            item.orgAccountTypes }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="my-2 px-2" cols="12" sm="12" md="6">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light">Profile Score</span>
                                    <v-spacer />
                                    <v-icon medium
                                        class="grey--text text--darken-2">oomph-identification-documents</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>

                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important"
                                class="mt-5 d-flex align-start justify-start">
                                <div>
                                    <v-progress-circular :rotate="-90" :size="100" :width="15"
                                        :value="computedProfileScore.score" :color="computedProfileScore.color"> {{
            computedProfileScore.score }} % </v-progress-circular>
                                </div>
                                <div class="ml-5">
                                    <span class="font-weight-bold" :class="computedProfileScore.color + '--text'">{{
            computedProfileScore.name }}</span>
                                    <p>{{ computedProfileScore.message }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* CONTACTS VIEW -->
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Contact</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Telephone:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <a :href="'callto:' + item.orgTelephone" target="_blank">{{
            item.orgTelephone }}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold body-2 white">Website:
                                                </td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <a :href="item.orgWebsite" target="_blank"
                                                        v-if="item.orgWebsite !== '' && item.orgWebsite !== undefined">{{
            item.orgWebsite.substring(0, 21) }}...</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Email:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <a :href="'mailto:' + item.orgEmail" target="_blank">{{
            item.orgEmail }}</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* GROUP STRUCTURE VIEW -->
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Group
                                        Structure</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-hierarch</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Structure:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.orgGroupStructure }}
                                                </td>
                                            </tr>
                                            <tr v-if="groupDetails !== ''">
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Group:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <a @click="MIX_go('/customers/' + groupDetails.orgId)"
                                                        style="cursor: pointer;">{{ groupDetails.orgName }}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Subdomain:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.orgSubdomain }}
                                                </td>
                                            </tr>
                                            <tr v-if="parentGroupDetails !== ''">
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Parent Group:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <a @click="MIX_go('/customers/' + parentGroupDetails.orgId)"
                                                        style="cursor: pointer;">{{ parentGroupDetails.orgName }}</a>
                                                </td>
                                            </tr>
                                            <tr v-if="item.orgDivisions !== '' && item.orgDivisions !== undefined">
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Divisions:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.orgDivisions.split(',').length }}
                                                </td>
                                            </tr>
                                            <tr v-if="item.orgRegions !== '' && item.orgRegions !== undefined">
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Regions:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.orgRegions.split(',').length }}
                                                </td>
                                            </tr>
                                            <tr
                                                v-if="item.orgDivisionName !== '' && item.orgDivisionName !== undefined">
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Division:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.orgDivisionName }}
                                                </td>
                                            </tr>
                                            <tr v-if="item.orgRegionName !== '' && item.orgRegionName !== undefined">
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Region:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.orgRegionName }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* INVOICE DETAILS VIEW -->
                    <v-col v-if="!item.orgAccountTypes.includes('Customer')" cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Finance
                                        &
                                        Accounting</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-calculator</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Registered Name:
                                                </td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">{{
            item.orgRegisteredName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Registration No:
                                                </td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">{{
            item.orgRegistrationNumber }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Invoice Email:
                                                </td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <a :href="'mailto:' + item.orgInvoiceEmail" target="_blank">{{
            item.orgInvoiceEmail }}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Purchasing Email:
                                                </td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <a :href="'mailto:' + item.orgPurchasingEmail" target="_blank">{{
            item.orgPurchasingEmail }}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Rate / Fee:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ new Intl.NumberFormat('en-GB', {
            style: 'currency', currency:
                'GBP'
        }).format(item.orgAccountCreditLimit) }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Frequency:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.orgAccountFrequency }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Account On Hold:
                                                </td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <span v-if="item.orgAccountOnHold === '0'">No</span>
                                                    <span v-if="item.orgAccountOnHold === '1'">Yes</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* ADDRESS DETAILS VIEW -->
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Address</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-map-marker</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody style="height: 100px" class="white">
                                            <tr class="d-flex white">
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Address:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <a :href="`https://www.google.com/maps/search/?api=1&query=${item.orgName},${item.orgAddressLine1},${item.orgAddressLine2},${item.orgAddressLine3},${item.orgTown},${item.orgCounty},${item.orgPostcode}`"
                                                        target="_blank">
                                                        <span v-if="item.orgAddressLine1">{{ item.orgAddressLine1
                                                            }}<span v-if="item.orgAddressLine2">, {{
            item.orgAddressLine2 }}</span><br /></span>
                                                        <span v-if="item.orgAddressLine3">{{ item.orgAddressLine3
                                                            }}<br /></span>
                                                        <span v-if="item.orgTown">{{ item.orgTown }}<span
                                                                v-if="item.orgCounty">, {{ item.orgCounty
                                                                }}</span><br /></span>
                                                        <span v-if="item.orgPostcode">{{ item.orgPostcode
                                                            }}<br /></span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //*  Tags -->
                    <v-col v-if="!item.orgAccountTypes.includes('Customer')" cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Tags</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-price-tag</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" style="height: 100% !important" class="pt-2"
                                v-if="item.orgTags !== '' && item.orgTags !== undefined">
                                <v-chip label class="mr-1 mt-1" small v-for="tag in item.orgTags.split(',')"
                                    :key="tag">{{ tag }}</v-chip>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* ORG INFO VIEW -->
                    <v-col v-if="!item.orgAccountTypes.includes('Customer')" cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Organisation
                                        Info</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-city</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Sector:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-1">{{
            item.orgSector }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Turnover:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">{{
            item.orgTurnover }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* OWNER / SOURCE INFO VIEW -->
                    <v-col v-if="!item.orgAccountTypes.includes('Customer')" cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Source
                                        Info</span>
                                    <v-spacer />
                                    <v-icon medium class="primary--text" text--darken-2>oomph-secured-letter</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Source:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">{{
            item.orgSource }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Source Name:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">{{
            item.orgSourceName }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* SOCIAL INFO VIEW -->
                    <v-col v-if="!item.orgAccountTypes.includes('Customer')" cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Social
                                        Networks</span>
                                    <v-spacer />
                                    <v-icon medium
                                        class="grey--text text--darken-2">oomph-people-working-together</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td colspan="2"
                                                    class="pl-0 apptext--text font-weight-normal body-1 white d-flex align-center">
                                                    <v-icon class="apptext--text mr-2">oomph-twitter</v-icon>
                                                    <div class="body-2 pointer">
                                                        <a :href="item.orgTwitter" target="_blank">{{ item.orgTwitter
                                                            }}</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"
                                                    class="pl-0 apptext--text font-weight-normal body-1 white d-flex align-center">
                                                    <v-icon class="apptext--text mr-2">oomph-linkedin</v-icon>
                                                    <div class="body-2">
                                                        <a :href="item.orgLinkedIn" target="_blank">{{ item.orgLinkedIn
                                                            }}</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"
                                                    class="pl-0 apptext--text font-weight-normal body-1 white d-flex align-center">
                                                    <v-icon class="apptext--text mr-2">oomph-facebook</v-icon>
                                                    <div class="body-2">
                                                        <a :href="item.orgFacebook" target="_blank">{{ item.orgFacebook
                                                            }}</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"
                                                    class="pl-0 apptext--text font-weight-normal body-1 white d-flex align-center">
                                                    <v-icon class="apptext--text mr-2">oomph-instagram</v-icon>
                                                    <div class="body-2">
                                                        <a :href="item.orgInstagram" target="_blank">{{
            item.orgInstagram }}</a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- METADATA INFO -->
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Metadata</span>
                                    <v-spacer />
                                    <v-icon medium class="primary--text" text--darken-2>oomph-secured-letter</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Owner:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">{{
            item.ownerUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Created by:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">{{
            item.createdUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Created:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">{{
            MIX_formatDateTime(item.createdDateTime, 'x', 'DD-MMM-YYYY
                                                    HH: mm: ss') }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Modified by:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">{{
                    item.modifiedUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Modified:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white pl-2">{{
            MIX_formatDateTime(item.modifiedDateTime, 'x', 'DD-MMM-YYYY
                                                    HH: mm: ss') }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <!-- FORM -->
            <v-col v-if="editmode && !loading" cols="12" class="" style="width: 100% !important">
                <v-row dense class="mx-2 mt-2">
                    <!-- Google Places Autocomplete -->
                    <v-col cols="12" sm="12" class="" v-if="itemnew">
                        <vuetify-google-autocomplete prepend-icon="oomph-google" autocomplete="donotcomplete" dense
                            outlined background-color="white" label="Google Business Lookup" name="myapp-name"
                            ref="addressInput" types="" id="map" classname="form-control"
                            v-on:placechanged="getAddressData" country="gb" clearable>
                        </vuetify-google-autocomplete>
                    </v-col>
                </v-row>
                <v-form ref="form" lazy-validation style="width: 100% !important" class="pa-o ma-0">
                    <!-- ORGANISATON DETAILS -->
                    <v-expansion-panels v-model="panelFixed" accordion flat class="pa-0 ma-0">
                        <v-expansion-panel class="grey lighten-4">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">{{ orgtype
                                    }} Details
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <!-- Name -->
                                    <v-col cols="12" xs="12" md="6" class="mt-0">
                                        <v-text-field label="Name *" v-model="item.orgName" :rules="rules.orgName"
                                            required outlined dense hide-details="auto" background-color="white"
                                            color="primary" clearable @click:clear="clearItem('orgName')"
                                            autocomplete="donoautocomplete"></v-text-field>
                                    </v-col>
                                    <!-- Account Type -->
                                    <v-col cols="12" xs="6" :md="inputCols" class="mt-0">
                                        <v-select label="Account Type *" multiple v-model="selectedAccountType"
                                            :rules="rules.orgAccountType" :items="accountTypes" outlined dense
                                            hide-details="auto" background-color="white" color="primary" clearable
                                            @click:clear="clearSelectedAccountTypes()"
                                            :menu-props="{ top: false, offsetY: true }" :attach="true"
                                            autocomplete="donotautocomplete"></v-select>
                                    </v-col>
                                    <!-- Group Structure -->
                                    <v-col cols="12" xs="12" :md="inputCols" class="">
                                        <v-select label="Group Structure *" v-model="selectedOrgGroupStructure"
                                            :rules="rules.orgGroupStructure" :items="orgGroupStructure" outlined dense
                                            hide-details="auto" background-color="white" color="primary" clearable
                                            @click:clear="clearSelectedOrgGroupStructure()"
                                            :menu-props="{ top: false, offsetY: true }" :attach="true"
                                            autocomplete="donotautocomplete"></v-select>
                                    </v-col>
                                    <!-- Parent -->
                                    <v-col cols="12" xs="12" :md="inputCols" class=""
                                        v-if="selectedOrgGroupStructure === 'Home'">
                                        <v-select label="Group *" v-model="selectedOrgGroupId"
                                            :items="computedOrgGroups" item-value="orgId" item-text="orgName" outlined
                                            dense hide-details="auto" background-color="white" color="primary" clearable
                                            @click:clear="clearSelectedOrgGroupStructure()"
                                            :menu-props="{ top: false, offsetY: true }" :attach="true"
                                            autocomplete="donotautocomplete"></v-select>
                                    </v-col>
                                    <v-col cols="12" xs="12" :md="inputCols" class=""
                                        v-if="selectedOrgGroupStructure === 'Group'">
                                        <v-select label="Parent Group *" v-model="selectedOrgParentGroupId"
                                            :items="computedOrgParentGroups" item-value="orgId" item-text="orgName"
                                            outlined dense hide-details="auto" background-color="white" color="primary"
                                            clearable @click:clear="clearSelectedOrgGroupStructure()"
                                            :menu-props="{ top: false, offsetY: true }" :attach="true"
                                            autocomplete="donotautocomplete"></v-select>
                                    </v-col>
                                    <!-- Division -->
                                    <v-col cols="12" xs="12" :md="inputCols" class=""
                                        v-if="item.orgGroupStructure === 'Home' && groupDetails.orgDivisions !== '' && groupDetails.orgDivisions !== undefined">
                                        <v-select label="Division" v-model="item.orgDivisionName"
                                            :items="groupDetails.orgDivisions.split(',')" outlined dense
                                            hide-details="auto" background-color="white" color="primary" clearable
                                            @click:clear="clearSelectedOrgGroupStructure()"
                                            :menu-props="{ top: false, offsetY: true }" :attach="true"
                                            autocomplete="donotautocomplete"></v-select>
                                    </v-col>
                                    <!-- Region -->
                                    <v-col cols="12" xs="12" :md="inputCols" class=""
                                        v-if="item.orgGroupStructure === 'Home' && groupDetails.orgRegions !== '' && groupDetails.orgRegions !== undefined">
                                        <v-select label="Region" v-model="item.orgRegionName"
                                            :items="groupDetails.orgRegions.split(',')" outlined dense
                                            hide-details="auto" background-color="white" color="primary" clearable
                                            @click:clear="clearSelectedOrgGroupStructure()"
                                            :menu-props="{ top: false, offsetY: true }" :attach="true"
                                            autocomplete="donotautocomplete"></v-select>
                                    </v-col>
                                    <!-- On Demand Alias -->
                                    <v-col v-if="item.orgAccountTypes.includes('Partner')" cols="12" xs="12" md="6"
                                        class="mt-0">
                                        <v-text-field label="On Demand Alias" v-model="item.orgAlias" required outlined
                                            dense hide-details="auto" background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgAlias')"
                                            autocomplete="donoautocomplete"></v-text-field>
                                    </v-col>
                                    <!-- On Demand Partner -->
                                    <v-col v-if="item.orgAccountTypes.includes('Partner')" cols="12" xs="12"
                                        class="d-flex align-center pt-0 mt-0">
                                        <!-- <v-row dense class="d-flex align-center"> -->
                                        <v-checkbox class=" pt-0 mt-3" hide-details="auto"
                                            v-model="item.orgApprovedPartner" :true-value="1" :false-value="0"
                                            label="On Demand Partner"></v-checkbox>
                                        <!-- </v-row> -->
                                    </v-col>

                                    <!-- Tags Edit -->
                                    <v-col cols="12" xs="12" md="12" class=""
                                        v-if="item.orgAccountTypes.includes('Prospect')">
                                        <v-autocomplete multiple small-chips deletable-chips hide-selected label="Tags"
                                            v-model="selectedOrgTags" :search-input.sync="tagAutocomplete"
                                            @change="tagAutocomplete = ''" :items="orgTags" outlined dense
                                            hide-details="auto" background-color="white" color="primary"
                                            :menu-props="{ top: false, offsetY: true }" :attach="true" clearable
                                            @click:clear="clearItem('quoteChannel')"></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- CONTACT DETAILS -->
                        <v-expansion-panel class="grey lighten-4 pa-0 ma-0"
                            style="border-top: 1px solid grey !important">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Contact
                                    Details</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <v-col cols="12" xs="12" :md="inputCols" v-if="toggleContactDetails">
                                        <v-text-field label="Telephone" v-model="item.orgTelephone"
                                            :rules="rules.orgTelephone" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgTelephone')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" :md="inputCols" v-if="toggleContactDetails">
                                        <v-text-field label="Other Telephone" v-model="item.orgOtherTelephone" outlined
                                            dense hide-details="auto" background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgTelephone')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" :md="inputCols" v-if="toggleContactDetails">
                                        <v-text-field label="Email" v-model="item.orgEmail" :rules="rules.orgEmail"
                                            outlined dense hide-details="auto" background-color="white" color="primary"
                                            clearable @click:clear="clearItem('orgEmail')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" :md="inputCols" v-if="toggleContactDetails">
                                        <v-text-field label="Website" v-model="item.orgWebsite"
                                            :rules="rules.orgWebsite" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgWebsite')"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- ADDRESS DETAILS -->
                        <v-expansion-panel class="grey lighten-4 pa-0 ma-0"
                            style="border-top: 1px solid grey !important">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Address
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <v-col cols="12" xs="12" :md="inputCols">
                                        <v-text-field label="Address Line 1" v-model="item.orgAddressLine1"
                                            :rules="rules.orgAddressLine1" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgAddressLine1')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" :md="inputCols">
                                        <v-text-field label="Address Line 2" v-model="item.orgAddressLine2"
                                            :rules="rules.orgAddressLine2" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgAddressLine2')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" :md="inputCols">
                                        <v-text-field label="Address Line 3" v-model="item.orgAddressLine3"
                                            :rules="rules.orgAddressLine3" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgAddressLine3')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" :md="inputCols">
                                        <v-text-field label="Town" v-model="item.orgTown" :rules="rules.orgTown"
                                            outlined dense hide-details="auto" background-color="white" color="primary"
                                            clearable @click:clear="clearItem('orgTown')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" :md="inputCols">
                                        <v-text-field label="County" v-model="item.orgCounty" :rules="rules.orgCounty"
                                            outlined dense hide-details="auto" background-color="white" color="primary"
                                            clearable @click:clear="clearItem('orgCounty')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" :md="inputCols">
                                        <v-text-field label="Postcode" v-model="item.orgPostcode"
                                            :rules="rules.orgPostcode" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgPostcode')"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- ORGANISATION LOGO -->
                        <v-expansion-panel class="grey lighten-4 pa-0 ma-0"
                            style="border-top: 1px solid grey !important">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">{{ orgtype
                                    }} Logo
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <v-col cols="12" sm="12" class="">
                                        <div class="text-left primary--text text-h6 font-weight-light">Upload
                                            Organisation Logo
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="12" class="">
                                        <FileUpload accept="image/jpeg, image/jpg" label="Select file"
                                            buttonClass="primary" progressClass="pa-2" folderLocation="organisationLogo"
                                            :fileName="MIX_generateId()" :selectedItem="item" :compressionMaxSizeMB="1"
                                            :compressionmaxWidthOrHeight="1920" :private="true" :isProfile="false"
                                            v-on:update="uploadImage" />
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- SUBDOMAIN AND BRANDIND -->
                        <v-expansion-panel class="grey lighten-4 pa-0 ma-0"
                            style="border-top: 1px solid grey !important">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">{{ orgtype
                                    }}
                                    Subdomain & Branding</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <v-col cols="12" xs="12" :md="inputCols">
                                        <v-text-field label="Subdomain" v-model="item.orgSubdomain"
                                            :rules="rules.orgSubdomain" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgSubdomain')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="3" class="d-flex justify-end">
                                        <v-btn height="40" block @click="checkBrandingExists(item.orgSubdomain)"
                                            class="grey--text text--lighten-2" depressed color="primary"
                                            :disabled="item.orgName === ''" :loading="checkBrand">Validate</v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6" class="d-flex justify-start"
                                        v-if="item.orgSubdomain !== undefined">
                                        <v-text-field outlined dense hide-details="auto" background-color="white"
                                            color="primary"
                                            :label="`https://ondemand.oomphwellness.org/${item.orgSubdomain}`" clearable
                                            readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="3" class="d-flex justify-end"
                                        v-if="item.orgSubdomain !== undefined">
                                        <v-btn height="40" block @click="copyURL(item.orgSubdomain)"
                                            class="grey--text text--lighten-2" depressed color="primary"
                                            :disabled="item.orgName === ''" :loading="checkBrand">Copy URL</v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="12" class="d-flex justify-start">
                                        <span v-if="hasBrand" class="success--text">There is already an organisation
                                            with that name
                                            that has an assotiated brand.</span>
                                        <span v-if="hasNoBrand" class="error--text">This organisation does not have an
                                            assotiated
                                            brand so the default Oomph Branding will apply. Please save this
                                            record.</span>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- ORGANISATION DETAILS -->
                        <v-expansion-panel class="grey lighten-4 pa-0 ma-0"
                            style="border-top: 1px solid grey !important"
                            v-if="item.orgAccountTypes.includes('Customer') || item.orgAccountTypes.includes('Prospect')">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">More info
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <v-col cols="12" xs="12" :md="inputCols">
                                        <v-select label="CQC Rating" v-model="item.orgCQCRating" :items="CQCRatings"
                                            outlined dense hide-details="auto" background-color="white" color="primary"
                                            clearable @click:clear="clearItem('orgCQCRating')"
                                            :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
                                    </v-col>
                                    <v-col cols="12" xs="12" :md="inputCols">
                                        <v-text-field label="Turnover" v-model="item.orgTurnover"
                                            :rules="rules.orgTurnover" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgTurnover')"></v-text-field>
                                    </v-col>
                                    <v-col v-if="!item.orgAccountTypes.includes('Customer')" cols="12" xs="12"
                                        :md="inputCols">
                                        <v-select label="Source" v-model="item.orgSource" :items="orgSources" outlined
                                            dense hide-details="auto" background-color="white" color="primary" clearable
                                            @click:clear="clearSelectedOrgGroupStructure()"
                                            :menu-props="{ top: false, offsetY: true }" :attach="true"
                                            autocomplete="donotautocomplete"></v-select>
                                    </v-col>
                                    <v-col v-if="!item.orgAccountTypes.includes('Customer')" cols="12" xs="12"
                                        :md="inputCols">
                                        <v-text-field label="Name of Source" v-model="item.orgSourceName"
                                            :rules="rules.orgSourceName" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgSourceName')"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- FINANCE & ACCOUNTING EDIT -->
                        <v-expansion-panel v-if="!item.orgAccountTypes.includes('Customer')"
                            class="grey lighten-4 pa-0 ma-0" style="border-top: 1px solid grey !important">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Finance
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <v-col cols="12" xs="12" md="3" class="">
                                        <v-btn height="40" block @click="dialogCompaniesHouse = true"
                                            class="grey--text text--lighten-2" depressed color="primary"
                                            :disabled="item.orgName === ''">Lookup</v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6" class="">
                                        <v-text-field label="Registered Name" v-model="item.orgRegisteredName" disabled
                                            :rules="rules.orgRegisteredName" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgRegisteredName')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="3" class="">
                                        <v-text-field label="Registration Number" v-model="item.orgRegistrationNumber"
                                            disabled :rules="rules.orgRegistrationNumber" outlined dense
                                            hide-details="auto" background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgRegistrationNumber')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="3" class="">
                                        <v-btn height="40" block @click="dialogXero = true"
                                            class="grey--text text--lighten-2" depressed color="primary"
                                            :disabled="item.orgName === ''">Xero Lookup</v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6" class="">
                                        <v-text-field label="Xero Account Number" v-model="item.orgAccountNumber"
                                            :rules="rules.orgAccountNumber" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgAccountNumber')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="3">
                                        <v-btn height="40" depressed block class="grey lighten-2 light-blue--text"><img
                                                src="@/assets/xerologo.png" width="25" height="25" class="mr-2" /> Open
                                            Account</v-btn>
                                    </v-col>

                                    <v-col cols="12" xs="12" md="6">
                                        <v-text-field label="Account Rate / Fee" v-model="item.orgAccountCreditLimit"
                                            :rules="rules.orgAccountCreditLimit" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgAccountCreditLimit')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6">
                                        <v-select label="Account Frequency" v-model="item.orgAccountFrequency"
                                            :items="frequencies" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgAccountFrequency')"
                                            :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
                                    </v-col>

                                    <v-col cols="12" xs="12" md="6">
                                        <v-text-field label="Invoice Email" v-model="item.orgInvoiceEmail"
                                            :rules="rules.orgInvoiceEmail" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgInvoiceEmail')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6" class="d-flex align-center">
                                        <div class="">Account On Hold:</div>
                                        <div class="ml-2">
                                            <v-radio-group hide-details="auto" class="pa-0 ma-0"
                                                v-model="item.orgAccountOnHold" row dense> <v-radio label="Yes"
                                                    value="1"></v-radio> <v-radio label="No" value="0"></v-radio>
                                            </v-radio-group>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- ACCOUNT OWNER -->
                        <v-expansion-panel class="grey lighten-4 pa-0 ma-0"
                            style="border-top: 1px solid grey !important">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Account
                                    Owner</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <v-col cols="12" xs="12" md="12">
                                        <v-autocomplete label="Account Owner" v-model="selectedOwner" :items="users"
                                            :item-text="(item) => item.userName" :rules="rules.orgOwner"
                                            auto-select-first return-object outlined :dense="dense" hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearSelectedOwner()"> </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- SOCIAL MEDIA -->
                        <v-expansion-panel v-if="!item.orgAccountTypes.includes('Customer')"
                            class="grey lighten-4 pa-0 ma-0" style="border-top: 1px solid grey !important">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Social
                                    Media</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <v-col cols="12" xs="12" md="6">
                                        <v-text-field label="Twitter" v-model="item.orgTwitter"
                                            :rules="rules.orgTwitter" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgTwitter')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6">
                                        <v-text-field label="Facebook Link" v-model="item.orgFacebook"
                                            :rules="rules.orgFacebook" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgFacebook')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6">
                                        <v-text-field label="LinkedIn Profile" v-model="item.orgLinkedIn"
                                            :rules="rules.orgLinkedIn" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgLinkedIn')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6">
                                        <v-text-field label="Instagram Handle" v-model="item.orgInstagram"
                                            :rules="rules.orgInstagram" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('orgInstagram')"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- STATUS -->
                        <v-expansion-panel class="grey lighten-4 pa-0 ma-0"
                            style="border-top: 1px solid grey !important">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">{{ orgtype
                                    }} Status
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <v-col cols="12" xs="12" md="6">
                                        <v-select label="Customer Stage" v-model="selectedStage" :rules="rules.orgStage"
                                            :items="orgStages" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearItem('leadStages')"
                                            :menu-props="{ top: false, offsetY: true }" :attach="true">
                                            <template v-slot:selection="data">
                                                {{ data.item.stageName }}
                                            </template>
                                            <template v-slot:item="data">
                                                {{ data.item.stageName }}
                                            </template>
                                        </v-select>
                                        <!-- <v-select label="Opportunity Stage" v-model="item.opportunityStage" :rules="rules.leadStatus" :items="opportunityStages" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadStatus')" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select> -->
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select label="Status" v-model="item.orgStatus" :rules="rules.orgStatus"
                                            :items="orgStatus" outlined dense hide-details="auto"
                                            background-color="white" color="primary" clearable
                                            @click:clear="clearFilter('orgStatus')"
                                            :menu-props="{ top: false, offsetY: true }" :attach="true"
                                            autocomplete="donotautocomplete"></v-select>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6">
                                        <v-text-field label="Customer ID" v-model="item.id" disabled outlined dense
                                            hide-details="auto" background-color="white" color="primary"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- TAGS -->
                        <v-expansion-panel class="grey lighten-4 pa-0 ma-0"
                            style="border-top: 1px solid grey !important"
                            v-if="item.orgAccountTypes.includes('Partner')">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Tags</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <v-col cols="12" class="d-flex justify-space-between">
                                        <v-btn-toggle v-model="selectedTags" style="width: 100% !important" multiple
                                            dense class="flex-wrap">
                                            <v-btn style="width: 25% !important" value="dementia friendly">
                                                <div class="mx-3">Dementia Friendly</div>
                                            </v-btn>
                                            <v-btn style="width: 25% !important" value="reduced mobility">
                                                <div class="mx-3">Reduced Mobility</div>
                                            </v-btn>
                                            <v-btn style="width: 25% !important" value="visual impairment">
                                                <div class="mx-3">Visual Impairment</div>
                                            </v-btn>
                                            <v-btn style="width: 25% !important" value="hearing impairment">
                                                <div class="mx-3">Hearing Impairment</div>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-form>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogCompaniesHouse" width="1024">
            <v-card class="mx-auto rounded">
                <companies-house :datadown="item.orgName" :key="dialogCompaniesHouseKey" v-on:updatech="updateCHDetails"
                    v-on:closech="closeCH" />
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogXero" width="1024">
            <v-card class="mx-auto rounded">
                <xero :datadown="item.orgName" :key="dialogXeroKey" v-on:updatech="updateXeroDetails"
                    v-on:closech="closeCH" />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import companiesHouse from '@/views/organisations/CompaniesHouse.vue';
import xero from '@/views/organisations/Xero.vue';
export default {
    name: 'OrganisationDetails',
    components: {
        'companies-house': companiesHouse,
        xero: xero,
    },
    props: {
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
        dense: {
            type: Boolean,
            default: false,
        },
        itemnew: {
            type: Boolean,
            default: false,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        orgtype: {
            type: String,
            default: '',
        },
    },
    // * DATA
    data: () => ({
        accountTypes: [],
        addManualAddress: false,
        addNotesToggle: false,
        address: {},
        checkBrand: false,
        countries: [],
        CQCRatings: [],
        dialogCompaniesHouse: false,
        dialogCompaniesHouseKey: 0,
        dialogXero: false,
        dialogXeroKey: 0,
        editmode: false,
        filter: {
            orgStatus: 'Active',
            country: '',
        },
        frequencies: ['Daily', 'Weekly', 'Fortnightly', 'Monthly', 'Bi-Monthly', 'Quarterly', 'Yearly'],
        groupDetails: '',
        hasBrand: false,
        hasNoBrand: false,
        industries: [],
        item: {},
        loading: true,
        localAuthority: [],
        orgGroups: [],
        orgGroupStructure: [],
        orgStages: [],
        orgStatus: [],
        orgTags: [],
        panelFixed: 0,
        panelVariable: 0,
        parentGroupDetails: '',
        rules: {},
        selectedAccountType: [],
        selectedChannel: [],
        selectedFrequency: [],
        selectedIndustry: [],
        selectedOrgGroupId: '',
        selectedOrgGroupStructure: '',
        selectedOrgParentGroupId: '',
        selectedOrgTags: [],
        selectedOwner: {
            ownerUserId: "",
            ownerUserName: "",
            ownerUserEmail: ""
        },
        selectedStage: [],
        selectedTags: [],
        showGroup: '',
        showGroupTab: false,
        showRegion: '',
        tagAutocomplete: '',
        toggleAddress: true,
        toggleContactDetails: true,
        toggleFinance: false,
        toggleGroupStructure: true,
        toggleLogo: false,
        toggleOrganisationInfo: false,
        toggleOtherAddress: false,
        toggleOwner: false,
        toggleSocial: false,
        toggleTags: false,
        users: [],
    }),
    computed: {
        inputCols() {
            if (this.listshrunk) {
                return '3';
            } else {
                return '6';
            }
        },
        computedLocalAuthority() {
            if (this.filter.country === '') {
                return [];
            } else {
                return this.localAuthority.filter((item) => {
                    return item.country === this.filter.country;
                });
            }
        },
        computedOrgGroups() {
            let groups = [...this.orgGroups];
            // Filter to include only groups that are not marked as deleted
            let filteredGroups = groups.filter(item => {
                return item.orgGroupStructure === 'Group' && item.deleted == 0;
            });
            return filteredGroups;
        },

        computedOrgParentGroups() {
            let groups = [...this.orgGroups];
            // Filter to include only parent groups that are not marked as deleted
            let filteredParentGroups = groups.filter(item => {
                return item.orgGroupStructure === 'Parent Group' && item.deleted == 0;
            });
            return filteredParentGroups;
        },
        computedProfileScore() {
            let score = 0;
            let totalScore = 14;
            if (this.item?.orgLogo !== '' && this.item?.orgLogo !== undefined && this.item?.orgLogo !== null) {
                score += 1;
            }
            if (this.item?.orgName !== '' && this.item?.orgName !== undefined && this.item?.orgName !== null) {
                score += 1;
            }
            if (this.item?.orgAccountTypes !== '' && this.item?.orgAccountTypes !== undefined && this.item?.orgAccountTypes !== null) {
                score += 1;
            }
            if (this.item?.orgTelephone !== '' && this.item?.orgTelephone !== undefined && this.item?.orgTelephone !== null) {
                score += 1;
            }
            if (this.item?.orgWebsite !== '' && this.item?.orgWebsite !== undefined && this.item?.orgWebsite !== null) {
                score += 1;
            }
            if (this.item?.orgEmail !== '' && this.item?.orgEmail !== undefined && this.item?.orgEmail !== null) {
                score += 1;
            }
            if (this.item?.orgGroupStructure !== '' && this.item?.orgGroupStructure !== undefined && this.item?.orgGroupStructure !== null) {
                score += 1;
            }
            if (this.iten?.orgRegisteredName !== '' && this.item?.orgRegisteredName !== undefined && this.item?.orgRegisteredName !== null) {
                score += 1;
            }
            if (this.item?.orgRegistrationNumber !== '' && this.item?.orgRegistrationNumber !== undefined && this.item?.orgRegistrationNumber !== null) {
                score += 1;
            }
            if (this.item?.orgInvoiceEmail !== '' && this.item?.orgInvoiceEmail !== undefined && this.item?.orgInvoiceEmail !== null) {
                score += 1;
            }
            if (this.item?.orgPurchasingEmail !== '' && this.item?.orgPurchasingEmail !== undefined && this.item?.orgPurchasingEmail !== null) {
                score += 1;
            }
            if (this.item?.orgAccountCreditLimit !== '' && this.item?.orgAccountCreditLimit !== undefined && this.item?.orgAccountCreditLimit !== null) {
                score += 1;
            }
            if (this.item?.orgAccountOnHold !== '' && this.item?.orgAccountOnHold !== undefined && this.item?.orgAccountOnHold !== null) {
                score += 1;
            }
            if (this.iten?.orgAddressLine1 !== '' && this.item?.orgAddressLine1 !== undefined && this.item?.orgAddressLine1 !== null) {
                score += 1;
            }
            if (this.item?.orgAddressLine2 !== '' && this.item?.orgAddressLine2 !== undefined && this.item?.orgAddressLine2 !== null) {
                score += 1;
            }
            if (this.item?.orgTown !== '' && this.item?.orgTown !== undefined && this.item?.orgTown !== null) {
                score += 1;
            }
            if (this.item?.orgCounty !== '' && this.item?.orgCounty !== undefined && this.item?.orgCounty !== null) {
                score += 1;
            }
            if (this.item?.orgPostcode !== '' && this.item?.orgPostcode !== undefined && this.item?.orgPostcode !== null) {
                score += 1;
            }
            if (this.item?.orgCountry !== '' && this.item?.orgCountry !== undefined && this.item?.orgCountry !== null) {
                score += 1;
            }
            if (this.item?.orgSector !== '' && this.item?.orgSector !== undefined && this.item?.orgSector !== null) {
                score += 1;
            }
            if (this.item?.orgTurnover !== '' && this.item?.orgTurnover !== undefined && this.item?.orgTurnover !== null) {
                score += 1;
            }
            if (this.item?.orgSource !== '' && this.item?.orgSource !== undefined && this.item?.orgSource !== null) {
                score += 1;
            }
            if (this.item?.orgSourceName !== '' && this.item?.orgSourceName !== undefined && this.item?.orgSourceName !== null) {
                score += 1;
            }

            if (this.item?.orgTwitter !== '' && this.item?.orgTwitter !== undefined && this.item?.orgTwitter !== null) {
                score += 1;
            }
            if (this.item?.orgLinkedIn !== '' && this.item?.orgLinkedIn !== undefined && this.item?.orgLinkedIn !== null) {
                score += 1;
            }
            if (this.item?.orgFacebook !== '' && this.item?.orgFacebook !== undefined && this.item?.orgFacebook !== null) {
                score += 1;
            }
            if (this.item?.orgInstagram !== '' && this.item?.orgInstagram !== undefined && this.item?.orgInstagram !== null) {
                score += 1;
            }
            let scoring = Math.round((score / totalScore) * 100);
            switch (true) {
                case scoring >= 0 && scoring < 25:
                    return {
                        score: scoring,
                        color: 'error',
                        name: 'Weak',
                        message: 'This profile is weak, please add more information.',
                    };
                case scoring >= 25 && scoring < 75:
                    return {
                        score: scoring,
                        color: 'warning',
                        name: 'Intermediate',
                        message: 'This profile is ok, please add more information.',
                    };

                case scoring >= 75:
                    return {
                        score: scoring,
                        color: 'success',
                        name: 'Strong',
                        message: 'This profile is good',
                    };
                default:
                    return {
                        score: 0,
                        color: 'info',
                        name: '',
                        message: '',
                    };
            }
        },
    },
    // * METHODS
    methods: {
        async checkBrandingExists(domain) {
            let t = this;
            t.checkBrand = true;
            let itemResult = await this.MIX_redisSearch("idx:brands", `@deleted:0  @brandName:${domain}`, 0, 9999);
            if (itemResult.data.total > 0) {
                t.hasBrand = true;
                t.hasNoBrand = false;
            } else {
                t.hasBrand = false;
                t.hasNoBrand = true;
            }
            t.checkBrand = false;
        },
        copyURL(domain) {
            let t = this;
            let copyText = 'https://ondemand.oomphwellness.org/' + domain;
            t.$clipboard(copyText);
        },
        uploadImage(url, file) {
            let t = this;
            t.item.orgLogo = url;
        },
        async initialise() {
            this.loading = true;
            // Load Datadown Prop into Item
            this.item = { ...this.datadown };
            // Get Org Sources
            let orgSources = await this.MIX_redisReadSet('set:sources', false);
            if (orgSources.code === 1) {
                this.orgSources = orgSources.data;
            }
            // Get Account Types
            let accountTypes = await this.MIX_redisReadSet('set:orgAccountTypes', false);
            if (accountTypes.code === 1) {
                this.accountTypes = accountTypes.data;
            }
            // Get Group Structures
            let groupStructureResult = await this.MIX_redisReadSet('set:orgGroupStructure', false);
            if (groupStructureResult.code === 1) {
                this.orgGroupStructure = groupStructureResult.data;
            }
            // Get CQC Ratings
            let CQCRatingResult = await this.MIX_redisReadSet('set:CQCRating', false);
            if (CQCRatingResult.code === 1) {
                this.CQCRatings = CQCRatingResult.data;
            }
            // Get Organisation Status
            let orgStatus = await this.MIX_redisReadSortedSet('sset:orgStatus', 0, 9999);
            if (orgStatus.code === 1) {
                this.orgStatus = orgStatus.data;
            }
            // Get Organisation Tags
            let orgTags = await this.MIX_redisReadSet('set:orgTags', false);
            if (orgTags.code === 1) {
                this.orgTags = orgTags.data;
            }
            // Get Groups and Parent Groups
            let orgGroups = await this.MIX_redisOrderedSearch('idx:orgs', `@orgAccountTypes:{Customer} @orgGroupStructure:{Group|Parent Group}`, 0, 9999, 'orgName');
            orgGroups = orgGroups.data.documents.map((x) => x.value);
            this.orgGroups = orgGroups.map((x) => {
                return {
                    orgId: x.id,
                    orgName: x.orgName,
                    orgGroupStructure: x.orgGroupStructure,
                    orgGroupId: x.orgGroupId,
                    orgParentGroupId: x.orgParentGroupId,
                    orgDivisions: x.orgDivisions,
                    orgRegions: x.orgRegions,
                    // add deleted flag from database and associate it to the orgGroups
                    deleted: x.deleted
                };
            });
            //Set Selected Group Structure
            if (this.item.orgGroupStructure !== '' && this.item.orgGroupStructure !== undefined) {
                this.selectedOrgGroupStructure = this.item.orgGroupStructure;
            } else {
                this.selectedOrgGroupStructure = '';
            }
            // Set Selected Org Group
            if (this.item.orgGroupId !== '' && this.item.orgGroupId !== undefined) {
                // let orgGroup = this.orgGroups.find((x) => x.orgId === this.item.orgGroupId);
                this.selectedOrgGroupId = this.item.orgGroupId;
                this.groupDetails = this.orgGroups.find((x) => x.orgId === this.item.orgGroupId);
            } else {
                this.selectedOrgGroupId = '';
            }
            // Set SElected Oor Parent Group
            if (this.item.orgParentGroupId !== '' && this.item.orgParentGroupId !== undefined) {
                // let orgParentGroup = this.orgGroups.find((x) => x.orgId === this.item.orgParentGroupId);
                this.selectedOrgParentGroupId = this.item.orgParentGroupId;
                this.parentGroupDetails = this.orgGroups.find((x) => x.orgId === this.item.orgParentGroupId);
            } else {
                this.selectedOrgParentGroupId = '';
            }


            if (this.item.orgTags !== '' && this.item.orgTags !== undefined && this.item.orgTags !== null) {
                if (this.item.orgAccountTypes.includes('Partners')) {
                    this.selectedTags = this.item.orgTags.split(',');
                } else {
                    this.selectedOrgTags = this.item.orgTags.split(',')
                }
            }

            // Get Users for Contract Owners Dropdown
            let users = await this.MIX_users("Sales,Developers,Administration");
            this.users = users.map((x) => {
                return {
                    userId: x.id,
                    userName: x.userName,
                    userEmail: x.userEmail,
                };
            });

            // Get Account Stages
            let orgStages = await this.MIX_redisOrderedSearch('idx:stages', `@stagePipeline:{Accounts}`, 0, 9999, 'stageValue');
            orgStages = orgStages.data.documents.map((x) => x.value);
            this.orgStages = orgStages.map((x) => {
                return {
                    stageId: x.id,
                    stageName: x.stageName,
                };
            });
            // if New item then enable EDit mode
            if (this.itemnew) {
                this.editmode = true;
                // Set Selected Account Type or Org Type
                switch (this.orgtype) {
                    case 'Customer':
                        if (this.selectedAccountType.findIndex((x) => x === 'Customer') === -1) {
                            this.selectedAccountType.push('Customer');
                        }
                        break;
                    // TODO - RG - Add other account types
                }
            } else {
                // Set Selected Account Type to Org Account Types
                if (this.item.orgAccountTypes !== '' && this.item.orgAccountTypes !== undefined && this.item.orgAccountTypes !== null) {
                    this.selectedAccountType = this.item.orgAccountTypes.split(',');
                }
                if (this.item.ownerUserId !== '') {
                    this.selectedOwner = {
                        userId: this.item.ownerUserId,
                        userName: this.item.ownerUserName,
                        userEmail: this.item.ownerUserEmail,
                    }
                }
                // Set Selected Tags to Org Tags
                if (this.item.orgTags !== '' && this.item.orgTags !== undefined && this.item.orgTags !== null) {
                    this.selectedOrgTags = this.item.orgTags.split(',');
                }
                // Set Selected Stage to Current Org Stage
                if (this.item.orgStageId !== '' && this.item.orgStageId !== null && this.item.orgStageId !== undefined) {
                    this.selectedStage.stageId = this.item.orgStageId;
                    this.selectedStage.stageName = this.item.orgStageName;
                }
            }
            // Finished Initialising
            this.loading = false;
        },
        validateForm() {
            this.rules = {
                orgName: [(v) => !!v || 'Organisation Name Required'],
                orgAccountType: [(v) => !!v || 'Organisation Account Type Required'],
                orgStatus: [(v) => !!v || 'Organisation Status Required'],
                // orgTelephone: [(v) => !!v || 'Telephone Required'],
            };
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    this.saveItem();
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Please fill in all required fields', show: true });
                }
            });
        },
        getAddressData: function (addressData, placeResultData) {
            let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
            // //console.log(JSON.stringify(placeResult, null, 2));
            this.item.orgName = placeResult.name;
            this.item.orgAddressLine1 = placeResult.addressLine1;
            this.item.orgAddressLine2 = placeResult.addressLine2;
            this.item.orgAddressLine3 = placeResult.addressLine3;
            this.item.orgTown = placeResult.town;
            this.item.orgCounty = placeResult.county;
            this.item.orgPostcode = placeResult.postcode;
            this.item.orgCountry = placeResult.country;
            this.item.orgLatitude = placeResult.lat;
            this.item.orgLongitude = placeResult.lng;
            this.item.orgTelephone = placeResult.elephone;
            this.item.orgWebsite = placeResult.website;
            // this.showMeetingAddress = true
        },
        cancelItem() {
            this.item = { ...this.datadown };
            this.editmode = false;
            this.$emit('cancel');
        },
        // Save Item
        saveItem() {
            if (JSON.stringify(this.selectedAccountType) !== '[]') {
                this.selectedAccountType = this.selectedAccountType.filter((item) => {
                    return item !== '';
                });
                this.item.orgAccountTypes = this.selectedAccountType.join(',');
            }
            // Save Group Structure
            switch (this.selectedOrgGroupStructure) {
                case 'Home':
                    this.item.orgGroupStructure = this.selectedOrgGroupStructure;
                    // Save Group Id
                    if (this.selectedOrgGroupId !== '') {
                        this.item.orgGroupId = this.selectedOrgGroupId;
                    } else {
                        this.item.orgGroupId = '';
                    }
                    this.item.orgParentGroupId = '';
                    break;
                case 'Group':
                    this.item.orgGroupStructure = this.selectedOrgGroupStructure;
                    this.item.orgGroupId = '';
                    // Save Parent Group Id
                    if (this.selectedOrgParentGroupId !== '') {
                        this.item.orgParentGroupId = this.selectedOrgParentGroupId;
                    } else {
                        this.item.orgParentGroupId = '';
                    }
                    break;
                case 'Parent Group':
                    this.item.orgGroupStructure = this.selectedOrgGroupStructure;
                    this.item.orgGroupId = '';
                    this.item.orgParentGroupId = '';
                    break;
                default:
                    this.item.orgGroupStructure = '';
                    this.item.orgGroupId = '';
                    this.item.orgParentGroupId = '';
                    break;
            }
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Clear Selected
        clearSelectedAccountTypes() {
            setTimeout(() => {
                this.selectedAccountType = [];
            }, 1);
        },
        // Clear Selected Channel
        clearSelectedChannel() {
            setTimeout(() => {
                this.selectedChannel = [];
            }, 1);
        },
        // Clear Selected Industry
        clearSelectedIndustries() {
            setTimeout(() => {
                this.selectedIndustry = [];
            }, 1);
        },
        // Clear Selected Industry
        clearSelectedFrequencies() {
            setTimeout(() => {
                this.selectedFrequency = [];
            }, 1);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // Clear Item Property
        clearFilter(property) {
            setTimeout(() => {
                this.filter[property] = '';
            }, 1);
        },
        updateCHDetails(value) {
            this.item.orgRegisteredName = value.registeredName;
            this.item.orgRegistrationNumber = value.registrationNumber;
            this.dialogCompaniesHouse = false;
            this.dialogCompaniesHouseKey++;
        },
        updateXeroDetails(value) {
            // //console.log(value)
        },
        closeCH() {
            this.dialogCompaniesHouse = false;
            this.dialogCompaniesHouseKey++;
        },
        formatTag: function (item) {
            if (item !== null) {
                return item.toLowerCase(); //.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            } else {
                return '';
            }
        },
        getUsers() {
            let t = this;
            t.MIX_firestore_readMany('users', 1).then((users) => {
                t.users = users.data;
            });
        },
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
        },
        selectedStage: {
            handler: function () {
                this.item.orgStageName = this.selectedStage.stageName;
                this.item.orgStageId = this.selectedStage.stageId;
            },
        },
        selectedOwner: {
            handler: function () {
                // //console.log('changing owner = ' + JSON.stringify(this.selectedOwner, null, 2))
                this.item.ownerUserId = this.selectedOwner.userId
                this.item.ownerUserName = this.selectedOwner.userName
                this.item.ownerUserEmail = this.selectedOwner.userEmail
            }
        },
        item: {
            handler: function () {
                if ((this.item.orgGroupStructure === '1' && this.item.orgAccountTypes.includes('Customer')) || (this.item.orgGroupStructure === '1' && this.item.orgAccountTypes.includes('Prospect'))) {
                    this.showGroupTab = true;
                } else {
                    this.showGroupTab = false;
                }
                this.$emit('showGroupTab', this.showGroupTab);
            },
            deep: true,
        },
        selectedAccountType: {
            handler: function () {
                this.item.orgAccountTypes = this.selectedAccountType.join(',');
            },
        },
        selectedIndustry: {
            handler: function () {
                this.item.orgIndustry = this.selectedIndustry.join(',');
            },
        },
        selectedChannel: {
            handler: function () {
                this.item.orgChannel = this.selectedChannel.join(',');
            },
        },
        // Selected Tags
        selectedOrgTags: {
            handler: async function () {
                // Update Quote Tags and split out array with commas
                this.item.orgTags = this.selectedOrgTags.join(',');
            },
            deep: true,
        },
        itemnew: {
            handler: function () {
                if (this.itemnew) {
                    this.editmode = true;
                }
            },
        },
        // Selected Tags
        selectedTags: {
            handler: async function () {
                for (let i = 0; i < this.selectedTags.length; i++) {
                    this.selectedTags[i] = this.selectedTags[i].toLowerCase();
                    this.selectedTags[i] = this.selectedTags[i].replace(/[\W_]+/g, ' ');
                    if (this.selectedTags[i] === ' ') {
                        this.selectedTags.splice(i, 1);
                    } else {
                        this.selectedTags[i] = this.selectedTags[i].trim();
                    }
                }
                // Update Content Tags and split out array with commas
                this.item.orgTags = this.selectedTags.join(',');

            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        this.initialise();
    },
};
</script>

<style>
#expansion-panel-content-1>* {
    padding-top: 0px;
    padding-right: 12px;
    padding-bottom: 0px;
    padding-left: 12px;
}
</style>
