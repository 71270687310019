 <template>
    <v-container id="overlay" class="primary pa-0 ma-0" fluid>
        <v-row class="d-flex appPrimary py-2" no-gutters>
            <v-col cols="12">
                <v-btn v-if="$route.params.id" @click="$router.push(`/home`)" class="ml-4 white--text" text depressed small><v-icon>oomph-back</v-icon>Back</v-btn>
                <v-btn
                    v-else-if="readingMode === true"
                    @click="
                        readingMode = false;
                        instructionPage = true;
                    "
                    class="ml-4 white--text"
                    text
                    depressed
                    small
                    ><v-icon>oomph-back</v-icon>Back</v-btn
                >
                <v-btn v-else @click="$emit('close')" class="ml-4 white--text" text depressed small><v-icon>oomph-back</v-icon>Back</v-btn>
            </v-col>
        </v-row>
        <!-- Intro page -->
        <v-col v-if="introPage" class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <div class="circle" style="z-index: 200 !important">
                <div class="text-body-1 white--text pa-2">{{ contentItem.contentDurationMins }} <br />mins</div>
            </div>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage"></v-img>
            </v-col>
            <v-col
                :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                class="d-flex align-stretch pa-0 ma-0"
                v-touch="{
                    left: () => {
                        (introPage = false), (quizPage = true);
                    },
                }"
            >
                <v-card class="elevation-0 rounded-0 px-6" width="100%" height="100vh">
                    <v-card-title class="text-h4">
                        <v-row>
                            <v-col cols="12" class="text-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="text-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="text-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="text-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="text-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="text-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingMode = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text class="pt-0 px-6 mt-5">
                        <div class="text-body-1">{{ contentItem.contentSubtitle }}</div>
                    </v-card-text>
                    <v-card-text class="pt-0">
                        <v-row no-gutters>
                            <v-col cols="8" class="py-4">
                                <div class="text-h5 primary--text pb-2">Description</div>
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end py-4">
                                <v-btn v-if="!addedToMyList" class="primary white--text pa-4" depressed small
                                    ><v-icon class="mr-2">oomph-add-properies</v-icon>Add to My List</v-btn
                                >
                                <v-btn v-else class="error white--text pa-4" @click="removeFromMyList()" depressed small><v-icon class="mr-2">oomph-delete-document</v-icon>Remove from My List</v-btn>
                            </v-col>
                        </v-row>
                        <div class="text-body-1">{{ contentItem.contentDescription }}</div>
                    </v-card-text>
                    <v-card-text class="pt-0">
                        <div class="text-h6 primary--text my-2">Suitable for:</div>
                        <v-row class="d-flex align-center ml-2 pt-4">
                            <v-col v-if="contentItem.contentTags.includes('dementia friendly')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-learning</v-icon>
                                <div class="text-body-2 ml-2">Dementia Friendly</div>
                            </v-col>
                            <v-col v-if="contentItem.contentTags.includes('visual impairment')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-eye</v-icon>
                                <div class="text-body-2 ml-2">Visual Impairment</div>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center ml-2 pt-4">
                            <v-col v-if="contentItem.contentTags.includes('group') || contentItem.contentTags.includes('small group')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-user-groups</v-icon>
                                <div v-if="contentItem.contentTags.includes('group')" class="text-body-2 ml-2">Group</div>
                                <div v-if="contentItem.contentTags.includes('small group')" class="text-body-2 ml-2">Small Group</div>
                            </v-col>
                            <v-col v-if="contentItem.contentTags.includes('hearing impairment')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-listen</v-icon>
                                <div class="text-body-2 ml-2">Hearing Impairment</div>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center ml-2 pt-4">
                            <v-col v-if="contentItem.contentTags.includes('independent')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-account</v-icon>
                                <div class="text-body-2 ml-2">Independent</div>
                            </v-col>
                            <v-col v-if="contentItem.contentTags.includes('one to one')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-group</v-icon>
                                <div class="text-body-2 ml-2">One to One</div>
                            </v-col>
                        </v-row>
                        <!-- Reduced Mobiltiy - START -->
                        <v-row class="d-flex align-center ml-2 pt-4">
                            <v-col v-if="contentItem.contentTags.includes('reduced mobility')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-wheelchair</v-icon>
                                <div class="text-body-2 ml-2">Reduced Mobility</div>
                            </v-col>
                        </v-row>
                        <!-- Reduced Mobiltiy - END -->
                    </v-card-text>
                    <v-card-text>
                        <div class="text-h6 primary--text mt-3 mr-2">Tags:</div>
                        <v-chip :to="'/search/' + item" class="secondary black--text mr-1 mt-4" v-for="(item, index) in tags" :key="index" label>{{
                            item.charAt(0).toUpperCase() + item.slice(1)
                        }}</v-chip>
                    </v-card-text>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            v-if="adaption"
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = false;
                                adaptionPage = true;
                            "
                            text
                            >Adaptions<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                        <v-btn
                            v-else
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = false;
                                quizPage = true;
                                getQuizQuestions();
                            "
                            text
                            >Launch Quiz<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <!-- Adaption page -->
        <v-col v-if="adaptionPage" class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <div class="circle">
                <div class="text-body-1 white--text pa-2">{{ contentItem.contentDurationMins }} <br />mins</div>
            </div>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col
                :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'"
                class="d-flex align-stretch pa-0 ma-0"
                v-touch="{
                    left: () => {
                        (adaptionPage = false), (quizPage = true);
                    },
                    right: () => {
                        (adaptionPage = false), (introPage = true);
                    },
                }"
            >
                <v-card class="elevation-0 rounded-0 px-6" width="100%" height="100vh">
                    <v-card-title class="text-h4">
                        <v-row>
                            <v-col cols="12" class="text-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="text-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="text-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="text-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="text-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="text-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingMode = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card class="elevation-0 mt-2" height="60vh" style="overflow-y: scroll">
                        <v-card-text v-if="adaption !== ''" class="py-0 px-6">
                            <div class="text-h5 primary--text pt-8 pb-2">Adaptions</div>
                            <div class="text-body-1" v-html="adaption"></div>
                        </v-card-text>
                    </v-card>
                    <div v-if="adaption.length > 2150" class="d-flex align-center justify-end">Scroll for more<v-icon color="primary" class="ml-2">oomph-down-button</v-icon></div>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = true;
                                adaptionPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Intro</v-btn
                        >
                        <v-btn
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                instructionPage = true;
                                adaptionPage = false;
                            "
                            text
                            >Instructions<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <v-col
            v-if="instructionPage"
            class="d-flex pa-0 ma-0"
            :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'"
            v-touch="{
                left: () => {
                    (pdfPage = true), (instructionPage = false);
                },
                right: () => {
                    (introPage = true), (instructionPage = false);
                },
            }"
        >
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0" width="100%">
                    <v-card-title class="text-h4">
                        <v-row>
                            <v-col cols="8" class="text-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end pr-8">
                                <v-btn class="primary white--text pa-2 ml-2" @click="showReviewOverlay = true" depressed medium><v-icon class="mr-2">oomph-star-2</v-icon>Add Review</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="text-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="text-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="text-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="text-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="text-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingPage = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="instructions" class="pt-0">
                        <div class="text-h5 primary--text pt-6 pb-2">Instructions</div>
                        <v-card class="elevation-0" height="62vh" style="overflow: scroll">
                            <v-card-text :style="contentbodytext" class="pb-2" v-html="instructions"></v-card-text>
                        </v-card>
                        <div v-if="instructions.length > 2150" class="d-flex align-center justify-end">Scroll for more<v-icon color="primary" class="ml-2">oomph-down-button</v-icon></div>
                    </v-card-text>
                    <v-card-text v-else class="d-flex align-center justify-center text-h4 font-weight-bold error--text text--lighten-2 mt-10">No instructions(s) have been uploaded</v-card-text>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            v-if="adaption"
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                adaptionPage = true;
                                instructionPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Adaptions</v-btn
                        >
                        <v-btn
                            v-else
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = true;
                                instructionPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Intro</v-btn
                        >
                        <v-btn
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                quizPage = true;
                                quizScore = 0;
                                instructionPage = false;
                                getQuizQuestions();
                            "
                            text
                            >Launch Quiz<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <!-- Quiz page -->
        <v-col
            v-if="quizPage"
            class="d-flex pa-0 ma-0"
            :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'"
            v-touch="{
                right: () => {
                    (introPage = true), (quizPage = false);
                },
            }"
        >
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0 px-6" width="100%" height="100vh">
                    <v-card-title class="text-h4">
                        <v-row>
                            <v-col cols="8" class="text-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end pr-8">
                                <v-btn class="primary white--text ml-2" @click="showReviewOverlay = true" depressed medium><v-icon class="mr-2">oomph-star-2</v-icon>Add Review</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="text-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="text-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="text-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="text-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="text-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingMode = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text class="pt-0">
                        <v-card class="elevation-0" height="70vh" style="overflow: scroll">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-card-title class="text-h4 primary--text mb-4">Question {{ activeQuestionIndex + 1 }}</v-card-title>
                                    <v-card-subtitle class="text-h5">{{ activeQuestion.value.questionTitle }}</v-card-subtitle>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" v-if="activeQuestion.value.questionType !== 'Image Question' && activeQuestion.value.questionType !== 'Video Question'" class="pa-4">
                                    <v-img
                                        v-if="activeQuestion.value.questionImageId"
                                        min-width="600"
                                        max-width="800"
                                        :src="bucketUrl + activeQuestion.value.questionImageId + '/' + activeQuestion.value.questionImageId + '.jpg'"
                                        contain
                                    ></v-img> 
                                </v-col>
                                <v-col cols="6">
                                    <!-- Show video if the type of question is video -->
                                    <v-card-text v-if="activeQuestion.value.questionType === 'Video Question'" class="d-flex flex-column justify-center pt-0">
                                        <mux-video
                                            :playback-id="activeQuestion.value.questionContentItemId"
                                            :poster="`https://image.mux.com/${activeQuestion.value.questionContentItemId}/thumbnail.jpg`"
                                            :env-key="video.envKey"
                                            height="350"
                                            stream-type="on-demand"
                                            preload
                                            prefer-mse
                                            controls
                                        ></mux-video>
                                    </v-card-text>
                                    <!-- Image answers -->
                                    <v-card-text v-if="activeQuestion.value.questionType === 'Image Question'" class="d-flex justify-center align-center pt-0">
                                        <v-col v-show="answer.imageId" cols="6" v-for="(answer, index) in JSON.parse(activeQuestion.value.answers)" :key="index">
                                            <v-img
                                                v-if="answer.imageId"
                                                :src="bucketUrl + activeQuestion.value.id + '/' + answer.imageId + '.jpg'"
                                                max-width="280"
                                                @click="checkAnswer(answer.correct, index, answer.points)"
                                                class="question-card"
                                            ></v-img>
                                            <v-img v-else max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                                        </v-col>
                                    </v-card-text>
                                    <!-- All remaining answer types -->
                                    <v-card-text v-else>
                                        <v-btn
                                            v-for="(answer, index) in JSON.parse(activeQuestion.value.answers)"
                                            :key="index"
                                            class="text-h6 question-card elevation-0 mb-4 rounded-lg font-weight-bold px-10 primary"
                                            @click="checkAnswer(answer.correct, index, answer.points)"
                                            block
                                            x-large
                                        >
                                            <span>{{ answer.text }}</span>
                                        </v-btn>
                                    </v-card-text>
                                    <v-col cols="12" class="px-4">
                                        <v-card-text
                                            v-if="showQuestionNote && activeQuestion.value.answerNotes !== ''"
                                            class="d-flex align-center text-h6 font-italic primary white--text pa-4 rounded-b-lg rounded-tr-lg"
                                            ><v-icon class="white--text mr-2" size="25">oomph-info</v-icon>{{ activeQuestion.value.answerNotes }}</v-card-text
                                        >
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-row class="d-flex flex-column" no-gutters>
                            <v-divider></v-divider>
                            <div>Question {{ activeQuestionIndex + 1 }} of {{ quizQuestions.length }}</div>
                            <v-progress-linear height="3vh" class="rounded-xl" :value="quizProgressPercent"></v-progress-linear>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-space-between align-center">
                        <v-btn
                            v-if="adaption && activeQuestionIndex < 1"
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                instructionPage = true;
                                quizPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Instructions</v-btn
                        >
                        <v-btn
                            v-else-if="activeQuestionIndex < 1"
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = true;
                                quizPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Intro</v-btn
                        >
                        <v-btn v-if="activeQuestionIndex > 0" color="primary" class="white--text" @click="getPreviousQuestion()" style="position: fixed !important; bottom: 10px !important" text
                            ><v-icon size="40">oomph-back-to</v-icon>Previous Question</v-btn
                        >
                        <!-- <v-btn
                            color="primary"
                            class="white--text"
                            @click="activeQuestionIndex = 0;"
                            text
                            >Restart Quiz<v-icon size="25" class="ml-2">oomph-return</v-icon></v-btn
                        > -->
                        <v-btn
                            v-show="correctAnswer && activeQuestionIndex < quizQuestions.length - 1"
                            color="primary"
                            class="white--text"
                            @click="getNextQuestion()"
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            text
                            >Next Question<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                        <v-btn
                            v-show="activeQuestionIndex >= quizQuestions.length - 1"
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                endPage = true;
                                quizPage = false;
                            "
                            text
                            >End Quiz<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <v-col
            v-if="endPage"
            class="d-flex pa-0 ma-0"
            :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'"
            v-touch="{
                left: () => {
                    (quizPage = true), (endPage = false);
                },
            }"
        >
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0" width="100%">
                    <v-card-title class="text-h4">
                        <v-row>
                            <v-col cols="8" class="text-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end pr-8">
                                <v-btn class="primary white--text pa-2 ml-2" @click="showReviewOverlay = true" depressed medium><v-icon class="mr-2">oomph-star-2</v-icon>Add Review</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="text-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="text-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="text-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="text-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="text-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingPage = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                            endPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text class="pt-0">
                        <div class="text-h5 primary--text pt-6 pb-2">Your quiz summary</div>
                        <v-card class="d-flex flex-column align-center justify-center elevation-0" height="62vh" style="overflow: scroll">
                            <div v-if="(quizScore / totalQuizPoints) * 100 < 24" class="text-h3 primary--text pt-6 pb-6">Unlucky, why not try again?</div>
                            <div v-if="(quizScore / totalQuizPoints) * 100 >= 24 && (quizScore / totalQuizPoints) * 100 <= 49" class="text-h3 primary--text pt-6 pb-6">
                                Nice try! Fancy another go?
                            </div>
                            <div v-if="(quizScore / totalQuizPoints) * 100 >= 50 && (quizScore / totalQuizPoints) * 100 <= 74" class="text-h3 primary--text pt-6 pb-6">
                                Well done! If you enjoyed this quiz we have plenty more to try
                            </div>
                            <div v-if="(quizScore / totalQuizPoints) * 100 >= 75 && (quizScore / totalQuizPoints) * 100 <= 89" class="text-h3 primary--text pt-6 pb-6">
                                Congratulations, great score!
                            </div>
                            <div v-if="(quizScore / totalQuizPoints) * 100 >= 90 && (quizScore / totalQuizPoints) * 100 <= 100" class="text-h3 primary--text pt-6 pb-6">
                                Quiz Master! You’re good at this!
                            </div>
                            <v-card-text class="text-h5 text-center pb-2"
                                >You scored <b>{{ quizScore }}</b> out of a possible <b>{{ totalQuizPoints }}</b> points.</v-card-text
                            >
                        </v-card>
                    </v-card-text>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = true;
                                endPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Intro</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <!-- Reading view -->
        <v-col v-if="readingMode" style="z-index: 999999999 !important" class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <v-col cols="12" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0 px-12 grey lighten-4" width="100%" height="100vh" style="overflow: scroll">
                    <!-- Accessibility ()-->
                    <div
                        v-if="maskOverlay"
                        class="d-flex align-center"
                        v-drag="{ axis: 'y', handle: '#someElement' }"
                        style="z-index: 9999999999; width: calc(100vw - 310px) !important; position: absolute; margin-top: 475px !important"
                    >
                        <div
                            v-if="maskOverlay"
                            class="flex-grow-1"
                            style="
                                margin-left: 50px;
                                width: 100% !important;
                                height: 200px !important;
                                position: absolute;
                                margin-top: 10px !important;
                                border: 0px black solid;
                                box-shadow: 0px 0px 0px 1000px rgba(100, 79, 16, 0.3);
                            "
                        ></div>
                    </div>
                    <v-col cols="12" class="grey lighten-4 px-12 py-0">
                        <v-img :style="greyscale ? 'filter: grayscale(100%) !important;' : ''" max-height="30vh" :src="coverImage"></v-img>
                        <v-card-title class="text-h4" :class="invert ? 'grey darken-4 white--text' : 'white'">
                            <v-row>
                                <v-col cols="3" class="d-flex justify-end">
                                    <v-btn
                                        icon
                                        :style="greyscale ? 'filter: grayscale(100%) !important;' : ''"
                                        class="primary white--text pa-5 mr-2"
                                        @click="
                                            readingMode = false;
                                            introPage = true;
                                        "
                                        depressed
                                        medium
                                        ><v-icon size="25">oomph-back-arrow</v-icon></v-btn
                                    >
                                    <v-spacer />
                                    <!-- Accessibility (TEMPLATE) - Start -->
                                    <v-btn
                                        :style="greyscale ? 'filter: grayscale(100%) !important;' : ''"
                                        class="primary white--text pa-5 mr-2"
                                        depressed
                                        icon
                                        @click="accessibilityRuler = !accessibilityRuler"
                                        ><v-icon size="25">oomph-drag-reorder</v-icon></v-btn
                                    >
                                    <v-btn :style="greyscale ? 'filter: grayscale(100%) !important;' : ''" class="primary white--text pa-5 mr-2" depressed icon @click="maskOverlay = !maskOverlay"
                                        ><v-icon size="25">oomph-grip-lines</v-icon></v-btn
                                    >
                                    <v-btn :style="greyscale ? 'filter: grayscale(100%) !important;' : ''" class="primary white--text pa-5 mr-2" depressed icon @click="invert = !invert"
                                        ><v-icon size="25">oomph-invert-colors</v-icon></v-btn
                                    >
                                    <v-btn :style="greyscale ? 'filter: grayscale(100%) !important;' : ''" class="primary white--text pa-5 mr-2" depressed icon @click="greyscale = !greyscale"
                                        ><v-icon size="25">oomph-grayscale</v-icon></v-btn
                                    >
                                    <v-btn :style="greyscale ? 'filter: grayscale(100%) !important;' : ''" class="primary white--text pa-5 mr-2" depressed icon @click="increaseFont()"
                                        ><v-icon size="25">oomph-increase-font</v-icon></v-btn
                                    >
                                    <v-btn :style="greyscale ? 'filter: grayscale(100%) !important;' : ''" class="primary white--text pa-5 mr-2" depressed icon @click="decreaseFont()"
                                        ><v-icon size="25">oomph-decrease-font</v-icon></v-btn
                                    >
                                    <!-- Accessibility (TEMPLATE) - End -->
                                    <v-btn v-if="contentItem.contentAllowDownload === '1'" class="primary white--text pa-0 mr-2" @click="saveOfflineContent()" depressed medium
                                        ><v-icon size="30">oomph-downloads-folder</v-icon></v-btn
                                    >
                                    <v-btn
                                        :style="greyscale ? 'filter: grayscale(100%) !important;' : ''"
                                        v-if="contentItem.contentAllowPDFConvert === '1'"
                                        class="primary white--text pa-5 mr-2"
                                        icon
                                        @click="createPdf(`${appUrl}/print/${contentItem.id}`)"
                                        depressed
                                        medium
                                        ><v-icon size="25">oomph-export-pdf-2</v-icon></v-btn
                                    >
                                </v-col>
                                <!-- Content Title with Accessibility - START -->
                                <v-col cols="10" :style="contenttitle" class="d-flex justify-start" style="word-break: break-word !important">
                                    {{ contentItem.contentTitle }}
                                </v-col>
                                <!-- Content Title with Accessbility - END -->
                            </v-row>
                        </v-card-title>
                        <v-card-subtitle :class="invert ? 'grey darken-4 white--text' : 'white'" :style="contentbodytext">{{ contentItem.contentSubtitle }}</v-card-subtitle>
                        <v-row class="d-flex pt-3">
                            <v-col cols="12" class="py-0">
                                <v-card height="100%" elevation="0" style="overflow: scroll" :dark="invert">
                                    <!-- Accessibility Ruler - Start-->
                                    <div v-if="accessibilityRuler" class="d-flex align-center" v-drag="{ axis: 'y', handle: '#someElement' }">
                                        <div class="primary flex-grow-1" style="width: 100% !important; height: 6px !important"></div>
                                        <div class="primary rounded-lg"><v-icon large class="pa-2 white--text">oomph-drag-reorder</v-icon></div>
                                    </div>
                                    <!-- Accessibility Ruler - End -->
                                    <div v-if="adaption !== ''">
                                        <div :style="contentsubtitle" class="pa-4" :class="greyscale ? 'grey--text text--darken-2' : 'primary--text'">
                                            Adaptions
                                            <v-btn icon class="white--text pa-0 mr-2" :class="greyscale ? 'grey' : 'primary--text'" depressed medium @click="startStopTTS(adaption)"
                                                ><v-icon size="30">oomph-listen</v-icon></v-btn
                                            >
                                        </div>
                                        <div :style="contentbodytext" class="pb-2 pl-4" v-html="adaption"></div>
                                    </div>
                                    <div :style="contentsubtitle" class="pa-4" :class="greyscale ? 'grey--text text--darken-2' : 'primary--text'">Ingredients</div>
                                    <v-card-text :style="contentbodytext" class="pb-2" v-html="ingredients"></v-card-text>
                                    <div :style="contentsubtitle" class="pa-4" :class="greyscale ? 'grey--text text--darken-2' : 'primary--text'">Method</div>
                                    <v-card-text :style="contentbodytext" class="pb-2" v-html="method"></v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-card-text :class="invert ? 'grey darken-4 white--text' : 'white'">
                            <span class="primary--text mr-2 pt-4">Tags:</span>
                            <v-chip label :to="'/search/' + item" class="secondary white--text mr-1 mt-4" v-for="(item, index) in tags" :key="index">{{ item }}</v-chip>
                        </v-card-text>
                    </v-col>
                </v-card>
            </v-col>
        </v-col>
        <v-overlay v-model="loading" style="z-index: 9999999999 !important">
            <div class="d-flex flex-column align-center">
                <v-progress-circular :size="100" color="primary" class="mb-4" indeterminate></v-progress-circular>
                <div class="text-h4">Generating PDF...</div>
            </div>
        </v-overlay>
        <!-- <Review :showReviewOverlay="showReviewOverlay" :contentItem="contentItem" @closeOverlay="showReviewOverlay = false" /> -->
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
// import Review from "../../components/content/ContentReview.vue";
import schema from '@/schema.js';

export default {
    name: 'Quiz',
    props: {
        showContentItem: {
            type: Boolean,
            default: false,
        },
        contentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        // contentItems: {
        //     type: Array,
        //     default: () => {
        //         return [];
        //     },
        // },
    },
    components: {
        // Review,
    },
    data: () => ({
        // Accessibility (DATA) - Start
        size: 1,
        letterSpacing: 0.2,
        accessibilityRuler: false,
        ttsSpeaking: false,
        tts: null,
        greyscale: false,
        invert: false,
        maskOverlay: false,
        // Accessibility (DATA) - End
        activeQuestionIndex: 0,
        adaption: '',
        adaptionPage: false,
        addedToMyList: false,
        answerAttempt: 0,
        appUrl: process.env.VUE_APP_URL,
        bucketUrl: 'https://storage.googleapis.com/oomph-portal-public/quizzes/questions/images/',
        coverImage: '',
        correctAnswer: false,
        endPage: false,
        firstAnswer: false,
        instructionPage: false,
        instructions: '',
        ingredients: '',
        introPage: true,
        quizPage: false,
        like: {},
        loading: false,
        method: '',
        methodPage: false,
        myList: [],
        myLists: [],
        normalMode: true,
        quizQuestions: [],
        readingMode: false,
        showQuestionNote: false,
        showReviewOverlay: false,
        tags: [],
        totalQuizPoints: 0,
        video: {
            title: '',
            poster: '',
            playbackId: '',
            envKey: process.env.VUE_APP_MUX,
        },
        quizScore: 0,
        quizProgressPercent: 0,

        contentItems: {}
    }),
    watch: {
        introPage() {
            let t = this;
            if (t.introPage === true) {
                t.methodPage = false;
            }
        },
    },
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        activeQuestion() {
            let t = this;
            return t.quizQuestions[t.activeQuestionIndex];
        },
        // ACCESSIBILITY (COMPUTED) - START
        contenttitle: {
            get: function () {
                let size = this.size * 2.125; // vuetify heading 4
                let letterSpacing = this.letterSpacing * 0.0073529412; // vuetify heading 4
                return 'font-size: ' + size + 'rem !important; letter-spacing: ' + letterSpacing + 'em !important; font-weigtht: 400 !important; line-height: 1.5 !important;';
            },
        },
        contentdescription: {
            get: function () {
                let size = this.size * 0.75;
                let letterSpacing = this.letterSpacing * 0.1;
                return 'font-size: ' + size + 'rem !important; letter-spacing: ' + letterSpacing + 'em !important; line-height: 1.5 !important;';
            },
        },
        contentsubtitle: {
            get: function () {
                let size = this.size * 1.5;
                let letterSpacing = this.letterSpacing * 0;
                return 'font-size: ' + size + 'rem !important; letter-spacing: ' + letterSpacing + 'rem !important; line-height: 1.5 !important;';
            },
        },
        contentbodytext: {
            get: function () {
                let size = this.size * 1;
                let letterSpacing = this.letterSpacing * 0.03125;
                return 'font-size: ' + size + 'rem !important; letter-spacing: ' + letterSpacing + 'em important; font-weigtht: 400 !important; line-height: 1.5 !important;';
            },
        },
        // ACCESSIBILITY (COMPUTED) - END
    },
    methods: {
        // ACCESSIBILITY (METHODS) - START
        increaseFont() {
            if (this.size >= 1 && this.size <= 1.75) {
                this.size = this.size + 0.25;
            }
        },
        decreaseFont() {
            if (this.size === 1) {
                this.size = 1;
            } else {
                this.size = this.size - 0.25;
            }
        },
        increaseLetterSpacing() {
            if (this.letterSpacing !== 1) {
                this.letterSpacing = this.letterSpacing + 0.25;
            } else {
                this.letterSpacing = this.letterSpacing + 0.25;
            }
        },
        decreaseLetterSpacing() {
            if (this.letterSpacing === 1) {
                this.letterSpacing = 1;
            } else {
                this.letterSpacing = this.letterSpacing - 0.25;
            }
        },
        startStopTTS(text) {
            if (this.ttsSpeaking === false) {
                this.ttsSpeaking = true;
                this.startTTS(text);
            } else {
                this.ttsSpeaking = false;
                window.speechSynthesis.cancel();
            }
        },
        startTTS(text) {
            let utter = new SpeechSynthesisUtterance();
            utter.voice = this.voice;
            utter.lang = 'en-GB';
            utter.volume = 1;
            utter.rate = 0.7;
            window.speechSynthesis.cancel();
            utter.text = this.MIX_convertTTS(text);
            window.speechSynthesis.speak(utter);
            utter.onstart = function () {
                console.log('started');
            };
            // event after text has been spoken
            utter.onend = function () {
                console.log('ended');
                this.speaking = false;
            };
        },
        // ACCESSIBILITY (METHODS) - END
        filterContentItems() {
            let t = this;
            //* Filter all content items
            t.contentItems.filter((item) => {
                //* Get cover image
                if (item.value.itemMarker === 'Cover Image') {
                    t.coverImage = item.value.itemURL;
                }
                //* Get text items
                if (item.value.itemMarker === 'Adaption Text') {
                    t.adaption = item.value.itemTextHTML;
                }
                if (item.value.itemMarker === 'Instruction Text') {
                    t.instructions = item.value.itemTextHTML;
                }
                if (item.value.itemMarker === 'Ingredient Text') {
                    t.ingredients = item.value.itemTextHTML;
                }
                if (item.value.itemMarker === 'Method Text') {
                    t.method = item.value.itemTextHTML;
                }
            });
        },
        async getQuizQuestions() {
            let t = this;
            let result = await t.MIX_redisSearch('idx:questions', `@questionParentId:{${t.contentItem.id}}`, 0, 9999);
            t.quizQuestions = result.data.documents;
            t.quizQuestions.sort((a, b) => a.value.questionOrder - b.value.questionOrder);
            t.activeQuestionIndex = 0;

            t.quizQuestions.forEach((question) => {
                const answerValues = JSON.parse(question.value.answers);
                answerValues.forEach((answer) => {
                    if (answer.correct && answer.correct == true) {
                        t.totalQuizPoints = t.totalQuizPoints + parseInt(answer.points)
                    }
                });
            });
            t.quizProgress();
        },
        checkAnswer(answer, index, points) {
            let t = this;
            if (answer === true) {
                t.showQuestionNote = true;
                t.correctAnswer = true;
                t.answerAttempt === 0 ? (t.quizScore = t.quizScore + parseInt(points)) : null;
                document.querySelectorAll('.question-card')[index].classList.remove('primary');
                document.querySelectorAll('.question-card')[index].classList.add('right-answer');
            } else if (answer === undefined || answer === '') {
                t.answerAttempt++;
                document.querySelectorAll('.question-card')[index].classList.remove('primary');
                document.querySelectorAll('.question-card')[index].classList.add('wrong-answer');
            }
        },
        getNextQuestion() {
            let t = this;
            t.answerAttempt = 0;
            t.showQuestionNote = false;
            t.activeQuestionIndex++;
            t.correctAnswer = false;
            document.querySelectorAll('.question-card').forEach((element) => {
                element.classList.remove('right-answer');
                element.classList.remove('wrong-answer');
                element.classList.add('primary');
            });
            t.quizProgress('increase');
        },
        getPreviousQuestion() {
            let t = this;
            t.answerAttempt = 0;
            t.showQuestionNote = false;
            t.activeQuestionIndex--;
            t.correctAnswer = false;
            document.querySelectorAll('.question-card').forEach((element) => {
                element.classList.remove('right-answer');
                element.classList.remove('wrong-answer');
                element.classList.add('primary');
            });
            t.quizProgress('decrease');
        },
        quizProgress(increment) {
            let t = this;
            t.quizProgressPercent = 0;
            let index = t.activeQuestionIndex;
            if (increment === 'increase') {
                t.quizProgressPercent = (++index / t.quizQuestions.length) * 100;
            } else {
                t.quizProgressPercent = (--index / t.quizQuestions.length) * 100;
            }
        },
        async getTags() {
            let t = this;
            let excludedTags = await t.MIX_redisReadJSON('settings:excludedTags', '.');
            const tagsArray = t.contentItem.contentTags.split(',');
            const exlusions = tagsArray.filter((tag) => {
                return excludedTags.data.indexOf(tag) === -1;
            });
            return (t.tags = exlusions);
        },
        async addToMyList() {
            let t = this;
            t.myList.id = await t.MIX_generateId();
            t.myList.status = '1';
            t.myList.contentId = t.contentItem.id;
            t.myList.orgId = t.GET_firebase_user.orgId;
            t.myList.orgName = t.GET_firebase_user.orgName;
            t.myList.createdDateTime = t.$moment().format('x');
            t.myList.createdUserId = t.GET_firebase_user.id;
            t.myList.createdUserName = t.GET_firebase_user.userName;
            t.myList.createdUserEmail = t.GET_firebase_user.userEmail;
            let addToMyList = await t.MIX_redisCreateHash(`myList:${t.myList.id}`, t.myList);
            if (addToMyList.code === 1) {
                t.addedToMyList = true;
                t.getMyList();
                t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Added item to your list', show: true });
            } else {
                t.addedToMyList = false;
                t.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Error saving to your list, please try again.', show: true });
            }
        },
        async removeFromMyList() {
            let t = this;
            let result = await this.MIX_destroyItem(t.myList.key, t.myLists[0].value.id);
            if (result.code === 1) {
                t.addedToMyList = false;
                t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Removed item from your list', show: true });
            } else {
                t.addedToMyList = true;
                t.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Error removing item from your list, please try again.', show: true });
            }
        },
        async getMyList() {
            let t = this;
            let result = await t.MIX_redisSearch('idx:myLists', `@contentId:${t.contentItem.id}`, 0, 9999);
            t.myLists = result.data.documents;
            t.myLists.forEach((list) => {
                list.value.status !== 1 ? (t.addedToMyList = true) : (t.addedToMyList = false);
            });
        },
        async addLike() {
            let t = this;
            t.addStat('like');
            t.getLikes();
        },
        async addDislike() {
            let t = this;
            t.addStat('dislike');
            t.getDislikes();
        },
        async getLikes() { 
            let t = this;
            let result = await t.MIX_statsCount({ searchQuery: `@contentId:${t.contentItem.id} @statType:like` });
            if (result.code === 1 && result.data.length > 0) {
                t.contentItem.contentLikes = parseInt(result.data[0].total);
            } else {
                t.contentItem.contentLikes = 0;
            }
        },
        async getDislikes() { 
            let t = this;
            let result = await t.MIX_statsCount({ searchQuery: `@contentId:${t.contentItem.id} @statType:dislike` });
            if (result.code === 1 && result.data.length > 0) {
                t.contentItem.contentDislikes = parseInt(result.data[0].total);
            } else {
                t.contentItem.contentDislikes = 0;
            }
        },
        async addView() {
            let t = this;
            const view = {};
            view.userId = t.GET_firebase_user.id;
            view.contentId = t.contentItem.id;
            const addView = await t.MIX_addView(view);
            if (addView.code === 1) {
                return;
            }
        },
        async createPdf(url) {
            let t = this;
            t.loading = true;
            t.addStat('download');
            return await t.MIX_generatePDF(url).then((response) => {
                response
                    .arrayBuffer()
                    .then((res) => {
                        const blob = new Blob([res], { type: 'application/pdf' });
                        t.$saveAs(blob, t.contentItem.id + '.pdf');
                        t.loading = false;
                    })
                    .catch((e) => alert(e));
            });
        },
        saveOfflineContent() {
            let t = this;
            const txn = t.$db.transaction('content', 'readwrite');
            const store = txn.objectStore('content');

            let query = store.put(t.contentItem, t.contentItem.id);
            query.onsuccess = (event) => {
                console.log(event);
            };
            query.onerror = (event) => {
                console.log(event.target.errorCode);
            };
        },
        addStat(type) {
            let t = this;
            const stat = schema.stat;
            stat.contentId = t.contentItem.id;
            stat.contentTitle = t.contentItem.contentTitle;
            stat.contentArea = t.contentItem.contentArea;
            stat.contentAlias = t.contentItem.contentAlias;
            stat.contentPartnerOrgId = t.contentItem.orgId;
            stat.contentTags = JSON.stringify(t.contentItem.contentTags);
            stat.statType = type;
            t.MIX_addStat('stat', stat);
        },
        
        async getContentItems(item) {
            let t = this;
            t.contentPreviewOverlay = true;
            if (JSON.stringify(item) !== '{}') {
                let result = await t.MIX_redisSearch('idx:contentItems', `@itemContentId:{${item.id}}`, 0, 9999);
                if (result.code === 1) {
                    t.contentItem = item;
                    if (t.contentItem.contentTags !== '') {
                        t.contentItem.contentTags.split(',').forEach((item) => {
                            t.tags.push(item);
                        });
                    }
                    t.contentItems = result.data.documents;
                    t.contentItems.filter((item) => {
                        if (item.value.itemMarker === 'Cover Image') {
                            t.coverImage = item.value.itemURL;
                        }
                    });
                    t.filterContentItems()
                }
            }
        },
    },
    mounted() {
        let t = this;
        t.myList = schema.myList;
        t.getContentItems(t.contentItem);
        // t.getQuizQuestions();
        t.getTags();
        t.getMyList();
        t.getLikes();
        t.getDislikes();
        // t.addView();
        // t.addStat('view');
    },
};
</script>

<style>
#overlay {
    position: absolute;
    top: 0;
    z-index: 10;
}

.right-answer {
    color: #ffffff !important;
    background-color: green !important;
}

.wrong-answer {
    color: #ffffff !important;
    background-color: red !important;
}

/* .disabled {
    color: #000000 !important;
    background-color: darkgray !important;
} */
.circle {
    z-index: 1 !important;
    position: absolute !important;
    text-align: center !important;
    top: 150px !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
    height: 70px !important;
    width: 70px !important;
    background: var(--v-primary-base) !important;
    border-radius: 50% !important;
}
</style>