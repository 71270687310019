<template>
    <v-card height="700px" class="d-flex flex-column grey lighten-3">
        <div class="pa-0 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
            <div >
                <v-icon class="white--text">oomph-link</v-icon>
            </div>
            <div class="text-left white--text text-h5 pl-2 font-weight-light">Add Content Link</div>
            <v-spacer />
            <div>
                <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeDialog()" depressed>
                    <v-icon>oomph-multiply</v-icon>
                </v-btn>
            </div>
        </div>

        <v-card-text class="grey lighten-3 pa-0">
            <!-- <div class="pa-4">
                eventId = {{ this.event.id }}
                <br />
                <pre>{{ contentLink }}</pre>
            </div> -->
            <!-- <div>linkGroups: <pre>{{ linkGroups }}</pre></div> -->
            <div class="pa-4">
                <div style="width: 100% !important" v-if="newContentLink">
                    <!-- TABLE BASED LIST -->
                    <content-list v-show="contentListTable" :popup="true" v-on:addLink="addLink"/>
                </div>
                <div v-if="showAddLink || !newContentLink">
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- GENERAL DETAILS EDIT-->
                        <v-col cols="12" sm="12" class="my-3">
                            <v-row dense>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Link Title" v-model="contentLink.linkTitle" :rules="rules.linkTitle" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <!-- <v-text-field label="Link Group" v-model="contentLink.linkGroup" :rules="rules.linkGroup" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearContactName('contactTwitter', '')"></v-text-field> -->
                                
                                
                                    <v-combobox
                                        class=""
                                        small-chips
                                        hide-selected
                                        label="Link Group"
                                        v-model="contentLink.linkGroup"
                                        :search-input.sync="linkGroupAutocomplete"
                                        @change="
                                            linkGroupAutocomplete = '';
                                            updateLinkGroups(contentLink.linkGroup);
                                        "
                                        :items="linkGroups"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        :menu-props="{ top: false, offsetY: true }"
                                        :attach="true"
                                        clearable
                                    >
                                        <template v-slot:selection="{ attrs, item, selected }">
                                            <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                                <span class="pr-2">
                                                    {{ item }}
                                                </span>
                                            </v-chip>
                                        </template>
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <span class="subheading">Create</span>
                                                <v-chip color="grey lighten-3" label small>
                                                    {{ linkGroupAutocomplete }}
                                                </v-chip>
                                            </v-list-item>
                                        </template>
                                    </v-combobox>
                                
                                
                                
                                </v-col>
                                <v-col cols="12" class="my-1 pl-2">
                                    <v-row dense class="d-flex align-center pt-2">
                                        <div class="text-left grey--text body-2 font-weight-regular pr-3">Show Content Image Thumbnail: </div>
                                        <v-radio-group v-model="contentLink.linkShowContentImage"  hide-details="auto" row dense>
                                            <v-radio class="grey--text" label="Yes" value="1"></v-radio>
                                            <v-radio class="grey--text" label="No" value="0"></v-radio>
                                        </v-radio-group>
                                    </v-row>
                                    <!-- <v-autocomplete label="Department" v-model="contentLink.contactJobDepartment" :items="jobDepartments" auto-select-first outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearselectedJobDepartment()" autocomplete="donotautocomplete"></v-autocomplete> -->
                                </v-col>

                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="grey lighten-1 pa-3">
            <v-col cols="12" class="d-flex">
                <v-col cols="6" class="d-flex justify-start pa-0">
                    <v-btn v-if="showAddLink" class="grey darken-1 white--text font-weight-bold" depressed @click="showAddLink = false; contentListTable = true"> <v-icon class="mr-2">oomph-back</v-icon><span>Back</span> </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-end pa-0">
                    <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="closeDialog"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                    <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="saveContentLink(contentLink)"> <v-icon class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
                </v-col>
            </v-col>
        </v-card-actions>
    </v-card>
</template>

<script>
import schema from '@/schema.js';
import contentList from "@/views/content/ContentList.vue";

export default {
    name: 'eventContentList',
    components: {
        'content-list': contentList,
    },
    // PROPS
    props: {
        contentlink: {
            Type: Object,
            Default: function() {
                return {}
            },
        },
        event: {
            Type: Object,
            Default: function() {
                return {}
            },
        }
    },
    // DATA
    data: () => ({
        key: 'contentLink',
        newContentLink: false,
        linkGroups: [],
        schema: {},
        contentListTable: true,
        contentLink: {},
        rules: {},
        showAddLink: false,
        linkGroupAutocomplete: ''
    }),
    computed: {
    },
    methods: {
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.contentLink[property] = '';
            }, 1);
        },
        // * Save Content Link
        async saveContentLink(contentLink) {
            let t = this;
            if (contentLink.id !== undefined && contentLink.id !== null && contentLink.id !== '') {
                // do nothing
            } else {
                contentLink.id = t.MIX_generateId(); // if no id then generate one
            }
            let result = await t.MIX_createItem(contentLink, t.key, t.newContentLink);
            if (result.code === 1) {
                t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
            }
            t.contentLink = { ...t.schema }
            t.$emit('close');
        },
        addLink(contentLink) {
            this.contentListTable = false;
            this.showAddLink = true
            this.contentLink.contentId = contentLink.id
            this.contentLink.linkTitle = contentLink.contentTitle
        },
        // Close Item
        closeDialog() {
            this.contentLink = { ...this.schema }
            this.$emit('close');
        },
        // Update Event Groups
        updateLinkGroups(group) {
            // //console.log("### EventContentAddLink ### updateLinkGroups ### group", group);
            // If Tag does not exist in array then add it
            if (this.linkGroups.indexOf(group) === -1) {
                this.linkGroups.push(group);
            }
            // Remove any blank groups from array
            this.linkGroups.forEach((item, index) => {
                if (item === '') {
                    this.linkGroups.splice(index, 1);
                }
            });
            // // //console.log('linkGroups = ' + JSON.stringify(this.linkGroups, null, 2))
            this.event.eventLinkGroups = this.linkGroups.join(',');
            this.$emit('updateevent', this.event); // Parent - EventContentLinks.vue
        },
        // Initialise Dialog Data
        initialise() {
            let t = this;
            t.schema = schema.contentLink;
            if (t.contentlink?.id !== '' && t.contentlink?.id !== undefined && t.contentlink?.id !== null) {
                t.contentLink = { ...t.contentlink }
                t.newContentLink = false;
            } else {
                t.contentLink = { ...t.schema }
                t.newContentLink = true;
            }
            t.contentLink.eventId = t.event.id;
            if (this.event.eventLinkGroups !== '' && this.event.eventLinkGroups !== undefined && this.event.eventLinkGroups !== null) {
                this.linkGroups = this.event.eventLinkGroups.split(',');
            }
        }
    },
    watch: {
        contentlink: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        }
    },
    async created() {
        var t = this
        t.initialise();
    },
};
</script>