<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode" @click="saveItem()"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <v-col v-if="!editmode && !loading" cols="12" class="d-flex pa-2 align-center">

                <!-- //* VIEW MODE -->
                <v-row style="width: 100% !important" no-gutters>
                    <!-- //* GENERAL DETAILS VIEW -->
                    <v-col cols="12" sm="12" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Poll Details</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-poll</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="20%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Name:</td>
                                                <td width="80%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.pollName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="20%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Status:</td>
                                                <td width="80%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.status }}</td>
                                            </tr>
                                            <tr>
                                                <td width="20%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Question:</td>
                                                <td width="80%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.pollQuestion }}</td>
                                            </tr>
                                            <tr>
                                                <td width="20%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Answers:</td>
                                                <td width="80%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <v-chip v-for="(item, index) in JSON.parse(item.pollAnswers)" class="mr-2" :key="index">{{ item }}</v-chip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>

                        <v-row class="white rounded pa-3 mt-2" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Poll Results</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-chart-bar</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <div  class="d-flex flex-column align-center justify-center pa-4">
                                    <v-card class="d-flex flex-column align-center white text-start black--text px-2 py-1 ma-1 mb-4 elevation-0 rounded-lg" width="100" height="100">
                                        <v-card-text class="text-center pa-0">Total polls</v-card-text>
                                        <v-card-title class="oomph-font-h4">{{ allPollAnswers.length }}</v-card-title>
                                    </v-card>
                                    <v-card class="rounded-lg" elevation="0" height="300px" width="100%">
                                        <v-card-title class="oomph-font-body-2 font-weight-bold text-uppercase primary--text d-flex justify-start align-center">
                                            <div>Answers</div>
                                            <v-spacer />
                                            <div><v-icon color="secondary">icons8-downloads-folder</v-icon></div>
                                        </v-card-title>
                                        <v-card-text class="d-flex align-start justify-start flex-column" style="width: 100% !important">
                                            <bar-chart :seriesdata="getAllPollAnswers()" :color="$vuetify.theme.themes.light.primary" seriestext="Downloads" />
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="editmode && !loading" cols="12" class="d-flex pa-4 pt-4 align-center" style="width: 100% !important">
                <v-form ref="form" lazy-validation style="width: 100% !important">
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- DETAILS EDIT -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field label="Poll Name" v-model="item.pollName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('pollName')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Poll Status" v-model="item.status" :rules="rules.packageCategory" :items="pollStatus" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('pollStatus')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-text-field label="Poll Question" v-model="item.pollQuestion" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('pollQuestion')"></v-text-field>
                                </v-col>

                                <v-col class="pb-0 mb-0 d-flex align-center" cols="12">
                                    <div class="grey--text text--darken-1 body-2 pa-0 ma-0">Please select which homes to Publish this to:</div>
                                    <v-spacer />
                                    <div class="grey--text font-weight-bold text--darken-1 body-2 pa-0 pr-2 ma-0">Select All</div>
                                    <v-checkbox @change="selectAllOrgs" v-model="selectAllOrgToggle" class="pb-2" hide-details="auto" dense/>
                                </v-col>
                                <v-col  cols="12" xs="12">
                                    <v-autocomplete label="Poll Available To" v-model="selectedOrganisations" :items="organisations" multiple item-text="orgName" auto-select-first return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedAvailableToOrg()">
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Answers</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" class="d-flex">
                                    <v-text-field label="Enter Answer" v-model="answer" background-color="white" hide-details="auto" counter outlined dense clearable light></v-text-field>
                                    <div class="ml-2"><v-btn @click="addAnswer()" :disabled="answers.length >= 4" depressed class="primary">Add</v-btn></div>
                                </v-col>
                                <v-col v-if="answers.length > 0" cols="12" class="d-flex">
                                    <div  v-for="(item, index) in answers" :key="index">
                                        <v-chip close @click:close="removeAnswer(index)" class="mr-2">{{ item }}</v-chip>
                                    </div>
                                </v-col>
                            </v-row>

                            <!-- <pre>{{ selectedOrganisations }}</pre>
                            <pre>{{ item }}</pre> -->

                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
import BarChart from '@/components/echarts/BarChart.vue';
import { mapGetters } from 'vuex';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        // activeitem: {
        //     Type: Object,
        //     Default: null,
        // },
    },
    components: {
        BarChart,
    },
    // * DATA
    data: () => ({
        key: 'poll',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        // dropdowns
        pollStatus: [],
        
        // new address object
        item: {},
        loading: true,
        rules: {},

        answer: '',
        answers: [],
        allPollAnswers: [],

        organisations: [],
        selectedOrganisations: [],

        selectAllOrgToggle: false,

    }),
    computed: {  
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken'
        }),
    },
    methods: {
        selectAllOrgs() {
            if (this.selectAllOrgToggle === true) {
                this.selectedOrganisations = this.organisations
            } else if (this.selectAllOrgToggle === false) {
                this.selectedOrganisations = []
            }
        },
        getSelectedOrganisations() {
            var t = this

            this.selectedOrganisations = []
            var orgs = t.item.parentAvailableTo.split(",")

            for (let i=0; i < orgs.length; i++) {
                var org = this.organisations.filter(function(item) {
                    return item.id == orgs[i]
                })
                this.selectedOrganisations.push(org[0]);
            }
        },
        initialise() {
            this.item = { ...this.datadown };
            // //console.log('item', JSON.stringify(this.item, null, 2));
            
            if (this.item?.pollName && this.item?.pollName !== "") {
                this.itemNew = false;
                this.editmode = false;
                this.answers = JSON.parse(this.item.pollAnswers);
                this.getAnswers(this.item.id);
                this.getSelectedOrganisations()
            } else {
                this.itemNew = true;
                this.editmode = true;
            }
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        async getStatus() {
            let t = this;
            let pollStatus = await t.MIX_redisReadSet('set:userStatuses', false);
            if (pollStatus.code === 1) {
                t.pollStatus = pollStatus.data;
            }
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            var t = this
            this.item.pollAnswers = JSON.stringify(this.answers)

            var availableTo = []

            // for each org in selectedorganisations, create duplcicate, add parentPollDetails and orgDetails, and save
            for ( let i = 0; i < this.selectedOrganisations.length; i++) {
                var duplicate = {...this.item }
                duplicate.id = this.MIX_generateId()
                duplicate.parentPoll = '0';
                duplicate.parentPollId = this.item.id 
                duplicate.orgId = this.selectedOrganisations[i].id 
                duplicate.orgName = this.selectedOrganisations[i].orgName 
                if (this.selectedOrganisations[i].orgLogo) {
                    duplicate.orgLogo = this.selectedOrganisations[i].orgLogo
                }
                duplicate.orgGroupId = this.selectedOrganisations[i].orgGroupId 

                // //console.log("duplicate = " + JSON.stringify(duplicate, null, 2))

                // save duplicate 
				await t.MIX_createItem(duplicate, t.key, t.itemNew);

                availableTo.push(this.selectedOrganisations[i].id)

            }   
            // //console.log("availableTo = " + JSON.stringify(availableTo, null, 2))
            // add orgIds from selectedOrgs to parentAvailableTo

            let orgInfo = await t.MIX_redisSearch('idx:orgs', `@id:${this.GET_FIREBASE_userAccessToken.org_id}`, 0, 9999);
            if (orgInfo.code === 1) {
                var org = orgInfo.data.documents.map((x) => x.value);
                org = org[0]
            }

            this.item.parentAvailableTo = availableTo
            this.item.orgId = org.id
            this.item.orgGroupId = org.orgGroupId
            this.item.orgName = org.orgName
            this.item.orgLogo = org.orgLogo


            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        removeAnswer(index) {
            let t = this;
            t.answers.splice(index);
        },
        addAnswer() {
            let t = this;
            t.answers.push(t.answer);
            t.answer = '';
        },
        async getAnswers(id) {
            let t = this;
            //console.log('getting answers for this poll = ' + id)
            let getAnswers = await t.MIX_redisSearch('idx:pollAnswers', `@parentPollId:${id}`, 0, 9999);
            if (getAnswers.code === 1) {
                t.allPollAnswers = [];
                getAnswers.data.documents.forEach((answer) => t.allPollAnswers.push(answer.value));
            }
        },
        getAllPollAnswers() {
            let t = this;
            let stats = [];
            //* Count all items in answsers including duplicates
            let objResult = t.allPollAnswers.reduce((acc, obj) => {
                acc[obj.pollAnswer] = (acc[obj.pollAnswer] || 0) + 1;
                return acc;
            }, {});
            //* Map array results into key value pairs
            let arrResult = Object.keys(objResult).map(k => ({
                name: k,
                value: objResult[k]
            }));
            //* Assign key value pairs to graph structure
            arrResult.forEach(stat => {
                stats.push({
                    name: stat.name,
                    value: stat.value
                })
            })
            return stats;
        },
        async getOrganisations() {
            var t = this;
            var orgGroupId = t.GET_FIREBASE_userAccessToken.org_id
            // var orgGroupId = 'BARCHESTERHEALTHCARE'

            let getOrganisations = await t.MIX_redisSearch('idx:orgs', `@orgGroupStructure:{Home} @orgGroupId:{${orgGroupId}}`, 0, 9999)
            if (getOrganisations.code === 1) {
                t.organisations = [],
                // //console.log("getOrganisations = " + JSON.stringify(getOrganisations, null, 2))
                t.organisations = getOrganisations.data.documents.map((x) => x.value);
                if (this.itemNew == false) {
                    this.getSelectedOrganisations()
                }
            }
        }
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.poll;
        t.loading = true
        t.getOrganisations()
        t.initialise();

        t.getStatus();

        let packageCategories = await t.MIX_redisReadSet('set:packageCategory', false);
        if (packageCategories.code === 1) {
            t.packageCategories = packageCategories.data;
        }
        let packageStatus = await t.MIX_redisReadSet('set:packageStatus', false);
        if (packageStatus.code === 1) {
            t.packageStatus = packageStatus.data;
        }
        let billingCycles = await t.MIX_redisReadSet('set:billingCycles', false);
        if (billingCycles.code === 1) {
            t.billingCycles = billingCycles.data;
        }


        t.loading = false
    },
    mounted() {},
};
</script>