<template>
    <div class="" style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow-y: scroll !important; overflow-x: hidden !important" >
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">

                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" >
                    <v-btn outlined class="primary white--text ml-3" v-if="!simpleEditMode" @click="simpleEditMode = !simpleEditMode" ><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn v-if="simpleEditMode" class="grey lighten-1 ml-3 white--text font-weight-bold" depressed @click="simpleEditMode = true"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>
                <v-spacer />
                <v-btn outlined class="primary white--text mr-3" v-if="simpleEditMode" 
                @click="
                        saveText();
                        simpleEditMode = false;
                " ><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
            </v-col>
        </v-row>
        <!-- <pre>{{ text }}</pre> -->
        <!-- <v-col cols="12" class="d-flex grey lighten-2 py-4 px-4">
            <v-col cols="6" class="pa-0">
                <v-btn
                    v-if="simpleEditMode"
                    class="primary white--text elevation-0"
                    @click="
                        saveText();
                        simpleEditMode = false;
                    "
                    depressed
                    outlined
                >
                    <v-icon size="30" class="mr-2">oomph-save</v-icon>
                    Save Content
                </v-btn>
                <v-btn v-else class="primary" @click="simpleEditMode = true"><v-icon size="30" class="mr-2" depressed outlined>oomph-edit</v-icon>Edit Content</v-btn>
            </v-col>
        </v-col> -->
        <!-- <pre>{{ contentItem }}</pre> -->
        <v-form ref="form" v-on:submit.prevent="saveText()" class="mb-7 ">
            <v-col cols="12">
                <vue-editor id="simpleEditor" v-model="text.itemTextHTML" :editorToolbar="customToolbar" :disabled="!simpleEditMode" :class="!simpleEditMode ? 'grey lighten-2' : 'white'"></vue-editor>
            </v-col>
            <!-- <v-col cols="12">
                <v-col cols="12" class="pa-0">
                    <v-btn block class="primary white--text" @click="simpleImageUploadPanel = true" :disabled="!simpleEditMode" outlined depressed>Upload Image</v-btn>
                </v-col>
            </v-col>
            <v-row v-show="simpleImageUploadPanel" class=" mx-3 mt-2 mb-2">
                <v-col cols="12" class="white" style="border: 1px solid lightgrey !important">
                    <v-row class="mb-2">
                        <v-col cols="6">
                            <div class="text-h6 font-weight-thin primary--text">Upload Cover Image</div>
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end">
                            <v-icon size="30" class="primary--text" @click="simpleImageUploadPanel = false">oomph-multiply</v-icon>
                        </v-col>
                    </v-row>
                    <FileUpload
                        accept="image/jpeg, image/jpg"
                        label="Select file"
                        buttonClass="primary"
                        progressClass="pa-2"
                        folderLocation="content/images"
                        :fileName="MIX_generateId()"
                        :selectedItem="contentItem"
                        :compressionMaxSizeMB="1"
                        :compressionmaxWidthOrHeight="1920"
                        :private="true"
                        :isProfile="false"
                        @update="saveImage"
                    />
                </v-col>
            </v-row> -->
        </v-form>
        <v-row dense class="pl-1">
            <v-col v-if="simpleEditMode" cols="12" class="d-flex justify-end grey darken-1 py-4 px-4 pr-7" style="position: fixed; bottom: 0 !important;" :style="listshrunk ? 'width: 73.5vw !important' : 'width: 49.2vw !important'">
                 <v-btn v-if="simpleEditMode" class="grey lighten-1 ml-3 white--text font-weight-bold" depressed @click="simpleEditMode = true"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                <!-- <v-btn class="grey lighten-1 white--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn> -->
                <v-spacer />
                <v-btn class="primary white--text font-weight-bold" depressed 
                    @click="
                        saveText();
                        simpleEditMode = false;
                    "> <v-icon class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
    name: 'ContentSimple',
    components: {
        VueEditor,
    },
    props: {
        originalContentId: {
            type: String,
            default: () => {
                return '';
            },
        },
        contentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        newItem: {
            type: Boolean,
            default: false,
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ align: '' }, { align: 'justify' }, { align: 'right' }],
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ['link'],
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ color: [] }, { background: [] }],
        ],
        contentStatus: ['Draft', 'Pending', 'Approved', 'Deleted', 'Archived'],
        dateMenuFrom: false,
        dateMenuTo: false,
        simpleEditMode: true,
        text: {
            itemTextHTML: '',
            id: '',
        },
        image: {},
        itemNew: false,
        rules: [(v) => !!v || 'Required'],
        simpleImageUploadPanel: false,
    }),
    watch: {
        newItem() {
            let t = this;
            if (t.newItem === true) {
                t.simpleEditMode = true;
                t.contentItem.id = t.MIX_generateId();
                t.text = {}
                t.image = {}
            }
        },
        editMode() {
            let t = this;
            t.simpleEditMode = t.editMode;
        },
    },
    methods: {
        async saveText() {
            if (this.$refs.form.validate()) {
                let t = this;
                t.text.itemType = 'Text';
                if (t.text.id === '') {
                    t.text.id = t.MIX_generateId();
                    t.itemNew = true;
                }
                // t.text.id = t.MIX_generateId();
                t.text.itemContentId = t.originalContentId;
                t.text.itemMarker = 'Simple Text'
                t.text.key = 'contentItem';
                let result = await t.MIX_createItem(t.text, 'contentItem', t.itemNew);
                if (result.code === 1) {
                    // console.log('results = ' + JSON.stringify(result.data, null, 2))
                    t.MIX_alertBox({ color: 'success', message: 'Created content item successfully', timeout: 4000, show: true });
                    t.$emit('update');
                } 
            }
        },
        async saveImage(url, file) {
            let t = this;
            t.itemNew = true;
            t.image.id = t.MIX_generateId();
            t.image.key = 'contentItem';
            t.image.itemContentId = t.originalContentId;
            t.image.itemURL = url;
            t.image.itemFormat = file.type;
            t.image.itemFileSizeKb = t.MIX_bytesToSize(file.size);
            t.image.itemType = 'Cover Image';
            await t.MIX_createItem(t.image, 'contentItem', t.itemNew);
            t.MIX_alertBox({ color: 'success', message: 'Created content item successfully', timeout: 4000, show: true });

            t.$emit('contentDialog', false);
        },
        async initialise() {
            let t = this;
            let result = await t.MIX_redisSearch('idx:contentItems', `@itemContentId:{${t.contentItem.id}} @itemMarker:{Simple Text}`, 0, 9999);
            // console.log('result = ' + JSON.stringify(result, null, 2))
            if (result.code === 1) {
                // result.data.documents.filter((item => {
                //     return item.
                // }))
                t.text = result.data.documents[0].value;
            }
        },
    },
    mounted() {
        let t = this;
        t.initialise();     
    },

};
</script>

<style>
#simpleEditor {
  height: 550px;
}
</style>