<template>
    <div class="" style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important">
        <!-- Menu Buttons -->
         <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey lighten-1 py-4">
                <div class="mx-3 d-flex align-center justify-end" style="width: 100% !important;">
					<v-spacer />
					<div class="d-flex justify-end">
						<v-btn depressed outlined class="primary white--text" @click="dialogLicence = true"><v-icon class="mr-2">oomph-licence</v-icon>Add Licence</v-btn>
					</div>
                </div>
            </v-col>
        </v-row>
		<v-row dense>
			<v-col cols="12" class="px-4">
				<licence-list :activeorg="org" :refreshitems="refreshitems" />
			</v-col>
		</v-row>
		<!-- v-on:edit="editItem"
		:refreshitems="refreshitems" -->
		<v-dialog v-model="dialogLicence" max-width="1024px" >
            <licence-item :activeorg="org" :activelicence="activeItem" :key="dialogLicenceKey" v-on:close="closeLicenceDialog"/>
        </v-dialog>
	</div>
</template>

<script>
	import LicenceItem from '@/views/licences/LicenceItem.vue';
	import LIcenceList from '@/views/licences/LicenceList.vue';
	export default {
		// * COMPONENTS
		components: {
			"licence-item": LicenceItem,
			"licence-list": LIcenceList
		},
		// * PROPS
		props: {
			editmode: {
				type: Boolean,
				default: false,
			},
			org: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			// licence
			licence: {},
			dialogLicence: false,
			dialogLicenceKey: 0,
			activeItem: {},	
			refreshitems: 0,
		}),
		// * COMPUTED
		computed: {},
		// * METHODS
		methods: {
			// Close Licence Dialog
			closeLicenceDialog() {
				this.dialogLicence = false;
				this.dialogLicenceKey++;
				this.refreshitems++;
			}
		},
		// * WATCH
		watch: {},
		// * CREATED
		async created() {
		},
	};
</script>
