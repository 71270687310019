import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Store from '@/store.js';

import Accounts from '@/views/accounts/Accounts.vue'
import Actions from '@/views/action/Actions.vue'
import Brandings from '@/views/brandings/Brandings.vue'
import Components from '@/views/components/Components.vue'
import Contents from '@/views/content/Contents.vue'
import ContractPrint from '@/views/contracts/ContractPrint.vue'
import Events from '@/views/events/Events.vue'
import Holding from '@/views/auth/Holding.vue';
import Home from '@/views/Home.vue'
import Leads from '@/views/leads/Leads.vue'
import Login from '@/views/auth/Login.vue'
import News from '@/views/news/Newses.vue';
import NotAuthorised from "@/views/auth/notAuthorised.vue";
import Opportunities from '@/views/opportunities/Opportunities.vue'
import Organisations from '@/views/organisations/Organisations.vue'
import Packages from '@/views/packages/Packages.vue'
import Polls from '@/views/polls/Polls.vue';
import PrintReports from '@/views/print/Report.vue';
import ReleaseNotes from '@/views/releasenotes/ReleaseNotes.vue';
import Register from '@/views/auth/Register.vue'
import Reporting from '@/views/reporting/Reporting.vue';
import Reset from '@/views/auth/Reset.vue'
import ReviewQuestions from '@/views/reviews/ReviewQuestions.vue';
import Reviews from '@/views/reviews/Reviews.vue'
import Sales from '@/views/sales/Sales.vue'
import Streams from '@/views/videos/Streams.vue';
import Tickets from '@/views/tickets/Tickets.vue'
import Users from '@/views/users/Users.vue'
import Videos from '@/views/videos/Videos.vue';
import Recurring from '@/views/recurring/RecurringEvents.vue';

import VueNumber from 'vue-number-animation'
Vue.use(VueNumber)
Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
	// { path: "/contacts/:id?", name: "Contacts", component: Contacts, meta: { hideNavbar: false, hideAppBar: false, requiresAuth: true, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers']} },
    { path: '/', name: 'Login', component: Login, meta: { requiresAuth: false, hideNavbar: true, hideAppBar: true }},
    { path: '/accounts', name: 'Accounts', component: Accounts, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/actions', name: 'Actions', component: Actions, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/brandings', name: 'Brandings', component: Brandings, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/components', name: 'Components', component: Components, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/contents', name: 'Contents', component: Contents, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Administration','IT','Developers'] }},
    { path: '/contractprint/:id?', name: 'ContractPrint', component: ContractPrint, meta: { requiresAuth: false, hideNavbar: true, hideAppBar: true, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    // { path: '/contracts', name: 'Contracts', component: Contracts, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/customers/:id?', name: 'Customers', component: Organisations, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/events', name: 'Events', component: Events, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/holding', name: 'Holding', component: Holding, meta: { requiresAuth: false, hideNavbar: true, hideAppBar: false, }},
    { path: '/home', name: 'Home', component: Home, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Customer', 'Content', 'Accounts', 'Sales','Administration','IT','Developers'] }},
    { path: '/leads', name: 'Leads', component: Leads, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/news', name: 'News', component: News, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Customer', 'Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/opportunities', name: 'Opportunities', component: Opportunities, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/organisations/:id?', name: 'Organisations', component: Organisations, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/packages', name: 'Packages', component: Packages, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/partners/:id?', name: 'Partners', component: Organisations, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/polls', name: 'Polls', component: Polls, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Customer', 'Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/prospects/:id?', name: 'Prospects', component: Organisations, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/register', name: 'Register', component: Register, meta: { requiresAuth: false, hideNavbar: true, hideAppBar: true }},
    { path: '/releasenotes', name: 'ReleaseNotes', component: ReleaseNotes, meta: { requiresAuth: true, hideAppBar: false, userGroups: ['Administration', 'IT'], userLevels: ['DeveloperAdmin','StaffAdmin']  }},
    { path: '/reporting', name: 'Reporting', component: Reporting, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Customer', 'Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/reporting/print', name: 'Print Reports', component: PrintReports, meta: { requiresAuth: false, hideNavbar: true } },
    { path: '/reset', name: 'Reset', component: Reset, meta: { requiresAuth: false, hideNavbar: true, hideAppBar: true }},
    { path: '/reviewquestions/', name: 'Review Questions', component: ReviewQuestions, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Administration','IT','Developers'] }},
    { path: '/reviews', name: 'Reviews', component: Reviews, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/sales/:id?', name: 'Sales', component: Sales, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/streams/', name: 'Streams', component: Streams, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Administration','IT','Developers'] }},    
    { path: '/suppliers/:id?', name: 'Suppliers', component: Organisations, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/tickets/:id?', name: 'Tickets', component: Tickets, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: '/users', name: 'Users', component: Users, meta: { requiresAuth: true, hideAppBar: false, userGroups: ['Administration', 'IT'], userLevels: ['DeveloperAdmin','StaffAdmin']  }},
    { path: '/videos/', name: 'Videos', component: Videos, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Administration','IT','Developers'] }},
    { path: '/recurring', name: 'EveRecurringnts', component: Recurring, meta: { requiresAuth: true, hideNavbar: false, hideAppBar: false, userGroups: ['Content','Accounts','Sales','Administration','IT','Developers'] }},
    { path: "/notauthorised", name: "NotAuthorised", component: NotAuthorised, meta: { hideNavbar: false, hideAppBar: false, requiresAuth: false, userGroups: ['Customer', 'Content', 'Accounts', 'Sales', 'Administration', 'IT', 'Developers', 'Home'] } },    
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
})

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth) {
        navigator.serviceWorker.register('/service-worker.js').then(reg => {
            reg.update();
        });        
        const auth = getAuth(); 
        await onAuthStateChanged(auth, (user) => {
            if (user !== null) {
                user.getIdToken(true).then((idToken) => {
                    Store.dispatch('firebase_auth_store/ACT_FIREBASE_userAccess', idToken);
                    var token = parseJwt(idToken)
                    if (token.user_status === 'Approved') {
                        let authFail = 0;
                        // User Group Check
                        switch (true) {
                            case (to.meta.userGroups !== undefined && to.meta.userLevels !== undefined):
                                console.log({userGroups: to.meta.userGroups, userLevels: to.meta.userLevels})
                                // userGroups and userLevels
                                if (to.meta.userGroups.includes(token.user_group) || to.meta.userLevels.includes(token.user_level)) {
                                    // allow passage
                                } else {
                                    // deny passage
                                    authFail++
                                console.log('failing 1')
                                }
                                break;
                            case (to.meta.userGroups !== undefined && to.meta.userLevels === undefined):
                                // userGroups and no userLevels
                                if (to.meta.userGroups.includes(token.user_group)) {
                                    // allow passage
                                } else {
                                    // deny passage
                                    authFail++
                                console.log('failing 2')
                                }
                                break;
                            case (to.meta.userGroups === undefined && to.meta.userLevels !== undefined):
                                // no userGroups and userLevels
                                if (to.meta.userLevels.includes(token.user_level)) {
                                    // allow passage
                                } else {
                                    // deny passage
                                    authFail++
                                console.log('failing 3')
                                }
                                break;
                            default:
                                authFail++
                                break;
                        }
                        // If UserGroup or UserLevel check fails, redirect to NotAuthorised
                        if (authFail === 0) {
                            next();
                        } else {
                            next('/notauthorised');
                        }
                    } else {
                        next('/holding');
                    }
                }).catch(error => {
                    console.error(error);
                    next('/login');
                })
            } else {
                next('/login');
            }
        })
    } else {
        next();
    }
})

export default router
