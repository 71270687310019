<template>
	<div>
		<v-dialog v-model="item.show" width="400">
			<v-card>
				<v-card-title :class="item.color" class="white--text">{{ item.title }}</v-card-title>
				<v-card-text>
					<div class="mt-3 body-2" v-html="item.message"></div>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn class="grey lighten-2 grey--text text--darken-3" depressed @click="actionButton1">{{ item.actionText1 }}</v-btn>
					<v-btn :color="item.color" class="white--text" depressed @click="actionButton2">{{ item.actionText2 }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		// props
		props: {
			datadown: {
				type: Object,
				default: () => {
					return this.defaultData;
				}
			}
		},
		data: () => ({
			item: {},
			defaultData: {
				show: false,
				title: '',
				message: '',
				actionText1: 'Cancel',
				actionText2: 'Ok',
				color: 'secondary',
				id: '',
				method: '',
				result: '',
			}
		}),
		// * WATCH
		watch: {
			datadown: {
				handler: function() {
					this.item = { ...this.datadown };
				},
				deep: true
			}
		},
		// * METHODS
		methods: {
			actionButton1: function() {
				this.item.action = 1;
				this.$emit("method", this.item)			
				this.item = this.defaultData;
			},		
			actionButton2: function() {
				this.item.action = 2;
				this.$emit("method", this.item)			
				this.item = this.defaultData;
			},
		},
		// * CREATED
		created() {
			this.item = this.defaultData;
		}
	
	}
</script>