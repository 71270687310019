<template>
    <div>
        <div class="d-flex align-start mb-1">
            <div class="d-flex align-start">
                <div><v-icon class="primary--text" x-large>oomph-versions</v-icon></div>
                <div class="text-left primary--text text-h4 font-weight-thin pl-1">Content</div>
            </div>
            <v-spacer></v-spacer>
            <div v-if="!listshrunk && listexpanded" class="d-flex">
                <v-card class="elevation-0 ml-2 grey darken-1" min-width="80px" min-height="80px">
                    <div class="pt-2 d-flex flex-column align-center justify-center">
                        <div class="font-weight-bold text-h5 white--text">{{ cardStats.Draft }}</div>
                        <v-spacer />
                        <div class="white--text">Draft</div>
                    </div>
                </v-card>
                <v-card class="elevation-0 ml-2 warning" min-width="80px" min-height="80px">
                    <div class="pt-2 d-flex flex-column align-center justify-center">
                        <div class="font-weight-bold text-h5 white--text">{{ cardStats.Pending }}</div>
                        <v-spacer />
                        <div class="white--text">Pending</div>
                    </div>
                </v-card>
                <v-card class="elevation-0 ml-2 info" min-width="80px" min-height="80px">
                    <div class="pt-2 d-flex flex-column align-center justify-center">
                        <div class="font-weight-bold text-h5 white--text">{{ cardStats.Approved }}</div>
                        <v-spacer />
                        <div class="white--text">Approved</div>
                    </div>
                </v-card>
                <v-card class="elevation-0 ml-2 success" min-width="80px" min-height="80px">
                    <div class="pt-2 d-flex flex-column align-center justify-center">
                        <div class="font-weight-bold text-h5 white--text">{{ cardStats.Live }}</div>
                        <v-spacer />
                        <div class="white--text">Live</div>
                    </div>
                </v-card>
                <v-card class="elevation-0 ml-2 error" min-width="80px" min-height="80px">
                    <div class="pt-2 d-flex flex-column align-center justify-center">
                        <div class="font-weight-bold text-h5 white--text">{{ cardStats.Rejected }}</div>
                        <v-spacer />
                        <div class="white--text">Rejected</div>
                    </div>
                </v-card>
                <v-card class="elevation-0 ml-2 black" min-width="80px" min-height="80px">
                    <div class="pt-2 d-flex flex-column align-center justify-center">
                        <div class="font-weight-bold text-h5 white--text">{{ cardStats.Total }}</div>
                        <v-spacer />
                        <div class="white--text">Total</div>
                    </div>
                </v-card>
            </div>
        </div>
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field label="Search Content" v-model="searchQuery" outlined dense hide-details="auto"
                    background-color="white" color="primary" clearable prepend-inner-icon="oomph-search"
                    @click:clear="clearSearchQuery()" @keyup="datatableOptions.page = 1">
                </v-text-field>
                <div class="ml-1 mt-1 body-2 app--text">Please type two or more Characters</div>
            </div>
            <!-- Content Partner  -->
            <div v-if="!listshrunk && listexpanded" class="ml-2">
                <div>
                    <v-autocomplete outlined auto-select-first v-model="filter.partner" label="Filter by Partner"
                        :items="organisations" :search-input.sync="contentPartnerAutocomplete"
                        @change="contentPartnerAutocomplete = ''" item-text="orgName" item-value="id" dense
                        hide-details="auto" background-color="white" color="primary" clearable
                        @click:clear="clearFilter('partner', '')">
                    </v-autocomplete>
                </div>
            </div>
            <!-- Filter Tags -->
            <div v-if="!listshrunk && listexpanded" class="ml-2">
                <div>
                    <v-autocomplete multiple outlined auto-select-first v-model="filter.tags"
                        :search-input.sync="tagContentTags" @change="
                            tagContentTags = ''
                        datatableOptions.page = 1
                            " label="Filter by Tags" :items="contentTags" dense hide-details="auto"
                        background-color="white" color="primary" clearable
                        @click:clear="clearSelectedContentTags()"></v-autocomplete>
                </div>
                <div class="ml-1 mt-1 body-2 app--text">
                    Filter is set to
                    <strong class="primary--text" @click="
                        changeContentTagsOperator()
                    datatableOptions.page = 1
                        " style="cursor: pointer">{{ filter.contentTagsOperator }}</strong>
                    of the above
                </div>
            </div>
            <!-- Filter Difficulty  -->
            <div v-if="!listshrunk && listexpanded" class="ml-2">
                <div>
                    <v-autocomplete multiple outlined auto-select-first v-model="filter.difficulty"
                        label="Filter by Difficulty" :search-input.sync="difficultyAutocomplete" @change="
                            difficultyAutocomplete = ''
                        datatableOptions.page = 1
                            " :items="difficulty" dense hide-details="auto" background-color="white" color="primary"
                        clearable @click:clear="clearFilter('difficulty', [])"></v-autocomplete>
                </div>
            </div>
            <!-- Filter Areas  -->
            <div v-if="!listshrunk && listexpanded" class="ml-2">
                <div>
                    <v-autocomplete multiple outlined auto-select-first v-model="filter.areas" label="Filter by Area"
                        :search-input.sync="areaAutocomplete" @change="
                            areaAutocomplete = ''
                        datatableOptions.page = 1
                            " :items="areas" dense hide-details="auto" background-color="white" color="primary"
                        clearable @click:clear="clearFilter('areas', [])"></v-autocomplete>
                </div>
            </div>
            <!-- Filter Areas  -->
            <div v-if="!listshrunk && listexpanded" class="ml-2">
                <div>
                    <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y
                        max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field label="Filter by Available To" v-model="computedDate2"
                                :search-input.sync="availableToFilter" @change="
                                    availableToFilter = ''
                                datatableOptions.page = 1
                                    " outlined dense hide-details="auto" background-color="white" color="primary"
                                v-bind="attrs" v-on="on" clearable @click:clear="date2 = ''"></v-text-field>
                        </template>
                        <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="1"
                            @input="dateMenu2 = false"></v-date-picker>
                    </v-menu>
                </div>
            </div>
            <!-- Filter Job Department -->
            <div v-if="!listshrunk && listexpanded" class="ml-2">
                <div>
                    <v-autocomplete multiple outlined auto-select-first v-model="filter.rating" label="Filter by Rating"
                        :search-input.sync="ratingAutocomplete" @change="
                            ratingAutocomplete = ''
                        datatableOptions.page = 1
                            " :items="ratings" dense hide-details="auto" background-color="white" color="primary"
                        clearable @click:clear="clearFilter('rating', [])"></v-autocomplete>
                </div>
            </div>
            <!-- Refresh Button -->
            <div v-if="!listshrunk" class="ml-2">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important"
                    class="white grey--text text--darken-2" @click="refreshItems">
                    <v-icon>oomph-available-updates</v-icon>
                </v-btn>
            </div>
            <!-- Column Selector -->
            <div v-if="!popup && eventId !== ''" class="ml-2">
                <v-menu offset-y class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important"
                            class="white grey--text text--darken-2" v-bind="attrs"
                            v-on="on"><v-icon>oomph-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto"
                                v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false"
                                :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <!-- Alphabet Search -->
        <div class="my-2" v-if="orgid === '' && !listshrunk && !popup && eventId === ''">
            <!-- // loop through each letter of the alphabet and create a button -->
            <v-btn-toggle v-model="filter.alpha" class="appbackground d-flex align-center flex-wrap"
                @change="datatableOptions.page = 1">
                <v-btn v-for="letter in alphabet" :key="letter" :value="letter" class="my-1 flex-grow-1" color="white"
                    depressed small>{{ letter }}</v-btn>
                <v-btn value="" class="my-1" depressed small>All</v-btn>
            </v-btn-toggle>
        </div>
        <!-- Content Status -->
        <v-row class="mb-4 px-3 align-center justify-center">
            <v-btn-toggle multiple v-model="filter.status" class="mt-4 appbackground d-flex align-center flex-wrap"
                @change="datatableOptions.page = 1">
                <v-btn value="Draft" active-class="grey darken-1 white--text" depressed small>Draft</v-btn>
                <v-btn value="Approved" active-class="info white--text" depressed small>Approved</v-btn>
                <v-btn value="Live" active-class="success white--text" depressed small>Live</v-btn>
                <v-btn value="Pending" active-class="warning white--text" depressed small>Pending</v-btn>
                <v-btn value="Rejected" active-class="error white--text" depressed small>Rejected</v-btn>
                <v-btn value="Archived" active-class="black white--text" depressed small>Archived</v-btn>
            </v-btn-toggle>
            <v-spacer />
            <div class="">
                <v-switch v-model="showStats" color="primary" label="Show / Hide Stats" hide-details="auto"
                    inset></v-switch>
            </div>
            <div class="ml-4 pt-4">
                <v-btn depressed block class="primary darken-2 white--text text--darken-2" @click="openItem({})">
                    <v-icon>oomph-duplicate</v-icon>
                    <span v-if="!listshrunk" class="ml-3">New</span>
                </v-btn>
            </div>
            <div class="ml-1 pt-4">
                <v-btn depressed block class="primary darken-2 white--text text--darken-2" @click="downloadCSV()">
                    <v-icon>oomph-export-csv</v-icon>
                    <span class="ml-3">Download</span>
                </v-btn>
            </div>
        </v-row>
        <div class="mt-2 mb-12">
            <!-- TABLE BASED LIST -->
            <v-data-table v-if="!listshrunk" sortable dense class="" :headers="computedHeaders" :items="computedItems"
                :server-items-length="itemsTotal" :loading="loading" loading-text="Loading Content"
                :options.sync="datatableOptions" :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 50, 100] }">
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span>
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="Permanently Delete" value="destroy" small>
                                <v-icon class="error--text">oomph-delete-trash</v-icon>
                            </v-btn>
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                            <v-btn title="Undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:item.contentTitle="{ item }">
                    <span :title="item.contentTitle" v-if="item.contentTitle !== undefined">{{
                        item.contentTitle.substring(0, 30) }}...</span>
                </template>
                <template v-slot:item.contentAvailableFrom="{ item }">
                    <span :title="item.contentAvailableFrom" v-if="item.contentAvailableFrom !== undefined">{{
                        MIX_formatDateTime(item.contentAvailableFrom, 'X', 'DD-MMM-YYYY') }}</span>
                </template>
                <template v-slot:item.contentAvailableTo="{ item }">
                    <span :title="item.contentAvailableTo" v-if="item.contentAvailableTo !== undefined">{{
                        MIX_formatDateTime(item.contentAvailableTo, 'X', 'DD-MMM-YYYY') }}</span>
                </template>
                <template v-slot:item.contentSubtitle="{ item }">
                    <span :title="item.contentSubtitle" v-if="item.contentSubtitle !== undefined">{{
                        item.contentSubtitle.substring(0, 40) }}...</span>
                </template>
                <template v-slot:item.contentDifficulty="{ item }">
                    <v-chip v-if="item.contentDifficulty === 'difficult'" small
                        class="error white--text font-weight-bold">Difficult</v-chip>
                    <v-chip v-if="item.contentDifficulty === 'medium'" small
                        class="warning white--text font-weight-bold">Medium</v-chip>
                    <v-chip v-if="item.contentDifficulty === 'easy'" small
                        class="success white--text font-weight-bold">Easy</v-chip>
                </template>
                <template v-slot:item.contentPinned="{ item }">
                    <v-chip v-if="item.contentPinned === '1'" small
                        class="success white--text font-weight-bold">Yes</v-chip>
                    <v-chip v-else small class="error white--text font-weight-bold">No</v-chip>
                </template>
                <template v-slot:item.contentStatus="{ item }">
                    <v-chip small v-if="item.contentStatus === 'Draft'" class="grey darken-1 font-weight-bold" dark>{{
                        item.contentStatus }}</v-chip>
                    <v-chip small v-if="item.contentStatus === 'Pending'" class="warning font-weight-bold" dark>{{
                        item.contentStatus }}</v-chip>
                    <v-chip small v-if="item.contentStatus === 'Rejected'" class="error font-weight-bold" dark>{{
                        item.contentStatus }}</v-chip>
                    <v-chip small v-if="item.contentStatus === 'Approved'" class="info font-weight-bold" dark>{{
                        item.contentStatus }}</v-chip>
                    <v-chip small v-if="item.contentStatus === 'Live'" class="success font-weight-bold" dark>{{
                        item.contentStatus }}</v-chip>
                    <v-chip small v-if="item.contentStatus === 'Archived'" class="black font-weight-bold" dark>{{
                        item.contentStatus }}</v-chip>
                </template>
                <template v-slot:item.contentDifficulty="{ item }">
                    <!-- {{ item.contentDifficulty }} -->
                    <span v-if="item.contentDifficulty">{{ item.contentDifficulty.charAt(0).toUpperCase() +
                        item.contentDifficulty.slice(1) }}...</span>
                </template>
                <template v-slot:item.contentViews="{ item }">
                    <span v-if="item.contentViews !== ''">{{ item.contentViews }}</span>
                    <span v-else-if="item.contentViews === ''">-</span>
                </template>
                <template v-slot:item.contentLikes="{ item }">
                    <span v-if="item.contentLikes !== ''">{{ item.contentLikes }}</span>
                    <span v-else-if="item.contentLikes === ''">-</span>
                </template>
                <template v-slot:item.contentReviews="{ item }">
                    <v-rating empty-icon="oomph-star" full-icon="oomph-star-2" length="5" size="20" background-color="grey"
                        color="primary" class="mr-6" :value="parseInt(item.contentRating)" readonly dense></v-rating>
                </template>
                <template v-slot:item.contentDownloads="{ item }">
                    <span v-if="item.contentDownloads !== ''">{{ item.contentDownloads }}</span>
                    <span v-else-if="item.contentDownloads === ''">-</span>
                </template>
                <template v-slot:item.contentRating="{ item }">
                    <span v-if="item.contentRating !== ''">{{ item.contentRating }}</span>
                    <span v-else-if="item.contentRating === ''">-</span>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')"
                        @click="
                            confirmDestroy.id = item.id
                        confirmDialog = confirmDestroy
                            ">oomph-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="
                        confirmDelete.id = item.id
                    confirmDialog = confirmDelete
                        " v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)"
                        v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon>
                    <v-icon v-if="!popup" class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>

                    <div v-if="popup" class="d-flex py-1">
                        <v-btn v-if="popup" disabled class="primary white--text font-weight-normal mr-1" depressed
                            @click="saveItem(item)"><span>View Content</span> </v-btn>
                        <v-btn v-if="popup" class="primary white--text font-weight-normal" depressed
                            @click="addLink(item)"><span>Add Link</span> </v-btn>
                    </div>
                </template>
            </v-data-table>
            <!-- CARD BASED LIST -->
            <div v-if="listshrunk" style="height: 67vh" class="">
                <v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
                    <div class="d-flex align-center ml-1">
                        <div class="ml-3">
                            <div class="body-2 font-weight-bold"
                                style="width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{
                                    item.contentTitle }}</div>
                            <div class="body-2"
                                style="width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{
                                    item.contentSubtitle }}</div>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="text-right mr-3">
                            <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>
                        </div>
                    </div>
                </v-card>
                <v-btn v-if="listshrunk" class="mt-2 grey lighten-3" depressed block @click="loadMore()">Load More</v-btn>
            </div>
            <!-- LOAD MORE CARDS -->
        </div>
        <confirm-dialog :datadown="confirmDialog" v-on:method="confirmMethodHandler" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0
        },
        listshrunk: {
            type: Boolean,
            default: false
        },
        listexpanded: {
            type: Boolean,
            default: false
        },
        orgType: {
            type: String,
            default: ''
        },
        orgid: {
            type: String,
            default: ''
        },
        popup: {
            type: Boolean,
            default: false
        },
        eventId: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        key: 'content:details',
        index: 'idx:content:details2',
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        itemsFrom: 0,
        itemsTo: 15,
        // Toggles
        toggleFeatures: [],
        showStats: false,
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', align: 'start', value: 'id', hidden: true },
            { text: 'Title', value: 'contentTitle', hidden: false, expanded: false, showStats: true },
            { text: 'Subtitle', value: 'contentSubtitle', hidden: false, expanded: false },
            { text: 'Short Description', value: 'contentDescription', hidden: true, expanded: true },
            { text: 'Available From', value: 'contentAvailableFrom', hidden: false, expanded: true },
            { text: 'Available To', value: 'contentAvailableTo', hidden: true, expanded: true },
            { text: 'Tags', value: 'contentTags', hidden: true, expanded: true },
            { text: 'Content Partner', value: 'orgName', hidden: false, expanded: false },
            { text: 'Area', value: 'contentArea', hidden: false, expanded: false },
            { text: 'Views', value: 'contentViews', hidden: true, expanded: false, showStats: true },
            { text: 'Likes', value: 'contentLikes', hidden: true, expanded: false, showStats: true },
            { text: 'Downloads', value: 'contentDownloads', hidden: true, expanded: false, showStats: true },
            { text: 'Template', value: 'contentTemplate', hidden: false, expanded: true },
            { text: 'Difficulty', value: 'contentDifficulty', hidden: false, expanded: true },
            { text: 'Status', value: 'contentStatus', hidden: false, expanded: false },
            { text: 'Allow Download', value: 'contentAllowDownload', hidden: true, expanded: true },
            { text: 'Allow Accessibility', value: 'contentAllowPrint', hidden: true, expanded: true },
            { text: 'Allow Offline', value: 'contentAllowOffline', hidden: true, expanded: true },
            { text: 'Allow PDF Convert', value: 'contentAllowPDFConvert', hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', hidden: true, expanded: true },
            { text: 'Rating', value: 'contentRating', hidden: false, expanded: false, showStats: true },
            { text: 'Pinned?', value: 'contentPinned', hidden: false, expanded: false, showStats: true },
            { text: 'Action', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false, width: '155px', showStats: true }
        ],
        // Fields to Retrieve from Database
        sortBy: '@contentTitle',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['contentTitle'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        loading: true,
        cardStats: {
            Draft: 0,
            Pending: 0,
            Approved: 0,
            Live: 0,
            Rejected: 0,
            Total: 0
        },
        // Dropdowns
        jobLevels: [],
        jobDepartments: [],
        organisations: [],
        tags: [],
        areas: [],
        partners: [],
        difficulty: ['easy', 'medium', 'difficult'],
        filter: {
            tags: [],
            availableFrom: '',
            availableTo: '',
            rating: [],
            status: [],
            partner: '',
            areas: [],
            difficulty: [],
            contentTagsOperator: 'ANY'
        },
        ratings: ['1', '2', '3', '4', '5'],
        jobLevelAutocomplete: '',
        ratingAutocomplete: '',
        tagsAutocomplete: '',
        areaAutocomplete: '',
        difficultyAutocomplete: '',
        contentPartnerAutocomplete: '',
        availableFromFilter: '',
        availableToFilter: '',

        tagContentTags: '',
        contentTags: [],

        date1: '',
        dateMenu1: false,
        date2: '',
        dateMenu2: false,
        // Dialogs
        confirmDialog: {},
        confirmDestroy: {
            show: true,
            title: 'WARNING - Destroy Content',
            message: 'Are you sure you want to destroy this piece of content? <p class="mt-2 error--text font-weight-bold">This Action is Permanent and Cannot be Undone.</p>',
            color: 'error',
            actionText1: 'No',
            actionText2: 'Yes',
            id: '',
            method: 'destroyItem',
            action: ''
        },
        confirmDelete: {
            show: true,
            title: 'WARNING - Delete Content',
            message: 'Are you sure you want to delete this piece of content? <p class="mt-2 warning--text font-weight-bold">You can restore this using undelete.</p>',
            color: 'warning',
            actionText1: 'No',
            actionText2: 'Yes',
            id: '',
            method: 'deleteItem',
            action: ''
        }
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken'
        }),
        userLevel() {
            return this.GET_FIREBASE_userAccessToken.user_level
        },
        userGroup() {
            return this.GET_FIREBASE_userAccessToken.user_group
        },
        userStatus() {
            return this.GET_FIREBASE_userAccessToken.user_status
        },
        computedDate1() {
            return this.MIX_formatDateTime(this.date1, 'YYYY-MM-DD', 'DD-MMM-YYYY')
        },
        computedDate2() {
            return this.MIX_formatDateTime(this.date2, 'YYYY-MM-DD', 'DD-MMM-YYYY')
        },
        alphaOptions() {
            let alphaOptions = []
            for (let i = 0; i < this.alphabet.length; i++) {
                alphaOptions.push(this.filter.alpha + this.alphabet[i] + '*')
            }
            return alphaOptions
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '*'
                } else {
                    searchQuery = '@deleted:0'
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`
                    }
                }
            }
            // Alphabet Filter
            if (this.filter.alpha !== '' && this.filter.alpha !== null && this.filter.alpha !== undefined) {
                let alphaOptions = this.alphaOptions.join(',')
                alphaOptions = alphaOptions.replace(/,/g, '|')
                searchQuery = `${searchQuery}  @contentFirstName:${alphaOptions}`
            }
            if (JSON.stringify(this.filter.tags) !== '[]') {
                if (this.filter.contentTagsOperator === 'ANY') {
                    searchQuery = `${searchQuery} @contentTags:{${this.filter.tags.join('|')}}`
                } else {
                    let contentTags = []
                    this.filter.tags.forEach((tag) => {
                        contentTags.push(`@contentTags:{${tag}}`)
                    })
                    searchQuery = `${searchQuery} ${contentTags.join(' ')}`
                }
            }
            // Difficulty Filter
            if (JSON.stringify(this.filter.difficulty) !== '[]') {
                let difficulty = this.filter.difficulty.join(',')
                difficulty = difficulty.replace(/,/g, '|')
                searchQuery = `${searchQuery}  @contentDifficulty:{${difficulty}}`
            }
            var yearFromNow = this.$moment().startOf('day').add(1, 'years').format('X')
            var yearAgo = this.$moment().startOf('day').subtract(1, 'years').format('X')
            if (this.computedDate1 != '') {
                var date = this.MIX_formatDateTime(this.computedDate1, 'DD-MMM-YYYY', 'X')
                searchQuery = `${searchQuery} @contentAvailableFrom:[${date} ${yearFromNow}]`
            }
            if (this.computedDate2 != '') {
                var date2 = this.MIX_formatDateTime(this.computedDate2, 'DD-MMM-YYYY', 'X')
                searchQuery = `${searchQuery} @contentAvailableFrom:[${yearAgo} ${date2}]`
            }
            // Area Filter
            if (JSON.stringify(this.filter.areas) !== '[]') {
                let areas = this.filter.areas.join(',')
                areas = areas.replace(/,/g, '|')
                searchQuery = `${searchQuery}  @contentArea:{${areas}}`
            }
            if (this.filter.partner !== '') {
                searchQuery = `${searchQuery}  @orgId:{${this.filter.partner}}`
            }
            // Status Filter
            if (JSON.stringify(this.filter.status) !== '[]') {
                let status = this.filter.status.join(',')
                status = status.replace(/,/g, '|')
                searchQuery = `${searchQuery}  @contentStatus:{${status}}`
            }
            // Rating filter
            if (JSON.stringify(this.filter.rating) !== '[]') {
                searchQuery = `${searchQuery}  @contentRating:${this.filter.rating}`
            }

            // console.log('Search Query = ' + searchQuery)

            this.itemsFrom = 0
            return searchQuery
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers
            if (this.showStats === true) {
                headers = headers.filter((header) => header.showStats)
            } else {
                headers = headers.filter((header) => !header.hidden) // Only return headers that are not hidden
            }
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expanded !== true) // Return Headers based on Expanded State
            }
            return headers
        },
        computedSortBy() {
            let sortBy = this.datatableOptions.sortBy
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => '@' + x)
                sortBy = sortBy.join(',')
            }
            return sortBy
        },
        computedSortDesc() {
            let sortDesc = this.datatableOptions.sortDesc[0]
            return sortDesc
        },
        computedItems() {
            var items = this.items
            return items
        },
        computedFields() {
            var computedFields = []
            if (this.showStats === false) {
                computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value)
            } else {
                computedFields = this.headers.filter((x) => x.showStats === true).map((y) => '@' + y.value)
            }
            if (!computedFields.includes('@id')) {
                computedFields.push('@id')
            }
            if (!computedFields.includes('@orgId')) {
                computedFields.push('@orgId')
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted')
            }
            computedFields = computedFields.join(',')
            return computedFields
        }
    },
    methods: {
        async downloadCSV() {
            let temp = {}

            const defaultComputedFields = "@id,@contentTitle,@contentSubtitle,@contentAvailableFrom,@orgName,@contentArea,@contentTemplate,@contentDifficulty,@contentStatus,@contentRating,@action,@contentViews,@contentLikes,@contentDislikes,@contentRating,@contentDownloads"

            const defaultResults = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, 0, 9999, defaultComputedFields, this.computedSortBy, this.computedSortDesc)

            const resultsWithStats = await this.updateContentStats(defaultResults.data.results);

            const itemsResult = resultsWithStats.map(e => {
                return {
                    'Partner': e.orgName || "",
                    'Title': e.contentTitle || "",
                    'Subtitle': e.contentSubtitle || "",
                    'Area': e.contentArea || "",
                    'Difficulty': e.contentDifficulty || "",
                    'Template': e.contentTemplate || "",
                    'Status': e.contentStatus || "",
                    'Rating': e.contentRating || 0,
                    'Views': e.contentViews || 0,
                    'Likes': e.contentLikes || 0,
                    'Downloads': e.contentDownloads || 0,
                    'Available From': this.MIX_formatDateTime(e.contentAvailableFrom, 'X', 'DD-MMM-YYYY') || "",
                }
            })
                .sort((a, b) => {
                    return parseInt(b.Views) - parseInt(a.Views)
                })

            const contentCSV = this.$papa.unparse(itemsResult);
            const dateNow = Math.floor(Date.now() / 1000)
            this.$papa.download(contentCSV, `Contents_${dateNow}`, "text/csv");
        },
        paginationChanged(value) {
            console.log('Pagination Changed = ' + JSON.stringify(value, null, 2))
        },
        // * CONFIRM METHOD HANDLER
        confirmMethodHandler(value) {
            this.confirmDialog = {}
            if (value.action === 2) {
                this[value.method](value.id)
            }
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage
            this.getItems()
        },
        changeContentTagsOperator() {
            if (this.filter.contentTagsOperator === 'ALL') {
                this.filter.contentTagsOperator = 'ANY'
            } else {
                this.filter.contentTagsOperator = 'ALL'
            }
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key)
            if (result.code === 1) {
                this.getItems()
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key)
            if (result.code === 1) {
                this.getItems()
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key)
            if (result.code === 1) {
                this.getItems()
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)))
        },
        // * ADD LINK
        addLink(item) {
            this.$emit('addLink', JSON.parse(JSON.stringify(item)))
        },
        // Refresh Items
        refreshItems() {
            this.getItems()
        },
        async computedCardStats() {
            var t = this
            var searchQuery = '@deleted:0'
            var groupBy = '@contentStatus'
            let itemsResult = await this.MIX_redisAgreggateCount(this.index, searchQuery, groupBy)
            if (itemsResult.code === 1) {
                for (var i = 0; i < itemsResult.data.documents.length; i++) {
                    var item = itemsResult.data.documents[i]
                    var status = item.contentStatus
                    var count = item.count
                    if (status === 'Draft') {
                        t.cardStats.Draft = Number(count)
                        t.cardStats.Total = t.cardStats.Total + Number(count)
                    } else if (status === 'Pending') {
                        t.cardStats.Pending = Number(count)
                        t.cardStats.Total = t.cardStats.Total + Number(count)
                    } else if (status === 'Approved') {
                        t.cardStats.Approved = Number(count)
                        t.cardStats.Total = t.cardStats.Total + Number(count)
                    } else if (status === 'Live') {
                        t.cardStats.Live = Number(count)
                        t.cardStats.Total = t.cardStats.Total + Number(count)
                    } else if (status === 'Rejected') {
                        t.cardStats.Rejected = Number(count)
                        t.cardStats.Total = t.cardStats.Total + Number(count)
                    }
                }
            }
        },
        // * GET ITEMS
        async getItems() {
            this.loading = true
            if (this.datatableOptions.itemsPerPage === -1) {
                this.datatableOptions.itemsPerPage = this.itemsTotal
            }
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.computedSortBy, this.computedSortDesc)
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total
                if (this.datatableOptions.itemsPerPage === '-1') {
                    this.datatableOptions.itemsPerPage = this.itemsTotal
                }
                this.items = itemsResult.data.results
                let itemsTo = Number(this.datatableOptions.page) * Number(this.datatableOptions.itemsPerPage)
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo
                } else {
                    this.itemsTo = this.itemsTotal
                }
            }
            this.items = await this.updateContentStats(this.items);

            this.loading = false
        },
        async updateContentStats(contentResults) {

            const itemIds = contentResults.map(item => item.id).join(" | ")
            const query = ` @contentId:(${itemIds}) `

            let itemsStats = await this.MIX_contentStats({ searchQuery: query });
            if (itemsStats.code === 1) {
                // update the stats data within the stats to use the stats from the stats collection
                itemsStats = itemsStats.data
                // Create a map to easily access stats by contentId
                const statsMap = {};
                itemsStats.forEach(stat => {
                    if (!statsMap[stat.contentId]) {
                        statsMap[stat.contentId] = {};
                    }
                    statsMap[stat.contentId][stat.statType] = stat.total;
                });

                // Update the items array with stats
                contentResults = contentResults.map(item => {
                    const contentId = item.id;
                    const stats = statsMap[contentId] || {};

                    // Add stats to the item
                    item.contentViews = stats.view || 0;
                    item.contentDownloads = stats.download || 0;
                    item.contentLikes = stats.like || 0;
                    item.contentRatings = stats.rating || 0;

                    return item;
                });
            }
            return contentResults
        },

        // * Clear Tag Filter
        clearFilter(key, value) {
            setTimeout(() => {
                this.filter[key] = value
            }, 1)
        },
        // Clear Selected Org Tags
        clearSelectedContentTags() {
            setTimeout(() => {
                this.filter.tags = []
            }, 1)
        }
    },
    watch: {
        filter: {
            handler: function () {
                this.itemsFrom = 0
                this.getItems()
            },
            deep: true
        },
        refreshitems: function () {
            this.getItems()
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems()
        },
        toggleDeletedItems() {
            this.getItems()
        },
        showStats() {
            this.getItems()
        },

        computedDate1: {
            handler() {
                // // console.log("got here1")
                this.filter.availableFrom = this.computedDate1
            }
        },
        computedDate2: {
            handler() {
                // // console.log("got here 2")
                this.filter.availableTo = this.computedDate2
            }
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage')
            //// console.log('imagesIndex = ' + imagesIndex)
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false
            } else {
                this.headers[imagesIndex].hidden = true
            }
        },
        datatableOptions: {
            handler() {
                // console.log('datatableOptions updated');
                this.itemsFrom = Number(this.datatableOptions.page) * Number(this.datatableOptions.itemsPerPage) - Number(this.datatableOptions.itemsPerPage)
                // console.log('itemsFrom = ' + this.itemsFrom);
                let itemsTo = Number(this.datatableOptions.page) * Number(this.datatableOptions.itemsPerPage)
                // console.log('itemsTo = ' + this.itemsTo);
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo
                } else {
                    this.itemsTo = this.itemsTotal
                }
                //// console.log('organisationFrom=' + this.organisationFrom)
                this.getItems()
                // // console.log("here")
            },
            deep: true
        },
        listshrunk() {
            if (!this.listshrunk && !this.listexpanded) {
                this.datatableOptions.itemsPerPage = 6
                this.itemsFrom = 0
                this.getItems()
            } else if (this.listexpanded && this.listshrunk) {
                this.datatableOptions.itemsPerPage = 10
                this.itemsFrom = 0
                this.getItems()
            }
        },
        listexpanded() {
            if (this.listexpanded === false) {
                this.datatableOptions.itemsPerPage = 6
                this.itemsFrom = 0
                this.getItems()
            } else if (this.listexpanded === true) {
                this.datatableOptions.itemsPerPage = 10
                this.itemsFrom = 0
                this.getItems()
            }
        }
    },
    async created() {
        let t = this
        t.loading = true
        // Get Organisations
        t.organisations = await t.MIX_organisations()
        // // console.log("organisations = " + JSON.stringify(t.organisations, null,2 ))
        t.organisations = t.organisations.filter((item) => {
            return item.orgAccountType == 'Partner'
        })

        let contentTags = await t.MIX_redisReadSet('set:contentTags', false)
        if (contentTags.code === 1) {
            t.contentTags = contentTags.data
        }

        // Job Levels
        let jobLevels = await t.MIX_redisReadSet('set:jobLevels', false)
        if (jobLevels.code === 1) {
            t.jobLevels = jobLevels.data
        }
        // Content Areas
        let areas = await t.MIX_redisReadSet('set:contentAreas', false)
        if (areas.code === 1) {
            t.areas = areas.data
        }
        // Content Partner
        let partners = await t.MIX_redisReadSet('set:contentAreas', false)
        if (partners.code === 1) {
            t.partner = partners.data
        }
        // Departments
        let jobDepartments = await t.MIX_redisReadSet('set:jobDepartments', false)
        if (jobDepartments.code === 1) {
            t.jobDepartments = jobDepartments.data
        }
        if (this.popup === true) {
            this.datatableOptions.itemsPerPage = 3
        }
        this.getItems()
        this.computedCardStats()
    }
}
</script>
