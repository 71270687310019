<template>
    <div class="px-5 appbackground" style="width: 100% !important; height: calc(100vh - 170px) !important">
        <v-row class="pt-3 d-flex align-center">
            <v-col cols="auto" class="d-flex align-center justify-center">
                <DashCard v-for="(item, index) in dashCards" :dashvalue="getTotalStats(item)" :key="index"
                    :dashtext="item.text" :dashdescription="item.description" :dashicon="item.icon" />
            </v-col>
        </v-row>
        <v-row class="">
            <v-col cols="12" sm="12" md="6" lg="6" class="d-flex align-start justify-start">
                <v-card class="rounded-lg" elevation="0" style="width: 100% !important; height: 500px !important">
                    <v-card-title
                        class="text-body-2 font-weight-bold text-uppercase grey--text text--darken-4 d-flex justify-start align-center">
                        <div>Views by Content</div>
                        <v-spacer />
                        <div><v-icon color="grey" size="35">oomph-eye</v-icon></div>
                    </v-card-title>
                    <v-card-text class="d-flex align-start justify-center flex-column" style="width: 100% !important">
                        <donut-chart :seriesdata="getAllStatsByType('view')" seriestext="Views" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" class="d-flex align-start justify-start">
                <v-card class="rounded-lg" elevation="0" style="width: 100% !important; height: 500px !important">
                    <v-card-title
                        class="text-body-2 font-weight-bold text-uppercase grey--text text--darken-4 d-flex justify-start align-center">
                        <div>Downloads by Content</div>
                        <v-spacer />
                        <div><v-icon color="grey" size="35">oomph-export-pdf</v-icon></div>
                    </v-card-title>
                    <v-card-text class="d-flex align-start justify-start flex-column" style="width: 100% !important">
                        <pie-chart :seriesdata="getAllStatsByType('download')" seriestext="Downloads"
                            setstyle="width: 100% !important; height: 300px !important;" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" class="d-flex align-start justify-start">
                <v-card class="rounded-lg" elevation="0" style="width: 100% !important; height: 500px !important">
                    <v-card-title
                        class="text-body-2 font-weight-bold text-uppercase grey--text text--darken-4 d-flex justify-start align-center">
                        <div>Reviews by Content</div>
                        <v-spacer />
                        <div><v-icon color="grey" size="35">oomph-star</v-icon></div>
                    </v-card-title>
                    <v-card-text class="d-flex align-start justify-start flex-column" style="width: 100% !important">
                        <pie-chart :seriesdata="getAllStatsByType('review')" seriestext="Reviews" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" class="d-flex align-start justify-start">
                <v-card class="rounded-lg" elevation="0" style="width: 100% !important; height: 500px !important">
                    <v-card-title
                        class="text-body-2 font-weight-bold text-uppercase grey--text text--darken-4 d-flex justify-start align-center">
                        <div>Average attendees by Content</div>
                        <v-spacer />
                        <div><v-icon color="grey" size="35">oomph-eye</v-icon></div>
                    </v-card-title>
                    <v-card-text class="d-flex align-start justify-center flex-column" style="width: 100% !important">
                        <donut-chart :seriesdata="getAllStatsByType('attendees')" seriestext="Average Attendees" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PieChart from "@/components/echarts/PieChart.vue";
import DonutChart from "@/components/echarts/DonutChart.vue";

const DashCard = {
    props: ["dashvalue", "dashtext", "dashdescription", "dashicon"],
    methods: {
        theFormat(number) {
            return number.toFixed(0);
        },
    },
    template: `<v-card elevation="0" class="rounded-lg d-flex flex-column mr-5 white" height="150" width="175">
                    <v-card-title class="text-body-2 font-weight-bold text-uppercase grey--text text--darken-4 d-flex justify-start align-center">
                        <div>{{ dashtext }}S</div>
                        <v-spacer />
                        <div><v-icon color="primary">{{ dashicon }}</v-icon></div>
                    </v-card-title>
                    <v-card-text class="d-flex align-start justify-start flex-column">
                        <div class="text-h4 primary--text font-weight-bold" style="height: 40px !important;">
                            <number :from="0" :to="dashvalue" :format="theFormat" :duration="1" :delay="0" easing="Power1.easeOut" />
                            </div>
                        <div class="caption grey--text text--darken-2">{{ dashdescription }}</div>

                    </v-card-text>
                </v-card>`,
};

export default {
    name: "Dashboard",
    components: {
        PieChart: PieChart,
        DonutChart: DonutChart,
        DashCard: DashCard,
    },
    props: {
        orgGroups: {
            type: Array,
            default: () => {
                return []
            }
        },
        selectedOrgGroup: {
            type: Object,
            default: () => {
                return {};
            },
        },
        selectedSubOrg: {
            type: Array,
            default: () => {
                return [];
            },
        },
        daterange: {
            type: Object,
            default: () => {
                return {
                    startDate: null,
                    endDate: null,
                };
            },
        },
    },
    data: () => ({
        activities: [],
        contentStats: [],
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["contentTitle"],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        dashCards: [
            { text: "login", description: "Total Logins", type: "loginStats", icon: "oomph-landlord" },
            { text: "view", description: "Total Views", type: "contentStats", icon: "oomph-eye" },
            { text: "download", description: "Total Downloads", type: "contentStats", icon: "oomph-export-pdf" },
            { text: "review", description: "Total Reviews", type: "contentStats", icon: "oomph-star" },
        ],
        itemsFrom: 0,
        itemsTo: 0,
        itemsTotal: 0,
        loginCount: 0,
        logins: [],
        residentIds: [],
        showWelcomeTourOverlay: true,
    }),
    computed: {
        ...mapGetters({
            GET_firebase_userAuth: 'GET_firebase_userAuth',
            GET_firebase_user: 'GET_firebase_user',
            GET_brand: 'GET_brand',
        }),
        computedSortBy() {
            let sortBy = this.datatableOptions.sortBy;
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => "@" + x);
                sortBy = sortBy.join(",");
            }
            return sortBy;
        },
        computedSortDesc() {
            let sortDesc = this.datatableOptions.sortDesc[0];
            return sortDesc;
        },
        computedFields() {
            return ["@id", "@residentId", "@residentName", "@contentTitle", "@sessionDateTime", "@contentAlias", "@contentArea", "@contentPartnerOrgId", "@sessionDuration", "@sessionMoodRating", "@sessionEnagementValue", "@sessionEnagementText", "@sessionAttendance"];
        },
    },
    methods: {
        async getLoginCount() {
            let t = this;
            let query = "";
            const daterange = t.daterange.startDate && t.daterange.endDate ? `@createdDateTime:[${t.daterange.startDate} ${t.daterange.endDate}]` : '';

            if (t.selectedOrgGroup.id === '3RTRBM9dqrkC4WEsIvhx') {
                //* Returns all stats for all orgs
                query = daterange || "";
            } else {
                //* Returns stats for selected org
                query = `@createdUserOrgId:{${t.selectedOrgGroup.id}} ${daterange}`;
            }
            if (t.selectedSubOrg.length > 0) {
                //* Returns stats for selected sub orgs
                const subOrgs = t.selectedSubOrg.map((org) => org.id).join('|');
                query = `@createdUserOrgId:{${subOrgs}} ${daterange}`;
            }
            let result = await t.MIX_loginCount({ searchQuery: query });
            t.loginCount = 0;
            if (result.code === 1) {
                t.logins = result.data;
                result.data.forEach((login) => {
                    t.loginCount = t.loginCount + parseInt(login.total);
                });
            }
        },
        // sums up the total stats for each of the stats:
        // views, downloads, reviews
        getTotalStats(content) {
            let t = this;
            let total = 0;
            switch (content.type) {
                case "loginStats":
                    total = t.loginCount;
                    break;
                case "contentStats":
                    t.contentStats.forEach((item) => {
                        if (item.statType === content.text) {
                            total += parseInt(item.total);
                        }
                    });
                    break;
            }
            return total;
        },
        isNumber(str) {
            if (typeof str != "string") return false
            return !isNaN(str) && !isNaN(parseFloat(str))
        },
        getAllStatsByType(type) {
            let t = this;
            let stats = [];
            if (type === "attendees") {
                const groups = t.activities.reduce((acc, item) => {
                    const { contentAlias, sessionAttendance } = item;
                    if (!acc[contentAlias]) {
                        acc[contentAlias] = { contentAlias, sessionAttendance: 0, count: 0 };
                    }

                    if (this.isNumber(sessionAttendance)) {
                        acc[contentAlias].sessionAttendance += parseInt(sessionAttendance);
                        acc[contentAlias].count++;
                    }

                    return acc;
                }, {});

                const result = Object.values(groups).map(({ contentAlias, sessionAttendance, count }) => ({
                    contentAlias,
                    sessionAttendance: (sessionAttendance / count).toFixed(2),
                }));

                result.forEach((item) => {
                    if (item?.contentAlias !== undefined) {
                        stats.push({
                            value: parseInt(item.sessionAttendance),
                            name: item.contentAlias,
                        });
                    }
                });

            } else {
                t.contentStats.forEach((item) => {
                    if (item.statType === type && item?.contentAlias !== "") {
                        stats.push({
                            value: parseInt(item.total),
                            name: item.contentAlias,
                        });
                    }
                });
            }
            return stats;
        },
        async getStats() {
            let t = this;
            let query = "";
            const daterange = t.daterange.startDate && t.daterange.endDate ? `@createdDateTime:[${t.daterange.startDate} ${t.daterange.endDate}]` : '';

            let oomphId = '3RTRBM9dqrkC4WEsIvhx';
            if (t.selectedOrgGroup.id === oomphId) {
                //* Returns all stats for all orgs
                query = `@createdUserOrgId:{${t.selectedOrgGroup.id}} ${daterange}`;
            } else if (t.selectedOrgGroup.id !== "") {
                //* Returns stats for selected org
                query = `@createdUserOrgId:{${t.selectedOrgGroup.id}} ${daterange}`;
            }
            if (t.selectedSubOrg.length > 0 && t.selectedOrgGroup.id !== oomphId) {
                //* Returns stats for selected sub orgs
                const subOrgs = t.selectedSubOrg.map((org) => org.id).join('|');
                query = `@createdUserOrgId:{${subOrgs}} ${daterange}`;
            }
            let result = await t.MIX_count({ searchQuery: query });
            if (result.code === 1) {
                t.contentStats = result.data.filter(item => item?.contentAlias !== null);
            }
        },
        async getActivityStats() {
            let t = this;
            let query = "";
            const daterange = t.daterange.startDate && t.daterange.endDate ? `@createdDateTime:[${t.daterange.startDate} ${t.daterange.endDate}]` : '';

            if (t.selectedOrgGroup.id === '3RTRBM9dqrkC4WEsIvhx') {

                //* Returns all stats for all orgs
                query = `@statType:{review} ${daterange} -@deleted:1`;
            } else if (t.selectedOrgGroup.id !== "") {
                //* Returns stats for selected org

                query = `@createdUserOrgId:{${t.selectedOrgGroup.id}} @statType:{review} ${daterange} -@deleted:1`;
            }
            if (t.selectedSubOrg.length > 0) {
                //* Returns stats for selected sub orgs
                const subOrgs = t.selectedSubOrg.map((org) => org.id).join('|');
                query = `@createdUserOrgId:{${subOrgs}} @statType:{review} ${daterange} -@deleted:1`;
            }
            let result = await t.MIX_redisSearch("idx:statsNew", `${query}`, 0, 3000);

            if (result.code === 1) {
                const stats = result.data.documents.map((item) => item.value);
                t.activities = stats;
            }
        },
    },
    watch: {
        daterange: {
            handler: function () {
                let t = this;
                t.getLoginCount();
                t.getStats();
                t.getActivityStats();
            },
            deep: true,
        },
    },
    created() {
        let t = this;
        t.getLoginCount();
        t.getStats();
        t.getActivityStats();
    },
};
</script>
