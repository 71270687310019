<template>
    <div class="grey lighten-3">
        <v-row class="" dense>
            <v-col cols="12" sm="12" class="">
                <div class="d-flex align-center primary pa-4">
                    <div class="mx-2">
                        <v-icon class="white--text">oomph-hotel-building</v-icon>
                    </div>
                    <div class="text-left white--text text-h5 font-weight-light">Companies House Lookup</div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row class="mx-2 mb-0 align-center" dense>
            <v-col cols="12" xs="12" sm="12">
                <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                <div class="body-2">Use the search below to find the company on companies house by Registered Name or
                    Company Number, click the tick to add the Registered Name and Number to this Organisation.</div>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
                <v-text-field background-color="white" label="Search" outlined dense v-model="search" hide-details="auto" />
            </v-col>
            <v-col cols="12" xs="12" sm="4">
                <v-btn depressed class="primary" @click="lookupCompany()">Search</v-btn>
            </v-col>
            <v-col cols="12">
                <v-data-table :headers="headers" :items="companies" :items-per-page="5" class="elevation-0">
                    <template v-slot:top>
                        <div class="d-flex align-center" style="width: 100% !important">
                        </div>
                    </template>
                    <template v-slot:item.company_status="{ item }">
                        <div class="text-capitalize">{{ item.company_status }}</div>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-icon class="primary--text ml-5" @click="updateCH(item)">oomph-checked-checkbox</v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
    },
    data: () => ({
        search: '',
        companies: [],
        headers: [
            { text: 'Registered Name', value: 'title' },
            { text: 'Address', value: 'address_snippet' },
            { text: 'Company Number', value: 'company_number' },
            { text: 'Company Status', value: 'company_status' },
            { text: 'Action', value: 'action' },
        ],
    }),
    watch: {
        datadown: {
            handler: function () {
                this.search = this.datadown;
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_firebase_userAccess: 'firebase_auth_store/GET_firebase_userAccess',
            GET_FIREBASE_apiHeaderToken: 'firebase_auth_store/GET_FIREBASE_apiHeaderToken',
        }),
        apiOptions() {
            return {
                headers: {
                    Authorization: `Bearer ${this.GET_FIREBASE_apiHeaderToken}`,
                },
            };
        },
    },
    methods: {
        async lookupCompany() {
            let companySearchResult = await this.$axios.get(`${process.env.VUE_APP_OOMPHSERVER}/companieshouse/search/companies/?q=${this.search}&items_per_page=10&start_index=0&restrictions=active-companies`, this.apiOptions);
            if (companySearchResult.data.code === 1) {
                this.companies = [];
                this.companies = companySearchResult.data.data.items;
            }
        },
        updateCH(item) {
            this.$emit("updatech", { "registeredName": item.title, "registrationNumber": item.company_number });
        },
        closeItem() {
            this.$emit("closech");
        },
    },
    mounted() {
        this.search = this.datadown;
        this.lookupCompany();
    },
};
</script>
