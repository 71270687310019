<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class=" grey lighten-5 apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode" @click="saveItem()"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
         <!-- <pre>{{ item }}</pre> -->
        <v-row class=" grey lighten-5 apptext--text" dense style="z-index: 0 !important">
            <v-col v-if="!editmode && !loading" cols="12" class="d-flex align-start grey lighten-5 rounded">
                <!-- //* VIEW MODE -->
                <v-row style="width: 100% !important" no-gutters>
                    <v-col cols="12" sm="12" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Action Details</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="6" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Type:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.actionType }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Subject:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.actionSubject }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Description:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.actionDescription }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <v-col cols="6" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Action Date:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.actionDate }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Priority:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.actionPriority }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Status:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.actionStatus }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    
                    <v-col v-if="item.actionType === 'Call'" cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Call Related</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-call</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Call Type:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.callType }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Call Duration:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.callDuration }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Call Purpose:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.callPurpose }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Call Result:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.callResult }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="item.actionType === 'Meeting'" cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Meeting Related</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-meeting</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Meeting Host:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.actionMeetingHost }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Meeting Type:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.actionMeetingType }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Meeting Participants:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.actionMeetingParticipants }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="item.actionType === 'Meeting'" cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Meeting Address</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-meeting</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr class="d-flex white">
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Address:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <a :href="`https://www.google.com/maps/search/?api=1&query=${item.actionMeetingHost},${item.meetingAddressLine1},${item.meetingAddressLine2},${item.meetingAddressLine3},${item.meetingTown},${item.meetingCounty},${item.meetingPostcode}`" target="_blank">
                                                        <span v-if="item.meetingAddressLine1"
                                                            >{{ item.meetingAddressLine1 }}<span v-if="item.meetingAddressLine2">, {{ item.meetingAddressLine2 }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.meetingAddressLine3">{{ item.meetingAddressLine3 }}<br /></span>
                                                        <span v-if="item.meetingTown"
                                                            >{{ item.meetingTown }}<span v-if="item.meetingCounty">, {{ item.meetingCounty }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.meetingPostcode">{{ item.meetingPostcode }}<br /></span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="item.actionType === 'Case'" cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Case Related</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-complaint</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Case Type:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.actionCaseType }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="item.actionType === 'Event'" cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Event Related</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-calendar</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Event Host:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.actionEventHost }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Event Participants:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.actionEventParticipants }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Event Type:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.actionEventType }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="item.actionType === 'Event'" cols="12" sm="12" md="6" class="my-2 px-2">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Event Address</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-calendar</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr class="d-flex white">
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Address:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <a :href="`https://www.google.com/maps/search/?api=1&query=${item.actionEventHost},${item.eventAddressLine1},${item.eventAddressLine2},${item.eventAddressLine3},${item.eventTown},${item.eventCounty},${item.eventPostcode}`" target="_blank">
                                                        <span v-if="item.eventAddressLine1"
                                                            >{{ item.eventAddressLine1 }}<span v-if="item.eventAddressLine2">, {{ item.eventAddressLine2 }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.eventAddressLine3">{{ item.eventAddressLine3 }}<br /></span>
                                                        <span v-if="item.eventTown"
                                                            >{{ item.eventTown }}<span v-if="item.eventCounty">, {{ item.eventCounty }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.eventPostcode">{{ item.eventPostcode }}<br /></span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="editmode && !loading" cols="12" class="d-flex pt-4 px-4 align-center">
                <!-- <pre>{{ item }}</pre> -->
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- DETAILS EDIT -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Type *" v-model="item.actionType" :rules="rules.actionType" :items="actionTypes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('actionType')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Priority *" v-model="item.actionPriority" :rules="rules.actionPriority" :items="priority" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('actionPriority')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-text-field label="Subject *" v-model="item.actionSubject" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('actionSubject')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-text-field label="Description" v-model="item.actionDescription" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('actionDescription')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Action Date *" v-model="computedDate1" :rules="rules.actionStatus" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('actionStartDate')"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <!-- <v-col cols="12" xs="12" md="6">
                                    <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Action End Date *" v-model="computedDate2" :rules="rules.actionStatus" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('actionEndDate')"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="2" @input="dateMenu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-menu v-model="dateMenu3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Action Close Date *" v-model="computedDate3" :rules="rules.actionStatus" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('actionCloseDate')"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date3" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu3 = false"></v-date-picker>
                                    </v-menu>
                                </v-col> -->
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Status" v-model="item.actionStatus" :rules="rules.actionStatus" :items="status" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('actionStatus')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Lead Stage" v-model="selectedStage" :rules="rules.actionStage" :items="stages" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadStages')" :menu-props="{ top: false, offsetY: true }" :attach="true">
                                        <template v-slot:selection="data">
                                            {{ data.item.stageName }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.stageName }}
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Related</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-autocomplete
                                        label="Organisation"
                                        v-model="selectedOrganisation"
                                        :rules="rules.orgTags"
                                        :items="organisations"
                                        item-text="orgName"
                                        return-object
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearSelectedOrganisation()"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-combobox multiple small-chips hide-selected label="Contacts" v-model="selectedContacts" :items="contacts" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearItem('quoteChannel')">
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                                <span class="pr-2">
                                                    {{ item }}
                                                </span>
                                                <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            {{ item.contactUserName }}
                                            <div class="ml-2 caption grey--text">{{ item.contactUserEmail }}</div>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            </v-row>
                            <v-row v-if="item.actionType === 'Call'" dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Call Related</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Call Type *" v-model="item.callType" :rules="rules.callType" :items="callType" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('callType')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Call Duration *" v-model="item.callDuration" :rules="rules.callDuration" :items="minutes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('callDuration')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Call Purpose *" v-model="item.callPurpose" :rules="rules.callPurpose" :items="callPurpose" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('callPurpose')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Call Result *" v-model="item.callResult" :rules="rules.callResult" :items="callResult" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('callResult')"></v-select>
                                </v-col>
                            </v-row>

                            <v-row v-if="item.actionType === 'Meeting'" dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Meeting Related</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" >
                                    <v-select label="Meeting Host" v-model="item.actionMeetingHost" :rules="rules.actionMeetingHost" :items="users" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('actionMeetingHost')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Meeting Type" v-model="item.actionMeetingType" :rules="rules.actionMeetingType" :items="meetingTypes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('actionMeetingType')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-select label="Meeting Participants" v-model="item.actionMeetingParticipants" :rules="rules.actionMeetingParticipants" :items="contacts" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('actionMeetingParticipants')"></v-select>
                                </v-col>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Meeting Address</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <vuetify-google-autocomplete prepend-icon="oomph-google" autocomplete="donotcomplete" dense outlined background-color="white" label="Meeting Location" name="myapp-name" ref="addressInput" types="" id="map" classname="form-control" v-on:placechanged="getAddressDataMeeting" country="gb" clearable> </vuetify-google-autocomplete>
                                </v-col>
                                <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
                                    <v-text-field label="Address Line 1" v-model="item.meetingAddressLine1" :rules="rules.meetingAddressLine1" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('meetingAddressLine1')"></v-text-field>
                                </v-col>
                                <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
                                    <v-text-field label="Address Line 2" v-model="item.meetingAddressLine2" :rules="rules.meetingAddressLine2" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('meetingAddressLine2')"></v-text-field>
                                </v-col>
                                <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
                                    <v-text-field label="Address Line 3" v-model="item.meetingAddressLine3" :rules="rules.meetingAddressLine3" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('meetingAddressLine3')"></v-text-field>
                                </v-col>
                                <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
                                    <v-text-field label="Town" v-model="item.meetingAddressTown" :rules="rules.meetingAddressTown" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('meetingAddressTown')"></v-text-field>
                                </v-col>
                                <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
                                    <v-text-field label="County" v-model="item.meetingAddressCounty" :rules="rules.meetingAddressCounty" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('meetingAddressCounty')"></v-text-field>
                                </v-col>
                                <v-col v-if="showMeetingAddress" cols="12" xs="12" md="6">
                                    <v-text-field label="Postcode" v-model="item.meetingAddressPostcode" :rules="rules.meetingAddressPostcode" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('meetingAddressPostcode')"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row v-if="item.actionType === 'Event'" dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Event Related</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <!-- <v-select label="Event Host" v-model="item.actionEventHost" :rules="rules.actionEventHost" :items="users" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('actionEventHost')"></v-select> -->
                                    <v-autocomplete label="Event Host" v-model="selectedHost" :rules="rules.actionEventHost" :items="users" item-text="ownerUserName" auto-select-first return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOwner()">
                                        <template v-slot:selection="{ item }">
                                            {{ item.ownerUserName }}
                                            <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            {{ item.ownerUserName }}
                                            <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12">

                                    <v-combobox multiple small-chips hide-selected label="Event Participants" v-model="selectedEventParticipants" :search-input.sync="participantsAutocomplete" @change="participantsAutocomplete = ''" :items="contacts" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearItem('quoteChannel')">
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                                <span class="pr-2">
                                                    {{ item }}
                                                </span>
                                                <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            {{ item.contactUserName }}
                                            <div class="ml-2 caption grey--text">{{ item.contactUserEmail }}</div>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-select label="Event Type" v-model="item.actionEventType" :rules="rules.actionEventType" :items="eventTypes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('actionEventType')"></v-select>
                                </v-col>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Event Address</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" sm="12" class="pb-0">
                                    <vuetify-google-autocomplete autocomplete="donotcomplete" dense outlined background-color="white" label="Event Location" name="myapp-name" ref="addressInput" types="" id="map" classname="form-control" v-on:placechanged="getAddressDataEvent" country="gb" clearable> </vuetify-google-autocomplete>
                                </v-col>
                                <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
                                    <v-text-field label="Address Line 1" v-model="item.eventAddressLine1" :rules="rules.eventAddressLine1" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('eventAddressLine1')"></v-text-field>
                                </v-col>
                                <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
                                    <v-text-field label="Address Line 2" v-model="item.eventAddressLine2" :rules="rules.eventAddressLine2" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('eventAddressLine2')"></v-text-field>
                                </v-col>
                                <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
                                    <v-text-field label="Address Line 3" v-model="item.eventAddressLine3" :rules="rules.eventAddressLine3" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('eventAddressLine3')"></v-text-field>
                                </v-col>
                                <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
                                    <v-text-field label="Town" v-model="item.eventAddressTown" :rules="rules.eventAddressTown" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('eventAddressTown')"></v-text-field>
                                </v-col>
                                <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
                                    <v-text-field label="County" v-model="item.eventAddressCounty" :rules="rules.eventAddressCounty" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('eventAddressCounty')"></v-text-field>
                                </v-col>
                                <v-col v-if="showEventAddress" cols="12" xs="12" md="6">
                                    <v-text-field label="Postcode" v-model="item.eventAddressPostcode" :rules="rules.eventAddressPostcode" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('eventAddressPostcode')"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row v-if="item.actionType === 'Case'" dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Case Related</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-select label="Case Type" v-model="item.actionCaseType" :rules="rules.actionCaseType" :items="caseTypes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: true, offsetY: true }" :attach="true" @click:clear="clearItem('actionCaseType')"></v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
import moment from 'moment';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        activeitem: {
            Type: Object,
            Default: null,
        },
        editmode: {
            Type: Boolean,
            Default: false
        }
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        // editmode: false,
        // dropdowns
        addressTypes: [],
        priority: ['Urgent', 'High', 'Medium', 'Low'],
        actionTypes: [],
        status: ['Open', 'Pending', 'Closed'],
        selectedStage: [],
        stages: [],

        //call dropodwons
        callType: ['Inbound', 'Outbound', 'Missed'],
        minutes: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60'],
        callPurpose: ['None', 'Prospecting', 'Administrative', 'Negotiotiation', 'Demo', 'Project', 'Desk'],
        callResult: ['None', 'Interested', 'Not Interested', 'No Response', 'Busy', 'Requested More Info', 'Requested Call Back', 'Invalid Number'],

        //meeting dropdowns
        users: [],
        contacts: [],
        meetingTypes: ['In Person', 'Remote', 'Conference Call'],
        eventTypes: ['Tradeshow', 'Exhibition', 'Other'],
        caseTypes: ['Complaint', 'Question', 'Other'],

        selectedEventParticipants: [],
        selectedContacts: [],
        selectedHost: [],
        selectedOrganisation: null,

        organisations: [],

        // new address object
        item: {},
        loading: true,
        rules: {},

        showEventAddress: false,
        showMeetingAddress: false,

        //date pickers
		date1: moment().format("YYYY-MM-DD"),
		dateMenu1: false,
		date2: moment().add(1, "days").format("YYYY-MM-DD"),
		dateMenu2: false,
		date3: moment().add(1, "days").format("YYYY-MM-DD"),
		dateMenu3: false,
    }),
    computed: {
		computedDate1() {
			return this.$moment(this.date1, "YYYY-MM-DD").format("DD-MMM-YYYY");
		},
		computedDate2() {
			return this.$moment(this.date2, "YYYY-MM-DD").format("DD-MMM-YYYY");
		},   
		computedDate3() {
			return this.$moment(this.date3, "YYYY-MM-DD").format("DD-MMM-YYYY");
		},    
    },
    methods: {
        updateItem() {
            this.$emit('update', this.item);
        },
        getAddressDataMeeting: function (addressData, placeResultData) {
            let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
            // console.log(JSON.stringify(placeResult, null, 2));
            this.item.meetingAddressName = placeResult.name;
            this.item.meetingAddressLine1 = placeResult.addressLine1;
            this.item.meetingAddressLine2 = placeResult.addressLine2;
            this.item.meetingAddressLine3 = placeResult.addressLine3;
            this.item.meetingAddressTown = placeResult.town;
            this.item.meetingAddressCounty = placeResult.county;
            this.item.meetingAddressPostcode = placeResult.postcode;
            this.item.meetingAddressCountry = placeResult.country;
            this.item.meetingAddressLat = placeResult.lat;
            this.item.meetingAddressLng = placeResult.lng;
            this.showMeetingAddress = true
        },
        getAddressDataEvent: function (addressData, placeResultData) {
            let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
            this.item.eventAddressName = placeResult.name;
            this.item.eventAddressLine1 = placeResult.addressLine1;
            this.item.eventAddressLine2 = placeResult.addressLine2;
            this.item.eventAddressLine3 = placeResult.addressLine3;
            this.item.eventAddressTown = placeResult.town;
            this.item.eventAddressCounty = placeResult.county;
            this.item.eventAddressPostcode = placeResult.postcode;
            this.item.eventAddressCountry = placeResult.country;
            this.item.eventAddressLat = placeResult.lat;
            this.item.eventAddressLng = placeResult.lng;
            this.showEventAddress = true
        },
        formatTag: function (item) {
            if (item !== null) {
                return item.toLowerCase(); //.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            } else {
                return '';
            }
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        clearSelectedOrganisation() {
            setTimeout(() => {
                this.selectedOrganisation = { id: '', orgName: '' };
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            // let t = this;
            // if no id then generate one
            // if (item.id !== undefined && item.id !== null && item.id !== '') {
            //     // do nothing
            // } else {
            //     item.id = t.MIX_generateId();
            // }
            // let result = await this.MIX_createItem(item, this.key, this.itemNew);
            // if (result.code === 1) {
            //     this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
            // }
            // this.item = this.schema;
            // this.$emit('close');

            this.$emit('save', this.item);
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.item = { ...this.datadown };
                if (this.item.actionEventHost !== '') {
                    this.selectedHost = this.item.actionEventHost.split(',');
                }
                if (this.item.actionEventParticipants !== '') {
                    this.selectedEventParticipants = this.item.actionEventParticipants.split(',');
                }
                if (this.item.orgId !== '') {
                    this.selectedOrganisation = {
                        id: this.item.orgId,
                        orgName: this.item.orgName,
                    };
                }
                if (this.item.actionStageId !== '' && this.item.actionStageId !== null && this.item.actionStageId !== undefined) {
                    this.selectedStage.stageId = this.item.actionStageId;
                    this.selectedStage.stageName = this.item.actionStageName;
                }
            },
            deep: true,
        },
        selectedOrganisation: {
            handler: function () {
                this.item.orgId = this.selectedOrganisation.id;
                this.item.orgName = this.selectedOrganisation.orgName;
            },
            deep: true,
        },
        selectedStage: {
            handler: function () {
                this.item.actionStageName = this.selectedStage.stageName
                this.item.actionStageId = this.selectedStage.stageId
            }
        },
        activeitem: {
            handler: function () {
                let t = this;
                t.item = { ...t.activeitem };
            }, deep: true
        },
        // Selected Tags
        selectedEventParticipants: {
            handler: async function () {
                // Force Title Case on Tags
                for (let i = 0; i < this.selectedEventParticipants.length; i++) {
                    // this.selectedEventParticipants[i] = this.selectedEventParticipants[i].contactUserName.toLowerCase(); //replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
                    this.selectedEventParticipants[i] = this.selectedEventParticipants[i].contactUserName;
                    if (this.selectedEventParticipants[i] === ' ') {
                        this.selectedEventParticipants.splice(i, 1);
                    } else {
                        this.selectedEventParticipants[i] = this.selectedEventParticipants[i].trim();
                    }
                }
                // Update Quote Tags and split out array with commas
                this.item.actionEventParticipants = this.selectedEventParticipants.join(',');
            },
            deep: true,
        },
        selectedContacts: {
            handler: async function () {
                // Force Title Case on Tags
                for (let i = 0; i < this.selectedContacts.length; i++) {
                    // this.selectedEventParticipants[i] = this.selectedEventParticipants[i].contactUserName.toLowerCase(); //replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
                    this.selectedContacts[i] = this.selectedContacts[i].contactUserName;
                    if (this.selectedContacts[i] === ' ') {
                        this.selectedContacts.splice(i, 1);
                    } else {
                        this.selectedContacts[i] = this.selectedContacts[i].trim();
                    }
                }
                // Update Quote Tags and split out array with commas
                this.item.selectedContacts = this.selectedContacts.join(',');
            },
            deep: true,
        },

        dateMenu1() {
            this.item.actionDate = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
           
        },
        dateMenu2() {
            this.item.actionEndDate = this.$moment(this.date2, 'YYYY-MM-DD').format('X');
            // this.checkDates();
        },
        dateMenu3() {
            this.item.actionCloseDate = this.$moment(this.date3, 'YYYY-MM-DD').format('X');
            // this.checkDates();
        },
    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.action;
        t.loading = true
        t.item = { ...t.datadown };

        if (t.item.actionDate == '') {
            t.item.actionDate = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
        }


        t.organisations = await t.MIX_organisations();
        let addressTypes = await t.MIX_redisReadSet('set:addressTypes', false);
        if (addressTypes.code === 1) {
            t.addressTypes = addressTypes.data;
        }
        let actionTypes = await t.MIX_redisReadSet('set:actionTypes', false);
        if (actionTypes.code === 1) {
            t.actionTypes = actionTypes.data;
        }

        let stages = await this.MIX_redisSearch('idx:stages', `*`, 0, 9999);
        stages = stages.data.documents.map((x) => x.value);

        t.stages = stages.filter((item) => {
            return item.stagePipeline === 'Accounts';
        })
        t.stages = t.stages.map((x) => {
            return {
                stageId: x.id,
                stageName: x.stageName,
            };
        })

        // Get Users
        let users = await this.MIX_users(''); // Add Users to Dropdown for Owner
        this.users = users.map((x) => {
            return {
                ownerUserId: x.id,
                ownerUserName: x.userName,
                ownerUserEmail: x.userEmail,
            };
        });
        // Get Users
        let contacts = await this.MIX_contacts(''); // Add Users to Dropdown for Owner
        this.contacts = contacts.map((x) => {
            return {
                contactUserId: x.id,
                contactUserName: x.contactName,
                contactUserEmail: x.contactEmail,
            };
        });
        t.loading = false
    },
    mounted() {},
};
</script>