<template>
    <v-card :class="actionLead === true ? '' : '' " flat outlined>
        <v-col cols="12" class="align-start">
		    <v-row class="pa-2 pb-0">
                <v-col cols="12" class="d-flex ma-0 pa-0 pl-1 align-start">
                    <!-- @click="openLead" -->
                    <div>
                        <!-- <pre>{{ lead }}</pre> -->
                        <div v-if="actionLead == false || orgIsGroup" class="font-weight-bold text-start body-2 text-truncate primary--text" style="max-width: 200px !important">
                            {{ lead.orgName }} 
                        </div>
                        <div v-if="lead.salesTitle" class="font-weight-bold text-start body-2 text-truncate grey--text" style="max-width: 250px !important">
                            {{ lead.salesTitle }} 
                        </div>
                    </div>
                    <v-spacer />
                    <div >
                        <v-avatar v-if="lead.ownerUserName" color="caption primary white--text font-weight-regular" size="30">{{ lead.ownerUserName.match(/\b(\w)/g).join('') }}</v-avatar>
                    </div>
                </v-col>
            </v-row>

		    <v-row class="pa-2 pt-0" :class="reveal ? 'pb-2' : ''">
		    	<v-col cols="12" class="d-flex ma-0 pa-0 pr-3 justify-end align-center">
                    <!-- <div> -->
                    <div v-if="lead.salesPackages !== '' && lead.salesPackages !== undefined && lead.salesPackages !== null">
                        <div class="d-flex align-center">
                            <div v-for="item in lead.salesPackages.split(',')" :key="item">
                                <PackageIcon :packagedata="getPackageDetails(item)" />
                            </div>
                        </div>
                    </div>
                    <v-spacer />
                    <div v-if="lead.salesType" 
                        class="font-weight-regular body-2 pr-3 text-start text-uppercase" 
                        :class="lead.salesType === 'hot' ? 'error--text' : lead.salesType === 'warm' ? 'warning--text' : lead.salesType === 'cold' ? 'info--text' : 'grey--text'"
                    >
                        {{ lead.salesType }} 
                    </div>
                    <!-- <v-spacer /> -->
                    <div>
                        <v-icon class="grey--text" size="15" @click="reveal = !reveal">oomph-expand-arrow</v-icon>
                    </div>
                    <!-- </div> -->
                </v-col>
            </v-row>

            <v-card v-if="reveal === true" class="mt-3 elevation-0" style="height: 100% !important; width: 100% !important">
                <v-divider />
                    <table class="ma-0 pa-0 mt-3 caption grey--text text--darken-2 " border="0" width="100%">
                        <!-- <tr>
                            <td class="font-weight-bold" width="40%" align="left">Exp Close:</td>
                            <td class="" width="60%" align="left">{{ MIX_formatDateTime(lead.salesEstimatedCloseDate, 'X', 'MMM-YY') }}</td>
                        </tr> -->
                        <tr>
                            <td class="font-weight-bold" width="40%" align="left">Owner:</td>
                            <td class="" width="60%" align="left">{{ lead.ownerUserName }}</td>
                        </tr>
                    </table>
                    
                    <div class="d-flex justify-stretch pt-3" style="width: 100% !important;">
                        <div v-if="actionLead == false" style="width: 48% !important;"><v-btn block depressed @click="openLead" class="caption font-weight-bold primary--text">Account</v-btn></div>
                        <v-spacer />
                        <div style="width: 48% !important;"><v-btn block depressed @click="openLeadItem" class="caption font-weight-bold primary--text">Edit</v-btn></div>
                    </div>
            </v-card>
        </v-col>

    </v-card>
</template>

<script>
export default {
    data: () => ({
        lead: {},
        reveal: false,
        orgType: '',
    }),
	props: {
        datadown: { 
            type: Object, 
            default: () => ({})
        },
        actionLead: {
            type: Boolean,
            default: false,
        },
        orgIsGroup: {
            type: Boolean,
            default: false,
        }
	},
    computed: {

    },
    methods: {
        openLead() {
            // ////console.log('openLead');
            this.$emit('openlead', this.lead);
        },
        openLeadItem() {
            this.$emit('openleaditem', this.lead);
        }
    },
    async created() {
        var t = this
        this.lead = { ...this.datadown };

        let itemResult = await this.MIX_readItem(t.lead.orgId, 'org');
        if (itemResult.code === 1) {
            // this.$emit('edit', itemResult.data);
            // // ////console.log("result = " + JSON.stringify(itemResult.data))
            t.orgType = itemResult.data.orgAccountTypes
        }
        // // ////console.log("lead = " + JSON.stringify(this.lead, null, 2));
    },

}
</script>

<style scoped>
.borderClass {
    border-left: 5px solid #019EAF !important;
    border-color: #019EAF !important
}
</style>
