<template>
    <div class=""
        style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow: scroll !important;">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey lighten-1 py-4">
                <div class="mx-3 d-flex align-center justify-end" style="width: 100% !important">
                    <v-btn text class="primary white--text" @click="updateOrg()"><v-icon
                            class="mr-2">oomph-save</v-icon>Update</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="6" class="mt-2 d-flex align-start pl-4 pr-1">
                <v-card class="elevation-0" style="width: 100% !important; height: 100% !important">
                    <v-card-title class="grey--text text--darken-2">Divisions</v-card-title>
                    <v-card-text class="text-left">
                        <div class="d-flex align-center">
                            <div class="flex-grow-1"><v-text-field label="Division" v-model="division" required outlined
                                    dense hide-details="auto" background-color="white" color="primary"
                                    autocomplete="donoautocomplete"></v-text-field></div>
                            <div><v-btn height="40" @click="addDivision" block class="grey--text text--lighten-2" depressed
                                    color="primary ml-2">Add</v-btn></div>
                        </div>
                        <div class="mt-5 grey--text" v-if="JSON.stringify(divisions) === '[]'">No Divisions</div>
                        <ul class="mt-5">
                            <li v-for="(division, index) in divisions" :key="index">
                                <span v-if="division.isEditing">
                                    <input v-model="editValueDivision" required />
                                    <v-btn @click="editDivision(index)" style="height: 100%;"
                                        class="grey--text text--lighten-2" color="primary ml-1">Save</v-btn>
                                </span>
                                <span v-else>
                                    {{ division.name }}
                                    <v-icon class="my-1 ml-1 grey--text"
                                        @click="deleteDivision(index)">oomph-trash-can</v-icon>
                                    <v-icon class="my-1 ml-1 grey--text"
                                        @click="changeDivisionEditing(index)">oomph-edit</v-icon>
                                </span>
                            </li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" class="mt-2 d-flex align-start pr-4 pl-1">
                <v-card class="elevation-0" style="width: 100% !important; height: 100% !important">
                    <v-card-title class="grey--text text--darken-2">Regions</v-card-title>
                    <v-card-text class="text-left">
                        <div class="d-flex align-center">
                            <div class="flex-grow-1"><v-text-field label="Regions" v-model="region" required outlined dense
                                    hide-details="auto" background-color="white" color="primary"
                                    autocomplete="donoautocomplete"></v-text-field></div>
                            <div><v-btn height="40" @click="addRegion" block class="grey--text text--lighten-2" depressed
                                    color="primary ml-2">Add</v-btn></div>
                        </div>
                        <div class="mt-5 grey--text" v-if="JSON.stringify(regions) === '[]'">No Regions</div>
                        <ul class="mt-5">
                            <li v-for="(region, index) in regions" :key="index">
                                <span v-if="region.isEditing">
                                    <input v-model="editValueRegion" required />
                                    <v-btn @click="editRegion(index)" style="height: 100%;"
                                        class="grey--text text--lighten-2" color="primary ml-2">Save</v-btn>
                                </span>
                                <span v-else>
                                    {{ region.name }}
                                    <v-icon class="my-1 ml-1 grey--text"
                                        @click="deleteRegion(index)">oomph-trash-can</v-icon>
                                    <v-icon class="my-1 ml-1 grey--text"
                                        @click="changeRegionEditing(index)">oomph-edit</v-icon>
                                </span>
                            </li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- <pre>{{ org }}</pre> -->
    </div>
</template>

<script>
export default {
    props: {
        datadown: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        editValueDivision: "",
        editValueRegion: "",
        division: "",
        region: "",
        divisions: [],
        regions: [],
        org: {},
    }),
    computed: {},
    methods: {
        initialise() {
            this.org = this.datadown;
            if (this.org.orgDivisions !== "" && this.org.orgDivisions !== undefined) {
                const divisions = this.org.orgDivisions.split(",");

                divisions.forEach((division) => {
                    this.divisions.push({
                        name: division,
                        isEditing: false,
                    });
                });
            }
            if (this.org.orgRegions !== "" && this.org.orgRegions !== undefined) {
                const regions = this.org.orgRegions.split(",");

                regions.forEach((region) => {
                    this.regions.push({
                        name: region,
                        isEditing: false,
                    });
                });
            }
        },
        changeDivisionEditing(index) {
            this.divisions.forEach(d => d.isEditing = false);
            this.divisions[index].isEditing = true;
            this.editValueDivision = this.divisions[index].name;
        },
        editDivision(index) {
            this.divisions[index].name = this.editValueDivision;
            this.divisions[index].isEditing = false;
            this.editValueDivision = "";
        },
        addDivision() {
            this.divisions.push({
                name: this.division,
                isEditing: false,
            });
            this.division = "";
        },
        deleteDivision(index) {
            this.divisions.splice(index, 1);
        },
        addRegion() {
            this.regions.push({
                name: this.region,
                isEditing: false,
            });
            this.region = "";
        },
        changeRegionEditing(index) {
            this.regions.forEach(r => r.isEditing = false);
            this.regions[index].isEditing = true;
            this.editValueRegion = this.regions[index].name;
        },
        editRegion(index) {
            this.regions[index].name = this.editValueRegion;
            this.regions[index].isEditing = false;
            this.editValueRegion = "";
        },
        deleteRegion(index) {
            this.regions.splice(index, 1);
        },
        updateOrg() {
            this.org.orgDivisions = this.divisions.map(division => division.name).join(",");
            this.org.orgRegions = this.regions.map(region => region.name).join(",");
            this.$emit('save', this.org);
        },
    },
    watch: {
        datadown: {
            handler: function () {
                /* In order to render another org */
                this.editValueDivision = "";
                this.editValueRegion = "";
                this.division = "";
                this.region = "";
                this.divisions = [];
                this.regions = [];
                this.org = {};

                this.initialise();
            },
            deep: true,
        },
    },
    async created() {
        this.initialise();
    },
};
</script>
