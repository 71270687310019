<template>
    <div class="px-5 appbackground" style="width: 100% !important; height: calc(100vh - 170px) !important;">
        <div class="d-flex align-center py-5">
            <div class="text-h5 font-weight-bold primary--text">Total Logins</div>
            <v-spacer />
            <div>
                <v-btn class="primary white--text rounded" @click="downloadCSV()" depressed><v-icon class="mr-2">icons8-export-csv</v-icon>Download CSV</v-btn>
            </div>
        </div>
        <v-data-table :options.sync="datatableOptions" :loading="loading" must-sort :headers="computedInteractionHeaders" :items="computedInteractionsData" :items-per-page="10" class="elevation-0">
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        selectedOrgGroup: {
            type: Object,
            default: () => {
                return {};
            },
        },
        selectedSubOrg: {
            type: Array,
            default: () => {
                return [];
            },
        },
        daterange: {
            type: Object,
            default: () => {
                return {
                    startDate: null,
                    endDate: null,
                };
            },
        },
        filterBy: {
            type: Array,
            default: () => {
                return [];
            },
        },
        divisions: {
            type: Boolean,
            default: () => {
                return false;
            },
        }
    },    
    data: () => ({
        contentItem: [],
        contentItems: [],
        contentTemplateKey: 0,
        loading: false,
        showContentItem: false,
        interactionData: [],
        interactionHeaders: [],
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['total'],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
    }),
    watch: {
        daterange: {
            handler: function () {
                let t = this;
                t.getInteractionsStats();
            },
            deep: true,
        },
        filterBy: {
            handler: function () {
                let t = this;
                t.getInteractionsStats();
            },
            deep: true,
        },
    }, 
    computed: {
        computedInteractionsData() {
            return this.interactionData
        },
        computedInteractionHeaders() {
            let headers = [
                { text: "Division", value: "division", align: "start" },
                { text: "Home", value: "orgName", align: "start" },
                { text: "On Demand Logins", value: "logins", align: "center" },
                { text: "TV Logins", value: "tvlogins", align: "center" },
                { text: "Total Logins", value: "total", align: "center" },
            ]

            if (!this.divisions || this.selectedSubOrg < 1) headers.shift()
            return headers
        }
    },
    methods: {
        async getInteractionsStats() {
            let t = this;
            let query = "";
            let org = ""
            let subOrgs = [];
            t.loading = true;

            const daterange = t.daterange.startDate && t.daterange.endDate ? `@createdDateTime:[${t.daterange.startDate} ${t.daterange.endDate}]` : '';

            if (t.selectedOrgGroup.id === '3RTRBM9dqrkC4WEsIvhx') {
                //* Returns all stats for all orgs
                query = daterange;
                org = ""
            } else {
                //* Returns stats for selected org
                query = `@createdUserOrgId:{${t.selectedOrgGroup.id}} ${daterange}`;
                org = `@id:(${t.selectedOrgGroup.id})`
            }
            if (t.selectedSubOrg.length > 0) {
                //* Returns stats for selected sub orgs
                subOrgs = t.selectedSubOrg.map((org) => org.id).join('|');
                query = `@createdUserOrgId:{${subOrgs}} ${daterange}`;
                org = `@id:(${subOrgs})`
            } 

         
            // let tvloginQuery = `${query} @statType:{tvlogin}`

            // let tvlogins = t.MIX_loginCount({ searchQuery: tvloginQuery });
            // const loginSearchQuery = t.selectedOrgGroup.id === '3RTRBM9dqrkC4WEsIvhx' ? "*" : loginQuery

            let logins = t.MIX_loginUsers({ searchQuery: query || "*", orgId: t.selectedOrgGroup.id, subOrgs: subOrgs });
            let orgs = t.MIX_organisations(null, org);
            let downloads = t.MIX_downloadsOrgs({ searchQuery: query });
            let views = t.MIX_viewsOrgs({ searchQuery: query });

            const res = await Promise.all([logins, downloads, views, orgs]).then(data => {
                return {
                    logins: data[0].data,   
                    downloads: data[1].data,
                    views: data[2].data,
                    orgs: data[3],
                }
            })

            console.log(`login count: ` + res.logins.length);
            console.log(`downloads count: ` + res.downloads.length);
            console.log(`views count: ` + res.views.length);
            console.log(`orgs count: ` + res.orgs.length);

            // let count = {}
            // for (let i = 0; i < res.logins.length; i++) {
            //     const statType = res.logins[i]?.statType || "NONE"

            //     if (count[statType] == undefined) count[statType] = 0
            //     count[statType]++
                
            // }

            // console.log(count);
       
            if (res) {
                // loop through res1 and res2 and add downloads and views together by id
                let temp = {}

                logins = res.logins
                downloads = res.downloads
                views = res.views
                orgs = res.orgs

                for (let i = 0; i < orgs.length; i++) {
                    const org = orgs[i].id
                    if (temp[org] == undefined) {
                        temp[org] = {}
                        temp[org]["orgId"] = org
                        temp[org]["orgName"] = orgs[i].orgName
                        temp[org]["division"] = orgs[i]?.orgDivision || ""
                        temp[org]["logins"] = 0
                        temp[org]["tvlogins"] = 0
                        temp[org]["total"] = 0
                    }      
                }

               for (let i = 0; i < logins.length; i++) {
                   const org = logins[i]?.orgId || logins[i]?.orgName || null
                    if (!org || temp[org] == undefined) continue
                    temp[org]["tvlogins"] += this.isNumber(logins[i]['tv']);
                    temp[org]["logins"] += this.isNumber(logins[i]['ondemand']);
                    temp[org]["total"] += this.isNumber(logins[i]['tv']) + this.isNumber(logins[i]['ondemand']);
                }

                let result = Object.values(temp);
                //if logins is 0, set interactions to 0
                for (let i = 0; i < result.length; i++) {
                    if (result[i].logins === 0) result[i].total = 0
                }

                const sorted = result.sort((a, b) => {
                    const key = this.datatableOptions.sortBy[0]
                    const operator = this.datatableOptions.sortDesc[0]

                    const valA = a[key]
                    const valB = b[key]

                    if (operator) {
                        if (valA < valB) return 1
                        if (valA > valB) return -1
                    } else {
                        if (valA < valB) return -1
                        if (valA > valB) return 1
                    }
                   
                    return 0

                })

                t.interactionData = []
                t.interactionData = sorted;
            }
            t.loading = false;
        },
        createOrg(item){
            const org = item.orgId || item.orgName
            let def = {
                orgId: org,
                orgName: item.orgName,
                division: item?.orgDivision || "",
                logins: 0,
                tvlogins: 0,
                total: 0,
            }
            return def
        },
        isNumber(n) {
            const num = parseInt(n);
            return !isNaN(num) ? num : 0;
        },
        downloadCSV() {
            let t = this;
            const dateFrom = t.$moment.unix(t.daterange.startDate).format('DDMMMYYYY');
            const dateTo = t.$moment.unix(t.daterange.endDate).format('DDMMMYYYY');
            const dateNow = t.$moment().format('DDMMMYYYY');
            const date = t.daterange.startDate && t.daterange.endDate ? `${dateFrom}_${dateTo}` : dateNow;
            let downloads = t.interactionData.map(e => {
                let final = {
                    'Home': e.orgName,
                    'Division': e.division,
                    'On Demand Logins': e.logins,
                    'TV Logins': e.tvlogins,
                    'Total Logins': e.logins + e.tvlogins
                }

                if (!this.divisions || this.selectedSubOrg < 1)  delete final.Division
                return final
            })

            const objChange = {
                "total": "Total Logins",
                "logins": "On Demand Logins",
                "tvlogins": "TV Logins",
                "orgName": "Home",
            }

            if (t.datatableOptions.sortBy.length > 0){
                downloads = downloads.sort((a, b) => {
                    let key = objChange[t.datatableOptions.sortBy[0]]
                    let valA = a[key]
                    let valB = b[key]
                    let operator = t.datatableOptions.sortDesc[0]

                    if (operator) {
                        if (valA < valB) return 1
                        if (valA > valB) return -1
                    } else {
                        if (valA < valB) return -1
                        if (valA > valB) return 1
                    }
                   
                    return 0
                })
            }
            

            let interactionsCSV = t.$papa.unparse(downloads);
            this.$papa.download(interactionsCSV, `Report_Total_Logins_${date}.csv`, "text/csv");
        },
        
    },
    created() {
        let t = this;
        t.getInteractionsStats();
    },
};
</script>
