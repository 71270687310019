<template>
    <v-row fluid fill-height class="d-flex flex-column align-start appbackground" style="height: 100% !important" no-gutters>
        <v-col class="align-center pa-3 mt-1" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
            <div class="d-flex align-center mb-1">
                <div class="d-flex align-center">
                    <div><v-icon class="primary--text" x-large>oomph-info</v-icon></div>
                    <div class="text-left primary--text pl-1 text-h4 font-weight-thin">Release Notes</div>
                </div>
                <v-spacer></v-spacer>
                <div class="ml-1">
                    <v-btn depressed block class="primary darken-2 white--text" @click="openItem({})">
                        <v-icon>oomph-info</v-icon>
                        <span v-if="!listShrunk" class="ml-3">New</span>
                    </v-btn>
                </div>
                <div>
                    <v-btn class="white--text primary ml-2" @click="listShrunk = false" depressed v-if="listShrunk">
                        <v-icon color="white">oomph-show-sidepanel</v-icon>
                    </v-btn>
                    <v-btn class="white--text primary ml-2" @click="listShrunk = true" depressed v-if="!listExpanded && !listShrunk">
                        <v-icon color="white">oomph-hide-sidepanel</v-icon>
                    </v-btn>
                </div>
            </div>
            <release-note-list :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:openItem="openItem" :refreshItems="refreshItems" />
        </v-col>
        <!-- Individual Release Component -->
        <transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
            <v-col
                style="border-left: #239eaf solid 1px !important; height: 100% !important"
                v-if="!listExpanded"
                class="grey lighten-3 pa-0 ma-0"
                cols="12"
                :xs="itemCols.xs"
                :sm="itemCols.sm"
                :md="itemCols.md"
                :lg="itemCols.lg"
                :xl="itemCols.xl"
            >
                <release v-on:refreshItems="refreshItems" :listshrunk="listShrunk" :datadown="activeItem" v-on:closeitem="closeItem" />
            </v-col>
        </transition>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import Release from '@/views/releasenotes/Release.vue';
import ReleaseNoteList from '@/views/releasenotes/ReleaseNotesList.vue';
export default {
    name: 'Users',
    components: {
        Release: Release,
        ReleaseNoteList: ReleaseNoteList,
    },
    data: () => ({
        activeItem: {},
        editMode: false,
        itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
        itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        headers: [
            { text: 'ID', align: 'start', value: 'id', hidden: true },
            { text: 'User', align: 'start', sortable: true, value: 'photoURL', hidden: false },
            { text: 'Name', value: 'userName', hidden: false },
            { text: 'Email', value: 'email', hidden: false },
            { text: 'User Level', value: 'userLevel', hidden: false },
            { text: 'User Status', value: 'userStatus', hidden: false },
            { text: 'Actions', value: 'actions', hidden: false },
        ],
        listExpanded: true,
        listShrunk: false,
        pinRules: [(v) => !!v || 'User Pin is required.'],
        refreshItems: 0,
        search: '',
        show: false,
        tab: '',
        taskCols: { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
        user: {},
        userDialog: false,
        userLevelItems: ['DeveloperAdmin', 'StaffAdmin'],
        users: [],
        userStatusItems: ['Approved', 'Pending', 'Deleted'],
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
        }),
        computedHeaders() {
            return this.headers.filter((header) => !header.hidden);
        },
    },
    watch: {
        listExpanded() {
            this.refreshCols();
        },
        listShrunk() {
            this.refreshCols();
        },
    },
    methods: {
        refreshitems() {
            this.refreshItems++;
        },
        refreshCols() {
            switch (true) {
                // Expanded List with No Task Bar
                case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // Expanded List with Task Bar
                case this.listExpanded && !this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
                    this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;
                // List with Product Page (No Task Bar)
                case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // List with Product Page and Task Bar
                case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
                    this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;
                // Shrunk List with Product Page (No Task Bar)
                case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }; // 2
                    this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 }; // 10
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
                    break;
                // Shrunk List with Product Page and Task Bar)
                case !this.listExpanded && this.listShrunk && this.GET_taskBar:
                    this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
                    this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
                    break;
                default:
                    this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 12
                    this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
                    this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
            }
        },
        openItem(item) {
            this.activeItem = item;
            this.listExpanded = false;
        },
        editUser(item) {
            let t = this;
            t.userDialog = true;
            t.user = item;
        },
        closeItem() {
            this.listExpanded = true;
            this.listShrunk = false;
        },
    },
};
</script>
