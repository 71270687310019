<template>
    <v-card flat outlined>
        <v-col cols="12" class="align-start">
		    <v-row class="pa-2 pb-0">
                <!-- {{ contact }} -->
		    	<v-col cols="12" class="d-flex ma-0 pa-0 pl-1 align-center">
                    <div style="max-width: 400px !important">
                        <div class="font-weight-bold text-start body-2 d-flex text-truncate primary--text" style="max-width: 450px !important">
                            {{ contact.contactFirstName }} {{ contact.contactLastName}}
                            <div v-if="contact.contactJobTitle" class="font-weight-bold caption pl-2 text-start text-truncate grey--text text--lighten-1">
                                {{ contact.contactJobTitle }} 
                            </div>
                        </div>
                        <div 
                            v-if="contact.contactMobilePhone" 
                            class="body-2 text-start primary--text font-weight-normal body-2 white"
                            style="max-width: 350px !important" >
                                <v-icon size="15" class="primary--text mr-2">oomph-phone</v-icon>
                                <a :href="'callto:' + contact.contactMobilePhone" target="_blank">{{ contact.contactMobilePhone }}</a>
                        </div>
                        <div 
                            v-if="orgIsGroup == true" 
                            class="body-2 text-start primary--text d-flex font-weight-normal body-2 white"
                            style="max-width: 350px !important" >
                                <v-icon size="15" class="primary--text mr-2">oomph-city</v-icon>
                                <div>{{ contact.orgName }}</div>
                        </div>
                        <div 
                            v-if="contact.contactEmail" 
                            class="body-2 text-start primary--text font-weight-normal body-2 white"
                            style="max-width: 350px !important" >
                                <v-icon size="15" class="primary--text mr-2">oomph-mail</v-icon>
                                <a :href="'mailto:' + contact.contactEmail" target="_blank">{{ contact.contactEmail }}</a>
                        </div>
                    </div>
                </v-col>
            </v-row>

		    <v-row class="pa-2 pt-0" :class="reveal ? 'pb-2' : ''">
		    	<v-col cols="6" class="d-flex ma-0 pa-0 pl-1 align-start">
                </v-col>
		    	<v-col cols="6" class="d-flex ma-0 pa-0 pr-3 justify-end align-center">
                    <!-- <div> -->
                        <div v-if="contact.contactPriority" class="font-weight-regular body-2 pr-3 text-start text-uppercase" :class="contact.contactPriority === 'Urgent' || contact.contactPriority === 'High' ? 'error--text' : contact.contactPriority === 'Medium' ? 'warning--text' : contact.contactPriority === 'Low' ? 'info--text' : 'grey--text'">
                            {{ contact.contactPriority }}
                        </div>
                        <!-- <div v-if="contact.icon" class="font-weight-regular body-2 pr-3 text-start text-uppercase" :class="contact.leadType === 'hot' ? 'error--text' : contact.leadType === 'warm' ? 'warning--text' : contact.leadType === 'cold' ? 'info--text' : 'grey--text'">
                            {{ contact.icon }} 
                        </div> -->
                        <!-- <v-spacer /> -->
                        <!-- <v-icon class="grey--text" size="15" @click="reveal = !reveal">oomph-expand-arrow</v-icon> -->
                    <!-- </div> -->
                </v-col>
            </v-row>

            <v-card v-if="reveal === true" class="mt-3 elevation-0" style="height: 100% !important; width: 100% !important">
                <v-divider />
                <v-row>
                    <v-col class="pb-0 pt-6" cols="5">
                        <div class="pl-0 font-weight-regular body-2 text-start grey--text white">Next contact:</div>
                    </v-col>
                    <v-col class="pb-0 pt-6"  cols="7">
                        <div class="pl-0 font-weight-regular body-2 text-start grey--text white">{{ contact.fullName }}</div>
                    </v-col>
                    <v-col class="pb-0 pt-1"  cols="5">
                        <div class="pl-0 font-weight-regular body-2 text-start grey--text white">Expected Close:</div>
                    </v-col>
                    <v-col class="pb-0 pt-1"  cols="7">
                        <div class="pl-0 font-weight-regular body-2 text-start grey--text white">{{ contact.fullName }}</div>
                    </v-col>
                    <v-col class="pb-0 pt-1"  cols="5">
                        <div class="pl-0 font-weight-regular body-2 text-start grey--text white">Owner:</div>
                    </v-col>
                    <v-col class="pb-0 pt-1"  cols="7">
                        <div class="pl-0 font-weight-regular body-2 text-start primary--text white">{{ contact.ownerUserName }}</div>
                    </v-col>
                    <v-col class="pb-0 pt-1" cols="5">
                        <div class="pl-0 font-weight-regular body-2 text-start grey--text white">Created:</div>
                    </v-col>
                    <v-col class="pb-0 pt-1"  cols="7">
                        <div class="pl-0 font-weight-regular body-2 text-start grey--text white">{{ MIX_formatDateTime(contact.createdDateTime, 'x', 'DD-MMM-YYYY') }} how long ago</div>
                    </v-col>
                    <v-col class=" pt-1" cols="5">
                        <div class="pl-0 font-weight-regular body-2 text-start grey--text white">Modified:</div>
                    </v-col>
                    <v-col class=" pt-1" cols="7">
                        <div class="pl-0 font-weight-regular body-2 text-start grey--text white">{{ MIX_formatDateTime(contact.modifiedDateTime, 'x', 'DD-MMM-YYYY') }}</div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

    </v-card>
</template>

<script>
export default {
    data: () => ({
        contact: {},
        reveal: false,
    }),
	props: {
        datadown: { 
            type: Object, 
            default: () => ({})
        },
        orgIsGroup: {
            type: Boolean,
            default: false,
        }
	},
    computed: {

    },
    methods: {
        openLead() {
            // ////console.log('openLead');
            this.$emit('openlead', this.contact);
        },
    },
    created() {
        this.contact = { ...this.datadown };
        // ////console.log("contact = " + JSON.stringify(this.contact, null, 2));
    },

}
</script>
