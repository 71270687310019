<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field label="Search" v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="oomph-search" @click:clear="searchQuery = ''"></v-text-field>
            </div>
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshList">
                <v-icon>oomph-available-updates</v-icon>
            </v-btn>
            <div class="ml-2">
                <v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>oomph-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <v-row class="pa-3 mt-1">
            <!-- TABLE BASED LIST -->
            <v-data-table :item-class="itemRowBackground" sortable dense class="" style="width: 100%" :headers="computedHeaders" :items="computedQuizzes" :server-items-length="itemsTotal" :options.sync="datatableOptions">
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="Permanently Delete" value="destroy" small>
                                <v-icon class="error--text">oomph-delete-trash</v-icon>
                            </v-btn>
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                            <v-btn title="Undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyReviewQuestions(item.id)">oomph-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteQuiz(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreQuiz(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openQuiz(item)">oomph-forward</v-icon>
                </template>
            </v-data-table>
        </v-row>
    </div>
</template>

<script>
export default {
    // * PROPS
    props: {
        refreshlist: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        reviewQuestions: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'Title', value: 'reviewQuestionsTitle', sortable: false, hidden: false, expanded: false },
            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Created Name', value: 'createdUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Name', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: false },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Name', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Email', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        sortBy: '@reviewQuestionsTitle',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['reviewQuestionsTitle'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
    }),
    computed: {
        // Computed Search Query
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:0';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }
            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (this.listshrunk) {
                headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            } else {
                headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            }
            return headers;
        },
        // Computed Quizzes
        computedQuizzes() {
            var reviewQuestions = this.reviewQuestions;
            return reviewQuestions;
        },
        // Computed Fields
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
    },
    // * METHODS
    methods: {
        // Refresh the List
        refreshList: function () {
            this.getReviewQuestions();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // Destroy
        async destroyReviewQuestions(itemId) {
            let result = await this.MIX_destroyItem(itemId, 'reviewQuestions');
            if (result.code === 1) {
                this.getReviewQuestions();
            }
        },
        // Delete
        async deleteQuiz(itemId) {
            let result = await this.MIX_deleteItem(itemId, 'reviewQuestions');
            if (result.code === 1) {
                this.getReviewQuestions();
            }
        },
        // Restore
        async restoreQuiz(itemId) {
            let result = await this.MIX_restoreItem(itemId, 'reviewQuestions');
            if (result.code === 1) {
                this.getReviewQuestions();
            }
        },
        // Open
        openQuiz(item) {
            this.$emit('open', JSON.parse(JSON.stringify(item)));
        },
        // Get Quizzes
        async getReviewQuestions() {
            let reviewQuestionsResult = await this.MIX_redisAggregateSearch('idx:reviewQuestions', this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
            if (reviewQuestionsResult.code === 1) {
                this.itemsTotal = reviewQuestionsResult.data.total;
                this.reviewQuestions = reviewQuestionsResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            }
        },
    },
    // * WATCH
    watch: {
        // Watch Refresh List
        refreshlist() {
            this.refreshList();
        },
        // Watch Computed Search Query
        computedSearchQuery() {
            this.getReviewQuestions();
        },
        // Watch Computed Fields
        computedFields() {
            this.getReviewQuestions();
        },
        // Watch Data Table Options
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getReviewQuestions();
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        this.getReviewQuestions();
    },
};
</script>