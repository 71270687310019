<template>
    <v-card>
        <v-toolbar class="pa-0 ma-0" color="primary" elevation="0">
            <div>
                <v-icon class="white--text text-h4">oomph-help</v-icon>
            </div>
            <div class="text-left white--text text-h5 pl-2 font-weight-light">
                <span v-if="newquestion">Add Question</span>
                <span v-else>Edit Question</span>
            </div>
            <v-spacer />
            <div>
                <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="cancelQuestion" depressed>
                    <v-icon>oomph-multiply</v-icon>
                </v-btn>
            </div>
        </v-toolbar>
        <v-card-text class="grey lighten-3 px-4">
            <v-row class="py-2 pt-4 px-0" dense>
                <v-col cols="12">
                    <!-- newquestion: {{ newquestion }}<br />
                    activequestion: {{ activequestion }}<br /> -->
                    <v-form ref="form" lazy-validation style="width: 100% !important">
                        <v-row dense>
                            <v-col cols="12" xs="12" md="12">
                                <v-autocomplete label="Question Type" v-model="question.questionType" :items="questionTypes" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearQuestion('questionType')"></v-autocomplete>
                                <div class="mt-3"><v-textarea v-if="question.questionType !== 'Guess the Image'" label="Question" rows="5" :rules="rules.questionTitle" v-model="question.questionTitle" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearQuestion('questionTitle')"></v-textarea></div>
                            </v-col>

                            <v-col cols="12" class="d-flex align-center pt-2" v-if="question.questionType !== 'Free Text Answer' && question.questionType !== 'Date' && question.questionType !== 'Duration' && question.questionType !== 'Numeric'">
                                <div class="text-left primary--text text-h6 font-weight-light">Answers</div>
                            </v-col>

                            <v-col v-if="question.questionType !== 'Free Text Answer' && question.questionType !== 'Date' && question.questionType !== 'Duration' && question.questionType !== 'Numeric'">
                                <v-textarea rows="2" class="mt-2" v-if="question.answerAllowNotes === '1'" label="Enter Notes for Answer" v-model="question.answerNotes" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearQuestion('answerNotes')"></v-textarea>
                            </v-col>

                            <v-col cols="12" xs="12" md="12" class="d-flex align-top" v-if="question.questionType !== 'Free Text Answer' && question.questionType !== 'Date' && question.questionType !== 'Duration' && question.questionType !== 'Numeric'">
                                <div v-if="question.questionType === 'Rating'">
                                    <v-btn @click="addRatings" depressed class="primary">Add Ratings</v-btn>
                                </div>
                                <div v-else class="flex-grow-1">
                                    <v-text-field label="Enter Answer" v-model="answer.text" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearAnswer('text')"></v-text-field>
                                </div>
                                <div class="ml-2" v-if="question.questionType !== 'Rating'"><v-btn @click="addAnswer()" depressed class="primary">Add</v-btn></div>
                            </v-col>

                            <v-col cols="12" class="" v-if="question.questionType !== 'Free Text Answer' && question.questionType !== 'Date' && question.questionType !== 'Duration' && question.questionType !== 'Numeric'">
                                <div v-if="minAnswers" class="error--text my-2">A Minimum of two Answers is Required</div>
                                <div v-if="correctAnswers" class="error--text my-2">Only one Correct Answer is Allowed</div>
                                <div v-if="missingCorrectAnswers" class="error--text my-2">Correct Answer is not selected</div>
                                <v-list>
                                    <v-list-item style="background-color: white">
                                        <v-list-item-content>
                                            <div class="d-flex align-center">
                                                <div class="body-2 font-weight-bold primary--text flex-grow-1">Suggested Answers</div>
                                                <v-spacer></v-spacer>
                                                <div v-if="question.questionType !== 'Multiple Choice with no Correct Answers' && question.questionType !== 'Rating'" class="d-flex justify-center body-2 font-weight-bold primary--text" style="width: 150px !important">Correct Answer</div>
                                                <div class="d-flex justify-end body-2 font-weight-bold primary--text" style="width: 80px !important">Actions</div>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <drop-list :items="answers" class="" @insert="onInsert" @reorder="$event.apply(answers)">
                                        <template v-slot:item="{ item, index }">
                                            <drag :data="item" :key="index">
                                                <v-list-item style="background-color: white">
                                                    <!-- <v-list-item-avatar>
                                                        <v-img :src="item.imageId" />
                                                    </v-list-item-avatar> -->
                                                    <v-list-item-content>
                                                        <div class="d-flex align-start">
                                                            <div><v-icon class="mr-2 grey--text">oomph-move-grabber</v-icon></div>
                                                            <div v-if="question.answerAllowABC === '1'" class="text-h6 mr-2 font-weight-bold primary--text">{{ alphabet[index] }}</div>
                                                            <div class="mx-3 mb-2 pa-1 d-flex align-center justify-center" v-if="item.imageId !== '' && !reorder" style="width: 100px; height: 100px; border-radius: 5px !important; border: 1px solid grey !important;">
                                                                <v-img :src="'https://storage.googleapis.com/oomph-portal-public/quizzes/questions/images/' + question.id + '/' + item.imageId + '.jpg'" cover height="80" max-width="80" style="border-radius: 3px !important;"></v-img>                                       
                                                            </div>
                                                            <div class="body-2 flex-grow-1 dnd-no-drag">
                                                                <v-text-field label="Answer" v-model="item.text" dense outlined hide-details="auto"></v-text-field>
                                                            </div>
                                                            <div v-if="question.answerAllowPoints === '1'" class="ml-2">
                                                                <div class="body-2 dnd-no-drag"><v-text-field label="Points" type="number" v-model="item.points" dense outlined hide-details="auto"></v-text-field></div>
                                                            </div>
                                                            <div v-if="question.questionType === 'Rating'" class="ml-2">
                                                                <div class="body-2 dnd-no-drag"><v-text-field label="Rating" type="number" v-model="item.value" dense outlined hide-details="auto"></v-text-field></div>
                                                            </div>
                                                            <div class="ml-2" v-if="question.answerAllowImages === '1'">
                                                                <v-btn v-if="item.imageId !== ''" @click="removeAnswerImage(index)" depressed>Remove Image</v-btn>
                                                                <v-btn v-if="item.imageId === ''" @click="selectedAnswerIndex = index; selectedAnswer = item; answerImageDialog = true" depressed>Add Image</v-btn>
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <div class="d-flex justify-center"  v-if="question.questionType !== 'Multiple Choice with no Correct Answers' && question.questionType !== 'Rating'" style="width: 150px !important"><v-checkbox class="pa-0 ma-0 dnd-no-drag" v-model="item.correct" hide-details="auto"></v-checkbox></div>
                                                            <div class="d-flex align-center justify-end" style="width: 80px !important">
                                                                <v-icon @click="deleteAnswer(index)" class="ml-2">oomph-trash-can</v-icon>
                                                            </div>
                                                        </div>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </drag>
                                        </template>
                                    </drop-list>
                                </v-list>
                            </v-col>

                        </v-row>
                    </v-form>
                </v-col>
                <!-- <v-col>
                    question:
                    <pre>{{ question }}</pre>
                </v-col>

                <v-col>
                    questionparent:
                    <pre>{{ questionparent }}</pre>
                </v-col> -->
            </v-row>
        </v-card-text>
        <v-card-actions class="grey lighten-1 pa-3 pr-4 d-flex justify-right align-center">
            <v-spacer />
            <div>
                <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="cancelQuestion"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
            </div>
            <div class="ml-2">
                <v-btn class="primary white--text font-weight-bold" depressed @click="validateQuestion()"> <v-icon class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
            </div>
        </v-card-actions>
        <v-dialog v-model="answerImageDialog" width="350px" height="250px">
            <v-card width="350px" height="250px" class="pa-5">
                <div class="title mb-2">Upload Image</div>
                <FileUpload accept="image/jpeg, image/jpg" label="Select Image" buttonClass="primary" progressClass="pa-2" folderLocation="quizzes/questions/images" :fileName="selectedAnswer.id" :selectedItem="question" :compressionMaxSizeMB="1" :compressionmaxWidthOrHeight="1920" :private="false" :isProfile="false" @update="saveAnswerImage" :key="answerImageKey" />
                <!-- <pre>{{ question }}</pre> -->
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
import { Drag, DropList } from 'vue-easy-dnd';
import FileUpload from '../../components/fileupload/FileUpload.vue';
import { mapGetters } from 'vuex';

export default {
    // * COMPONENTS
    components: {
        Drag,
        DropList,
        FileUpload,
    },
    // * PROPS
    props: {
        activequestion: {
            Type: Object,
            Default: null,
        },
        newquestion: {
            Type: Boolean,
            Default: false,
        },
        questionparent: {
            Type: Object,
            Default: {},
        },
    },
    // * DATA
    data: () => ({
        schema: {},
        // Answers
        answers: [],
        answer: {
            id: '',
            text: '',
            value: '',
            points: '1',
            imageId: '',
            correct: '',
        },
        selectedAnswer: {},
        selectedAnswerIndex: 0,
        answerImageKey: 0,
        alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        // Dropdowns
        questionTypes: ['Multiple Choice with Single Answer', 'Multiple Choice with Multiple Answers', 'Multiple Choice with no Correct Answers', 'Rating', 'Free Text Answer', 'Date', 'Duration', 'Numeric'], // TODO - Put in List
        // Question
        question: {},
        // Parent
        questionParent: {},
        // Form
        rules: {},
        minAnswers: false, // must have minimum number of answers
        correctAnswers: false, // only 1 answer can be correct
        missingCorrectAnswers: false, // missing correct answer
        questionImageKey: 0,
        questionImagePanel: false,
        answerImageDialog: false,
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        // Check for Multiple Answers
        checkForMultipleAnswers() {
            let answers = this.answers.filter((x) => x.answer === true);
            if (answers.length > 1) {
                return true;
            } else {
                return false;
            }
        },
        // Check for Missing Answers
        checkForMissingAnswers() {
            let answers = this.answers.filter((x) => x.correct === true);
            if (answers.length < 1 ) {
                return true;
            } else {
                return false;
            }
        },
    },
    // * METHODS
    methods: {
        addRatings() {
            let answer = this.answer
            for (let i = 0; i < 5; i++) {
                answer = {
                    id: this.MIX_generateId(),
                    order: (i+1).toString(),
                    text: '',
                    value: (i+1).toString(),
                    points: '1',
                    imageId: '',
                    correct: '',
                };
                this.answers.push({ ...answer });
            }
        },
        // Save Question Image
        async saveQuestionImage(url, file) {
            let t = this;
            // //console.log('url = ' + url);
            // //console.log('file = ' + JSON.stringify(file, null, 2));
            t.question.questionImageId = t.question.id;
            t.MIX_alertBox({ color: 'success', message: 'Image uploaded successfully', timeout: 4000, show: true });
            t.questionImageKey++;
        },
        // Save Answer Image
        async saveAnswerImage(url, file) {
            let t = this;
            // //console.log('url = ' + url);
            // //console.log('file = ' + JSON.stringify(file, null, 2));
            t.answers[t.selectedAnswerIndex].imageId = t.selectedAnswer.id;
            t.MIX_alertBox({ color: 'success', message: 'Image uploaded successfully', timeout: 4000, show: true });
            t.answerImageKey++;
        },

        // Add Answer
        addAnswer() {
            let answer = { ...this.answer };
            answer.id = this.MIX_generateId();
            answer.order = this.answers.length + 1;
            this.answers.push(answer);
            this.answer = {
                id: '',
                order: '',
                text: '',
                value: '',
                points: '1',
                imageId: '',
            };
        },
        removeAnswerImage(index) {
            alert('remove answer image ' + index);
            // TODO - REMOVE ANSWER IMAGE
        },
        // Delete Answer
        deleteAnswer(index) {
            this.answers.splice(index, 1);
        },
        // On Insert Drag and Drop
        onInsert(event) {
            this.answers.splice(event.index, 0, event.data);
        },
        // Initialise Question
        initialiseQuestion() {
            this.questionParent = { ...this.questionparent };
            if (this.newquestion) {
                this.rules = {};
                this.answers = [];
                this.answer = {
                    id: '',
                    text: '',
                    value: '',
                    points: '1',
                    imageId: '',
                    correct: '',
                };
                this.question = { ...this.schema };
                this.question.id = this.MIX_generateId();
                this.question.questionParentId = this.questionparent.id;
                this.question.questionCategory = this.questionparent.key;
                this.question.questionOrder = (Number(this.questionParent.questionTotal) + 1).toString();
            } else {
                //console.log('existing question')
                this.question = { ...this.activequestion };
                this.answers = JSON.parse(this.question.answers);
            }
        },
        // Update Parent
        updateParent() {
            this.questionParent.questionTotal = (Number(this.questionParent.questionTotal) + 1).toString();
            this.$emit('update', this.questionParent);
        },
        // Clear Item Property
        clearQuestion(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // Clear Answer Property
        clearAnswer(property) {
            setTimeout(() => {
                this.answer[property] = '';
            }, 1);
        },
        validateQuestion() {
            let errors = 0;
            this.rules = {
                // List all validation rules for form
                questionTitle: [(v) => !!v || 'Question Title Required'],
            };
            if (this.answers.length < 2 && this.question.questionType !== 'Free Text Answer' && this.question.questionType !== 'Date' && this.question.questionType !== 'Duration' && this.question.questionType !== 'Numeric') {
                this.minAnswers = true;
                errors++;
            } else {
                this.minAnswers = false;
            }
            if (this.question.questionType === 'Multiple Choice with Single Answer') {
                if (this.checkForMultipleAnswers) {
                    this.correctAnswers = true;
                    errors++;
                } else {
                    this.correctAnswers = false;
                }
            } else {
                this.correctAnswers = false;
            }
            if (this.checkForMissingAnswers && this.question.questionType !== 'Multiple Choice with no Correct Answers' && this.question.questionType !== 'Rating' && this.question.questionType !== 'Free Text Answer' && this.question.questionType !== 'Date' && this.question.questionType !== 'Duration' && this.question.questionType !== 'Numeric') {
                this.missingCorrectAnswers = true;
                errors++;
            } else {
                this.missingCorrectAnswers = false;
            }
            //console.log('errors = ' + errors);
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    if (errors === 0) {
                        if (this.newquestion) {
                            //alert('Save Question');
                            this.saveQuestion(); // save quiz
                        } else {
                            this.updateQuestion(); // update quiz
                        }
                    }
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Fill out all the fields correctly in order to proceed.', show: true });
                }
            });
        },
        // Save Question
        async saveQuestion() {
            let result = await this.MIX_createItem(this.question, 'question', true);
            if (result.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
            }
            this.question = { ...this.schema };
            this.$emit('refresh');
            this.$emit('close');
        },
        // Update Question
        async updateQuestion() {
            let result = await this.MIX_createItem(this.question, 'question', false);
            if (result.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Updated', show: true });
            }
            this.question = { ...this.schema };
            this.$emit('refresh');
            this.$emit('close');
        },
        // Cancel Item
        cancelQuestion() {
            this.question = { ...this.schema };
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.question = { ...this.schema };
            this.$emit('close');
        },
    },
    // * WATCH
    watch: {
        // Watch for changes to activequestion
        activequestion: {
            handler: function () {
                this.initialiseQuestion();
            },
            deep: true,
        },
        // Watch for changes to Answers
        answers: {
            handler: function () {
                this.question.answers = JSON.stringify(this.answers);
            },
            deep: true,
        },

    },
    // * CREATED
    async created() {
        this.schema = { ...schema.question };
        this.initialiseQuestion();
    },
};
</script>