<template>
    <div>
        <div class="d-flex py-1 align-center" v-if="this.$vuetify.breakpoint.smAndUp">
            <div><v-img contain width="120" height="20" style="" :src="require('@/assets/vlogo.png')"></v-img></div>       
            <v-spacer></v-spacer>
            <div v-if="!listexpanded" class="caption font-weight-normal" :class="colour + '--text'">© COPYRIGHT 2021 VINDICO</div>
        </div>
        <div class="d-flex py-1 align-center justify-center flex-column" v-if="this.$vuetify.breakpoint.xsOnly">
            <div><v-img contain width="120" height="20" style="" :src="require('@/assets/vlogo.png')"></v-img></div>       
            <div v-if="!listexpanded" class="caption font-weight-normal">© COPYRIGHT 2021 VINDICO</div>
        </div>  
    </div>  
</template>

<script>
	export default {
		// props
		props: {
			colour: {
				type: String,
				default: '',
			},
            listexpanded: {
                type: Boolean,
                default: false,
            },
		}
    }
</script>