<template>
    <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
use([GridComponent, CanvasRenderer, BarChart, TitleComponent, TooltipComponent, LegendComponent]);

export default {
    name: "BarChart",
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "light",
    },
    props: {
        seriesdata: {
            type: Array,
            default: () => [],
        },
        color: {
            type: String,
        },
        seriestext: {
            type: String,
        },
    },
    data: () => ({
        colors: [],
    }),
    computed: {
        option() {
            return {
                grid: {
                    left: 10,
                    right: 20,
                    top: 0,
                    bottom: 30,
                },
                xAxis: {
                    type: "value",
                    axisTick: { show: true },
                },
                yAxis: {
                    type: "category",
                    axisLabel: { show: false },
                    data: this.seriesdata.map((x) => x.name),
                    axisTick: { show: false },
                },
                series: [
                    {
                        label: {
                            show: true,
                            formatter: "{b}",
                            position: ['5', '30%'],
                            color: "#ffffff",
                        },
                        radius: ["100%"],
                        data: this.seriesdata.map((x) => x.value),
                        type: "bar",
                        showBackground: true,
                        color: this.color,
                        backgroundStyle: {
                            color: "#c5c5c5",
                        },
                    },
                ],
            };
        },
    },
    methods: {
        LightenColor(color, percent) {
            var num = parseInt(color, 16),
                amt = Math.round(2.55 * percent),
                R = (num >> 16) + amt,
                B = ((num >> 8) & 0x00ff) + amt,
                G = (num & 0x0000ff) + amt;
            return (0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255)).toString(16).slice(1);
        },
    },
    created() {
        this.colors = [];
        if (!this.color) return
        let color = this.color.slice(1, 10);
        let seriesLength = this.seriesdata.length;
        for (let i = 0; i < seriesLength; i++) {
            let newColour = this.LightenColor(color, i * 10);
            this.colors.push("#" + newColour);
        }
    },
};
</script>
<style scoped>
.chart {
    width: 100% !important;
    height: 400px !important;
}
</style>
