<template>
    <v-container id="overlay" class="primary pa-0 ma-0" fluid>
        <v-row class="d-flex appPrimary py-2" no-gutters>
            <v-col cols="12">
                <v-btn v-if="$route.params.id" @click="$router.push(`/home`)" class="ml-4 white--text" text depressed small><v-icon>oomph-back</v-icon>Back</v-btn>
                <v-btn
                    v-else-if="readingMode === true"
                    @click="
                        readingMode = false;
                        instructionPage = true;
                    "
                    class="ml-4 white--text"
                    text
                    depressed
                    small
                    ><v-icon>oomph-back</v-icon>Back</v-btn
                >
                <v-btn v-else @click="$emit('close')" class="ml-4 white--text" text depressed small><v-icon>oomph-back</v-icon>Back</v-btn>
            </v-col>
        </v-row>
        <!-- Intro page -->
        <v-col v-if="introPage" class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <div class="circle">
                <div class="oomph-font-body-1 white--text pa-2">{{ contentItem.contentDurationMins }} <br />mins</div>
            </div>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0 px-6" width="100%" height="100vh">
                    <v-card-title class="oomph-font-h4">
                        <v-row>
                            <v-col cols="12" class="oomph-font-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="oomph-font-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="oomph-font-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="oomph-font-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingMode = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text class="pt-0 px-6 mt-5">
                        <div class="oomph-font-body-1">{{ contentItem.contentSubtitle }}</div>
                    </v-card-text>
                    <v-card-text class="pt-0">
                        <v-row no-gutters>
                            <v-col cols="8" class="py-4">
                                <div class="oomph-font-h5 primary--text pb-2">Description</div>
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end py-4">
                                <v-btn v-if="!addedToMyList" class="primary white--text pa-4" @click="addToMyList()" depressed small
                                    ><v-icon class="mr-2">oomph-add-properies</v-icon>Add to My List</v-btn
                                >
                                <v-btn v-else class="error white--text pa-4" @click="removeFromMyList()" depressed small><v-icon class="mr-2">oomph-delete-document</v-icon>Remove from My List</v-btn>
                            </v-col>
                        </v-row>
                        <div class="oomph-font-body-1">{{ contentItem.contentDescription }}</div>
                    </v-card-text>
                    <v-card-text class="pt-0">
                        <div class="oomph-font-h6 primary--text my-2">Suitable for:</div>
                        <v-row class="d-flex align-center ml-2 pt-4">
                            <v-col v-if="contentItem.contentTags.includes('dementia friendly')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-learning</v-icon>
                                <div class="oomph-font-body-2 ml-2">Dementia Friendly</div>
                            </v-col>
                            <v-col v-if="contentItem.contentTags.includes('visual impairment')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-eye</v-icon>
                                <div class="oomph-font-body-2 ml-2">Visual Impairment</div>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center ml-2 pt-4">
                            <v-col v-if="contentItem.contentTags.includes('group') || contentItem.contentTags.includes('small group')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-user-groups</v-icon>
                                <div v-if="contentItem.contentTags.includes('group')" class="oomph-font-body-2 ml-2">Group</div>
                                <div v-if="contentItem.contentTags.includes('small group')" class="oomph-font-body-2 ml-2">Small Group</div>
                            </v-col>
                            <v-col v-if="contentItem.contentTags.includes('hearing impairment')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-listen</v-icon>
                                <div class="oomph-font-body-2 ml-2">Hearing Impairment</div>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center ml-2 pt-4">
                            <v-col v-if="contentItem.contentTags.includes('independent')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-account</v-icon>
                                <div class="oomph-font-body-2 ml-2">Independent</div>
                            </v-col>
                            <v-col v-if="contentItem.contentTags.includes('one to one')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-group</v-icon>
                                <div class="oomph-font-body-2 ml-2">One to One</div>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center ml-2 pt-4">
                            <v-col v-if="contentItem.contentTags.includes('reduced mobility')" cols="6" class="d-flex align-center pa-0">
                                <v-icon size="25" color="primary">oomph-wheelchair</v-icon>
                                <div class="oomph-font-body-2 ml-2">Reduced Mobility</div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <div class="oomph-font-h6 primary--text mt-3 mr-2">Tags:</div>
                        <v-chip :to="'/search/' + item" class="secondary black--text mr-1 mt-4" v-for="item in tags" :key="item" label>{{ item.charAt(0).toUpperCase() + item.slice(1) }}</v-chip>
                    </v-card-text>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            v-if="adaption"
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = false;
                                adaptionPage = true;
                            "
                            text
                            >Adaptions<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                        <v-btn
                            v-else-if="contentType !== 'Recipe' && contentType !== 'Recipe with PDF' && contentType !== 'Recipe with Media' && contentType !== 'Recipe with Video' && contentType !== 'Simple'"
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = false;
                                instructionPage = true;
                            "
                            text
                            >Instructions<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                        <v-btn
                            v-else-if="contentType === 'Simple'"
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = false;
                                textPage = true;
                            "
                            text
                            >View<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                        <v-btn
                            v-else
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                ingredientPage = true;
                                adaptionPage = false;
                            "
                            text
                            >Ingredients<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <!-- Adaption page -->
        <v-col v-if="adaptionPage" class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <div class="circle">
                <div class="oomph-font-body-1 white--text pa-2">{{ contentItem.contentDurationMins }} <br />mins</div>
            </div>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0 px-6" width="100%" height="100vh">
                    <v-card-title class="oomph-font-h4">
                        <v-row>
                            <v-col cols="12" class="oomph-font-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="oomph-font-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="oomph-font-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="oomph-font-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingMode = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card class="elevation-0 mt-2" height="70vh" style="overflow-y: scroll">
                        <v-card-text v-if="adaption !== ''" class="py-0 px-6">
                            <div class="oomph-font-h5 primary--text pt-8 pb-2">Adaptions</div>
                            <div class="oomph-font-body-1" v-html="adaption"></div>
                        </v-card-text>
                    </v-card>
                    <div v-if="adaption.length > 2150" class="d-flex align-center justify-end">Scroll for more<v-icon color="primary" class="ml-2">oomph-down-button</v-icon></div>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = true;
                                adaptionPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Intro</v-btn
                        >
                        <v-btn
                            v-show="resources"
                            v-if="contentType === 'Craft' || contentType === 'Craft with PDF' || contentType === 'Craft with Media' || contentType === 'Craft with Video'"
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                resourcesPage = true;
                                adaptionPage = false;
                            "
                            text
                            >Resources<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                        <v-btn
                            v-show="!resources"
                            v-if="contentType === 'Video' || contentType === 'PDF' || contentType === 'Craft' || contentType === 'Craft with PDF' || contentType === 'Craft with Video' || contentType === 'Craft with Media' || contentType === 'Exercise with Video'|| contentType === 'Exercise with PDF' || contentType === 'Exercise with Media'"
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                instructionPage = true;
                                adaptionPage = false;
                            "
                            text
                            >Instructions<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                        <v-btn
                            v-if="contentType === 'Recipe' || contentType === 'Recipe with PDF' || contentType === 'Recipe with Media' || contentType === 'Recipe with Video'"
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                ingredientPage = true;
                                adaptionPage = false;
                            "
                            text
                            >Ingredients<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <!-- Text page -->
        <v-col v-if="textPage" class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0" width="100%">
                    <v-card-title class="oomph-font-h4">
                        <v-row>
                            <v-col cols="8" class="oomph-font-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end pr-8">
                                <v-btn class="primary white--text pa-4 ml-2" @click="showReviewOverlay = true" depressed medium><v-icon class="mr-2">oomph-star-2</v-icon>Add Review</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="oomph-font-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="oomph-font-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="oomph-font-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingMode = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text class="pt-0">
                        <div class="oomph-font-h5 primary--text pt-6 pb-2">Information</div>
                        <v-card class="elevation-0" height="62vh" style="overflow: scroll">
                            <v-card-text :style="contentbodytext" class="pb-2" v-html="simpleText"></v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = true;
                                textPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Intro</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <!-- Resources page -->
        <v-col v-if="resourcesPage" class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0" width="100%">
                    <v-card-title class="oomph-font-h4">
                        <v-row>
                            <v-col cols="8" class="oomph-font-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end pr-8">
                                <v-btn class="primary white--text pa-2 ml-2" @click="showReviewOverlay = true" depressed medium><v-icon class="mr-2">oomph-star-2</v-icon>Add Review</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="oomph-font-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="oomph-font-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="oomph-font-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingPage = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="resources" class="pt-0">
                        <div class="oomph-font-h5 primary--text pt-6 pb-2">Resources</div>
                        <v-card class="elevation-0" height="62vh" style="overflow: scroll">
                            <v-card-text :style="contentbodytext" class="pb-2" v-html="resources"></v-card-text>
                        </v-card>
                        <div v-if="resources.length > 2150" class="d-flex align-center justify-end">Scroll for more<v-icon color="primary" class="ml-2">oomph-down-button</v-icon></div>
                    </v-card-text>
                    <v-card-text v-else class="d-flex align-center justify-center oomph-font-h4 font-weight-bold error--text text--lighten-2 mt-10">No resources(s) have been uploaded</v-card-text>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            v-if="adaption"
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                adaptionPage = true;
                                resourcesPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Adaptions</v-btn
                        >
                        <v-btn
                            v-else
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = true;
                                resourcesPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Intro</v-btn
                        >
                        <v-btn
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                resourcesPage = false;
                                instructionPage = true;
                            "
                            text
                            >Instructions<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <!-- Instruction page -->
        <v-col v-if="instructionPage" class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0" width="100%">
                    <v-card-title class="oomph-font-h4">
                        <v-row>
                            <v-col cols="8" class="oomph-font-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end pr-8">
                                <v-btn class="primary white--text pa-2 ml-2" @click="showReviewOverlay = true" depressed medium><v-icon class="mr-2">oomph-star-2</v-icon>Add Review</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="oomph-font-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="oomph-font-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="oomph-font-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingMode = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="instructions" class="pt-0">
                        <div class="oomph-font-h5 primary--text pt-6 pb-2">Instructions</div>
                        <v-card class="elevation-0" height="62vh" style="overflow: scroll">
                            <v-card-text :style="contentbodytext" class="pb-2" v-html="instructions"></v-card-text>
                        </v-card>
                        <div v-if="instructions.length > 2150" class="d-flex align-center justify-end pr-8">Scroll for more<v-icon color="primary" class="ml-2">oomph-down-button</v-icon></div>
                    </v-card-text>
                    <v-card-text v-else class="d-flex align-center justify-center oomph-font-h4 font-weight-bold error--text text--lighten-2 mt-10">No instructions(s) have been uploaded</v-card-text>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            v-if="adaption"
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                adaptionPage = true;
                                instructionPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Adaptions</v-btn
                        >
                        <v-btn
                            v-else
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = true;
                                instructionPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Intro</v-btn
                        >
                        <v-btn
                            v-if="contentType === 'Video' || contentType === 'Craft with Video' || contentType === 'Craft with Media' || contentType === 'Recipe with Video' || contentType === 'Recipe with Media' || contentType === 'Exercise with Video' || contentType === 'Exercise with Media'"
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                instructionPage = false;
                                videoPage = true;
                            "
                            text
                            >Video<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                        <v-btn
                            v-if="
                                contentType === 'Craft with Media' ||
                                contentType === 'Craft with PDF' ||
                                contentType === 'Exercise with Media' ||
                                contentType === 'Exercise with PDF' ||
                                contentType === 'PDF' ||
                                contentType === 'Recipe with Media' ||
                                contentType === 'Recipe with PDF'
                            "
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                instructionPage = false;
                                pdfPage = true;
                            "
                            text
                            >PDF<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <!-- Pdf page -->
        <v-col v-if="pdfPage" class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0" width="100%">
                    <v-card-title class="oomph-font-h4">
                        <v-row>
                            <v-col cols="8" class="oomph-font-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end pr-8">
                                <v-btn class="primary white--text pa-2 ml-2" @click="showReviewOverlay = true" depressed medium><v-icon class="mr-2">oomph-star-2</v-icon>Add Review</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="oomph-font-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="oomph-font-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="oomph-font-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingMode = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="pdfURL" class="d-flex justify-center grey lighten-3">
                        <FlipBook :pdfurl="pdfURL" :key="flipbookkey" />
                    </v-card-text>
                    <v-card-text v-else class="d-flex align-center justify-center oomph-font-h4 font-weight-bold error--text text--lighten-2 mt-10">No pdf(s) have been uploaded</v-card-text>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                instructionPage = true;
                                pdfPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Instructions</v-btn
                        >
                        <v-btn
                            v-if="contentType === 'Craft with Media' && contentType === 'Craft with Video' && contentType === 'Exercise with Media' && contentType === 'Exercise with Video'"
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                pdfPage = false;
                                videoPage = true;
                            "
                            text
                            >Video<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <!-- Ingredient page -->
        <v-col v-if="ingredientPage" class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0" width="100%">
                    <v-card-title class="oomph-font-h4">
                        <v-row>
                            <v-col cols="8" class="oomph-font-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end pr-8">
                                <v-btn class="primary white--text ml-2" @click="showReviewOverlay = true" depressed medium><v-icon class="mr-2">oomph-star-2</v-icon>Add Review</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="oomph-font-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="oomph-font-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="oomph-font-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingMode = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text class="pt-0">
                        <div class="oomph-font-h5 primary--text pt-6 pb-2">Ingredients</div>
                        <v-card class="elevation-0" height="62vh" style="overflow: scroll">
                            <v-card-text :style="contentbodytext" class="pb-2" v-html="ingredients"></v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            v-if="adaption"
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                adaptionPage = true;
                                ingredientPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Adaptions</v-btn
                        >
                        <v-btn
                            v-else
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = true;
                                ingredientPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Intro</v-btn
                        >
                        <v-btn
                            style="position: fixed !important; bottom: 10px !important; right: 0 !important"
                            color="primary"
                            class="white--text"
                            @click="
                                ingredientPage = false;
                                methodPage = true;
                            "
                            text
                            >Method<v-icon size="40">oomph-next-page</v-icon></v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <!-- Method page -->
        <v-col v-if="methodPage" class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0" width="100%">
                    <v-card-title class="oomph-font-h4">
                        <v-row>
                            <v-col cols="8" class="oomph-font-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end pr-8">
                                <v-btn class="primary white--text pa-4 ml-2" @click="showReviewOverlay = true" depressed><v-icon class="mr-2">oomph-star-2</v-icon>Add Review</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="oomph-font-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="oomph-font-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="oomph-font-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingMode = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text class="pt-0">
                        <div class="oomph-font-h5 primary--text pt-6 pb-2">Method</div>
                        <v-card class="elevation-0" height="62vh" style="overflow: scroll">
                            <v-card-text :style="contentbodytext" class="pb-2" v-html="method"></v-card-text>
                        </v-card>
                        <div v-if="method.length > 2150" class="d-flex align-center justify-end">Scroll for more<v-icon color="primary" class="ml-2">oomph-down-button</v-icon></div>
                    </v-card-text>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                ingredientPage = true;
                                methodPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Ingredients</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <!-- Video page -->
        <v-col v-if="videoPage" class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0" width="100%">
                    <v-card-title class="oomph-font-h4">
                        <v-row>
                            <v-col cols="8" class="oomph-font-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end pr-8">
                                <v-btn class="primary white--text pa-2 ml-2" @click="showReviewOverlay = true" depressed medium><v-icon class="mr-2">oomph-star-2</v-icon>Add Review</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="oomph-font-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="oomph-font-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="oomph-font-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingMode = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="video.playbackId" class="d-flex flex-column justify-center pt-0">
                        <div class="oomph-font-h5 primary--text pt-6 pb-2">Instruction Video</div>
                        <mux-video
                            :playback-id="video.playbackId"
                            :metadata-video-title="video.title"
                            :env-key="video.envKey"
                            :poster="video.poster"
                            stream-type="on-demand"
                            height="700"
                            preload
                            prefer-mse
                            controls
                        ></mux-video>
                    </v-card-text>
                    <v-card-text v-else class="d-flex align-center justify-center oomph-font-h4 font-weight-bold error--text text--lighten-2 mt-10">No video(s) have been uploaded</v-card-text>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            v-if="contentType === 'Video' || contentType === 'Craft with Video' || contentType === 'Craft with Media' || contentType === 'Exercise with Video' || contentType === 'Exercise with Media'"
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                instructionPage = true;
                                videoPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Instructions</v-btn
                        >
                        <v-btn
                            v-if="contentType === 'Recipe with Video' || contentType === 'Recipe with Media'"
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                methodPage = true;
                                videoPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Method</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <!-- Podcast page -->
        <v-col
            v-if="podcastPage"
            class="d-flex pa-0 ma-0"
            :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'"
        >
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '100vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" src="require('@/assets/logo.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0" width="100%">
                    <v-card-title class="oomph-font-h4">
                        <v-row>
                            <v-col cols="8" class="oomph-font-h5 d-flex justify-start pb-0" style="word-break: break-word !important">
                                {{ contentItem.contentTitle }}
                            </v-col>
                            <v-col cols="4" class="d-flex justify-end pr-8">
                                <v-btn class="primary white--text pa-2 ml-2" @click="showReviewOverlay = true" depressed medium><v-icon class="mr-2">oomph-star-2</v-icon>Add Review</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="mb-2">
                        <v-row class="d-flex align-center justify-start">
                            <v-col cols="12" class="d-flex justify-start mt-2">
                                <div class="d-flex align-center justify-start">
                                    <v-rating
                                        empty-icon="oomph-star"
                                        full-icon="oomph-star-2"
                                        length="5"
                                        size="20"
                                        background-color="grey"
                                        color="primary"
                                        class="mr-6"
                                        :value="parseInt(contentItem.contentRating)"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                                <div class="d-flex align-center justify-center">
                                    <v-icon v-if="contentItem.contentDifficulty === 'easy'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'medium'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficulty === 'difficult'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficulty === 'easy'" class="oomph-font-body-1 px-2 mr-6 success--text">Easy</div>
                                    <div v-if="contentItem.contentDifficulty === 'medium'" class="oomph-font-body-1 px-2 mr-6 warning--text">Medium</div>
                                    <div v-if="contentItem.contentDifficulty === 'difficult'" class="oomph-font-body-1 px-2 mr-6 error--text">Difficult</div>
                                </div>
                                <div class="d-flex align-center justify-end">
                                    <v-icon size="28" color="success" @click="addLike()">oomph-good-quality</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentLikes }}</div>
                                    <v-icon size="28" color="error" @click="addDislike()">oomph-unlike</v-icon>
                                    <div class="oomph-font-h6 black--text px-2">{{ contentItem.contentDislikes }}</div>
                                    <v-btn
                                        v-if="contentItem.contentAllowPrint === '1'"
                                        class="primary white--text pa-0"
                                        @click="
                                            readingMode = true;
                                            introPage = false;
                                            adaptionPage = false;
                                            instructionPage = false;
                                            ingredientPage = false;
                                            methodPage = false;
                                            pdfPage = false;
                                            resourcePage = false;
                                            videoPage = false;
                                        "
                                        depressed
                                        icon
                                        ><v-icon size="28">oomph-reading</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="podcast.playbackId" class="d-flex flex-column justify-center pt-0">
                        <div class="oomph-font-h5 primary--text pt-6 pb-2">Podcast</div>
                        <v-col cols="12" class="d-flex justify-center">
                            <mux-video :playback-id="podcast.playbackId" :env-key="podcast.envKey" :metadata-video-title="podcast.title" stream-type="on-demand" controls></mux-video>
                        </v-col>
                    </v-card-text>
                    <v-card-text v-else class="d-flex align-center justify-center oomph-font-h4 font-weight-bold error--text text--lighten-2 mt-10">No podcast(s) have been uploaded</v-card-text>
                    <v-card-actions style="position: relative !important">
                        <v-btn
                            v-if="adaption"
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                adaptionPage = true;
                                podcastPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Adaptions</v-btn
                        >
                        <v-btn
                            v-else
                            style="position: fixed !important; bottom: 10px !important"
                            color="primary"
                            class="white--text"
                            @click="
                                introPage = true;
                                podcastPage = false;
                            "
                            text
                            ><v-icon size="40">oomph-back-to</v-icon>Intro</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-col>
        <!-- Reading view -->
        <v-col v-if="readingMode" style="z-index: 999999999 !important" class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <v-col cols="12" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0 px-12 grey lighten-4" width="100%" height="100vh" style="overflow: scroll">
                    <div
                        v-if="maskOverlay"
                        class="d-flex align-center"
                        v-drag="{ axis: 'y', handle: '#someElement' }"
                        style="z-index: 9999999999; width: calc(100vw - 310px) !important; position: absolute; margin-top: 475px !important"
                    >
                        <div
                            v-if="maskOverlay"
                            class="flex-grow-1"
                            style="
                                margin-left: 50px;
                                width: 100% !important;
                                height: 200px !important;
                                position: absolute;
                                margin-top: 10px !important;
                                border: 0px black solid;
                                box-shadow: 0px 0px 0px 1000px rgba(100, 79, 16, 0.3);
                            "
                        ></div>
                    </div>
                    <v-col cols="12" class="grey lighten-4 px-12 py-0">
                        <v-img :style="greyscale ? 'filter: grayscale(100%) !important;' : ''" max-height="30vh" :src="coverImage"></v-img>
                        <v-card-title class="oomph-font-h4" :class="invert ? 'grey darken-4 white--text' : 'white'">
                            <v-row>
                                <v-col cols="12" class="d-flex justify-start">
                                    <v-btn
                                        icon
                                        :style="greyscale ? 'filter: grayscale(100%) !important;' : ''"
                                        class="primary white--text pa-5 mr-2"
                                        @click="
                                            readingMode = false;
                                            introPage = true;
                                        "
                                        depressed
                                        medium
                                        ><v-icon size="25">oomph-back-arrow</v-icon></v-btn
                                    >
                                    <v-spacer />
                                    <v-btn
                                        :style="greyscale ? 'filter: grayscale(100%) !important;' : ''"
                                        class="primary white--text pa-5 mr-2"
                                        depressed
                                        icon
                                        @click="accessibilityRuler = !accessibilityRuler"
                                        ><v-icon size="25">oomph-drag-reorder</v-icon></v-btn
                                    >
                                    <v-btn :style="greyscale ? 'filter: grayscale(100%) !important;' : ''" class="primary white--text pa-5 mr-2" depressed icon @click="maskOverlay = !maskOverlay"
                                        ><v-icon size="25">oomph-grip-lines</v-icon></v-btn
                                    >
                                    <v-btn :style="greyscale ? 'filter: grayscale(100%) !important;' : ''" class="primary white--text pa-5 mr-2" depressed icon @click="invert = !invert"
                                        ><v-icon size="25">oomph-invert-colors</v-icon></v-btn
                                    >
                                    <v-btn :style="greyscale ? 'filter: grayscale(100%) !important;' : ''" class="primary white--text pa-5 mr-2" depressed icon @click="greyscale = !greyscale"
                                        ><v-icon size="25">oomph-grayscale</v-icon></v-btn
                                    >
                                    <v-btn :style="greyscale ? 'filter: grayscale(100%) !important;' : ''" class="primary white--text pa-5 mr-2" depressed icon @click="increaseFont()"
                                        ><v-icon size="25">oomph-increase-font</v-icon></v-btn
                                    >
                                    <v-btn :style="greyscale ? 'filter: grayscale(100%) !important;' : ''" class="primary white--text pa-5 mr-2" depressed icon @click="decreaseFont()"
                                        ><v-icon size="25">oomph-decrease-font</v-icon></v-btn
                                    >
                                    <v-btn
                                        :style="greyscale ? 'filter: grayscale(100%) !important;' : ''"
                                        v-if="contentItem.contentAllowPDFConvert === '1'"
                                        class="primary white--text pa-5 mr-2"
                                        icon
                                        @click="createPdf(`${appUrl}/print/${contentItem.id}`)"
                                        depressed
                                        medium
                                        ><v-icon size="25">oomph-export-pdf-2</v-icon></v-btn
                                    >
                                </v-col>
                                <v-col cols="10" :style="contenttitle" class="d-flex justify-start" style="word-break: break-word !important">
                                    {{ contentItem.contentTitle }}
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-subtitle :class="invert ? 'grey darken-4 white--text' : 'white'" :style="contentbodytext">{{ contentItem.contentSubtitle }}</v-card-subtitle>
                        <v-row class="d-flex pt-3">
                            <v-col cols="12" class="py-0">
                                <v-card height="100%" elevation="0" style="overflow: scroll" :dark="invert">
                                    <div v-if="accessibilityRuler" class="d-flex align-center" v-drag="{ axis: 'y', handle: '#someElement' }">
                                        <div class="primary flex-grow-1" style="width: 100% !important; height: 6px !important"></div>
                                        <div class="primary rounded-lg"><v-icon large class="pa-2 white--text">oomph-drag-reorder</v-icon></div>
                                    </div>
                                    <div v-if="adaption !== ''">
                                        <div :style="contentsubtitle" class="pa-4" :class="greyscale ? 'grey--text text--darken-2' : 'primary--text'">
                                            Adaptions
                                            <v-btn icon class="white--text pa-0 mr-2" :class="greyscale ? 'grey' : 'primary--text'" depressed medium @click="startStopTTS(adaption)"
                                                ><v-icon size="30">oomph-listen</v-icon></v-btn
                                            >
                                        </div>
                                        <div :style="contentbodytext" class="pb-2 pl-4" v-html="adaption"></div>
                                    </div>
                                    <div :style="contentsubtitle" class="pa-4" :class="greyscale ? 'grey--text text--darken-2' : 'primary--text'">
                                        Instructions
                                        <v-btn icon class="white--text pa-0 mr-2" :class="greyscale ? 'grey' : 'primary--text'" depressed medium @click="startStopTTS(instructions)"
                                            ><v-icon size="30">oomph-listen</v-icon></v-btn
                                        >
                                    </div>
                                    <v-card-text :style="contentbodytext" class="pb-2" v-html="instructions"></v-card-text>
                                    <v-card-text class="d-flex justify-center">
                                        <mux-video
                                            :playback-id="video.playbackId"
                                            :metadata-video-title="video.title"
                                            :poster="video.poster"
                                            :env-key="video.envKey"
                                            stream-type="on-demand"
                                            height="700"
                                            preload
                                            prefer-mse
                                            controls
                                        ></mux-video>
                                    </v-card-text>
                                    <div :style="contentsubtitle" class="pa-4" :class="greyscale ? 'grey--text text--darken-2' : 'primary--text'">
                                        Resources
                                        <v-btn icon class="white--text pa-0 mr-2" :class="greyscale ? 'grey' : 'primary--text'" depressed medium @click="startStopTTS(resources)"
                                            ><v-icon size="30">oomph-listen</v-icon></v-btn
                                        >
                                    </div>
                                    <v-card-text :style="contentbodytext" class="pb-2" v-html="resources"></v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-card-text :class="invert ? 'grey darken-4 white--text' : 'white'">
                            <span class="primary--text mr-2 pt-4">Tags:</span>
                            <v-chip label :to="'/search/' + item" class="secondary white--text mr-1 mt-4" v-for="item in tags" :key="item">{{ item }}</v-chip>
                        </v-card-text>
                    </v-col>
                </v-card>
            </v-col>
        </v-col>
        <v-overlay v-model="loading" style="z-index: 9999999999 !important">
            <div class="d-flex flex-column align-center">
                <v-progress-circular :size="100" color="primary" class="mb-4" indeterminate></v-progress-circular>
                <div class="oomph-font-h4">Generating PDF...</div>
            </div>
        </v-overlay>
    </v-container>
</template>

<script src="/js/jquery.min.js"></script>
<script src="/js/html2canvas.min.js"></script>
<script src="/js/three.min.js"></script>
<script src="/js/pdf.min.js"></script>
<script src="/js/3dflipbook.min.js"></script>

<script>
import FlipBook from '@/views/FlipBook.vue';
import { mapGetters } from 'vuex';
import schema from '@/schema.js';

export default {
    name: 'ContentTemplate',
    props: {
        showContentItem: {
            type: Boolean,
            default: false,
        },
        contentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        contentType: {
            type: String,
            default: '',
        },
    },
    components: {
        // Review,
        FlipBook,
    },
    data: () => ({
        // Accessibility (DATA) - Start
        size: 1,
        letterSpacing: 0.2,
        accessibilityRuler: false,
        ttsSpeaking: false,
        tts: null,
        greyscale: false,
        invert: false,
        maskOverlay: false,
        // Accessibility (DATA) - End
        adaption: '',
        adaptionPage: false,
        addedToMyList: false,
        appUrl: process.env.VUE_APP_URL,
        coverImage: '',
        flipbookkey: 0,
        introPage: true,
        ingredientPage: false,
        instructionPage: false,
        like: {},
        loading: false,
        normalMode: true,
        methodPage: false,
        myList: [],
        myLists: [],
        page: 1,
        pageCount: 1,
        pdfPage: false,
        pdfURL: '',
        podcastPage: false,
        podcast: {
            title: '',
            playbackId: '',
            envKey: process.env.VUE_APP_MUX,
        },
        readingMode: false,
        resources: '',
        resourcesPage: false,
        showReviewOverlay: false,
        tags: [],
        textPage: false,
        videoPage: false,
        video: {
            title: '',
            poster: '',
            playbackId: '',
            envKey: process.env.VUE_APP_MUX,
        },

        contentItems: {}
    }),
    watch: {
        introPage() {
            let t = this;
            if (t.introPage === true) {
                t.pdfPage = false;
            }
        },


    },
    computed: {
        ...mapGetters({
            GET_firebase_userAuth: 'GET_firebase_userAuth',
            GET_firebase_user: 'GET_firebase_user',
        }),
        contenttitle: {
            get: function () {
                let size = this.size * 2.125; // vuetify heading 4
                let letterSpacing = this.letterSpacing * 0.0073529412; // vuetify heading 4
                return 'font-size: ' + size + 'rem !important; letter-spacing: ' + letterSpacing + 'em !important; font-weigtht: 400 !important; line-height: 1.5 !important;';
            },
        },
        contentdescription: {
            get: function () {
                let size = this.size * 0.75;
                let letterSpacing = this.letterSpacing * 0.1;
                return 'font-size: ' + size + 'rem !important; letter-spacing: ' + letterSpacing + 'em !important; line-height: 1.5 !important;';
            },
        },
        contentsubtitle: {
            get: function () {
                let size = this.size * 1.5;
                let letterSpacing = this.letterSpacing * 0;
                return 'font-size: ' + size + 'rem !important; letter-spacing: ' + letterSpacing + 'rem !important; line-height: 1.5 !important;';
            },
        },
        contentbodytext: {
            get: function () {
                let size = this.size * 1;
                let letterSpacing = this.letterSpacing * 0.03125;
                return 'font-size: ' + size + 'rem !important; letter-spacing: ' + letterSpacing + 'em important; font-weigtht: 400 !important; line-height: 1.5 !important;';
            },
        },
    },
    methods: {
        increaseFont() {
            let t = this;
            if (t.size >= 1 && t.size <= 1.75) {
                t.size = t.size + 0.25;
            }
        },
        decreaseFont() {
            let t = this;
            if (t.size === 1) {
                t.size = 1;
            } else {
                t.size = t.size - 0.25;
            }
        },
        increaseLetterSpacing() {
            let t = this;
            if (t.letterSpacing !== 1) {
                t.letterSpacing = t.letterSpacing + 0.25;
            } else {
                t.letterSpacing = t.letterSpacing + 0.25;
            }
        },
        decreaseLetterSpacing() {
            let t = this;
            if (t.letterSpacing === 1) {
                t.letterSpacing = 1;
            } else {
                t.letterSpacing = t.letterSpacing - 0.25;
            }
        },
        startStopTTS(text) {
            let t = this;
            if (t.ttsSpeaking === false) {
                t.ttsSpeaking = true;
                t.startTTS(text);
            } else {
                t.ttsSpeaking = false;
                window.speechSynthesis.cancel();
            }
        },
        startTTS(text) {
            let utter = new SpeechSynthesisUtterance();
            utter.voice = this.voice;
            utter.lang = 'en-GB';
            utter.volume = 1;
            utter.rate = 0.7;
            window.speechSynthesis.cancel();
            utter.text = this.MIX_convertTTS(text);
            window.speechSynthesis.speak(utter);
            utter.onstart = function () {
                console.log('started');
            };
            // Event after text has been spoken
            utter.onend = function () {
                console.log('ended');
                this.speaking = false;
            };
        },
        handleDocumentRender() {
            let t = this;
            t.isLoading = false;
            t.pageCount = this.$refs.pdfRef.pageCount;
        },
        filterContentItems() {
            let t = this;
            //* Filter all content items
            t.contentItems.filter((item) => {
                //* Get cover image
                if (item.value.itemMarker === 'Cover Image') {
                    t.coverImage = item.value.itemURL;
                }
                //* Get text items
                if (item.value.itemMarker === 'Adaption Text') {
                    t.adaption = item.value.itemTextHTML;
                }
                if (item.value.itemMarker === 'Resources Text') {
                    t.resources = item.value.itemTextHTML;
                }
                if (item.value.itemMarker === 'Instruction Text') {
                    t.instructions = item.value.itemTextHTML;
                }
                if (item.value.itemMarker === 'Ingredient Text') {
                    t.ingredients = item.value.itemTextHTML;
                }
                if (item.value.itemMarker === 'Method Text') {
                    t.method = item.value.itemTextHTML;
                }
                if (item.value.itemMarker === 'Simple Text') {
                    t.simpleText = item.value.itemTextHTML;
                }
                //* Get PDF items
                if (item.value.itemType === 'PDF') {
                    t.pdfURL = item.value.itemURL;
                }
                //* Get video
                if (item.value.itemType === 'Video') {
                    t.video.playbackId = item.value.itemPlaybackId;
                    t.video.title = item.value.itemName;
                    t.video.poster = `https://image.mux.com/${item.value.itemPlaybackId}/thumbnail.jpg`;
                }
                //* Get audio only item (podcast)
                if (item.value.itemMarker === 'Audio') {
                    t.podcast.playbackId = item.value.itemPlaybackId;
                    t.podcast.title = item.value.itemName;
                }
            });
        },
        async getTags() {
            let t = this;
            let excludedTags = await t.MIX_redisReadJSON('settings:excludedTags', '.');
            const tagsArray = t.contentItem.contentTags.split(',');
            const exlusions = tagsArray.filter((tag) => {
                return excludedTags.data.indexOf(tag) === -1;
            });
            return (t.tags = exlusions);
        },
        async addToMyList() {
            let t = this;
            t.myList.id = await t.MIX_generateId();
            t.myList.status = '1';
            t.myList.contentId = t.contentItem.id;
            t.myList.orgId = t.GET_firebase_user.orgId;
            t.myList.orgName = t.GET_firebase_user.orgName;
            t.myList.createdDateTime = t.$moment().format('x');
            t.myList.createdUserId = t.GET_firebase_user.id;
            t.myList.createdUserName = t.GET_firebase_user.userName;
            t.myList.createdUserEmail = t.GET_firebase_user.userEmail;
            let addToMyList = await t.MIX_redisCreateHash(`myList:${t.myList.id}`, t.myList);
            if (addToMyList.code === 1) {
                t.addedToMyList = true;
                t.getMyList();
                t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Added item to your list', show: true });
            } else {
                t.addedToMyList = false;
                t.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Error saving to your list, please try again.', show: true });
            }
        },
        async removeFromMyList() {
            let t = this;
            let result = await this.MIX_destroyItem(t.myList.key, t.myLists[0].value.id);
            if (result.code === 1) {
                t.addedToMyList = false;
                t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Removed item from your list', show: true });
            } else {
                t.addedToMyList = true;
                t.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Error removing item from your list, please try again.', show: true });
            }
        },
        async getRating() {
            let t = this;
            let result = await t.MIX_reviewCount({ searchQuery: `@contentId:{${t.contentItem.id}}` });
            if (result.code === 1) {
                let total = [];
                result.data.forEach(review => {
                    total.push(parseInt(review.reviewRating))
                })
                const average = total.length > 0 ? total.reduce((a, b) => a + b) / total.length : 0;
                t.contentItem.contentRating = average;
            }
        },
        async getMyList() {
            let t = this;
            let result = await t.MIX_redisSearch('idx:myLists', `@contentId:${t.contentItem.id}`, 0, 9999);
            t.myLists = result.data.documents;
            t.myLists.forEach((list) => {
                list.value.status !== 1 ? (t.addedToMyList = true) : (t.addedToMyList = false);
            });
        },
        addLike() {
            let t = this;
            t.addStat('like');
            t.getLikes();
        },
        addDislike() {
            let t = this;
            t.addStat('dislike');
            t.getDislikes();
        },
        async getLikes() { 
            let t = this;
            let result = await t.MIX_statsCount({ searchQuery: `@contentId:${t.contentItem.id} @statType:like` });
            if (result.code === 1 && result.data.length > 0) {
                t.contentItem.contentLikes = parseInt(result.data[0].total);
            } else {
                t.contentItem.contentLikes = 0;
            }
        },
        async getDislikes() { 
            let t = this;
            let result = await t.MIX_statsCount({ searchQuery: `@contentId:${t.contentItem.id} @statType:dislike` });
            if (result.code === 1 && result.data.length > 0) {
                t.contentItem.contentDislikes = parseInt(result.data[0].total);
            } else {
                t.contentItem.contentDislikes = 0;
            }
        },
        async addView() {
            let t = this;
            const view = {};
            view.userId = t.GET_firebase_user.id;
            view.contentId = t.contentItem.id;
            const addView = await t.MIX_addView(view);
            if (addView.code === 1) {
                return;
            }
        },
        async createPdf(url) {
            let t = this;
            t.loading = true;
            t.addStat('download');
            return await t.MIX_generatePDF(url).then((response) => {
                response
                    .arrayBuffer()
                    .then((res) => {
                        const blob = new Blob([res], { type: 'application/pdf' });
                        t.$saveAs(blob, t.contentItem.id + '.pdf');
                        t.loading = false;
                    })
                    .catch((e) => alert(e));
            });
        },
        addStat(type) {
            let t = this;
            const stat = schema.stat;
            stat.contentId = t.contentItem.id;
            stat.contentTitle = t.contentItem.contentTitle;
            stat.contentArea = t.contentItem.contentArea;
            stat.contentAlias = t.contentItem.contentAlias;
            stat.contentPartnerOrgId = t.contentItem.orgId;
            stat.contentTags = JSON.stringify(t.contentItem.contentTags);
            stat.statType = type;
            t.MIX_addStat('stat', stat);
        },
        async getContentItems(item) {
            let t = this;
            t.contentPreviewOverlay = true;
            if (JSON.stringify(item) !== '{}') {
                let result = await t.MIX_redisSearch('idx:contentItems', `@itemContentId:{${item.id}}`, 0, 9999);
                if (result.code === 1) {
                    t.contentItem = item;
                    if (t.contentItem.contentTags !== '') {
                        t.contentItem.contentTags.split(',').forEach((item) => {
                            t.tags.push(item);
                        });
                    }
                    t.contentItems = result.data.documents;
                    t.contentItems.filter((item) => {
                        if (item.value.itemMarker === 'Cover Image') {
                            t.coverImage = item.value.itemURL;
                        }
                    });
                    t.filterContentItems()
                }
            }
        },
    },
    mounted() {
        let t = this;
        t.myList = schema.myList;
        t.getContentItems(t.contentItem);
        t.getTags();
        t.getRating();
        t.getMyList();
        t.getLikes();
        t.getDislikes();
        // t.addView();
        // t.addStat('view');
    },
};
</script>

<style>
#overlay {
    position: absolute;
    top: 0;
    z-index: 10;
}

.flipbook-container {
    height: 75vh;
    width: 100vw;
}
</style>