<template>
    <v-container fluid fill-height :class="$vuetify.breakpoint.mobile ? 'd-flex flex-column pa-0 ma-0 primary' : 'd-flex pa-0 ma-0 primary'">
        <v-col cols="3" class="primary d-flex align-center justify-center flex-column">
            <div><v-img contain class="mb-8" max-height="100" :src="require('@/assets/logo.png')"></v-img></div>
            <div class="white--text font-weight-bold">v{{ appVersion }}</div>
        </v-col>
        <v-col cols="9" class="white d-flex align-self-stretch justify-center pb-0">
            <v-card width="550" class="pa-8 rounded-sm elevation-0 align-self-center">
                <v-card-title class="d-flex justify-start">
                    <span class="headline py-4">Login</span>
                </v-card-title>
                <v-card-subtitle class="d-flex justify-start pb-10 black--text"> Enter your email and password below </v-card-subtitle>
                <v-card-text class="pt-6 mb-6">
                    <v-form ref="form" lazy-validation>
                        <v-text-field outlined class="primary--text" v-model="form.userEmail" label="Email" type="email" append-icon="oomph-secured-letter" :rules="rules.emailRules" required></v-text-field>
                        <v-text-field
                            outlined
                            class="primary--text"
                            v-model="form.password"
                            label="Password"
                            :append-icon="showPassword ? 'oomph-unavailable' : 'oomph-lock'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            :rules="rules.passwordRules"
                            autocomplete="off"
                            hide-details="auto"
                            required
                            v-on:keyup.enter="validateForm()"
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-btn block x-large class="primary white--text mb-4 elevation-0" @click="validateForm()" :loading="loading">Login</v-btn>
                </v-card-actions>
                <div class="body-1 float-left pl-4 font-weight-bold quaternary--text" style="cursor: pointer" @click="$router.push('/reset')">Reset Password</div>
                <div class="body-1 float-right pr-4 font-weight-bold quaternary--text" style="cursor: pointer" @click="$router.push('/register')">Sign up</div>
            </v-card>
        </v-col>
        <v-col cols="12" v-if="!$vuetify.breakpoint.mobile" class="py-0 white d-flex align-self-stretch justify-end">
            <v-row>
                <v-col cols="3" class="primary"></v-col>
                <v-col cols="9" class="white d-flex justify-end py-4">
                    <span class="d-flex align-self-end">Powered by</span>
                    <v-img contain class="ml-2 mb-1 d-flex align-self-end" max-height="50" max-width="100" :src="require('@/assets/vindico.png')"></v-img>
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { mapActions } from 'vuex'
import { version as appVersion } from '../../../package.json'

export default {
    data: () => ({
        appVersion: appVersion,
        alertMessage: '',
        form: {
            email: '',
            password: ''
        },
        emailRules: [(v) => !!v || 'Email is required', (v) => /.+@.+/.test(v) || 'Email must be valid'],
        loading: false,
        passwordRules: [(v) => !!v || 'Password is required'],
        showPassword: false,
        type: 'error',
        value: false,
        rules: []
    }),
    methods: {
        ...mapActions({
            ACT_FIREBASE_userAuth: 'firebase_auth_store/ACT_FIREBASE_userAuth'
        }),
        validateForm() {
            var t = this

            t.rules = {
                emailRules: [(v) => !!v || 'Email is required', (v) => /.+@.+/.test(v) || 'Email must be valid'],
                passwordRules: [(v) => !!v || 'Password is required']
            }
            t.$nextTick(() => {
                if (t.$refs.form.validate()) {
                    this.login()
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Fill out all the fields correctly in order to proceed.', show: true })
                }
            })
        },
        login() {
            let t = this
            t.loading = true
            const auth = getAuth()
            signInWithEmailAndPassword(auth, t.form.userEmail, t.form.password)
                .then(async (userCredential) => {
                    const user = userCredential.user
                    await t.MIX_FIREBASE_userAuth(user)
                    t.$router.push('/home')
                    t.loading = false
                })
                .catch((error) => {
                    t.loading = false
                    this.MIX_alertBox({ color: 'error', timeout: 2000, message: error.message, show: true })
                })
        }
    }
}
</script>
