<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field
                    label="Search Tickets"
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="oomph-search"
                    @click:clear="searchQuery = ''"
                ></v-text-field>
            </div>
            <div v-if="listexpanded" class="pl-2">
                <v-text-field
                    label="Filter by Assign To"
                    v-model="filter.owner"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="oomph-search"
                    @click:clear="clearSelectedOwnersFilter()"
                ></v-text-field>
            </div>
            <div v-if="listexpanded" class="pl-2">
                <v-text-field
                    label="Filter by Type"
                    v-model="filter.types"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="oomph-search"
                    @click:clear="clearTypeFilter()"
                ></v-text-field>
            </div>
            <div v-if="listexpanded" class="pl-2">
                <v-text-field
                    label="Filter by Organisation"
                    v-model="filter.orgs"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="oomph-search"
                    @click:clear="clearSelectedOrgsFilter()"
                ></v-text-field>
            </div>
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>oomph-available-updates</v-icon>
            </v-btn>
            <div class="ml-2">
                <v-menu
                    offset-y
                    class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false"
                    origin="top right"
                    left
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                            ><v-icon>oomph-table-properties</v-icon></v-btn
                        >
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox
                                v-model="headers[index].hidden"
                                dense
                                hide-details="auto"
                                v-for="(item, index) in headers"
                                :key="index"
                                :label="item.text"
                                :true-value="false"
                                :false-value="true"
                            ></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>

        <div v-if="!listexpanded" class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-select
                    label="Filter by Assign To"
                    :menu-props="{ top: false, offsetY: true }"
                    background-color="white"
                    hide-details="auto"
                    item-text="ownerUserName"
                    item-value="ownerUserName"
                    v-model="filter.owner"
                    :items="owners"
                    outlined
                    dense
                    @click:clear="clearSelectedOwnersFilter()"
                    clearable
                >
                    <!-- class="overflow" -->
                    <template v-slot:selection="data">
                        {{ data.item.ownerUserName }}
                    </template>
                    <template v-slot:item="data">
                        {{ data.item.ownerUserName }}
                    </template>
                </v-select>
            </div>
            <div class="flex-grow-1 pl-2">
                <v-select
                    label="Filter by Type"
                    :menu-props="{ top: false, offsetY: true }"
                    background-color="white"
                    hide-details="auto"
                    v-model="filter.types"
                    :items="types"
                    outlined
                    dense
                    @click:clear="clearTypeFilter()"
                    clearable
                >
                </v-select>
            </div>
            <div class="flex-grow-1 pl-2">
                <v-select
                    label="Filter by Organisation"
                    :menu-props="{ top: false, offsetY: true }"
                    background-color="white"
                    hide-details="auto"
                    item-text="orgName"
                    item-value="orgId"
                    v-model="filter.orgs"
                    :items="organisations"
                    outlined
                    dense
                    @click:clear="clearSelectedOrgsFilter()"
                    clearable
                >
                    <template v-slot:selection="data">
                        {{ data.item.orgName }}
                    </template>
                    <template class="overflow" v-slot:item="data">
                        {{ data.item.orgName }}
                    </template>
                </v-select>
            </div>
        </div>
        <div class="d-flex align-start pt-3">
            <!-- <v-spacer /> -->
            <v-btn-toggle multiple v-model="filter.status" class="appbackground d-flex align-center flex-wrap">
                <v-btn value="Open" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.mdAndDown ? 'Open' : ''" size="20" color="grey" class="oomph-open-message"></v-icon>
                    <span v-if="$vuetify.breakpoint.lgAndUp">Open</span>
                </v-btn>
                <v-btn value="Closed" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.mdAndDown ? 'Closed' : ''" size="20" color="grey" class="oomph-cancel"></v-icon>
                    <span v-if="$vuetify.breakpoint.lgAndUp">Closed</span>
                </v-btn>
                <v-btn value="Archived" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.mdAndDown ? 'Archived' : ''" size="20" color="grey" class="oomph-archive"></v-icon>
                    <span v-if="$vuetify.breakpoint.lgAndUp">Archived</span>
                </v-btn>
                <v-btn value="Solved" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.mdAndDown ? 'Solved' : ''" size="20" color="grey" class="oomph-checkmark-yes"></v-icon>
                    <span v-if="$vuetify.breakpoint.lgAndUp">Solved</span>
                </v-btn>
                <v-btn value="Pending" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.mdAndDown ? 'Pending' : ''" size="20" color="grey" class="oomph-clock-2"></v-icon>
                    <span v-if="$vuetify.breakpoint.lgAndUp">Pending</span>
                </v-btn>
                <v-btn value="On Hold" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.mdAndDown ? 'On Hold' : ''" size="20" color="grey" class="oomph-pause-button"></v-icon>
                    <span v-if="$vuetify.breakpoint.lgAndUp">On Hold</span>
                </v-btn>
            </v-btn-toggle>
        </div>
        <v-row class="pa-3 mt-1">
            <!-- TABLE BASED LIST -->
            <v-data-table
                :item-class="itemRowBackground"
                sortable
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="computedItems"
                :server-items-length="itemsTotal"
                :options.sync="datatableOptions"
            >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="Edit" value="edit" small>
                                <v-icon class="grey--text text--darken-3">oomph-edit</v-icon>
                            </v-btn>
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                            <v-btn title="Undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:[`item.createdDateTime`]="{ item }">
                    <div class="d-flex align-center">
                        {{ $moment(item.createdDateTime, 'x').format('DD/MM/YY') }}
                    </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <div class="d-flex align-center">
                        <v-icon
                            class="grey--text text--darken-1"
                            :class="
                                item.status === 'Open'
                                    ? 'oomph-open-message'
                                    : item.status === 'Closed'
                                    ? 'oomph-cancel'
                                    : item.status === 'Archived'
                                    ? 'oomph-archive'
                                    : item.status === 'Solved'
                                    ? 'oomph-checkmark-yes'
                                    : item.status === 'Pending'
                                    ? 'oomph-clock-2'
                                    : item.status === 'On Hold'
                                    ? 'oomph-pause-button'
                                    : ''
                            "
                        />
                        <div class="font-weight-bold grey--text text--darken-1 pl-1">{{ item.status }}</div>
                    </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">oomph-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>
                </template>
            </v-data-table>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        type: '',
        status: '',
        key: 'ticket',
        index: 'idx:tickets',
        ticket: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', value: 'id', sortable: false, hidden: true, expanded: true },
            { text: 'Status', value: 'status', sortable: false, hidden: false, expanded: false },
            { text: 'Subject', value: 'subject', sortable: false, hidden: false, expanded: false },
            { text: 'Message', value: 'message', sortable: false, hidden: false, expanded: false },
            { text: 'Type', value: 'type', sortable: false, hidden: false, expanded: false },
            { text: 'Org', value: 'orgName', sortable: false, hidden: false, expanded: false },
            { text: 'Owner Id', value: 'ownerUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Assigned To', value: 'ownerUserName', sortable: false, hidden: false, expanded: false },
            { text: 'Owner Email', value: 'ownerUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: false, expanded: false },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Created Name', value: 'createdUserName', sortable: false, hidden: false, expanded: false },
            { text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Name', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Name', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Email', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        sortBy: '@message',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['message'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        filter: {
            status: [],
            owner: [],
            types: [],
            orgs: [],
        },
        owners: [],
        types: ['Content Request', 'Feature Request', 'Feedback', 'Issue', 'Question'],
        organisations: [],
    }),
    computed: {
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:0';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }

            if (JSON.stringify(this.filter.owner) !== '[]') {
                searchQuery = `${searchQuery} @ownerUserName:${this.filter.owner}`;
            }
            if (JSON.stringify(this.filter.status) !== '[]') {
                searchQuery = `${searchQuery} @status:${this.filter.status.join('|')}`;
            }
            if (JSON.stringify(this.filter.types) !== '[]') {
                searchQuery = `${searchQuery} @type:${this.filter.types}`;
            }
            if (JSON.stringify(this.filter.orgs) !== '[]') {
                searchQuery = `${searchQuery} @orgName:${this.filter.orgs}`;
            }
            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (this.listshrunk) {
                headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            } else {
                headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedItems() {
            var t = this;
            var items = t.items;
            items.sort((a, b) => b.createdDateTime - a.createdDateTime);
            return items;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        // Clear Selected Org Tags
        clearSelectedOwnersFilter() {
            setTimeout(() => {
                this.filter.owner = [];
            }, 1);
        },
        // Clear Selected Type Tags
        clearTypeFilter() {
            setTimeout(() => {
                this.filter.types = [];
            }, 1);
        },
        // Clear Selected Org Tags
        clearSelectedOrgsFilter() {
            setTimeout(() => {
                this.filter.orgs = [];
            }, 1);
        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, this.key);
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // * GET ITEMS
        async getItems() {
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.contract = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this;
        this.ticket = { ...this.datadown };
        this.getItems();
    },
};
</script>