<template>
    <div>
        <v-icon :title="packagedata.packageName">{{ packagedata.packageIcon }}</v-icon>
    </div>
</template>

<script>
    export default {
        props: {
            packagedata: {
                type: Object,
                default: () => {
                    return {};
                },
            }
        }
    }
</script>