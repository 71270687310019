<template>
    <div class="px-5 appbackground" style="width: 100% !important; height: calc(100vh - 170px) !important;">
        <div class="d-flex align-center py-5">
            <div class="text-h5 font-weight-bold primary--text">Interactions</div>
            <v-spacer />
            <div>
                <v-btn class="primary white--text rounded" @click="downloadCSV()" depressed><v-icon class="mr-2">icons8-export-csv</v-icon>Download CSV</v-btn>
            </div>
        </div>
        <v-data-table :loading="loading" must-sort :headers="interactionHeaders" :items="computedInteractionsData" :items-per-page="10" class="elevation-0">
            <template v-slot:[`item.dementiaFriendly`]="{ item }">
                {{ item.dementiaFriendly == "1" ? "Yes" : "No" }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        selectedOrgGroup: {
            type: Object,
            default: () => {
                return {};
            },
        },
        selectedSubOrg: {
            type: Array,
            default: () => {
                return [];
            },
        },
        daterange: {
            type: Object,
            default: () => {
                return {
                    startDate: null,
                    endDate: null,
                };
            },
        },
        filterBy: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },    
    data: () => ({
        contentItem: [],
        contentItems: [],
        contentTemplateKey: 0,
        loading: false,
        showContentItem: false,
        interactionData: [],
        interactionHeaders: [
            { text: "Content Title", value: "contentTitle", align: "start" },
            { text: "Content Area", value: "contentArea", align: "start" },
            { text: "Content Alias", value: "contentAlias", align: "start" },
            { text: "Dementia Friendly", value: "dementiaFriendly", align: "center" },
            { text: "Number of Interactions", value: "interactionsTotal", align: "center" },
        ],
    }),
    watch: {
        daterange: {
            handler: function () {
                let t = this;
                t.getInteractionsStats();
            },
            deep: true,
        },
        filterBy: {
            handler: function () {
                let t = this;
                t.getInteractionsStats();
            },
            deep: true,
        },
    }, 
    computed: {
        computedInteractionsData() {
            return this.interactionData.filter(e => e.contentTitle !== '' && e.contentTitle !== null && e.contentTitle !== undefined)
        }
    },
    methods: {
        async getInteractionsStats() {
            let t = this;
            let query = "";
            let subOrgs = [];
            t.loading = true;

            let downloadFilters = ""
            let viewFilters = ""
            
            for (let i = 0; i < t.filterBy.length; i++) {
                const filter = t.filterBy[i].split("_");
                if (filter[0] === "downloads") {
                    if (!downloadFilters) downloadFilters += filter[1]
                    else downloadFilters += `|${filter[1]}`
                } else if (filter[0] === "views") {
                    if (!viewFilters) viewFilters += filter[1]
                    else viewFilters += `|${filter[1]}`
                }
            }

            if (t.selectedOrgGroup.id === '3RTRBM9dqrkC4WEsIvhx') {
                //* Returns all stats for all orgs
                query = `@createdDateTime:[${t.daterange.startDate} ${t.daterange.endDate}]`;
            } else {
                //* Returns stats for selected org
                query = `@createdUserOrgId:{${t.selectedOrgGroup.id}} @createdDateTime:[${t.daterange.startDate} ${t.daterange.endDate}]`;
            }
            if (t.selectedSubOrg.length > 0) {
                //* Returns stats for selected sub orgs
                subOrgs = t.selectedSubOrg.map((org) => org.id).join('|');
                query = `@createdUserOrgId:{${subOrgs}} @createdDateTime:[${t.daterange.startDate} ${t.daterange.endDate}]`;
            } 

            let downloadQuery = `${query} ${(downloadFilters) ? `@contentArea:(${downloadFilters})` : ""}`
            let viewQuery = `${query} ${(viewFilters) ? `@contentArea:(${viewFilters})` : ""}`

            let res1 = await t.MIX_downloads({ searchQuery: downloadQuery });
            let res2 = await t.MIX_views({ searchQuery: viewQuery });

            // console.log(res1.data, res2.data);
            if (res1.code === 1 && res2.code === 1) {
                // loop through res1 and res2 and add downloads and views together by id
                let temp = {}

                let downloads = res1.data
                let views = res2.data

                downloads.forEach(e => {
                    if (temp[e.contentId] == undefined) temp[e.contentId] = e;
                    if (temp[e.contentId]['interactionsTotal'] == undefined) temp[e.contentId]['interactionsTotal'] = 0;
                    temp[e.contentId]['interactionsTotal'] += this.isNumber(e['downloadsTotal']);
                })

                views.forEach(e => {
                    if (temp[e.contentId] == undefined) temp[e.contentId] = e;
                    if (temp[e.contentId]['interactionsTotal'] == undefined) temp[e.contentId]['interactionsTotal'] = 0;
                    temp[e.contentId]['interactionsTotal'] += this.isNumber(e['viewsTotal']);
                })

                //combine downloads total and interactions total from res1 and res2
                let result = Object.values(temp);
                result.sort((a, b) => b.interactionsTotal - a.interactionsTotal); 
                t.interactionData = result;
            }
            t.loading = false;
        },
        isNumber(n) {
            const num = parseInt(n);
            return !isNaN(num) ? num : 0;
        },
        downloadCSV() {
            let t = this;
            const dateFrom = t.$moment.unix(t.daterange.startDate).format('DDMMMYYYY');
            const dateTo = t.$moment.unix(t.daterange.endDate).format('DDMMMYYYY');
            const dateNow = t.$moment().format('DDMMMYYYY');
            const date = t.daterange.startDate && t.daterange.endDate ? `${dateFrom}_${dateTo}` : dateNow;
            var downloads = t.computedInteractionsData.map(e => {
                return {
                    'Content Title': e.contentTitle,
                    'Content Area': e.contentArea,
                    'Content Type': e.contentAlias,
                    'Dementia Friendly': e.dementiaFriendly === '1' ? 'Yes' : "No",
                    'Number of Interactions': e.interactionsTotal,
                }
            })
            var interactionsCSV = t.$papa.unparse(downloads);
            this.$papa.download(interactionsCSV, `Report_Interactions_${date}.csv`, "text/csv");
        },
    },
    created() {
        let t = this;
        t.getInteractionsStats();
    },
};
</script>
