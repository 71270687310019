<template>
    <div class="" style="width: 100% !important">
        <v-row dense style="background-color: #C9E4E9" class="d-flex pa-3 mt-0 align-start">
            <!-- <pre>{{ tab }}</pre> -->
			<v-col cols="1" class="pl-1">
                <v-btn depressed block class="primary darken-2 white--text" @click="openItem({})" >
                    <v-icon>oomph-business-network</v-icon>
                    <span class="ml-3">New Account</span>
                </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="10">
                <v-row dense class="d-flex align-center">
                    <v-col class="d-flex justify-end" v-if="tab == 'Home'" cols="4">
                        <div class="primary--text">Please select a Group in order to view its Homes:</div>
                    </v-col>
                    <v-spacer />
                    <v-col v-if="tab == 'Home'" cols="2">
                        <v-select label="Group" v-model="filter.orgGroupId" :items="orgGroups" item-value="orgId" item-text="orgName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOrgGroupStructure()" :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            label="Search Accounts"
                            v-model="searchQuery"
                            outlined
                            dense
                            hide-details="auto"
                            background-color="white"
                            color="primary"
                            clearable
                            prepend-inner-icon="oomph-search"
                            @click:clear="clearSearchQuery()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2" v-if="listexpanded" class="pl-1">
                        <v-select 
                            label="Filter by Type" 
                            :menu-props="{ top: false, offsetY: true }" 
                            background-color="white" 
                            hide-details="auto" 
                            v-model="filter.type" 
                            :items="types" 
                            outlined 
                            dense 
                            @click:clear="clearSelectedTypeFilter()" 
                            clearable>
                        </v-select>
                    </v-col>
                    <v-col cols="2" v-if="listexpanded" class="pl-1">
                        <v-select 
                            label="Filter by Status" 
                            :menu-props="{ top: false, offsetY: true }" 
                            background-color="white" 
                            hide-details="auto" 
                            v-model="filter.status" 
                            :items="status" 
                            outlined 
                            @click:clear="clearSelectedStatusFilter()" 
                            dense 
                            clearable>
                        </v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
            <v-col cols="12" class="pt-0">
                <v-card class="notscrolly d-flex transparent elevation-0 pa-0 ma-0" height="750" width="100%" style="overflow-x: scroll !important;">
                    <v-col cols="12" class="d-flex font-weight-bold pl-1 white--text">
                        <v-row>
                            <v-card class="d-flex transparent elevation-0 pa-0 ma-0 " width="94vw">
                                <v-col v-for="(stage, index) in stages" :key="index" :id="index" class="px-1 pt-5 pl-2 text-center d-flex flex-column">
                                    <v-card class="borderBottom" :color="stage.stageOutcome === 'success' ? 'primary' : stage.stageOutcome === 'failure' ? 'primary darken-2' : ''"  fixed flat>
                                        <v-col cols="12">
                                            <v-row class="justify-start pa-0">
                                                <v-col cols="12" class="d-flex justify-center align-center">
                                                    <div style="min-width: 220px !important">
                                                        <v-card-title 
                                                            :class="stage.stageOutcome === 'success' ? 'white--text' : stage.stageOutcome === 'failure' ? 'white--text' : 'primary--text'" 
                                                            class="body-2 pa-0 ma-0 font-weight-bold justify-center text-truncate" 
                                                            style="min-width: 200px !important">
                                                            {{ stage.stageName }}
                                                        </v-card-title>
                                                        <v-card-title 
                                                            :class="stage.stageOutcome === 'success' ? 'white--text' : stage.stageOutcome === 'failure' ? 'white--text' : 'primary--text'" 
                                                            class="body-1 pa-0 ma-0 justify-center "
                                                            style="min-width: 200px !important">
                                                            <!-- <span class="pr-1"> 
                                                                £{{ computedItems(stage).total}} </span> 
                                                             -  -->
                                                            {{ computedItems(stage).length }} Accounts
                                                            <!-- <v-spacer /> -->
                                                        </v-card-title>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-card>
                                    <!-- <pre>{{ computedItems(stage) }}</pre> -->
                                    <v-col class="pa-0 ma-0 mt-2">
                                        <drop-list 
                                            class="list"
                                            :items="computedItems(stage)"
                                            @insert="onInsert($event, stage)"
                                        >
                                            <template v-slot:item="{item}">
                                                <drag  @dragstart="dragS" @cut="remove(computedItems(stage), item)" :data="item" :key="item.id">
                                                    <account-card @click="openLead" v-on:openorg="openLead" v-on:openorgitem="openItem" class="mb-2" style="" :datadown="item"></account-card>
                                                </drag>
                                            </template>
                                            <template v-slot:feedback="{data}">
                                                <account-card @click="openLead" v-on:openorg="openLead" v-on:openorgitem="openItem" class="mb-2" style="" :key="data.id" :datadown="data"></account-card>
                                            </template>
                                        </drop-list>
                                    </v-col>
                                </v-col>
                            </v-card>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>
                    

        <v-dialog persistent v-model="actionDialog" width="700px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mx-3 mb-1">
                        <v-icon class="white--text">oomph-business-network</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Stage Tasks</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeActionDialog()" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-0">
                    <v-row v-if="previousActions.length > 0" class="pa-0 ma-0 d-flex justify-center" fill-height>
                        <v-col class="pa-0" fill-height cols="12" style="width: 100% !important">
                            <div class="font-weight-bold pa-3 text-h7 grey--text">
                                There are tasks yet to be completed for this Account, these are listed below. Please confirm you have read this message.
                            </div>
                        </v-col>
                        <v-col cols="6" class="align-center pt-0">
                            <div class="error--text justify-left align-center" v-for="(action, index) in previousActions" :key="index">
                                <v-icon class="oomph-filled-circle" color="grey" size="10"></v-icon>
                                <span class="font-weight-medium body-2 grey--text ma-0 pa-0 pl-2">{{ action.actionSubject }}</span>

                            </div>
                            <div class="d-flex justify-center align-center ma-0 pa-0 pt-2">
                                <v-checkbox 
                                    on-icon="oomph-ok"
                                    off-icon="oomph-circle"
                                    v-model="confirmCheckBox" 
                                    true-value="1" 
                                    false-value="0" 
                                    hide-details
                                    class="body-2 ma-0 pa-0"
                                />
                                <span class="font-weight-medium body-2 grey--text ma-0 pa-0" :class="confirmCheckBox == 0 ? 'error--text' : ''">I confirm I have read this message</span>
                            </div>

                        </v-col>
                    </v-row>                    
                    
                    <v-row v-if="stageActions.length > 0" class="pa-0 ma-0 justify-center d-flex" fill-height>
                        <v-col class="pa-0" fill-height cols="12" style="width: 100% !important">
                            <div class="font-weight-bold body-2 pa-3 text-h7 grey--text">
                                The below Actions will be added to this Account:
                            </div>
                        </v-col>
                        <v-col cols="6" class="align-center pt-0">
                            <div class="d-flex  ma-0 pa-0 align-center pt-0" v-for="(action, index) in stageActions" :key="index">
                                <!-- <v-checkbox
                                    on-icon="oomph-ok"
                                    off-icon="oomph-circle" 
                                    v-model="action.addAction"
                                    class="ma-0 pa-0" 
                                    hide-details /> -->
                                <v-icon class="oomph-filled-circle" color="grey" size="10"></v-icon>
                                <span class="font-weight-medium body-2 grey--text pl-2 ma-0 pa-0">{{ action.actionSubject }}</span>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-2">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="closeActionDialog()"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                            <v-btn class="primary white--text font-weight-bold ml-2" :disabled="previousActions.length > 0 && confirmCheckBox === '0'" depressed @click="saveActions"> <v-icon class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import accountCard from '@/views/accounts/AccountCard.vue'
import { Drag, DropList } from "vue-easy-dnd";
import actionCard from '@/views/sales/TaskCard.vue'
export default {
    components: {
        'account-card': accountCard,
        'action-card': actionCard,
        Drag,
        DropList
    },
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
        tab: {
            type: String,
            default: 'group'
        }
    },
    data: () => ({
        type: '',
        status: '',
        key: 'org',
        index: 'idx:orgs',
        lead: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        opportunityDialog: false,
        actionDialog: false,
        stageActions: {},
        previousActions: {},
        opportunityStage: '',
        orgGroups: [],

        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', value: 'id', sortable: false, hidden: true, expandable: true },
            { text: 'Name', value: 'orgName', sortable: false, hidden: false, expandable: true },
            { text: 'Registered Name', value: 'orgRegistrationNumber', sortable: false, hidden: true, expandable: true },
            { text: 'status', value: 'orgStatus', sortable: false, hidden: false, expandable: true },
            { text: 'Telephone', value: 'orgTelephone', sortable: false, hidden: false, expandable: true },
            { text: 'Website', value: 'orgWebsite', sortable: false, hidden: false, expandable: false },
            { text: 'Email', value: 'orgEmail', sortable: false, hidden: true, expandable: true },
            { text: 'Invoice Email', value: 'orgInvoiceEmail', sortable: false, hidden: true, expandable: false },
            { text: 'Purchasing Email', value: 'orgPurchasingEmail', sortable: false, hidden: true, expandable: false },

            { text: "Group Structure", value: "orgGroupStructure", sortable: false, hidden: false, expandable: false },
            { text: "Group Id", value: "orgGroupId", sortable: false, hidden: false, expandable: false },

            { text: 'Stage Id', value: 'orgStageId', sortable: false, hidden: false, expandable: false },
            { text: 'Stage Name', value: 'orgStageName', sortable: false, hidden: false, expandable: false },

            { text: 'Address Type', value: 'orgAddressType', sortable: false, hidden: true, expandable: false },
            { text: 'Address Line 1', value: 'orgAddressLine1', sortable: false, hidden: true, expandable: false },
            { text: 'Address Line 2', value: 'orgAddressLine2', sortable: false, hidden: true, expandable: false },
            { text: 'Address Line 3', value: 'orgAddressLine3', sortable: false, hidden: true, expandable: false },
            { text: 'Town', value: 'orgTown', sortable: false, hidden: false, expandable: false },
            { text: 'County', value: 'orgCounty', sortable: false, hidden: true, expandable: false },
            { text: 'Postcode', value: 'orgPostcode', sortable: false, hidden: true, expandable: false },

            { text: 'Other Address Type', value: 'orgOtherAddressType', sortable: false, hidden: true, expandable: false },
            { text: 'Other Address Line 1', value: 'orgOtherAddressLine1', sortable: false, hidden: true, expandable: false },
            { text: 'Other Address Line 2', value: 'orgOtherAddressLine2', sortable: false, hidden: true, expandable: false },
            { text: 'Other Address Line 3', value: 'orgOtherAddressLine3', sortable: false, hidden: true, expandable: false },
            { text: 'Other Address Town', value: 'orgOtherAddressTown', sortable: false, hidden: true, expandable: false },
            { text: 'Other Address County', value: 'orgOtherAddressCounty', sortable: false, hidden: true, expandable: false },
            { text: 'Other Address Postcode', value: 'orgOtherAddressPostcode', sortable: false, hidden: true, expandable: false },

            { text: 'Employees', value: 'orgEmployees', sortable: false, hidden: true, expandable: false },
            { text: 'Industry', value: 'orgIndustry', sortable: false, hidden: true, expandable: false },
            { text: 'Sector', value: 'orgSector', sortable: false, hidden: true, expandable: false },
            { text: 'Account Number', value: 'orgAccountNumber', sortable: false, hidden: true, expandable: false },
            { text: 'Account Types', value: 'orgAccountTypes', sortable: false, hidden: false, expandable: false },
            { text: 'Local Authority', value: 'orgLocalAuthority', sortable: false, hidden: true, expandable: false },
            { text: 'Turnover', value: 'orgTurnover', sortable: false, hidden: true, expandable: false },
            { text: 'Notes', value: 'orgNotes', sortable: false, hidden: true, expandable: false },

            { text: 'Account Owner', value: 'orgAccountOwner', sortable: false, hidden: true, expandable: false },
            { text: 'Source', value: 'orgSource', sortable: false, hidden: true, expandable: false },
            { text: 'Source Name', value: 'orgSourceName', sortable: false, hidden: true, expandable: false },

            { text: 'Twitter', value: 'orgTwitter', sortable: false, hidden: true, expandable: false },
            { text: 'Facebook', value: 'orgFacebook', sortable: false, hidden: true, expandable: false },
            { text: 'Linked In', value: 'orgLinkedIn', sortable: false, hidden: true, expandable: false },
            { text: 'Instagram', value: 'orgInstagram', sortable: false, hidden: true, expandable: false },

            { text: 'Date Time (C)', value: 'createdDateTime', sortable: false, hidden: true, expandable: false },
            { text: 'User ID (C)', value: 'createdUserId', sortable: false, hidden: true, expandable: false },
            { text: 'User Name (C)', value: 'createdUserName', sortable: false, hidden: true, expandable: false },
            { text: 'User Email (C)', value: 'createdUserEmail', sortable: false, hidden: true, expandable: false },
            { text: 'Date Time (M)', value: 'modifiedDateTime', sortable: false, hidden: true, expandable: false },
            { text: 'User ID (M)', value: 'modifiedUserId', sortable: false, hidden: true, expandable: false },
            { text: 'User Name (M)', value: 'modifiedUserName', sortable: false, hidden: true, expandable: false },
            { text: 'User Email (M)', value: 'modifiedUserEmail', sortable: false, hidden: true, expandable: false },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expandable: false },
            { text: 'Date Time (D)', value: 'deletedDateTime', sortable: false, hidden: true, expandable: false },
            { text: 'User ID (D)', value: 'deletedUserId', sortable: false, hidden: true, expandable: false },
            { text: 'User Name (D)', value: 'deletedUserName', sortable: false, hidden: true, expandable: false },
            { text: 'User Email (D)', value: 'deletedUserEmail', sortable: false, hidden: true, expandable: false },
            { text: 'Action', value: 'action', align: 'end', sortable: false, hidden: false, expandable: false, width: '155px' },
        ],
        // Fields to Retrieve from Database
        // fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@orgName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 100,
            sortBy: ['orgName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        leadStage: {},
        newOpportunity: {},

        // productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
        filter: {
            type: [],
            status: [],
            orgGroupId: ""
        },

        types: [],
        status: [],


        stages: {},
        opportunityStages: {},

        confirmCheckBox: '0',

        activeEvent:{}


    }),
    computed: {
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:0';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }


            // Group Structure Filter
            if (this.tab == 'Group') {
                searchQuery = `${searchQuery}  @orgGroupStructure:{${this.tab}}`;
            }

            // Organisation Groups
            else if (this.tab == 'Home') {
            // && this.filter.orgGroupId !== "" && this.filter.orgGroupId !== null && this.filter.orgGroupId !== undefined) {
                searchQuery = `${searchQuery} @orgGroupStructure:{${this.tab}} @orgGroupId:{${this.filter.orgGroupId}}`;
            }

            // Organisation Groups
            // if (this.tab == 'independent homes') {
            //     searchQuery = `${searchQuery} @orgGroupStructure:Independent home @orgGroupId:{${this.filter.orgGroupId}}`;
            // }

            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expanded !== true); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            // if (!computedFields.includes('@actionId')) {
            //     computedFields.push('@actionId');
            // }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        closeActionDialog() {
            var t = this;
            t.actionDialog = false,
            t.getItems()
        },
        saveActions() {
            var t = this
            var stageActions = t.stageActions
            
            stageActions.forEach((item) => {
                item.id = t.MIX_generateId()
                item.orgId = t.activeEvent.id
                item.orgName = t.activeEvent.orgName
                t.saveAction(item)
            })

            this.saveItem(t.activeEvent)
            t.actionDialog = false
        },

        async saveAction(item) {
			let t = this;
			let result = await t.MIX_createItem(item, 'action', true);
			if (result.code === 1) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Task Saved", show: true });
			}

        },
        // Clear Selected Org Tags
        clearSelectedStatusFilter() {
            setTimeout(() => {
                this.filter.status = [];
            }, 1);
        },
        clearSelectedOrgGroupStructure() {
            setTimeout(() => {
                this.filter.orgGroupId = ''
            })
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        // Clear Selected Org Tags
        clearSelectedTypeFilter() {
            setTimeout(() => {
                this.filter.type = [];
            }, 1);
        },
        computedItems(stage) {
            var t = this
            var items = this.items 

            items = items.filter((item) => item.orgStageId === stage.stageId);

            if (JSON.stringify(this.filter.type) !== '[]') {
                items = items.filter((item) => item.orgAccountTypes.includes(this.filter.type))
            }

            if (JSON.stringify(this.filter.status) !== '[]') {
                items = items.filter((item) => t.filter.status === item.orgStatus )
            }

            return items
        },
		dragS(e) {
    	  this.activeDrag = e.data;
    	},
    	onDrop(slotId) {
		  	this.activeDrag = {};
    	},
    	remove(n) {
    	},
        removeItemFromList(event) {
            var t = this;
            switch (event.opportunityStage) {
                case "Initial Interest/Lead Generated":
                    t.computedInitialInterestItems.splice(t.computedInitialInterestItems.indexOf(event), 1);
                    break;
                default:
                    break;
            }
        },
        async createOpportunity() {
            var t = this;

            t.newOpportunity.salesStageName = t.leadStage.stageName
            t.newOpportunity.salesStageId = t.leadStage.stageId

            // SAVE ITEM - LEAD
            t.saveItem(t.newOpportunity)

            // Get the item from the database
            let itemResult = await this.MIX_readItem(t.newOpportunity.id, 'lead');
            if (itemResult.code === 1) {
                // this.$emit('edit', itemResult.data);
                t.newOpportunity = itemResult.data

            }

            // CHANGE STATUS TO NEW OPPORTUNITY STATUS
            t.newOpportunity.salesStageName = t.opportunityStage.stageName
            t.newOpportunity.salesStageId = t.opportunityStage.stageId
            t.newOpportunity.key = "opportunity"

            t.saveOpportunity(t.newOpportunity)
            
            t.opportunityDialog = false

            
        },
        showOpportunityDialog(event, stage) {
            var t = this;
            t.newOpportunity = []
            t.newOpportunity = event.data
            t.leadStage = stage
            t.opportunityDialog++
        },
        onInsert(event, stage) {
            var t = this
            console.log("onInsert event = " + JSON.stringify(event, null, 2));
            console.log("onInsert stage = " + JSON.stringify(stage, null, 2));
            if (stage.stageOutcome === 'success') {
                t.showOpportunityDialog(event, stage) 
            } else {
                if (event.data.length > 0) {
                    // // // console.log("here 1")
                    event.data.forEach((e, idx) => {
                    // event.index is the starting point of the target droplist
                    // event.index + idx = appending the items one after the other
                    this[listName].splice(event.index + idx, 0, e);
                    });
                } else {
                    // // // console.log("here 2")
                    // here we have just one item
                    // @see https://codesandbox.io/s/droplist-ozs8b
                    // // // console.log("this.list before = " + JSON.stringify(this.computedItems(stage), null, 2));
                    this.computedItems(stage).splice(event.index, 0, event.data);
                    // this.removeItemFromList(event.data)
                    console.log("event data = " + JSON.stringify(event.data, null, 2))
                    var previousStage = {
                        stageId: '',
                        stageName:''
                    }
                    previousStage.stageId = event.data.orgStageId
                    previousStage.stageName = event.data.orgStageName

                    event.data.orgStageName = stage.stageName;
                    event.data.orgStageId = stage.stageId;
                    // // // console.log("event after stage = " + JSON.stringify(event, null, 2))
                    // // // console.log("this.list after = " + JSON.stringify(this.computedItems(stage), null, 2));
                    this.getActions(stage, previousStage, event.data)
                    
                    // this.saveItem(event.data)
                }
            }

            this.activeDrag = [];
        },
        async getActions(stage, previousStage, item) {
            var previousStage = previousStage
            var stage = stage;

            var event = item

            //* FIND OUT IF ACCOUNT HAS CERTAIN PACKAGES

            //* GETTING TASKS FOR NEW STAGE

            let stageActions = await this.MIX_redisSearch('idx:stageActions', `@deleted:0`, 0, 9999);
            stageActions = stageActions.data.documents.map((x) => x.value);

            stageActions = stageActions.filter((item) => {
                return item.actionStageId === stage.stageId;
            })
            stageActions = stageActions.filter((item) => {
                return item.orgId === ''
            })
            stageActions.forEach((item) => {
                item.addAction = 0
            })
            this.stageActions = stageActions


            //* GETTING TASKS FROM PREVIOUS STAGE AND CHECKING IF COMPLETED

            let previousActions = await this.MIX_redisSearch('idx:actions', `@deleted:0 @actionType:Task`, 0, 9999);
            previousActions = previousActions.data.documents.map((x) => x.value);

            previousActions = previousActions.filter((item) => {
                return item.actionStageId === previousStage.stageId;
            })
            previousActions = previousActions.filter((item) => {
                return item.orgId === event.id
            })
            previousActions = previousActions.filter((item) => {
                return item.actionCompleted === '0' || item.actionCompleted === null
            })
            this.previousActions = previousActions
            
            this.activeEvent = event

            if (this.stageActions.length > 0 || this.previousActions.length > 0) {
                this.actionDialog = true
            } else {
                this.saveItem(event)
            }
        },
        async saveItem(event) {
			let t = this;
            // console.log("saving event  = " + JSON.stringify(event, null, 2))
			let result = await t.MIX_createItem(event, 'org', false);
			if (result.code === 1) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
				// t.readItem(item.id);
				// t.$emit("refreshitems");
			}
        },
        async saveOpportunity(event) {
			let t = this;
			let result = await t.MIX_createItem(event, 'opportunity', true);
			if (result.code === 1) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
				// t.readItem(item.id);
				// t.$emit("refreshitems");
			}
        },
        toggleSelected(listName, item) {
            if (listName !== this.selectedList) {
                this.activeDrag = [];
                this.selectedList = listName;
            }

            // Basic toggeling logic
            // If an item is in the list remove it
            // otherwise add it to the list
            const index = this.activeDrag.indexOf(item);
            if (index > -1) {
                this.activeDrag.splice(index, 1);
            } else {
                this.activeDrag.push(item);
            }
        },

        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'lead');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Lead', show: true });
            }
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // openLeadItem(item) {

        // },
        // * OPEN ITEM
        openLead(item) {
            console.log("opening lead = " + JSON.stringify(item, null, 2));
            // this.$emit('openLead', JSON.parse(JSON.stringify(item)));

            this.MIX_go('/customers/'+ item.id)
        },
        // * GET ITEMS
        async getItems() {
            // if (this.searchQuery.length !== 1) {
                this.items = []
                let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, 9999, this.computedFields, this.sortBy, false);
                if (itemsResult.code === 1) {
                    this.itemsTotal = itemsResult.data.total;
                    this.items = itemsResult.data.results;
                    let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                    if (itemsTo < this.itemsTotal) {
                        this.itemsTo = itemsTo;
                    } else {
                        this.itemsTo = this.itemsTotal;
                    }
                }
            // }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.lead = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
        },
        tab() {
            this.getItems()
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        var t = this
        this.lead = { ...this.datadown };
        let stages = await this.MIX_redisOrderedSearch('idx:stages', `*`, 0, 9999, 'stageValue');
        stages = stages.data.documents.map((x) => x.value);
        // // // // console.log("stages = " + JSON.stringify(stages, null, 2));
        t.stages = stages.filter((item) => {
            return item.stagePipeline === 'Accounts';
        })

        // Get Groups and Parent Groups
        let orgGroups = await this.MIX_redisOrderedSearch("idx:orgs", `@orgAccountTypes:{Customer} @orgGroupStructure:{Group}`, 0, 9999, "orgName");
        orgGroups = orgGroups.data.documents.map((x) => x.value);
        this.orgGroups = orgGroups.map((x) => {
            return {
                orgId: x.id,
                orgName: x.orgName,
                orgGroupStructure: x.orgGroupStructure,
                orgGroupId: x.orgGroupId,
                orgParentGroupId: x.orgParentGroupId,
                orgDivisions: x.orgDivisions,
                orgRegions: x.orgRegions,
            };
        });

        let types = await t.MIX_redisReadSet('set:orgAccountTypes', false);
        if (types.code === 1) {
            t.types = types.data;
        }
        // Get Organisation Status SortedSet
        let status = await t.MIX_redisReadSortedSet('sset:orgStatus', 0, 9999);
        if (status.code === 1) {
            t.status = status.data;
        }

        t.stages = t.stages.map((x) => {
            return {
                stageId: x.id,
                stageName: x.stageName,
                stageOutcome: x.stageOutcome
            };
        })
        this.getItems();
    },
};
</script>

<style scoped>
.borderBottom {
    border-bottom: 5px solid #019EAF !important;
}
  .list {
    /* border: 1px solid black; */
    /* margin: 100px auto; */
    /* width: 200px; */
    min-height: 600px;
  }
</style>