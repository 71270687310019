<template>
    <v-container class="mt-8">
        <v-col cols="12" class="d-flex justify-center">
            <span class="text-h6 primary--text"
                >You are <strong>NOT AUTHORISED</strong> to view this section.</span
            >
        </v-col>
        <v-col cols="12" class="d-flex flex-column align-center primary--text">
                <span> If you require access to this section then please contact
                <a
                    :href="'mailto:support@oomph-wellness.org'"
                    class="font-weight-bold"
                    >support@oomph-wellness.org</a
                ></span
            >
        </v-col>
    </v-container>
</template>

<script>
import { doc, onSnapshot } from "firebase/firestore";
import { mapGetters } from "vuex";
export default {
    name: "Holding",
    data: () => ({
        userStatus: null,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
    },
    async created() {
        // await onSnapshot(
        //     doc(
        //         this.$firebase.db,
        //         "users",
        //         this.GET_FIREBASE_userAuth.multiFactor.user.uid
        //     ),
        //     (doc) => {
        //         this.userStatus = doc.data().userStatus;
        //     }
        // );
    },
};
</script>