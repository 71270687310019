<template>
    <div
        class=""
        style="padding: 0px !important; margin: 0px !important; overflow: scroll !important"
        :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important;' : 'height: calc(100vh - 120px) !important;'"
    >
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey lighten-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>
                <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
                <div v-if="$vuetify.breakpoint.smAndDown" class="white--text text-h6 font-weight-bold pl-3">
                    <div>{{ item.contactFullName }}</div>
                    <div @click="close()" class="font-weight-normal caption"><v-icon size="15" class="white--text pa-0">oomph-back</v-icon> Return to List</div>
                </div>
                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true" :large="!dense"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="grey darken-1 white--text font-weight-bold mr-2" v-if="editmode" @click="cancelItem" :large="!dense"><v-icon class="mr-2">oomph-multiply</v-icon>Cancel</v-btn>
                    <v-btn text class="primary white--text font-weight-bold" v-if="editmode" @click="validateForm()" :large="!dense"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <!-- <pre>{{ item }}</pre> -->
        <!-- Contact Details View -->
        <v-row class="apptext--text px-3" dense v-if="!editmode && !loading">
            <v-col v-if="!editmode" cols="12" class="d-flex align-center justify-start rounded pt-8">
                <v-row style="width: 100% !important" dense>
                    <!-- Full Name / Job Title / Department / Marketing -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Names</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Full Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">
                                                    {{ item.contactTitle }} {{ item.contactFirstName }} {{ item.contactLastName }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Job Title:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.contactJobTitle }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Job Level:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.contactJobLevel }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Department:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.contactJobDepartment }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text-h6 font-weight-light">Profile Score</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>

                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important" class="mt-5 d-flex align-start justify-start">
                                <div>
                                    <v-progress-circular :rotate="-90" :size="100" :width="15" :value="computedProfileScore.score" :color="computedProfileScore.color">
                                        {{ computedProfileScore.score }} %
                                    </v-progress-circular>
                                </div>
                                <div class="ml-5">
                                    <span class="font-weight-bold" :class="computedProfileScore.color + '--text'">{{ computedProfileScore.name }}</span>
                                    <p>{{ computedProfileScore.message }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Telepjhone Numbers and Email -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Contact</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Mobile Phone:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.contactMobilePhone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">DDI Phone:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.contactDDIPhone }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Email:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">
                                                    <a :href="'mailto:' + item.contactEmail" target="_blank">{{ item.contactEmail }}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Alternate Email:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">
                                                    <a :href="'mailto:' + item.contactAlternateEmail" target="_blank">{{ item.contactAlternateEmail }}</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Address -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Address</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-map-marker</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody style="height: 100px" class="white">
                                            <tr class="d-flex white">
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Address:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">
                                                    <a
                                                        :href="`https://www.google.com/maps/search/?api=1&query=${item.contactAddressLine1},${item.contactAddressLine2},${item.contactAddressLine3},${item.contactTown},${item.contactCounty},${item.contactPostcode}`"
                                                        target="_blank"
                                                    >
                                                        <span v-if="item.contactAddressLine1"
                                                            >{{ item.contactAddressLine1 }}<span v-if="item.contactAddressLine2">, {{ item.contactAddressLine2 }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.contactAddressLine3">{{ item.contactAddressLine3 }}<br /></span>
                                                        <span v-if="item.contactTown"
                                                            >{{ item.contactTown }}<span v-if="item.contactCounty">, {{ item.contactCounty }}</span
                                                            ><br
                                                        /></span>
                                                        <span v-if="item.contactPostcode">{{ item.contactPostcode }}<br /></span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Organisation -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Organisation</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-city</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Organisation Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.orgName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Reporting To:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.contactReportingTo }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Social Networks -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Social Networks</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-people-working-together</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important" v-if="item.contactTwitter || item.contactLinkedIn || item.contactFacebook || item.contactInstagram">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr v-if="item.contactTwitter">
                                                <td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 white d-flex align-center">
                                                    <v-icon class="apptext--text mr-2">oomph-twitter</v-icon>
                                                    <div class="body-2 pointer">
                                                        <a :href="item.contactTwitter" target="_blank">{{ item.contactTwitter }}</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="item.contactLinkedIn">
                                                <td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 white d-flex align-center">
                                                    <v-icon class="apptext--text mr-2">oomph-linkedin</v-icon>
                                                    <div class="body-2">
                                                        <a :href="item.contactLinkedIn" target="_blank">{{ item.contactLinkedIn }}</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="item.contactFacebook">
                                                <td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 white d-flex align-center">
                                                    <v-icon class="apptext--text mr-2">oomph-facebook</v-icon>
                                                    <div class="body-2">
                                                        <a :href="item.contactFacebook" target="_blank">{{ item.contactFacebook }}</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="item.contactInstagram">
                                                <td colspan="2" class="pl-0 apptext--text font-weight-normal body-1 white d-flex align-center">
                                                    <v-icon class="apptext--text mr-2">oomph-instagram</v-icon>
                                                    <div class="body-2">
                                                        <a :href="item.contactInstagram" target="_blank">{{ item.contactInstagram }}</a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Metadata -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Metadata</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-info-squared</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Owner:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.ownerUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Created by:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.createdUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Created:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">
                                                    {{ MIX_formatDateTime(item.createdDateTime, 'x', 'DD-MMM-YYYY HH:mm:ss') }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Modified by:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.modifiedUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Modified:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">
                                                    {{ MIX_formatDateTime(item.modifiedDateTime, 'x', 'DD-MMM-YYYY HH:mm:ss') }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- Contact Details Edit -->
        <v-row class="px-3">
            <v-col v-if="editmode && !loading" cols="12" class="pt-4 d-flex align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- GENERAL DETAILS EDIT-->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="12" class="d-flex py-0 body-2 grey--text text--darken-2 text-left">
                                    <div>* Required Fields</div>
                                    <v-spacer />
                                    <div class="caption text-right"><strong>ID:</strong> {{ item.id }}</div>
                                </v-col>
                                <v-col cols="12" sm="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="2" class="my-1">
                                    <v-select
                                        label="Title"
                                        v-model="contactName.title"
                                        :rules="rules.orgName"
                                        :items="titles"
                                        required
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        :menu-props="{ top: false, offsetY: true }"
                                        :attach="true"
                                        @click:clear="clearContactName('contactTwitter', '')"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="5" class="my-1">
                                    <v-text-field
                                        label="First Name *"
                                        v-model="contactName.firstName"
                                        :rules="rules.contactFirstName"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearContactName('contactTwitter', '')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="5" class="my-1">
                                    <v-text-field
                                        label="Last Name *"
                                        v-model="contactName.lastName"
                                        :rules="rules.contactLastName"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearContactName('contactTwitter', '')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="12" class="my-1">
                                    <v-text-field
                                        label="Full Name *"
                                        v-model="item.contactFullName"
                                        :rules="rules.contactFullName"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactFullName', '')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field
                                        label="Email *"
                                        v-model="item.contactEmail"
                                        :rules="rules.contactEmail"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactEmail', '')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field
                                        label="Alternate Email"
                                        v-model="item.contactAlternateEmail"
                                        :rules="rules.contactAlternateEmail"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactAlternateEmail', '')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="mt-1">
                                    <v-text-field
                                        label="Mobile Phone"
                                        v-model="item.contactMobilePhone"
                                        :rules="rules.contactMobilePhone"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactMobilePhone', '')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="mt-1">
                                    <v-text-field
                                        label="Direct Dial Phone"
                                        v-model="item.contactDDIPhone"
                                        :rules="rules.contactDDIPhone"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactDDIPhone', '')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field
                                        label="Switchboard Phone"
                                        v-model="item.contactSwitchboard"
                                        :rules="rules.contactSwitchboard"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactSwitchboard', '')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field
                                        label="Extension No."
                                        v-model="item.contactExtension"
                                        :rules="rules.contactExtension"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactExtension', '')"
                                    ></v-text-field>
                                </v-col>
                                <!-- //* MARKETING UNSUBSCRIBE -->
                                <v-col cols="12" class="my-1 d-flex align-center">
                                    <v-radio-group v-model="item.contactUnsubscribe" label="Unsubscribed from mailing list:" row hide-details="auto">
                                        <v-radio label="Yes" value="0"></v-radio>
                                        <v-radio label="No" value="1"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- ORGANISATOIN EDIT -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Organisation</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleOrganisation = !toggleOrganisation">
                                        <span v-if="!toggleOrganisation">Show</span>
                                        <span v-if="toggleOrganisation">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleOrganisation">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col v-if="toggleOrganisation" cols="12" xs="12" md="6" class="my-1">
                                    <v-autocomplete
                                        label="Organisation *"
                                        v-model="selectedOrganisation"
                                        :rules="rules.contactOrganisation"
                                        :items="organisations"
                                        item-text="orgName"
                                        return-object
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearSelectedOrganisation()"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col v-if="toggleOrganisation" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field
                                        label="Reporting To"
                                        v-model="item.contactReportingTo"
                                        :rules="rules.contactReportingTo"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactReportingTo', '')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- JOB ROLE INFO EDIT -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Job Role</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleJobRole = !toggleJobRole">
                                        <span v-if="!toggleJobRole">Show</span>
                                        <span v-if="toggleJobRole">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleJobRole">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1" v-if="toggleJobRole">
                                    <v-autocomplete
                                        label="Job Level *"
                                        v-model="item.contactJobLevel"
                                        :items="jobLevels"
                                        auto-select-first
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactJobLevel', '')"
                                        autocomplete="donotautocomplete"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1" v-if="toggleJobRole">
                                    <v-autocomplete
                                        label="Department *"
                                        v-model="item.contactJobDepartment"
                                        :items="jobDepartments"
                                        auto-select-first
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearselectedJobDepartment()"
                                        autocomplete="donotautocomplete"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" md="12" class="my-1" v-if="toggleJobRole">
                                    <v-text-field
                                        label="Job Title *"
                                        v-model="item.contactJobTitle"
                                        :rules="rules.orgInvoiceEmail"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactJobTitle', '')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Assigned to</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleOwner = !toggleOwner">
                                        <span v-if="!toggleOwner">Show</span>
                                        <span v-if="toggleOwner">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleOwner">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col v-if="toggleOwner" cols="12" xs="12" :md="inputCols">
                                    <v-autocomplete
                                        label="Assigned to *"
                                        v-model="selectedOwner"
                                        :rules="rules.contactOwner"
                                        :items="assignedTo"
                                        :item-text="(item) => item.userName"
                                        auto-select-first
                                        return-object
                                        outlined
                                        :dense="dense"
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearSelectedOwner()"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- //* ADDRESS DETAILS EDIT-->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Address</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleAddress = !toggleAddress">
                                        <span v-if="!toggleAddress">Show</span>
                                        <span v-if="toggleAddress">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleAddress">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field
                                        label="Address Line 1"
                                        v-model="item.contactAddressLine1"
                                        :rules="rules.orgAddressLine1"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                    ></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field
                                        label="Address Line 2"
                                        v-model="item.contactAddressLine2"
                                        :rules="rules.orgAddressLine2"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                    ></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field
                                        label="Address Line 3"
                                        v-model="item.contactAddressLine3"
                                        :rules="rules.orgAddressLine3"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                    ></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field
                                        label="Town"
                                        v-model="item.contactTown"
                                        :rules="rules.orgTown"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                    ></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field
                                        label="County"
                                        v-model="item.contactCounty"
                                        :rules="rules.orgCounty"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                    ></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field
                                        label="Postcode"
                                        v-model="item.contactPostcode"
                                        :rules="rules.orgPostcode"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- //* LEAD STATUS EDIT-->
                        <v-col v-if="toggleAddLeadStatus || item.contactLeadStatus || item.contactSource" cols="12" sm="12" class="mb-3">
                            <v-row no-gutters>
                                <v-col cols="12" class="pb-2">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Lead Status</span>
                                </v-col>
                                <v-col v-if="toggleAddLeadStatus || item.contactLeadStatus" cols="12" xs="12" md="6" class="pr-3">
                                    <v-text-field
                                        label="Lead Status"
                                        v-model="item.contactLeadStatus"
                                        :rules="rules.orgName"
                                        required
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                    ></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddLeadStatus || item.contactSource" cols="12" xs="12" md="6">
                                    <v-text-field
                                        label="Source"
                                        v-model="item.contactSource"
                                        :rules="rules.orgName"
                                        required
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- //* SOCIAL INFO EDIT-->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Social</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleSocial = !toggleSocial">
                                        <span v-if="!toggleSocial">Show</span>
                                        <span v-if="toggleSocial">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleSocial">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>

                                <v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
                                    <v-text-field
                                        label="Twitter"
                                        v-model="item.contactTwitter"
                                        :rules="rules.orgTwitter"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactTwitter')"
                                    ></v-text-field>
                                </v-col>
                                <v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
                                    <v-text-field
                                        label="Facebook Link"
                                        v-model="item.contactFacebook"
                                        :rules="rules.orgFacebook"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactFacebook')"
                                    ></v-text-field>
                                </v-col>
                                <v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
                                    <v-text-field
                                        label="LinkedIn Profile"
                                        v-model="item.contactLinkedIn"
                                        :rules="rules.orgLinkedIn"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactLinkedIn')"
                                    ></v-text-field>
                                </v-col>
                                <v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
                                    <v-text-field
                                        label="Instagram Handle"
                                        v-model="item.contactInstagram"
                                        :rules="rules.orgInstagram"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactInstagram')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="height: 100px !important" />
                </v-form>
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-end grey lighten-1 py-4">
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading" >
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true" :large="!dense"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="grey darken-1 white--text font-weight-bold mr-2" v-if="editmode" @click="cancelItem" :large="!dense"><v-icon class="mr-2">oomph-multiply</v-icon>Cancel</v-btn>
                    <v-btn text class="primary white--text font-weight-bold" v-if="editmode" @click="validateForm()" :large="!dense"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row dense class="pl-1">
            <v-col v-if="editmode && !pu" cols="12" class="d-flex justify-start grey darken-1 py-4 px-4 pr-7" style="position: fixed; bottom: 0 !important; max-width: 49vw !important">
                <v-btn class="grey lighten-1 white--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                <v-spacer />
                <v-btn class="primary white--text font-weight-bold" depressed @click="saveItem"> <v-icon class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import schema from "@/schema.js";
export default {
    name: 'ContactDetails',
    // * PROPS
    props: {
        dense: {
            type: Boolean,
            default: false,
        },
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
        org: {
            type: Object,
            default: function () {
                return {};
            },
        },
        fullContact: {
            type: Boolean,
            default: false,
        },
        quickContact: {
            type: Boolean,
            default: false,
        },
        pu: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        assignedTo: [],
        item: {},
        newItem: true,
        key: 'contact',
        index: 'idx:contacts',
        editmode: false,
        loading: true,
        // Items
        items: [],
        itemsTotal: null,
        itemsFrom: 0,
        itemsTo: 9,

        // contactName
        contactName: {
            title: '',
            firstName: '',
            lastName: '',
        },

        activeItem: {},
        searchQuery: '',
        // dropdowns
        titles: ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr'],

        options: [],
        optionSelected: {},
        // Validation
        rules: {},

        // users
        users: [],
		schema: null,

        // Toggles
        toggleJobRole: true,
        toggleOrganisation: true,
        toggleSocial: false,
        toggleAddress: false,
        toggleSource: false,

        toggleJSON: false,
        toggleAddAddress: false,
        toggleAddOtherAddress: false,
        toggleOwner: true,
        toggleAddLeadStatus: false,
        toggleAddOrgInfo: false,
        toggleAddSocialInfo: false,

        addManualAddress: false, // TODO - RG - Is this a Toggle

        // Organisation Dropdown
        organisations: [],
        selectedOrganisation: null,
        selectedOwner: null,

        // jobRoles Dropdown
        jobLevels: [],
        jobDepartments: [],
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_dense: 'GET_dense',
            GET_FIREBASE_userData: 'firebase_auth_store/GET_FIREBASE_userData',
        }),
        inputCols() {
            if (this.listshrunk) {
                return '4';
            } else {
                return '6';
            }
        },
        // Computed Profile Score
        computedProfileScore() {
            let score = 0;
            let totalScore = 8;
            if (this.item?.contactFirstName !== '' && this.item?.contactFirstName !== undefined && this.item?.contactFirstName !== null) {
                score += 1;
            }
            if (this.item?.contactLastName !== '' && this.item?.contactLastName !== undefined && this.item?.contactLastName !== null) {
                score += 1;
            }
            if (this.item?.contactEmail !== '' && this.item?.contactEmail !== undefined && this.item?.contactEmail !== null) {
                score += 1;
            }
            if (this.item?.contactMobilePhone !== '' && this.item?.contactMobilePhone !== undefined && this.item?.contactMobilePhone !== null) {
                score += 1;
            }
            if (this.item?.contactDDIPhone !== '' && this.item?.contactDDIPhone !== undefined && this.item?.contactDDIPhone !== null) {
                score += 1;
            }
            if (this.item?.contactJobTitle !== '' && this.item?.contactJobTitle !== undefined && this.item?.contactJobTitle !== null) {
                score += 1;
            }
            if (this.item?.contactJobLevel !== '' && this.item?.contactJobLevel !== undefined && this.item?.contactJobLevel !== null) {
                score += 1;
            }
            if (this.item?.contactJobDepartment !== '' && this.item?.contactJobDepartment !== undefined && this.item?.contactJobDepartment !== null) {
                score += 1;
            }
            let scoring = Math.round((score / totalScore) * 100);
            switch (true) {
                case scoring >= 0 && scoring < 25:
                    return {
                        score: scoring,
                        color: 'error',
                        name: 'Weak',
                        message: 'This profile is weak, please add more information.',
                    };
                case scoring >= 25 && scoring < 75:
                    return {
                        score: scoring,
                        color: 'warning',
                        name: 'Intermediate',
                        message: 'This profile is ok, please add more information.',
                    };

                case scoring >= 75:
                    return {
                        score: scoring,
                        color: 'success',
                        name: 'Strong',
                        message: 'This profile is good',
                    };
                default:
                    return {
                        score: 0,
                        color: 'info',
                        name: '',
                        message: '',
                    };
            }
        },
    },
    methods: {
        validateForm() {
            this.rules = {
                contactFirstName: [(v) => !!v || 'Firstname Required'],
                contactLastName: [(v) => !!v || 'Lastname Required'],
                contactFullName: [(v) => !!v || 'Fullname Required'],
                contactEmail: [(v) => !!v || 'Email Required'],
                contactOrganisation: [(v) => !!v || 'Organisation Required'],
                contactJobTitle: [(v) => !!v || 'Job Title Required'],
                contactJobLevel: [(v) => !!v || 'Job Level Required'],
                contactJobDepartment: [(v) => !!v || 'Job Department Required'],
                contactOwner: [(v) => !!v || 'Assigned to Required'],
            };
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    this.saveItem();
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Please fill in all required fields', show: true });
                }
            });
        },
        saveItem() {
            this.item.ownerUserId = this.selectedOwner.ownerUserId;
            this.item.ownerUserName = this.selectedOwner.ownerUserName;
            this.item.ownerUserEmail = this.selectedOwner.ownerUserEmail;
            this.$emit('save', this.item);
            this.editmode = false;
        },
        cancelItem() {
            this.editmode = false;
            this.$emit('cancel');
        },
        clearSelectedOrganisation() {
            setTimeout(() => {
                this.selectedOrganisation = { id: '', orgName: '' };
            }, 1);
        },
        loadDataDown() {
            this.item = { ...this.datadown };
            console.log('datadown item = ' + JSON.stringify(this.item, null, 2))

            if (JSON.stringify(this.item) === '{}') {
                console.log('no item')
				this.item = { ...this.schema };
            }

            if (this.item.contactFirstName === '') {
                this.editmode = true;
            }
            if (this.item.orgId !== '') {
                this.selectedOrganisation = {
                    id: this.datadown.orgId,
                    orgName: this.datadown.orgName,
                };
            }
            this.contactName.title = this.item.contactTitle;
            this.contactName.firstName = this.item.contactFirstName;
            this.contactName.lastName = this.item.contactLastName;
            this.contactName.fullName = this.item.contactFirstName + ' ' + this.item.contactLastName;

            if (this.item.ownerUserId !== '' && this.item.ownerUserId !== undefined && this.item.ownerUserId !== null) {
                this.selectedOwner = { ownerUserId: this.datadown.ownerUserId, ownerUserName: this.datadown.ownerUserName, ownerUserEmail: this.datadown.ownerUserEmail };
            } else {
                this.selectedOwner = this.MIX_getCurrentOwner();
            }
        },
        // Clear Item Property
        clearItem(key, value) {
            setTimeout(() => {
                this.item[key] = value;
            }, 1);
        },
        // Clear Item Property
        clearContactName(key, value) {
            setTimeout(() => {
                this.item[key] = value;
            }, 1);
        },
        getUsers() {
            let t = this;
            t.MIX_firestore_readMany('users', 1).then((users) => {
                t.assignedTo = users.data;
            });
        },
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.loadDataDown();
            },
            deep: true,
        },
        selectedOrganisation: {
            handler: function () {
                this.item.orgId = this.selectedOrganisation.id;
                this.item.orgName = this.selectedOrganisation.orgName;
            },
            deep: true,
        },
        contactName: {
            handler: function () {
                this.item.contactTitle = this.contactName.title;
                this.item.contactFirstName = this.contactName.firstName;
                this.item.contactLastName = this.contactName.lastName;
                this.item.contactFullName = this.contactName.title + ' ' + this.contactName.firstName + ' ' + this.contactName.lastName;
                this.item.contactFullName.trim();
            },
            deep: true,
        },
        pu: {
            handler: function () {
                if (this.pu === true) {
                    this.editmode = true;
                }
                // console.log(this.pu + ' ' + this.editmode);
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        let t = this;
        t.loading = true;
		t.schema = schema.contact;
        // Get Organisations
        t.organisations = await t.MIX_organisations();
        // Job Levels
        let jobLevels = await t.MIX_redisReadSet('set:jobLevels', false);
        if (jobLevels.code === 1) {
            t.jobLevels = jobLevels.data;
        }
        if (this.pu === true) {
            this.editmode = true;
        }
        // Departments
        let jobDepartments = await t.MIX_redisReadSet('set:jobDepartments', false);
        if (jobDepartments.code === 1) {
            t.jobDepartments = jobDepartments.data;
        }
        // Address Types
        let addressTypes = await t.MIX_redisReadSortedSet('sset:addressTypes', 0, 9999);
        if (addressTypes.code === 1) {
            t.addressTypes = addressTypes.data;
        }
        // Industries
        let industries = await t.MIX_redisReadSet('set:industries', false);
        if (industries.code === 1) {
            t.industries = industries.data;
        }
        // Load the Data into Item
        this.loadDataDown();
        t.loading = false;
    },
    mounted() {
        let t = this;
        t.getUsers();
        t.selectedOwner = t.GET_FIREBASE_userAuth.displayName;
    },
};
</script>
