<template>
    <v-row fluid fill-height class="d-flex flex-column align-start appbackground" style="height: 100% !important" no-gutters>
		<v-col class="align-center pa-3 mt-1" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">            
			<!-- Quiz Header -->
			<div class="d-flex align-center mb-1">
				<!-- Heading -->
				<div class="d-flex align-center">
					<div><v-icon class="primary--text" x-large>oomph-index</v-icon></div>
					<div class="text-left primary--text pl-1 text-h4 font-weight-thin text-capitalize">Review Questions</div>
				</div>
				<v-spacer></v-spacer>
				<!-- New Quiz Button-->
				<div class="ml-1">
                    <v-btn depressed block class="primary darken-2 white--text" @click="openReviewQuestion({})" >
                        <v-icon>oomph-index</v-icon>
                        <span v-if="!listShrunk" class="ml-3">New</span>
                    </v-btn>
                </div>
				<!-- Other Buttons -->
				<div>
					<v-btn class="white--text primary ml-2" @click="listShrunk = false" depressed v-if="listShrunk">
						<v-icon color="white">oomph-show-sidepanel</v-icon>
					</v-btn>
					<v-btn class="white--text primary ml-2" @click="listShrunk = true" depressed v-if="!listExpanded && !listShrunk">
						<v-icon color="white">oomph-hide-sidepanel</v-icon>
					</v-btn>
				</div>
			</div>            
			<!-- Quiz List -->
            <reviewquestion-list :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:open="openReviewQuestion" :refreshlist="refreshListKey" />		
        </v-col>
		<!-- Quiz Component -->
		<transition v-if="activeReviewQuestions" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col style="border-left: #239eaf solid 1px !important; height: 100% !important" v-if="!listExpanded" class="grey lighten-3 pa-0 ma-0" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
                <reviewquestion v-on:refreshlist="refreshList" :listshrunk="listShrunk" :activereviewquestion="activeReviewQuestions" v-on:close="closeReviewQuestion"/>
			</v-col>
		</transition>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import ReviewQuestion from '@/views/reviews/ReviewQuestion.vue';
import ReviewQuestionList from '@/views/reviews/ReviewQuestionList.vue'
export default {
    name: 'ReviewQuestions',
    components: {
        'reviewquestion': ReviewQuestion,
        'reviewquestion-list': ReviewQuestionList
    },
    data: () => ({
        refreshListKey: 0,
        activeReviewQuestions: {},
		// Item Columns
		itemsCols: { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12,},
		itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
		taskCols: {	cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2,},
		listExpanded: true,
		listShrunk: false,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
        }),
    },
    methods: {
		refreshList() {
			this.refreshListKey++
		},
		refreshCols() {
			switch (true) {
				// Expanded List with No Task Bar
				case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Expanded List with Task Bar
				case this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
					this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// List with Product Page (No Task Bar)
				case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// List with Product Page and Task Bar
				case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
					this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				// Shrunk List with Product Page (No Task Bar)
				case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }; // 2
					this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 }; // 10
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
					break;
				// Shrunk List with Product Page and Task Bar)
				case !this.listExpanded && this.listShrunk && this.GET_taskBar:
					this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
					break;
				default:
					this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 12
					this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
					this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
			}
		},
		openReviewQuestion(reviewquestion) {
			// //console.log('reviewquestion = ' + JSON.stringify(reviewquestion, null, 2))
			this.activeReviewQuestions = reviewquestion;
			this.listExpanded = false;
		},
		closeReviewQuestion() {
			this.listExpanded = true;
			this.listShrunk = false;
		},
    },
	watch: {
		listExpanded() { this.refreshCols() },
		listShrunk() { this.refreshCols() },
	},
};
</script>
