import moment from "moment";
import { mapActions, mapGetters } from "vuex";

const mixin = {
    data: () => ({
        apiUrl: process.env.VUE_APP_OOMPHSERVER,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
            GET_FIREBASE_apiHeaderToken: 'firebase_auth_store/GET_FIREBASE_apiHeaderToken',
        }),
        apiOptions() {
            return {
                headers: {
                    Authorization: `Bearer ${this.GET_FIREBASE_apiHeaderToken}`,
                },
            };
        },
    },
    methods: {
        ...mapActions({
            ACT_alertBox: "ACT_alertBox",
            ACT_puDialog: 'ACT_puDialog'
        }),
        // * CHANGE ROUTE FUNCTION
        MIX_go(path) {
            this.$router.push(path).catch((err) => {
                console.log("Route error: " + err);
            });
        },
        // * CHANGE ROUTE FUNCTION
        MIX_goExternal(path) {
            window.open(path, '_blank').focus();
        },
        // * FORMAT DATE TIME
        MIX_formatDateTime: function (date, formatIn, formatOut) {
            if (date !== "" && date !== null && date !== undefined && date != 0) {
                return moment(date, formatIn).format(formatOut);
            } else {
                return '';
            }
        },
        MIX_addFormatDateTime: function (data, formatIn, formatOut, timeValue, timeUnit) {
            if (data !== "" && data !== null && data !== undefined) {
                return moment(data, formatIn).add(timeValue, timeUnit).format(formatOut);
            } else {
                return moment().add(timeValue, timeUnit).format(formatOut);
            }
        },
        MIX_fromNow: function (date, formatIn, ago) {
            if (date !== "" && date !== null && date !== undefined) {
                return moment(date, formatIn).fromNow(ago);
            } else {
                return '';
            }
        },
        MIX_alertBox: function (payload) {
            this.ACT_alertBox(payload);
        },
        MIX_puDialog(show, dynamicComponent, width, height) {
            var payload = {};
            payload.show = show;
            payload.dynamicComponent = dynamicComponent;
            payload.width = width;
            payload.height = height;
            this.ACT_puDialog(payload);
        },
        MIX_updateApp: function () {
            alert("Update App");
        },
        MIX_generateId() {
            let generatedId = "";
            const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Alphanumeric characters
            for (let i = 0; i < 20; i++) {
                generatedId += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return generatedId;
        },
        MIX_organisations: async function (type, orgIds = "") {
            var result
            if ((type !== undefined) && (type !== null) && (type !== "")) {
                result = await this.MIX_redisSearch("idx:orgs", `@deleted:0 @orgAccountTypes:{${type}} ${orgIds}`, 0, 9999);
            } else {
                result = await this.MIX_redisSearch("idx:orgs", `@deleted:0 ${orgIds}`, 0, 9999);
            }
            if (result.code === 1) {
                return result.data.documents.map((x) => {
                    return {
                        id: x.value.id,
                        orgName: x.value.orgName,
                        orgAccountType: x.value.orgAccountTypes,
                        orgLogo: x.value.orgLogo,
                        orgDivision: x.value.orgDivisionName || "",
                    };
                });
            }
        },
        MIX_contacts: async function (orgId) {
            var result
            if ((orgId !== undefined) && (orgId !== null) && (orgId !== "")) {
                result = await this.MIX_redisSearch("idx:contacts", `@deleted:0 @orgId:${orgId}`, 0, 9999); // TODO - RG - Need to add @enabled to schema
            } else {
                result = await this.MIX_redisSearch("idx:contacts", `@deleted:0`, 0, 9999);
            }
            if (result.code === 1) {
                return result.data.documents.map((x) => {
                    return {
                        id: x.value.id,
                        contactName: x.value.contactFirstName + " " + x.value.contactLastName,
                        contactEmail: x.value.contactEmail,
                        orgId: x.value.orgId
                    };
                });
            }
        },
        // Users from Cache
        MIX_components: async function () {
            var result
            result = await this.MIX_redisSearch("idx:components", `@deleted:0`, 0, 9999);
            if (result.code === 1) {
                return result.data.documents.map(x => x.value)
            }
        },
        // Users from Cache
        MIX_users: async function (userGroup) {
            var result
            if ((userGroup !== undefined) && (userGroup !== null) && (userGroup !== "")) {
                let userGroupArray = userGroup.split(",");
                let userGroupString = userGroupArray.join("|");
                result = await this.MIX_redisSearch("idx:users", `@deleted:0 @userGroup:{${userGroupString}}`, 0, 9999); // TODO - RG - Need to add @enabled to schema
            } else {
                result = await this.MIX_redisSearch("idx:users", `@deleted:0`, 0, 9999);
            }
            if (result.code === 1) {
                return result.data.documents.map(x => x.value)
            }
        },
        // Get Current Owner - used to populated current owner on new forms
        MIX_getCurrentOwner: function () {
            return {
                ownerUserId: this.GET_FIREBASE_userAuth.uid,
                ownerUserName: this.GET_FIREBASE_userAuth.displayName,
                ownerUserEmail: this.GET_FIREBASE_userAuth.email
            }
        },
        // CRUD Actions
        // Save Item
        MIX_createItem: async function (item, itemType, newItem) {
            let t = this;
            try {
                let dateTimeNow = t.$moment().format("x");
                if (newItem) {
                    item.createdDateTime = dateTimeNow;
                    item.createdUserId = t.GET_FIREBASE_userAuth.uid;
                    item.createdUserName = t.GET_FIREBASE_userAuth.displayName;
                    item.createdUserEmail = t.GET_FIREBASE_userAuth.email;
                }
                item.modifiedDateTime = dateTimeNow;
                item.modifiedUserId = t.GET_FIREBASE_userAuth.uid;
                item.modifiedUserName = t.GET_FIREBASE_userAuth.displayName;
                item.modifiedUserEmail = t.GET_FIREBASE_userAuth.email;
                item.deletedDateTime = '0';
                let result = await t.MIX_redisCreateHash(`${itemType}:${item.id}`, item);
                if (result.code === 1) {
                    return result;
                } else {
                    return result;
                }
            } catch (error) {
                console.log(error);
            }
        },
        // Read Item
        MIX_readItem: async function (itemId, key) {
            let readItemResult = await this.MIX_redisReadHash(`${key}:${itemId}`);
            if (readItemResult.code === 1) {
                return readItemResult;
            } else {
                this.MIX_alertBox({ color: "error", timeout: 4000, message: "Error Reading Item", show: true });
            }
        },
        // Destroy Item
        MIX_destroyItem: async function (itemId, itemType) {
            let t = this;
            try {
                let result = await this.MIX_redisDestroyHash(`${itemType}:${itemId}`);
                if (result.code === 1) {
                    this.MIX_alertBox({ color: "success", message: "Permenantly Deleted", timeout: 2000, show: true });
                    t.$emit("refreshItems");
                    return result;
                } else {
                    this.MIX_alertBox({ color: "error", message: "Error Deleting", timeout: 4000, show: true });
                    console.log("error: " + result.error);
                }
            } catch (error) {
                t.MIX_alertBox({ color: "error", timeout: 4000, message: "Error Deleting", show: true });
                console.log(error);
            }
        },
        // Delete Item
        MIX_deleteItem: async function (itemId, key) {
            let t = this;
            try {
                let fields = {};
                let dateTimeNow = t.$moment().format("x");
                fields.deleted = "1";
                fields.modifiedDateTime = dateTimeNow;
                fields.modifiedUserId = t.GET_FIREBASE_userAuth.uid;
                fields.modifiedUserName = t.GET_FIREBASE_userAuth.displayName;
                fields.modifiedUserEmail = t.GET_FIREBASE_userAuth.email;
                fields.deletedDateTime = dateTimeNow;
                fields.deletedUserId = t.GET_FIREBASE_userAuth.uid;
                fields.deletedUserName = t.GET_FIREBASE_userAuth.displayName;
                fields.deletedUserEmail = t.GET_FIREBASE_userAuth.email;
                let result = await this.MIX_redisUpdateHash(`${key}:${itemId}`, fields);
                if (result.code === 1) {
                    this.MIX_alertBox({ color: "success", message: "Deleted", timeout: 2000, show: true });
                    t.$emit("refreshItems");
                    return result;
                } else {
                    this.MIX_alertBox({ color: "error", message: "Error Deleting", timeout: 4000, show: true });
                    console.log("error: " + result.error);
                }
            } catch (error) {
                t.MIX_alertBox({ color: "error", timeout: 4000, message: "Error Deleting", show: true });
                console.log(error);
            }
        },
        // Restore Item
        MIX_restoreItem: async function (itemId, key) {
            let t = this;
            try {
                let fields = {};
                let dateTimeNow = t.$moment().format("x");
                fields.deleted = "0";
                fields.modifiedDateTime = dateTimeNow;
                fields.modifiedUserId = t.GET_FIREBASE_userAuth.uid;
                fields.modifiedUserName = t.GET_FIREBASE_userAuth.displayName;
                fields.modifiedUserEmail = t.GET_FIREBASE_userAuth.email;
                fields.deletedDateTime = "0";
                fields.deletedUserId = "";
                fields.deletedUserName = "";
                fields.deletedUserEmail = "";
                let result = await this.MIX_redisUpdateHash(`${key}:${itemId}`, fields);
                if (result.code === 1) {
                    this.MIX_alertBox({ color: "success", message: "Item has been restored", timeout: 2000, show: true });
                    t.$emit("refreshItems");
                    return result;
                } else {
                    this.MIX_alertBox({ color: "error", message: "Error restoring item", timeout: 4000, show: true });
                    console.log("error: " + result.error);
                }
            } catch (error) {
                this.MIX_alertBox({ color: "error", message: "Error restoring item", timeout: 4000, show: true });
                console.log(error);
            }
        },
        MIX_generatePDF: async function (url) {
            return await fetch(this.apiUrl + '/createPdf/create', {
                method: 'POST',
                body: JSON.stringify({ url: url }),
                headers: {
                    Authorization: `Bearer ${this.GET_FIREBASE_apiHeaderToken}`,
                },
            }).then(response => {
                return response
            })
        },
        MIX_generateLiveStreamURL: async function () {
            var t = this;
            return t.$axios.post(t.apiUrl + `/video/live-streams/`, {}, t.apiOptions)
                .then(response => {
                    return response.data
                })
        },
        MIX_getLiveStreamDownload: async function (limit, id) {
            var t = this;
            return t.$axios.get(t.apiUrl + `/video/assets?limit=${limit}&live_stream_id=${id}`, t.apiOptions)
                .then(response => {
                    return response.data
                })
        },
        MIX_sendEmail: async function (email) {
            return await fetch(this.apiUrl + `/sendEmail?to=${email.to}`, {
                method: 'POST',
                body: JSON.stringify({ subject: email.subject, body: email.body, attachment: email.attachment }),
                headers: {
                    Authorization: `Bearer ${this.GET_FIREBASE_apiHeaderToken}`,
                },
            }).then(response => {
                return response
            })
        },
        MIX_bytesToSize: function (bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },
        MIX_googlePlacesConversion(placeResultData) {
            placeResultData = JSON.parse(placeResultData);
            console.log('placeResultData', JSON.stringify(placeResultData, null, 2));
            let result = {}
            result.name = placeResultData.name;
            let premise = placeResultData.address_components.filter((component) => component.types.includes('premise'));
            let street_number = placeResultData.address_components.filter((component) => component.types.includes('street_number'));
            let route = placeResultData.address_components.filter((component) => component.types.includes('route'));
            let sublocality_level_1 = placeResultData.address_components.filter((component) => component.types.includes('sublocality_level_1'));
            let sublocality_level_2 = placeResultData.address_components.filter((component) => component.types.includes('sublocality_level_2'));
            let locality = placeResultData.address_components.filter((component) => component.types.includes('locality'));
            let administrative_area_level_2 = placeResultData.address_components.filter((component) => component.types.includes('administrative_area_level_2'));
            let country = placeResultData.address_components.filter((component) => component.types.includes('country'));
            let postal_code = placeResultData.address_components.filter((component) => component.types.includes('postal_code'));
            let postal_town = placeResultData.address_components.filter((component) => component.types.includes('postal_town'));
            let formatted_phone_number = placeResultData.formatted_phone_number;

            var addressLine1 = '';
            var addressLine2 = '';
            // Address Line 1
            if (JSON.stringify(premise) !== '[]') {
                addressLine1 = premise[0]['long_name'];
            }
            if (JSON.stringify(street_number) !== '[]') {
                addressLine1 = addressLine1 + ' ' + street_number[0]['long_name'];
            }
            if (JSON.stringify(route) !== '[]') {
                addressLine1 = addressLine1 + ' ' + route[0]['long_name'];
            }
            if (addressLine1 !== null && addressLine1 !== undefined && addressLine1 !== '') {
                result.addressLine1 = addressLine1.trim();
            } else {
                result.addressLine1 = placeResultData.formatted_address.split(',')[0];
            }
            // Address Line 2
            if (JSON.stringify(sublocality_level_1) !== '[]') {
                addressLine2 = sublocality_level_1[0]['long_name'];
            }
            if (JSON.stringify(sublocality_level_2) !== '[]') {
                addressLine2 = addressLine2 + ' ' + sublocality_level_2[0]['long_name'];
            }
            if (JSON.stringify(locality) !== '[]') {
                addressLine2 = addressLine2 + ' ' + locality[0]['long_name'];
            }
            if (addressLine2 !== null && addressLine2 !== undefined && addressLine2 !== '') {
                result.addressLine2 = addressLine2.trim();
            }
            // Address Line 3
            result.AddressLine3 = '';
            // Check for Postal Town
            if (JSON.stringify(postal_town) !== '[]') {
                result.town = postal_town[0]['long_name'];
            }
            // Check for County
            if (JSON.stringify(administrative_area_level_2) !== '[]') {
                result.county = administrative_area_level_2[0]['long_name'];
            }
            // Check for Country
            if (JSON.stringify(country) !== '[]') {
                result.country = country[0]['long_name'];
            }
            // Check for postcode
            if (JSON.stringify(postal_code) !== '[]') {
                result.postcode = postal_code[0]['long_name'];
            }
            // Add LAttitude and Longitude
            console.log('lat = ', placeResultData.geometry);
            result.lat = placeResultData.geometry.location.lat;
            result.lng = placeResultData.geometry.location.lng;
            // Check for Phone Number
            if (formatted_phone_number !== null && formatted_phone_number !== undefined && formatted_phone_number !== '') {
                result.elephone = formatted_phone_number.replace(/\s/g, '');
            }
            // Add Website
            result.website = placeResultData.website;
            return result;
        },
        async MIX_contentStats(data) {
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/stats/content`, data, t.apiOptions)
                    .then(response => {
                        return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats content", data: null, error: error };
            }
        },

        // * Redis get Likes for content
        async MIX_getLikes(data) {
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/stats/likes?contentId=` + data.contentId, t.apiOptions)
                    .then(response => {
                        return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not read likes", data: null, error: error };
            }
        },

        // * Redis get Dislikes for content
        async MIX_getDislikes(data) {
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/stats/dislikes?contentId=` + data.contentId, t.apiOptions)
                    .then(response => {
                        return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not read likes", data: null, error: error };
            }
        },
        // * Redis Add like to Content
        async MIX_addLike(data) {
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/stats/likes/add`, data, t.apiOptions)
                    .then(response => {
                        return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not add to set", data: null, error: error };
            }
        },
        // * Redis Add dislike to Content
        async MIX_addDislike(data) {
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/stats/dislikes/add`, data, t.apiOptions)
                    .then(response => {
                        return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not add to set", data: null, error: error };
            }
        },

        // * Redis Remove like to Content
        async MIX_removeLike(data) {
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/stats/likes/remove`, data, t.apiOptions)
                    .then(response => {
                        return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not add to set", data: null, error: error };
            }
        },
        // * Redis Remove dislike to Content
        async MIX_removeDislike(data) {
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/stats/dislikes/remove`, data, t.apiOptions)
                    .then(response => {
                        return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not add to set", data: null, error: error };
            }
        },
        // * Redis Add view to Content
        async MIX_addView(data) {
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/stats/views/add`, data, t.apiOptions)
                    .then(response => {
                        return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not add view to set", data: null, error: error };
            }
        },

        async MIX_reviewCount(data) {
            let t = this;
            try {
                return t.$axios.post(t.apiUrl + `/stats/reviewcount`, data, t.apiOptions)
                    .then(response => {
                        return response.data
                    })
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats", data: null, error: error };
            }
        },

        //* Stats
        async MIX_statsCount(data) {
            let t = this;
            try {
                return t.$axios.post(`${t.apiUrl}/stats/statscount`, data, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats", data: null, error: error };
            }
        },
        async MIX_loginCount(data) {
            let t = this;
            try {
                return t.$axios.post(`${t.apiUrl}/stats/logincount`, data, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats", data: null, error: error };
            }
        },
        async MIX_lastlogin(data) {
            let t = this;
            try {
                return t.$axios.post(`${t.apiUrl}/stats/lastlogin`, data, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats", data: null, error: error };
            }
        },
        async MIX_reviewCount(data) {
            let t = this;
            try {
                return t.$axios.post(`${t.apiUrl}/stats/reviewcount`, data, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats", data: null, error: error };
            }
        },
        async MIX_count(data) {
            let t = this;
            try {
                return t.$axios.post(`${t.apiUrl}/stats/count`, data, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats", data: null, error: error };
            }
        },
        async MIX_loginUsers(data) {
            let t = this;
            try {
                return t.$axios.post(`${t.apiUrl}/stats/loginusers`, data, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats", data: null, error: error };
            }
        },
        async MIX_views(data) {
            let t = this;
            try {
                return t.$axios.post(`${t.apiUrl}/stats/views`, data, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats", data: null, error: error };
            }
        },
        async MIX_viewsOrgs(data) {
            let t = this;
            try {
                return t.$axios.post(`${t.apiUrl}/stats/views/orgs`, data, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats", data: null, error: error };
            }
        },
        async MIX_downloads(data) {
            let t = this;
            try {
                return t.$axios.post(`${t.apiUrl}/stats/downloads2`, data, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats", data: null, error: error };
            }
        },
        async MIX_downloadsOrgs(data) {
            let t = this;
            try {
                return t.$axios.post(`${t.apiUrl}/stats/downloads2/orgs`, data, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats", data: null, error: error };
            }
        },
        async MIX_reviews(data) {
            let t = this;
            try {
                return t.$axios.post(`${t.apiUrl}/stats/reviews`, data, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats", data: null, error: error };
            }
        },
        // Residents Stats - Residents Count
        async MIX_residentCount(orgId, condition) {
            let t = this;
            let url = "";
            try {
                if (condition) {
                    url = `${t.apiUrl}/stats/residentscount?orgid=${orgId}&condition=${condition}`;
                } else {
                    url = `${t.apiUrl}/stats/residentscount?orgid=${orgId}`;
                }
                return t.$axios.get(url, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get stats", data: null, error: error };
            }
        },
        // Residents Stats - Residents Activities
        async MIX_residentsActivities(orgId, startDate, endDate) {
            let t = this;
            let url = "";
            try {
                if (startDate && endDate) {
                    url = `${t.apiUrl}/stats/residentsactivities?orgid=${orgId}&startdate=${startDate}&enddate=${endDate}`;
                } else {
                    url = `${t.apiUrl}/stats/residentsactivities?orgid=${orgId}`;
                }
                return t.$axios.get(url, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get residents activities stats", data: null, error: error };
            }
        },
        // Residents Stats - Residents Attendance
        async MIX_residentsAttendance(orgId, startDate, endDate) {
            let t = this;
            let url = "";
            try {
                if (startDate && endDate) {
                    url = `${t.apiUrl}/stats/residentsattendance?orgid=${orgId}&startdate=${startDate}&enddate=${endDate}`;
                } else {
                    url = `${t.apiUrl}/stats/residentsattendance?orgid=${orgId}`;
                }
                return t.$axios.get(url, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get resident attendance stats", data: null, error: error };
            }
        },
        // Residents Stats - Residents Average Attendance
        async MIX_residentsAvgAttendance(orgId, startDate, endDate) {
            let t = this;
            let url = "";
            try {
                if (startDate && endDate) {
                    url = `${t.apiUrl}/stats/residentsavgattendance?orgid=${orgId}&startdate=${startDate}&enddate=${endDate}`;
                } else {
                    url = `${t.apiUrl}/stats/residentsavgattendance?orgid=${orgId}`;
                }
                return t.$axios.get(url, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get resident average attendance stats", data: null, error: error };
            }
        },
        // Residents Stats - Residents Duration
        async MIX_residentsDuration(orgId, startDate, endDate) {
            let t = this;
            let url = "";
            try {
                if (startDate && endDate) {
                    url = `${t.apiUrl}/stats/residentsduration?orgid=${orgId}&startdate=${startDate}&enddate=${endDate}`;
                } else {
                    url = `${t.apiUrl}/stats/residentsduration?orgid=${orgId}`;
                }
                return t.$axios.get(url, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get resident duration stats", data: null, error: error };
            }
        },
        // Residents Stats - Residents Engagement
        async MIX_residentsEngagement(orgId, startDate, endDate, residentId) {
            let t = this;
            let url = "";
            try {
                if (startDate && endDate) {
                    url = `${t.apiUrl}/stats/residentsengagement?orgid=${orgId}&startdate=${startDate}&enddate=${endDate}`;
                } else {
                    url = `${t.apiUrl}/stats/residentsengagement?orgid=${orgId}`;
                }
                if (residentId) {
                    url = `${url}&residentid=${residentId}`;
                }
                return t.$axios.get(url, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get resident engagement stats", data: null, error: error };
            }
        },
        // Residents Stats - Residents Average Engagement Score
        async MIX_residentsAvgEngagement(orgId, startDate, endDate, residentId) {
            let t = this;
            let url = "";
            try {
                if (startDate && endDate) {
                    url = `${t.apiUrl}/stats/residentsavgengagement?orgid=${orgId}&startdate=${startDate}&enddate=${endDate}`;
                } else {
                    url = `${t.apiUrl}/stats/residentsavgengagement?orgid=${orgId}`;
                }
                if (residentId) {
                    url = `${url}&residentid=${residentId}`;
                }
                return t.$axios.get(url, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get resident avg engagement stats", data: null, error: error };
            }
        },
        // Residents Stats - Resident Duration
        async MIX_residentDuration(orgId, startDate, endDate, residentId) {
            let t = this;
            let url = "";
            try {
                if (startDate && endDate) {
                    url = `${t.apiUrl}/stats/residentduration?orgid=${orgId}&startdate=${startDate}&enddate=${endDate}`;
                } else {
                    url = `${t.apiUrl}/stats/residentduration?orgid=${orgId}`;
                }
                if (residentId) {
                    url = `${url}&residentid=${residentId}`;
                }
                return t.$axios.get(url, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get resident duration stats", data: null, error: error };
            }
        },
        // Top 10 trending stats based on views
        async MIX_topTenTrending(index, contentArea, startDate, endDate) {
            let t = this;
            let url = "";
            try {
                if (startDate && endDate) {
                    url = `${t.apiUrl}/stats/trendingcontent?index=${index}&contentArea=${contentArea}&startDate=${startDate}&endDate=${endDate}`;
                }
                return t.$axios.get(url, t.apiOptions).then((response) => {
                    return response.data;
                });
            } catch (error) {
                return { code: -1, mesage: "Error: could not get trending stats", data: null, error: error };
            }
        },

        // Recurring Events 
        MIX_createRecurring: async function (item, newItem) {
            let t = this;
            try {
                let dateTimeNow = t.$moment().format("x");
                if (newItem) {
                    item.createdDateTime = dateTimeNow;
                    item.createdUserId = t.GET_FIREBASE_userAuth.uid;
                    item.createdUserName = t.GET_FIREBASE_userAuth.displayName;
                    item.createdUserEmail = t.GET_FIREBASE_userAuth.email;
                }
                item.modifiedDateTime = dateTimeNow;
                item.modifiedUserId = t.GET_FIREBASE_userAuth.uid;
                item.modifiedUserName = t.GET_FIREBASE_userAuth.displayName;
                item.modifiedUserEmail = t.GET_FIREBASE_userAuth.email;
                item.deletedDateTime = '0';
                let url = `${t.apiUrl}/createRecurringEvent`;
                return t.$axios.post(t.apiUrl + `/createRecurringEvent`, item, t.apiOptions)
                    .then(response => {
                        return response.data
                    })
            } catch (error) {
                console.log(error);
            }
        },

        MIX_updateRecurring: async function (item) {
            let t = this;
            try {
                let dateTimeNow = t.$moment().format("x");
                item.modifiedDateTime = dateTimeNow;
                item.modifiedUserId = t.GET_FIREBASE_userAuth.uid;
                item.modifiedUserName = t.GET_FIREBASE_userAuth.displayName;
                item.modifiedUserEmail = t.GET_FIREBASE_userAuth.email;
                let url = `${t.apiUrl}/editRecurringEvent`;
                return t.$axios.post(t.apiUrl + `/editRecurringEvent`, item, t.apiOptions)
                    .then(response => {
                        return response.data
                    })
            } catch (error) {
                console.log(error);
            }
        },

        MIX_deleteRecurring: async function (item) {
            let t = this;
            try {
                let dateTimeNow = t.$moment().format("x");
                item.deletedDateTime = dateTimeNow;
                item.deletedUserId = t.GET_FIREBASE_userAuth.uid;
                item.deletedUserName = t.GET_FIREBASE_userAuth.displayName;
                item.deletedUserEmail = t.GET_FIREBASE_userAuth.email;
                item.deleted = '1';
                let url = `${t.apiUrl}/deleteRecurringEvent`;
                return t.$axios.post(t.apiUrl + `/deleteRecurringEvent`, item, t.apiOptions)
                    .then(response => {
                        return response.data
                    })
            } catch (error) {
                console.log(error);
            }
        }
    },
};

export default {
    install(Vue) {
        Vue.mixin(mixin);
    },
};
