import { render, staticRenderFns } from "./LeadCustomerOpportunities.vue?vue&type=template&id=915a1e82&"
import script from "./LeadCustomerOpportunities.vue?vue&type=script&lang=js&"
export * from "./LeadCustomerOpportunities.vue?vue&type=script&lang=js&"
import style0 from "./LeadCustomerOpportunities.vue?vue&type=style&index=0&id=915a1e82&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports