<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
       
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <v-col v-if="!editmode && !loading" cols="12" class="d-flex pa-2 align-center">

                <!-- //* VIEW MODE -->
                <v-row style="width: 100% !important" no-gutters>
                    <!-- //* GENERAL DETAILS VIEW -->
                    <v-col cols="12" sm="12">
                        <v-col  cols="12" xs="12">
                            <div class="pb-2">Please select the Home you would like to view Stats for:</div>
                            <v-autocomplete label="Poll Available To" @change="getHomeAnswers" v-model="selectedOrganisation" :items="organisations" item-text="orgName" auto-select-first return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedAvailableToOrg()">
                            </v-autocomplete>
                        </v-col>

                        <v-row class="white rounded pa-3 mt-2 mx-3" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Poll Results</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-chart-bar</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <div  class="d-flex flex-column align-center justify-center pa-4">
                                    <v-card class="d-flex flex-column align-center white text-start black--text px-2 py-1 ma-1 mb-4 elevation-0 rounded-lg" width="100" height="100">
                                        <v-card-text class="text-center pa-0">Total polls</v-card-text>
                                        <v-card-title class="oomph-font-h4">{{ allPollAnswers.length }}</v-card-title>
                                    </v-card>
                                    <v-card class="rounded-lg" elevation="0" height="300px" width="100%">
                                        <v-card-title class="oomph-font-body-2 font-weight-bold text-uppercase primary--text d-flex justify-start align-center">
                                            <div>Answers</div>
                                            <v-spacer />
                                            <div><v-icon color="secondary">icons8-downloads-folder</v-icon></div>
                                        </v-card-title>
                                        <v-card-text class="d-flex align-start justify-start flex-column" style="width: 100% !important">
                                            <bar-chart :seriesdata="getAllPollAnswers()" :color="$vuetify.theme.themes.light.primary" seriestext="Downloads" />
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
import BarChart from '@/components/echarts/BarChart.vue';
import { mapGetters } from 'vuex';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        // activeitem: {
        //     Type: Object,
        //     Default: null,
        // },
    },
    components: {
        BarChart,
    },
    // * DATA
    data: () => ({
        key: 'poll',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        // dropdowns
        pollStatus: [],
        
        // new address object
        item: {},
        loading: true,
        rules: {},

        answer: '',
        answers: [],
        allPollAnswers: [],

        organisations: [],
        selectedOrganisations: [],
        selectedOrganisation: [],

        selectAllOrgToggle: false,
        poll: [],

    }),
    computed: {  
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken'
        }),
    },
    methods: {
        selectAllOrgs() {
            if (this.selectAllOrgToggle === true) {
                this.selectedOrganisation = this.organisations
            } else if (this.selectAllOrgToggle === false) {
                this.selectedOrganisation = []
            }
        },
        getSelectedOrganisations() {
            var t = this

            this.selectedOrganisations = []
            var orgs = t.item.parentAvailableTo.split(",")

            for (let i=0; i < orgs.length; i++) {
                var org = this.organisations.filter(function(item) {
                    return item.id == orgs[i]
                })
                this.selectedOrganisation.push(org[0]);
            }
        },
        initialise() {
            this.item = { ...this.datadown };
            //console.log('item', JSON.stringify(this.item, null, 2));
            
            if (this.item?.pollName && this.item?.pollName !== "") {
                this.itemNew = false;
                this.editmode = false;
                this.answers = JSON.parse(this.item.pollAnswers);
                this.getAnswers(this.item.id);
                this.getSelectedOrganisations()
            } else {
                this.itemNew = true;
                this.editmode = true;
            }
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        async getStatus() {
            let t = this;
            let pollStatus = await t.MIX_redisReadSet('set:userStatuses', false);
            if (pollStatus.code === 1) {
                t.pollStatus = pollStatus.data;
            }
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.item.pollAnswers = JSON.stringify(this.answers)

            // for each org in selectedOrganisation, create duplcicate, add parentPollDetails and orgDetails, and save
            for ( let i = 0; i < this.selectedOrganisation.length; i++) {
                var duplicate = {...this.item }
                duplicate.id = this.MIX_generateId()
                duplicate.parentPoll = '0';
                duplicate.parentPollId = this.item.id 
                duplicate.orgId = this.selectedOrganisation[i].id 
                duplicate.orgName = this.selectedOrganisation[i].orgName 
                duplicate.orgLogo = this.selectedOrganisation[i].orgLogo
                duplicate.orgGroupId = this.selectedOrganisation[i].orgGroupId 

                //console.log("duplicate = " + JSON.stringify(duplicate, null, 2))

                // save duplicate 

            }   
            // add orgIds from selectedOrgs to parentAvailableTo

            let orgInfo = await t.MIX_redisSearch('idx:orgs', `@id:${this.GET_FIREBASE_userAccessToken.org_id}`, 0, 9999);
            if (orgInfo.code === 1) {
                var org = orgInfo.data.documents.map((x) => x.value);
                org = org[0]
            }


            this.item.orgId = this.GET_FIREBASE_userAccessToken.org_id


            // this.$emit('save', this.item);
            // this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        removeAnswer(index) {
            let t = this;
            t.answers.splice(index);
        },
        addAnswer() {
            let t = this;
            t.answers.push(t.answer);
            t.answer = '';
        },
        async getHomeAnswers() {
            var t = this;
            //console.log("getting answers for this home: " + JSON.stringify(this.selectedOrganisation, null, 2))
            //console.log("parentPoll = " + this.item.id)
            let getHomePoll = await t.MIX_redisSearch('idx:polls', `@orgId:{${this.selectedOrganisation.id}} @parentPollId:${this.item.id}`, 0, 9999)
            if (getHomePoll.code === 1) {
                t.poll = []
                t.poll = getHomePoll.data.documents.map((x) => x.value);
                //console.log("poll = " + JSON.stringify(t.poll[0].id, null, 2))
                this.getAnswers(t.poll[0].id)

            }
        },
        async getAnswers(id) {
            let t = this;
            let getAnswers = await t.MIX_redisSearch('idx:pollAnswers', `@pollLinkId:${id}`, 0, 9999);
            if (getAnswers.code === 1) {
                t.allPollAnswers = [];
                getAnswers.data.documents.forEach((answer) => t.allPollAnswers.push(answer.value));
                //console.log('allPollAnswers = ' + JSON.stringify(getAnswers, null, 2))
            }
        },
        getAllPollAnswers() {
            let t = this;
            let stats = [];
            //* Count all items in answsers including duplicates
            let objResult = t.allPollAnswers.reduce((acc, obj) => {
                acc[obj.pollAnswer] = (acc[obj.pollAnswer] || 0) + 1;
                return acc;
            }, {});
            //* Map array results into key value pairs
            let arrResult = Object.keys(objResult).map(k => ({
                name: k,
                value: objResult[k]
            }));
            //* Assign key value pairs to graph structure
            arrResult.forEach(stat => {
                stats.push({
                    name: stat.name,
                    value: stat.value
                })
            })
            return stats;
        },
        async getOrganisations() {
            var t = this;
            var orgGroupId = t.GET_FIREBASE_userAccessToken.org_id
            // var orgGroupId = 'BARCHESTERHEALTHCARE'

            let getOrganisations = await t.MIX_redisSearch('idx:orgs', `@orgGroupStructure:{Home} @orgGroupId:{${orgGroupId}}`, 0, 9999)
            if (getOrganisations.code === 1) {
                t.organisations = [],
                // //console.log("getOrganisations = " + JSON.stringify(getOrganisations, null, 2))
                t.organisations = getOrganisations.data.documents.map((x) => x.value);
                if (this.itemNew == false) {
                    this.getSelectedOrganisations()
                }
            }
        }
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.package;
        t.loading = true
        t.initialise();

        t.getStatus();

        let packageCategories = await t.MIX_redisReadSet('set:packageCategory', false);
        if (packageCategories.code === 1) {
            t.packageCategories = packageCategories.data;
        }
        let packageStatus = await t.MIX_redisReadSet('set:packageStatus', false);
        if (packageStatus.code === 1) {
            t.packageStatus = packageStatus.data;
        }
        let billingCycles = await t.MIX_redisReadSet('set:billingCycles', false);
        if (billingCycles.code === 1) {
            t.billingCycles = billingCycles.data;
        }

        t.getOrganisations()

        t.loading = false
    },
    mounted() {},
};
</script>