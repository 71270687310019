<template>
    <div class="flipbook-container" id="flipbook"></div>    
</template>
<script src="/js/jquery.min.js"></script>
<script>
export default {
    props: {
        pdfurl: {
            type: String,
        },
    },
    data: () => ({
    }),
    methods: {
        loadPDF() {
            let t = this;
            console.log('loadPDF')
            $('#flipbook').FlipBook({
                controlsProps: { actions: { cmdPrint: { enabled: false, enabledInNarrow: false }, cmdSmartPan: { enabled: false }, cmdSinglePage: { enabled: false }, cmdSounds: { enabled: false }, cmdStats: { enabled: false }, cmdLightingUp: { enabled: true }, cmdLightingDown: { enabled: true }, },},
                pdf: this.pdfurl
                // template: template,
            });
        }
    },
    mounted() {
        let t = this;
        // console.log("mounted")
        // console.log('t.pdfurl = ' + t.pdfurl)
        $(document).ready(function() {
            // console.log("flipbook component ready")
            t.loadPDF();
        });
    }
}
</script>

<style>
.flipbook-container {
    height: 69vh !important;
    width: 100% !important;
}
</style>