<template>
    <div>
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field
                    :label="homeLogin ? 'Search Home Logins' : homeUser ? 'Search Home Users' : 'Search Users'"
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="oomph-search"
                    @click:clear="clearSearchQuery()"
                ></v-text-field>
            </div>
            <!-- Organisation Filter  -->
            <div v-if="!listshrunk && orgid == ''" class="ml-2">
                <div>
                    <v-text-field
                        label="Filter by Organisation"
                        v-model="filter.orgs"
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                        color="primary"
                        clearable
                        prepend-inner-icon="oomph-search"
                        @click:clear="clearSelectedOrgsFilter()"
                    ></v-text-field>
                </div>
            </div>
            <!-- User Group Filter  -->
            <div v-if="!listshrunk && orgid == ''" class="ml-2">
                <div>
                    <v-autocomplete
                        outlined
                        auto-select-first
                        v-model="filter.userGroup"
                        label="User Group"
                        :items="userGroupItems"
                        dense
                        hide-details="auto"
                        background-color="white"
                        color="primary"
                        clearable
                        @click:clear="clearFilter('userGroup', '')"
                    >
                    </v-autocomplete>
                </div>
            </div>
            <!-- User Status Filter  -->
            <div v-if="!listshrunk && orgid == ''" class="ml-2">
                <div>
                    <v-autocomplete
                        outlined
                        auto-select-first
                        v-model="filter.userStatus"
                        label="User Status"
                        :items="userStatusItems"
                        dense
                        hide-details="auto"
                        background-color="white"
                        color="primary"
                        clearable
                        @click:clear="clearFilter('userStatus', '')"
                    >
                    </v-autocomplete>
                </div>
            </div>
            <!-- User Level Filter  -->
            <div v-if="!listshrunk && orgid == ''" class="ml-2">
                <div>
                    <v-autocomplete
                        outlined
                        auto-select-first
                        v-model="filter.userLevel"
                        label="User Level"
                        :items="userLevelItems"
                        dense
                        hide-details="auto"
                        background-color="white"
                        color="primary"
                        clearable
                        @click:clear="clearFilter('userLevel', '')"
                    >
                    </v-autocomplete>
                </div>
            </div>
            <!-- Refresh Users -->
            <div v-if="!listshrunk" class="ml-2">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="refreshItems">
                    <v-icon>oomph-available-updates</v-icon>
                </v-btn>
            </div>
            <!-- Show / Hide Columns -->
            <div v-if="!listshrunk" class="ml-2">
                <v-menu
                    offset-y
                    class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false"
                    origin="top right"
                    left
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                            ><v-icon>oomph-table-properties</v-icon></v-btn
                        >
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox
                                v-model="headers[index].hidden"
                                dense
                                hide-details="auto"
                                v-for="(item, index) in headers"
                                :key="index"
                                :label="item.text"
                                :true-value="false"
                                :false-value="true"
                            ></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>

        <div v-if="!homeLogin && !groupUser" class="my-3 d-flex justify-end">
            <div class=""><v-switch v-model="showDeleted" color="primary" label="Show Deleted" hide-details="auto" class="pa-0 ma-0" inset></v-switch></div>
        </div>

        <div class="mt-2">
            <v-data-table
                v-if="!listshrunk"
                dense
                :headers="computedHeaders"
                :items="computedItems"
                :server-items-length="itemsTotal"
                :options.sync="datatableOptions"
                :search="search"
                :height="calcTableHeight"
            >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                            <v-btn value="undelete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template #[`item.orgName`]="{ item }">
                    <div v-if="item.orgName">{{ item.orgName.slice(0, 30) }}</div>
                </template>

                <template #[`item.userChangePassword`]="{ item }">
                    <div v-if="item.userChangePassword === '0'">No</div>
                    <div v-if="item.userChangePassword === '1'">Yes</div>
                    <div class="error--text" v-if="item.userChangePassword === '' || item.userChangePassword === null || item.userChangePassword === undefined">MISSING</div>
                </template>

                <template #[`item.userLoggedInTime`]="{ item }">
                    <div v-if="item.userLoggedInTime">
                        <v-chip small label class="font-weight-bold" :class="daysFromNowClass(item.userLoggedInTime)">{{ MIX_fromNow(item.userLoggedInTime, 'x', true) }}</v-chip>
                    </div>
                </template>
                <template #[`item.userStatus`]="{ item }">
                    <div v-if="item.userStatus">
                        <v-chip small label :class="item.userStatus !== 'Approved' ? 'warning white--text font-weight-bold' : 'white font-weight-normal'">{{ item.userStatus }}</v-chip>
                    </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <!-- //? delete certain user levels + option to restor  -->
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteConfirmation(item)" v-if="item.deleted === '0' && toggleFeatures.includes('delete') && ( item.userLevel == 'HomeUser' || item.userLevel == 'HomeManager')">oomph-trash-can</v-icon>
                    <!-- //? destroy other user levels -->
                    <v-icon class="error--text text--darken-2 ml-5" @click="destroyConfirmation(item)" v-else-if="(item.deleted === '0' && toggleFeatures.includes('delete')) || (item.deleted === '0' && toggleFeatures.includes('delete'))">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>
                </template>
            </v-data-table>
            <!-- CARD BASED LIST -->
            <div v-if="listshrunk" class="">
                <v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
                    <div class="d-flex align-center ml-1">
                        <div class="ml-3">
                            <div class="body-2 font-weight-bold" style="width: 180px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.userName }}</div>
                            <span class="body-2">{{ item.orgName }}</span
                            ><br />
                        </div>
                        <v-spacer></v-spacer>
                        <div class="text-right mr-3">
                            <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>
                        </div>
                    </div>
                </v-card>
            </div>
            <!-- LOAD MORE CARDS -->
            <v-btn v-if="listshrunk" class="mt-2" depressed block>Load More</v-btn>
        </div>



        <v-dialog v-model="confirmDialog" :key="confirmDialogKey" persistent width="466px" style="overflow: hidden !important" >
            <div class="px-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
                <div class="mb-1">
                    <v-icon class="white--text" large>oomph-menu-squared</v-icon>
                </div>
                <div class="white--text text-h6 d-flex align-center">
                    <span class="font-weight-normal ml-2">
                        {{ deleteProcess }} User?
                    </span>
                </div>
                <v-spacer />
                <div>
                    <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeConfirmDialog()" depressed>
                        <v-icon>oomph-multiply</v-icon>
                    </v-btn>
                </div>
            </div>
            <div class="pa-3 ma-0 grey lighten-3">
                <div v-if="deleteProcess == 'Destroy'">Are you sure you want to destroy this user (<b>{{ deletingItem.userName }}</b>) ? <p class="mt-2 error--text font-weight-bold">This Action is Permanent and Cannot be Undone.</p></div>

                <div v-else-if="deleteProcess == 'Delete'">Are you sure you want to delete this user (<b>{{ deletingItem.userName }}</b>) ? <p class="mt-2 warning--text font-weight-bold">You can restore this using undelete.</p></div>
            </div>
            <div dense class="pt-0 mt-0">
                <div class="d-flex justify-start grey darken-1 py-4 px-4 mt-0 pr-7" >
                    <v-btn class="grey lighten-1 white--text font-weight-bold" depressed @click="closeConfirmDialog()"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span></v-btn>
                    <v-spacer />
                    <v-btn v-if="deleteProcess == 'Destroy'" class="error white--text font-weight-bold" depressed @click="destroyItem(deletingItem.id)"> <v-icon class="mr-2">oomph-trash-can</v-icon><span>Destroy</span></v-btn>
                    <v-btn v-else-if="deleteProcess == 'Delete'" class="primary white--text font-weight-bold" depressed @click="deleteItem(deletingItem.id)"> <v-icon class="mr-2">oomph-trash-can</v-icon><span>Delete</span></v-btn>
                </div>
            </div>
        </v-dialog>



    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    // PROPS
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
        homeLogin: {
            type: Boolean,
            default: false,
        },
        homeUser: {
            type: Boolean,
            default: false,
        },
        groupUser: {
            type: Boolean,
            default: false,
        },
        streamUser: {
            type: Boolean,
            default: false,
        },
        orgtype: {
            type: String,
            default: '',
        },
        orgid: {
            type: String,
            default: '',
        },
    },
    // DATA
    data: () => ({
        key: 'user',
        index: 'idx:users',
        toggleDeletedItems: false,
        // Switches
        toggleResidents: false,
        toggleHomeUsers: false,

        // dropdowns
        organisations: [],
        // tag
        tagOrgAccountTypes: '',
        tagOrgTags: '',

        filter: {
            orgId: '',
            userLevel: '',
            userStatus: '',
            userGroup: '',
        },
        // Toggles
        toggleFeatures: [],

        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Headers to display in the Data Table
        headers: [
            { text: 'User Name', value: 'userName', hidden: false, expandable: true },
            { text: 'Organisation', value: 'orgName', hidden: false, expandable: false },
            { text: 'Organisation ID', value: 'orgId', hidden: true, expandable: false },
            { text: 'User Group', value: 'userGroup', hidden: false, expandable: false },
            { text: 'User Level', value: 'userLevel', hidden: false, expandable: false },
            { text: 'ID', align: 'start', value: 'id', hidden: true, expandable: true },
            { text: 'User Email', value: 'userEmail', hidden: false, expandable: true },
            { text: 'Last Login', value: 'userLoggedInTime', hidden: false, expandable: true },
            { text: 'Change PWD Next Login', value: 'userChangePassword', hidden: false, expandable: true },
            // { text: "User", align: "start", sortable: true, value: "photoURL", hidden: true, expandable: true },
            { text: 'User Pin', value: 'userPin', hidden: false, expandable: true },
            // { text: "Deleted Flag", value: "deleted", hidden: false, expandable: true },
            { text: 'User Status', value: 'userStatus', align: 'center', hidden: false, expandable: true },
            { text: 'Date Time (C)', value: 'createdDateTime', sortable: false, hidden: true, expandable: false },
            { text: 'User ID (C)', value: 'createdUserId', sortable: false, hidden: true, expandable: false },
            { text: 'User Name (C)', value: 'createdUserName', sortable: false, hidden: true, expandable: false },
            { text: 'User Email (C)', value: 'createdUserEmail', sortable: false, hidden: true, expandable: false },
            { text: 'Date Time (M)', value: 'modifiedDateTime', sortable: false, hidden: true, expandable: false },
            { text: 'User ID (M)', value: 'modifiedUserId', sortable: false, hidden: true, expandable: false },
            { text: 'User Name (M)', value: 'modifiedUserName', sortable: false, hidden: true, expandable: false },
            { text: 'User Email (M)', value: 'modifiedUserEmail', sortable: false, hidden: true, expandable: false },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expandable: false },
            { text: 'Date Time (D)', value: 'deletedDateTime', sortable: false, hidden: true, expandable: false },
            { text: 'User ID (D)', value: 'deletedUserId', sortable: false, hidden: true, expandable: false },
            { text: 'User Name (D)', value: 'deletedUserName', sortable: false, hidden: true, expandable: false },
            { text: 'User Email (D)', value: 'deletedUserEmail', sortable: false, hidden: true, expandable: false },
            { text: 'Action', value: 'action', align: 'end', sortable: false, hidden: false, expandable: true, width: '155px' },
        ],
        sortDesc: false,
        datatableOptions: {
            page: 1,
            itemsPerPage: 12,
            sortBy: ['id'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        search: '',
        userLevelItems: [], //["DeveloperAdmin", "StaffAdmin", "StaffUser", "StaffManager", "GuestUser"],
        userGroupItems: [], //["Content", "Accounts", "Sales", "Administration", "IT", "Developers", "Guest"],
        userStatusItems: [], // ["Approved", "Pending", "Deleted"],

        showDeleted: false,

        confirmDialog: false, 
        confirmDialogKey: 0,

        deleteProcess: ''
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        userLevel() {
            return this.GET_FIREBASE_userAccessToken.user_level;
        },
        userGroup() {
            return this.GET_FIREBASE_userAccessToken.user_group;
        },
        userStatus() {
            return this.GET_FIREBASE_userAccessToken.user_status;
        },
        computedSortBy() {
            return '@' + this.datatableOptions.sortBy[0];
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.showDeleted == true) {
                    searchQuery = '@deleted:1';
                } else {
                    searchQuery = '@deleted:0';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.showDeleted == true) {
                        searchQuery = `${searchQuery}*  @deleted:1 `;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.showDeleted == true) {
                        searchQuery = `${searchQuery}*  @deleted:1`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }
            if (this.homeUser == true) {
                searchQuery = `${searchQuery} @orgId:{${this.orgid}} @userLevel:{HomeUser|HomeManager|HomeAdmin}`;
            }
            if (this.homeLogin == true) {
                searchQuery = `${searchQuery} @orgId:{${this.orgid}} @userLevel:{HomeLogin}`;
            }
            if (this.groupUser == true) {
                searchQuery = `${searchQuery} @orgId:{${this.orgid}} @userLevel:{GroupUser}`;
            }
            if (this.streamUser == true) {
                searchQuery = `${searchQuery} @orgId:{${this.orgid}} @userLevel:{StreamUser}`;
            }

            if (this.filter.userLevel !== '' && this.filter.userLevel !== null && this.filter.userLevel !== undefined) {
                searchQuery = `${searchQuery}  @userLevel:{${this.filter.userLevel}}`;
            }
            if (this.filter.userStatus !== '' && this.filter.userStatus !== null && this.filter.userStatus !== undefined) {
                searchQuery = `${searchQuery}  @userStatus:{${this.filter.userStatus}}`;
            }
            if (this.filter.userGroup !== '' && this.filter.userGroup !== null && this.filter.userGroup !== undefined) {
                searchQuery = `${searchQuery}  @userGroup:{${this.filter.userGroup}}`;
            }
            if (this.filter.orgs !== '' && this.filter.orgs !== null && this.filter.orgs !== undefined) {
                searchQuery = `${searchQuery} @orgName:${this.filter.orgs}*`;
            }
            return searchQuery;
        },
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden);
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expandable !== false);
            }
            return headers;
        },
        computedItems() {
            var items = this.items;
            return items;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        calcTableHeight() {
            let tableHeight = window.innerHeight - 400;
            
            console.log("tableHeight = " + tableHeight)
            if (tableHeight < 300) {
                tableHeight = 300;
            }

            if (!this.homeUser && !this.homeLogin && !this.groupUser && !this.streamUser) {
                return tableHeight;
            } else {
                return ''
            }

        },
    },
    methods: {
        closeConfirmDialog() {
            var t = this; 

            t.deleteProcess = ''
            t.deletingItem = {}

            t.confirmDialog = false
            t.confirmDialogKey++
        },
        deleteConfirmation(item) {
            var t = this; 

            console.log('deleting item = ' + JSON.stringify(item, null, 2))

            t.deleteProcess = 'Delete'
            t.deletingItem = item

            t.confirmDialog = true
        },
        destroyConfirmation(item) {
            var t = this; 

            console.log('destroying item = ' + JSON.stringify(item, null, 2))

            t.deleteProcess = 'Destroy'
            t.deletingItem = item

            t.confirmDialog = true
        },
        async initialise() {
            let t = this;
            let userStatusesResult = await t.MIX_redisReadSet('set:userStatuses', true);
            if (userStatusesResult.code === 1) {
                t.userStatusItems = userStatusesResult.data;
                t.userStatusItems.sort((a, b) => a.localeCompare(b));
            }
            let userGroupsResult = await t.MIX_redisReadSet('set:userGroups', true);
            if (userGroupsResult.code === 1) {
                t.userGroupItems = userGroupsResult.data;
                t.userGroupItems.sort((a, b) => a.localeCompare(b));
            }
            let userLevelsResult = await t.MIX_redisReadSet('set:userLevels', true);
            if (userLevelsResult.code === 1) {
                t.userLevelItems = userLevelsResult.data;
                t.userLevelItems.sort((a, b) => a.localeCompare(b));
            }
        },
        async destroyItem(itemId) {
            var t = this;
            var item = {};

            let result = await t.MIX_readItem(itemId, t.key);
            if (result.code !== 1) {
                t.MIX_alertBox({ color: "error", message: "ERROR destroying User", timeout: 2000, show: true })
                return
            }
            item = result.data;

            var key = 'user:' + item.id
            console.log('key = ' + key)

            Promise.all([this.MIX_redisDestroyHash(`user:${item.id}`), this.MIX_firestore_destroy(item)]).then((results) => {
                if (results[0].code == '1' && results[1].data.code == '1') {
                    t.MIX_alertBox({ color: "success", message: "User Destroyed", timeout: 2000, show: true });
                    t.closeConfirmDialog()
                } else {
                    t.MIX_alertBox({ color: "error", message: "ERROR destroying User", timeout: 2000, show: true })
                }

            }).catch((error) => {
                console.log('error : ' + JSON.stringify(error, null, 2))
            })
            t.getItems()

        },
        async deleteItem(itemId) {
            var t = this;
            var item = {};

            // console.log('deleting user = ' + JSON.stringify(itemId, null, 2))

            let result = await t.MIX_readItem(itemId, t.key);
            if (result.code === 1) {
                item = result.data;
            }
            // console.log('deleting user = ' + JSON.stringify(item, null, 2))
            console.log(item);
            let dateTimeNow = t.$moment().format('x');
            item.deleted = '1';
            item.modifiedDateTime = dateTimeNow;
            item.modifiedUserId = t.GET_FIREBASE_userAuth.uid;
            item.modifiedUserName = t.GET_FIREBASE_userAuth.displayName;
            item.modifiedUserEmail = t.GET_FIREBASE_userAuth.email;
            item.deletedDateTime = dateTimeNow;
            item.deletedUserId = t.GET_FIREBASE_userAuth.uid;
            item.deletedUserName = t.GET_FIREBASE_userAuth.displayName;
            item.deletedUserEmail = t.GET_FIREBASE_userAuth.email;

            let documentReadResult = await this.MIX_firestore_delete(itemId, 'users', item);
            // console.log('documentReadResult = ' + JSON.stringify(documentReadResult, null, 2))
            if (documentReadResult.code === 1) {
                let addUserToRedis = await t.MIX_redisUpdateHash(`user:${item.id}`, item);
                // console.log('addUserToRedis = ' + JSON.stringify(addUserToRedis, null, 2))
                if (addUserToRedis.code === 1) {
                    t.MIX_alertBox({ color: "success", message: "User Deleted", timeout: 2000, show: true });
                    this.getItems();
                    this.closeConfirmDialog()
                }
                else {
                    t.MIX_alertBox({ color: "error", message: "Error Deleting User", timeout: 2000, show: true });
                }
            }
            else {
                t.MIX_alertBox({ color: "error", message: "Error Deleting User", timeout: 2000, show: true });
            }
        },
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        openItem(item) {
            this.$emit('openItem', item);
        },
        refreshItems() {
            this.getItems();
        },
        async getItems() {
            this.loading = true;
            if (this.datatableOptions.itemsPerPage === -1) {
                this.datatableOptions.itemsPerPage = this.itemsTotal;
            }
            let itemsResult = await this.MIX_redisAggregateSearch(
                this.index,
                this.computedSearchQuery,
                this.itemsFrom,
                this.datatableOptions.itemsPerPage,
                this.computedFields,
                this.computedSortBy,
                this.sortDesc
            );
            
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                if (this.datatableOptions.itemsPerPage === '-1') {
                    this.datatableOptions.itemsPerPage = this.itemsTotal;
                }
                let items = itemsResult.data.results;
                let itemsTo = Number(this.datatableOptions.page) * Number(this.datatableOptions.itemsPerPage);
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }

                // * Get Last Login - wipe previous login time
                items.forEach(e => e.userLoggedInTime = "");
                const lastlogins = await this.MIX_lastlogin({ searchQuery: `@createdUserId:{${items.map(item => item.id).join('|')}}`})
                
                if (lastlogins.code === 1) {
                    lastlogins.data.forEach(e => {
                        const index = items.findIndex(item => item.id === e.createdUserId)
                        if (index !== -1) {
                            if (e.lastLoginTime.length !== 13) e.lastLoginTime = e.lastLoginTime + '000'
                            items[index].userLoggedInTime = e.lastLoginTime
                        }
                    })
                }

                this.items = items;
            }




            this.loading = false;
        },
        // * Change Org Account Types Tag Operator
        changeAccountTypesOperator() {
            if (this.filter.orgAccountTypesOperator === 'ALL') {
                this.filter.orgAccountTypesOperator = 'ANY';
            } else {
                this.filter.orgAccountTypesOperator = 'ALL';
            }
        },
        // Clear Selected Account Types
        clearSelectedAccountTypes() {
            setTimeout(() => {
                this.filter.orgAccountTypes = [];
            }, 1);
        },
        // Clear Selected Org Tags
        clearSelectedOrgTags() {
            setTimeout(() => {
                this.filter.orgTags = [];
            }, 1);
        },
        clearSearchQuery() {
            this.search = '';
        },
        daysFromNowClass(date) {
            let todaysDate = this.$moment().format('x');
            let lastSessionDate = this.$moment(date, 'x').format('X');
            let days = this.$moment(todaysDate, 'x').diff(this.$moment(lastSessionDate, 'X'), 'days');
            switch (true) {
                case days >= 5:
                    return 'error';
                case days < 5 && days >= 2:
                    return 'warning';
                case days < 2 && days >= 0:
                    return 'success';
                default:
                    return 'error';
            }
        },
        clearSelectedOrgsFilter() {
            setTimeout(() => {
                this.filter.orgs = [];
            }, 1);
        },
    },
    watch: {
        refreshitems() {
            this.refreshItems();
        },
        computedSearchQuery() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
        listexpanded() {
            if (this.listexpanded === false) {
                this.datatableOptions.itemsPerPage = 15;
                this.itemsFrom = 0;
                this.getItems();
            } else if (this.listexpanded === true) {
                this.datatableOptions.itemsPerPage = 15;
                this.itemsFrom = 0;
                this.getItems();
            }
        },
        searchQuery(){
          this.itemsFrom = 0;
          this.datatableOptions.page = 1;
        }
    },
    async created() {
        let t = this;
        this.initialise();
        t.organisations = await t.MIX_organisations();
        this.getItems();
    },
};
</script>
