<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode" @click="saveItem()"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text px-3" dense style="z-index: 0 !important">
            <v-col v-if="!loading" cols="12" class="d-flex pt-4 pb-4 align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- DETAILS EDIT -->
                        <!-- <pre>{{ item }}</pre> -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Title</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field label="Lead Title" v-model="item.leadTitle" :rules="rules.leadTitle" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadTitle')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Lead Stage" v-model="selectedLeadStage" :rules="rules.leadStage" :items="leadStages" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadStages')" :menu-props="{ top: false, offsetY: true }" :attach="true">
                                        <template v-slot:selection="data">
                                            {{ data.item.stageName }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.stageName }}
                                        </template>
                                    </v-select>
                                </v-col>



                                <v-col cols="12" class="my-1 mb-0">
                                    <v-textarea label="Short Description" :rules="descriptionRules" counter=280  rows="2" background-color="white" v-model="item.leadDescription"  outlined dense color="primary" hide-details="auto" clearable @click:clear="clearItem('leadDescription', '')"></v-textarea>
                                </v-col>
                                <!-- <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Country and Local Authority</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="8">
                                    <v-btn-toggle class="flex-wrap " style="width: 100% !important" v-model="countryFilter" dense>
                                        <v-btn :width="$vuetify.breakpoint.mdAndUp ? '50%' : '50%'" v-for="country in countries" :key="country" :value="country" height="44" active-class="primary white--text" depressed
                                            ><div class="mx-3">{{ country }}</div></v-btn
                                        >
                                    </v-btn-toggle>
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                    <v-autocomplete label="Local Authority" v-model="item.leadLocalAuthority" :rules="rules.leadLocalAuthority" :items="computedLocalAuthority" item-text="authority" auto-select-first item-value="authority" outlined dense hide-details="auto" background-color="white" color="primary" no-data-text="Please select a Country first" clearable @click:clear="clearItem('localAuthorities')"></v-autocomplete>
                                </v-col> -->



                                <!-- <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Channel</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn-toggle class="flex-wrap" style="width: 100% !important" v-model="item.leadChannel"  dense>
                                        <v-btn value="Education" active-class="primary white--text" style="width: 50% !important" :large="!GET_dense" depressed><div class="mx-3">Education</div></v-btn>
                                        <v-btn value="Workplace" active-class="primary white--text" style="width: 50% !important" :large="!GET_dense" depressed><div class="mx-3">Workplace</div></v-btn>
                                    </v-btn-toggle>
                                </v-col> -->


                                <!-- <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Tags - <span class="darkgrey--text font-weight-normal body-2"> Please select one or more tags which best reflect this lead in order</span></div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn-toggle v-if="item.leadChannel === 'Education'" v-model="tags" class="d-flex flex-wrap justify-stretch grey lighten-3" multiple dense>
                                        <v-btn v-for="tag in leadEducationTags" :key="tag" :value="tag" active-class="primary white--text" small depressed
                                            ><div class="mx-3">{{ tag }}</div></v-btn
                                        >
                                    </v-btn-toggle>
                                    <v-btn-toggle v-else-if="item.leadChannel === 'Workplace'" v-model="tags" style="width: 100% !important" class="d-flex flex-wrap grey lighten-3" multiple dense>
                                        <v-btn v-for="tag in leadWorkplaceTags" :key="tag" :value="tag" active-class="primary white--text" small depressed
                                            ><div class="mx-3">{{ tag }}</div></v-btn
                                        >
                                    </v-btn-toggle>
                                </v-col> -->




                                <v-col cols="12" sm="12" md="6">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Type</span>
                                    <v-btn-toggle v-model="item.leadType" class="" style="width: 100% !important" :color="chosenOneType" dense>
                                        <v-btn value="cold" active-class="info white--text" style="width: 33.33% !important">Cold</v-btn>
                                        <v-btn value="warm" active-class="warning white--text" style="width: 33.33% !important">Warm</v-btn>
                                        <v-btn value="hot" active-class="error white--text" style="width: 33.33% !important">Hot</v-btn>
                                    </v-btn-toggle>
                                </v-col>    



                                <v-col cols="12" sm="12" md="6">
                                    <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Time Priority</span>
                                    <v-btn-toggle v-model="item.leadTimePriority" class="" style="width: 100% !important" :color="chosenOneTime" dense>
                                        <v-btn value="low" active-class="success white--text" style="width: 33.33% !important" depressed>Low</v-btn>
                                        <v-btn value="medium" active-class="warning white--text" style="width: 33.33% !important"  depressed>Medium</v-btn>
                                        <v-btn value="high" active-class="error white--text" style="width: 33.33% !important"  depressed>High</v-btn>
                                    </v-btn-toggle>
                                </v-col>    


                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Source Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-autocomplete label="Source" v-model="item.leadSource" :rules="rules.leadSource" :items="sources" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgOtherAddressType')" auto-select-first></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <!-- <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Source Name</span> -->
                                    <v-text-field label="Source Name" v-model="item.leadSourceName" :rules="rules.leadSourceName" required outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadSourceName')"></v-text-field>
                                </v-col>



                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Tag Organisations</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <!-- <pre>{{ organisations }}</pre> -->
                                <v-col cols="12" xs="12">
                                    <!-- <v-autocomplete multiple small-chips hide-selected label="Tag Organisations" v-model="selectedOrgTags" :search-input.sync="tagAutocomplete" @change="tagAutocomplete = ''" :items="organisations" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearItem('selectedOrgTags')">
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                                <span class="pr-2">
                                                    {{ item.orgName }}
                                                </span>
                                                <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.orgName }}
                                        </template>
                                    </v-autocomplete> -->
                                    <v-autocomplete
                                        label="Organisation"
                                        v-model="selectedOrgTags"
                                        :rules="rules.contactOrganisation"
                                        :items="organisations"
                                        item-text="orgName"
                                        return-object
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearSelectedOrganisation()"
                                    ></v-autocomplete>
                                    <!-- <v-autocomplete label="Tag Organisations" v-model="selectedOrgTags" :rules="rules.leadOrgTags" :items="organisations" multiple outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadOrgTags')">
                                        <template v-slot:selection="{ parent }">
                                            <v-chip  class="grey lighten-3" label small>
                                                <span class="pr-2">
                                                    {{ item.orgName }}
                                                </span>
                                                <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.orgName }}
                                        </template>
                                    </v-autocomplete> -->
                                </v-col>


                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Tag Contacts</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-autocomplete label="Tag Contacts" v-model="selectedContactTags" :rules="rules.leadContactTags" :items="contacts" multiple outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadContactTags')">
                                        <template v-slot:selection="data">
                                            {{ data.item.contactName }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.contactName }}
                                        </template>
                                    </v-autocomplete>
                                </v-col>




                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Lead Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Lead Status" v-model="item.leadStatus" :rules="rules.leadStatus" :items="leadStatuses" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadStatus')" :menu-props="{ top: true, offsetY: true }" :attach="true"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <!-- <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Owner</span> -->
                                    <v-select label="Lead Owner" v-model="selectedLeadOwner" :rules="rules.leadOwner" :items="users" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadStatus')" :menu-props="{ top: true, offsetY: true }" :attach="true">
                                        <template v-slot:selection="data">
                                            {{ data.item.ownerUserName }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.ownerUserName }}
                                        </template>
                                    </v-select>
                                </v-col>


                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
import moment from 'moment';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        activeitem: {
            Type: Object,
            Default: null,
        },
    },
    // * DATA
    data: () => ({
        key: 'lead',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,

        leadTitle: '',
        countries: [],
        countryFilter: '',
        sources: [],

        selectedLeadStatus: [],
        selectedOrgTags: null,
        selectedContactTags: [],
        // dropdowns
        packageTypes: [],
        billingCycles: [],
        packageStatus: [],
        descriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],
        selectedLeadOwner: [],
        selectedLeadStage: [],
        tagAutocomplete: '',

        leadStages: [],


        chosenOneTime: '',
        chosenOneType: '',

        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],

        // rules
        packageCustomerDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],
        packageInternalDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],



        // new address object
        item: {},
        loading: true,
        rules: {},

    }),
    computed: {  
        computedLocalAuthority() {
            if (this.countryFilter === '') {
                return [];
            } else {
                return this.localAuthority.filter((item) => {
                    return item.country === this.countryFilter;
                });
            }
            // 	return this.localAuthority.filter(function (item) {
            // 		return item.country === this.countryFilter;
            // 	});
            // } else {
            //}
        },
    },
    methods: {
        clearSelectedOrganisation() {
            setTimeout(() => {
                this.selectedOrgTags = { id: '', orgName: '' };
            }, 1);
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.item);
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        formatTag: function (item) {
            if (item !== null) {
                return item.toLowerCase(); //.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            } else {
                return '';
            }
        },
    },
    watch: {
        selectedOrgTags: {
            handler: function () {
                this.item.orgId = this.selectedOrgTags.id;
                this.item.orgName = this.selectedOrgTags.orgName;
                // //console.log("item = " + JSON.stringify(this.item, null, 2))
            },
            deep: true,
        },
        datadown: {
            handler: function () {
                this.item = { ...this.datadown };
                if (this.item.ownerUserId !== '' && this.item.ownerUserId !== undefined && this.item.ownerUserId !== null) {
                    this.selectedLeadOwner = { ownerUserId: this.datadown.ownerUserId, ownerUserName: this.datadown.ownerUserName, ownerUserEmail: this.datadown.ownerUserEmail };
                } else {
                    this.selectedLeadOwner = this.MIX_getCurrentOwner();
                }
                if (this.item.orgId !== '') {
                    this.selectedOrgTags = {
                        id: this.datadown.orgId,
                        orgName: this.datadown.orgName,
                    };
                }
                // // //console.log("slectedLeadOwner = " + JSON.stringify(this.selectedLeadOwner, null, 2));
            },
            deep: true,
        },
        activeitem: {
            handler: function () {
                let t = this;
                t.item = { ...t.activeitem };
            }, deep: true
        },
        leadTitle: {
            handler: function () {
                this.item.leadTitle = this.leadTitle;
                // //console.log('lead title = ' + this.item.leadTitle);
            },
        },
        // Selected Tags
        selectedTags: {
            handler: async function () {
                for (let i = 0; i < this.selectedTags.length; i++) {
                    this.selectedTags[i] = this.selectedTags[i].toLowerCase();
                    this.selectedTags[i] = this.selectedTags[i].replace(/[\W_]+/g, ' ');
                    if (this.selectedTags[i] === ' ') {
                        this.selectedTags.splice(i, 1);
                    } else {
                        this.selectedTags[i] = this.selectedTags[i].trim();
                    }
                }
                // Update Content Tags and split out array with commas
                this.contentItem.contentTags = this.selectedTags.join(',');

            },
            deep: true,
        },
        // selectedOrgTags: {
        //     handler: function () {
        //         this.item.leadOrgTags = '';
        //         this.item.leadOrgTagsId = '';
        //         for (let i = 0; i < this.selectedOrgTags.length; i++) {
        //             this.item.leadOrgTags = this.item.leadOrgTags + this.selectedOrgTags[i].orgName + ',';
        //             this.item.leadOrgTagsId = this.item.leadOrgTagsId + this.selectedOrgTags[i].id + ',';
        //         }
        //         // // //console.log('selectedOrgTags = ' + JSON.stringify(this.selectedOrgTags, null, 2));
        //     },
        //     deep: true,
        // },
        selectedContactTags: {
            handler: function () {
                this.item.leadContactTags = '';
                for (let i = 0; i < this.selectedContactTags.length; i++) {
                    this.item.leadContactTags = this.item.leadContactTags + this.selectedContactTags[i].contactName + ',';
                }
                // //console.log('selectedContactTags = ' + JSON.stringify(this.selectedContactTags, null, 2));
            },
            deep: true,
        },
        selectedLeadStatus: {
            handler: function () {
                this.item.leadStatus = this.selectedLeadStatus.join(',');
            },
        },
        selectedIndustry: {
            handler: function () {
                this.item.orgIndustry = this.selectedIndustry.join(',');
            },
        },
        selectedChannel: {
            handler: function () {
                this.item.orgChannel = this.selectedChannel.join(',');
            },
        },
        selectedLeadOwner: {
            handler: function () {
                this.item.ownerUserId = this.selectedLeadOwner.ownerUserId;
                this.item.ownerUserName = this.selectedLeadOwner.ownerUserName;
                this.item.ownerUserEmail = this.selectedLeadOwner.ownerUserEmail;
                // //console.log('selectedLeadOwner = ' + JSON.stringify(this.selectedLeadOwner.ownerUserName, null, 2));
            },
        },
        selectedLeadStage: {
            handler: function () {
                this.item.leadStageName = this.selectedLeadStage.stageName;
                this.item.leadStageId = this.selectedLeadStage.stageId;
            },
        }

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.lead;
        t.loading = true
        t.item = { ...t.datadown };
        if (this.item.ownerUserId !== '' && this.item.ownerUserId !== undefined && this.item.ownerUserId !== null) {
            this.selectedLeadOwner = { ownerUserId: this.datadown.ownerUserId, ownerUserName: this.datadown.ownerUserName, ownerUserEmail: this.datadown.ownerUserEmail };
        } else {
            this.selectedLeadOwner = this.MIX_getCurrentOwner();
        }
        // //console.log("slectedLeadOwner = " + JSON.stringify(this.selectedLeadOwner, null, 2));

        // Get Local Authority
        let localAuthoritiesResult = await this.MIX_redisSearch('idx:localAuthority', `*`, 0, 9999);
        t.localAuthority = localAuthoritiesResult.data.documents.map((x) => x.value);
        // Get Countries
        t.countries = Array.from(new Set(t.localAuthority.map((x) => x.country)));

        
        let sources = await t.MIX_redisReadSet('set:sources', true);
        if (sources.code === 1) {
            t.sources = sources.data;
        }
        let organisations = await t.MIX_organisations();
        t.organisations = organisations;

        let contacts = await t.MIX_contacts();
        t.contacts = contacts;

        let users = await t.MIX_users();
        t.users = users.map((x) => {
            return {
                ownerUserId: x.id,
                ownerUserName: x.userName,
                ownerUserEmail: x.userEmail,
            };
        });
        // // //console.log("users = " + JSON.stringify(users, null, 2));

        let leadStatuses = await t.MIX_redisReadSortedSet('sset:leadStatus', 0, 9999);
        if (leadStatuses.code === 1) {
            t.leadStatuses = leadStatuses.data;
        }


        let packageTypes = await t.MIX_redisReadSet('set:componentTypes', false);
        if (packageTypes.code === 1) {
            t.packageTypes = packageTypes.data;
        }
        let packageStatus = await t.MIX_redisReadSet('set:componentStatus', false);
        if (packageStatus.code === 1) {
            t.packageStatus = packageStatus.data;
        }
        let billingCycles = await t.MIX_redisReadSet('set:billingCycles', false);
        if (billingCycles.code === 1) {
            t.billingCycles = billingCycles.data;
        }


        let leadStages = await this.MIX_redisSearch('idx:stages', `*`, 0, 9999);
        leadStages = leadStages.data.documents.map((x) => x.value);
        // //console.log("leadStages = " + JSON.stringify(leadStages, null, 2));
        t.leadStages = leadStages.filter((item) => {
            return item.stagePipeline === 'Leads';
        })
        t.leadStages = t.leadStages.map((x) => {
            return {
                stageId: x.id,
                stageName: x.stageName,
            };
        })
        this.selectedLeadStatus = this.item.leadStatus.split(',');
        t.loading = false
    },
    mounted() {},
};
</script>