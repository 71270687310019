import { render, staticRenderFns } from "./PodcastPreview.vue?vue&type=template&id=2082af63&"
import script from "./PodcastPreview.vue?vue&type=script&lang=js&"
export * from "./PodcastPreview.vue?vue&type=script&lang=js&"
import style0 from "./PodcastPreview.vue?vue&type=style&index=0&id=2082af63&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports