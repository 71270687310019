import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    icons: {
		iconfont: 'mdiSvg' | 'icons8' || 'mdi'
	},
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#569D92',
                secondary: '#B2E1E7',
                tertiary: '#006E7B',
                quaternary: '#FFAA45',
                appbackground: '#ECF7F9',
				apptext: '#4f4f4f',

                appPrimary: '#0C9BAB',
                appSecondary: '#0E575F',
                appTertiary: '#0C8E9C',
                appQuaternary: '#E1E1E1',
                appAccent: '#F7AA12',
            },
            dark: {},
        }
    },
}

export default new Vuetify(opts);