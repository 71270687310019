<template>
    <div class=""
        style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow-y: scroll !important; overflow-x: hidden !important">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey lighten-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div
                        class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">
                        Loading</div>
                </div>
                <v-btn class="ml-3 grey darken-1 white--text font-weight-bold" depressed @click="cancelItem"
                    v-if="editMode"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn v-if="item.deleted == '0' && !newItem" text class="error white--text mr-2" @click="deleteDialog = true"
                        :large="!dense"><v-icon class="mr-2">oomph-delete-trash</v-icon>Delete</v-btn>
                    <v-btn  text class="primary white--text" v-if="!editMode" @click="editMode = true"
                        :large="!dense"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editMode" @click="validateForm()" :large="!dense"><v-icon
                            class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <!-- Contact Details View -->
        <v-row class="apptext--text px-3" dense v-if="!editMode">
            <v-col v-if="!editMode && !loading" cols="12" class="d-flex align-center justify-start rounded">
                <v-row style="width: 100% !important" dense>
                    <!-- Full Name / Job Title / Department / Marketing -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Details</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold body-2 white">Event Title:
                                                </td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{ item.recurringEventTitle
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold body-2 white">Event Type:
                                                </td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{ item.recurringEventType
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Event Status:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        item.recurringEventStatus }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Event Alias:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        item.contentAlias }}</td>
                                            </tr>
                                            <tr v-if="item.recurringEventAllDay === '0'">
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Start Time:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{
                                                        $moment(item.recurringEventStartDateTime, 'X').format('HH:mm') }}
                                                </td>
                                            </tr>
                                            <tr v-if="item.recurringEventAllDay === '0'">
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">End Time:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ $moment(item.recurringEventEndDateTime,
                                                        'X').format('HH:mm') }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Recurring Details</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Start Date:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ $moment(item.recurringRuleStartDateTime, 'X').format('DD-MMM-YYYY') }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">End Date:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ $moment(item.recurringRuleEndDateTime, 'X').format('DD-MMM-YYYY') }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Repeating:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ eventRepeatable}}
                                                </td>
                                            </tr>
                                            <tr v-if="eventRepeatable !== 'DAILY'">
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Repeat on:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    <span v-for="day in eventDays" :key="day">{{ day }}, </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Repeating every:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ eventInterval }}
                                                </td>
                                            </tr>
                                            <tr v-if="eventEnd == 'occurance'">
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Repeating for:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ eventRepeat }} times
                                                </td>
                                            </tr>
                                            <tr v-else>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">End Date:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ $moment(item.recurringRuleEndDateTime, 'X').format('DD-MMM-YYYY') }}
                                                    <span v-if="item.recurringEventAllDay === '0'">{{ $moment(item.recurringEventEndDateTime,
                                                        'X').format('HH:mm') }}</span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Telepjhone Numbers and Email -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Description</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Description:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        item.recurringEventDescription }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Content Partner -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Content
                                        Partner</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Organisation:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{ item.orgName }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Tags -->
                    <v-col cols="12" sm="12" :md="inputCols" v-if="item.recurringEventTags !== ''">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Tags</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-price-tag</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col v-if="item.recurringEventTags !== '' && item.recurringEventTags !== undefined" cols="12"
                                style="height: 100% !important" class="pt-2">
                                <v-chip label class="mr-1 mt-1" small v-for="tag in item.recurringEventTags.split(',')" :key="tag">{{
                                    tag }}</v-chip>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Metadata -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Metadata</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-info-squared</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Owner:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        item.ownerUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Created by:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        item.createdUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Created:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ MIX_formatDateTime(item.createdDateTime, 'x', 'DD-MMM-YYYY HH:mm:ss')
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Modified by:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        item.modifiedUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Modified:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ MIX_formatDateTime(item.modifiedDateTime, 'x', 'DD-MMM-YYYY HH: mm: ss') }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="px-3">
            <v-col v-if="editMode && !loading" cols="12" class="d-flex align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- GENERAL DETAILS EDIT-->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="12" class="d-flex align-center">
                                    <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Details
                                    </div>
                                    <v-spacer />
                                    <div class="caption text-right"><strong>ID:</strong> {{ item.id }}</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Event Title" v-model="item.recurringEventTitle" :rules="rules.eventTitle"
                                        outlined dense hide-details="auto" background-color="white" color="primary"
                                        clearable @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-autocomplete label="Event Type" v-model="item.recurringEventType" :rules="rules.eventType"
                                        :items="eventTypes" auto-select-first outlined dense hide-details="auto"
                                        background-color="white" color="primary" clearable
                                        @click:clear="clearselectedJobLevel()"
                                        autocomplete="donotautocomplete"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" class="mb-0">
                                    <div class="caption grey--text"> Please provide the start and end date/time for the first event in the series. </div>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-menu v-model="dateMenu1" :close-on-content-click="false"
                                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Event Start Date" v-model="computedDate1"
                                                :rules="rules.recurringEventStartDateTime" outlined dense hide-details="auto"
                                                background-color="white" color="primary" v-bind="attrs" v-on="on" readonly
                                                clearable
                                                @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date1" full-width no-title color="secondary"
                                            :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                                    </v-menu>
                                    <!-- <v-text-field label="Event Start Date" v-model="item.recurringEventStartDateTime" :rules="rules.orgRegistrationNumber" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearContactName('contactTwitter', '')"></v-text-field> -->
                                    <div class="ml-1 mt-1 body-2 primary--text" @click="showStartTime = !showStartTime"
                                        style="cursor: pointer">
                                        <span v-if="!showStartTime">Add Specific Start Time</span>
                                        <span v-if="showStartTime">Hide Specific Start Time</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-menu v-model="dateMenu2" :close-on-content-click="false"
                                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Event End Date" v-model="computedDate2"
                                                :rules="rules.recurringEventEndDateTime" outlined readonly dense hide-details="auto"
                                                background-color="white" color="primary" v-bind="attrs" v-on="on" clearable
                                                @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                            <!-- <v-text-field dense background-color="white" filled v-model="computedDate2" label="Event End Date" hide-details="auto" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field> -->
                                        </template>
                                        <v-date-picker v-model="date2" full-width no-title color="secondary"
                                            :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                                    </v-menu>
                                    <!-- <v-text-field label="Event End Date Name" v-model="item.recurringEventEndDateTime" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactFullName', '')"></v-text-field> -->
                                    <div class="ml-1 mt-1 body-2 primary--text" @click="showEndTime = !showEndTime"
                                        style="cursor: pointer">
                                        <span v-if="!showEndTime">Add Specific End Time</span>
                                        <span v-if="showEndTime">Hide Specific End Time</span>
                                    </div>
                                </v-col>
                                <v-col v-if="showStartTime || showEndTime" cols="12" xs="12" md="6">
                                    <v-row dense v-if="showStartTime">
                                        <!-- start time -->
                                        <v-col cols="12" xs="12" md="6" class="my-1">
                                            <v-autocomplete label="Hour" v-model="eventStartTimeHours" :items="hours"
                                                auto-select-first outlined dense hide-details="auto"
                                                background-color="white" color="primary" clearable
                                                @click:clear="clearselectedJobLevel()"
                                                autocomplete="donotautocomplete"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="6" class="my-1">
                                            <v-autocomplete label="Minutes" v-model="eventStartTimeMins" :items="mins"
                                                auto-select-first outlined dense hide-details="auto"
                                                background-color="white" color="primary" clearable
                                                @click:clear="clearselectedJobLevel()"
                                                autocomplete="donotautocomplete"></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col v-if="showEndTime || showStartTime" cols="12" xs="12" md="6">
                                    <v-row dense v-if="showEndTime">
                                        <!-- end time -->
                                        <v-col cols="12" xs="12" md="6" class="my-1">
                                            <v-autocomplete label="Hour" v-model="eventEndTimeHours" :items="hours"
                                                auto-select-first outlined dense hide-details="auto"
                                                background-color="white" color="primary" clearable
                                                @click:clear="clearselectedJobLevel()"
                                                autocomplete="donotautocomplete"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="6" class="my-1">
                                            <v-autocomplete label="Minutes" v-model="eventEndTimeMins" :items="mins"
                                                auto-select-first outlined dense hide-details="auto"
                                                background-color="white" color="primary" clearable
                                                @click:clear="clearselectedJobLevel()"
                                                autocomplete="donotautocomplete"></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-autocomplete label="Event Status" v-model="item.recurringEventStatus"
                                        :rules="rules.eventStatus" :items="eventStatus" auto-select-first outlined dense
                                        hide-details="auto" background-color="white" color="primary" clearable
                                        @click:clear="clearselectedJobLevel()"
                                        autocomplete="donotautocomplete"></v-autocomplete>
                                </v-col>
                                <v-col cols="6" class="my-1">
                                    <v-select label="Content Alias" background-color="white" :rules="rules" class="pr-1"
                                        v-model="selectedAlias" :items="contentAlias" item-text="alias" outlined dense
                                        color="primary" hide-details="auto" clearable
                                        :menu-props="{ top: false, offsetY: true }" :attach="true"
                                        @click:clear="clearContactName('contentArea', '')"></v-select>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12" xs="12" class="d-flex align-center my-1 pl-2">
                                    <div class="text-left grey--text body-2 font-weight-regular pr-3">Is this a Live Stream
                                        Event: </div>
                                    <v-radio-group v-model="recurringEventLiveStream" hide-details="auto" row dense>
                                        <v-radio class="grey--text" label="Yes" value="1"></v-radio>
                                        <v-radio class="grey--text" label="No" value="0"></v-radio>
                                    </v-radio-group>
                                    <v-spacer />
                                    <v-icon @click="incrementIconKey"
                                        v-if="item.recurringEventLiveStreamId !== ''">oomph-tv-show</v-icon>
                                </v-col>
                            </v-row>
                            <v-row v-if="liveStreamKey >= 10" dense>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Event Playback ID" v-model="item.recurringEventPlaybackId" outlined dense
                                        hide-details="auto" background-color="white" color="primary" clearable
                                        disabled></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Event Stream Key" v-model="item.recurringEventStreamKey" outlined dense
                                        hide-details="auto" background-color="white" color="primary" clearable
                                        disabled></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Event Live Stream ID" v-model="item.recurringEventLiveStreamId" outlined
                                        dense hide-details="auto" background-color="white" color="primary" clearable
                                        disabled></v-text-field>
                                </v-col>
                            </v-row>

                            <!-- // RECURRING  -->
                            <v-row dense>
                                <v-col cols="12" xs="12" class="d-flex align-center my-1 pl-2">
                                    <div class="text-left grey--text body-2 font-weight-regular pr-3">Repeat Task? </div>
                                    <v-radio-group :disabled="!newItem" v-model="eventRepeatable" hide-details="auto" row dense>
                                        <!-- <v-radio class="grey--text" label="No" value="No"></v-radio> -->
                                        <v-radio class="grey--text" label="Daily" value="DAILY"></v-radio>
                                        <v-radio class="grey--text" label="Weekly" value="WEEKLY"></v-radio>
                                        <v-radio class="grey--text" label="Monthly" value="MONTHLY"></v-radio>
                                        <v-radio class="grey--text" label="Annually" value="ANNUALLY"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="eventRepeatable !== 'No'">

                                <v-col v-if="eventRepeatable == 'WEEKLY'" cols="12" class="d-flex align-center pl-2">
                                    <div class="text-left grey--text body-2 font-weight-regular pr-3">Repeat on</div>
                                    <v-checkbox :disabled="!newItem" class="pl-2 grey--text" hide-details="auto" v-model="eventDays" label="Mon" value="Mon"></v-checkbox>
                                    <v-checkbox :disabled="!newItem" class="pl-2 grey--text" hide-details="auto" v-model="eventDays" label="Tue" value="Tue"></v-checkbox>
                                    <v-checkbox :disabled="!newItem" class="pl-2 grey--text" hide-details="auto" v-model="eventDays" label="Wed" value="Wed"></v-checkbox>
                                    <v-checkbox :disabled="!newItem" class="pl-2 grey--text" hide-details="auto" v-model="eventDays" label="Thu" value="Thu"></v-checkbox>
                                    <v-checkbox :disabled="!newItem" class="pl-2 grey--text" hide-details="auto" v-model="eventDays" label="Fri" value="Fri"></v-checkbox>
                                    <v-checkbox :disabled="!newItem" class="pl-2 grey--text" hide-details="auto" v-model="eventDays" label="Sat" value="Sat"></v-checkbox>
                                    <v-checkbox :disabled="!newItem" class="pl-2 grey--text" hide-details="auto" v-model="eventDays" label="Sun" value="Sun"></v-checkbox>
                                </v-col>

                                <v-col  cols="12" class="d-flex align-center pl-2">
                                    <div class="text-left grey--text body-2 font-weight-regular pr-3">Repeat every</div>
                                    <div style="width: 50px">
                                        <v-text-field :disabled="!newItem" v-model="eventInterval" type="number" outlined dense hide-details="auto" background-color="white" color="primary" ></v-text-field>
                                    </div>
                                </v-col>

                                <v-col cols="12" xs="12" class="d-flex align-center pl-2">
                                    <div class="text-left grey--text body-2 font-weight-regular pr-3">Ends</div>
                                    <v-radio-group :disabled="!newItem" v-model="eventEnd" hide-details="auto" row dense>
                                        <v-radio class="grey--text" value="occurance">
                                            <template v-slot:label>
                                                <div>after</div>
                                                <div class="mx-2" style="width: 50px">
                                                    <v-text-field v-model="eventRepeat" type="number" outlined dense hide-details="auto" background-color="white" color="primary" ></v-text-field>
                                                </div>
                                                <div>occurence(s)</div>
                                            </template>
                                        </v-radio>
                                        <v-radio class="grey--text" value="date">
                                            <template v-slot:label>
                                                <div>on</div>
                                                <div class="mx-2" style="width: 200px">
                                                    <v-menu v-model="dateMenu3" :close-on-content-click="false"
                                                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field label="Event End Date" v-model="computedDate3"
                                                                :rules="rules.recurringEventEndDateTime" outlined readonly dense hide-details="auto"
                                                                background-color="white" color="primary" v-bind="attrs" v-on="on" clearable
                                                                @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                                            <!-- <v-text-field dense background-color="white" filled v-model="computedDate2" label="Event End Date" hide-details="auto" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field> -->
                                                        </template>
                                                        <v-date-picker v-model="date3" full-width no-title color="secondary"
                                                            :first-day-of-week="1" @input="dateMenu3 = false"></v-date-picker>
                                                    </v-menu>
                                                </div>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                </v-col>

                                <v-col cols="12">
                                    <div class="grey--text text---darken-2">Summary</div>
                                    <div class="grey--text body-2 font-weight-regular">{{ eventRecurringText }}</div>
                                    <div class="grey--text font-weight-bold" :style="{ cursor: hoverCursor }" @mouseover="toggleCursor('pointer')" @mouseleave="toggleCursor('default')" @click="showDates()">
                                        Click to see all dates

                                    </div>
                                </v-col>
                            </v-row>


                            <!-- <pre>streamKey: {{ item.eventStreamKey }}</pre>
                            <pre>id: {{ item.recurringEventLiveStreamId }}</pre> -->
                        </v-col>
                        <!-- <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <span class="text-left grey--text pr-3 text-h6 font-weight-light">Live Stream</span>
                                    <v-chip small><span class="grey--text">Coming Soon</span></v-chip>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                            </v-row>
                        </v-col> -->
                        <!-- <pre>{{ item }}</pre> -->
                        <!-- ORGANISATOIN EDIT -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Short
                                        Description</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" class="my-1">
                                    <v-textarea label="Event Description" :rules="descriptionRules" counter=1000 rows="2"
                                        background-color="white" v-model="item.recurringEventDescription" outlined dense
                                        color="primary" hide-details="auto" clearable
                                        @click:clear="clearItem('recurringEventDescription', '')">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- CONTENT PARTNER -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Content
                                        Partner</div>
                                    <!-- <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleJobRole = !toggleJobRole">
                                        <span v-if="!toggleJobRole">Show</span>
                                        <span v-if="toggleJobRole">Hide</span>
                                    </v-btn> -->
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-autocomplete label="Partner" v-model="selectedOrganisation"
                                        :rules="rules.eventOrganisation" :items="organisations" item-text="orgName"
                                        item-value="orgId" return-object outlined dense hide-details="auto"
                                        background-color="white" color="primary" clearable
                                        @click:clear="clearSelectedOrganisation()"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="d-flex justify-end">
                                    <v-row dense class="d-flex align-center justify-end pt-2">
                                        <v-checkbox v-model="item.recurringEventShowPartnerLogo" hide-details="auto"></v-checkbox>
                                        <div class="text-left grey--text body-2 font-weight-regular pr-4">Show Partner Logo
                                            alongside Content:</div>
                                        <!-- <v-radio-group v-model="item.recurringEventShowPartnerLogo"  hide-details="auto" row dense>
                                                <v-radio class="grey--text" label="Yes" value="1"></v-radio>
                                                <v-radio class="grey--text" label="No" value="0"></v-radio>
                                            </v-radio-group> -->
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- ASSIGNED TO  -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Assigned to</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" :md="inputCols">
                                    <v-autocomplete :disabled="selectedOrganisation.orgId == ''" label="Owner *" v-model="selectedOwner" :rules="rules.eventOwner"
                                        :items="users" item-text="ownerUserName" auto-select-first return-object outlined
                                        dense hide-details="auto" background-color="white" color="primary" clearable
                                        @click:clear="clearSelectedOwner()">
                                        <template v-slot:selection="{ item }">
                                            {{ item.ownerUserName }}
                                            <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            {{ item.ownerUserName }}
                                            <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- //* TAGS EDIT-->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="my-1">
                                    <v-row dense>
                                        <v-col cols="12" sm="6" class="d-flex align-center">
                                            <div class="text-left  primary--text text--darken-2 text-h6 font-weight-light">
                                                Tags</div>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <v-divider class="grey lighten-1 mb-1"></v-divider>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="12">
                                            <v-combobox multiple small-chips hide-selected label="Tags"
                                                v-model="selectedTags" :search-input.sync="tagAutocomplete"
                                                @change="tagAutocomplete = ''" :items="eventTags" outlined dense
                                                hide-details="auto" background-color="white" color="primary"
                                                :menu-props="{ top: true, offsetY: true }" :attach="true" clearable
                                                @click:clear="clearItem('quoteChannel')">
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                                    <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected"
                                                        label small>
                                                        <span class="pr-2">
                                                            {{ formatTag(item) }}
                                                        </span>
                                                        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                                                    </v-chip>
                                                </template>
                                            </v-combobox>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn-toggle v-model="selectedTags" style="width: 100% !important" multiple
                                                dense class="d-flex flex-wrap flex-row">
                                                <v-btn style="width: 25% !important" value="dementia friendly">
                                                    <div class="mx-3">Dementia Friendly</div>
                                                    <!-- <v-icon class="grey--text text--darken-3">oomph-edit</v-icon> -->
                                                </v-btn>
                                                <v-btn style="width: 25% !important" value="small group">
                                                    <div class="mx-3">Small Group</div>
                                                    <!-- <v-icon class="grey--text text--darken-3">oomph-delete-trash</v-icon> -->
                                                </v-btn>
                                                <v-btn style="width: 25% !important" value="visual impairment">
                                                    <div class="mx-3">Visual Impairment</div>
                                                    <!-- <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon> -->
                                                </v-btn>
                                                <v-btn style="width: 25% !important" value="hearing impairment">
                                                    <div class="mx-3">Hearing Impairment</div>
                                                    <!-- <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon> -->
                                                </v-btn>
                                            </v-btn-toggle>

                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="height: 80px !important" />
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="pl-1">
            <v-col v-if="editMode" cols="12" class="d-flex justify-start grey lighten-1 py-4 px-4 pr-7"
                style="position: fixed; bottom: 0 !important; max-width: 49vw !important">
                <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="cancelItem"> <v-icon
                        class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                <v-spacer />
                <v-btn class="primary white--text font-weight-bold" depressed @click="validateForm"> <v-icon
                        class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
            </v-col>
        </v-row>

        <v-dialog persistent v-model="datesDialog" width="700px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mx-3 mb-1">
                        <v-icon class="white--text">oomph-business-network</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Event Dates</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeDatesDialog()" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-4">
                    <div>
                        <v-simple-table 
                            :key="eventDates.length"
                            fixed-header 
                            dense
                            height="300px">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Date</th>
                                        <th class="text-left">Start Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="date in eventDates" :key="date.id">
                                        <td>{{ $moment(date).format('ddd, DD MMM yyyy') }}</td>
                                        <!-- <td>{{ $moment(date).format('HH:mm') }}</td> -->
                                        <td>{{ date }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <!-- <pre>{{ eventDates }}</pre> -->
                    </div>
                </v-card-text>

                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-2">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="closeDatesDialog"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Close</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>

        </v-dialog>
        
        <v-dialog persistent v-model="deleteDialog" width="700px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mx-3 mb-1">
                        <v-icon class="white--text">oomph-delete-trash</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Delete Dates from</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="deleteDialog = false" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-4">
                    <div>
                        <div class="pb-3 grey--text">Please select a date that you would like events to be deleted from:</div>
                        <v-menu v-model="dateMenu4" :close-on-content-click="false"
                            transition="scale-transition" offset-y max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="computedDate4" outlined readonly dense hide-details="auto"
                                    background-color="white" color="primary" v-bind="attrs" v-on="on" clearable
                                    @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                <!-- <v-text-field dense background-color="white" filled v-model="computedDate2" label="Event End Date" hide-details="auto" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field> -->
                            </template>
                            <v-date-picker v-model="date4" full-width no-title color="secondary"
                                :first-day-of-week="1" @input="dateMenu4 = false"></v-date-picker>
                        </v-menu>
                    </div>
                </v-card-text>

                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-2">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="deleteDialog = false"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Close</span> </v-btn>
                            <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="deleteItem"> <v-icon class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>

        </v-dialog>
        <v-dialog persistent v-model="editDialog" width="700px" height="400px" style="overflow: hidden !important">
            <div class="d-flex flex-column ma-0 pa-0">
                <div class="pa-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mx-3 mb-1">
                        <v-icon class="white--text">oomph-business-network</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Edit Dates from</strong>
                        </span>
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="editDialog = false" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-4">
                    <div>
                        <div class="pb-3 grey--text">Please select a date that you would like events to be edited from:</div>
                        <v-menu v-model="dateMenu4" :close-on-content-click="false"
                            transition="scale-transition" offset-y max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="computedDate4" outlined readonly dense hide-details="auto"
                                    background-color="white" color="primary" v-bind="attrs" v-on="on" clearable
                                    @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                <!-- <v-text-field dense background-color="white" filled v-model="computedDate2" label="Event End Date" hide-details="auto" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field> -->
                            </template>
                            <v-date-picker v-model="date4" full-width no-title color="secondary"
                                :first-day-of-week="1" @input="dateMenu4 = false"></v-date-picker>
                        </v-menu>
                    </div>
                </v-card-text>

                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-2">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="editDialog = false"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Close</span> </v-btn>
                            <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="updateItem"> <v-icon class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </div>

        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { RRule } from 'rrule';
export default {
    name: 'EventDetails',
    // * PROPS
    props: {
        dense: {
            type: Boolean,
            default: false,
        },
        datadown: {
            type: Object,
            default: () => {
                contentDeta
                return {};
            },
        },
        org: {
            type: Object,
            default: function () {
                return {};
            },
        },
        pu: {
            type: Boolean,
            default: false,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        editmode: {
            type: Boolean,
            default: false,
        },
        newItem: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        item: {
            recurringEventShowPartnerLogo: false,
        },
        key: 'event',
        index: 'idx:events',
        editMode: false,
        // Items
        items: [],
        selectedAlias: [],
        contentAlias: [],
        itemsTotal: null,
        itemsFrom: 0,
        itemsTo: 9,
        activeItem: {},
        eventTags: [],
        searchQuery: '',
        tagAutocomplete: '',
        // dropdowns
        eventStatus: [],
        eventTypes: [],
        options: [],
        optionSelected: {},
        selectedTags: [],
        // Validation
        rules: [],
        loading: true,
        // Organisation Dropdown
        organisations: [],
        selectedOrganisation: {
            orgId: 'OOMPHWELLNESS0000001',
            orgName: 'Oomph',
        },
        users: [],
        selectedOwner: null,
        orgResidents: [],
        // date picker
        date1: moment().format('YYYY-MM-DD'),
        dateMenu1: false,
        date2: moment().format('YYYY-MM-DD'),
        dateMenu2: false,
        showStartTime: false,
        showEndTime: false,
        eventStartTimeHours: '',
        eventStartTimeMins: '',
        eventEndTimeHours: '',
        eventEndTimeMins: '',

        eventTimeDefaults: {
            eventStartTimeHours: '00',
            eventStartTimeMins: '00',
            eventEndTimeHours: '23',
            eventEndTimeMins: '55',
        },


        descriptionRules: [v => v.length <= 1000 || 'Max 1000 characters'],

        recurringEventLiveStream: '0',
        recurringEventLiveStreamURL: '',
        liveStreamKey: 0,

        eventRepeatable: 'No',
        eventInterval: 1,
        eventRepeat: 1,
        eventRecurringText: '',
        eventDays: [],
        eventDates: [],
        eventEnd: 'occurance',
        date3: moment().add(1, 'years').format('YYYY-MM-DD'),
        dateMenu3: false,
        date4: moment().format('YYYY-MM-DD'),
        dateMenu4: false,
        datesDialog: false,
        editDialog: false,
        deleteDialog: false,
        hoverCursor: 'default',
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_FIREBASE_userData: 'firebase_auth_store/GET_FIREBASE_userData',
        }),
        inputCols() {
            if (this.listshrunk) {
                return '4';
            } else {
                return '6';
            }
        },
        computedDate1() {
            return this.$moment(this.date1, 'YYYY-MM-DD').format('DD-MMM-YYYY');
        },
        computedDate2() {
            return this.$moment(this.date2, 'YYYY-MM-DD').format('DD-MMM-YYYY');
        },
        computedDate3() {
            return this.$moment(this.date3, 'YYYY-MM-DD').format('DD-MMM-YYYY');
        },
        computedDate4() {
            return this.$moment(this.date4, 'YYYY-MM-DD').format('DD-MMM-YYYY');
        },
        hours() {
            var hours = [];
            for (var i = 0; i < 24; i++) {
                if (i < 10) {
                    hours.push('0' + i).toString();
                } else {
                    hours.push(i.toString());
                }
            }
            return hours;
        },
        mins() {
            var mins = [];
            for (var i = 0; i < 60; i++) {
                if (i < 10) {
                    mins.push('0' + i).toString();
                } else {
                    mins.push(i.toString());
                }
                i = i + 4;
            }
            return mins;
        },
    },
    // * METHODS
    methods: {
        getRecurringInfo() {
            
            var eventRepeatable = ''
            switch (this.eventRepeatable) {
                case 'DAILY':
                    eventRepeatable = RRule.DAILY
                    break;
                case 'WEEKLY':
                    eventRepeatable = RRule.WEEKLY
                    break;
                case 'MONTHLY':
                    eventRepeatable = RRule.MONTHLY
                    break;
                case 'ANNUALLY':
                    eventRepeatable = RRule.ANNUALLY
                    break;
                default:
                    eventRepeatable = 'No'
                    break;
            }

            var days = []
            for (let i = 0; i < this.eventDays.length; i++) {
                if (this.eventDays[i] === 'Mon') {
                    days.push(RRule.MO)
                } else if (this.eventDays[i] === 'Tue') {
                    days.push(RRule.TU)
                } else if (this.eventDays[i] === 'Wed') {
                    days.push(RRule.WE)
                } else if (this.eventDays[i] === 'Thu') {
                    days.push(RRule.TH)
                } else if (this.eventDays[i] === 'Fri') {
                    days.push(RRule.FR)
                } else if (this.eventDays[i] === 'Sat') {
                    days.push(RRule.SA)
                } else if (this.eventDays[i] === 'Sun') {
                    days.push(RRule.SU)
                }
            }

            var rule = new RRule ({
                freq: eventRepeatable,
                dtstart: new Date(moment(this.date1, 'YYYY-MM-DD').utc().valueOf()),
                interval: this.eventInterval,
                byweekday: this.eventRepeatable !== 'WEEKLY' ? null : days,
                until: this.eventEnd !== 'occurance' ? new Date(moment(this.date3, 'YYYY-MM-DD').utc().valueOf()) : null,
                count: this.eventEnd === 'occurance' ? this.eventRepeat : null
            })

            console.log('rule', rule)

            this.eventRecurringText = 'This event will recure ' + rule.toText()
            this.eventDates = rule.all()
        },
        showDates() {
            this.datesDialog = true
        },
        closeDatesDialog() {
            this.datesDialog = false
        },
        toggleCursor(cursor) {
            this.hoverCursor = cursor;
        },
        validateForm() {
            this.rules = {
                eventTitle: [(v) => !!v || 'Title Required'],
                eventType: [(v) => !!v || 'Type Required'],
                recurringEventStartDateTime: [(v) => !!v || 'Start Date Required'],
                recurringEventEndDateTime: [(v) => !!v || 'End Date Required'],
                eventStatus: [(v) => !!v || 'Status Required'],
                eventDescription: [(v) => !!v || 'Description Required'],
                eventOrganisation: [(v) => !!v || 'Organisation Required'],
                eventAlias: [(v) => !!v || 'Alias Required'],
                //eventOwner: [(v) => !!v || 'Assigned to Required'],
            };
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    this.saveItem();
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Please fill in all required fields', show: true });
                }
            });
        },
        isDateValid(date) {
            if (date === null || date === undefined || date === '' || date == "Invalid date") return false
            return true
        },

        async saveItem() {
            if (this.item.recurringEventShowPartnerLogo === true) {
                this.item.recurringEventShowPartnerLogo = "1";
            } else {
                this.item.recurringEventShowPartnerLogo = "0";
            }
            this.item.recurringEventFrequency = this.eventRepeatable;
            this.item.recurringEventCount = this.eventRepeat;
            this.item.recurringEventLiveStream = this.recurringEventLiveStream
            this.item.recurringEventDays = this.eventDays
            this.item.recurringEventInterval = this.eventInterval
            this.item.recurringEventEnd = this.eventEnd
            this.assignDate1();
            this.assignDate2();
            this.item.orgId = this.selectedOrganisation.orgId;
            this.item.contentAlias = this.selectedAlias;
            this.item.recurringEventTags = this.selectedTags.join(',');
            this.item.orgName = this.selectedOrganisation.orgName;
            this.item.ownerUserId = this.selectedOwner.ownerUserId;
            this.item.ownerUserName = this.selectedOwner.ownerUserName;
            this.item.ownerUserEmail = this.selectedOwner.ownerUserEmail;

            this.item.recurringRuleEndDateTime = this.$moment(this.date3, 'YYYY-MM-DD').toISOString();


            if (this.newItem == true) {
                this.item.id = await this.MIX_generateId();
                console.log('new item' + this.newItem)
                this.editMode = false;
                this.$emit('save', this.item);
            } else {
                this.editDialog = true
            }
        },
        updateItem() {
            let date = this.$moment(this.date4, 'YYYY-MM-DD').format('X');
            this.item.searchQuery = `@deleted:0 @eventStartDateTime:[${date} +inf]`
            this.editDialog = false
            this.$emit('update', this.item);
        },
        deleteItem() {
            let date = this.$moment(this.date4, 'YYYY-MM-DD').format('X');
            this.item.searchQuery = `@deleted:0 @eventStartDateTime:[${date} +inf]`
            this.deleteDialog = false
            this.$emit('delete', this.item);
        },
        cancelItem() {
            this.editMode = false;
            this.$emit('cancel');
        },
        clearSelectedOrganisation() {
            setTimeout(() => {
                this.selectedOrganisation = { orgId: '', orgName: '' };
            }, 1);
        },
        incrementIconKey() {
            this.liveStreamKey++
        },
        loadDataDown() {
            this.editMode = this.editmode;
            this.item = { ...this.datadown };
            if (this.item.recurringEventTitle === '') {
                this.editMode = true;
            }
            if (this.item.orgId !== '') {
                this.selectedOrganisation = {
                    orgId: this.datadown.orgId,
                    orgName: this.datadown.orgName,
                };
            }
            // Tags
            if (this.item.eventTags !== '' && this.item.eventTags !== undefined && this.item.eventTags !== null) {
                this.selectedTags = this.item.recurringEventTags.split(',');
            }
            if (this.item.contentAlias !== '') {
                this.selectedAlias = this.item.contentAlias;
            }
            if (this.item.recurringRuleStartDateTime !== '') {
                this.date1 = moment(this.item.recurringRuleStartDateTime, 'X').format('YYYY-MM-DD')
            }
            if (this.item.recurringRuleEndDateTime !== '') {
                this.date2 = moment(this.item.recurringRuleEndDateTime, 'X').format('YYYY-MM-DD')
            }
            if (this.item.recurringEventAllDay === '0') {
                this.eventStartTimeHours = moment(this.item.recurringEventStartDateTime, 'X').format('HH')
                this.eventStartTimeMins = moment(this.item.recurringEventStartDateTime, 'X').format('mm')
                this.eventEndTimeHours = moment(this.item.recurringEventEndDateTime, 'X').format('HH')
                this.eventEndTimeMins = moment(this.item.recurringEventEndDateTime, 'X').format('mm')
                this.showStartTime = true
                this.showEndTime = true
            }
            if (this.item.recurringEventLiveStream !== '') {
                this.recurringEventLiveStream = this.item.recurringEventLiveStream
            }
            // Assigned To
            if (this.item.ownerUserId !== '' && this.item.ownerUserId !== undefined && this.item.ownerUserId !== null) {
                this.selectedOwner = { ownerUserId: this.item.ownerUserId, ownerUserName: this.item.ownerUserName, ownerUserEmail: this.item.ownerUserEmail };
            } else {
                this.selectedOwner = this.MIX_getCurrentOwner();
            }


            // assign recurring 
            if (this.item.recurringEventFrequency !== '') {
                this.eventRepeatable = this.item.recurringEventFrequency
            }
            if (this.item.recurringEventCount !== '') {
                this.eventRepeat = this.item.recurringEventCount
            }
            if (this.item.recurringEventDays !== '') {
                this.eventDays = JSON.parse(this.item.recurringEventDays)
            }
            if (this.item.recurringEventInterval !== '') {
                this.eventInterval = this.item.recurringEventInterval
            }
            if (this.item.recurringEventEnd !== '') {
                this.eventEnd = this.item.recurringEventEnd
            }
            if (this.item.recurringRuleEndDateTime !== '') {
                this.date3 = moment(this.item.recurringRuleEndDateTime, 'X').format('YYYY-MM-DD')
            }

        },
        // Clear Item Property
        clearItem(key, value) {
            setTimeout(() => {
                this.item[key] = value;
            }, 1);
        },
        // Clear Item Property
        clearContactName(key, value) {
            setTimeout(() => {
                this.item[key] = value;
            }, 1);
        },
        isEventAllDay() {
            if (!this.showStartTime || !this.showEndTime) {
                this.item.recurringEventAllDay = '1'
                return true
            }

            this.item.recurringEventAllDay = '0'
            return false
        },
        assignDate1() {
            this.item.recurringRuleStartDateTime = this.$moment(this.date1, 'YYYY-MM-DD').toISOString();
            if (this.isEventAllDay()) {
                this.item.recurringEventStartDateTime = this.$moment(this.date1, 'YYYY-MM-DD').toISOString();
                return
            }

            var startDateTime = '';

            const eventStartTimeHours = this.isDateValid(this.eventStartTimeHours) ? this.eventStartTimeHours : this.eventTimeDefaults.eventStartTimeHours;
            const eventStartTimeMins = this.isDateValid(this.eventStartTimeMins) ? this.eventStartTimeMins : this.eventTimeDefaults.eventStartTimeMins;

            startDateTime = eventStartTimeHours + ':' + eventStartTimeMins
            startDateTime = startDateTime + ' ' + this.date1;

            this.item.recurringEventStartDateTime = this.$moment(startDateTime, 'HH:mm YYYY-MM-DD').toISOString();
        },
        assignDate2() {
            if (this.isEventAllDay()) {
                this.item.recurringEventEndDateTime = this.$moment(this.date2, 'YYYY-MM-DD').toISOString();
                return
            }
            // TODO _EB
            var endDateTime = '';

            const eventEndTimeHours = this.isDateValid(this.eventEndTimeHours) ? this.eventEndTimeHours : this.eventTimeDefaults.eventEndTimeHours;
            const eventEndTimeMins = this.isDateValid(this.eventEndTimeMins) ? this.eventEndTimeMins : this.eventTimeDefaults.eventEndTimeMins;

            endDateTime = eventEndTimeHours + ':' + eventEndTimeMins;
            endDateTime = endDateTime + ' ' + this.date2;

            this.item.recurringEventEndDateTime = this.$moment(endDateTime, 'HH:mm YYYY-MM-DD').toISOString();
        },
        formatTag(item) {
            if (item !== null) {
                return item.toLowerCase();
            } else {
                return '';
            }
        },
        async getUsers(searchQuery) {
            let users = await this.MIX_redisSearch("idx:users", searchQuery, 0, 9999);
            console.log('users = ' + JSON.stringify(users, null, 2));
            if (users.code === 1) {

                const transformedResult = users.data.documents.map(doc => {
                    return {
                        id: doc.id,
                        ...doc.value
                    };
                });
                this.users = transformedResult.map((x) => {
                    return {
                        ownerUserId: x.id,
                        ownerUserName: x.userName,
                        ownerUserEmail: x.userEmail,
                        orgId: x.orgId,
                    };
                });
                this.users.sort((a, b) => a.ownerUserName.localeCompare(b.ownerUserName));
            }
        },
    },
    // * WATCH
    watch: {
        // watch recurring fields to trigger new string when anything is changed
        eventRepeatable: {
            handler: function () {
                this.getRecurringInfo();
            },
        },
        eventInterval: {
            handler: function () {
                this.getRecurringInfo();
            },
        },
        eventRepeat: {
            handler: function () {
                this.getRecurringInfo();
            },
        },
        eventDays: {
            handler: function () {
                this.getRecurringInfo();
            },
        },
        eventRepeventEndeatable: {
            handler: function () {
                this.getRecurringInfo();
            },
        },
        eventEnd: {
            handler: function () {
                this.getRecurringInfo();
            },
        },
        datadown: {
            handler: function () {
                this.loadDataDown();
            },
            deep: true,
        },
        selectedOrganisation: {
            handler: function () {
                this.item.orgId = this.selectedOrganisation.orgId;
                this.item.orgName = this.selectedOrganisation.orgName;
                var searchQuery = `@deleted:0 @orgId:{${this.selectedOrganisation.orgId}}`;
                this.getUsers(searchQuery)
            },
            deep: true,
        },
        editmode: {
            handler: function () {
                this.editMode = this.editmode;
            },
        },
        // Selected Tags
        selectedTags: {
            handler: async function () {
                for (let i = 0; i < this.selectedTags.length; i++) {
                    this.selectedTags[i] = this.selectedTags[i].toLowerCase();
                    this.selectedTags[i] = this.selectedTags[i].replace(/[\W_]+/g, ' ');
                    if (this.selectedTags[i] === ' ') {
                        this.selectedTags.splice(i, 1);
                    } else {
                        this.selectedTags[i] = this.selectedTags[i].trim();
                    }
                }
                // Update Content Tags and split out array with commas
                this.item.eventTags = this.selectedTags.join(',');
            },
            deep: true,
        },
        recurringEventLiveStream: {
            handler: async function () {
                var t = this;
                if (t.recurringEventLiveStream === '1') {
                    let result = await this.MIX_generateLiveStreamURL();
                    if (result.code === 1) {
                        t.item.eventPlaybackId = result.data.playback_ids[0].id;
                        t.item.eventStreamKey = result.data.stream_key
                        t.item.recurringEventLiveStreamId = result.data.id
                    }
                } else if (t.recurringEventLiveStream === '0') {
                    t.item.eventPlaybackId = "";
                    t.item.eventStreamKey = ""
                    t.item.recurringEventLiveStreamId = ""
                }
            }
        }
    },
    // * CREATED
    async created() {
        let t = this;
        t.loading = true;
        // Get Tags
        let eventTags = await t.MIX_redisReadSet('set:contentTags', false);
        if (eventTags.code === 1) {
            t.eventTags = eventTags.data;
        }
        // Get Organisations
        let organisations = await t.MIX_organisations();
        t.organisations = organisations.map(organisation => ({
            orgId: organisation.id,
            orgName: organisation.orgName
        }))
        // Get Users
        // let users = await this.MIX_users(''); // Add Users to Dropdown for Owner
        // this.users = users.map((x) => {
        //     return {
        //         ownerUserId: x.id,
        //         ownerUserName: x.userName,
        //         ownerUserEmail: x.userEmail,
        //     };
        // });
        var searchQuery = `@deleted:0 @orgId:{${this.selectedOrganisation.orgId}}`;
        this.getUsers(searchQuery)
        // Event Types
        let eventTypes = await t.MIX_redisReadSet('set:eventTypes', false);
        if (eventTypes.code === 1) {
            t.eventTypes = eventTypes.data;
        }
        // Event Status
        let eventStatus = await t.MIX_redisReadSet('set:eventStatus', false);
        if (eventStatus.code === 1) {
            t.eventStatus = eventStatus.data;
        }

        // Get Content Template - JSON
        let contentAlias = await t.MIX_redisReadSet('set:contentAlias', false);
        if (contentAlias.code === 1) {
            t.contentAlias = contentAlias.data;
        }

        // Load the Data into Item
        this.loadDataDown();
        t.loading = false;
    },
};
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0px;
}
.table-container {
  height: 300px;
  overflow-y: auto;
}

.scrollable-body {
  max-height: 250px;
  overflow-y: auto;
}
</style>
