<template>
    <div style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow-y: scroll !important; overflow-x: hidden !important">
        <v-row>
            <v-col cols="12">
                <v-card class="pa-3 elevation-0">
                    <v-card-title class="primary--text font-weight-bold">Downloads</v-card-title>
                    <v-card-subtitle>Please find below a download button for the live stream video. (Please note that this is only available 24 hours after the live stream has finished)</v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-btn :disabled="checkDate()" class="my-2" color="primary" elevation="0" @click="downloadEventDetails">Download Video</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "EventDownloads",
    props: {
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data: () => ({
        downloadId: null,
        staticRendition: 'low.mp4'
    }),
    watch: {
        datadown () {
            this.getDownload();
        }
    },
    methods: {
        checkDate() {
            let t = this;
            var format = 'ddd, DD MMM YYYY HH:mm:ss ZZ';
            const now = t.$moment().format(format);
            const futureDate = t.$moment.unix(this.datadown.eventStartDateTime).add(2, 'hours').format(format);
            if (t.$moment(now).isBefore(futureDate)) {
                return true
            } else {
                return false
            }
        },
        async getDownload() {
            let t = this;
            const fetchDownload = await t.MIX_getLiveStreamDownload("1", this.datadown.eventLiveStreamId)
            fetchDownload.data.forEach(item => {
                var format = 'ddd, DD MMM YYYY HH:mm:ss ZZ';
                const dateCheck = t.$moment.unix(t.datadown.eventStartDateTime).format(format);
                const muxDateStreamed = t.$moment.unix(item.created_at).format(format);
                const result = t.$moment(dateCheck).isSame(muxDateStreamed, 'day');

                if(result && item.status === "ready") {
                    this.downloadId = item.playback_ids[0].id;
                    this.staticRendition = item?.static_renditions?.files[0]?.name || "low.mp4"
                }
            })
        },
        downloadEventDetails() {
            window.open(`https://stream.mux.com/${this.downloadId}/${this.staticRendition}?download=${this.datadown.eventTitle}`, '_blank');         
            this.downloadId = null;
            this.$emit('updatevent', this.datadown)
        }
    },
    mounted() {
        let t = this;
        t.getDownload();
    }
}
</script>