/* eslint-disable */
import { doc, setDoc, getDoc, getDocs, deleteDoc, collection, query, where, orderBy, limit, startAt } from "firebase/firestore";
import { mapGetters } from "vuex";
import moment from "moment";

const mixin = {
	data: () => ({
        apiUrl: process.env.VUE_APP_OOMPHSERVER,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
		apiOptions() {
            return {
                headers: {
                    Authorization: `Bearer ${this.GET_firebase_userAccess}`,
                },
            };
        },
    },
	methods: {
		//* Create a new firebase auth user
		async MIX_firebaseAuth_register(document) { 
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
						let t = this;
						let dateTimeNow = moment().format("x");
						document.createdDateTime = dateTimeNow;
						document.createdUserId = this.GET_FIREBASE_userAuth.uid;
						document.createdUserName = this.GET_FIREBASE_userAuth.displayName;
						document.createdUserEmail = this.GET_FIREBASE_userAuth.email;
						document.photoURL = '';
											return this.$axios.post(this.apiUrl + `/firebase/createuser`, { data: document }, t.apiOptions).then((result) => {
                            if (result.data.code == 1) {
                                document.id = result.data.data.uid;
                                var key = 'user:' + document.id;
                                delete document.userPassword;
																resolve(result.data)
                                // this.$axios.post(this.apiUrl + `/createhash?key=${key}`, document, t.apiOptions).then((response) => {
                                //     resolve(response);
                                // })
                            } else {
															resolve(result.data)
                            }
                        })
                    } catch (error) {
						console.log(error)
                        return { code: -1, message: "Error Creating Account", data:null, error: error }
                    }
                })();
            });
		},
		//* Update a firebase auth user
		async MIX_firebaseAuth_update(document) { 
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
						let t = this;
						let dateTimeNow = moment().format("x");
						document.modifiedDateTime = dateTimeNow;
						document.modifiedUserId = this.GET_FIREBASE_userAuth.uid;
						document.modifiedUserName = this.GET_FIREBASE_userAuth.displayName;
						document.modifiedUserEmail = this.GET_FIREBASE_userAuth.email;
											return this.$axios.post(this.apiUrl + `/firebase/updateuser`, { data: document }, t.apiOptions).then((result) => {
                            if (result.data.code == 1) {
								document.photoURL = '0';
								document.emailVerified = '0';
								document.disabled = '0';
                                // this.$axios.post(this.apiUrl + `/updatehash?key=${document.key}`, document, t.apiOptions).then((response) => {
                                //     resolve(response);
                                // })
																resolve(result.data);
                            } else {
                                resolve(result.data)
                            }
                        })
                    } catch (error) {
						console.log(error)
                        return { code: -1, message: "Error Updating Account", data:null, error: error }
                    }
                })();
            });
		},
		//* Destroy a Firestore Document
		async MIX_firestore_destroy(document) {
            return new Promise((resolve, reject) => {
                ( async () => {
                    try {
						let t = this;
                        return this.$axios.post(this.apiUrl + `/firebase/destroyuser?id=${document.id}&level=${document.userLevel}`, t.apiOptions).then((result) => {
                            if (result.data.code == 1) {
								resolve(result);
                            } else {
                                resolve(result)
                            } 

                        })
                    } catch (error) {
                        resolve({ code: -1, message: "Error Destroying Account", data: null, error: error })
                    }
                })()
            })
		},
		async MIX_firestore_create(document, collectionName) {
            try {
				// Add a new document in collectionName requires id to be passed
                return await setDoc(doc(this.$firebase.db, collectionName, document.id), document).then(() => {
                    return { code: 1, mesage: "Firestore Document Created Successfully", data: null, error: null };
                });
            } catch (error) {
                return { code: -1, mesage: "Error Occured Creating Firestore Document", data: null, error: error };
            }

		},
		// Read a Firestore Document
		async MIX_firestore_read(documentId, collectionName) {
			try {
				// Reads a document in collectionName requires id to be passed
				const docRef = doc(this.$firebase.db, collectionName, documentId);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					return { code: 1, mesage: "Firestore Document Read Successfully", data: docSnap.data(), error: null };
				} else {
					return { code: 0, mesage: "Firestore Document does not exist", data: null, error: null };
				}
			} catch (error) {
				return { code: -1, mesage: "Error Occured Reading Firestore Document", data: null, error: error };
			}
		},
		// Read Many Firestore Documents
		async MIX_firestore_readMany(collectionName, showDeleted, orderField, orderDirection, limitNumber) {
			try {
				// Reads all Documents in the Firestore collectionName
				let q = null
				let conditions = []
				let documents = [];
				const collectionRef = collection(this.$firebase.db, collectionName);
				if (orderField !== null && orderField !== undefined) {
					if (orderDirection === 'desc') { 
						conditions.push(orderBy(orderField, orderDirection))
					} else {
						conditions.push(orderBy(orderField))
					}
				}
				if (limitNumber !== null && limitNumber !== undefined) {
					conditions.push(limit(limitNumber))
				}
				q = query(collectionRef);
				if (conditions.length === 0) {
					q = query(collectionRef);
				} else {
					q = query(collectionRef, ...conditions);
				}
				let querySnapshot = await getDocs(q)
				// Loop through Results
				querySnapshot.forEach((doc) => {
					if (showDeleted) {
						documents.push(doc.data())
					} else {
						if (!doc.data().deleted) {
							documents.push(doc.data());
						}	
					}
				})
				return { code: 1, mesage: "Firestore Documents Read Successfully", data: documents, error: null };
					
			} catch (error) {
				console.log(error)
				return { code: -1, mesage: "Error Occured Reading Firestore Documents", data: null, error: error };
			}
		},
		// Read Many Firestore Documents
		async MIX_firestore_readManyWhere(collectionName, showDeleted, whereConditions, limitNumber) {
			try {
				// Reads all Documents in the Firestore collectionName where conditions are defined
				let q = null
				let conditions = []
				let documents = [];
				const collectionRef = collection(this.$firebase.db, collectionName);
				// // Loop through and add Where Clauses to Conditions
				for (var i = 0; i < whereConditions.length; i++) {
					conditions.push(where(whereConditions[i].field, whereConditions[i].operator, whereConditions[i].value));
				}
				if (limitNumber !== null && limitNumber !== undefined) {
					conditions.push(limit(limitNumber))
				}
				q = query(collectionRef);
				if (conditions.length === 0) {
					q = query(collectionRef);
				} else {
					q = query(collectionRef, ...conditions);
				}
				let querySnapshot = await getDocs(q)
				// Loop through Results
				querySnapshot.forEach((doc) => {
					if (showDeleted) {
						documents.push(doc.data())
					} else {
						if (!doc.data().deleted) {
							documents.push(doc.data());
						}	
					}
				})
				return { code: 1, mesage: "Firestore Documents Read Successfully", data: documents, error: null };
					
			} catch (error) {
				console.log(error)
				return { code: -1, mesage: "Error Occured Reading Firestore Documents", data: null, error: error };
			}
		},		

		// Update a Firestore Document
		async MIX_firestore_update(document, collectionName, fields) {
			try {
				// Updates a document in collectionName requires id to be passed
				const documentRef = doc(this.$firebase.db, collectionName, document);
				return await setDoc(documentRef, fields, { merge: true }).then(() => {
					return { code: 1, mesage: "Firestore Document Updated Successfully", data: null, error: null };
				});
			} catch (error) {
				return { code: -1, mesage: "Error Occured Updating Firestore Document", data: null, error: error };
			}
		},
		// Delete a Firestore Document (Mark as Delete)
		async MIX_firestore_delete(document, collectionName, fields) {
			try {
				// Updates a document in collectionName requires id to be passed
				const documentRef = doc(this.$firebase.db, collectionName, document);
				return await setDoc(documentRef, fields, { merge: true }).then(() => {
					return { code: 1, mesage: "Firestore Document Deleted Successfully", data: null, error: null };
				});
			} catch (error) {
				return { code: -1, mesage: "Error Occured Deleting/Updating Firestore Document", data: null, error: error };
			}
		},
	},
};

export default {
	install(Vue) {
		Vue.mixin(mixin);
	},
};
