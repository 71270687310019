<template>
    <div>
			<div class="d-flex align-start mb-1">
				<div class="d-flex align-start">
                    <div><v-icon class="primary--text" x-large>oomph-news</v-icon></div>
					<div class="text-left primary--text text-h4 font-weight-thin pl-1" >News</div>
				</div>
			</div>
        <div class="my-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field 
                    label="Search Content" 
                    v-model="searchQuery" 
                    outlined 
                    dense 
                    hide-details="auto" 
                    background-color="white" 
                    color="primary" 
                    clearable 
                    prepend-inner-icon="oomph-search" 
                    @click:clear="clearSearchQuery()"
                    @keyup="datatableOptions.page = 1">
                </v-text-field>
                <!-- <div class="ml-1 mt-1 body-2 app--text">
                    Please type two or more Characters
                </div> -->
            </div>
            <!-- Refresh Button -->
            <div v-if="!listshrunk" class="ml-2">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="refreshItems">
                    <v-icon>oomph-available-updates</v-icon>
                </v-btn>
            </div>
            <!-- Column Selector -->
            <div class="ml-2">
                <v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>oomph-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
            
        </div>
        <!-- <pre>{{ GET_FIREBASE_userAccessToken }}</pre> -->
        
        <!-- Content Status -->
        <v-row class="mb-4 px-3 align-center justify-center">
            <v-btn-toggle multiple v-model="filter.status" class="mt-4 appbackground d-flex align-center flex-wrap" @change="datatableOptions.page = 1">
                <v-btn value="Draft" active-class="grey darken-1 white--text" depressed small>Draft</v-btn>
                <v-btn value="Live" active-class="success white--text" depressed small>Live</v-btn>
                <v-btn value="Archived" active-class="black white--text" depressed small>Archived</v-btn>
            </v-btn-toggle>
            <v-spacer />
			<div class="ml-4 pt-4">
                <v-btn depressed block class="primary darken-2 white--text text--darken-2" style="border: 1px solid grey !important;" @click="openItem({})" >
                    <v-icon>oomph-duplicate</v-icon>
                    <span v-if="!listshrunk" class="ml-3">New</span>
                </v-btn>
            </div>
        </v-row>
        <div class="mt-2 mb-12">
            <!-- TABLE BASED LIST -->
            <v-data-table
                v-if="!listshrunk"
                sortable
                dense
                class="" 
                :headers="computedHeaders" 
                :items="computedItems" 
                :server-items-length="itemsTotal"
                :loading="loading"
                loading-text="Loading Content"
                :options.sync="datatableOptions"
                :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 50, 100] }"
                >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <!-- TODO - HIGH - SECURITY - Hide/Show Buttons -->
                        <!-- 
							Show Delete / Restore Button for Staff-Directors / StaffManagers / StaffAdmin / DeveloperAdmin
							Show Destroy Option Button DeveloperAdmin Only
						-->
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <!-- <v-btn title="Permanently Delete" value="destroy" small>
                                <v-icon class="error--text">oomph-delete-trash</v-icon>
                            </v-btn> -->
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                            <!-- <v-btn title="Undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                            </v-btn> -->
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:item.contentTitle="{ item }">
                    <span :title="item.contentTitle" v-if="item.contentTitle !== undefined">{{ item.contentTitle.substring(0, 30) }}...</span>
                </template>
                <template v-slot:item.contentAvailableFrom="{ item }">
                    <span :title="item.contentAvailableFrom" v-if="item.contentAvailableFrom !== undefined">{{ MIX_formatDateTime(item.contentAvailableFrom, 'X', 'DD-MMM-YYYY') }}</span>
                </template>
                <template v-slot:item.contentAvailableTo="{ item }">
                    <span :title="item.contentAvailableTo" v-if="item.contentAvailableTo !== undefined">{{ MIX_formatDateTime(item.contentAvailableTo, 'X', 'DD-MMM-YYYY')}}</span>
                </template>
                <template v-slot:item.contentSubtitle="{ item }">
                    <span :title="item.contentSubtitle" v-if="item.contentSubtitle !== undefined">{{ item.contentSubtitle.substring(0, 40) }}...</span>
                </template>
				<template v-slot:item.contentDifficulty="{ item }">
					<v-chip v-if="item.contentDifficulty === 'difficult'" small class="error white--text font-weight-bold">Difficult</v-chip>
					<v-chip v-if="item.contentDifficulty === 'medium'" small class="warning white--text font-weight-bold" >Medium</v-chip>
					<v-chip v-if="item.contentDifficulty === 'easy'" small class="success white--text font-weight-bold" >Easy</v-chip>
				</template>
                <template v-slot:item.contentStatus="{ item }">
                    <v-chip small v-if="item.contentStatus === 'Draft'" class="grey darken-1 font-weight-bold" dark>{{ item.contentStatus }}</v-chip>
                    <v-chip small v-if="item.contentStatus === 'Pending'" class="warning font-weight-bold" dark>{{ item.contentStatus }}</v-chip>
                    <v-chip small v-if="item.contentStatus === 'Rejected'" class="error font-weight-bold" dark>{{ item.contentStatus }}</v-chip>
                    <v-chip small v-if="item.contentStatus === 'Approved'" class="info font-weight-bold" dark>{{ item.contentStatus }}</v-chip>
                    <v-chip small v-if="item.contentStatus === 'Live'" class="success font-weight-bold" dark>{{ item.contentStatus }}</v-chip>
                    <v-chip small v-if="item.contentStatus === 'Archived'" class="black font-weight-bold" dark>{{ item.contentStatus }}</v-chip>
                </template>
                <template v-slot:item.contentDifficulty="{ item }">
                    <span>{{ item.contentDifficulty.charAt(0).toUpperCase() + item.contentDifficulty.slice(1) }}...</span>
                </template>

                
                <template v-slot:item.contentViews="{ item }">
                    <span v-if="item.contentViews !== ''">{{ item.contentViews }}</span>
                    <span v-else-if="item.contentViews === ''">-</span>
                </template>
                <template v-slot:item.contentLikes="{ item }">
                    <span v-if="item.contentLikes !== ''">{{ item.contentLikes }}</span>
                    <span v-else-if="item.contentLikes === ''">-</span>
                </template>
                <template v-slot:item.contentReviews="{ item }">
                    <v-rating
                        empty-icon="oomph-star"
                        full-icon="oomph-star-2"
                        length="5"
                        size="20"
                        background-color="grey"
                        color="primary"
                        class="mr-6"
                        :value="parseInt(item.contentRating)"
                        readonly
                        dense
                    ></v-rating>
                </template>
                <template v-slot:item.contentDownloads="{ item }">
                    <span v-if="item.contentDownloads !== ''">{{ item.contentDownloads }}</span>
                    <span v-else-if="item.contentDownloads === ''">-</span>
                </template>
                <template v-slot:item.contentRating="{ item }">
                    <span v-if="item.contentRating !== ''">{{ item.contentRating }}</span>
                    <span v-else-if="item.contentRating === ''">-</span>
                </template>





                <template v-slot:item.action="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="confirmDestroy.id = item.id ;confirmDialog = confirmDestroy">oomph-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="confirmDelete.id = item.id ;confirmDialog = confirmDelete" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon>
                    <v-icon v-if="!popup" class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>

                    <div v-if="popup" class="d-flex py-1">
                        <v-btn v-if="popup" disabled class="primary white--text font-weight-normal mr-1" depressed @click="saveItem(item)"><span>View Content</span> </v-btn>
                        <v-btn v-if="popup" class="primary white--text font-weight-normal" depressed @click="addLink(item)"><span>Add Link</span> </v-btn>
                    </div>
                </template>
            </v-data-table>
            <!-- CARD BASED LIST -->
            <div v-if="listshrunk" style="height: 67vh" class="">
                <v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
                    <div class="d-flex align-center ml-1">
                        <div class="ml-3">
                            <div class="body-2 font-weight-bold" style="width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.contentTitle }}</div>
                            <div class="body-2" style="width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.contentSubtitle }}</div>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="text-right mr-3">
                            <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>
                        </div>
                    </div>
                </v-card>
                <v-btn v-if="listshrunk" class="mt-2 grey lighten-3" depressed block @click="loadMore()">Load More</v-btn>
        
            </div>
            <!-- LOAD MORE CARDS -->
        </div>
        <confirm-dialog :datadown="confirmDialog" v-on:method="confirmMethodHandler" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
        orgType: {
            type: String,
            default: '',
        },
        orgid: {
            type: String,
            default: '',
        },
        popup: {
            type: Boolean,
            default: false,
        },
        eventId: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        key: 'content:details',
        index: 'idx:content:details',
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        itemsFrom: 0,
        itemsTo: 15,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        showStats: false,
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', align: 'start', value: 'id', hidden: true },
            { text: 'Title', value: 'contentTitle', hidden: false, expanded: false, showStats: true },
            { text: 'Subtitle', value: 'contentSubtitle', hidden: false, expanded: false },
            { text: 'Short Description', value: 'contentDescription', hidden: true, expanded: true },
            { text: 'Available From', value: 'contentAvailableFrom', hidden: false, expanded: true },
            { text: 'Available To', value: 'contentAvailableTo', hidden: true, expanded: true },
            { text: 'Tags', value: 'contentTags', hidden: true, expanded: true },
            { text: 'Content Partner', value: 'orgName', hidden: false, expanded: false },
            { text: 'Area', value: 'contentArea', hidden: false, expanded: false },
            { text: 'Views', value: 'contentViews', hidden: true, expanded: false, showStats: true },
            { text: 'Likes', value: 'contentLikes', hidden: true, expanded: false, showStats: true },
            { text: 'Reviews', value: 'contentReviews', hidden: true, expanded: false, showStats: true },
            { text: 'Downloads', value: 'contentDownloads', hidden: true, expanded: false, showStats: true },
            { text: 'Template', value: 'contentTemplate', hidden: false, expanded: true },
            { text: 'Difficulty', value: 'contentDifficulty', hidden: false, expanded: true },
            { text: 'Status', value: 'contentStatus', hidden: false, expanded: false },
            { text: 'Allow Download', value: 'contentAllowDownload', hidden: true, expanded: true },
            { text: 'Allow Accessibility', value: 'contentAllowPrint', hidden: true, expanded: true },
            { text: 'Allow Offline', value: 'contentAllowOffline', hidden: true, expanded: true },
            { text: 'Allow PDF Convert', value: 'contentAllowPDFConvert', hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', hidden: true, expanded: true },
            { text: 'Rating', value: 'contentRating', hidden: false, expanded: false, showStats: true },
            { text: 'Action', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false, width: '155px', showStats: true },
        ],
        // Fields to Retrieve from Database
        //fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@contentTitle',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['contentTitle'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        loading: true,
        cardStats: {
            Draft: 0,
            Pending: 0,
            Approved: 0,
            Live: 0,
            Rejected: 0,
            Total: 0,
        },
        
        // [
        //     {
        //         statusName: 'Draft',
        //         statusValue: 0,

        //     },
        //     {
        //         statusName: 'Approved',
        //         statusValue: 0,

        //     },
        //     {
        //         statusName: 'Live',
        //         statusValue: 0,

        //     },
        //     {
        //         statusName: 'Pending',
        //         statusValue: 0,

        //     },
        //     {
        //         statusName: 'Rejected',
        //         statusValue: 0,

        //     },
        //     {
        //         statusName: 'Archived',
        //         statusValue: 0,

        //     }
        // ],

        // productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
        // Dropdowns
        jobLevels: [],
        jobDepartments: [],
        organisations: [],
        tags: [],
        areas: [],
        partners: [],
        difficulty: ['easy', 'medium', 'difficult'],
        filter: {
            tags: [],
            availableFrom: '',
            availableTo: '',
            rating: [],
            status: [],
            partner: '',
            areas: [],
            difficulty: [],
            contentTagsOperator: 'ANY',
        },
        ratings: ['1', '2', '3', '4', '5'],
        jobLevelAutocomplete: '',
        ratingAutocomplete: '',
        tagsAutocomplete: '',
        areaAutocomplete: '',
        difficultyAutocomplete: '',
        contentPartnerAutocomplete: '',
        availableFromFilter: '',
        availableToFilter: '',

        tagContentTags: '',
        contentTags: [],

        date1: '',
        dateMenu1: false,
        date2: '',
        dateMenu2: false,
        // Dialogs
        confirmDialog: {},
        confirmDestroy: {
            show: true,
            title: 'WARNING - Destroy Content',
            message: 'Are you sure you want to destroy this piece of content? <p class="mt-2 error--text font-weight-bold">This Action is Permanent and Cannot be Undone.</p>',
            color: 'error',
            actionText1: 'No',
            actionText2: 'Yes',
            id: '',
            method: 'destroyItem',
            action: '',
        },
        confirmDelete: {
            show: true,
            title: 'WARNING - Delete Content',
            message: 'Are you sure you want to delete this piece of content? <p class="mt-2 warning--text font-weight-bold">You can restore this using undelete.</p>',
            color: 'warning',
            actionText1: 'No',
            actionText2: 'Yes',
            id: '',
            method: 'deleteItem',
            action: '',
        },                
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        userLevel() {
            return this.GET_FIREBASE_userAccessToken.user_level;
        },
        userGroup() {
            return this.GET_FIREBASE_userAccessToken.user_group;
        },
        userStatus() {
            return this.GET_FIREBASE_userAccessToken.user_status;
        },
		computedDate1() {
			return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
		},
		computedDate2() {
			return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
		},  
        alphaOptions() {
            let alphaOptions = [];
            for (let i = 0; i < this.alphabet.length; i++) {
                alphaOptions.push(this.filter.alpha + this.alphabet[i] + '*');
            }
            return alphaOptions;
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:0';
                }
            } else {
                // this.datatableOptions.page = '1'; // added
                
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }

            var orgId = this.GET_FIREBASE_userAccessToken.user_id
                
            searchQuery = `${searchQuery}  @createdUserId:{${orgId}}`;
            
            this.itemsFrom = 0;
            // this.datatableOptions.page = 1;
            // this.datatableOptions.itemsPerPage = 10
            //console.log("Search Query = " + searchQuery);
            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            if (this.showStats === true) {
                headers = headers.filter(header => header.showStats);
            } else {
                headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            }
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expanded !== true); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedSortBy () {
            let sortBy = this.datatableOptions.sortBy;
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => '@' + x);
                sortBy = sortBy.join(',');
            }
            return sortBy;
        },
        computedSortDesc() {
            let sortDesc = this.datatableOptions.sortDesc[0];
            return sortDesc;
        },
        computedItems() {
            var items = this.items;
            return items;
        },
        computedFields() {
            var computedFields = []
            if (this.showStats === false) {
                computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);   
            } else {
                computedFields = this.headers.filter((x) => x.showStats === true).map((y) => '@' + y.value);   
            }
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@orgId')) {
                computedFields.push('@orgId');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
    },
    methods: {
        paginationChanged(value) {
            //console.log("Pagination Changed = " + JSON.stringify(value, null, 2))
        },
        // * CONFIRM METHOD HANDLER
        confirmMethodHandler(value) {
            this.confirmDialog = {};
            if (value.action === 2) {
                this[value.method](value.id);
            }
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        changeContentTagsOperator() {
            if (this.filter.contentTagsOperator === 'ALL') {
                this.filter.contentTagsOperator = 'ANY';
            } else {
                this.filter.contentTagsOperator = 'ALL';
            }
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // * ADD LINK
        addLink(item) {
            this.$emit('addLink', JSON.parse(JSON.stringify(item)));
        },
        // Refresh Items
        refreshItems() {
            this.getItems();
        },
        async computedCardStats() {
            var t = this;
            var searchQuery = '@deleted:0' ;
            var groupBy = '@contentStatus';
            let itemsResult = await this.MIX_redisAgreggateCount(this.index, searchQuery, groupBy)
            if (itemsResult.code === 1) {
                for (var i = 0; i < itemsResult.data.documents.length; i++) {
                    var item = itemsResult.data.documents[i];
                    var status = item.contentStatus;
                    var count = item.count;
                    if (status === 'Draft') {
                        t.cardStats.Draft = Number(count);
                        t.cardStats.Total = t.cardStats.Total + Number(count);
                    } else if (status === 'Pending') {
                        t.cardStats.Pending = Number(count);
                        t.cardStats.Total = t.cardStats.Total + Number(count);
                    } else if (status === 'Approved') {
                        t.cardStats.Approved = Number(count);
                        t.cardStats.Total = t.cardStats.Total + Number(count);
                    } else if (status === 'Live') {
                        t.cardStats.Live = Number(count);
                        t.cardStats.Total = t.cardStats.Total + Number(count);
                    } else if (status === 'Rejected') {
                        t.cardStats.Rejected = Number(count);
                        t.cardStats.Total = t.cardStats.Total + Number(count);
                    }
                }
                // //console.log("itemsResult = " + JSON.stringify(itemsResult.data, null, 2))
            }
        },
        // * GET ITEMS
        async getItems() {
            this.loading = true;
            if (this.datatableOptions.itemsPerPage === -1) {
                this.datatableOptions.itemsPerPage = this.itemsTotal;
            }
            // //console.log("searchquery = " + JSON.stringify(this.searchQuery, null, 2))
            // if (this.searchQuery.length !== 1 || this.searchQuery === null) {
                let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.computedSortBy, this.computedSortDesc);
                if (itemsResult.code === 1) {
                    this.itemsTotal = itemsResult.data.total;
                    if (this.datatableOptions.itemsPerPage === '-1') {
                        this.datatableOptions.itemsPerPage = this.itemsTotal;
                    }
                    this.items = itemsResult.data.results;
                    let itemsTo = Number(this.datatableOptions.page) * Number(this.datatableOptions.itemsPerPage);
                    if (itemsTo < this.itemsTotal) {
                        this.itemsTo = itemsTo;
                    } else {
                        this.itemsTo = this.itemsTotal;
                    }
                }
            // }
            this.loading = false;
        },
        // * Clear Tag Filter
        clearFilter(key, value) {
            setTimeout(() => {
                this.filter[key] = value;
            }, 1);
        },
        // Clear Selected Org Tags
        clearSelectedContentTags() {
            setTimeout(() => {
                this.filter.tags = [];
            }, 1);
        },
    },
    watch: {
        filter: {
            handler: function () {
                this.itemsFrom = 0;
                this.getItems();
            },
            deep: true,
        },
        refreshitems: function () {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showStats() {
            // // //console.log("showStats firing")
            this.getItems();
        },
        
        computedDate1: {
            handler() {
                // // //console.log("got here1")
                this.filter.availableFrom = this.computedDate1;
            },
        },
        computedDate2: {
            handler() {
                // // //console.log("got here 2")
                this.filter.availableTo = this.computedDate2;
            },
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            //// //console.log('imagesIndex = ' + imagesIndex)
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                // //console.log('datatableOptions updated');
                this.itemsFrom = Number(this.datatableOptions.page) * Number(this.datatableOptions.itemsPerPage) - Number(this.datatableOptions.itemsPerPage);
                // //console.log('itemsFrom = ' + this.itemsFrom);
                let itemsTo = Number(this.datatableOptions.page) * Number(this.datatableOptions.itemsPerPage);
                // //console.log('itemsTo = ' + this.itemsTo);
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                //// //console.log('organisationFrom=' + this.organisationFrom)
                this.getItems();
                // // //console.log("here")
            },
            deep: true,
        },
        listshrunk() {
            if (!this.listshrunk && !this.listexpanded) {
                this.datatableOptions.itemsPerPage = 6;
                this.itemsFrom = 0;
                this.getItems();
            }
            else if (this.listexpanded && this.listshrunk) {
                this.datatableOptions.itemsPerPage = 10;
                this.itemsFrom = 0;
                this.getItems();
            }
        },
        listexpanded() {
            if (this.listexpanded === false) {
                this.datatableOptions.itemsPerPage = 6;
                this.itemsFrom = 0;
                this.getItems();
            } else if (this.listexpanded === true) {
                this.datatableOptions.itemsPerPage = 10;
                this.itemsFrom = 0;
                this.getItems();
            }
        },
    },
    async created() {
        let t = this;
        t.loading = true
        // Get Organisations
        t.organisations = await t.MIX_organisations();
        // // //console.log("organisations = " + JSON.stringify(t.organisations, null,2 ))
        t.organisations = t.organisations.filter((item) => {
            return item.orgAccountType == 'Partner';
        });

        let contentTags = await t.MIX_redisReadSet('set:contentTags', false);
        if (contentTags.code === 1) {
            t.contentTags = contentTags.data;
        }
        
        // Job Levels
        let jobLevels = await t.MIX_redisReadSet('set:jobLevels', false);
        if (jobLevels.code === 1) {
            t.jobLevels = jobLevels.data;
        }
        // Content Areas
        let areas = await t.MIX_redisReadSet('set:contentAreas', false);
        if (areas.code === 1) {
            t.areas = areas.data;
        }
        // Content Partner
        let partners = await t.MIX_redisReadSet('set:contentAreas', false);
        if (partners.code === 1) {
            t.partner = partners.data;
        }
        // Departments
        let jobDepartments = await t.MIX_redisReadSet('set:jobDepartments', false);
        if (jobDepartments.code === 1) {
            t.jobDepartments = jobDepartments.data;
        }
        if (this.popup === true) {
            this.datatableOptions.itemsPerPage = 3;
        }
        this.getItems();
        this.computedCardStats()
    },
};
</script>
