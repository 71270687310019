<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <v-row class="pa-3" dense>
            <v-col cols="12" class="d-flex justify-end align-center">
                <!-- <div class="ml-2">This Quiz has <strong>{{ activequiz.questionTotal }}</strong> questions</div>-->
                <v-switch class="ml-2" dense inset v-model="reorder" :label="`Reorder`" ></v-switch> 
                <v-spacer />
                <v-btn depressed class="primary" @click="newQuestion()">Add Question</v-btn>
            </v-col>
        </v-row>
        <v-row dense class="px-3">
            <v-col cols="12">
            <!-- <pre>{{ activequiz }}</pre> -->
            <drop-list :items="questions" class="" @insert="onInsert" @reorder="onReorder">
                <template v-slot:item="{ item, index }">
                    <drag :data="item" :key="index">
                        <div cols="auto" class="mb-2 d-flex align-start" style="width: 100% !important">
                            <div class="mt-3 mr-2" v-if="reorder"><v-icon class="grey--text">oomph-move-grabber</v-icon></div>
                            <div class="" style="width: 100% !important;">                            
                                <div class="white pa-3 elevation-0 d-flex align-start dnd-no-drag default" style="width: 100% !important; border-radius: 3px !important;">
                                    <div class="mr-3" v-if="item.questionImageId !== '' && !reorder" style="width: 100px; height: 100px; border-radius: 100px !important;">
                                        <v-img :src="'https://storage.googleapis.com/oomph-portal-public/quizzes/questions/images/' + item.id + '/' + item.questionImageId + '.jpg'" cover height="100" max-width="100" style="border-radius: 3px !important;"></v-img>                                       
                                    </div>
                                    <div class="dnd-no-drag">
                                        <div>{{ item.questionOrder }}. {{ item.questionTitle }}</div>
                                        <div class="d-flex align-center mt-2">
                                            <div v-for="(answer, index) in JSON.parse(item.answers)" :key="index">
                                                <div class="d-flex align-start mr-2" v-if="answer.imageId !== ''">
                                                    <div v-if="item.answerAllowABC === '1'">{{ alphabet[index] }}. <span v-if="item.answerAllowImagesText === '1'">{{ answer.text }}</span></div>
                                                    <div class="ml-2 pa-1" style="border: 1px solid lightgrey !important; border-radius: 3px !important;"><v-img v-if="!reorder" :src="'https://storage.googleapis.com/oomph-portal-public/quizzes/questions/images/' + item.id + '/' + answer.imageId + '.jpg'" cover height="40" max-width="40" style="border-radius: 3px !important;"></v-img></div>
                                                </div>
                                                <div><v-chip v-if="answer.imageId === ''" label class="mt-2 mr-1" :class="answer.correct ? 'success' : 'grey lighten-2'">
                                                    <span class="mr-2" v-if="item.answerAllowABC === '1'">{{ alphabet[index] }}. </span>
                                                    {{ answer.text }}
                                                </v-chip></div>
                                            </div>
                                        </div>
                                        <div class="mt-2 body-2">{{ item.answerNotes }}</div>
                                    </div>
                                    <v-spacer />
                                    <div class="d-flex justify-end dnd-no-drag" style="width: 100px !important">
                                        <v-icon @click="editQuestion(item.id)" class="ml-2">oomph-edit</v-icon>
                                        <v-icon @click="deleteQuestion(item.id)" class="ml-2">oomph-trash-can</v-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </drag>
                </template>
            </drop-list>
            </v-col>
        </v-row>
        <!-- <pre class="caption">{{ questions }}</pre> -->
        <v-dialog v-model="dialogQuestion" max-width="1024px">
            <question-item :activequestion="activeQuestion" :newquestion="newquestion" :questionparent="quiz" v-on:close="dialogQuestion = false" v-on:refresh="refreshQuestions" />
        </v-dialog>
    </div>
</template>

<script>
import { Drag, DropList } from 'vue-easy-dnd';
import QuizQuestionItem from '@/views/quizzes/QuizQuestionItem.vue';
export default {
    name: 'QuizQuestions',
    // * COMPONENTS
    components: {
        'question-item': QuizQuestionItem,
        Drag,
        DropList,
    },
    props: {
        refreshquestions: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        activequiz: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data: () => ({
        reorder: false,
        activeQuestion: {},
        type: 'quiz',
        newquestion: false,
        questions: [],
        itemsTotal: null,
        alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        searchQuery: '',
        // activeItem: {},
        dialogQuestion: false,
        // Toggles
        toggleFeatures: [],
        sortBy: '@questionOrder',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['questionOrder'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        quiz: {},
    }),
    computed: {
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            searchQuery = `@questionParentId:{${this.quiz.id}}`;
            return searchQuery;
        },
        computedQuestions() {
            var questions = this.questions;
            if (this.type) {
                questions = this.questions.filter((item) => item.actionType === this.type);
            }
            return questions;
        },
        computedType() {
            const types = [];
            this.questions.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
    },
    methods: {
        initialiseQuiz() {
            this.quiz = { ...this.activequiz };
        },
        newQuestion() {
            this.newquestion = true;
            this.activeQuestion = {};
            this.dialogQuestion = true;
        },
        // On Insert Drag and Drop
        onInsert(event) {
            this.questions.splice(event.index, 0, event.data);
        },
        async onReorder(event) {
            event.apply(this.questions);
            for (var i = 0; i < this.questions.length; i++) {
                this.questions[i].questionOrder = i + 1;
                let result = await this.MIX_createItem(this.questions[i], 'question', false);
            }
        },
        async editQuestion(id) {
            // Get the item from the database
            this.newquestion = false;
            let itemResult = await this.MIX_readItem(id, 'question');
            if (itemResult.code === 1) {
                this.activeQuestion = itemResult.data;
                this.dialogQuestion = true;
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        refreshQuestions: function () {
            this.getQuestions();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * Destroy
        async destroyQuestion(questionId) {
            let result = await this.MIX_destroyItem(questionId, 'question');
            if (result.code === 1) {
                this.getQuestions();
            }
        },
        // * Delete
        async deleteQuestion(questionId) {
            let result = await this.MIX_deleteItem(questionId, 'question');
            if (result.code === 1) {
                this.getQuestions();
            }
        },
        // * Restore
        async restoreQuestion(questionId) {
            let result = await this.MIX_restoreItem(questionId, 'question');
            if (result.code === 1) {
                this.getQuestions();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // * GET ITEMS
        async getQuestions() {
            let questionsResult = await this.MIX_redisOrderedSearch('idx:questions', this.computedSearchQuery, 0, 999, 'questionOrder');
            if (questionsResult.code === 1) {
                this.itemsTotal = questionsResult.data.total;
                this.quiz.questionTotal = this.itemsTotal;
                this.$emit('update', this.quiz);
                this.questions = questionsResult.data.documents.map((question) => question.value);
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.questions = this.questions.filter((item) => item.deleted !== '1');
                this.checkQuestionOrder()
            }
        },
        async checkQuestionOrder() {
            let lastOrder = 0;
            let currentOrder = 0;
            let questions = this.questions;
            let recordsNeedUpdating = false;

            for (let i = 0; i < questions.length; i++) {
                const order = parseInt(questions[i].questionOrder) 
                currentOrder = order;
                
                if ((currentOrder - lastOrder) > 1){
                    recordsNeedUpdating = true;
                    questions[i].questionOrder = lastOrder + 1;
                    lastOrder = lastOrder + 1;
                    continue
                }
                
                lastOrder = order
            }
            
            this.questions = questions
            if (recordsNeedUpdating){
                for (var i = 0; i < questions.length; i++) {
                    await this.MIX_createItem(questions[i], 'question', false);
                }
            }
        },
    },
    watch: {
        activequiz() {
            this.initialiseQuiz();
        },
        refreshquestions() {
            this.getQuestions();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getQuestions();
        },
        computedFields() {
            this.getQuestions();
        },
    },
    async created() {
        this.initialiseQuiz();
        this.getQuestions();
    },
};
</script>

<style scoped>
    .pointer {cursor: pointer;}
    .default {cursor: default;}
</style>