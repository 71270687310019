<template>
    <div class="px-5 appbackground" style="width: 100% !important; height: calc(100vh - 170px) !important;">
        <div class="d-flex align-center py-5">
            <div v-if="filterBy.length < 1" class="text-h5 font-weight-bold primary--text">Downloads</div>
            <div v-if="filterBy.length == 1" class="text-h5 font-weight-bold primary--text">{{ filterBy[0] }} Downloads</div>
            <div v-if="filterBy.length > 1" class="text-h5 font-weight-bold primary--text">Total Downloads</div>
            <v-spacer />
            <div>
                <v-btn class="primary white--text rounded" @click="downloadCSV()" depressed><v-icon class="mr-2">icons8-export-csv</v-icon>Download CSV</v-btn>
            </div>
        </div>
        <v-data-table :loading="loading" must-sort :headers="downloadHeaders" :items="computedDownloadData" :items-per-page="10" class="elevation-0">
            <template v-slot:[`item.dementiaFriendly`]="{ item }">
                {{ item.dementiaFriendly == "1" ? "Yes" : "No" }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        selectedOrgGroup: {
            type: Object,
            default: () => {
                return {};
            },
        },
        selectedSubOrg: {
            type: Array,
            default: () => {
                return [];
            },
        },
        daterange: {
            type: Object,
            default: () => {
                return {
                    startDate: null,
                    endDate: null,
                };
            },
        },
        filterBy: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },    
    data: () => ({
        contentItem: [],
        contentItems: [],
        contentTemplateKey: 0,
        loading: false,
        showContentItem: false,
        downloadData: [],
        downloadHeaders: [
            { text: "Content Title", value: "contentTitle", align: "start" },
            { text: "Content Area", value: "contentArea", align: "start" },
            { text: "Content Alias", value: "contentAlias", align: "start" },
            { text: "Dementia Friendly", value: "dementiaFriendly", align: "center" },
            { text: "Number of Downloads", value: "downloadsTotal", align: "center" },
        ],
    }),
    watch: {
        daterange: {
            handler: function () {
                let t = this;
                t.getDownloadStats();
            },
            deep: true,
        },
        filterBy: {
            handler: function () {
                let t = this;
                t.getDownloadStats();
            },
            deep: true,
        },
    }, 
    computed: {
        computedDownloadData() {
            return this.downloadData.filter(e => e.contentTitle !== '' && e.contentTitle !== null && e.contentTitle !== undefined)
        }
    },
    methods: {
        async getDownloadStats() {
            let t = this;
            let query = "";
            let subOrgs = [];
            t.loading = true;
            const daterange = t.daterange.startDate && t.daterange.endDate ? `@createdDateTime:[${t.daterange.startDate} ${t.daterange.endDate}]` : '';

            if (t.selectedOrgGroup.id === '3RTRBM9dqrkC4WEsIvhx') {
                //* Returns all stats for all orgs
                query = daterange;
            } else {
                //* Returns stats for selected org
                query = `@createdUserOrgId:{${t.selectedOrgGroup.id}} ${daterange}`;
            }
            if (t.selectedSubOrg.length > 0) {
                //* Returns stats for selected sub orgs
                subOrgs = t.selectedSubOrg.map((org) => org.id).join('|');
                query = `@createdUserOrgId:{${subOrgs}} ${daterange}`;
            } 
            let result = await t.MIX_downloads({ searchQuery: query });
            if (result.code === 1) {
                let temp = result.data
                t.downloadData = temp;
            }
            t.loading = false;
        },
        downloadCSV() {
            let t = this;
            const dateFrom = t.$moment.unix(t.daterange.startDate).format('DDMMMYYYY');
            const dateTo = t.$moment.unix(t.daterange.endDate).format('DDMMMYYYY');
            const dateNow = t.$moment().format('DDMMMYYYY');
            const date = t.daterange.startDate && t.daterange.endDate ? `${dateFrom}_${dateTo}` : dateNow;

            var downloads = t.computedDownloadData.map(e => {
                return {
                    'Content Title': e.contentTitle,
                    'Content Area': e.contentArea,
                    'Content Type': e.contentAlias,
                    'Dementia Friendly': e.dementiaFriendly === '1' ? 'Yes' : "No",
                    'Number of Downloads': e.downloadsTotal,
                }
            })
            var downloadCSV = t.$papa.unparse(downloads);
            this.$papa.download(downloadCSV, `Report_Downloads_${date}.csv`, "text/csv");
        },
    },
    created() {
        let t = this;
        t.getDownloadStats();
    },
};
</script>
