<template>
    <div class="" style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''">
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true" :large="!dense"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode" @click="saveItem(item)" :large="!dense"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text px-3" dense style="z-index: 0 !important">
            <v-col cols="12">
                <v-row dense class="d-flex align-center pt-2">
                    <v-col cols="12" sm="12" class="d-flex align-center">
                        <div class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Group Structure</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-divider class="grey lighten-1 mb-1"></v-divider>
                    </v-col>
                </v-row>
                <!-- // * IS THIS ORG A PARENT GROUP -->
                <v-row dense class="d-flex align-center">
                    <v-col cols="6">
                        <div class="text-left grey--text body-2 font-weight-regular">Is this Organisation a Parent Group: </div>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end pa-0 ma-0" >
                        <v-radio-group v-model="item.orgParentGroup" :disabled="!editmode" hide-details="auto" row dense>
                            <v-radio class="grey--text" label="Yes" value="1"></v-radio>
                            <v-radio class="grey--text" label="No" value="0"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>


                <!-- // * IF ORG IS PARENT GROUP, SHOW LIST OF ALL GROUPS IN THE PARENT GROUP -->
                <v-row v-if="item.orgParentGroup == '1' && !editmode">
                    <v-col cols="12">
                        <orgGroupList :datadown="item" v-on:edit="editItem" :listshrunk="listshrunk" :refreshitems="refreshitems" />
                    </v-col>
                </v-row>


                <!-- // * IF ORG IS NOT PARENT GROUP, IS ORG A GROUP -->
                <v-row dense v-if="item.orgParentGroup === '0'" class="d-flex align-center">
                    <v-col cols="6">
                        <div class="text-left grey--text body-2 font-weight-regular">Is this Organisation a Group: </div>
                    </v-col>
                    <v-col cols="6" xs="3" class="d-flex justify-end pa-0 ma-0">
                        <v-radio-group v-model="item.orgGroup" hide-details="auto" :disabled="!editmode" row dense>
                            <v-radio class="grey--text" label="Yes" value="1"></v-radio>
                            <v-radio class="grey--text" label="No" value="0"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>


                <!-- // * IF ORG IS GROUP, IS ORG PART OF PARENT GROUP -->
                <!-- <pre>{{ item.orgGroupId }}</pre> -->
                <v-row dense v-if="item.orgGroup === '1' && item.orgParentGroup === '0'" class="d-flex align-center">
                    <v-col cols="6" xs="4">
                        <div class="text-left grey--text body-2 font-weight-regular">Is this Organisation part of a Parent Group: </div>
                    </v-col>
                    <v-col cols="6" xs="2" class="d-flex justify-end pa-0 ma-0">
                        <v-radio-group v-model="item.partOfParentGroup" hide-details="auto" :disabled="!editmode" row dense>
                            <v-radio class="grey--text" label="Yes" value="1"></v-radio>
                            <v-radio class="grey--text" label="No" value="0"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <!-- // * IF ORG IS PART OF PARENT GROUP, SELECT WHICH PARENT GROUP -->
                    <v-col cols="6" v-if="item.partOfParentGroup === '1'">
                        <v-autocomplete :disabled="!editmode" label="Super Group Organisation" v-model="selectedParentGroup" :items="superGroups" :item-text="item => item.orgName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgGroup')" return-object></v-autocomplete>
                    </v-col>
                </v-row>

                <!-- // * IF ORG IS GROUP, SHOW LIST OF ALL HOMES IN THE GROUP -->
                <v-row v-if="item.orgGroup == '1' && !editmode">
                    <v-col cols="12">
                        <orgHomeList :datadown="item" v-on:edit="editItem" :listshrunk="listshrunk" :refreshitems="refreshitems" />
                    </v-col>
                </v-row>


                <!-- // * IF ORG IS NOT GORUP, IS ORG PART OF GROUP  -->
                <v-row dense v-if="item.orgGroup === '0' && item.orgParentGroup === '0'" class="d-flex align-center">
                    <v-col cols="6" xs="4">
                        <div class="text-left grey--text body-2 font-weight-regular">Is this Organisation part of a Group: </div>
                    </v-col>
                    <v-col cols="6" xs="2" class="d-flex justify-end pa-0 ma-0">
                        <v-radio-group v-model="item.partOfGroup" hide-details="auto" :disabled="!editmode" row dense>
                            <v-radio class="grey--text" label="Yes" value="1"></v-radio>
                            <v-radio class="grey--text" label="No" value="0"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <!-- // * IF ORG IS PART OF GROUP, SELECT WHICH GROUP -->
                    <v-col cols="6" v-if="item.partOfGroup === '1'">
                        <v-autocomplete :disabled="!editmode" label="Group Organisation" v-model="selectedGroup" :items="organisations" :item-text="item => item.orgName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgGroup')" return-object></v-autocomplete>
                    </v-col>
                </v-row>


                <!-- // * IF ORG IS PART OF GROUP, IS ORG PART OF REGION -->
                <v-row dense v-if="item.partOfGroup === '1' && item.orgParentGroup === '0' " class="d-flex align-center">
                    <v-col cols="6" xs="4">
                        <div class="text-left grey--text body-2 font-weight-regular">Is this Organisation part of a Region: </div>
                    </v-col>
                    <v-col cols="6" xs="2" class="d-flex justify-end pa-0 ma-0">
                        <v-radio-group v-model="showRegion" hide-details="auto" :disabled="!editmode" row dense>
                            <v-radio class="grey--text" label="Yes" value=true></v-radio>
                            <v-radio class="grey--text" label="No" value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <!-- // * IF ORG IS PART OF REGION, SELECT WHICH REGION -->
                    <v-col cols="6" v-if="showRegion === 'true'">
                        <v-autocomplete label="Region" :disabled="!editmode" v-model="item.orgRegion" :items="localAuthority" :item-text="item => item.authority" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgGroup')"></v-autocomplete>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import orgGroupList from "@/views/organisations/OrganisationGroupList.vue";
import orgHomeList from "@/views/organisations/OrganisationHomeList.vue";
export default {
    name: 'OrganisationGroup',
    components: {
        "orgGroupList": orgGroupList,
        "orgHomeList": orgHomeList
    },
    props: {
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
        dense: {
            type: Boolean,
            default: false,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        itemnew: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        localAuthority: [],
        countries: [],
        organisations: [],
        index: 'idx:orgs',
        itemsFrom: 0,
        itemsTo: 9,
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['orgName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        sortBy: "@orgName",
        fields: "@id,@orgName,@orgParentGroup",
        key: 'address',

        selectedOrgGroupId: [],

        // toggles
        activeItem: {},

        // dialogs
        dialogAddress: false,
        dialogAddressKey: 0,

        item: {},
        editmode: false,
        // dropdowns
        addressTypes: [],

        selectedGroup: null, 
        selectedParentGroup: null, 

        // rules
        rules: {},
        showGroup: '',
        showRegion: '',
        refreshitems: 0
    }),
    methods: {
        async getAllOrganisations() {
            let t = this;
            let itemsResult = await t.MIX_redisAggregateSearch(t.index, '*', t.itemsFrom, t.datatableOptions.itemsPerPage, t.fields, t.sortBy, false);
            if (itemsResult.code === 1) {
                t.itemsTotal = itemsResult.data.total;
                var organisations = itemsResult.data.results;
                // //console.log("organisations = " + JSON.stringify(t.organisations, null, 2));
                let itemsTo = t.datatableOptions.page * t.datatableOptions.itemsPerPage;
                if (itemsTo < t.itemsTotal) {
                    t.itemsTo = itemsTo;
                } else {
                    t.itemsTo = t.itemsTotal;
                }
                t.superGroups = organisations.filter(item => item.orgParentGroup === '1');
                t.organisations = organisations.filter(item => item.orgParentGroup === '0');
                // //console.log("super groups = " + JSON.stringify(t.orgGroup, null, 2));
            }
        },
        closeAddress() { 
            this.activeItem = {};
            this.dialogAddressKey++
            this.dialogAddress = false; 
            this.refreshitems++
        },
        editItem(value) {
            this.activeItem = value;
            this.dialogAddress = true;
        },
        saveItem(item) {
            this.editmode = false;
            this.$emit('save', item);
        }
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.item = { ...this.datadown };
                if (this.item.orgGroupId !== '' && this.item.orgGroupId !== undefined && this.item.orgGroupId !== null) {
                    this.selectedGroup = { id: this.item.orgGroupId, orgName: this.item.orgGroupName };
                }
                if (this.item.orgParentGroupId !== '' && this.item.orgParentGroupId !== undefined && this.item.orgParentGroupId !== null) {
                    this.selectedParentGroup = { id: this.item.orgParentGroupId, orgName: this.item.orgParentGroupName };
                }
            },
            deep: true,
        },
        selectedGroup: {
            handler: function () {
                this.item.orgGroupId = this.selectedGroup.id;
                this.item.orgGroupName = this.selectedGroup.orgName;
            },
        },
        selectedParentGroup: {
            handler: function () {
                this.item.orgParentGroupId = this.selectedParentGroup.id;
                this.item.orgParentGroupName = this.selectedParentGroup.orgName;
            },
        },
    },
    // * CREATED
    async created() {
        let t = this;
        t.item = { ...this.datadown };
        let localAuthoritiesResult = await this.MIX_redisSearch('idx:localAuthority', `*`, 0, 9999);
        t.localAuthority = localAuthoritiesResult.data.documents.map((x) => x.value);
        t.localAuthority.sort((a, b) => a.authority.localeCompare(b.authority));
        t.countries = Array.from(new Set(t.localAuthority.map((x) => x.country)));
    },
    mounted() {
        let t = this;
        t.getAllOrganisations();
    }
};
</script>