<template>
    <v-card>
        <v-toolbar class="pa-0 ma-0" color="primary" elevation="0">
            <div>
                <v-icon class="white--text">oomph-licence</v-icon>
            </div>
            <div class="text-left white--text text-h5 pl-2 font-weight-light">Add Licence(s)</div>
            <v-spacer />
            <div>
                <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="cancelItem" depressed>
                    <v-icon>oomph-multiply</v-icon>
                </v-btn>
            </div>
        </v-toolbar>
        <v-card-text class="grey lighten-3 px-4">
            <v-row class="py-2 pt-4 px-0" dense>
                <v-col cols="12">
                    <form>
                        <v-row dense>
                            <v-col cols="12" class="d-flex align-center pt-0">
                                <div class="text-left primary--text body-1 font-weight-light">Licence Details</div>
                            </v-col>
                            <!-- Select Component -->
                            <v-col cols="12" xs="12" md="9">
                                <v-autocomplete chips multiple v-model="selectedComponents" item-text="componentName" label="Select Components" return-object :items="components" outlined hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('packageCategory')" />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                                <v-text-field label="No of Seats" v-model="seats" type="number" outlined dense hide-details="auto" persistent-hint hint="Required for Some Components" background-color="white" color="primary" clearable @click:clear="clearDataVariable('seats', '1')"></v-text-field>
                            </v-col>
                            <!-- Group -->
                            <v-col cols="12" xs="12" md="6">
                                <v-autocomplete label="Group" @change="updateHomes" v-model="selectedGroup" :items="groups" auto-select-first item-text="orgName" return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOrg()" />
                                <!-- <br /><pre>{{ selectedGroup }}</pre> -->
                            </v-col>
                            <!-- Home -->
                            <v-col cols="12" xs="12" md="6">
                                <v-autocomplete label="Home" v-model="selectedHome" :items="homes" auto-select-first item-text="orgName" return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOrg()" />
                                <!-- <br /><pre>{{ selectedHome }}</pre> -->
                            </v-col>
                            <!-- Contracts -->
                            <v-col cols="12" xs="12" md="12">
                                <v-autocomplete
                                    label="Contract"
                                    v-model="selectedContract"
                                    @change="
                                        licence.contractId = selectedContract.id;
                                        licenceStartDate = MIX_formatDateTime(selectedContract.contractCommencementDate, 'X', 'YYYY-MM-DD');
                                        licenceExpiryDate = MIX_formatDateTime(selectedContract.contractExpiryDate, 'X', 'YYYY-MM-DD');
                                    "
                                    :items="contracts"
                                    auto-select-first
                                    item-text="contractTitle"
                                    return-object
                                    outlined
                                    dense
                                    hide-details="auto"
                                    background-color="white"
                                    color="primary"
                                    clearable
                                    @click:clear="clearSelectedOrg()"
                                />
                                <span class="grey--text" v-if="selectedContract.contractCommencementDate !== ''"
                                    >Commencement Date: <strong>{{ MIX_formatDateTime(selectedContract.contractCommencementDate, "X", "DD-MMM-YY") }}</strong></span
                                >
                                <span class="grey--text" v-if="selectedContract.contractExpiryDate !== '' && selectedContract.contractExpiryDate !== '0'">
                                    Expiry Date: <strong>{{ MIX_formatDateTime(selectedContract.contractExpiryDate, "X", "DD-MMM-YY") }}</strong></span
                                >
                                <span class="grey--text" v-if="selectedContract.contractExpiryDate === '0'"> Expiry Date: <strong>Not Set</strong></span>

                                <!-- <br /><pre>{{ selectedGroup }}</pre> -->
                            </v-col>
                            <!-- Licence Start Date -->
                            <v-col cols="12" xs="12" md="6">
                                <v-menu v-model="licenceStartDateMenu" :close-on-content-click="false" transition="scale-transition" bottom offset-y max-width="290px" min-width="auto" :attach="true">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field label="Licence Start Date" v-model="computedLicenceStartDate" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearDataVariable('licenceStartDate', '')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="licenceStartDate" full-width no-title color="secondary" :first-day-of-week="1" @input="licenceStartDateMenu = false"></v-date-picker>
                                </v-menu>
                                <span class="warning--text" v-if="selectedContract.contractCommencementDate > licence.licenceStartDate && licence.licenceStartDate !== ''">WARNING - Contract Commencement Date is in the Future</span>
                            </v-col>

                            <!-- Licence Expiry Date -->
                            <v-col cols="12" xs="12" md="6">
                                <v-menu v-model="licenceExpiryDateMenu" :close-on-content-click="false" transition="scale-transition" bottom offset-y max-width="290px" min-width="auto" :attach="true">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field label="Licence Expiry Date" v-model="computedLicenceExpiryDate" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearDataVariable('licenceExpiryDate', '')"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="licenceExpiryDate" full-width no-title color="secondary" :first-day-of-week="1" @input="licenceExpiryDateMenu = false"></v-date-picker>
                                </v-menu>
                                <span class="warning--text" v-if="selectedContract.contractExpiryDate > licence.licenceExpiryDate && selectedContract.contractExpiryDate !== '0' && licence.licenceExpiryDate !== '0'">WARNING - Licence Expiry Date exceeds Contract Expiry Date</span>
                                <span class="warning--text" v-if="selectedContract.contractExpiryDate < licence.licenceExpiryDate && selectedContract.contractExpiryDate !== '0' && licence.licenceExpiryDate !== '0'">WARNING - Licence Expiry Date preceeds Contract Expiry Date</span>
                                <span class="error--text" v-if="licenceExpiryDate < licenceStartDate && licence.licenceExpiryDate !== '0'">ERROR - Licence Expiry Date preceeds Licence Start Date</span>
                            </v-col>
                            <v-col cols="12" xs="12" md="12">
                                <v-text-field label="Comment" v-model="licence.licenceComments" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('addressLine1')"></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" md="6">
                                <pre>{{ selectedComponents }}</pre>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <pre>{{ licence }}</pre>
                            </v-col> -->
                        </v-row>
                    </form>
                    <br /><br /><br /><br /><br /><br /><br /><br />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="grey lighten-1 pa-3 pr-4 d-flex justify-right align-center">
            <v-spacer />
            <div>
                <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
            </div>
            <div class="ml-2">
                <v-btn class="primary white--text font-weight-bold" depressed @click="saveLicences()"> <v-icon class="mr-2">oomph-licence</v-icon><span>Add</span> </v-btn>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import schema from "@/schema.js";
import { mapGetters } from "vuex";

export default {
    props: {
        activeorg: {
            Type: Object,
            Default: () => {},
        },
        activelicence: {
            Type: Object,
            Default: null,
        },
    },
    // * DATA
    data: () => ({
        key: "licence",
        itemNew: true,
        schema: {},
        licence: {},

        org: {},
        // dropdowns
        customers: [],
        groups: [],
        homes: [],
        contracts: [],
        components: [],

        // seats
        seats: "1",

        // dropdown selections
        selectedComponents: [],
        selectedGroup: {
            id: "",
            orgName: "",
            orgAccountType: "",
            orgGroupStructure: "",
        },
        selectedHome: {
            id: "",
            orgName: "",
            orgAccountType: "",
            orgGroupStructure: "",
        },
        selectedContract: {
            id: "",
            contractTitle: "",
            contractCommencementDate: "",
            contractExpiryDate: "",
        },

        // new address object
        item: {},

        // Dates
        licenceStartDateMenu: false,
        licenceStartDate: "", //new Date(Date.now()).toISOString().substr(0, 10),
        licenceExpiryDateMenu: false,
        licenceExpiryDate: "", //new Date(Date.now()).toISOString().substr(0, 10),
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth'
        }),
        // Computed Date
        computedLicenceStartDate: {
            get: function () {
                return this.MIX_formatDateTime(this.licenceStartDate, "YYYY-MM-DD", "DD-MMM-YYYY");
            },
            set: function () {
                // do nothing
            },
        },
        computedLicenceExpiryDate: {
            get: function () {
                return this.MIX_formatDateTime(this.licenceExpiryDate, "YYYY-MM-DD", "DD-MMM-YYYY");
            },
            set: function () {
                // do nothing
            },
        },
    },
    methods: {
        // Initialise
        async initialise() {
            // //console.log("initialise");
            // //console.log("activelicence" + JSON.stringify(this.activelicence, null, 2));
            this.schema = schema.licence;
            if (JSON.stringify(this.activelicence) === "{}") {
                this.licence = this.schema;
            } else {
                this.licence = this.activelicence;
            }
            // Set Audit Field to Current User
            let dateTimeNow = this.$moment().format("x");
            this.licence.createdDateTime = dateTimeNow;
            this.licence.createdUserId = this.GET_FIREBASE_userAuth.uid;
            this.licence.createdUserName = this.GET_FIREBASE_userAuth.displayName;
            this.licence.createdUserEmail = this.GET_FIREBASE_userAuth.email;
            this.licence.modifiedDateTime = dateTimeNow;
            this.licence.modifiedUserId = this.GET_FIREBASE_userAuth.uid;
            this.licence.modifiedUserName = this.GET_FIREBASE_userAuth.displayName;
            this.licence.modifiedUserEmail = this.GET_FIREBASE_userAuth.email;
            this.licence.deletedDateTime = '0';

            // Get Organisations for Customers Dropdown
            let orgsResult = await this.MIX_redisOrderedSearch("idx:orgs", `@deleted:0 @orgAccountTypes:{Customer}`, 0, 9999, "orgName");
            let orgs = orgsResult.data.documents.map((x) => {
                return {
                    id: x.value.id,
                    orgName: x.value.orgName,
                    orgAccountTypes: x.value.orgAccountTypes,
                    orgGroupStructure: x.value.orgGroupStructure,
                    orgGroupId: x.value.orgGroupId,
                };
            });
            this.customers = orgs;
            ////console.log("orgs" + JSON.stringify(orgs, null, 2));
            let groups = orgs.filter((org) => {
                return org.orgGroupStructure === "Group";
            });
            this.groups = groups.map((x) => {
                return {
                    id: x.id,
                    orgName: x.orgName,
                    orgAccountTypes: x.orgAccountTypes,
                    orgGroupStructure: x.orgGroupStructure,
                };
            });
            // Get Homes
            let homes = orgs.filter((org) => {
                return org.orgGroupStructure === "Home" && org.orgGroupId === this.activeorg.orgGroupId;
            });
            // Map Details from Homes
            this.homes = homes.map((x) => {
                return {
                    id: x.id,
                    orgName: x.orgName,
                    orgAccountTypes: x.orgAccountTypes,
                    orgGroupStructure: x.orgGroupStructure,
                    orgGroupId: x.orgGroupId,
                };
            });
            // Set Selected Home
            this.selectedHome = {
                orgId: this.activeorg.id,
                orgName: this.activeorg.orgName,
                orgAccountTypes: this.activeorg.orgAccountTypes,
                orgGroupStructure: this.activeorg.orgGroupStructure,
                orgGroupId: this.activeorg.orgGroupId,
            };
            this.licence.orgId = this.selectedHome.orgId;
            this.licence.orgName = this.selectedHome.orgName;
            // Look up Group Details
            let group = groups.find((org) => {
                return org.id === this.activeorg.orgGroupId;
            });
            // Set Selected Group
            this.selectedGroup = {
                orgId: group.id,
                orgName: group.orgName,
                orgAccountTypes: group.orgAccountTypes,
                orgGroupStructure: group.orgGroupStructure,
            };
            // Get Organisation Contracts
            let contractsResult = await this.MIX_redisOrderedSearch("idx:contracts", `@deleted:0 @orgId:{${this.activeorg.id}|${this.activeorg.orgGroupId}}`, 0, 9999, "contractTitle");
            this.contracts = contractsResult.data.documents.map((x) => {
                return {
                    id: x.value.id,
                    contractTitle: x.value.contractTitle,
                    contractCommencementDate: x.value.contractCommencementDate,
                    contractExpiryDate: x.value.contractExpiryDate,
                };
            });
            // Get Components
            let componentsResult = await this.MIX_redisOrderedSearch("idx:components", `@deleted:0`, 0, 9999, "componentName");
            if (componentsResult.code === 1) {
                this.components = componentsResult.data.documents.map((x) => {
                    return {
                        id: x.value.id,
                        componentName: x.value.componentName,
                        componentType: x.value.componentType,
                        componentCategory: x.value.componentCategory,
                        componentCode: x.value.componentCode,
                    };
                });
            }
        },
        updateHomes() {
            let homes = [...this.customers];
            homes = homes.filter((org) => {
                return org.orgGroupStructure === "Home" && org.orgGroupId === this.selectedGroup.id;
            });
            this.homes = homes.map((x) => {
                return {
                    id: x.id,
                    orgName: x.orgName,
                    orgAccountTypes: x.orgAccountTypes,
                    orgGroupStructure: x.orgGroupStructure,
                    orgGroupId: x.orgGroupId,
                };
            });
        },
        updateItem() {
            this.$emit("update", this.item);
        },
        getAddressData: function (addressData, placeResultData) {
            let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
            // //console.log('placeResult = ' + JSON.stringify(placeResult, null, 2));
            this.item.addressName = placeResult.name;
            this.item.addressLine1 = placeResult.addressLine1;
            this.item.addressLine2 = placeResult.addressLine2;
            this.item.addressLine3 = placeResult.addressLine3;
            this.item.addressTown = placeResult.town;
            this.item.addressCounty = placeResult.county;
            this.item.addressPostcode = placeResult.postcode;
            this.item.addressCountry = placeResult.country;
            this.item.addressLat = placeResult.lat;
            this.item.addressLng = placeResult.lng;
            // //console.log("this.item = " + JSON.stringify(this.item, null, 2));
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = "";
            }, 1);
        },
        // * Save Licences
        async saveLicences() {
            let licences = [];
            for (var i = 0; i < this.selectedComponents.length; i++) {
                let component = this.selectedComponents[i];
                if (component.componentType === "Seat" && component.componentType === "Workshop") {
                    for (var j = 0; j < this.seats; j++) {
                        let licence = {
                            ...this.licence,
                            id: this.MIX_generateId(),
                            componentId: component.id,
                            licenceName: component.componentName,
                            licenceCategory: component.componentCategory,
                            licenceType: component.componentType,
                            licenceCode: component.componentCode
                        };
                        if (licence.licenceStartDate === "") { licence.licenceStartDate = "0" }
                        if (licence.licenceExpiryDate === "") { licence.licenceExpiryDate = "0" }
                        licences.push(licence);
                        // //console.log(JSON.stringify(licence, null, 2));
                    }
                } else {
                    let licence = {
                        ...this.licence,
                        id: this.MIX_generateId(),
                        componentId: component.id,
                        licenceName: component.componentName,
                        licenceCategory: component.componentCategory,
                        licenceType: component.componentType,       
                        licenceCode: component.componentCode                                         
                    };
                    if (licence.licenceStartDate === "") { licence.licenceStartDate = "0" }
                    if (licence.licenceExpiryDate === "") { licence.licenceExpiryDate = "0" }                    
                    licences.push(licence);
                    // //console.log(JSON.stringify(licence, null, 2));
                }
            }
            let bulkLicenceResult = await this.MIX_redisBulkHashUpdate('licence', licences)
            if (bulkLicenceResult.code === 1) {
                this.MIX_alertBox({ color: "success", timeout: 2000, message: "Licences Successfully Added", show: true });
                this.$emit("close")
            } else {
                this.MIX_alertBox({ color: "error", timeout: 4000, message: "Error Adding Licences", show: true });
            }
        },
        // * CREATE ITEM
        async saveItem(item) {
            let t = this;
            // if no id then generate one
            if (item.id !== undefined && item.id !== null && item.id !== "") {
                // do nothing
            } else {
                item.id = t.MIX_generateId();
            }
            let result = await this.MIX_createItem(item, this.key, this.itemNew);
            if (result.code === 1) {
                this.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
            }
            this.item = this.schema;
            this.$emit("close");
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit("close");
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit("close");
        },
    },
    watch: {
        datadown: {
            handler: function () {
                let t = this;
                this.org = { ...this.datadown };
                if (JSON.stringify(t.activeitem) === "{}") {
                    t.item = { ...t.schema };
                    this.item.orgId = this.org.id;
                    this.item.orgName = this.org.orgName;
                } else {
                    t.item = { ...t.activeItem };
                }
            },
            deep: true,
        },
        activelicence: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        // Watch for changes to the licenceStartDateMenu
        licenceStartDateMenu: {
            handler: async function () {
                this.licence.licenceStartDate = this.MIX_formatDateTime(this.licenceStartDate, "YYYY-MM-DD", "X");
            },
            deep: true,
        },
        licenceExpiryDateMenu: {
            handler: async function () {
                this.licence.licenceExpiryDate = this.MIX_formatDateTime(this.licenceExpiryDate, "YYYY-MM-DD", "X");
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        this.initialise();
        // t.org = { ...t.datadown };
        // if (JSON.stringify(t.activeitem) === "{}") {
        //     t.item = { ...t.schema };
        //     t.item.orgId = t.org.id;
        //     t.item.orgName = t.org.orgName;
        // } else {
        //     t.item = { ...t.activeitem };
        // }
    },
    mounted() {},
};
</script>
