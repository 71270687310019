<template>
    <div class="scrollbar-hidden" style="overflow-y: auto; height: calc(100vh - 100px) !important">
        <v-list dense nav>
            <v-list-item-group v-model="selectedItem" color="primary">
                <div v-for="item in groupItems('')" :key="item.title">
                    <v-list-item link v-if="item.userGroups.includes(userGroup) || item.userLevels.includes(userLevel)" :disabled="userStatus !== 'Approved'" @click="$router.push({ path: item.route }).catch((err) => {})">
                        <v-list-item-icon>
                            <v-icon :disabled="userStatus !== 'Approved'" :class="[userStatus !== 'Approved' ? 'grey--text' : 'primary--text']">{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :class="[userStatus !== 'Approved' ? 'grey--text' : 'primary--text']">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
                <div v-for="item in groupItems('CMS')" :key="item.title">
                    <v-list-item link v-if="item.userGroups.includes(userGroup) || item.userLevels.includes(userLevel)" :disabled="userStatus !== 'Approved'" @click="$router.push({ path: item.route }).catch((err) => {})">
                        <v-list-item-icon>
                            <v-icon :disabled="userStatus !== 'Approved'" :class="[userStatus !== 'Approved' ? 'grey--text' : 'primary--text']">{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :class="[userStatus !== 'Approved' ? 'grey--text' : 'primary--text']">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
                <div v-for="item in groupItems('CRM')" :key="item.title">
                    <v-list-item link v-if="item.userGroups.includes(userGroup) || item.userLevels.includes(userLevel)" :disabled="userStatus !== 'Approved'" @click="$router.push({ path: item.route }).catch((err) => {})">
                        <v-list-item-icon>
                            <v-icon :disabled="userStatus !== 'Approved'" :class="[userStatus !== 'Approved' ? 'grey--text' : 'primary--text']">{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :class="[userStatus !== 'Approved' ? 'grey--text' : 'primary--text']">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
                <div v-for="item in groupItems('ADMIN')" :key="item.title">
                    <v-list-item link v-if="item.userGroups.includes(userGroup) || item.userLevels.includes(userLevel)" :disabled="userStatus !== 'Approved'" @click="$router.push({ path: item.route }).catch((err) => {})">
                        <v-list-item-icon>
                            <v-icon :disabled="userStatus !== 'Approved'" :class="[userStatus !== 'Approved' ? 'grey--text' : 'primary--text']">{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :class="[userStatus !== 'Approved' ? 'grey--text' : 'primary--text']">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
                <div v-for="item in groupItems('RPT')" :key="item.title">
                    <v-list-item link v-if="item.userGroups.includes(userGroup) || item.userLevels.includes(userLevel)" :disabled="userStatus !== 'Approved'" @click="$router.push({ path: item.route }).catch((err) => {})">
                        <v-list-item-icon>
                            <v-icon :disabled="userStatus !== 'Approved'" :class="[userStatus !== 'Approved' ? 'grey--text' : 'primary--text']">{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :class="[userStatus !== 'Approved' ? 'grey--text' : 'primary--text']">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data: () => ({
        selectedItem: 0,
        items: [
            { title: 'Brandings', group: 'ADMIN', icon: 'oomph-fill-color', route: '/brandings', userGroups: ['Content', 'Accounts', 'Sales', 'Administration', 'IT'], userLevels: ['DeveloperAdmin', 'StaffAdmin'] },
            { title: 'Calendar', group: 'CMS', icon: 'oomph-calendar', route: '/events', userGroups: ['Content'], userLevels: ['DeveloperAdmin', 'StaffAdmin'] },
            // { title: 'Contacts', group: 'CRM', icon: 'oomph-user-groups', route: '/contacts', userGroups: ['Content', 'Accounts', 'Sales', 'Administration', 'IT'], userLevels: ['DeveloperAdmin', 'StaffAdmin'] },
            { title: 'Content', group: 'CMS', icon: 'oomph-versions', route: '/contents', userGroups: ['Content'], userLevels: ['DeveloperAdmin'] },
            // { title: 'Contracts', group: 'CRM', icon: 'oomph-bill', route: '/contracts', userGroups: ['Content', 'Accounts', 'Sales', 'Administration', 'IT'], userLevels: ['DeveloperAdmin', 'StaffAdmin'] },
            { title: 'Customers', group: 'CRM', icon: 'oomph-hotel-building', route: '/customers', userGroups: ['Content', 'Accounts', 'Sales', 'Administration', 'IT'], userLevels: ['DeveloperAdmin', 'StaffAdmin'] },
            { title: 'Home', group: '', icon: 'oomph-home-page', route: '/home', userGroups: ['Customer', 'Content', 'Accounts', 'Sales', 'Administration', 'IT', 'Developers', 'Home'], userLevels: ['DeveloperAdmin', 'StaffAdmin', 'GroupUser']  },
            { title: 'News', group: 'CMS', icon: 'oomph-news', route: '/news', userGroups: ['Customer'], userLevels: ['GroupUser'] },
            { title: 'Organisations', group: 'ADMIN', icon: 'oomph-city', route: '/organisations', userGroups: ['Content', 'Accounts', 'Sales', 'Administration', 'IT'], userLevels: ['DeveloperAdmin', 'StaffAdmin'] },
            { title: 'Partners', group: 'CMS', icon: 'oomph-factory', route: '/partners', userGroups: ['Content'], userLevels: ['DeveloperAdmin', 'StaffAdmin'] },
            { title: 'Polls', group: 'CMS', icon: 'oomph-poll', route: '/polls', userGroups: ['Customer'], userLevels: ['GroupUser'] },
            { title: 'Release Notes', group: 'ADMIN', icon: 'oomph-info', route: '/releasenotes', userGroups: ['Content', 'Accounts', 'Sales', 'Administration', 'IT'], userLevels: ['DeveloperAdmin', 'StaffAdmin'] },
            { title: 'Reporting', group: 'RPT', icon: 'oomph-chart', route: '/reporting', userGroups: ['Content', 'Accounts', 'Sales', 'Administration', 'IT', 'Home'], userLevels: ['DeveloperAdmin', 'StaffAdmin', 'GroupUser'] },
            { title: 'Review Questions', group: 'ADMIN', icon: 'oomph-star', route: '/reviewquestions', userGroups: ['Content', 'Accounts', 'Sales', 'Administration', 'IT'], userLevels: ['DeveloperAdmin', 'StaffAdmin'] },
            { title: 'Reviews', group: 'CMS', icon: 'oomph-star', route: '/reviews', userGroups: ['Content'], userLevels: ['DeveloperAdmin', 'StaffAdmin'] },
            { title: 'Tickets', group: 'ADMIN', icon: 'oomph-two-tickets', route: '/tickets', userGroups: ['Administration', 'IT'], userLevels: ['DeveloperAdmin', 'StaffAdmin'] },
            { title: 'Users', group: 'ADMIN', icon: 'oomph-business-group', route: '/users', userGroups: ['Administration', 'IT'], userLevels: ['DeveloperAdmin', 'StaffAdmin'] }
        ]
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken'
        }),
        userLevel() {
            if (this.GET_FIREBASE_userAccessToken.user_level !== undefined && this.GET_FIREBASE_userAccessToken.user_level !== null) {
                return this.GET_FIREBASE_userAccessToken.user_level
            } else {
                return ''
            }
        },
        userGroup() {
            if (this.GET_FIREBASE_userAccessToken.user_group !== undefined && this.GET_FIREBASE_userAccessToken.user_group !== null) {
                return this.GET_FIREBASE_userAccessToken.user_group
            } else {
                return ''
            }
        },
        userStatus() {
            if (this.GET_FIREBASE_userAccessToken.user_status !== undefined && this.GET_FIREBASE_userAccessToken.user_status !== null) {
                return this.GET_FIREBASE_userAccessToken.user_status
            } else {
                return ''
            }
        }
    },
    methods: {
        groupItems(group) {
            return this.items.filter((x) => x.group === group)
        }
    }
}
</script>

<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
    display: none !important;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    /* Firefox */
}
</style>
