<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode" @click="saveItem()"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text px-3" dense style="z-index: 0 !important">
            <v-col v-if="!loading" cols="12" class="d-flex pt-4 align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- DETAILS EDIT -->
                        <!-- <pre>{{ item }}</pre> -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="12">
                                    <v-text-field label="Name" v-model="item.componentName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('componentName')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Category" v-model="item.componentCategory" :rules="rules.componentCategory" :items="componentCategories" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('componentType')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Licence Type" v-model="item.componentType" :rules="rules.componentType" :items="componentTypes" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('componentType')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field :disabled="item.componentCode === ''" label="Component Code" v-model="item.componentCode" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('componentCode')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Component Status" v-model="item.componentStatus" :rules="rules.componentStatus" :items="componentStatus" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('componentStatus')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <div><v-text-field label="Component Cost" prefix="£" v-model="item.componentCost" type="number" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('componentCost')"></v-text-field></div>
                                    <div class="ml-1 mt-1 body-2 grey--text">
                                        What does this component cost for Oomph to deliver
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field label="Component Sell" prefix="£" v-model="item.componentSell" type="number" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('componentSell')"></v-text-field>
                                    <div class="ml-1 mt-1 body-2 grey--text">
                                        What does this component cost to Oomph Customers individually
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class=" align-center">
                                    <div class="ml-1 body-2 app--text">
                                        Can this Component be purchased outside of a package:
                                    </div>
                                    <v-radio-group class="pt-0 mt-1 ml-1" v-model="item.componentPurchaseSeperate" row hide-details="auto">
                                        <v-radio label="Yes" value="1"></v-radio>
                                        <v-radio label="No" value="0"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Billing Cycle" v-model="item.componentBillingCycle" :rules="rules.componentStatus" :items="billingCycles" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('componentBillingCycle')"></v-select>
                                </v-col>
                                <v-col cols="12" class="pt-4 d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Customer Description</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" class="my-1 mb-0">
                                    <v-textarea label="Customer Description" :rules="componentCustomerDescriptionRules" counter=500  rows="2" background-color="white" v-model="item.componentCustomerDescription"  outlined dense color="primary" hide-details="auto" clearable @click:clear="clearItem('componentCustomerDescription')"></v-textarea>
                                </v-col>
                                <!-- <v-col cols="12" xs="12">
                                    <v-text-field label="Customer Description" v-model="item.componentCustomerDescription" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('componentCustomerDescription')"></v-text-field>
                                </v-col> -->
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Internal Description</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" class="my-1">
                                    <v-textarea label="Internal Description" :rules="componentInternalDescriptionRules" counter=500  rows="2" background-color="white" v-model="item.componentInternalDescription"  outlined dense color="primary" hide-details="auto" clearable @click:clear="clearItem('componentInternalDescription')"></v-textarea>
                                </v-col>

                            </v-row>



                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
import moment from 'moment';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        activeitem: {
            Type: Object,
            Default: null,
        },
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        // dropdowns
        componentTypes: [],
        componentCategories: [],
        billingCycles: [],
        componentStatus: [],


        // rules
        componentCustomerDescriptionRules: [ v => v.length <= 500 || 'Max 500 characters'],
        componentInternalDescriptionRules: [ v => v.length <= 500 || 'Max 500 characters'],



        // new address object
        item: {},
        loading: true,
        rules: {},

    }),
    computed: {  
    },
    methods: {
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.item);
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.item = { ...this.datadown };
            },
            deep: true,
        },
        activeitem: {
            handler: function () {
                let t = this;
                t.item = { ...t.activeitem };
            }, deep: true
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.component;
        t.loading = true
        t.item = { ...t.datadown };


        let componentTypes = await t.MIX_redisReadSet('set:componentTypes', false);
        if (componentTypes.code === 1) {
            t.componentTypes = componentTypes.data;
        }
        let componentCategories = await t.MIX_redisReadSet('set:componentCategories', false);
        if (componentCategories.code === 1) {
            t.componentCategories = componentCategories.data;
        }
        let componentStatus = await t.MIX_redisReadSet('set:componentStatus', false);
        if (componentStatus.code === 1) {
            t.componentStatus = componentStatus.data;
        }
        let billingCycles = await t.MIX_redisReadSet('set:billingCycles', false);
        if (billingCycles.code === 1) {
            t.billingCycles = billingCycles.data;
        }
        t.loading = false
    },
    mounted() {},
};
</script>