<template>
    <div class="" style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow-y: scroll !important; overflow-x: hidden !important">
        <v-card class="white ma-3 elevation-0">
            <v-card-title class="text-h6 font-weight-light primary white--text">
                <strong>Cover Image</strong>
                <v-spacer />
                <v-btn class="primary lighten-1 mr-1 white--text" :disabled="coverImagePanel || coverImage.id !== ''" @click="coverImagePanel = true" outlined depressed
                    ><v-icon class="pr-1 white--text">oomph-upload</v-icon>Upload</v-btn
                >
                <v-btn class="primary lighten-1 white--text" :disabled="coverImage.id == ''" @click="destroyCoverImage(coverImage.id)" outlined depressed
                    ><v-icon class="pr-1 white--text">oomph-trash-can</v-icon>Remove</v-btn
                >
            </v-card-title>

            <v-col cols="12" class="d-flex justify-center secondary pa-0" v-if="!coverImagePanel">
                <v-img v-if="coverImage.itemURL" contain height="300" :src="coverImage.itemURL || computedCoverImage" class="greyscale"></v-img>
                <v-img v-else height="300px" max-width="250" contain :src="require('@/assets/oomphlogoalt.png')"></v-img>
            </v-col>

            <v-row v-show="coverImagePanel" class="white mx-3 my-2 pb-2" style="height: 300px !important">
                <v-col cols="12" style="border: 1px solid lightgrey !important">
                    <v-row class="mb-2">
                        <v-col cols="6">
                            <div class="text-h6 font-weight-thin primary--text">Upload Cover Image</div>
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end">
                            <v-icon size="30" class="primary--text" @click="coverImagePanel = false">oomph-multiply</v-icon>
                        </v-col>
                    </v-row>
                    <FileUpload
                        accept="image/jpeg, image/jpg"
                        label="Select file"
                        buttonClass="primary"
                        progressClass="pa-2"
                        folderLocation="content/images"
                        :fileName="MIX_generateId()"
                        :selectedItem="eventItem"
                        :compressionMaxSizeMB="1"
                        :compressionmaxWidthOrHeight="1920"
                        :private="true"
                        :isProfile="false"
                        @update="saveCoverImage"
                        :key="coverImageKey"
                    />
                </v-col>
            </v-row>
        </v-card>
        <!-- Thumbnail Image -->
        <!-- <pre>{{ thumbnailImage }}</pre> -->
        <v-row dense class="pb-8">
            <v-col cols="6" class="pr-0">
                <v-card class="white ma-3 elevation-0">
                    <v-card-title class="text-h6 font-weight-light primary white--text">
                        <strong>Thumbnail</strong>
                        <v-spacer />
                        <v-btn class="primary lighten-1 mr-1 white--text" :disabled="thumbnailImagePanel || thumbnailImage.id !== ''" @click="thumbnailImagePanel = true" outlined depressed
                            ><v-icon class="white--text">oomph-upload</v-icon></v-btn
                        >
                        <v-btn class="primary lighten-1 white--text" :disabled="thumbnailImage.id == ''" @click="destroyThumbnailImage(thumbnailImage.id)" outlined depressed
                            ><v-icon class="white--text">oomph-trash-can</v-icon></v-btn
                        >
                    </v-card-title>
                    <v-col cols="12" class="d-flex justify-center secondary pa-0" v-if="!thumbnailImagePanel">
                        <v-img v-if="thumbnailImage.itemURL" contain height="220" :src="thumbnailImage.itemURL || computedThumbnailImage" class="greyscale"></v-img>
                        <v-img v-else height="220px" max-width="250" contain :src="require('@/assets/oomphlogoalt.png')"></v-img>
                    </v-col>
                    <v-row v-show="thumbnailImagePanel" class="white mx-3 mt-2 pb-2" style="height: 220px !important">
                        <v-col cols="12" style="border: 1px solid lightgrey !important">
                            <v-row class="mb-2">
                                <v-col cols="6">
                                    <div class="text-h6 font-weight-thin primary--text">Upload Cover Image</div>
                                </v-col>
                                <v-col cols="6" class="d-flex justify-end">
                                    <v-icon size="30" class="primary--text" @click="thumbnailImagePanel = false">oomph-multiply</v-icon>
                                </v-col>
                            </v-row>
                            <FileUpload
                                accept="image/jpeg, image/jpg"
                                label="Select file"
                                buttonClass="primary"
                                progressClass="pa-2"
                                folderLocation="content/images"
                                :fileName="MIX_generateId()"
                                :selectedItem="eventItem"
                                :compressionMaxSizeMB="1"
                                :compressionmaxWidthOrHeight="200"
                                :private="true"
                                :isProfile="false"
                                @update="saveThumbnailImage"
                                :key="thumbnailImageKey"
                            />
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <!-- Preview Image -->
            <!-- <pre>{{ previewImage }}</pre> -->
            <v-col cols="6" class="pl-0">
                <v-card class="white ma-3 elevation-0">
                    <v-card-title class="text-h6 font-weight-light primary white--text">
                        <strong>Preview</strong>
                        <v-spacer />
                        <v-btn class="primary lighten-1 mr-1 white--text" :disabled="previewImagePanel || previewImage.id !== ''" @click="previewImagePanel = true" outlined depressed
                            ><v-icon class="hite--text">oomph-upload</v-icon></v-btn
                        >
                        <v-btn class="primary lighten-1 white--text" :disabled="previewImage.id == ''" @click="destroyPreviewImage(previewImage.id)" outlined depressed
                            ><v-icon class="white--text">oomph-trash-can</v-icon></v-btn
                        >
                    </v-card-title>
                    <v-col cols="12" class="d-flex justify-center secondary pa-0" v-if="!previewImagePanel">
                        <v-img v-if="previewImage.itemURL" max-height="220" contain :src="previewImage.itemURL || computedPreviewImage" class="greyscale"></v-img>
                        <v-img v-else height="220px" max-width="250" contain :src="require('@/assets/oomphlogoalt.png')"></v-img>
                    </v-col>
                    <v-row v-show="previewImagePanel" class="white mx-3 mt-2 pb-2" style="height: 220px !important">
                        <v-col cols="12" style="border: 1px solid lightgrey !important">
                            <v-row class="mb-2">
                                <v-col cols="6">
                                    <div class="text-h6 font-weight-thin primary--text">Upload Cover Image</div>
                                </v-col>
                                <v-col cols="6" class="d-flex justify-end">
                                    <v-icon size="30" class="primary--text" @click="previewImagePanel = false">oomph-multiply</v-icon>
                                </v-col>
                            </v-row>
                            <FileUpload
                                accept="image/jpeg, image/jpg"
                                label="Select file"
                                buttonClass="primary"
                                progressClass="pa-2"
                                folderLocation="content/images"
                                :fileName="MIX_generateId()"
                                :selectedItem="eventItem"
                                :compressionMaxSizeMB="1"
                                :compressionmaxWidthOrHeight="500"
                                :private="true"
                                :isProfile="false"
                                @update="savePreviewImage"
                                :key="previewImageKey"
                            />
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <!-- <pre>{{ eventItem }}</pre> -->
            <!-- <pre>{{ coverImage }}</pre>
            <pre>{{ thumbnailImage }}</pre>
            <pre>{{ previewImage }}</pre> -->
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'EventImages',
    data: () => ({
        coverImagePanel: false,
        coverImage: {
            id: '',
            itemURL: '',
        },
        coverImageKey: 0,
        items: [],
        previewImagePanel: false,
        previewImage: {
            id: '',
            itemURL: '',
        },
        previewImageKey: 0,
        simpleEditMode: false,
        thumbnailImagePanel: false,
        thumbnailImage: {
            id: '',
            itemURL: '',
        },
        thumbnailImageKey: 0,
    }),
    props: {
        originalEventId: {
            type: String,
            default: () => {
                return '';
            },
        },
        eventItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        computedCoverImage() {
            let t = this;
            return t.items.forEach((coverImage) => {
                if (coverImage.value.itemType === 'Cover Image') {
                    t.coverImage.id = coverImage.value.id;
                    t.coverImage.itemURL = coverImage.value.itemURL;
                }
            });
        },
        computedThumbnailImage() {
            let t = this;
            return t.items.forEach((thumbnailImage) => {
                if (thumbnailImage.value.itemType === 'Thumbnail Image') {
                    t.thumbnailImage.itemURL = thumbnailImage.value.itemURL;
                }
            });
        },
        computedPreviewImage() {
            let t = this;
            return t.items.forEach((previewImage) => {
                if (previewImage.value.itemType === 'Preview Image') {
                    t.previewImage.itemURL = previewImage.value.itemURL;
                }
            });
        },
    },
    watch: {
        editMode() {
            let t = this;
            t.simpleEditMode = t.editMode;
        },
        eventItem: {
            handler: function () {
                this.getEventItems();
            },
            deep: true,
        },
        originalEventId: {
            handler: function () {
                this.coverImageKey++;
                this.thumbnailImageKey++;
                this.previewImageKey++;
                this.getEventItems();
            },
            deep: true,
        },
    },
    methods: {
        async getEventItems() {
            let t = this;
            this.coverImage = {
                id: '',
                itemURL: '',
            };
            t.thumbnailImage = {
                id: '',
                itemURL: '',
            };
            t.previewImage = {
                id: '',
                itemURL: '',
            };
            // // //console.log('t.eventItem.id = ' + t.eventItem.id);
            let result = await t.MIX_redisSearch('idx:eventItems', `@itemEventId:{${t.eventItem.id}}`, 0, 9999);
            // // //console.log('result = ' + JSON.stringify(result));
            if (result.code === 1) {
                t.items = result.data.documents;
                t.items.forEach((item) => {
                    if (item.value.itemType === 'Cover Image') {
                        t.coverImage.id = item.value.id;
                        t.coverImage.itemURL = item.value.itemURL;
                    }
                    if (item.value.itemType === 'Thumbnail Image') {
                        t.thumbnailImage.id = item.value.id;
                        t.thumbnailImage.itemURL = item.value.itemURL;
                    }
                    if (item.value.itemType === 'Preview Image') {
                        t.previewImage.id = item.value.id;
                        t.previewImage.itemURL = item.value.itemURL;
                    }
                });
            }
        },
        async saveCoverImage(url, file) {
            let t = this;
            t.itemNew = true;
            t.coverImage.id = t.MIX_generateId();
            t.coverImage.key = 'eventItem';
            t.coverImage.itemEventId = t.originalEventId;
            t.coverImage.itemURL = url;
            t.coverImage.itemFormat = file.type;
            t.coverImage.itemFileSizeKb = t.MIX_bytesToSize(file.size);
            t.coverImage.itemType = 'Cover Image';
            await t.MIX_createItem(t.coverImage, 'eventItem', t.itemNew);
            t.updateContentItem(t.coverImage.itemURL, 'Cover');
            t.MIX_alertBox({ color: 'success', message: 'Created content item successfully', timeout: 4000, show: true });
            t.coverImagePanel = false;
            t.coverImageKey++;
        },
        async saveThumbnailImage(url, file) {
            let t = this;
            t.itemNew = true;
            t.thumbnailImage.id = t.MIX_generateId();
            t.thumbnailImage.key = 'eventItem';
            t.thumbnailImage.itemEventId = t.originalEventId;
            t.thumbnailImage.itemURL = url;
            t.thumbnailImage.itemFormat = file.type;
            t.thumbnailImage.itemFileSizeKb = t.MIX_bytesToSize(file.size);
            t.thumbnailImage.itemType = 'Thumbnail Image';
            await t.MIX_createItem(t.thumbnailImage, 'eventItem', t.itemNew);
            t.updateContentItem(t.thumbnailImage.itemURL, 'Thumbnail');
            t.MIX_alertBox({ color: 'success', message: 'Created content item successfully', timeout: 4000, show: true });
            t.thumbnailImagePanel = false;
        },
        async savePreviewImage(url, file) {
            let t = this;
            t.itemNew = true;
            t.previewImage.id = t.MIX_generateId();
            t.previewImage.key = 'eventItem';
            t.previewImage.itemEventId = t.originalEventId;
            t.previewImage.itemURL = url;
            t.previewImage.itemFormat = file.type;
            t.previewImage.itemFileSizeKb = t.MIX_bytesToSize(file.size);
            t.previewImage.itemType = 'Preview Image';
            await t.MIX_createItem(t.previewImage, 'eventItem', t.itemNew);
            t.updateContentItem(t.previewImage.itemURL, 'Preview');
            t.MIX_alertBox({ color: 'success', message: 'Created content item successfully', timeout: 4000, show: true });
            t.previewImagePanel = false;
        },
        // * destroyCoverImage
        async destroyCoverImage(key) {
            let result = await this.MIX_destroyItem(key, 'eventItem');
            if (result.code === 1) {
                this.coverImagePanel = false;
                (this.coverImage = {
                    id: '',
                    itemURL: '',
                }),
                    this.coverImageKey++;
                this.getEventItems();
            }
        },
        // * destroyThumbnailImage
        async destroyThumbnailImage(key) {
            let result = await this.MIX_destroyItem(key, 'eventItem');
            if (result.code === 1) {
                this.thumbnailImagePanel = false;
                (this.thumbnailImage = {
                    id: '',
                    itemURL: '',
                }),
                    this.thumbnailImageKey++;
                this.getEventItems();
            }
        },
        // * destroyPreviewImage
        async destroyPreviewImage(key) {
            let result = await this.MIX_destroyItem(key, 'eventItem');
            if (result.code === 1) {
                this.previewImagePanel = false;
                (this.previewImage = {
                    id: '',
                    itemURL: '',
                }),
                    this.previewImageKey++;
                this.getEventItems();
            }
        },
        async updateContentItem(url, marker) {
            let t = this;
            if (marker === 'Cover') {
                t.eventItem.coverImageURL = url;
            } else if (marker === 'Thumbnail') {
                t.eventItem.thumbnailImageURL = url;
            } else if (marker === 'Preview') {
                t.eventItem.previewImageURL = url;
            }
            await t.MIX_createItem(t.eventItem, 'event', false);
        },
    },
    mounted() {
        let t = this;
        t.getEventItems();
    },
};
</script>