<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row dense>
            <v-col cols="12" class="d-flex align-center justify-start grey lighten-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    <div class="ml-3 text-h6 primary--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>
                <v-spacer></v-spacer>
                <div class="mx-3 d-flex align-center">
                    <v-btn text class="primary white--text" @click="dialogAddLink = true"><v-icon class="mr-2">oomph-link</v-icon>Add Content Link</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row dense>
            <!-- <v-col cols="12">
                <pre>{{ event }}</pre>
                eventLinkGroups = {{ event.eventLinkGroups }}
            </v-col> -->
            <v-col cols="12" class="pa-4">
                <v-data-table sortable dense class="" :headers="computedHeaders" :items="computedItems">
                    <!-- Table Header -->
                    <template v-slot:top>
                        <div  class="d-flex align-center">
                            <v-spacer></v-spacer>
                            <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                                <v-btn value="destroy" small>
                                    <v-icon class="error--text">oomph-delete-trash</v-icon>
                                </v-btn>
                                <v-btn value="delete" small>
                                    <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                                </v-btn>
                                <v-btn value="undelete" small>
                                    <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                    </template>
                    <!-- Table Items -->
                    <template v-slot:item.action="{ item }">
                        <v-icon class="error--text pa-1" v-if="toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">oomph-delete-trash</v-icon>
                        <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                        <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon>
                        <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-edit</v-icon>
                    </template>
                    <template v-slot:item.linkShowContentImage="{ item }">
                        <span v-if="item.linkShowContentImage === '1'">Yes</span>
                        <span v-else>No</span>
                    </template>

                </v-data-table>
            </v-col>
        </v-row>
        <!-- <div class="pa-2">
            event.id: {{ event.id }}
        </div>
        <div class="pa-2">
            items: {{ items.length }}
        </div> -->
        <v-dialog v-model="dialogAddLink" max-width="1024px" >
            <events-content-add-link :contentlink="activeContentLink" :event="event" :key="dialogAddLinkKey" v-on:close="closeDialog('AddLink')" v-on:updateevent="updateEvent" />
        </v-dialog>
    </div>
</template>

<script>
import eventContentAddLink from "@/views/events/EventContentAddLink.vue";
// import eventContentLinks from "@/views/events/EventContentLinks.vue";
export default {
    name: "eventContent",
    // * COMPONENTS
    components: {
        'events-content-add-link': eventContentAddLink,
    },
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        key: 'contentLink',
        activeContentLink: {},
        loading: false,
        event: {},
        items: [],
        // headers
        headers: [
            { text: 'ID', value: 'id', sortable: false, hidden: true },
            { text: 'Content ID', value: 'contentId', sortable: false, hidden: true },
            { text: 'Event ID', value: 'eventId', sortable: false, hidden: true },
            { text: 'Link Title', value: 'linkTitle', sortable: false, hidden: false },
            { text: 'Link Group', value: 'linkGroup', sortable: false, hidden: false },
            { text: 'Show Image', value: 'linkShowContentImage', sortable: false, hidden: false },         
            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created User ID', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Created By', value: 'createdUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Modified', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified User ID', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified By', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted User Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted By', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Email', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Action', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false, width: '155px' },               
        ],


        // toggles
        toggleFeatures: [],

        // dialogs
        dialogAddLink: false,
        dialogAddLinkKey: 0,

        

        // dropdowns
        addressTypes: [],

        refreshitems: 0

    }),
    // * COMPUTED
    computed: {
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expanded !== true); // Return Headers based on Expanded State
            }
            return headers;
        },
        // Computed Items
        computedItems() {
            var items = this.items.map(x => x.value);
            return items;
        },
    },
    // * METHODS
    methods: {
        openItem(item){
            let t = this;
            t.activeContentLink = item;
            t.dialogAddLink = true;
        },
        closeDialog(dialog) { 
            // //console.log('closeDialog : ' + dialog);
            let t = this;
            switch(dialog) {
                case 'AddLink':
                    // //console.log('closeDialog AddLink ')
                    t.dialogAddLinkKey++
                    t.dialogAddLink = false;
                    break;
                default:
                    break;
            }
            t.activeContentLink = {};
            t.getItems();
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        initialise() {
            this.event = { ...this.datadown };
            this.getItems();          
        },
        async getItems() {
            let t = this;
            t.items = [];
            let itemsResult = await t.MIX_redisSearch('idx:contentLinks', `@eventId:{${t.event.id}}`, 0, 9999);
            if (itemsResult.code === 1) {
                t.items = itemsResult.data.documents;
            }
            t.loading = false;
        },
        updateEvent(event) {
            // //console.log("## EventContentLinks ## updateEvent");
            this.$emit('updateevent', event);
        },        
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
    },
    // * CREATED
    created() {
        this.loading = true;
        this.initialise();
        this.getItems();
    },

};
</script>