<template>
	<v-row class="pa-0 ma-0" fill-height>
		<v-row no-gutters class="pb-3 px-3 pt-3"
			:class="item.orgAccountOnHold === '1' ? 'warning' : 'primary darken-2'">
			<v-col class="font-weight-bold white--text text-h6 d-flex align-center"
				v-if="JSON.stringify(item) !== '{}'">
				<div class="mx-3 mb-1"><v-icon class="white--text">oomph-user-groups</v-icon></div>
				<div v-if="item.contentTitle">
					<span class="font-weight-light"><strong>Content: </strong>{{
						item.contentTitle.substring(0, 30) }}...</span>
				</div>
				<div v-else class="ml-2 font-weight-light">New</div>
				<v-chip small v-if="item.contentStatus === 'Draft'" class="grey darken-1 ml-3" dark>{{
					item.contentStatus }}</v-chip>
				<v-chip small v-if="item.contentStatus === 'Pending'" class="warning ml-3" dark>{{
					item.contentStatus }}</v-chip>
				<v-chip small v-if="item.contentStatus === 'Rejected'" class="error ml-3" dark>{{
					item.contentStatus }}</v-chip>
				<v-chip small v-if="item.contentStatus === 'Approved'" class="info ml-3" dark>{{
					item.contentStatus }}</v-chip>
				<v-chip small v-if="item.contentStatus === 'Live'" class="success ml-3" dark>{{
					item.contentStatus }}</v-chip>
				<v-chip small v-if="item.contentStatus === 'Archived'" class="black ml-3" dark>{{
					item.contentStatus }}</v-chip>
				<v-spacer></v-spacer>
				<div>
					<v-btn outlined class="primary white--text ml-3"
						v-if="!itemNew && item.contentTemplate !== '' && item.contentTemplate !== null"
						@click="preview = true">Preview</v-btn>
					<v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
						<v-icon>oomph-multiply</v-icon>
					</v-btn>
				</div>
			</v-col>
		</v-row>
		<v-col class="pa-0" fill-height cols="auto" style="width: 100% !important">
			<v-tabs background-color="white" v-model="tab" show-arrows>
				<v-tab href="#details">Details</v-tab>
				<v-tab :disabled="itemNew" href="#images">Images</v-tab>
				<v-tab :disabled="itemNew" v-if="item.contentType === 'Simple' || itemNew"
					href="#simple">Content</v-tab>
				<v-tab :disabled="itemNew" v-else href="#content">Content (Advanced)</v-tab>
				<v-tab v-if="item.contentTemplate === 'Quiz'" :disabled="itemNew" href="#quiz">Quiz</v-tab>
				<!-- <v-tab disabled href="#stats">Stats</v-tab> -->
				<v-tab :disabled="true" href="#preview">Reviews</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab" fill-height>
				<!-- Fabric Tab -->
				<v-tab-item value="details" class="grey lighten-4">
					<ContentDetails :originalContentId="item.id" :contentItem="item" :newItem="itemNew"
						:editMode="editMode" v-on:save="createItem" :listshrunk="listShrunk"
						v-on:updatecontent="updateContent" v-on:delete="deleteItem" />
				</v-tab-item>
				<v-tab-item value="simple" class="grey lighten-4">
					<ContentSimple :listshrunk="listShrunk" :originalContentId="item.id" :contentItem="item"
						:newItem="itemNew" :editMode="editMode" v-on:update="update"
						v-on:updatecontent="updateContent" />
				</v-tab-item>
				<v-tab-item value="images" class="grey lighten-4">
					<ContentImages :originalContentId="item.id" :contentItem="item" :newItem="itemNew"
						:editMode="editMode" v-on:updatecontent="updateContent" />
				</v-tab-item>
				<v-tab-item value="content" class="grey lighten-4">
					<ContentItems :key="contentItemsKey" :originalContentId="item.id" :contentItem="item"
						:contentDoc="item" :newItem="itemNew" :editMode="editMode"
						v-on:updatecontent="updateContent" v-on:getlatestdata="getLatestData" />
				</v-tab-item>
				<v-tab-item value="quiz" class="grey lighten-4">
					<quiz-questions :activequiz="item" v-on:save="updateContent"
						v-on:update="updateContent" />
				</v-tab-item>
				<v-tab-item value="stats" class="grey lighten-4">
					<ContentStats :key="`stats-${item.id}`" :originalContentId="item.id"
						:contentLikes="item.contentLikes" :contentViews="item.contentViews"
						:contentRating="item.contentRating" :contentReviews="item.contentReviews"
						:contentDownloads="item.contentDownloads" />
				</v-tab-item>
			</v-tabs-items>
		</v-col>
		<v-dialog :key="componentKey" v-model="preview" style="overflow: hidden !important" persistent>
			<v-card class="pa-0 ma-0"
				style="position: absolute; top: 0; left: 0 !important; width: 100vw !important; height: 100vh !important">
				<News style="overflow-x: hidden !important; overflow-y: hidden !important" :key="componentKey"
					v-if="item.contentArea.includes('News')" :contentItem="item" @close="close()" />
				<Quiz style="overflow-x: hidden !important; overflow-y: hidden !important" :key="componentKey"
					v-if="item.contentTemplate === 'Quiz'" :contentItem="item" @close="close()" />
				<Content style="overflow-x: hidden !important; overflow-y: hidden !important"
					:key="componentKey" :contentType="item.contentTemplate" v-else :contentItem="item"
					@close="close()" />
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { mapGetters } from "vuex";

import News from './templates/NewsTemplate.vue';
import Quiz from './templates/QuizTemplate.vue';
import Content from './templates/ContentTemplate.vue';

import ContentDetails from './ContentDetails.vue';
import ContentItems from './ContentItems.vue';
import ContentStats from './ContentStats.vue';
import ContentSimple from './ContentSimple.vue';
import ContentImages from './ContentImages.vue';
import QuizQuestions from "@/views/quizzes/QuizQuestions.vue"
import schema from "@/schema.js";

export default {
	components: {
		ContentDetails,
		ContentItems,
		ContentStats,
		ContentSimple,
		ContentImages,
		News,
		Content,
		Quiz,
		"quiz-questions": QuizQuestions
	},
	props: {
		datadown: {
			type: Object,
			default: function () {
				return {};
			},
		},
		listShrunk: {
			type: Boolean,
			default: false,
		}
	},
	// * DATA
	data: () => ({
		key: "content:details",
		tab: "details",
		schema: null,
		// Item
		item: {},
		itemNew: false,
		editMode: false,
		// Form items
		accountTypes: [],
		addressTypes: [],
		industries: [],
		// Tags
		computedOrgTags: [],
		// Validation
		rules: {},
		// Toggles
		addAddressToggle: false,
		addManualAddress: false,
		addOtherAddressToggle: false,
		addLeadStatusToggle: false,
		addOrgInfoToggle: false,
		addSocialInfoToggle: false,
		preview: false,
		componentKey: 0,
		contentItemsKey: 0,
	}),
	// * COMPUTED
	computed: {
		...mapGetters({

		}),
		computedFullName() {
			return this.item.contactFirstName + " " + this.item.contactLastName;
		},
	},
	// * METHODS
	methods: {
		getLatestData(id) {
			let t = this;
			t.readItem(id);
		},
		// * INITIALISE ITEM
		initialiseItem() {
			let t = this;
			if (JSON.stringify(t.datadown) !== "{}") {
				this.readItem(t.datadown.id);
			} else {
				t.itemNew = true;
				t.editMode = true;
				t.item = { ...t.schema };
				t.item.id = t.MIX_generateId();
			}
		},
		// * VALIDATION RULES
		// TODO - RG - I think this needs to be moved to contactDetails.vue
		validateItem() {
			let t = this;
			t.rules = {
				// GENERAL DETAILS
				orgName: [(v) => !!v || "Organisation Name Required"],
				// orgRegisteredName: [ v => !!v || 'Registered Name Required'],
				// orgRegistrationNumber: [ v => !!v || 'Registration Number Required'],
				// orgTelephone: [ v => !!v || 'Telephone Required'],
				// orgWebsite: [ v => !!v || 'Website Required'],
				// orgEmail: [ v => !!v || 'Email Required'],
				// orgInvoiceEmail: [ v => !!v || 'Invoice Email Required'],
				// orgPurchasingEmail: [ v => !!v || 'Purchasing Email Required'],

				// ADDRESS DETAILS
				// orgAddressType: [ v => !!v || 'Address Type Required'],
				// orgAddressLine1: [ v => !!v || 'Address Line 1 Required'],
				// orgAddressLine2: [ v => !!v || 'Address Line 2 Required'],
				// orgAddressLine3: [ v => !!v || 'Address Line 3 Required'],
				// orgTown: [ v => !!v || 'Town Required'],
				// orgCounty: [ v => !!v || 'County Required'],
				// orgPostcode: [ v => !!v || 'Postcode Required'],

				// OTHER ADDRESS DETAILS
				// orgOtherAddressType: [ v => !!v || 'Address Type Required'],
				// orgOtherAddressLine1: [ v => !!v || 'Address Line 1 Required'],
				// orgOtherAddressLine2: [ v => !!v || 'Address Line 2 Required'],
				// orgOtherAddressLine3: [ v => !!v || 'Address Line 3 Required'],
				// orgOtherTown: [ v => !!v || 'Town Required'],
				// orgOtherCounty: [ v => !!v || 'County Required'],
				// orgOtherPostcode: [ v => !!v || 'Postcode Required'],

				// ORG INFO
				// orgEmployees: [ v => !!v || 'No of Employees Required'],
				// orgIndustry: [ v => !!v || 'Industry Required'],
				// orgSector: [ v => !!v || 'Sector Required'],
				// orgAccountNumber: [ v => !!v || 'Account Number Required'],
				//orgAccountTypes: [ v => !!v || 'Account Type Required'],
				// orgLocalAuthority: [ v => !!v || 'Local Authority Required'],
				// orgTurnover: [ v => !!v || 'Turnover Required'],

				// OWNER / SOURCE INFO
				// orgAccountOwner: [ v => !!v || 'Account Owner Required'],
				// orgSource: [ v => !!v || 'Source Required'],
				// orgSourceName: [ v => !!v || 'Source Name Required'],

				// SOCIAL INFO
				// orgTwitter: [ v => !!v || 'Twitter Required'],
				// orgFacebook: [ v => !!v || 'Facebook Required'],
				// orgLinkedIn: [ v => !!v || 'LinkedIn Required'],
				// orgInstagram: [ v => !!v || 'Instagram Required'],
			};
			t.$nextTick(() => {
				if (t.$refs.form.validate()) {
					this.createItem();
					this.editMode = false;
				} else {
					this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
				}
			});
		},
		// * CREATE ITEM
		async createItem(item) {
			let t = this;
			let result = await t.MIX_createItem(item, t.key, t.itemNew);
			if (result.code === 1) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
				t.readItem(item.id);
				t.$emit("refreshitems");
			}
		},
		// * READ ITEM
		async readItem(itemId) {
			let t = this;
			let result = await t.MIX_readItem(itemId, t.key);
			if (result.code === 1) {
				this.item = result.data;
				t.itemNew = false;
				t.loading = false;
			}
		},
		// * UPDATE CONTENT
		async updateContent(content) {
			let t = this;
			t.close()
			let result = await t.MIX_createItem(content, "content:details", false);
			if (result.code === 1) {
				t.readItem(content.id);
				t.$emit("refreshitems");
				t.contentItemsKey++
			}
		},
		// * DESTROY ITEM
		async destroyItem() {
			let result = await this.MIX_destroyItem(this.item.id, this.key);
			if (result.code === 1) {
				// this.readItem(this.item.id);
				this.closeItem()
			}
		},
		// * DELETE ITEM
		async deleteItem() {
			let result = await this.MIX_deleteItem(this.item.id, this.key);
			if (result.code === 1) {
				this.readItem(this.item.id);
			}
		},
		// * RESTORE ITEM
		async restoreItem() {
			let result = await this.MIX_restoreItem(this.item.id, this.key);
			if (result.code === 1) {
				this.readItem(this.item.id);
			}
		},
		update() {
			this.componentKey++
		},
		close() {
			let t = this;
			t.preview = false;
			t.componentKey++
		},
		// Close Item
		closeItem() {
			this.$emit("closeitem");
		},
	},
	// * WATCH
	watch: {
		// Datadown Changes
		datadown() {
			this.initialiseItem();
		},
		computedOrgTags() {
			var t = this;
			var splitVal = [];
			splitVal = t.item.orgTags.split(",");

			// // console.log(JSON.stringify(val, null, 2));
			return splitVal;
		},
	},
	// * CREATED
	async created() {
		let t = this;
		t.loading = true;
		t.schema = schema.content;
		t.initialiseItem();

		let accountTypes = await t.MIX_redisReadSet("set:orgAccountTypes", false);
		if (accountTypes.code === 1) {
			t.accountTypes = accountTypes.data;
		}

		let addressTypes = await t.MIX_redisReadSortedSet("sset:addressTypes", 0, 9999);
		if (addressTypes.code === 1) {
			t.addressTypes = addressTypes.data;
		}

		let industries = await t.MIX_redisReadSet("set:industries", true);
		if (industries.code === 1) {
			t.industries = industries.data;
		}
	},
	// * MOUNTED
	async mounted() { },
};
</script>

<style scoped>
.v-data-table--dense>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table--dense>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table--dense>.v-data-table__wrapper>table>tfoot>tr>td {
	height: 22px;
}
</style>
