import { render, staticRenderFns } from "./ComponentDetails.vue?vue&type=template&id=348a7874&"
import script from "./ComponentDetails.vue?vue&type=script&lang=js&"
export * from "./ComponentDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports