<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <v-row class="apptext--text" dense>
            <div style="height: calc(100vh - 370px) !important; overflow-y: scroll; width: 100% !important" class="pa-3 pl-4" ref="messages">
                Messages:
                <div class="d-flex flex-row mt-2">
                    <div>
                        <div v-if="showTime" class="caption grey--text">{{ $moment(ticket.createdDateTime, 'x').format('DD-MMM-YYYY HH:mm A')}}</div>
                        <v-chip @click="showDateTime()" color="white" style="height: auto; white-space: normal" class="pa-4 mb-2">
                            <div class="d-flex flex-column">
                                <div class="body-1">{{ ticket.message }}</div>
                                <div class="caption grey--text text--darken-1">{{ ticket.createdUserName }} - {{ $moment(ticket.createdDateTime, 'x').format('DD-MMM-YYYY HH:mm A') }}</div>
                            </div>
                        </v-chip>
                    </div>
                </div>
                <div v-for="(reply, index) in ticketReplies" :key="index">
                    <div v-if="reply.createdUserId === ticket.createdUserId" class="d-flex flex-row mt-2">
                        <div>
                            <div v-if="showTime" class="caption grey--text">{{ $moment(reply.createdDateTime, 'x').format('DD-MMM-YYYY HH:mm A')}}</div>
                            <v-chip @click="showDateTime()" color="white" style="height: auto; white-space: normal" class="pa-4 mb-2">
                                <div class="d-flex flex-column">
                                    <div class="body-1">{{ reply.message }}</div>
                                    <div class="caption grey--text text--darken-1">{{ reply.createdUserName }} - {{ computedTimeAgo(reply.createdDateTime) }}</div>
                                </div>
                            </v-chip>
                        </div>
                    </div>
                    <div v-else class="d-flex flex-row-reverse">
                        <div>
                            <div v-if="showTime" class="caption grey--text">{{ $moment(reply.createdDateTime, 'x').format('DD-MMM-YYYY HH:mm A')}}</div>
                            <v-chip @click="showDateTime()" style="height: auto; white-space: normal" class="pa-4 mb-2 primary">
                                <div class="d-flex flex-column">
                                    <div class="body-1">{{ reply.message }}</div>
                                    <div class="caption white--text">{{ reply.createdUserName }} - {{ computedTimeAgo(reply.createdDateTime) }}</div>
                                </div>
                            </v-chip>
                        </div>
                    </div>
                </div>
            </div>
        </v-row>


        <v-row dense class="" style="position: absolute; bottom: 30px; width: 100% !important" >
            <div style="width: 100% !important; height: 200px !important">
                <v-card tile flat>
                    <v-card-text class="pa-0">
                        <v-form ref="ticketReply" class="pt-3 pl-2" lazy-validation>
                            <v-col cols="12" class="pb-0 pt-1">
                                <v-textarea
                                    color="primary"
                                    background-color="white"
                                    hide-details="auto"
                                    class="rounded"
                                    :rules="rules.ticketReplyMessage"
                                    v-model="ticketReply.message"
                                    counter
                                    outlined
                                    dense
                                    clearable
                                    light
                                    rows=4
                                ></v-textarea>
                            </v-col>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end pt-0 pr-3">
                        <v-btn class="primary" @click="submitTicketReply()" depressed><v-icon>oomph-sent</v-icon>Submit</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </v-row>


    </div>
</template>

<script>
import { Drag, DropList } from "vue-easy-dnd";
import schema from "@/schema.js";
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
    name: "contract-packages",
    // * COMPONENTS
    components: {
        Drag,
        DropList,
    },
    // PROPS
    props: {
        activeTicket: {
            type: Object,
            default: () => {},
        },
    },
    // * DATA
    data: () => ({
        ticket: {},
        loading: false,
        editmode: false,
        schema: {},
        ticketReplies: [],
        ticketReply: {},
        rules: {},
        showTime: false,
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
        }),
        computedContractValue() {
            
            // return totalValue;
        },
    },
    // * METHODS
    methods: {
        showDateTime() {
            this.showTime = !this.showTime
        },
        computedTimeAgo(value) {
            var t = this;

            var value = value

            var weekAgo = t.$moment().subtract(7, 'days').format('x')

            if (value > weekAgo) {
                value = t.$moment(value, 'x').fromNow()
            } else {
                value = t.$moment(value, 'x').format('DD-MMM-YYYY HH:mm A')
            }

            return value
        },
        async initialise() {
            
            this.loading = true;
            this.ticket = { ...this.activeTicket };
            await this.getTicketReplies()
            this.loading = false;
        },        
        updateChat(value) {
            this.$emit('updatechat', value)
        },
        scrollToBottom() {
            const container = this.$refs.messages;
            container.scrollTop = container.height;
        },
        async getTicketReplies() {
            let t = this;
            // Get Replies
            let repliesResult = await this.MIX_redisSearch("idx:ticketReplies", `@ticketLinkId:${this.ticket.id}`, 0, 9999);
            
            if (repliesResult.code === 1) {
                this.ticketReplies = repliesResult.data.documents.map((x) => x.value).sort((a, b) => a?.createdDateTime - b?.createdDateTime);
            }
            return
        },
        async submitTicketReply() {
            let t = this;
            t.rules = {
                ticketReplyMessage: [(v) => v.length < 600 || 'Message must be less than 600 characters'],
            };
            t.$nextTick(async () => {
                if (t.$refs.ticketReply.validate()) {
                    var currentUserId = t.GET_FIREBASE_userAuth.uid;
                    var currentUser = {};

                    let currentUserResult = await this.MIX_redisSearch("idx:users", `@id:${currentUserId}`, 0, 999)
                    if (currentUserResult.code === 1) {
                        var users = currentUserResult.data.documents.map((x) => x.value);
                        currentUser = users[0]
                    }



                    t.ticketReply.id = await t.MIX_generateId();
                    t.ticketReply.ticketLinkId = t.ticket.id;
                    t.ticketReply.orgId = currentUser.orgId;
                    t.ticketReply.orgName = currentUser.orgName;
                    t.ticketReply.createdDateTime = t.$moment().format('x');
                    t.ticketReply.createdUserId = currentUser.id;
                    t.ticketReply.createdUserPhotoURL = currentUser.photoURL;
                    t.ticketReply.createdUserName = currentUser.userName;
                    t.ticketReply.createdUserEmail = currentUser.userEmail;
                    let submitTicketReply = await t.MIX_redisCreateHash(`ticketReply:${t.ticketReply.id}`, t.ticketReply);
                    if (submitTicketReply.code === 1) {
                        t.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Reply submitted', show: true });
                        t.ticketReply = {}
                        // document.querySelector('#comments').click();
                        t.getTicketReplies();
                    }
                }
            });
        },

    },
    // * WATCHER
    watch: {
        activeTicket: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
    },
    // * CREATED
    created() {
        // this.schema = schema.contractItem;
        this.initialise();
        // this.scrollToBottom()
    },
    // * MOUNTED
    mounted() {},
};
</script>

<style lang="scss">
html,
body,
#app,
.v-application--wrap,
.v-content,
.v-content__wrap {
    height: 100%;
}

.drop-in {
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.3);
}
</style>

<style scoped>
.list {
    width: 100% !important;
    padding: 12px !important;
    margin: 0px !important;
}
.item {
    width: 100%;
    /* padding: 20px; */
    margin-top: 10px;
    background-color: white !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fullwidth {
    width: 100% !important;
}
</style>
