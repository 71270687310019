import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import firebase_auth_store from "@/plugins/firebase/firebase_auth_store.js";
import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
	plugins: [ createPersistedState() ],
	state: {
		alertBox: { color: "success", timeout: 2000, message: "Success", show: false },   
		appUpdate: { show: false },
		taskBar: false,
		// add tokenrefreshinterval to state so that it can be cleared on logout
		tokenRefreshInterval: null,
	},
	mutations: {
		MUT_alertBox(state, payload) { state.alertBox =  payload },    
		MUT_appUpdate(state, payload) { state.appUpdate =  payload },
		MUT_taskBar(state) { state.taskBar = !state.taskBar },
		// add mutation to set tokenrefreshinterval
		SET_TOKEN_REFRESH_INTERVAL(state, intervalId) {
			state.tokenRefreshInterval = intervalId;
		},
		// add clear tokenrefreshinterval mutation
		CLEAR_TOKEN_REFRESH_INTERVAL(state) {
			if (state.tokenRefreshInterval){
				clearInterval(state.tokenRefreshInterval);
				state.tokenRefreshInterval = null;
			}
			
		}
	},
	actions: {
		ACT_alertBox({commit}, payload) { commit('MUT_alertBox', payload) },     
		ACT_taskBar({commit}) { commit('MUT_taskBar') },
        ACT_appUpdate({commit}, payload) { commit('MUT_appUpdate', payload) },	
		// Action to set the token refresh interval
		setTokenRefreshInterval({ commit }, intervalId) {
			commit('SET_TOKEN_REFRESH_INTERVAL', intervalId);
		},
		// Action to clear the token refresh interval
		clearTokenRefreshInterval({ commit }) {
			commit('CLEAR_TOKEN_REFRESH_INTERVAL');
		},

	},
	getters: {
		GET_alertBox(state) { return state.alertBox },   
		GET_appUpdate(state) { return state.appUpdate },
		GET_taskBar(state) { return state.taskBar },
	},
	modules: {
        firebase_auth_store: firebase_auth_store,
    },
});
