<template>
    <div
        class="pb-0 mb-0"
        style="padding: 0px !important; margin: 0px !important; width: 100% !important"
        :style="pu ? ' overflow: hidden !important' : $vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; overflow: scroll !important;'"
    >
        <!-- Menu Buttons -->
        <v-row v-if="!pu" class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey lighten-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>
                <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
                <div v-if="$vuetify.breakpoint.smAndDown" class="white--text text-h6 font-weight-bold pl-3">
                    <div>{{ item.contactFullName }}</div>
                    <div @click="close()" class="font-weight-normal caption"><v-icon size="15" class="white--text pa-0">oomph-back</v-icon> Return to List</div>
                </div>
                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="success white--text font-weight-bold mr-3" v-if="item.userStatus === 'Pending' && ['StaffManager', 'StaffAdmin', 'DeveloperAdmin'].includes(userLevel)" @click="approveUser = true"
                        ><v-icon class="mr-2">oomph-save</v-icon>Approve</v-btn
                    >
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true" :large="!dense"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="grey darken-1 white--text font-weight-bold mr-2" v-if="editmode" @click="cancelItem" :large="!dense"><v-icon class="mr-2">oomph-multiply</v-icon>Cancel</v-btn>
                    <v-btn text class="primary white--text font-weight-bold" v-if="editmode" @click="validateForm()" :large="!dense"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <!-- <pre>{{ item }}</pre> -->
        <!-- Contact Details View -->
        <v-row class="apptext--text px-3" dense v-if="!editmode && !loading && !pu">
            <v-col v-if="!editmode" cols="12" class="d-flex align-center justify-start rounded pt-8">
                <v-row style="width: 100% !important" dense>
                    <v-col cols="12" sm="12">
                        <v-row class="pb-3 d-flex justify-center" no-gutters>
                            <!-- <v-avatar v-if="item.photoURL === '' || item.photoURL === null || item.photoURL === undefined" color="text-h5 primary white--text font-weight-bold" size="150">{{ item.userName.match(/\b(\w)/g).join("") }}</v-avatar> -->
                            <!-- <v-avatar v-else size="150"><img :src="item.photoURL" /></v-avatar> -->
                        </v-row>
                    </v-col>
                    <!-- Full Name / Job Title / Department / Marketing -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Details</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Full Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">
                                                    {{ item.userName }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Email:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">
                                                    <div v-if="!editmode">{{ item.userEmail }}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Telephone:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">
                                                    <div v-if="!editmode">{{ item.userTelephone }}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">User Group:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">
                                                    <div v-if="!editmode">{{ item.userGroup }}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">User Level:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">
                                                    <div v-if="!editmode">{{ item.userLevel }}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">User Status:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">
                                                    <div v-if="!editmode">{{ item.userStatus }}</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Organisation</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Organisation:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white">
                                                    {{ item.orgName }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- Contact Details Edit -->
        <v-row class="pb-0 mb-0" dense>
            <v-col v-if="editmode" cols="12" class="d-flex align-center pb-0 mb-0">
                <v-form ref="form" lazy-validation style="width: 100% !important" class="pa-0 ma-0">
                    <!-- ORGANISATON DETAILS -->
                    <v-row v-if="pu && homeUser" class="pa-4 grey lighten-4" dense>
                        <v-col cols="12" xs="12" md="12" class="mt-0">
                            <div class="text-left primary--text pb-2 text--darken-2 text-h6 font-weight-light">User Details</div>
                        </v-col>
                        <!-- User Name -->
                        <v-col cols="12" xs="12" md="4" class="mt-0">
                            <v-text-field autocomplete="null" label="User Name" v-model="item.userName" :rules="rules.userName" dense outlined hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                        </v-col>
                        <!-- User job title -->
                        <v-col cols="12" xs="12" md="4" class="">
                            <v-text-field outlined dense class="primary--text" :rules="rules.userJobTitle" v-model="item.userJobTitle" label="Job Title" hide-details="auto" background-color="white"></v-text-field>
                        </v-col>
                        <!-- User level -->
                        <v-col cols="12" xs="12" md="4" class="">
                            <!-- <pre>New Item: {{ newItem }}</pre> -->
                            <v-select
                                label="User Level"
                                v-model="item.userLevel"
                                :rules="rules.userLevel"
                                :items="['HomeAdmin', 'HomeManager', 'HomeUser']"
                                required
                                outlined
                                dense
                                hide-details="auto"
                                background-color="white"
                                color="primary"
                                clearable
                                :menu-props="{ top: false, offsetY: true }"
                                :attach="true"
                                @click:clear="clearItem('userLevel')"
                            ></v-select>
                        </v-col>
                        <!-- User Pin -->
                        <v-col cols="12" xs="12" md="4">
                            <!-- <v-text-field label="On-Demand Pin" outlined dense :append-icon="show ? 'oomph-unavailable' : 'oomph-lock'" :type="show ? 'text' : 'password'" @click:append="show = !show" v-model="item.userPin" hide-details="auto" background-color="white" color="primary" :rules="pinRules"></v-text-field> -->
                            <div class="grey--text font-weight-medium">On-Demand Pin</div>
                            <v-otp-input hide-details="auto" dense label="On-Demand Pin" v-model="item.userPin" type="number" length="4" :rules="pinRules"></v-otp-input>
                        </v-col>
                        <!-- User Email -->
                        <v-col cols="12" xs="12" md="4" class="pt-8">
                            <v-text-field label="LMS Email" v-model="item.userEmail" dense outlined hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('userEmail')"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" class="">
                            <div class="text-left primary--text pb-2 text--darken-2 text-h6 font-weight-light">Profile Photo</div>
                            <FileUpload
                                accept="image/jpeg, image/jpg"
                                label="Select file"
                                buttonClass="primary"
                                progressClass="pa-2"
                                folderLocation="profile"
                                :fileName="item.id"
                                :selectedItem="item"
                                :compressionMaxSizeMB="1"
                                :compressionmaxWidthOrHeight="1920"
                                :private="true"
                                :isProfile="true"
                                v-on:update="loadDataDown()"
                            />
                        </v-col>
                    </v-row>
                    <!-- <pre>{{ newItem }}</pre> -->

                    <v-row v-if="pu && (homeLogin || groupUser || streamUser)" class="pa-4 grey lighten-4" dense>
                        <v-col cols="12" xs="12" md="12" class="mt-0">
                            <div class="text-left primary--text pb-2 text--darken-2 text-h6 font-weight-light">User Details</div>
                        </v-col>
                        <!-- User Name -->
                        <v-col cols="12" xs="12" md="6" class="mt-0">
                            <v-text-field label="User Name" v-model="item.userName" :rules="rules.userName" dense outlined hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                        </v-col>
                        <!-- User Email -->
                        <v-col cols="12" xs="12" md="6" class="">
                            <v-text-field
                                label="User Email"
                                v-model="item.userEmail"
                                :rules="rules.userEmail"
                                dense
                                outlined
                                hide-details="auto"
                                background-color="white"
                                color="primary"
                                clearable
                                @click:clear="clearItem('userEmail')"
                            ></v-text-field>
                        </v-col>
                        <!-- User Telephone -->
                        <v-col cols="12" xs="12" md="6" :class="!homeLogin ? 'pt-8' : ''">
                            <v-text-field outlined dense class="primary--text" v-model="item.userJobTitle" :rules="rules.userJobTitle" label="Job Title" hide-details="auto" background-color="white"></v-text-field>
                        </v-col>
                        <!-- User Pin -->
                        <v-col v-if="!homeLogin" cols="12" xs="12" md="6">
                            <!-- <v-text-field label="On-Demand Pin" outlined dense :append-icon="show ? 'oomph-unavailable' : 'oomph-lock'" :type="show ? 'text' : 'password'" @click:append="show = !show" v-model="item.userPin" hide-details="auto" background-color="white" color="primary" :rules="pinRules"></v-text-field> -->
                            <div class="grey--text font-weight-medium">On-Demand Pin</div>
                            <v-otp-input hide-details="auto" dense label="On-Demand Pin" v-model="item.userPin" type="number" length="4" :rules="pinRules"></v-otp-input>
                        </v-col>
                    </v-row>
                    <v-row v-if="pu && (homeLogin || groupUser || streamUser)" class="px-4 grey lighten-4" dense>
                        <!-- Password Toggle -->
                        <v-col v-if="item.userLevel !== 'HomeUser' && item.userLevel !== 'HomeManager' && item.userLevel" class="d-flex justify-center align-center" cols="12" xs="12">
                            <div class="text-left primary--text pb-2 text--darken-2 text-h6 font-weight-light">Password</div>
                            <v-switch v-if="!newItem" class="ma-0 mb-2 pa-0 ml-3" dense inset v-model="item.userChangePassword" :true-value="'1'" :false-value="'0'" hide-details="auto" label="Change Password at next Login"></v-switch>
                            <v-spacer />
                            <v-btn v-if="!changePassword && !newItem" text class="primary white--text font-weight-bold" @click="showChangePassword()">Change Password</v-btn>
                            <v-btn v-if="changePassword || newItem" text class="white primary--text font-weight-bold" :class="newItem ? 'mr-0' : 'mr-2'" @click="usePass123()">Set Default</v-btn>
                            <v-btn v-if="changePassword && !newItem" text class="primary white--text font-weight-bold" @click="cancelChangePassword()">Cancel Change Password</v-btn>
                        </v-col>
                        <v-col v-if="changePassword || newItem" cols="12" xs="12" md="6">
                            <v-text-field
                                outlined
                                class="primary--text"
                                v-model="password"
                                label="Password"
                                :append-icon="showPassword ? 'oomph-unavailable' : 'oomph-lock'"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                                :rules="passwordRules"
                                autocomplete="off"
                                required
                                dense
                                hide-details="auto"
                                background-color="white"
                                color="primary"
                            ></v-text-field>
                        </v-col>
                        <v-col v-if="changePassword || newItem" cols="12" xs="12" md="6">
                            <v-text-field
                                outlined
                                class="primary--text"
                                v-model="confirmPassword"
                                label="Confirm Password"
                                :append-icon="showConfirmPassword ? 'oomph-unavailable' : 'oomph-lock'"
                                :type="showConfirmPassword ? 'text' : 'password'"
                                @click:append="showConfirmPassword = !showConfirmPassword"
                                :rules="[password === confirmPassword || 'Password must match']"
                                autocomplete="off"
                                required
                                dense
                                hide-details="auto"
                                background-color="white"
                                color="primary"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" class="">
                            <div class="text-left primary--text pb-2 text--darken-2 text-h6 font-weight-light">Profile Photo</div>
                            <FileUpload
                                accept="image/jpeg, image/jpg"
                                label="Select file"
                                buttonClass="primary"
                                progressClass="pa-2"
                                folderLocation="profile"
                                :fileName="item.id"
                                :selectedItem="item"
                                :compressionMaxSizeMB="1"
                                :compressionmaxWidthOrHeight="1920"
                                :private="true"
                                :isProfile="true"
                                v-on:update="uploadImage()"
                            />
                        </v-col>
                    </v-row>

                    <v-expansion-panels v-if="!pu && !loading" v-model="panelFixed" accordion flat class="pa-0 ma-0">
                        <v-expansion-panel class="grey lighten-4">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">User Details</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <!-- User Name -->
                                    <v-col cols="12" xs="12" md="6" class="mt-0">
                                        <v-text-field label="User Name" v-model="item.userName" :rules="rules.userName" dense outlined hide-details="auto" background-color="white" color="primary" clearable></v-text-field>
                                    </v-col>
                                    <!-- User Email -->
                                    <v-col cols="12" xs="12" md="6" class="">
                                        <v-text-field
                                            label="User Email"
                                            v-model="item.userEmail"
                                            :rules="rules.userEmail"
                                            dense
                                            outlined
                                            hide-details="auto"
                                            background-color="white"
                                            color="primary"
                                            clearable
                                            @click:clear="clearItem('userEmail')"
                                        ></v-text-field>
                                    </v-col>
                                    <!-- User Telephone -->
                                    <v-col cols="12" xs="12" md="6" class="">
                                        <v-text-field outlined dense class="primary--text" v-model="item.userTelephone" label="Telephone" hide-details background-color="white"></v-text-field>
                                    </v-col>
                                    <!-- User Telephone -->
                                    <v-col cols="12" xs="12" md="6" class="">
                                        <v-text-field outlined dense class="primary--text" v-model="item.userJobTitle" label="Job Title" hide-details background-color="white"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel class="grey lighten-4">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Profile Photo</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <v-col cols="12" sm="12" class="">
                                        <FileUpload
                                            accept="image/jpeg, image/jpg"
                                            label="Select file"
                                            buttonClass="primary"
                                            progressClass="pa-2"
                                            folderLocation="profile"
                                            :fileName="item.id"
                                            :selectedItem="item"
                                            :compressionMaxSizeMB="1"
                                            :compressionmaxWidthOrHeight="1920"
                                            :private="true"
                                            :isProfile="true"
                                            v-on:update="loadDataDown()"
                                        />
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel class="grey lighten-4">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Organisation</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <v-col cols="12" xs="12" md="6">
                                        <v-autocomplete
                                            label="Organisation"
                                            v-model="selectedOrganisation"
                                            :rules="rules.contactOrganisation"
                                            :items="organisations"
                                            item-text="orgName"
                                            return-object
                                            outlined
                                            dense
                                            hide-details="auto"
                                            background-color="white"
                                            color="primary"
                                            clearable
                                            @click:clear="clearSelectedOrganisation()"
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel class="grey lighten-4">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Security / Access</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <!-- User Group -->
                                    <v-col cols="12" xs="12" md="6">
                                        <v-select
                                            label="User Group"
                                            v-model="item.userGroup"
                                            :rules="rules.userGroup"
                                            :items="userGroupItems"
                                            required
                                            outlined
                                            dense
                                            hide-details="auto"
                                            background-color="white"
                                            color="primary"
                                            clearable
                                            :menu-props="{ top: false, offsetY: true }"
                                            :attach="true"
                                            @click:clear="clearItem('userGroup')"
                                        ></v-select>
                                    </v-col>
                                    <!-- User Levels -->
                                    <v-col cols="12" xs="12" md="6">
                                        <v-select
                                            label="User Level"
                                            v-model="item.userLevel"
                                            :rules="rules.userLevel"
                                            :items="userLevelItems"
                                            required
                                            outlined
                                            dense
                                            hide-details="auto"
                                            background-color="white"
                                            color="primary"
                                            clearable
                                            :menu-props="{ top: true, offsetY: true, maxHeight: '200' }"
                                            :attach="true"
                                            @click:clear="clearItem('userLevel')"
                                        ></v-select>
                                    </v-col>
                                    <!-- <pre>{{ item }}</pre> -->
                                    <!-- User Status -->
                                    <v-col cols="12" xs="12" md="6" class="pt-8">
                                        <v-select
                                            label="User Status"
                                            v-model="item.userStatus"
                                            :rules="rules.userStatus"
                                            :items="userStatusItems"
                                            required
                                            outlined
                                            hide-details="auto"
                                            dense
                                            background-color="white"
                                            color="primary"
                                            clearable
                                            :menu-props="{ top: true, offsetY: true }"
                                            :attach="true"
                                            @click:clear="clearItem('userStatus')"
                                        ></v-select>
                                    </v-col>
                                    <!-- User Pin -->
                                    <v-col cols="12" xs="12" md="6">
                                        <div class="grey--text font-weight-medium">On-Demand Pin</div>
                                        <v-otp-input hide-details="auto" dense label="On-Demand Pin" v-model="item.userPin" type="number" length="4" :rules="pinRules"></v-otp-input>
                                    </v-col>
                                    <v-col v-if="item.userLevel !== 'HomeUser' && item.userLevel !== 'HomeManager' && item.userLevel" class="d-flex justify-start align-center" cols="12" xs="12">
                                        <v-switch v-if="!newItem" class="mb-3" dense inset v-model="item.userChangePassword" :true-value="'1'" :false-value="'0'" hide-details="auto" label="Change Password at next Login"></v-switch>
                                        <v-spacer />
                                        <v-btn v-if="!changePassword && !newItem" text class="primary white--text font-weight-bold" @click="showChangePassword()">Change Password</v-btn>
                                        <v-btn v-if="changePassword || newItem" text class="white primary--text font-weight-bold mr-2" @click="usePass123()">Set Default</v-btn>
                                        <v-btn v-if="changePassword && !newItem" text class="primary white--text font-weight-bold" @click="cancelChangePassword()">Cancel Change Password</v-btn>
                                    </v-col>
                                    <v-col v-if="changePassword || (newItem && item.userLevel !== 'HomeUser' && item.userLevel !== 'HomeManager' && item.userLevel)" cols="12" xs="12" md="6">
                                        <v-text-field
                                            outlined
                                            class="primary--text"
                                            v-model="password"
                                            label="Password"
                                            :append-icon="showPassword ? 'oomph-unavailable' : 'oomph-lock'"
                                            :type="showPassword ? 'text' : 'password'"
                                            @click:append="showPassword = !showPassword"
                                            :rules="passwordRules"
                                            autocomplete="off"
                                            required
                                            dense
                                            hide-details="auto"
                                            background-color="white"
                                            color="primary"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col v-if="changePassword || (newItem && item.userLevel !== 'HomeUser' && item.userLevel !== 'HomeManager' && item.userLevel)" cols="12" xs="12" md="6">
                                        <v-text-field
                                            outlined
                                            class="primary--text"
                                            v-model="confirmPassword"
                                            label="Confirm Password"
                                            :append-icon="showConfirmPassword ? 'oomph-unavailable' : 'oomph-lock'"
                                            :type="showConfirmPassword ? 'text' : 'password'"
                                            @click:append="showConfirmPassword = !showConfirmPassword"
                                            :rules="[password === confirmPassword || 'Password must match']"
                                            autocomplete="off"
                                            required
                                            dense
                                            hide-details="auto"
                                            background-color="white"
                                            color="primary"
                                        ></v-text-field>
                                    </v-col>

                                    <!-- User Deleted -->
                                    <!-- TODO - RG - Remove User Deleted before Go Live -->
                                    <v-col cols="12" xs="12" md="6" class="">
                                        <v-text-field outlined dense class="primary--text" v-model="item.deleted" label="Deleted Flag" type="number" hide-details background-color="purple lighten-4"></v-text-field>
                                    </v-col>
                                    <!-- User Id -->
                                    <v-col cols="12" xs="12" md="6" class="">
                                        <v-text-field disabled outlined dense class="primary--text" v-model="item.id" label="User ID" hide-details background-color="purple lighten-4"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <!-- <pre align="left" class="caption">{{ item }}</pre> -->
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="pl-1 pt-0 mt-0">
            <v-col v-if="editmode" cols="12" class="d-flex justify-start grey darken-1 py-4 px-4 mt-0 pr-7" :style="!pu ? 'position: fixed; bottom: 0 !important; max-width: 49vw !important' : ''">
                <v-btn class="grey lighten-1 white--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span></v-btn>
                <v-spacer />
                <v-btn class="primary white--text font-weight-bold" depressed @click="validateForm()"> <v-icon class="mr-2">oomph-save</v-icon><span>Save</span></v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="approveUser" max-width="1024px">
            <v-card>
                <v-toolbar class="pa-0 ma-0" color="primary" elevation="0">
                    <div>
                        <v-icon class="white--text">oomph-map</v-icon>
                    </div>
                    <div class="text-left white--text text-h5 pl-2 font-weight-light">Approve User</div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="approveUser = !approveUser" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </v-toolbar>
                <v-card-text class="grey lighten-3 px-4">
                    <v-row style="height: 200px !important" class="py-2 pt-4 px-0" dense>
                        <v-col cols="12">
                            <form>
                                <v-row dense>
                                    <v-col cols="12" class="d-flex align-center">
                                        <div class="text-left primary--text text-h7">
                                            Please select the User Group and User Level for the following user: <span class="grey--text text--darken-1">{{ item.userName }}, {{ item.orgName }}</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6">
                                        <v-select
                                            label="User Group"
                                            v-model="item.userGroup"
                                            :rules="rules.userGroup"
                                            :items="userGroupItems"
                                            required
                                            outlined
                                            dense
                                            hide-details="auto"
                                            background-color="white"
                                            color="primary"
                                            clearable
                                            :menu-props="{ top: false, offsetY: true }"
                                            :attach="true"
                                            @click:clear="clearItem('userGroup')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6">
                                        <v-select
                                            label="User Level"
                                            v-model="item.userLevel"
                                            :rules="rules.userLevel"
                                            :items="userLevelItems"
                                            required
                                            outlined
                                            dense
                                            hide-details="auto"
                                            background-color="white"
                                            color="primary"
                                            clearable
                                            :menu-props="{ top: true, offsetY: true }"
                                            :attach="true"
                                            @click:clear="clearItem('userLevel')"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </form>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="grey lighten-1 pa-3 pr-4 d-flex justify-right align-center">
                    <v-spacer />
                    <div>
                        <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="approveUser = !approveUser"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                    </div>
                    <div class="ml-2">
                        <v-btn class="success white--text font-weight-bold" depressed @click="approvedUser()"> <v-icon class="mr-2">oomph-save</v-icon><span>Approve</span> </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth'
import schema from '@/schema.js'
import { mapGetters } from 'vuex'
export default {
    name: 'UserDetails',
    // * PROPS
    props: {
        dense: {
            type: Boolean,
            default: false
        },
        datadown: {
            type: Object,
            default: () => {
                return {}
            }
        },
        org: {
            type: Object,
            default: function () {
                return {}
            }
        },
        fullContact: {
            type: Boolean,
            default: false
        },
        quickContact: {
            type: Boolean,
            default: false
        },
        pu: {
            type: Boolean,
            default: false
        },
        newItem: {
            type: Boolean,
            default: false
        },
        homeUser: {
            type: Boolean,
            default: false
        },
        homeLogin: {
            type: Boolean,
            default: false
        },
        groupUser: {
            type: Boolean,
            default: false
        },
        streamUser: {
            type: Boolean,
            default: false
        },
        orgid: {
            type: String,
            default: ''
        }
    },
    // * DATA
    data: () => ({
        panelFixed: 0,
        assignedTo: [],
        item: {
            id: '',
            userEmail: '',
            userName: '',
            photoURL: '',
            deleted: '0',
            userLevel: ''
        },
        editmode: true,
        loading: true,
        // Items
        items: [],
        itemsTotal: null,
        itemsFrom: 0,
        itemsTo: 9,
        schema: {},

        activeItem: {},
        searchQuery: '',
        // Validation
        rules: {},
        pinRules: [
            (v) => !!v || 'User Pin is required.',
            (v) => (v && v.length == 4) || 'User Pin must be 4 digits.',
            (v) => (v && !isNaN(parseInt(v))) || 'User Pin must be a number.'
        ],

        show: false,

        approveUser: false,

        organisations: [],
        selectedOrganisation: null,

        userLevelItemsBackup: [],
        userLevelItems: [], //"StaffAdmin", "StaffUser", "StaffManager", "GuestUser", "DeveloperAdmin", "HomeLogin", "HomeManager", "HomeUser", "ResidentUser"],

        userGroupItemsBackup: [],
        userGroupItems: [], //["Content", "Accounts", "Sales", "Administration", "IT", "Developers", "Guest"],
        userStatusItems: [], //"Approved", "Pending", "Deleted", "Archived", "Suspended", "Rejected"],


        passwordRules: [(v) => !!v || 'Password is required'],
        showPassword: false,
        showConfirmPassword: false,
        password: '',
        confirmPassword: '',

        changePassword: false
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken'
        }),
        inputCols() {
            if (this.listshrunk) {
                return '4'
            } else {
                return '6'
            }
        },
        userLevel() {
            return this.GET_FIREBASE_userAccessToken.user_level
        }
    },
    methods: {
        showChangePassword() {
            var t = this

            t.changePassword = true

            t.item.userChangePassword = '1'
        },
        cancelChangePassword() {
            var t = this

            ;(t.changePassword = false), (t.item.userChangePassword = '0')
        },
        usePass123() {
            var t = this

            t.password = 'Pass123!'
            t.confirmPassword = 'Pass123!'
            t.showPassword = true
            t.showConfirmPassword = true
        },
        async initialise() {
            let t = this
            t.loading = true

            if (JSON.stringify({ ...t.datadown }) === '{}') {
                t.item = { ...t.schema }
            } else {
                t.openDocument()
            }

            let userStatusesResult = await t.MIX_redisReadSet('set:userStatuses', true)
            if (userStatusesResult.code === 1) {
                t.userStatusItems = userStatusesResult.data
            }
            let userGroupsResult = await t.MIX_redisReadSet('set:userGroups', true)
            if (userGroupsResult.code === 1) {
                t.userGroupItems = userGroupsResult.data
                t.userGroupItems.sort((a, b) => a.localeCompare(b))

                t.userGroupItemsBackup = [...t.userGroupItems]
            }
            let userLevelsResult = await t.MIX_redisReadSet('set:userLevels', true)
            if (userLevelsResult.code === 1) {
                const userLevelItems = userLevelsResult.data
                userLevelItems.sort((a, b) => a.localeCompare(b))

                if (t.datadown.userLevel == "HomeUser" || t.datadown.userLevel == "GroupUser" || t.datadown.userLevel == "GuestUser") {
                    for (var i = userLevelItems.length - 1; i >= 0; i--) {
                        if (userLevelItems[i] === "DeveloperAdmin") {
                            userLevelItems.splice(i, 1);
                        }
                    }
                }
                
                t.userLevelItems = userLevelItems
                t.userLevelItemsBackup = userLevelItems
            }

            t.organisations = await t.MIX_organisations()
            t.organisations.sort((a, b) => a.orgName.localeCompare(b.orgName))

            if (this.pu) {
                this.editmode = true
            }

            if (t.homeUser == true) {
                // if (t.newItem) {
                //     t.item.userLevel = 'HomeUser'
                // }
                t.item.userGroup = 'Home'
                t.item.userStatus = 'Approved'
            }

            if (t.homeLogin == true) {
                t.item.userLevel = 'HomeLogin'
                t.item.userGroup = 'Home'
                t.item.userStatus = 'Approved'
            }
            if (t.groupUser == true) {
                t.item.userLevel = 'GroupUser'
                t.item.userGroup = 'Customer'
                t.item.userStatus = 'Approved'
            }
            if (t.streamUser == true) {
                t.item.userLevel = 'StreamUser'
                t.item.userGroup = 'Partner'
                t.item.userStatus = 'Approved'
            }

            if (t.orgid !== '') {
                var orgs = t.organisations.filter(function (item) {
                    return item.id === t.orgid
                })
                this.selectedOrganisation = { id: orgs[0].id, orgName: orgs[0].orgName }
                this.item.orgId = this.selectedOrganisation.id
                this.item.orgName = this.selectedOrganisation.orgName
            }

            t.loading = false
        },
        acceptNumber() {
            return this.item.userTelephone.replace(/\D/g, '').match(/(\d{0,5})(\d{0,7})/)
        },
        approvedUser() {
            var t = this
            t.item.userStatus = 'Approved'
            t.saveItem(t.item)
            t.approveUser = false
        },
        validateForm() {
            if (this.homeUser == true || this.item.userLevel === 'HomeAdmin' || this.item.userLevel === 'HomeManager') {
                this.rules = {
                    userName: [(v) => !!v || 'User Name Required'],
                    userJobTitle: [(v) => !!v || 'Job Title Required'],
                    userLevel: [(v) => !!v || 'User Level Required']
                }
            } else if (this.homeLogin == true) {
                this.rules = {
                    userName: [(v) => !!v || 'User Name Required'],
                    userEmail: [(v) => !!v || 'Email Required'],
                    userJobTitle: [(v) => !!v || 'Job Title Required']
                }
            } else {
                this.rules = {
                    userName: [(v) => !!v || 'User Name Required'],
                    userEmail: [(v) => !!v || 'Email Required'],
                    userOrganisation: [(v) => !!v || 'Organisation Required'],
                    userJobTitle: [(v) => !!v || 'Job Title Required'],
                    userJobLevel: [(v) => !!v || 'Job Level Required'],
                    userJobDepartment: [(v) => !!v || 'Job Department Required'],
                    userOwner: [(v) => !!v || 'Assigned to Required']
                }
            }
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    // Updates a firebase auth user
                    if(this.newItem === false && this.item.userLevel === 'DeveloperAdmin' || this.newItem === false && this.item.userLevel === 'HomeLogin'){
                        this.updateFirebaseAuthUser()
                    } 

                    // Updates a firebase user
                    if(this.newItem === false && this.item.userLevel !== 'DeveloperAdmin' || this.newItem === false && this.item.userLevel !== 'HomeLogin'){
                        this.saveItem()
                    }

                    // Creates a firebase user
                    if(this.newItem === true && this.item.userLevel === 'HomeUser' || this.newItem === true &&  this.item.userLevel === 'HomeManager' || this.newItem === true && this.item.userLevel === 'HomeAdmin'){
                        this.createItem()
                    }

                    // Creates a firebase auth user
                    if(this.newItem === true && this.item.userLevel !== 'HomeUser' || this.newItem === true &&  this.item.userLevel !== 'HomeManager' || this.newItem === true && this.item.userLevel !== 'HomeAdmin'){
                        this.registerFirebaseAuthUser()
                    }

                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Please fill in all required fields', show: true })
                }
            })
        },
        async registerFirebaseAuthUser() {
            var t = this
            t.item.userPassword = t.password
            t.item.deleted = '0'
            let documentReadResult = await t.MIX_firebaseAuth_register(t.item, 'users')
            // console.log(JSON.stringify(documentReadResult.data));
            
            if (documentReadResult.code === 1) {
                this.editmode = false
                this.$emit('save', this.item)
                this.MIX_alertBox({ color: 'success', message: 'User Created', timeout: 2000, show: true })
            } else {
                this.MIX_alertBox({ color: 'error', message: documentReadResult.data.error, timeout: 4000, show: true })
            }
        },
        async updateFirebaseAuthUser() {
            let t  = this;
            t.item.photoURL = t.item.photoURL !== '0' && t.item.photoURL !== '' && t.item.photoURL !== undefined ? t.item.photoURL : null
            t.item.userPassword = t.password
            let documentReadResult = await t.MIX_firebaseAuth_update(t.item, 'users')
            // console.log(JSON.stringify(documentReadResult.data));
            
        
            if (documentReadResult.data.code === 1) {
                this.editmode = false
                this.$emit('save', this.item)
                this.MIX_alertBox({ color: 'success', message: 'User Updated', timeout: 2000, show: true })
            } else {
                this.MIX_alertBox({ color: 'error', message: documentReadResult.data.error, timeout: 4000, show: true })
            }
        },
        setFirebaseUser(uid) {
            var t = this
            t.item.id = uid
            t.item.created = t.$moment().format('x')
        },
        clearSelectedOrganisation() {
            setTimeout(() => {
                this.selectedOrganisation = { id: '', orgName: '' }
            }, 1)
        },
        uploadImage(url, file) {
            let t = this
            t.item.photoURL = url
        },
        async createItem() {
            var t = this
            t.item.id = t.MIX_generateId()
            t.item.deleted = '0'
            console.log('item = ' + JSON.stringify(t.item, null, 2))

            let documentReadResult = await t.MIX_firestore_create(t.item, 'users')
            // console.log('documentReadResult = ' + JSON.stringify(documentReadResult, null, 2))

            if (documentReadResult.code === 1) {
                t.editmode = false
                t.$emit('save', t.item)
                t.MIX_alertBox({ color: 'success', message: 'User Created', timeout: 2000, show: true })
                // let addUserToRedis = await t.MIX_redisCreateHash(`user:${t.item.id}`, t.item)
                // // console.log('addUserToRedis = ' + JSON.stringify(addUserToRedis, null, 2))
                // if (addUserToRedis.code === 1) {
                    
                // } else {
                //     t.MIX_alertBox({ color: 'error', message: 'Error Creating Items: ' + addUserToRedis.error, timeout: 4000, show: true })
                // }
                // t.editmode = false;
            } else {
                t.MIX_alertBox({ color: 'error', message: 'Error Creating Items: ' + documentReadResult.error, timeout: 4000, show: true })
                // t.editmode = false;
            }
        },
        async saveItem() {
            let t = this
            if (t.item.id == '') {
                t.item.id = t.MIX_generateId()
            }

            if (this.changePassword) {
                this.item.userPassword = this.password
            }

            t.item.deleted = '0'
            t.item.photoURL = t.item.photoURL !== '0' && t.item.photoURL !== '' && t.item.photoURL !== undefined ? t.item.photoURL : null
            let updateFirebaseAuth = {}
            if (this.homeUser !== true && this.item.userLevel !== 'HomeAdmin' && this.item.userLevel !== 'HomeManager') {
                // check if in firebaseAuth
                updateFirebaseAuth = await t.MIX_firestore_update(t.item.id, 'users', t.item)
            }
            if (
                updateFirebaseAuth.code === 1 ||
                updateFirebaseAuth.error == 'There is no user record corresponding to the provided identifier.' ||
                this.homeUser === true ||
                this.item.userLevel === 'HomeAdmin' ||
                this.item.userLevel === 'HomeManager'
            ) {
                delete this.item.userPassword

                let documentReadResult = await t.MIX_firestore_update(t.item.id, 'users', t.item)
                // t.item.photoURL = t.item.photoURL === null ? '0' : t.item.photoURL
                if (documentReadResult.code === 1) {
                    t.editmode = false
                    t.$emit('save', t.item)
                    t.MIX_alertBox({ color: 'success', message: 'User Updated', timeout: 2000, show: true })
                }
            } else {
                t.MIX_alertBox({ color: 'error', message: 'Error updating items: ' + updateFirebaseAuth.error, timeout: 4000, show: true })
                t.editmode = false
            }
        },
        cancelItem() {
            this.editmode = false
            this.$emit('cancel')
        },
        loadDataDown() {
            this.item = { ...this.datadown }
            if (this.item.orgId !== '') {
                this.selectedOrganisation = {
                    id: this.datadown.orgId,
                    orgName: this.datadown.orgName
                }
            }
        },
        async openDocument() {
            this.item = { ...this.datadown }
            let documentReadResult = await this.MIX_firestore_read(this.item.id, 'users')
            if (documentReadResult.code === 1) {
                this.item = documentReadResult.data
                if (this.item.orgId !== '') {
                    this.selectedOrganisation = {
                        id: this.item.orgId,
                        orgName: this.item.orgName
                    }
                }
            }
        },
        // Clear Item Property
        clearItem(key, value) {
            setTimeout(() => {
                this.item[key] = value
            }, 1)
        },
        // Clear Item Property
        clearContactName(key, value) {
            setTimeout(() => {
                this.item[key] = value
            }, 1)
        }
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.openDocument()
            },
            deep: true
        },
        selectedOrganisation: {
            handler: function () {
                this.item.orgId = this.selectedOrganisation.id
                this.item.orgName = this.selectedOrganisation.orgName
            },
            deep: true
        }
    },
    // * CREATED
    async created() {
        this.schema = schema.user
        this.initialise()
    }
}
</script>
