<template>
    <div class=""
        style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important; overflow-y: scroll !important; overflow-x: hidden !important">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey lighten-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div
                        class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">
                        Loading</div>
                </div>
                <v-btn class="ml-3 grey darken-1 white--text font-weight-bold" depressed @click="cancelItem"
                    v-if="editMode"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="!editMode" @click="editMode = true"
                        :large="!dense"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editMode" @click="validateForm()" :large="!dense"><v-icon
                            class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <!-- Contact Details View -->
        <v-row class="apptext--text px-3" dense v-if="!editMode">
            <v-col v-if="!editMode && !loading" cols="12" class="d-flex align-center justify-start rounded">
                <v-row style="width: 100% !important" dense>
                    <!-- Full Name / Job Title / Department / Marketing -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Details</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold body-2 white">Event Title:
                                                </td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{ item.eventTitle
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold body-2 white">Event Type:
                                                </td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{ item.eventType
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Start Date:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ $moment(item.eventStartDateTime, 'X').format('DD-MMM-YYYY') }}
                                                    <span v-if="item.eventAllDay === '0'">{{
                                                        $moment(item.eventStartDateTime, 'X').format('HH:mm') }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">End Date:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ $moment(item.eventEndDateTime, 'X').format('DD-MMM-YYYY') }}
                                                    <span v-if="item.eventAllDay === '0'">{{ $moment(item.eventEndDateTime,
                                                        'X').format('HH:mm') }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Event Status:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        item.eventStatus }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Event Alias:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        item.contentAlias }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Telepjhone Numbers and Email -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Description</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Description:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        item.eventDescription }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Content Partner -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Content
                                        Partner</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <!-- Card Body -->
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Organisation:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{ item.orgName }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Tags -->
                    <v-col cols="12" sm="12" :md="inputCols" v-if="item.eventTags !== ''">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Tags</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-price-tag</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col v-if="item.eventTags !== '' && item.eventTags !== undefined" cols="12"
                                style="height: 100% !important" class="pt-2">
                                <v-chip label class="mr-1 mt-1" small v-for="tag in item.eventTags.split(',')" :key="tag">{{
                                    tag }}</v-chip>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Metadata -->
                    <v-col cols="12" sm="12" :md="inputCols">
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <!-- Card Heading -->
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span
                                        class="text-left primary--text text--darken-2 text-h6 font-weight-light">Metadata</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-info-squared</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Owner:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        item.ownerUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Created by:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        item.createdUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Created:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ MIX_formatDateTime(item.createdDateTime, 'x', 'DD-MMM-YYYY HH:mm:ss')
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Modified by:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">{{
                                                        item.modifiedUserName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top"
                                                    class="pl-0 apptext--text font-weight-bold white">Modified:</td>
                                                <td width="60%" valign="top"
                                                    class="apptext--text font-weight-normal body-1 white">
                                                    {{ MIX_formatDateTime(item.modifiedDateTime, 'x', 'DD-MMM-YYYY HH: mm: ss') }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- <pre>{{ item }}</pre> -->

        <!-- Contact Details Edit -->
        <v-row class="px-3">
            <v-col v-if="editMode && !loading" cols="12" class="d-flex align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- GENERAL DETAILS EDIT-->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="12" class="d-flex align-center">
                                    <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Details
                                    </div>
                                    <v-spacer />
                                    <div class="caption text-right"><strong>ID:</strong> {{ item.id }}</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Event Title" v-model="item.eventTitle" :rules="rules.eventTitle"
                                        outlined dense hide-details="auto" background-color="white" color="primary"
                                        clearable @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-autocomplete label="Event Type" v-model="item.eventType" :rules="rules.eventType"
                                        :items="eventTypes" auto-select-first outlined dense hide-details="auto"
                                        background-color="white" color="primary" clearable
                                        @click:clear="clearselectedJobLevel()"
                                        autocomplete="donotautocomplete"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-menu v-model="dateMenu1" :close-on-content-click="false"
                                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Event Start Date" v-model="computedDate1"
                                                :rules="rules.eventStartDateTime" outlined dense hide-details="auto"
                                                background-color="white" color="primary" v-bind="attrs" v-on="on" readonly
                                                clearable
                                                @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date1" full-width no-title color="secondary"
                                            :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                                    </v-menu>
                                    <!-- <v-text-field label="Event Start Date" v-model="item.eventStartDateTime" :rules="rules.orgRegistrationNumber" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearContactName('contactTwitter', '')"></v-text-field> -->
                                    <div class="ml-1 mt-1 body-2 primary--text" @click="showStartTime = !showStartTime"
                                        style="cursor: pointer">
                                        <span v-if="!showStartTime">Add Specific Start Time</span>
                                        <span v-if="showStartTime">Hide Specific Start Time</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-menu v-model="dateMenu2" :close-on-content-click="false"
                                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="Event End Date" v-model="computedDate2"
                                                :rules="rules.eventEndDateTime" outlined readonly dense hide-details="auto"
                                                background-color="white" color="primary" v-bind="attrs" v-on="on" clearable
                                                @click:clear="clearContactName('contactTwitter', '')"></v-text-field>
                                            <!-- <v-text-field dense background-color="white" filled v-model="computedDate2" label="Event End Date" hide-details="auto" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field> -->
                                        </template>
                                        <v-date-picker v-model="date2" full-width no-title color="secondary"
                                            :first-day-of-week="1" @input="dateMenu2 = false"></v-date-picker>
                                    </v-menu>
                                    <!-- <v-text-field label="Event End Date Name" v-model="item.eventEndDateTime" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contactFullName', '')"></v-text-field> -->
                                    <div class="ml-1 mt-1 body-2 primary--text" @click="showEndTime = !showEndTime"
                                        style="cursor: pointer">
                                        <span v-if="!showEndTime">Add Specific End Time</span>
                                        <span v-if="showEndTime">Hide Specific End Time</span>
                                    </div>
                                </v-col>
                                <v-col v-if="showStartTime || showEndTime" cols="12" xs="12" md="6">
                                    <v-row dense v-if="showStartTime">
                                        <!-- start time -->
                                        <v-col cols="12" xs="12" md="6" class="my-1">
                                            <v-autocomplete label="Hour" v-model="eventStartTimeHours" :items="hours"
                                                auto-select-first outlined dense hide-details="auto"
                                                background-color="white" color="primary" clearable
                                                @click:clear="clearselectedJobLevel()"
                                                autocomplete="donotautocomplete"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="6" class="my-1">
                                            <v-autocomplete label="Minutes" v-model="eventStartTimeMins" :items="mins"
                                                auto-select-first outlined dense hide-details="auto"
                                                background-color="white" color="primary" clearable
                                                @click:clear="clearselectedJobLevel()"
                                                autocomplete="donotautocomplete"></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col v-if="showEndTime || showStartTime" cols="12" xs="12" md="6">
                                    <v-row dense v-if="showEndTime">
                                        <!-- end time -->
                                        <v-col cols="12" xs="12" md="6" class="my-1">
                                            <v-autocomplete label="Hour" v-model="eventEndTimeHours" :items="hours"
                                                auto-select-first outlined dense hide-details="auto"
                                                background-color="white" color="primary" clearable
                                                @click:clear="clearselectedJobLevel()"
                                                autocomplete="donotautocomplete"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="6" class="my-1">
                                            <v-autocomplete label="Minutes" v-model="eventEndTimeMins" :items="mins"
                                                auto-select-first outlined dense hide-details="auto"
                                                background-color="white" color="primary" clearable
                                                @click:clear="clearselectedJobLevel()"
                                                autocomplete="donotautocomplete"></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-autocomplete label="Event Status" v-model="item.eventStatus"
                                        :rules="rules.eventStatus" :items="eventStatus" auto-select-first outlined dense
                                        hide-details="auto" background-color="white" color="primary" clearable
                                        @click:clear="clearselectedJobLevel()"
                                        autocomplete="donotautocomplete"></v-autocomplete>
                                </v-col>
                                <v-col cols="6" class="my-1">
                                    <v-select label="Content Alias" background-color="white" :rules="rules" class="pr-1"
                                        v-model="selectedAlias" :items="contentAlias" item-text="alias" outlined dense
                                        color="primary" hide-details="auto" clearable
                                        :menu-props="{ top: false, offsetY: true }" :attach="true"
                                        @click:clear="clearContactName('contentArea', '')"></v-select>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12" xs="12" class="d-flex align-center my-1 pl-2">
                                    <div class="text-left grey--text body-2 font-weight-regular pr-3">Is this a Live Stream
                                        Event: </div>
                                    <v-radio-group v-model="eventLiveStream" hide-details="auto" row dense>
                                        <v-radio class="grey--text" label="Yes" value="1"></v-radio>
                                        <v-radio class="grey--text" label="No" value="0"></v-radio>
                                    </v-radio-group>
                                    <v-spacer />
                                    <v-icon @click="incrementIconKey"
                                        v-if="item.eventLiveStreamId !== ''">oomph-tv-show</v-icon>
                                </v-col>
                            </v-row>
                            <v-row v-if="liveStreamKey >= 10" dense>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Event Playback ID" v-model="item.eventPlaybackId" outlined dense
                                        hide-details="auto" background-color="white" color="primary" clearable
                                        disabled></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Event Stream Key" v-model="item.eventStreamKey" outlined dense
                                        hide-details="auto" background-color="white" color="primary" clearable
                                        disabled></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-text-field label="Event Live Stream ID" v-model="item.eventLiveStreamId" outlined
                                        dense hide-details="auto" background-color="white" color="primary" clearable
                                        disabled></v-text-field>
                                </v-col>
                            </v-row>
                            <!-- <pre>streamKey: {{ item.eventStreamKey }}</pre>
                            <pre>id: {{ item.eventLiveStreamId }}</pre> -->
                        </v-col>
                        <!-- <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <span class="text-left grey--text pr-3 text-h6 font-weight-light">Live Stream</span>
                                    <v-chip small><span class="grey--text">Coming Soon</span></v-chip>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                            </v-row>
                        </v-col> -->
                        <!-- <pre>{{ item }}</pre> -->
                        <!-- ORGANISATOIN EDIT -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Short
                                        Description</div>
                                    <!-- <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleOrganisation = !toggleOrganisation">
                                        <span v-if="!toggleOrganisation">Show</span>
                                        <span v-if="toggleOrganisation">Hide</span>
                                    </v-btn> -->
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" class="my-1">
                                    <!-- <v-text-field
                                        label="Event Description"
                                        v-model="item.eventDescription"
                                        :rules="rules.eventDescription"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearItem('contactReportingTo', '')"
                                    ></v-text-field> -->
                                    <v-textarea label="Event Description" :rules="descriptionRules" counter=1000 rows="2"
                                        background-color="white" v-model="item.eventDescription" outlined dense
                                        color="primary" hide-details="auto" clearable
                                        @click:clear="clearItem('eventDescription', '')">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- CONTENT PARTNER -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Content
                                        Partner</div>
                                    <!-- <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleJobRole = !toggleJobRole">
                                        <span v-if="!toggleJobRole">Show</span>
                                        <span v-if="toggleJobRole">Hide</span>
                                    </v-btn> -->
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="my-1">
                                    <v-autocomplete label="Partner" v-model="selectedOrganisation"
                                        :rules="rules.eventOrganisation" :items="organisations" item-text="orgName"
                                        item-value="orgId" return-object outlined dense hide-details="auto"
                                        background-color="white" color="primary" clearable
                                        @click:clear="clearSelectedOrganisation()"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" md="6" class="d-flex justify-end">
                                    <v-row dense class="d-flex align-center justify-end pt-2">
                                        <v-checkbox v-model="item.eventShowPartnerLogo" hide-details="auto"></v-checkbox>
                                        <div class="text-left grey--text body-2 font-weight-regular pr-4">Show Partner Logo
                                            alongside Content:</div>
                                        <!-- <v-radio-group v-model="item.eventShowPartnerLogo"  hide-details="auto" row dense>
                                                <v-radio class="grey--text" label="Yes" value="1"></v-radio>
                                                <v-radio class="grey--text" label="No" value="0"></v-radio>
                                            </v-radio-group> -->
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- ASSIGNED TO  -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text--darken-2  text-h6 font-weight-light">Assigned to</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" :md="inputCols">
                                    <v-autocomplete :disabled="selectedOrganisation.orgId == ''" label="Owner *" v-model="selectedOwner" :rules="rules.eventOwner"
                                        :items="users" item-text="ownerUserName" auto-select-first return-object outlined
                                        dense hide-details="auto" background-color="white" color="primary" clearable
                                        @click:clear="clearSelectedOwner()">
                                        <template v-slot:selection="{ item }">
                                            {{ item.ownerUserName }}
                                            <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            {{ item.ownerUserName }}
                                            <div class="ml-2 caption grey--text">{{ item.ownerUserEmail }}</div>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- //* TAGS EDIT-->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="my-1">
                                    <v-row dense>
                                        <v-col cols="12" sm="6" class="d-flex align-center">
                                            <div class="text-left  primary--text text--darken-2 text-h6 font-weight-light">
                                                Tags</div>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <v-divider class="grey lighten-1 mb-1"></v-divider>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="12">
                                            <v-combobox multiple small-chips hide-selected label="Tags"
                                                v-model="selectedTags" :search-input.sync="tagAutocomplete"
                                                @change="tagAutocomplete = ''" :items="eventTags" outlined dense
                                                hide-details="auto" background-color="white" color="primary"
                                                :menu-props="{ top: true, offsetY: true }" :attach="true" clearable
                                                @click:clear="clearItem('quoteChannel')">
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                                    <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected"
                                                        label small>
                                                        <span class="pr-2">
                                                            {{ formatTag(item) }}
                                                        </span>
                                                        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                                                    </v-chip>
                                                </template>
                                            </v-combobox>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn-toggle v-model="selectedTags" style="width: 100% !important" multiple
                                                dense class="d-flex flex-wrap flex-row">
                                                <v-btn style="width: 25% !important" value="dementia friendly">
                                                    <div class="mx-3">Dementia Friendly</div>
                                                    <!-- <v-icon class="grey--text text--darken-3">oomph-edit</v-icon> -->
                                                </v-btn>
                                                <v-btn style="width: 25% !important" value="small group">
                                                    <div class="mx-3">Small Group</div>
                                                    <!-- <v-icon class="grey--text text--darken-3">oomph-delete-trash</v-icon> -->
                                                </v-btn>
                                                <v-btn style="width: 25% !important" value="visual impairment">
                                                    <div class="mx-3">Visual Impairment</div>
                                                    <!-- <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon> -->
                                                </v-btn>
                                                <v-btn style="width: 25% !important" value="hearing impairment">
                                                    <div class="mx-3">Hearing Impairment</div>
                                                    <!-- <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon> -->
                                                </v-btn>
                                            </v-btn-toggle>

                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- <pre>{{ item }}</pre> -->
                    </v-row>
                    <v-row style="height: 80px !important" />
                </v-form>
            </v-col>
            <!-- <v-col cols="12">
                <pre>{{ item }}</pre>
            </v-col> -->
        </v-row>
        <v-row dense class="pl-1">
            <v-col v-if="editMode" cols="12" class="d-flex justify-start grey lighten-1 py-4 px-4 pr-7"
                style="position: fixed; bottom: 0 !important; max-width: 49vw !important">
                <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="cancelItem"> <v-icon
                        class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                <v-spacer />
                <v-btn class="primary white--text font-weight-bold" depressed @click="saveItem"> <v-icon
                        class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
            </v-col>
        </v-row>
        <!-- <pre>{{ item }}</pre> -->
        <!-- <v-row dense class="pl-1">
            <v-col v-if="editMode" cols="12" class="d-flex justify-start grey lighten-1 py-4 px-4 pr-7" style="position: fixed; bottom: 0 !important; width: 49vw !important">
                
                <v-btn class="secondary grey--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                <v-spacer />
                <v-btn class="primary white--text font-weight-bold" depressed @click="saveItem"> <v-icon class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
            </v-col>
        </v-row> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    name: 'EventDetails',
    // * PROPS
    props: {
        dense: {
            type: Boolean,
            default: false,
        },
        datadown: {
            type: Object,
            default: () => {
                contentDeta
                return {};
            },
        },
        org: {
            type: Object,
            default: function () {
                return {};
            },
        },
        pu: {
            type: Boolean,
            default: false,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        editmode: {
            type: Boolean,
            default: false,
        },
        newItem: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        item: {
            eventShowPartnerLogo: false,
        },
        key: 'event',
        index: 'idx:events',
        editMode: false,
        // Items
        items: [],
        selectedAlias: [],
        contentAlias: [],
        itemsTotal: null,
        itemsFrom: 0,
        itemsTo: 9,
        activeItem: {},
        eventTags: [],
        searchQuery: '',
        tagAutocomplete: '',
        // dropdowns
        eventStatus: [],
        eventTypes: [],
        options: [],
        optionSelected: {},
        selectedTags: [],
        // Validation
        rules: {},
        loading: true,
        // Organisation Dropdown
        organisations: [],
        selectedOrganisation: {
            orgId: 'OOMPHWELLNESS0000001',
            orgName: 'Oomph',
        },
        users: [],
        selectedOwner: null,
        orgResidents: [],
        // date picker
        date1: moment().format('YYYY-MM-DD'),
        dateMenu1: false,
        date2: moment().format('YYYY-MM-DD'),
        dateMenu2: false,
        showStartTime: false,
        showEndTime: false,
        eventStartTimeHours: '',
        eventStartTimeMins: '',
        eventEndTimeHours: '',
        eventEndTimeMins: '',

        eventTimeDefaults: {
            eventStartTimeHours: '00',
            eventStartTimeMins: '00',
            eventEndTimeHours: '23',
            eventEndTimeMins: '55',
        },


        descriptionRules: [v => v.length <= 1000 || 'Max 1000 characters'],

        eventLiveStream: '0',
        eventLiveStreamURL: '',

        liveStreamKey: 0
    }),
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_FIREBASE_userData: 'firebase_auth_store/GET_FIREBASE_userData',
        }),
        inputCols() {
            if (this.listshrunk) {
                return '4';
            } else {
                return '6';
            }
        },
        computedDate1() {
            return this.$moment(this.date1, 'YYYY-MM-DD').format('DD-MMM-YYYY');
        },
        computedDate2() {
            return this.$moment(this.date2, 'YYYY-MM-DD').format('DD-MMM-YYYY');
        },
        hours() {
            var hours = [];
            for (var i = 0; i < 24; i++) {
                if (i < 10) {
                    hours.push('0' + i).toString();
                } else {
                    hours.push(i.toString());
                }
            }
            return hours;
        },
        mins() {
            var mins = [];
            for (var i = 0; i < 60; i++) {
                if (i < 10) {
                    mins.push('0' + i).toString();
                } else {
                    mins.push(i.toString());
                }
                i = i + 4;
            }
            return mins;
        },
    },
    // * METHODS
    methods: {
        validateForm() {
            this.rules = {
                eventTitle: [(v) => !!v || 'Title Required'],
                eventType: [(v) => !!v || 'Type Required'],
                eventStartDateTime: [(v) => !!v || 'Start Date Required'],
                eventEndDateTime: [(v) => !!v || 'End Date Required'],
                eventStatus: [(v) => !!v || 'Status Required'],
                eventDescription: [(v) => !!v || 'Description Required'],
                eventOrganisation: [(v) => !!v || 'Organisation Required'],
                eventAlias: [(v) => !!v || 'Alias Required'],
                //eventOwner: [(v) => !!v || 'Assigned to Required'],
            };
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    this.saveItem();
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Please fill in all required fields', show: true });
                }
            });
        },
        isDateValid(date) {
            if (date === null || date === undefined || date === '' || date == "Invalid date") return false
            return true
        },

        saveItem() {
            if (this.item.eventShowPartnerLogo === true) {
                this.item.eventShowPartnerLogo = "1";
            } else {
                this.item.eventShowPartnerLogo = "0";
            }
            this.item.eventLiveStream = this.eventLiveStream
            this.assignDate1();
            this.assignDate2();
            this.item.orgId = this.selectedOrganisation.orgId;
            this.item.contentAlias = this.selectedAlias;
            this.item.orgName = this.selectedOrganisation.orgName;
            this.item.ownerUserId = this.selectedOwner.ownerUserId;
            this.item.ownerUserName = this.selectedOwner.ownerUserName;
            this.item.ownerUserEmail = this.selectedOwner.ownerUserEmail;
            this.$emit('save', this.item);
            this.editMode = false;
        },
        cancelItem() {
            this.editMode = false;
            this.$emit('cancel');
        },
        clearSelectedOrganisation() {
            setTimeout(() => {
                this.selectedOrganisation = { orgId: '', orgName: '' };
            }, 1);
        },
        incrementIconKey() {
            this.liveStreamKey++
        },
        loadDataDown() {
            this.editMode = this.editmode;
            this.item = { ...this.datadown };
            if (this.item.eventTitle === '') {
                this.editMode = true;
            }
            if (this.item.orgId !== '') {
                this.selectedOrganisation = {
                    orgId: this.datadown.orgId,
                    orgName: this.datadown.orgName,
                };
            }
            // Tags
            if (this.item.eventTags !== '' && this.item.eventTags !== undefined && this.item.eventTags !== null) {
                this.selectedTags = this.item.eventTags.split(',');
            }
            if (this.item.contentAlias !== '') {
                this.selectedAlias = this.item.contentAlias;
            }
            if (this.item.eventStartDateTime !== '') {
                this.date1 = moment(this.item.eventStartDateTime, 'X').format('YYYY-MM-DD')
            }
            if (this.item.eventEndDateTime !== '') {
                this.date2 = moment(this.item.eventEndDateTime, 'X').format('YYYY-MM-DD')
            }
            if (this.item.eventAllDay === '0') {
                this.eventStartTimeHours = moment(this.item.eventStartDateTime, 'X').format('HH')
                this.eventStartTimeMins = moment(this.item.eventStartDateTime, 'X').format('mm')
                this.eventEndTimeHours = moment(this.item.eventEndDateTime, 'X').format('HH')
                this.eventEndTimeMins = moment(this.item.eventEndDateTime, 'X').format('mm')
                this.showStartTime = true
                this.showEndTime = true
            }
            if (this.item.eventLiveStream !== '') {
                this.eventLiveStream = this.item.eventLiveStream
            }
            // Assigned To
            if (this.item.ownerUserId !== '' && this.item.ownerUserId !== undefined && this.item.ownerUserId !== null) {
                this.selectedOwner = { ownerUserId: this.item.ownerUserId, ownerUserName: this.item.ownerUserName, ownerUserEmail: this.item.ownerUserEmail };
            } else {
                this.selectedOwner = this.MIX_getCurrentOwner();
            }
        },
        // Clear Item Property
        clearItem(key, value) {
            setTimeout(() => {
                this.item[key] = value;
            }, 1);
        },
        // Clear Item Property
        clearContactName(key, value) {
            setTimeout(() => {
                this.item[key] = value;
            }, 1);
        },
        isEventAllDay() {
            if (!this.showStartTime || !this.showEndTime) {
                this.item.eventAllDay = '1'
                return true
            }

            this.item.eventAllDay = '0'
            return false
        },
        assignDate1() {
            if (this.isEventAllDay()) {
                this.item.eventStartDateTime = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
                return
            }

            var startDateTime = '';

            const eventStartTimeHours = this.isDateValid(this.eventStartTimeHours) ? this.eventStartTimeHours : this.eventTimeDefaults.eventStartTimeHours;
            const eventStartTimeMins = this.isDateValid(this.eventStartTimeMins) ? this.eventStartTimeMins : this.eventTimeDefaults.eventStartTimeMins;

            startDateTime = eventStartTimeHours + ':' + eventStartTimeMins
            startDateTime = startDateTime + ' ' + this.date1;

            this.item.eventStartDateTime = this.$moment(startDateTime, 'HH:mm YYYY-MM-DD').format('X');
        },
        assignDate2() {
            if (this.isEventAllDay()) {
                this.item.eventEndDateTime = this.$moment(this.date2, 'YYYY-MM-DD').format('X');
                return
            }
            // TODO _EB
            var endDateTime = '';

            const eventEndTimeHours = this.isDateValid(this.eventEndTimeHours) ? this.eventEndTimeHours : this.eventTimeDefaults.eventEndTimeHours;
            const eventEndTimeMins = this.isDateValid(this.eventEndTimeMins) ? this.eventEndTimeMins : this.eventTimeDefaults.eventEndTimeMins;

            endDateTime = eventEndTimeHours + ':' + eventEndTimeMins;
            endDateTime = endDateTime + ' ' + this.date2;

            this.item.eventEndDateTime = this.$moment(endDateTime, 'HH:mm YYYY-MM-DD').format('X');
        },
        formatTag(item) {
            if (item !== null) {
                return item.toLowerCase();
            } else {
                return '';
            }
        },
        async getUsers(searchQuery) {
            let users = await this.MIX_redisSearch("idx:users", searchQuery, 0, 9999);
            console.log('users = ' + JSON.stringify(users, null, 2));
            if (users.code === 1) {

                const transformedResult = users.data.documents.map(doc => {
                    return {
                        id: doc.id,
                        ...doc.value
                    };
                });
                this.users = transformedResult.map((x) => {
                    return {
                        ownerUserId: x.id,
                        ownerUserName: x.userName,
                        ownerUserEmail: x.userEmail,
                        orgId: x.orgId,
                    };
                });
                this.users.sort((a, b) => a.ownerUserName.localeCompare(b.ownerUserName));
            }
        },
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.loadDataDown();
            },
            deep: true,
        },
        selectedOrganisation: {
            handler: function () {
                this.item.orgId = this.selectedOrganisation.orgId;
                this.item.orgName = this.selectedOrganisation.orgName;

                var searchQuery = `@deleted:0 @orgId:{${this.selectedOrganisation.orgId}}`;
                this.getUsers(searchQuery)
            },
            deep: true,
        },
        editmode: {
            handler: function () {
                this.editMode = this.editmode;
            },
        },
        // Selected Tags
        selectedTags: {
            handler: async function () {
                for (let i = 0; i < this.selectedTags.length; i++) {
                    this.selectedTags[i] = this.selectedTags[i].toLowerCase();
                    this.selectedTags[i] = this.selectedTags[i].replace(/[\W_]+/g, ' ');
                    if (this.selectedTags[i] === ' ') {
                        this.selectedTags.splice(i, 1);
                    } else {
                        this.selectedTags[i] = this.selectedTags[i].trim();
                    }
                }
                // Update Content Tags and split out array with commas
                this.item.eventTags = this.selectedTags.join(',');
            },
            deep: true,
        },
        eventLiveStream: {
            handler: async function () {
                var t = this;
                if (t.eventLiveStream === '1') {
                    let result = await this.MIX_generateLiveStreamURL();
                    if (result.code === 1) {
                        t.item.eventPlaybackId = result.data.playback_ids[0].id;
                        t.item.eventStreamKey = result.data.stream_key
                        t.item.eventLiveStreamId = result.data.id
                    }
                } else if (t.eventLiveStream === '0') {
                    t.item.eventPlaybackId = "";
                    t.item.eventStreamKey = ""
                    t.item.eventLiveStreamId = ""
                }
            }
        }
    },
    // * CREATED
    async created() {
        let t = this;
        t.loading = true;
        // Get Tags
        let eventTags = await t.MIX_redisReadSet('set:contentTags', false);
        if (eventTags.code === 1) {
            t.eventTags = eventTags.data;
        }
        // Get Organisations
        let organisations = await t.MIX_organisations();
        // order organisations by orgName
        t.organisations = organisations.map(organisation => ({
            orgId: organisation.id,
            orgName: organisation.orgName
        }))
        t.organisations.sort((a, b) => a.orgName.localeCompare(b.orgName));
        // Get Users
        // let users = await this.MIX_users(''); // Add Users to Dropdown for Owner
        // this.users = users.map((x) => {
        //     return {
        //         ownerUserId: x.id,
        //         ownerUserName: x.userName,
        //         ownerUserEmail: x.userEmail,
        //         orgId: x.orgId,
        //     };
        // });
        // t.users.sort((a, b) => a.ownerUserName.localeCompare(b.ownerUserName));

        var searchQuery = `@deleted:0 @orgId:{${this.selectedOrganisation.orgId}}`;
        this.getUsers(searchQuery)
        // Event Types
        let eventTypes = await t.MIX_redisReadSet('set:eventTypes', false);
        if (eventTypes.code === 1) {
            t.eventTypes = eventTypes.data;
        }
        // Event Status
        let eventStatus = await t.MIX_redisReadSet('set:eventStatus', false);
        if (eventStatus.code === 1) {
            t.eventStatus = eventStatus.data;
        }

        // Get Content Template - JSON
        let contentAlias = await t.MIX_redisReadSet('set:contentAlias', false);
        if (contentAlias.code === 1) {
            t.contentAlias = contentAlias.data;
        }

        // Load the Data into Item
        this.loadDataDown();
        t.loading = false;
    },
};
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0px;
}
</style>
