<template>
    <div class="px-5 appbackground" style="width: 100% !important; height: calc(100vh - 170px) !important">
        <div class="d-flex align-center py-5">
            <div class="text-h5 font-weight-bold primary--text">Activity Schedule</div>
            <v-spacer />
            <div>
                <v-btn class="primary white--text rounded" @click="downloadCSV()" depressed><v-icon class="mr-2">icons8-export-csv</v-icon>Download CSV</v-btn>
            </div>
        </div>
        <v-data-table :headers="activityHeaders" :items="activities" :items-per-page="10" class="elevation-0 mt-4">
            <template v-slot:[`item.sessionDate`]="{ item }">
                {{ item.sessionDateTime !== null ? MIX_formatDateTime(item.sessionDateTime, "X", "DD MMM YYYY") : "" }}
            </template>
            <template v-slot:[`item.sessionTime`]="{ item }">
                {{ item.sessionDateTime !== null ? MIX_formatDateTime(item.sessionDateTime, "X", "HH:mm") : "" }}
            </template>
            <template v-slot:[`item.contentPartnerOrgId`]="{ item }">
                {{ lookupPartner(item.contentPartnerOrgId) }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        selectedOrgGroup: {
            type: Object,
            default: () => {
                return {};
            },
        },
        selectedSubOrg: {
            type: Array,
            default: () => {
                return [];
            },
        },
        daterange: {
            type: Object,
            default: () => {
                return {
                    startDate: null,
                    endDate: null,
                };
            },
        },
    },
    data: () => ({
        activityHeaders: [
            // { text: "Date", value: "sessionDate", align: "start", sortable: false },
            // { text: "Time", value: "sessionTime", align: "start", sortable: false },
            { text: "Activity", value: "contentTitle", align: "start", sortable: false },
            { text: "Activity Type", value: "contentAlias", align: "start", sortable: false },
            { text: "Source", value: "contentPartnerOrgId", align: "start"},
            { text: "Attendees", value: "sessionAttendance", align: "center", sortable: false },
            { text: "Rating", value: "sessionRating", align: "start" },
        ],
        itemsFrom: 0,
        itemsTo: 0,
        itemsTotal: 0,
        activities: [],
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["contentTitle"],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        residentId: "wYFXKp2kziN8tEevEfBo",
        partnerOrgs:[],
    }),
    computed: {
        computedSortBy() {
            let sortBy = this.datatableOptions.sortBy;
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => "@" + x);
                sortBy = sortBy.join(",");
            }
            return sortBy;
        },
        computedSortDesc() {
            let sortDesc = this.datatableOptions.sortDesc[0];
            return sortDesc;
        },
        computedFields() {
            return ["@id", "@residentId", "@residentName", "@contentTitle", "@sessionDateTime", "@contentAlias", "@contentArea", "@contentPartnerOrgId", "@sessionDuration", "@sessionMoodRating", "@sessionEnagementValue", "@sessionEnagementText", "@sessionAttendance"];
        },
    },
    methods: {
        async getActivityStats() {
            let t = this
            let query = "";
            let subOrgs = [];
            t.loading = true;
            const daterange = t.daterange.startDate && t.daterange.endDate ? `@createdDateTime:[${t.daterange.startDate} ${t.daterange.endDate}]` : '';

            if (t.selectedOrgGroup.id === '3RTRBM9dqrkC4WEsIvhx') {
                //* Returns all stats for all orgs
                query = daterange || '-@deleted:1';
            } else {
                //* Returns stats for selected org
                query = `@createdUserOrgId:{${t.selectedOrgGroup.id}} ${daterange} -@deleted:1`;
            }
            if (t.selectedSubOrg.length > 0) {
                //* Returns stats for selected sub orgs
                subOrgs = t.selectedSubOrg.map((org) => org.id).join('|');
                query = `@createdUserOrgId:{${subOrgs}} ${daterange} -@deleted:1`;
            } 
            
            let result = await t.MIX_redisSearch('idx:statsNew', `@statType:{review} ${query}`, 0, 9999)
            if (result.code === 1) {
                result.data.documents.forEach((activity) => {
                    this.activities.push(activity.value)
                })
            }
            this.activities.sort((a, b) => {
                return a.sessionDateTime - b.sessionDateTime
            })
        },
        lookupPartner(orgId) {
            let partnerObject = this.partnerOrgs.find((x) => x.orgId === 'org:'+orgId);
            if (partnerObject) {
                if (partnerObject.orgApprovedPartner === '1') {
                    if (partnerObject.orgName === 'Oomph Wellness') {
                        return 'Oomph Wellness';
                    }
                    return 'Oomph - ' + partnerObject.orgName;
                } else {
                    return partnerObject.orgName;
                }
            } else {
                return '';
            }
        },
        downloadCSV() {
            let t = this;
            const dateFrom = t.$moment.unix(t.daterange.startDate).format('DDMMMYYYY');
            const dateTo = t.$moment.unix(t.daterange.endDate).format('DDMMMYYYY');
            const dateNow = t.$moment().format('DDMMMYYYY');
            const date = t.daterange.startDate && t.daterange.endDate ? `${dateFrom}_${dateTo}` : dateNow;
            var views = t.activities.map((e) => {
                return {
                    "Date": this.MIX_formatDateTime(e.sessionDateTime, "X", "DD MMM YYYY"),
                    "Time": this.MIX_formatDateTime(e.sessionDateTime, "X", "HH:mm"),
                    "Activity": e.contentTitle,
                    "Activity Type": e.contentAlias,
                    "Attendees": e.sessionAttendance,
                    "Rating": e.sessionRating,
                    "Source": this.lookupPartner(e.contentPartnerOrgId),
                };
            });
            var loginCSV = t.$papa.unparse(views);
            this.$papa.download(loginCSV, `Report_Activity_Schedule_${date}.csv`, "text/csv");
        },
        round2(value) {
            return +(Math.round(value + "e+" + 2) + "e-" + 2);
        },
        convertHoursToHrsMins(hours) {
            var h = Math.floor(hours);
            var m = Math.floor((hours % 1) * 60);
            if (h < 1) {
                return m + " mins";
            }
            return h + " hrs " + m + " mins";
        },
        checkGroupEvent(value) {
            if (parseInt(value) > 1) {
                return "Yes";
            } else {
                return "No";
            }
        },
    },
    watch: {
        daterange: {
            handler: function () {
                let t = this;
                t.getActivityStats();
            },
            deep: true,
        },
    },
    async created() {
        let t = this;
        let partnerResults = await t.MIX_redisSearch("idx:orgs", "@orgAccountTypes:{Partner}", 0, 9999);
        this.partnerOrgs = partnerResults.data.documents.map((x) => {
            return {
                orgName: x.value.orgName,
                orgId: x.id,
                orgApprovedPartner: x.value.orgApprovedPartner,
            };
        });
        t.getActivityStats();
    },
};
</script>
