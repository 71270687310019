<template>
    <v-card :class="actionLead === true ? 'borderClass' : '' " flat outlined>
        <v-col cols="12" class="align-start">
		    <v-row class="pa-2 pb-0">
		    	<v-col cols="10" class="d-flex ma-0 pa-0 pl-1 align-start">
                    <div style="max-width: 250px !important">
                        <!-- {{ org }} -->
                        <div class="font-weight-bold text-start body-2 text-truncate primary--text" style="max-width: 190px !important">
                            {{ org.orgName }} 
                        </div>
                        <div v-if="org.salesDescription" class="font-weight-regular body-2 text-start text-truncate grey--text" style="max-width: 250px !important">
                            {{ org.salesDescription }} 
                        </div>
                    </div>
                </v-col>
		    	<v-col cols="2" class="d-flex ma-0 pa-0 pr-1 justify-end align-center">
                    <div >
                        <v-avatar v-if="org.ownerUserName" color="caption primary white--text font-weight-regular" size="30">{{ org.ownerUserName.match(/\b(\w)/g).join('') }}</v-avatar>
                    </div>
                </v-col>
            </v-row>

		    <v-row class="pa-2 pt-0" :class="reveal ? 'pb-2' : ''">
		    	<v-col cols="6" class="d-flex ma-0 pa-0 pl-1 align-start">
                    <div>
                    </div>
                </v-col>
		    	<v-col cols="6" class="d-flex ma-0 pa-0 pr-3 justify-end align-center">
                    <!-- <div> -->
                        <div v-if="org.salesType" class="font-weight-regular body-2 pr-3 text-start text-uppercase" :class="org.salesType === 'hot' ? 'error--text' : org.salesType === 'warm' ? 'warning--text' : org.salesType === 'cold' ? 'info--text' : 'grey--text'">
                            {{ org.salesType }} 
                        </div>
                        <!-- <v-spacer /> -->
                        <v-icon class="grey--text" size="15" @click="reveal = !reveal">oomph-expand-arrow</v-icon>
                    <!-- </div> -->
                </v-col>
            </v-row>

            <v-card v-if="reveal === true" class="mt-3 elevation-0" style="height: 100% !important; width: 100% !important">
                <v-divider />
                <!-- <v-col class="d-flex justify-end align-end pa-0 pt-6" cols="12">
                    <v-btn @click="openLead" class="caption pr-3 primary--text" text>Account</v-btn>
                    <v-spacer />
                    <v-btn @click="openLeadItem" class="caption pl-3 primary--text" text>Edit</v-btn>
                </v-col> -->
                <div class="d-flex justify-stretch pt-3" style="width: 100% !important;">
                    <div v-if="!actionLead" style="width: 48% !important;"><v-btn block depressed @click="openLead" class="caption font-weight-bold primary--text">Account</v-btn></div>
                    <v-spacer />
                    <div style="width: 48% !important;"><v-btn block depressed @click="openLeadItem" class="caption font-weight-bold primary--text">Edit</v-btn></div>
                </div>
            </v-card>
        </v-col>

    </v-card>
</template>

<script>
export default {
    data: () => ({
        org: {},
        reveal: false,
    }),
	props: {
        datadown: { 
            type: Object, 
            default: () => ({})
        },
        actionLead: {
            type: Boolean,
            default: false,
        }
	},
    computed: {

    },
    methods: {
        openLead() {
            // console.log('openLead');
            this.$emit('openorg', this.org);
        },
        openLeadItem() {
            this.$emit('openorgitem', this.org);
        }
    },
    created() {
        this.org = { ...this.datadown };
        // // console.log("org = " + JSON.stringify(this.org, null, 2));

    },

}
</script>

<style scoped>
.borderClass {
    border-left: 5px solid #019EAF !important;
    border-color: #019EAF !important
}
</style>
