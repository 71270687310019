// Organisation
const organisation = {
    // Main Fields
    id: "",
    orgAccountTypes: "",
    orgName: "",
    orgRegisteredName: "",
    orgRegistrationNumber: "",
    orgLocalAuthority: "",
    orgContactName: "",
	orgTelephone: "",
	orgContactEmail: "",
    orgStatus: "Active",
	orgStageName: "",
    orgStageId: "",
    orgLogo: "",
	// Group Structure
	orgGroupStructure: "",
	orgGroupId: "",
	orgParentGroupId: "",
    // On Demand Partner
    orgApprovedPartner: "0",
    orgAlias: "",
	// Divisions and Regions
	orgDivisions: "",
	orgRegions: "",
	orgDivisionName: "",
	orgRegionName: "",
	orgCQCRating: "",
    // Address Fields
    orgAddressLine1: "",
    orgAddressLine2: "",
    orgAddressLine3: "",
    orgTown: "",
    orgCounty: "",
    orgPostcode: "",
    orgCountry: "",
    orgLatitude: "",
    orgLongitude: "",
    // Organisation Info Related Fields
    orgEmployees: "",
    orgResidents: "",
    orgTurnover: "",
    orgSource: "",
    orgSourceName: "",
    orgAccountNumber: "",
    orgAccountCreditLimit: "",
    orgDecription: "",
    orgLogo: "",
    orgTwitter: "",
    orgFacebook: "",
    orgLinkedIn: "",
    orgInstagram: "",
	orgWebsite: "",
    orgTags: "",
	orgOndemand: "0",
	orgLMS: "0",
	orgResidents: "0",
	orgSubdomain: "",
	orgOnDemandAccess: "0",
	orgLMSAccess: "0",
	orgOnDemandDisableAccess: "0",
	orgResidentsDisableAccess: "0",
	orgOomphCalDisableAccess: "0",
	orgNewsDisableAccess: "0",
	// Standard Fields
    ownerUserId: "",
    ownerUserName: "",
    ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
}
// Address
const address = {
	addressType: '',
	addressName: '',
	addressLine1: '',
	addressLine2: '',
	addressLine3: '',
	addressTown: '',
	addressCounty: '',
	addressPostcode: '',
	addressCountry: '',
	addressLat: '',
	addressLng: '',
	orgId: '',
	orgName: '',
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: 'address'
}
// Action
const action = {
    id: "",
    actionType: "",
    actionSubject: "",
    actionDescription: "",
    actionPriority: "",
    actionDate: "",
    orgTags: "",
    contactTags: "",
    opportunityId: "",
    leadId: "",
    actionCallType: "",
    actionCallDuration: "",
    actionCallPurpose: "",
    actionCallResult: "",
    actionMeetingHost: "",
    actionMeetingParticipants: "",
    actionMeetingType: "",
    actionMeetingLocation: "",
    actionEventHost: "",
    actionEventParticipants: "",
    actionEventType: "",
    actionEventLocation: "",
    actionCaseType: "",

	orgId: "",
	orgName: "",

	contactId: "",
	contactName: "",

    actionCompleted: 0,
    actionCompletedDateTime: '',

    actionStageId: "",
    actionStageName: "",

	// Standard Fields
    ownerUserId: "",
    ownerUserName: "",
    ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
    enabled: "0",
    key: "action"
}
// Contact
const contact = {
    id: "",
    contactTitle: "",
    contactFirstName: "",
    contactLastName: "",
	contactFullName: "",
    contactEmail: "",
    contactAlternateEmail: "",
    contactUnsubscribe: "0",
    contactMobilePhone: "",
    contactDDIPhone: "",
	contactSwitchboard: "",
	contactExtension: "",
    contactJobTitle: "",
	contactJobLevel: "",
    contactJobDepartment: "",
	// Addresses
    contactAddressLine1: "",
    contactAddressLine2: "",
    contactAddressLine3: "",
    contactTown: "",
    contactCounty: "",
    contactPostcode: "",
	contactStatus: "",
    contactSource: "",
    contactSourceName: "",
    orgId: "",
    orgName: "",
    contactReportingTo: "",
    contactTwitter: "",
    contactFacebook: "",
    contactLinkedIn: "",
    contactInstagram: "",
    contactNotes: "",
	contactProfileScore: "0",
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "contact",
}
// Contact
const content = {
    id: "",
    contentTitle: "",
    contentSubtitle: "",
    contentDescription: "",
	contentArea: "",
    contentType: "Simple",
    contentTemplate: "Simple",
    contentDurationMins: "",
    contentDifficulty: "",
    contentAvailableFrom: "0",
    contentAvailableTo: "0",
	contentTags: "",
    contentStatus: "Draft",
    orgId: "",
    orgName: "",
    orgLogo: "",
    contentShowPartnerLogo: "1",
	contentAllowDownload: "0",
    contentAllowPrint: "1",
	contentAllowOffline: "0",
    contentAllowPDFConvert: "0",
	contentViews: "",
	contentLikes: "",
    contentRating: "",
	contentReviews: "",
    contentDownloads: "",
    contentDislikes: "",
    contentOwnerId: "",
    contentPremium: "0",
    availableOnlyOrgId: '',
    availableOnlyOrgName: '',


    // Standard Fields
    ownerUserId: "",
    ownerUserName: "",
    ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	createdUserOrgId: "",
    
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "0",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "content",
}
// Event
const event = {
    id: "",
    eventTitle: "",
    eventType: "",
    eventDescription: "",
    eventStartDateTime: "",
	eventEndDateTime: "",
    eventAllDay: "0",
    eventStatus: "Draft",


    eventLiveStream: "0",
    eventPlaybackId: "",
    eventStreamKey: "",
    eventLiveStreamId: "",

    eventRecurringId: "",

    orgId: "",
    orgName: "",
    eventShowPartnerLogo: "0",
    eventPartnerImageId: "",
	eventLinkGroups: "",
	eventResidentTags: "",
    // Standard Fields
    ownerUserId: "",
    ownerUserName: "",
    ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "event",
}
const recurring = {
    id: "",
    recurringEventTitle: "",
    recurringEventType: "",
    recurringEventDescription: "",
    recurringEventStartDateTime: "",
	recurringEventEndDateTime: "",
    recurringRuleStartDateTime: "",
    recurringRuleEndDateTime: "",
    recurringEventAllDay: "0",
    recurringEventStatus: "Draft",


    recurringEventLiveStream: "0",
    recurringEventPlaybackId: "",
    recurringEventStreamKey: "",
    recurringEventLiveStreamId: "",
    recurringEventTags: "",
    

    recurringEventInterval: "",
    recurringEventFrequency: "",
    recurringEventRecurringText: "",
    recurringEventDays: "",
    recurringEventEnd: "",
    recurringEventCount: "",

    orgId: "",
    orgName: "",
    recurringEventShowPartnerLogo: "0",
    recurringEventPartnerImageId: "",
	recurringEventLinkGroups: "",
	recurringEventResidentTags: "",


    // Standard Fields
    ownerUserId: "",
    ownerUserName: "",
    ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "recurringEvent",
}
// User
const user = {
    id: "",
	userName: "",
	userEmail: "",
    userJobTitle: "",
    userTelephone: "",
    userLevel: "",
    userStatus: "",
    userGroup: "",
	userPin: "",	
	userLoggedInTime: "",
	userChangePassword: "1",
	userChangePin: "0",
    photoURL: "",
    orgId: "",
    orgName: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	imported: "0",
	key: "user",
}

// Content Link
const contentLink = {
    id: "",
    eventId: "",
    contentId: "",
    linkTitle: "",
    linkGroup: "",
    linkShowContentImage: '0',
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "contentLink",
}

const contentItem = {
    id: '',
    itemContentId: '',
    itemAssetId: '',
    itemName: '',
    itemMarker: '',
    itemStatus: '',
    itemType: '',
    itemURL: '',
    itemFileSizeKb: '',
    itemFormat: '',
    itemWidth: '',
    itemHeight: '',
    itemLengthMins: '',
    itemEncodeStatus: '',
    itemTextHTML: '',
    itemUploadId: '',
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "contentItem",
}

const component = {
    id: '',
    componentName: '',
    componentType: '',
	componentCategory: '',
    componentCode: '',
    componentStatus: '',
    componentCost: '',
    componentSell: '',
    componentCustomerDescription: '',
    componentInternalDescription: '',
    componentVersion: '',
    componentBillingCycle: '',
    componentPurchaseSeperate: '0',
    
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "component",
}

const package = {
    id: '',
    packageName: '',
    packageCategory: '',
    packageCode: '',
    packageStatus: '',
    packageCost: '',
    packageSell: '',
	packageManagersDiscount: '',
	packageDirectorsDiscount: '',
    packageBillingCycle: '',
    packageMinTerm: '',
    packageNoticePeriod: '',
    packageCustomerDescription: '',
    packageInternalDescription: '',
    
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "package",
}
// Contract
const contract = {
    id: "",
    contractTitle: "",
	orgId: '',
	orgName: '',
	contactId: '',
	contactName: '',
	contactEmail: '',

	contractDate: "0",
	contractCommencementDate: "0",
	contractExpiryDate: "0",
	contractTerm: "12",
	contractContinuation: "subsequent",
	contractSubsequentTerm: "12",
	contractPaymentCycle: "Annual",
	contractPaymentMethod: "Direct Debit",
	contractOtherTerms: "",
	contractStatus: "Draft",
	contractValue: "",
	
	ownerUserId: "",
	ownerUserName: "",
	ownerUserEmail: "",

	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "contract",
}

const contractItem = {
	id: '',
	contractId: '',
	contractItemOrder: '',
	contractItemName: '',
	contractItemPCType: '', // Package / Component
	contractItemPCId: '',
	contractItemCategory: '',
	contractItemType: '',
    contractItemCode: '',
	contractItemBaseCost: '0',
	contractItemNewBaseCost: '0',
	contractItemCostChanged: '0',
    contractItemCost: '0',
	contractItemBaseSell: '0',
	contractItemNewBaseSell: '0',
    contractItemSell: '0',
	contractItemSellChanged: '0',
	contractPaymentCycle: 'Annual',
    // packageBillingCycle: '',
    // packageMinTerm: '',
    // packageNoticePeriod: '',
    contractItemCustomerDescription: '',
    contractItemInternalDescription: '',
	contractItemDiscount: '',
	contractItemPremises: '',
	contractItemMarginValue: '0',
	contractItemMarginPercent: '0',
	contractItemTotal: '0',
	contractItemOptional: '0',
	contractItemShowDiscount: '0',
	contractItemComment: '',
	contractItemLowMargin: '0',
	orgId: '',
	orgName: '',
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: 'contractItem'
}

const componentItem = {
    id: "",
    componentId: "",
	componentName: "",
    componentCategory: "",
	componentType: "",
	componentCredits: "",
    packageId: "",    
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "componentItem",
}

const licence = {
    id: "",
	licenceName: "",
	licenceStartDate: "0",
	licenceExpiryDate: "0",
	licenceUsed: "0",
	licenceComments: "",
	licenceType: "",
	licenceCategory: "",
	orgId: "",
	orgName: "",
    packageId: "",    
	contractId: "",
	usedDateTime: "0",
	usedUserId: "",
	usedUserName: "",
	usedUserEmail: "",
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "",
	key: "licence",
}


// * SALE
const opportunity = {
    // GENERAL INFO
	id: "",
    
	salesTags: "",
	salesTitle: "",
	salesDescription: "",
	salesPipeline: "",
	salesChannel: "",
	salesType: "warm",
	salesTimePriority: "medium",
	salesSource: "",
	salesSourceName: "",
	salesNotes: "",
	salesOrgTags: "",
	salesImageId: "",
	salesOtherImageIds: "",
	salesStatus: "Draft",
	salesEstimatedCloseDate: "0",
	salesActualCloseDate: "0",
    leadStageName: '',
    leadStageId: '',
    salesPackages: "",
	packageId: "",
    packageName: "",
    salesStageName: '',
    salesStageId: '',
	salesProbability: "0",
	salesValue: "",
	orgId: "",
	orgName: "",
	orgTags: "",
	contactId: "",
	contactName: "",
	contactEmail: "",

	// Standard Fields
    ownerUserId: "",
    ownerUserName: "",
    ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
    key: "opportunity"
}
// * LEAD
const lead = {
    // GENERAL INFO
	id: "",
    
	salesTags: "",
	salesTitle: "",
	salesDescription: "",
	salesPipeline: "",
	salesChannel: "",
	salesType: "warm",
	salesTimePriority: "medium",
	salesSource: "",
	salesSourceName: "",
	salesNotes: "",
	salesOrgTags: "",
	salesImageId: "",
	salesOtherImageIds: "",
	salesStatus: "Draft",
    salesStageName: '',
    salesStageId: '',
	salesProbability: "0",
	salesValue: "",
    packageId: "",
    packageName: "",
	orgId: "",
	orgName: "",
	orgTags: "",
	contactId: "",
	contactName: "",
	contactEmail: "",

	// Standard Fields
    ownerUserId: "",
    ownerUserName: "",
    ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
    key: "lead"
}

// * STAGE
const stage = {
    // GENERAL INFO
	id: "",
	stageName: "",
	stagePipeline: "",
	stageValue: "",
	stageOutcome: "",

	// Standard Fields
    ownerUserId: "",
    ownerUserName: "",
    ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
    
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
    key: "stage"
}

const question = {
	id: "",
	questionParentId: "",
	questionOrder: "",
	questionTitle: "",
	questionImageId: "",
	questionContentItemId: "",
	questionType: "",
	questionCategory: "",
	answers: "",
	answerNotes: "",
	answerAllowABC: "0",
	answerAllowNotes: "0",
	answerAllowImages: "0",
	answerAllowPoints: "0",
	answerAllowImagesText: "0",
	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
	key: "question"
}

// This might not be required
const quiz = {
	id: "",
	quizTitle: "",
	questionTotal: "0",
	// Standard Fields
	ownerUserId: "",
	ownerUserName: "",
	ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
	key: "quiz"
}

const reviewQuestions = {
	id: "",
	reviewQuestionsTitle: "",
	reviewQuestionsTotal: "0",
	reviewQuestionsTemplate: "",
    reviewQuestionsArea: "",

	// Standard Fields
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
	key: "reviewQuestions"
}
// * BRAND
const brand = {
    // GENERAL INFO
	id: "",
	brandName: "",
	accent: "",
	accentAlt: "",
	primary: "",
	secondary: "",

	// Standard Fields
    ownerUserId: "",
    ownerUserName: "",
    ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
    
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
    key: "brand"
}
// * POLL
const poll = {
    // GENERAL INFO
	id: "",
	pollName: "",
	pollQuestion: "",
	status: "",
	pollAnswers: "",

    parentPoll: '1',
    parentAvailableTo: "",
    parentPollId: "",
    
    orgGroupId: "",
    orgId: "",
    orgName: "",
    orgLogo: "",


	// Standard Fields
    ownerUserId: "",
    ownerUserName: "",
    ownerUserEmail: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
    
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
    key: "poll"
}

const releaseNote = {
	id: "",
	releaseDate: "",
	releaseVersion: "",
	releaseNote: "",
	createdDateTime: "",
	createdUserId: "",
	createdUserName: "",
	createdUserEmail: "",
	modifiedDateTime: "",
	modifiedUserId: "",
	modifiedUserName: "",
	modifiedUserEmail: "",
	deleted: "0",
	deletedDateTime: "",
	deletedUserId: "",
	deletedUserName: "",
	deletedUserEmail: "", 
    key: "releaseNote"
}

module.exports = {
    organisation,
    address,
    action,
    contact,
    content,
    event,
    recurring,
    user,
    contentLink,
    contentItem,
    component,
    package,
    componentItem,
    lead,
    opportunity,
    stage,
	question,
	quiz,
	reviewQuestions,
	contract,
	contractItem,
	licence,
    brand,
    poll,
	releaseNote
}