<template>
    <v-container class="pa-0 animate__animated animate__fadeIn appbackground" fluid>
        <v-row class="d-flex pa-0 ma-0 " no-gutters>
            <v-col cols="12" class="pl-5 pt-4">
				<div class="d-flex align-center">
					<div><v-icon class="primary--text" x-large>oomph-chart</v-icon></div>
					<div class="text-left primary--text pl-1 text-h4 font-weight-thin">Reporting
                        <span class="text-h5"  v-if="viewingHome"> - {{ orgName }}</span>
                    </div>
				</div>
                <div v-if="viewingHome" class="d-flex text-left primary--text pt-2 text-h6">
                    <v-btn @click="viewGroup" text class="elevation-0 pl-0 primary--text text-h7">
                        <div><v-icon class="primary--text" medium>oomph-back</v-icon></div>
                        Return to Group
                    </v-btn>
                </div>
                <!-- <div v-if="viewingHome">Viewing Home {{ orgName }}</div> -->
                <!-- <v-btn @click="refresh" large depressed class="primary white--text mr-5">Refresh Page</v-btn> -->

            </v-col>
        </v-row>
        <!-- <pre>{{ GET_FIREBASE_userAccessToken }}</pre> -->
        <!-- <v-card :height="$vuetify.breakpoint.lgAndUp ? '2vh' : '18vh'"></v-card> -->
        <v-row class=" px-5">
            <v-col cols="4" class="d-flex align-start justify-start" dense>
                <v-card class="rounded-lg white" elevation="0" style="width: 100% !important; height: 150px !important">
                    <div class="d-flex align-center justify-start primary pa-2">
                        <div><v-icon class="text-h3 white--text">oomph-chart</v-icon></div>
                        <div class="ml-3 white--text oomph-font-h5 font-weight-bold">Reporting</div>
                    </div>
                    <div class="d-flex mt-5 px-5">
                        <div class="">
                            <v-btn large depressed class="primary white--text" @click="downloadPDF(`${appUrl}/reporting/print?data=${tab}&org=${GET_FIREBASE_userAccessToken.org_id}`)"><v-icon class="mr-2">oomph-export-pdf</v-icon>Download PDF</v-btn>
                        </div>
                        <!-- <v-btn large depressed class="primary white--text"><v-icon class="mr-2">oomph-export-pdf</v-icon>Download PDF</v-btn>
                        <v-spacer />
                        <v-btn large depressed class="primary white--text ml-2"><v-icon class="mr-2">oomph-export-csv</v-icon>Download CSV</v-btn> -->
                    </div>
                </v-card>
            </v-col>
            <v-col cols="8" class="d-flex align-center justify-end pr-0">
                <DashCard v-for="(item, index) in dashCards" :dashvalue="getTotalStats(item)" :key="index" :dashtext="item.text" :dashdescription="item.description" :dashicon="item.icon" />
            </v-col>
        </v-row>
        <v-row class="px-5">
            <v-col cols="3" class="d-flex align-start justify-start">
                <v-card class="rounded-lg" elevation="0" style="width: 100% !important; height: 300px !important">
                    <v-card-title class="oomph-font-body-2 font-weight-bold text-uppercase primary--text d-flex justify-start align-center">
                        <div>Views by Content</div>
                        <v-spacer />
                        <div><v-icon color="primary">oomph-eye</v-icon></div>
                    </v-card-title>
                    <v-card-text class="d-flex align-start justify-start flex-column" style="width: 100% !important">
                        <donut-chart :seriesdata="getAllStatsByType('view')" :color="$vuetify.theme.themes.light.primary" seriestext="Views" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="5" class="d-flex align-start justify-start">
                <v-card class="rounded-lg" elevation="0" style="width: 100% !important; height: 300px !important">
                    <v-card-title class="oomph-font-body-2 font-weight-bold text-uppercase primary--text d-flex justify-start align-center">
                        <div>Downloads by Content</div>
                        <v-spacer />
                        <div><v-icon color="primary">oomph-download</v-icon></div>
                    </v-card-title>
                    <v-card-text class="d-flex align-start justify-start flex-column" style="width: 100% !important">
                        <bar-chart :seriesdata="getAllStatsByType('download')" :color="$vuetify.theme.themes.light.primary" seriestext="Downloads" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4" class="d-flex align-start justify-start">
                <v-card class="rounded-lg" elevation="0" style="width: 100% !important; height: 300px !important">
                    <v-card-title class="oomph-font-body-2 font-weight-bold text-uppercase primary--text d-flex justify-start align-center">
                        <div>Reviews by Content</div>
                        <v-spacer />
                        <div><v-icon color="primary">oomph-star</v-icon></div>
                    </v-card-title>
                    <v-card-text class="d-flex align-start justify-start flex-column" style="width: 100% !important">
                        <pie-chart :seriesdata="getAllStatsByType('review')" :color="$vuetify.theme.themes.light.primary" seriestext="Reviews" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="px-5">
            <v-col cols="12" class="">
                <v-tabs v-model="tab" fixed-tabs background-color="primary" dark class="" style="border-top-left-radius: 10px !important; border-top-right-radius: 10px !important">
                    <v-tab v-if="!viewingHome" href="#groupLevel"> Group Level </v-tab>
                    <v-tab href="#logins"> Logins </v-tab>
                    <v-tab href="#downloads"> Downloads </v-tab>
                    <v-tab href="#reviews"> Feedback / Reviews </v-tab>
                    <!-- <v-tab href="#residents"> Resident Inclusion </v-tab>
                    <v-tab href="#activity"> Activity Schedule </v-tab> -->

                    <v-tabs-items v-model="tab" style="border-bottom-left-radius: 10px !important; border-bottom-right-radius: 10px !important">
                        <v-tab-item value="groupLevel">
                            <v-card flat>
                                <v-card-text class="px-5">
                                    <v-data-table 
                                        :headers="groupLevelHeaders" 
                                        :items="groupLevelData" 
                                        :items-per-page="10" 
                                        @click:row="changeOrg"
                                        dense
                                        class="elevation-0">
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item value="logins">
                            <div class="d-flex justify-start pa-4">
                                <v-btn large depressed class="primary white--text ml-2" @click="downloadCSV('logins')"><v-icon class="mr-2">icons8-export-csv</v-icon>Download CSV</v-btn>
                            </div>
                            <v-card flat>
                                <v-card-text class="px-5">
                                    <v-data-table dense :headers="loginHeaders" :items="loginData" :items-per-page="10" class="elevation-0">
                                        <template v-slot:[`item.userLoggedInTime`]="{ item }">
                                            {{  MIX_formatDateTime(item.userLoggedInTime, 'x', 'DD/MM/YYYY HH:mm') }}
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item value="downloads">
                            <div class="d-flex justify-start pa-4">
                                <v-btn large depressed class="primary white--text ml-2" @click="downloadCSV('downloads')"><v-icon class="mr-2">icons8-export-csv</v-icon>Download CSV</v-btn>
                            </div>
                            <v-card flat>
                                <v-card-text class="px-5">
                                    <v-data-table dense :headers="downloadsHeaders" :items="downloadsData" :items-per-page="10" class="elevation-0">
                                        <template v-slot:[`item.dementiaFriendly`]="{ item }">
                                            {{ item.dementiaFriendly === '1' ? 'Yes' : "No" }}
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item value="reviews">
                            <div class="d-flex justify-start pa-4">
                                <v-btn large depressed class="primary white--text ml-2" @click="downloadCSV('reviews')"><v-icon class="mr-2">icons8-export-csv</v-icon>Download CSV</v-btn>
                            </div>
                            <v-card flat>
                                <v-card-text class="px-5">
                                    <v-data-table dense :headers="reviewsHeaders" :items="reviewsData" :items-per-page="10" class="elevation-0">
                                        <template v-slot:[`item.contentReview.createdDateTime`]="{ item }">
                                            {{  MIX_formatDateTime(item.contentReview.createdDateTime, 'x', 'DD/MM/YYYY HH:ss') }}
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item value="residents">
                            <v-card flat>
                                <v-card-text class="px-5">
                                    <v-data-table dense :headers="residentsHeaders" :items="residentsData" :items-per-page="10" class="elevation-0"></v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item value="activity">
                            <v-card flat>
                                <v-card-text class="px-5">
                                    <v-data-table dense :headers="activityHeaders" :items="activityData" :items-per-page="10" class="elevation-0"></v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
                <br /><br />
            </v-col>
        </v-row>        
        <!-- getDashValue(item) -->
        <!-- <v-row>
            <v-col>
                View = <pre>{{ getAllStatsByType('View') }}</pre>
            </v-col>            <v-col>
                Review = <pre>{{ getAllStatsByType('Review') }}</pre>
            </v-col>
        </v-row> -->
    </v-container>
</template>

<script>
import PieChart from "@/components/echarts/PieChart.vue";
import BarChart from "@/components/echarts/BarChart.vue";
import DonutChart from "@/components/echarts/DonutChart.vue";

const DashCard = {
    props: ["dashvalue", "dashtext", "dashdescription", "dashicon"],
    methods: {
        theFormat(number) {
            return number.toFixed(0);
        },
    },
    template: `<v-card elevation="0" class="rounded-lg d-flex flex-column mr-3 primary" height="150" width="570">
                    <v-card-title class="body-1 font-weight-bold text-uppercase white--text d-flex justify-start align-center">
                        <div>{{ dashtext }}S</div>
                        <v-spacer />
                        <div><v-icon color="secondary">{{ dashicon }}</v-icon></div>
                    </v-card-title>
                    <v-card-text class="d-flex align-start justify-start flex-column">
                        <div class="text-h3 white--text font-weight-bold" style="height: 40px !important;">
                            <number :from="0" :to="dashvalue" :format="theFormat" :duration="1" :delay="0" easing="Power1.easeOut" />
                            </div>
                        <div class="body-1 pt-2 white--text">{{ dashdescription }}</div>

                    </v-card-text>
                </v-card>`,
};
//{{ Intl.NumberFormat('en-GB').format(dashvalue) }}
import { mapGetters } from "vuex";
export default {
    name: "Reporting",
    components: {
        BarChart: BarChart,
        PieChart: PieChart,
        DonutChart: DonutChart,
        DashCard: DashCard,
    },

    data: () => ({
        appUrl: process.env.VUE_APP_OOMPHSERVER,
        tab: "groupLevel",
        dashCards: [
            { text: "login", description: "Total Logins", type: "loginStats", icon: "oomph-landlord" },
            { text: "view", description: "Total Views", type: "contentStats", icon: "oomph-eye" },
            { text: "download", description: "Total Downloads", type: "contentStats", icon: "oomph-download" },
            { text: "review", description: "Total Reviews", type: "contentStats", icon: "oomph-star" },
        ],
        logins: {
            value: 330,
        },
        orgId: '',
        orgName: '',
        viewingHome: false,

        loginCount: '0',
        loginData: [],
        logins: [],
        loginCount: '0',
        seriesdata: [],

        contentStats: [
            {
                contentAlias: "Activity Book",
                statType: "View",
                total: "954",
            },
            {
                contentAlias: "Activity Book",
                statType: "Dislikes",
                total: "956",
            },
            {
                contentAlias: "Activity Book",
                statType: "Download",
                total: "954",
            },
            {
                contentAlias: "Activity Book",
                statType: "Review",
                total: "985",
            },
            {
                contentAlias: "Craft",
                statType: "Review",
                total: "512",
            },
            {
                contentAlias: "Craft",
                statType: "Dislikes",
                total: "457",
            },
            {
                contentAlias: "Craft",
                statType: "Like",
                total: "451",
            },
            {
                contentAlias: "Craft",
                statType: "Download",
                total: "523",
            },
            {
                contentAlias: "Craft",
                statType: "View",
                total: "472",
            },
            {
                contentAlias: "Exercise",
                statType: "View",
                total: "890",
            },
            {
                contentAlias: "Exercise",
                statType: "Like",
                total: "932",
            },
            {
                contentAlias: "Exercise",
                statType: "Review",
                total: "915",
            },
            {
                contentAlias: "Exercise",
                statType: "Download",
                total: "912",
            },
            {
                contentAlias: "Exercise",
                statType: "Dislikes",
                total: "920",
            },
            {
                contentAlias: "Flashcards",
                statType: "View",
                total: "471",
            },
            {
                contentAlias: "Flashcards",
                statType: "Review",
                total: "475",
            },
            {
                contentAlias: "Flashcards",
                statType: "Download",
                total: "428",
            },
            {
                contentAlias: "Flashcards",
                statType: "Dislikes",
                total: "487",
            },
            {
                contentAlias: "Flashcards",
                statType: "Like",
                total: "470",
            },
            {
                contentAlias: "News",
                statType: "Like",
                total: "1834",
            },
            {
                contentAlias: "News",
                statType: "Review",
                total: "1810",
            },
            {
                contentAlias: "News",
                statType: "Dislikes",
                total: "1828",
            },
            {
                contentAlias: "News",
                statType: "Download",
                total: "1961",
            },
            {
                contentAlias: "News",
                statType: "View",
                total: "1849",
            },
        ],

        seriesdata: [],
        loginHeaders: [
            { text: "Name", value: "userName", align: 'start' },
            { text: "Role", value: "userJobTitle", align: 'start' },
            { text: "Organisation", value: "orgName", align: 'start' },
            { text: "Last Login", value: "userLoggedInTime", align: 'start' },
            { text: "Total Logins", value: "total", align: 'start' },
        ],
        loginData: [],
        groupLevelHeaders: [
            { text: "Location", value: "location", align: 'start' },
            { text: "Total Logins", value: "totalLogins", align: 'start' },
            { text: "Date of Last Login", value: "lastLoginDate", align: 'start' },
            { text: "Time of Last Login", value: "lastLoginTime", align: 'start' },
            { text: "Number of Views", value: "totalViews", align: 'start' },
            { text: "Number of Downloads", value: "totalDownloads", align: 'start' },
            { text: "Total Reviews", value: "totalReviews", align: 'start' },
            { text: "Avg Rating", value: "avgRating", align: 'start' },
            { text: "Total Residents", value: "totalResidents", align: 'start' },
        ],
        groupLevelData: [],

        downloadsHeaders: [
            // { text: "Content Type", value: "contentType", align: 'start' },
            // { text: "Content Area", value: "contentArea", align: 'start' },
            { text: "Content Title", value: "contentTitle", align: 'start' },
            { text: "Dementia Friendly", value: "dementiaFriendly", align: 'start' },
            { text: "Number of Downloads", value: "downloadTotal", align: 'start' },
            { text: "Number of Views", value: "viewsTotal", align: 'start' },
            { text: "Feedback Score", value: "feedbackScore", align: 'start' },
        ],
        downloadsData: [],

        reviewsHeaders: [
            // { text: "Content Type", value: "contentType", align: 'start' },
            // { text: "Content Area", value: "contentArea", align: 'start' },

            { text: 'Content Alias', value: 'contentAlias', align: 'start' },
            { text: "Content Title", value: "contentTitle", align: 'start' },
            { text: 'Date/Time of Session', value: 'contentReview.createdDateTime', align: 'start' },
            { text: "Number of Participants", value: "contentReview.reviewQ3A", align: 'start' },

            // { text: "Organisation", value: "organisation", align: 'start' },
            // { text: "Location", value: "location", align: 'start' },
            
            { text: 'Name of Person Providing Feedback', value: 'contentReview.createdUserName', align: 'start' },
            { text: "Role", value: "contentReview.userJobTitle", align: 'start' },
            { text: "Feedback Given", value: "contentReview.reviewComment", align: 'start' },
            { text: "Rating Provided", value: "contentReview.reviewQ4A", align: 'start' },
        ],
        reviewsData: [],

        residentsHeaders: [
            { text: "Resident Name", value: "resident", align: 'start' },
            { text: "Activity Attended", value: "activity", align: 'start' },
            { text: "Date of Attendance", value: "eventDate", align: 'start' },
            { text: "Time of Event", value: "eventStartTime", align: 'start' },
            { text: "Duration of Event", value: "eventDuration", align: 'start' },
            { text: "Mood Rating", value: "moodRating", align: 'start' },
            { text: "Engaged", value: "engaged", align: 'start' },
            { text: "Group Event", value: "groupEvent", align: 'start' },
        ],
        residentsData: [],

        activityHeaders: [
            { text: "Date of Event", value: "eventDate", align: 'start' },
            { text: "Time of Event", value: "eventTime", align: 'start' },
            { text: "Name of Event", value: "eventName", align: 'start' },
            { text: "Source of Event", value: "eventSource", align: 'start' },
            { text: "Attendees", value: "eventAttendees", align: 'start' },
            { text: "Rating", value: "eventRating", align: 'start' },
        ],
        activityData: [],

    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
            GET_brand: "GET_brand",
        }),
        computedViews() {
            return this.contentStats.filter((item) => item.statType === "View");
        },
    },
    methods: {
        getTotalStats(content) {
            let total = 0;
            switch (content.type) {
                case "loginStats":
                    total = this.loginCount;
                    break;
                case "contentStats":
                    // console.log('content.type = ', JSON.stringify(content, null,2 ))
                    this.contentStats.forEach((item) => {
                        if (item.statType === content.text) {
                            total += parseInt(item.total);
                        }
                    });
                    break;
            }
            return total;
        },
        // getStatsByContent(content, type) {
        //     let total = 0;
        //     this.contentStats.forEach((item) => {
        //         if (item.statType === type && item.contentAlias === content) {
        //             total += parseInt(item.total);
        //         }
        //     });
        //     return total;
        // },
        getAllStatsByType(type) {
            // loop through contentStats and return an object with stats by type
            let stats = [];
            this.contentStats.forEach((item) => {
                if (item.statType === type) {
                    stats.push({
                        value: parseInt(item.total),
                        name: item.contentAlias,
                    });
                }
            });
            return stats;
        },
        async getLoginCount() {
            let t = this;
            let result = await t.MIX_loginCount({ searchQuery: `@createdUserOrgId:{${t.orgId}}` });
            if (result.code === 1) {
                console.log("result = " + JSON.stringify(result, null, 2))
                t.logins = result.data;
                if (result.data[0]) {
                    t.loginCount = result.data[0].total;
                } else {
                    t.loginCount = 0
                }
            }
        },
        async getStats() {
            let t = this;
            let result = await t.MIX_count({ searchQuery: `@createdUserOrgId:{${t.orgId}}` });
            if (result.code === 1) {
                t.contentStats = result.data;
                
            }
        },
        async getLoginUsers() {
            let t = this;
            let result = await t.MIX_loginUsers({ orgId: `@orgId:{${t.orgId}}`, createdUserOrgId: `@createdUserOrgId:{${t.orgId}}` });
            if (result.code === 1) {
                t.loginData = result.data;
            }
        },
        async getDownloadStats() {
            let t = this;
            let result = await t.MIX_downloads({ searchQuery: `@createdUserOrgId:{${t.orgId}}` });
            if (result.code === 1) {
                t.downloadsData = result.data;
            }
        },
        async getReviewStats() {
            let t = this;
            let result = await t.MIX_reviews({ searchQuery: `@createdUserOrgId:{${t.orgId}}` });
            if (result.code === 1) {
                t.reviewsData = result.data;
            }
        },
        async getGroupLevelData() {
            let t = this;

            var orgId = this.GET_FIREBASE_userAccessToken.org_id


            let getOrganisations = await t.MIX_redisSearch('idx:orgs', `@orgGroupStructure:{Home} @orgGroupId:{${orgId}}`, 0, 9999)
            if (getOrganisations.code === 1) {
                var organisations = [];
                organisations = getOrganisations.data.documents.map((x) => x.value);
                for (let i=0; i < organisations.length; i++) {
                    let org = organisations[i]
                    let contentStats = []
                    org.login = 0
                    org.view = 0 
                    org.download = 0
                    org.review = 0
                    org.totalResidents = 0

                    let result = await t.MIX_count({ searchQuery: `@createdUserOrgId:{${org.id}}` });
                    if (result.code === 1) {
                        contentStats = result.data;
                        
                        contentStats.forEach((item) => {
                            if (item.statType === 'login') {
                                org.login += parseInt(item.total);
                            }
                            if (item.statType === 'view') {
                                org.view += parseInt(item.total);
                            }
                            if (item.statType === 'download') {
                                org.download += parseInt(item.total);
                            }
                            if (item.statType === 'review') {
                                org.review += parseInt(item.total);
                            }
                        });
                    }

                    let loginresult = await t.MIX_loginCount({ searchQuery: `@createdUserOrgId:{${org.id}}` });
                    if (loginresult.code === 1) {
                        console.log("result = " + JSON.stringify(loginresult, null, 2))
                        if (loginresult.data[0]) {
                            org.login = loginresult.data[0].total;
                        }
                    }


                    const searchResult = await this.MIX_redisOrderedSearch("idx:logins", `@createdUserOrgId:{${org.id}}`, 0, 1, "createdDateTime");
                    var lastLogin = searchResult.data.documents.map((x) => x.value);
                    console.log('lastLogin = ' + JSON.stringify(lastLogin, null, 2))

                    if (lastLogin[0]) {
                        org.lastLoginDate = this.$moment(lastLogin[0].createdDateTime, 'X').format('DD/MM/YYYY')
                        org.lastLoginTime = this.$moment(lastLogin[0].createdDateTime, 'X').format('HH:mm')
                    } else {
                        org.lastLoginDate = '-'
                        org.lastLoginTime = '-'
                    }


                    var groupBy = '@userGroup';
                    let itemsResult = await this.MIX_redisAgreggateCount('idx:users', `@deleted:0 @orgName:${org.orgName}`, groupBy)
                    if (itemsResult.code === 1) {
                        console.log('count results = ' + JSON.stringify(itemsResult, null, 2))
                        // itemsResult = itemsResult.data.documents.map((x) => x.value);
                        console.log('count results = ' + JSON.stringify(itemsResult, null, 2))
                        // if (itemsResult) {
                            itemsResult.data.documents.forEach((item) => {
                                if (item.contentStatus == 'Residents') {
                                    org.totalResidents = item.count
                                }
                            })
                        // }
                    }




                }

                organisations = organisations.map(e => {
                    return {
                        id: e.id,
                        location: e.orgName,
                        lastLoginDate: e.lastLoginDate,
                        lastLoginTime: e.lastLoginTime,
                        totalLogins: e.login,
                        totalViews: e.view,
                        totalDownloads: e.download,
                        totalReviews: e.review,
                        totalResidents: e.totalResidents,
                    }
                })

                this.groupLevelData = organisations
            }
        },
        viewGroup() {
            var t = this;

            t.orgId = t.GET_FIREBASE_userAccessToken.org_id
            t.orgName = '',

            t.viewingHome = false,
            t.tab = 'groupLevel'

            t.refresh()
        },
        changeOrg(item) {
            // this.orgId
            console.log('org = ' + JSON.stringify(item, null, 2))
            this.orgId = item.id 
            this.orgName = item.location
            this.tab = 'logins'
            this.refresh()
            this.viewingHome = true
        },
        refresh() {
            this.getLoginCount();
            this.getStats();
            this.getLoginUsers();
            this.getDownloadStats();
            this.getReviewStats();
            this.getGroupLevelData()
        },

        async downloadPDF(url) {
            let t = this;
            t.loading = true;
            return await t.MIX_generatePDF(url).then((response) => {
                response
                    .arrayBuffer()
                    .then((res) => {
                        const blob = new Blob([res], { type: 'application/pdf' });
                        t.$saveAs(blob, t.tab + '.pdf');
                        t.loading = false;
                    })
                    .catch((e) => alert(e));
            });
        },

        downloadCSV(data) {
            let t = this;
            switch (true) {
                case data === 'logins':
                    var loginCSV = t.$papa.unparse(t.loginData);
                    var loginCSVData = new Blob([loginCSV], {type: 'text/csv;charset=utf-8;'});
                    var loginCSVURL = window.URL.createObjectURL(loginCSVData);
                    var loginDownload = document.createElement('a');
                    loginDownload.href = loginCSVURL;
                    loginDownload.setAttribute('logins', 'logins.csv');
                    loginDownload.click();
                    break;
                case data === 'downloads':
                    var downloadCSV = t.$papa.unparse(t.downloadsData);
                    var downloadCSVData = new Blob([downloadCSV], {type: 'text/csv;charset=utf-8;'});
                    var downloadCSVURL = window.URL.createObjectURL(downloadCSVData);
                    var downloadFile = document.createElement('a');
                    downloadFile.href = downloadCSVURL;
                    downloadFile.setAttribute('downloads', 'downloads.csv');
                    downloadFile.click();
                    break;
                case data === 'reviews':
                    var reviewCSV = t.$papa.unparse(t.reviewsData);
                    var reviewCSVData = new Blob([reviewCSV], {type: 'text/csv;charset=utf-8;'});
                    var reviewCSVURL = window.URL.createObjectURL(reviewCSVData);
                    var reviewDownload = document.createElement('a');
                    reviewDownload.href = reviewCSVURL;
                    reviewDownload.setAttribute('reviews', 'reviews.csv');
                    reviewDownload.click();
                    break;
            }
        }
    },
    async created() {
        var t = this
        t.orgId = t.GET_FIREBASE_userAccessToken.org_id
        t.getLoginCount();
        t.getStats();
        t.getLoginUsers();
        t.getDownloadStats();
        t.getReviewStats();
        t.getGroupLevelData()
    },
};
</script>

<style scoped>
.chart {
    height: 100vh;
}
</style>

<style>
body {
    margin: 0;
}
</style>
