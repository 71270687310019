<template>
    <v-menu offset-y left origin="top right" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
            <v-btn depressed plain class="primary--text px-0" v-bind="attrs" v-on="on">
                <v-icon class="ml-5" size="28" color="white">oomph-identification-documents</v-icon>
            </v-btn>
        </template>
        <v-card>
                        <!-- <pre>{{ GET_FIREBASE_userAuth }}</pre> -->
            <v-list>
                <v-list-item>
                    <v-list-item-avatar v-if="GET_FIREBASE_userAuth.displayName">
                        <!-- <img :src="GET_FIREBASE_userAuth.photoURL" /> -->
                        <v-list-item-avatar>
                            {{ GET_FIREBASE_userAuth.displayName.match(/\b(\w)/g).join('') }}
                        </v-list-item-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6 primary--text font-weight-bold">{{ GET_FIREBASE_userAuth ? GET_FIREBASE_userAuth.displayName : '' }}</v-list-item-title>
                        <v-list-item-subtitle class="grey--text">{{ GET_FIREBASE_userAuth ? GET_FIREBASE_userAuth.email : '' }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <!-- <v-divider></v-divider>
            <v-list style="cursor: pointer !important;">
                <v-list-item>
                    <v-list-item-title class="grey--text"><v-icon size="32" class="mr-4 primary--text">oomph-verified-account</v-icon>Profile</v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title class="grey--text"><v-icon size="32" class="mr-4 primary--text">oomph-phone</v-icon>App Support</v-list-item-title>
                </v-list-item>
            </v-list> -->
            <v-divider></v-divider>
            <v-list style="cursor: pointer !important;">    
<v-list-item class="caption grey--text text--darken-2" dense>
                            <div class="d-flex flex-column align-start justify-start">
                            <div class="primary--text text-subtitle-1">User Info</div>
                            <table width="100%">
                                <tr><td><strong>User: </strong></td><td>{{ GET_FIREBASE_userAuth.displayName }}</td></tr>
                                <tr><td><strong>Email:</strong></td><td>{{ GET_FIREBASE_userAuth.email }}</td></tr>
                                <tr><td><strong>Level:</strong></td><td>{{ userLevel }}</td></tr>
                                <tr><td><strong>Group:</strong></td><td>{{ userGroup }}</td></tr>
                                <tr><td><strong>Status:</strong></td><td>{{ userStatus }}</td></tr>
                            </table>
                            </div>
                        </v-list-item>
                    <v-list-item>
                    <v-list-item-title @click="logout()" class="grey--text"><v-icon size="32" class="mr-4 primary--text">oomph-logout</v-icon>Logout</v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title class="grey--text">v{{ appVersion }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { version as appVersion } from '../../../package.json';
export default {
    data: () => ({
        appVersion: appVersion,
    }),
    computed: {
        ...mapGetters({
			GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        userLevel() { return this.GET_FIREBASE_userAccessToken.user_level },
        userGroup() { return this.GET_FIREBASE_userAccessToken.user_group },
        userStatus() { return this.GET_FIREBASE_userAccessToken.user_status },
    },
    methods: {
        ...mapActions({
            ACT_FIREBASE_userAuth: 'firebase_auth_store/ACT_FIREBASE_userAuth',
        }),
        logout() {
            let t = this;
            t.ACT_FIREBASE_userAuth(null);
            t.$firebase.auth.signOut().then(() => {
                t.$router.push('/').catch((err) => {
                    console.log('Route error: ' + err);
                });
            });
        },
    },
};
</script>