<template>
    <div>
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field 
                    label="Search Recurring Events" 
                    v-model="searchQuery" 
                    outlined 
                    dense 
                    hide-details="auto" 
                    background-color="white" 
                    color="primary" 
                    clearable 
                    prepend-inner-icon="oomph-search" 
                    @click:clear="clearSearchQuery()">
                </v-text-field>
            </div>
            <!-- Filter Organisations -->
            <!-- <div v-if="!listshrunk && orgid === '' && listexpanded" class="ml-2">
                <div><v-autocomplete outlined auto-select-first v-model="filter.orgId" label="Filter by Organisation" :items="organisations" item-text="orgName" item-value="id" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('orgId', '')"></v-autocomplete></div>
            </div> -->
			<!-- Refresh Button -->
            <div v-if="!listshrunk" class="ml-2">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="refreshItems">
                    <v-icon>oomph-available-updates</v-icon>
                </v-btn>
            </div>
            <!-- Column Selector -->
            <div class="ml-2">
                <v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>oomph-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>

        <div class="mt-2">
            <!-- TABLE BASED LIST -->
            <v-data-table v-if="!listshrunk" sortable dense class="" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions"
                :loading="loading" loading-text="Loading People" >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <!-- TODO - HIGH - SECURITY - Hide/Show Buttons -->
                        <!-- 
							Show Delete / Restore Button for Staff-Directors / StaffManagers / StaffAdmin / DeveloperAdmin
							Show Destroy Option Button DeveloperAdmin Only
						-->
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="Permanently Delete" value="destroy" small>
                                <v-icon class="error--text">oomph-delete-trash</v-icon>
                            </v-btn>
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                            <v-btn title="Undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                
                <template v-slot:item.recurringRuleStartDateTime="{ item }">
                    <div>{{ $moment(item.recurringRuleStartDateTime, 'X').format('DD/MM/YYYY') }}</div>
                </template>
                <template v-slot:item.recurringRuleEndDateTime="{ item }">
                    <div>{{ $moment(item.recurringRuleEndDateTime, 'X').format('DD/MM/YYYY') }}</div>
                </template>

                <template v-slot:item.action="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">oomph-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>
                </template>
            </v-data-table>
            <!-- CARD BASED LIST -->
            <div v-if="listshrunk" style="height: 67vh" class="">
                <v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
                    <div class="d-flex align-center ml-1">
                        <div class="ml-3">
                            <div class="body-2 font-weight-bold" style="width: 180px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.orgName }}</div>
                            <!-- <span class="body-2">{{ item.productCode }}</span
							><br />
							<span class="body-2">{{ item.supplierName }}</span
							><br /> -->
                        </div>
                        <v-spacer></v-spacer>
                        <div class="text-right mr-3">
                            <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>
                        </div>
                    </div>
                </v-card>
            </div>
            <!-- LOAD MORE CARDS -->
            <v-btn v-if="listshrunk" class="mt-2 grey lighten-3" depressed block>Load More</v-btn>
        </div>
        <!-- <pre>{{ filter }}</pre> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "ContactList",
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
        orgType: {
            type: String,
            default: '',
        },
        orgid: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        key: 'recurringEvent',
        index: 'idx:recurringEvents',
        toggleDeletedItems: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', value: 'id', sortable: false, hidden: true, expanded: true },
            { text: 'Start Date', value: 'recurringRuleStartDateTime', sortable: false, hidden: false, expanded: true },
            { text: 'End Date', value: 'recurringRuleEndDateTime', sortable: false, hidden: false, expanded: false },
            { text: 'Title', value: 'recurringEventTitle', sortable: false, hidden: false, expanded: false },
            { text: 'Status', value: 'recurringEventStatus', sortable: false, hidden: false, expanded: false },
            { text: 'Organisation Name', value: 'orgName', sortable: false, hidden: false, expanded: false },
            { text: 'Type', value: 'recurringEventType', sortable: false, hidden: false, expanded: true },
            { text: 'Description', value: 'recurringEventDescription', sortable: false, hidden: true, expanded: true },


            { text: 'Date Time (C)', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'User ID (C)', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'User Name (C)', value: 'createdUserName', sortable: false, hidden: true, expanded: true },
            { text: 'User Email (C)', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Date Time (M)', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'User ID (M)', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'User Name (M)', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'User Email (M)', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Date Time (D)', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'User ID (D)', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'User Name (D)', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'User Email (D)', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Action', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false, width: '155px' },
        ],
        loading: true,
        // Fields to Retrieve from Database
        //fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@recurringEventStartDateTime',
        datatableOptions: {
            page: 1,
            itemsPerPage: 15,
            sortBy: ['recurringEventStartDateTime'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        // productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
        // Dropdowns
        jobLevels: [],
        jobDepartments: [],
        organisations: [],
        tags: [],
    }),
    computed: {
			...mapGetters({
				GET_taskBar: "GET_taskBar",
				GET_dense: "GET_dense",
				GET_destroy: "GET_destroy",
			}),
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:0';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }

            console.log(searchQuery);

            // Page Filters
            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expanded !== true); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedItems() {
            var items = this.items; //.filter(x => x.);
            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@orgId')) {
                computedFields.push('@orgId');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            //if (!computedFields.includes("@attSupplier")) { computedFields.push ("@attSupplier") }
            computedFields = computedFields.join(',');
            return computedFields;
        },
    },
    methods: {
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        // Refresh Items
        refreshItems() {
            this.getItems();
        },
        // * GET ITEMS
        async getItems() {
            this.loading = true
            // if (this.searchQuery.length !== 1) {
                console.log('this.index=' + this.index)
                console.log('this.computedSearchQuery=' + this.computedSearchQuery)
                console.log('this.itemsFrom=' + this.itemsFrom)
                console.log('this.datatableOptions.itemsPerPage=' + this.datatableOptions.itemsPerPage)
                console.log('this.computedFields=' + this.computedFields)
                console.log('this.sortBy=' + this.sortBy)
            
                let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
                console.log('itemsResult=' + JSON.stringify(itemsResult, null, 2))
                if (itemsResult.code === 1) {
                    this.itemsTotal = itemsResult.data.total;
                    this.items = itemsResult.data.results;
                    let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                    if (itemsTo < this.itemsTotal) {
                        this.itemsTo = itemsTo;
                    } else {
                        this.itemsTo = this.itemsTotal;
                    }
                }
            // }
            this.loading = false;
        },
		// * Clear Tag Filter
		clearFilter(key, value) {
			setTimeout(() => {
				this.filter[key] = value;
			}, 1);
		},		
    },
    watch: {
        filter: {
            handler: function () {
                this.getItems();
            },
            deep: true,
        },
        refreshitems: function () {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                //// console.log('organisationFrom=' + this.organisationFrom)
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        let t = this;
        t.loading = true
        this.getItems();
    },
};
</script>
