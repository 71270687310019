<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode" @click="saveItem()"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <v-col v-if="!loading" cols="12" class="d-flex pt-4 align-center">
                <v-form ref="form" lazy-validation style="width: 100% !important" class="pa-o ma-0">
                    <!-- ORGANISATON DETAILS -->
                    <v-expansion-panels v-model="panelFixed" accordion flat class="pa-0 ma-0">
                        <v-expansion-panel class="grey lighten-4">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Contract Details</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-1" class="">
                                <v-row dense class="mb-3">
                                    <!-- Contract Title -->
                                    <v-col cols="12" xs="12" md="12">
                                        <v-text-field label="Contract Title" v-model="contract.contractTitle" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contractTitle')"></v-text-field>
                                    </v-col>
                                    <!-- Customer -->
                                    <v-col cols="12" xs="12" md="6">
                                        <v-autocomplete @change="filterOrgContacts(); updateOrganisation();" label="Customer" v-model="selectedOrg" :items="customers" auto-select-first item-text="orgName" return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOrg()" />
                                        <!-- <br />{{ selectedOrg }} -->
                                    </v-col>
                                    <!-- Contact Name -->
                                    <v-col cols="12" xs="6" md="6">
                                        <v-autocomplete @change="updateContact();" :disabled="JSON.stringify(selectedOrg) === '{}' || selectedOrg == null || selectedOrg.id === ''" auto-select-first label="Select Contact" v-model="selectedContact" :items="orgContacts" item-text="contactName" return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedContact()"> </v-autocomplete>
                                        <!-- <v-btn  :disabled="JSON.stringify(selectedOrg) === '{}' || selectedOrg == null || selectedOrg.id === ''" class="grey lighten-2 caption ml-2 font-weight-bold primary--text elevation-0" @click="showAddOrgContactDialog('contact')">Add +</v-btn> -->
                                        <!-- <br />{{ selectedContact }} -->
                                    </v-col>
                                    <!-- Contract Date -->
                                    <v-col cols="12" xs="12" md="6">
                                        <v-menu v-model="contractDateMenu" :close-on-content-click="false" transition="scale-transition" bottom offset-y max-width="290px" min-width="auto" :attach="true">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field label="Contract Date" v-model="computedContractDate" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearDataVariable('contractDate', '')"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="contractDate" full-width no-title color="secondary" :first-day-of-week="1" @input="contractDateMenu = false"></v-date-picker>
                                        </v-menu>
                                        <!-- {{ contractDate }} - {{ contract.contractDate }} - {{ MIX_formatDateTime(contract.contractDate, "X", "YYYY-MM-DD") }} -->
                                    </v-col>
                                    <!-- Commencement Date -->
                                    <v-col cols="12" xs="12" md="6">
                                        <v-menu v-model="commencementDateMenu" :close-on-content-click="false" transition="scale-transition" bottom offset-y max-width="290px" min-width="auto" :attach="true">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field label="Commencement Date" v-model="computedCommencementDate" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearDataVariable('commencementDate', '')"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="commencementDate" full-width no-title color="secondary" :first-day-of-week="1" @input="commencementDateMenu = false"></v-date-picker>
                                        </v-menu>
                                        <!-- {{ commencementDate }} - {{ contract.contractCommencementDate }} - {{ MIX_formatDateTime(contract.contractCommencementDate, "X", "YYYY-MM-DD") }} -->
                                    </v-col>
                                    <!-- Contract Length -->
                                    <v-col cols="12" xs="12" md="12">
                                        <div class="d-flex align-center">
                                            <div>Length of Term:<br /><span class="caption">Months from the Commencement Date</span></div>
                                            <div class="ml-5">
                                                <v-radio-group v-model="selectedContractTerm" row hide-details="auto" class="ma-0 pa-0 mt-1" @change="updateContractTerm()">
                                                    <v-radio label="12" value="12"></v-radio>
                                                    <v-radio label="24" value="24"></v-radio>
                                                    <v-radio label="36" value="36"></v-radio>
                                                    <v-radio label="Other" value="Other"></v-radio>
                                                </v-radio-group>
                                            </div>
                                            <div style="width: 120px !important"><v-text-field :disabled="selectedContractTerm !== 'Other'" label="Other" @keyup="updateContractTerm()" v-model="otherContractTerm" outlined dense hide-details="auto" background-color="white" color="primary"></v-text-field></div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Renewal / Termination -->
                        <v-expansion-panel class="grey lighten-4">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Renewal / Termination</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="expansion-panel-content-2" class="">
                                <v-row dense class="">
                                    <!-- Continuation -->
                                    <v-col cols="12" xs="12" md="12">
                                        <v-select label="Renewal / Termination" v-model="contract.contractContinuation" :items="continuation" auto-select-first outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearDataVariable('selectedContinuation', '')" />
                                        <div v-if="selectedContinuation === 'subsequent'">
                                            <div class="d-flex align-center py-4">
                                                <div>Length of Term:<br /><span class="caption">Months from the Expiry of Intial Term</span></div>
                                                <div class="ml-5">
                                                    <v-radio-group v-model="selectedSubsequentTerm" row hide-details="auto" class="ma-0 pa-0 mt-1" @change="updateSubsequentTerm()">
                                                        <v-radio label="12" value="12"></v-radio>
                                                        <v-radio label="24" value="24"></v-radio>
                                                        <v-radio label="36" value="36"></v-radio>
                                                        <v-radio label="Other" value="Other"></v-radio>
                                                    </v-radio-group>
                                                </div>
                                                <div style="width: 120px !important"><v-text-field :disabled="selectedSubsequentTerm !== 'Other'" label="Enter Term" type="number" @keyup="updateSubsequentTerm()" v-model="otherSubsequentTerm" outlined dense hide-details="auto" background-color="white" color="primary"></v-text-field></div>
                                            </div>
                                            <div class="body-2 text--secondary"><strong>Info: </strong></div>
                                        </div>
                                        <div v-if="selectedContinuation === 'extended'" class="my-3">
                                            <div class="body-2 text--secondary"><strong>Info: </strong>The Contract will be extended and continue following the Initial Term until either party gives not less than three months’ notice of termination in writing to the other party (subject to earlier termination in accordance with the Oomph Customer Standard Terms and Conditions).</div>
                                        </div>
                                        <div v-if="selectedContinuation === 'none'" class="my-3">
                                            <div class="body-2 text--secondary"><strong>Info: </strong>The Contract will not automatically extend.</div>
                                        </div>                                
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Payment Cycle / Method -->
                        <v-expansion-panel class="grey lighten-4">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Payment Terms</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row dense class="">
                                    <!-- Payment Cycle -->
                                    <v-col cols="12" xs="12" md="6">
                                        <v-select label="Payment Cycle" v-model="contract.contractPaymentCycle" :items="paymentCycles" auto-select-first outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearDataVariable('selectedPaymentTerms', '')" :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-select>
                                    </v-col>
                                    <!-- Payment Method -->
                                    <v-col cols="12" xs="12" md="6">
                                        <v-select label="Payment Cycle" v-model="contract.contractPaymentMethod" :items="paymentMethods" auto-select-first outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearDataVariable('selectedPaymentTerms', '')" :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-select>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Other Contract Terms -->
                        <v-expansion-panel class="grey lighten-4">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Other Contract Terms</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row dense class="">
                                    <!-- Payment Cycle -->
                                    <v-col cols="12" xs="12" md="12">
                                        <v-textarea label="Other Contract Terms" v-model="contract.contractOtherTerms" rows="3" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contractPaymentTerms')"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Contract Owner -->
                        <v-expansion-panel class="grey lighten-4">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Owner / Sales Person</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row dense class="">
                                    <!-- Payment Cycle -->
                                    <v-col cols="12" xs="12" md="12">
                                        <v-autocomplete label="Owner *" v-model="selectedOwner" @change="updateOwner()" :items="users" auto-select-first return-object outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOwner()">
                                            <template v-slot:selection="{ item }">
                                                {{ item.userName }}
                                                <div class="ml-2 caption grey--text">{{ item.userEmail }}</div>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                {{ item.userName }}
                                                <div class="ml-2 caption grey--text">{{ item.userEmail }}</div>
                                            </template>
                                        </v-autocomplete>
                                        <!-- selectedOwner: {{ selectedOwner }} -->
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- Contract Status -->
                        <v-expansion-panel class="grey lighten-4">
                            <v-expansion-panel-header class="px-3 py-0">
                                <div class="text-left primary--text text--darken-2 text-h6 font-weight-light">Status</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row dense class="">
                                    <!-- Payment Cycle -->
                                    <v-col cols="12" xs="12" md="12">
                                        <v-select label="Status *" v-model="contract.contractStatus" :items="statuses" auto-select-first outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('contractStatus')"></v-select>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <!-- <pre align="left" class="">{{ contract }}</pre> -->
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "TicketDetails",
    props: {
        activecontract: {
            Type: Object,
            Default: null,
        },
    },
    // * DATA
    data: () => ({
        key: "contract",
        schema: {},
        editmode: false,
        selectedOrg: {
            id: "",
            orgName: "",
            orgAccountType: "",
        },
        selectedContact: {
            id: "",
            contactName: "",
            contactEmail: "",
            orgId: "",
        },
        selectedOwner: {
            ownerUserId: "",
            ownerUserName: "",
            ownerUserEmail: "",
        },
        selectedContinuation: "subsequent",
        selectedSubsequentTerm: "12",

        // dropdowns
        orgs: [],
        contacts: [],
        orgContacts: [],
        continuation: [
            { text: "Subsequent Term", value: "Subsequent", description: "The Contract will be extended for a subsequent term of 12 months on the expiry of the Initial Term (each such period being a “Subsequent Term”) and each Subsequent Term unless either party gives notice of termination in writing, such notice to be provided not less than three months before the end of the Initial Term or the Subsequent Term (as applicable) (subject to earlier termination in accordance with the Oomph Customer Standard Terms and Conditions)" },
            { text: "Extended (3 Months Notice)", value: "Extended" },
            { text: "No Renewal / No Extension", value: "None" },
        ],
        paymentCycles: ["Monthly", "Quarterly", "Biannual", "Annual"],
        paymentMethods: ["Direct Debit", "Credit Card", "Manual"],
        statuses: ['Draft', 'Approved', 'Rejected', 'Ready', 'Sent', 'Expired', 'Archived', 'Accepted', 'Declined', 'Cancelled'],
        users: [],

        // radio
        selectedContractTerm: "12",
        selectedSubsequentTerm: "12",
        otherContractTerm: '',
        otherSubsequentTerm: '',

        // panels
        panelFixed: 0,
        panelVariable: 0, // not sure being used

        // rules
        // componentCustomerDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],
        // componentInternalDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],

        // new contract object
        contract: {},
        loading: true,
        rules: {},

        // Dates
        contractDateMenu: false,
        contractDate: "", //new Date(Date.now()).toISOString().substr(0, 10),
        commencementDateMenu: false,
        commencementDate: "", //new Date(Date.now()).toISOString().substr(0, 10),
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: "firebase_auth_store/GET_FIREBASE_userAuth",
        }),
        computedOwner() {
            return {
                userId: this.GET_FIREBASE_userAuth.uid,
                userName: this.GET_FIREBASE_userAuth.displayName,
                userEmail: this.GET_FIREBASE_userAuth.email,
            };
        },
        // Computed Date
        computedContractDate: {
            get: function () {
                return this.MIX_formatDateTime(this.contractDate, "YYYY-MM-DD", "DD-MMM-YYYY");
            },
            set: function () {
                // do nothing
            },
        },
        computedCommencementDate: {
            get: function () {
                return this.MIX_formatDateTime(this.commencementDate, "YYYY-MM-DD", "DD-MMM-YYYY");
            },
            set: function () {
                // do nothing
            },
        },
    },
    methods: {
        // Initialise
        async initialise() {
            this.loading = true;
            this.contract = { ...this.activecontract };

            // Get Organisations for Customers Dropdown
            let organisations = await this.MIX_organisations();
            this.organisations = organisations;
            let customers = organisations.filter((org) => {
                return org.orgAccountType === "Customer";
            });
            this.customers = customers;

            // Get Contacts for Contact Names Dropdown
            let contacts = await this.MIX_contacts();
            this.contacts = contacts;

            // Get Users for Contract Owners Dropdown
            let users = await this.MIX_users("Sales,Developers,Administration");
            this.users = users.map((x) => {
                return {
                    userId: x.id,
                    userName: x.userName,
                    userEmail: x.userEmail,
                };
            });
            // //console.log("this.contract = " + JSON.stringify(this.contract, null, 2));
            // Set Contract Defaults Based on New or Existing
            if (this.contract.contractTitle === "") {
                // New Contract
                this.selectedOwner = this.computedOwner;
                this.contract.ownerUserId = this.computedOwner.userId;
                this.contract.ownerUserName = this.computedOwner.userName;
                this.contract.ownerUserEmail = this.computedOwner.userEmail;
            } else {
                this.selectedOrg = {
                    id: this.contract.orgId,
                    orgName: this.contract.orgName                
                }
                this.filterOrgContacts();
                this.selectedContact = {
                    id: this.contract.contactId,
                    contactName: this.contract.contactName,
                    contactEmail: this.contract.contactEmail,
                }
                this.selectedOwner = {
                    userId: this.contract.ownerUserId,
                    userName: this.contract.ownerUserName,
                    userEmail: this.contract.ownerUserEmail,
                }
                this.contractDate = this.MIX_formatDateTime(this.contract.contractDate, 'X', 'YYYY-MM-DD');
                this.commencementDate = this.MIX_formatDateTime(this.contract.contractCommencementDate, 'X', 'YYYY-MM-DD');
            }

            this.loading = false;
        },
        updateItem() {
            this.$emit("update", this.contract);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.contract[property] = "";
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit("save", this.contract);
        },
        // Cancel Item
        cancelItem() {
            this.contract = this.schema;
            this.$emit("close");
        },
        // Close Item
        closeItem() {
            this.contract = this.schema;
            this.$emit("close");
        },
        clearDataVariable(dataVariable, value) {
            setTimeout(() => {
                this[dataVariable] = value;
            }, 1);
        },
        clearSelectedOrg() {
            setTimeout(() => {
                this.selectedOrg = {
                    id: "",
                    orgName: "",
                    orgAccountType: "",
                };
            }, 1);
        },
        clearSelectedContact() {
            setTimeout(() => {
                this.selectedContact = {
                    id: "",
                    contactName: "",
                    contactEmail: "",
                    orgId: "",
                };
            }, 1);
        },
        filterOrgContacts() {
            this.orgContacts = this.contacts.filter((contact) => contact.orgId === this.selectedOrg.id);
        },
        updateContractTerm() {
            if (this.selectedContractTerm === "Other") {
                this.contract.contractTerm = this.otherContractTerm;
            } else {
                this.contract.contractTerm = this.selectedContractTerm;
                this.otherContractTerm = '';
            }
        },
        updateSubsequentTerm() {
            if (this.selectedSubsequentTerm === "Other") {
                this.contract.contractSubsequentTerm = this.otherSubsequentTerm;
            } else {
                this.contract.contractSubsequentTerm = this.selectedSubsequentTerm;
                this.otherSubsequentTerm = '';
            }
        },
        updateOrganisation() {
            this.contract.orgId = this.selectedOrg.id;
            this.contract.orgName = this.selectedOrg.orgName;
        },
        updateContact() {
            this.contract.contactId = this.selectedContact.id;
            this.contract.contactName = this.selectedContact.contactName;
            this.contract.contactEmail = this.selectedContact.contactEmail;
        },
        updateOwner() {
            this.contract.ownerUserId = this.selectedOwner.userId;
            this.contract.ownerUserName = this.selectedOwner.userName;
            this.contract.ownerUserEmail = this.selectedOwner.userEmail;
        }
    },
    watch: {
        contractDateMenu: {
            handler: async function () {
                this.contract.contractDate = this.MIX_formatDateTime(this.contractDate, "YYYY-MM-DD", "X");
            },
            deep: true,
        },
        commencementDateMenu: {
            handler: async function () {
                this.contract.contractCommencementDate = this.MIX_formatDateTime(this.commencementDate, "YYYY-MM-DD", "X");
            },
            deep: true,
        },
        activecontract: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        }
    },
    // * CREATED
    async created() {
        this.initialise();
    },
    mounted() {},
};
</script>

<style>
#expansion-panel-content-1 > * {
    padding-top: 0px;
    padding-right: 12px;
    padding-bottom: 0px;
    padding-left: 12px;
}
</style>
