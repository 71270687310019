<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field
                    label="Search Actions "
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="oomph-search"
                    @click:clear="clearSearchQuery()"
                ></v-text-field>
            </div>
            <!-- <div v-if="!listshrunk && listexpanded" class="ml-2">
                <v-select label="Filter by Type" background-color="white" v-model="type" :items="computedType" outlined dense clearable></v-select>
            </div> -->
            <!-- <div class="ml-2">
                <v-select label="Filter by Status" background-color="white" v-model="status" :items="computedStatus" outlined dense clearable></v-select>
            </div> -->
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>oomph-available-updates</v-icon>
            </v-btn>
            <div class="ml-2">
                <v-menu
                    offset-y
                    class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false"
                    origin="top right"
                    left
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                            ><v-icon>oomph-table-properties</v-icon></v-btn
                        >
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox
                                v-model="headers[index].hidden"
                                dense
                                hide-details="auto"
                                v-for="(item, index) in headers"
                                :key="index"
                                :label="item.text"
                                :true-value="false"
                                :false-value="true"
                            ></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <div class="d-flex align-start pt-4">
            <v-btn-toggle multiple v-model="status" class="appbackground d-flex align-center flex-wrap">
                <v-btn value="Open" active-class="grey lighten-1 white--text" depressed small>
                    <!-- <v-icon size="20" color="#146a7b" class="oomph-filled-circle"></v-icon> -->
                    Open
                </v-btn>
                <v-btn value="Pending" active-class="grey lighten-1 white--text" depressed small>
                    <!-- <v-icon size="20" color="pink" class="oomph-filled-circle"></v-icon> -->
                    Pending
                </v-btn>
                <v-btn value="Closed" active-class="grey lighten-1 white--text" depressed small>
                    <!-- <v-icon size="20" color="orange" class="oomph-filled-circle"></v-icon> -->
                    Closed
                </v-btn>
            </v-btn-toggle>
            <v-spacer />
            <!-- <pre>{{ type }}</pre> -->
            <v-btn-toggle multiple v-model="type" class="appbackground d-flex align-center flex-wrap">
                <v-btn value="Call" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Call' : ''"  size="20" color="grey" class="oomph-call"></v-icon>
                    <span v-if="$vuetify.breakpoint.xlOnly && listexpanded">Call</span>
                </v-btn>
                <v-btn value="Call Back" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Call Back' : ''" size="20" color="grey" class="oomph-callback"></v-icon>
                    <span v-if="$vuetify.breakpoint.xlOnly && listexpanded">Call Back</span>
                </v-btn>
                <v-btn value="Case" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Case' : ''"  size="20" color="grey" class="oomph-complaint"></v-icon>
                    <span v-if="$vuetify.breakpoint.xlOnly && listexpanded">Case</span>
                </v-btn>
                <v-btn value="Email" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Email' : ''"  size="20" color="grey" class="oomph-mail"></v-icon>
                    <span v-if="$vuetify.breakpoint.xlOnly && listexpanded">Email</span>
                </v-btn>
                <v-btn value="Event" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Event' : ''"  size="20" color="grey" class="oomph-calendar"></v-icon>
                    <span v-if="$vuetify.breakpoint.xlOnly && listexpanded">Event</span>
                </v-btn>
                <v-btn value="Meeting" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Meeting' : ''"  size="20" color="grey" class="oomph-meeting"></v-icon>
                    <span v-if="$vuetify.breakpoint.xlOnly && listexpanded">Meeting</span>
                </v-btn>
                <v-btn value="Task" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Task' : ''"  size="20" color="grey" class="oomph-tasklist"></v-icon>
                    <span v-if="$vuetify.breakpoint.xlOnly && listexpanded">Task</span>
                </v-btn>
                <v-btn class="flex-grow-1" value="Note" active-class="grey lighten-1 white--text" depressed small>
                    <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Note' : ''"  size="20" color="grey" class="oomph-note"></v-icon>
                    <span v-if="$vuetify.breakpoint.xlOnly && listexpanded">Note</span>
                </v-btn>
            </v-btn-toggle>
        </div>
        <v-row class="pa-3 mt-1">
            <!-- TABLE BASED LIST -->
            <v-data-table
                :item-class="itemRowBackground"
                sortable
                dense
                class=""
                style="width: 100%"
                :headers="computedHeaders"
                :items="computedItems"
                :server-items-length="itemsTotal"
                :options.sync="datatableOptions"
            >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <!-- <v-btn title="Edit" value="edit" small>
                                <v-icon class="grey--text text--darken-3">oomph-edit</v-icon>
                            </v-btn> -->
                            <v-btn title="Permanently Delete" value="destroy" small>
                                <v-icon class="error--text">oomph-delete-trash</v-icon>
                            </v-btn>
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                            <v-btn title="Undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                        <!-- <div class="mb-5 mx-5"><v-switch v-model="showImages" color="primary" label="Images" hide-details="auto" inset></v-switch></div>
					<div class="mb-5"><v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch></div>-->
                    </div>
                </template>


                <template v-slot:item.actionType="{ item }">
                    <div class="d-flex">
                        <div v-if="item.actionType === 'Call'">
                            <v-icon size="20" color="grey darken-2" class="oomph-call"></v-icon>
                        </div>
                        <div v-if="item.actionType === 'Call Back'">
                            <v-icon size="20" color="grey darken-2" class="oomph-callback"></v-icon>
                        </div>
                        <div v-if="item.actionType === 'Case'">
                            <v-icon size="20" color="grey darken-2" class="oomph-complaint"></v-icon>
                        </div>
                        <div v-if="item.actionType === 'Email'">
                            <v-icon size="20" color="grey darken-2" class="oomph-mail"></v-icon>
                        </div>
                        <div v-if="item.actionType === 'Event'">
                            <v-icon size="20" color="grey darken-2" class="oomph-calendar"></v-icon>
                        </div>
                        <div v-if="item.actionType === 'Meeting'">
                            <v-icon size="20" color="grey darken-2" class="oomph-meeting"></v-icon>
                        </div>
                        <div v-if="item.actionType === 'Task'">
                            <v-icon size="20" color="grey darken-2" class="oomph-tasklist"></v-icon>
                        </div>
                        <div v-if="item.actionType === 'Note'">
                            <v-icon size="20" color="grey darken-2" class="oomph-note"></v-icon>
                        </div>
                        <div class="font-weight-medium grey--text text--darken-2 pl-2">{{ item.actionType }}</div>
                    </div>
                </template>

                <template v-slot:item.actionStatus="{ item }">
                    <div v-if="item.actionStatus " class="d-flex">
                        <div>
                            <v-icon size="20" :color="item.actionStatus === 'Open' ? 'success' : item.actionStatus === 'Pending' ? 'warning' : item.actionStatus === 'Closed' ? 'error' : ''" class="oomph-filled-circle"></v-icon>
                        </div>
                        <div class="font-weight-medium grey--text text--darken-2 pl-2">{{ item.actionStatus }}</div>
                    </div>
                </template>

                <template v-slot:item.actionPriority="{ item }">
                    <div v-if="item.actionPriority " class="d-flex">
                        <div>
                            <v-icon size="20" :color="item.actionPriority === 'Urgent' || item.actionPriority === 'High' ? 'error' : item.actionPriority === 'Medium' ? 'warning' : item.actionPriority === 'Low' ? 'info' : ''" class="oomph-filled-circle"></v-icon>
                        </div>
                        <div class="font-weight-medium grey--text text--darken-2 pl-2">{{ item.actionPriority }}</div>
                    </div>
                </template>

                <template v-slot:item.actionDate="{ item }">
                    <div>{{ MIX_formatDateTime(item.actionDate, 'X', 'DD-MMM-YYYY') }}</div>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">oomph-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>
                </template>
            </v-data-table>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        contact: {
            type: Object,
            default: () => ({}),
        },
        org: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        status: [],
        type: [],
        key: 'action',
        index: 'idx:actions',
        action: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', value: 'id', sortable: false, hidden: true, expanded: true },
            { text: 'Action', value: 'actionType', sortable: false, hidden: false, expanded: false },
            { text: 'Subject', value: 'actionSubject', sortable: false, hidden: false, expanded: false },
            { text: 'Description', value: 'actionDescription', sortable: false, hidden: false, expanded: true },
            { text: 'Status', value: 'actionStatus', sortable: false, hidden: false, expanded: false },
            { text: 'Priority', value: 'actionPriority', sortable: false, hidden: false, expanded: false },
            { text: 'Date', value: 'actionDate', sortable: false, hidden: false, expanded: true },
            { text: 'selected Contacts', value: 'selectedContacts', sortable: false, hidden: false, expanded: true },
            // { text: 'End Date', value: 'actionEndDate', sortable: false, hidden: false, expanded: false },
            // { text: 'Close Date', value: 'actionCloseDate', sortable: false, hidden: false, expanded: false },
            { text: 'Organisation', value: 'orgName', sortable: false, hidden: false, expanded: false },
            { text: 'Organisation', value: 'orgId', sortable: false, hidden: false, expanded: true },
            { text: 'Contact Tags', value: 'contactTags', sortable: false, hidden: true, expanded: true },
            { text: 'Opportunity', value: 'opportunityId', sortable: false, hidden: true, expanded: true },
            { text: 'Lead', value: 'leadId', sortable: false, hidden: true, expanded: false },
            { text: 'Call Type', value: 'actionCallType', sortable: false, hidden: true, expanded: false },
            { text: 'Call Duration', value: 'actionCallDuration', sortable: false, hidden: true, expanded: false },
            { text: 'Call Purpose', value: 'actionCallPurpose', sortable: false, hidden: true, expanded: false },
            { text: 'Call Result', value: 'actionCallResult', sortable: false, hidden: true, expanded: true },
            { text: 'Meeting Host', value: 'actionMeetingHost', sortable: false, hidden: true, expanded: false },
            { text: 'Meeting Participants', value: 'actionMeetingParticipants', sortable: false, hidden: true, expanded: false },
            { text: 'Meeting Type', value: 'actionMeetingType', sortable: false, hidden: true, expanded: false },
            { text: 'Meeting Location', value: 'actionMeetingLocation', sortable: false, hidden: true, expanded: true },
            { text: 'Event Host', value: 'actionEventHost', sortable: false, hidden: true, expanded: true },
            { text: 'Event Participants', value: 'actionEventParticipants', sortable: false, hidden: true, expanded: false },
            { text: 'Event Type', value: 'actionEventType', sortable: false, hidden: true, expanded: false },
            { text: 'Event Location', value: 'actionEventLocation', sortable: false, hidden: true, expanded: false },
            { text: 'Case Type', value: 'actionCaseType', sortable: false, hidden: true, expanded: false },


            { text: 'Owner Id', value: 'ownerUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Owner Name', value: 'ownerUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Owner Email', value: 'ownerUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Created Name', value: 'createdUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Name', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Name', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Email', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        // Fields to Retrieve from Database
        //fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@actionSubject',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['addressName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        // productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
        filter: {
            productCategories: [],
        },
    }),
    computed: {
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:0';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }

            if (this.type !== null && this.type !== undefined && JSON.stringify(this.type) !== '[]') {
                searchQuery = `${searchQuery}* @actionType:${this.type.join('|')}`
            }
            // if (this.contact.id ) {

            // }

            if (this.org.id) {
                searchQuery = `${searchQuery}*  @orgId:${this.org.id}`;
            }

            // if (this.status !== null && this.status !== undefined && JSON.stringify(this.status) !== '[]') {
            //     searchQuery = `${searchQuery}* @actionStatus:${this.status}`
            // }
            // console.log(searchQuery)
            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            // if (this.listshrunk) {
            //     headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            // } else {
            //     headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            // }
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expanded !== true); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedItems() {
            var items = this.items;
            // if (this.type !== null && this.type !== undefined && JSON.stringify(this.type) !== '[]') {
            //     items = this.items.filter((item) => this.type.includes(item.actionType));
            // }
            // if (this.org.id ) {
            //     items = this.items.filter((item) => item.orgId === this.org.id)
            // }
            // if (this.contact.id ) {
            //     items = this.items.filter((item) => item.contactId === this.contact.id)
            // }
            if (this.status !== null && this.status !== undefined && JSON.stringify(this.status) !== '[]') {
                items = this.items.filter((item) => this.status.includes(item.actionStatus));
            }
            return items;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            if (!computedFields.includes('@actionId')) {
                computedFields.push('@actionId');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedType() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                return status.push(item.actionStatus);
            });
            return status;
        },
    },
    methods: {
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'action');
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Action', show: true });
            }
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // * GET ITEMS
        async getItems() {
            // if (this.searchQuery.length !== 1) {
                let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
                if (itemsResult.code === 1) {
                    this.itemsTotal = itemsResult.data.total;
                    this.items = itemsResult.data.results;
                    let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                    if (itemsTo < this.itemsTotal) {
                        this.itemsTo = itemsTo;
                    } else {
                        this.itemsTo = this.itemsTotal;
                    }
                }
            // }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.action = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        this.action = { ...this.datadown };
        this.getItems();
    },
};
</script>