<template>
    <div>
        <div class="noprint pa-5">
            <v-btn v-if="$route.query.print === 'yes'" type="primary" @click="print()">Print</v-btn>
        </div>
        <!-- Header -->
        <header v-if="$route.name === 'ContractPrint'">
            <!-- INSERT HEADER CONTENT HERE -->
            <div class="black pa-5 text-center" style="height: 100px !important">
                <img :src="require('@/assets/logo.png')" />
            </div>
        </header>
        <!-- Page Body -->
        <table>
            <thead v-if="$route.name === 'ContractPrint'">
                <tr>
                    <td>
                        <div class="header-space">&nbsp;</div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <!-- INSERT PAGE CONTENT HERE -->
                        <div class="text-center">
                            <h2>Customer Order</h2>
                        </div>
                        <!-- Customer Details -->
                        <p>
                            To:
                            <span v-if="org.orgRegistrationNumber">{{ org.orgRegistrationNumber }}</span>
                            <span v-else>{{ org.orgName }}</span>
                        </p>
                        <p class="body-2">
                            <span v-if="org.orgAddressLine1">{{ org.orgAddressLine1 }}<br /></span>
                            <span v-if="org.orgAddressLine2">{{ org.orgAddressLine2 }}<br /></span>
                            <span v-if="org.orgAddressLine3">{{ org.orgAddressLine3 }}<br /></span>
                            <span v-if="org.orgTown">{{ org.orgTown }}<br /></span>
                            <span v-if="org.orgCounty">{{ org.orgCounty }}<br /></span>
                            <span v-if="org.orgPostcode">{{ org.orgPostcode }}<br /></span>
                            <span v-if="org.orgCountry">{{ org.orgCountry }}<br /></span>
                            <span v-if="org.orgRegistrationNumber">{{ org.orgRegistrationNumber }}</span>
                        </p>

                        <p class="mt-5">Dear {{ contract.contactName }}</p>
                        <p>
                            <span class="font-weight-bold">Re: Provision of Services</span> - <span class="font-weight-normal">{{ contract.contractTitle }}</span>
                        </p>

                        <p class="mt-2 body-2">Further to our recent discussions, Oomph Wellness Training Limited (Supplier) hereby confirms the terms on which the Supplier agrees to provide and the Customer has agreed to take and pay for the Supplier's services subject to the terms and conditions of this agreement, which services will be of the nature and scope as set out in the key terms set out below) or as may be further agreed between us in writing from time to time (Services). The key terms are as follows:</p>

                        <table class="mt-5" id="contracttable" width="100%">
                            <!-- Commencement Date -->
                            <tr>
                                <td width="30%" valign="top" class="font-weight-bold grey lighten-2">Commencement Date</td>
                                <td width="70%" valign="top" class="white">{{ MIX_formatDateTime(contract.contractCommencementDate, "X", "DD-MMM-YYYY") }}</td>
                            </tr>
                            <!-- Initial Term -->
                            <tr>
                                <td width="30%" valign="top" class="font-weight-bold grey lighten-2">Initial Term</td>
                                <td width="70%" valign="top" class="white">{{ contract.contractTerm }} month(s) from {{ MIX_formatDateTime(contract.contractCommencementDate, "X", "DD-MMM-YYYY") }}</td>
                            </tr>
                            <!-- Services to be provided -->
                            <tr>
                                <td width="30%" valign="top" class="font-weight-bold grey lighten-2">Services to be provided</td>
                                <td width="70%" valign="top" class="white">
                                    <div v-if="JSON.stringify(computedWellBeing) !== '[]'">
                                        <strong>Wellbeing Package</strong>
                                        <ul class="mt-2">
                                            <li v-for="item in computedWellBeing" :key="item.id">
                                                {{ item.contractItemName }}: <strong>{{ item.contractItemPremises }}</strong> customer premise(s)
                                            </li>
                                        </ul>
                                        <p class="my-3">Services provided as part of the Wellbeing Package are further described in the Wellbeing Training Packages provided to the Customer on or prior to the date of this Customer Order, as such plan is amended from time to time and notified to Customer at least 90 days prior to any Subsequent Term.</p>
                                    </div>
                                    <div class="mt-4" v-if="JSON.stringify(computedOnDemand) !== '[]'">
                                        <strong>On Demand Package</strong>
                                        <ul class="mt-2">
                                            <li v-for="item in computedOnDemand" :key="item.id">
                                                {{ item.contractItemName }}: <strong>{{ item.contractItemPremises }}</strong> customer premise(s)
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="mt-4" v-if="JSON.stringify(computedComponents) !== '[]'">
                                        <strong>Additional Training</strong>
                                        <ul class="mt-2">
                                            <li v-for="item in computedComponents" :key="item.id">
                                                {{ item.contractItemName }}: <strong>{{ item.contractItemPremises }}</strong> qty
                                            </li>
                                        </ul>
                                        <p class="my-3">Services to be provided are also subject to the Training Booking and Cancelation policy provided to the Customer on or prior to the date of this Customer Order, as such plan is amended from time to time and notified to Customer at least 90 days prior to any Subsequent Term.</p>
                                    </div>
                                </td>
                            </tr>
                            <!-- Services to be provided -->
                            <tr>
                                <td width="30%" valign="top" class="font-weight-bold grey lighten-2">Fees</td>
                                <td width="70%" valign="top" class="white">
                                    <div v-if="JSON.stringify(computedWellBeing) !== '[]'">
                                        <strong>Wellbeing Package</strong>
                                        <ul class="mt-2">
                                            <li v-for="item in computedWellBeing" :key="item.id">
                                                {{ item.contractItemName }}:
                                                <span class="font-weight-bold">{{ new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(item.contractItemSell) }}</span>
                                                per premise
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Annual'">per year</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Monthly'">per month</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Quarterly'">per quarter</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Biannual'">twice per year</span>
                                                for an aggregate of
                                                <span class="font-weight-bold">{{ new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(item.contractItemTotal) }}</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Annual'"> per year</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Monthly'"> per month</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Quarterly'"> per quarter</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Biannual'"> twice per year</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="mt-3" v-if="JSON.stringify(computedOnDemand) !== '[]'">
                                        <strong>On Demand Package</strong>
                                        <ul class="mt-2">
                                            <li v-for="item in computedOnDemand" :key="item.id">
                                                {{ item.contractItemName }}:
                                                <span class="font-weight-bold">{{ new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(item.contractItemSell) }}</span>
                                                per premise
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Annual'">per year</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Monthly'">per month</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Quarterly'">per quarter</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Biannual'">twice per year</span>
                                                for an aggregate of
                                                <span class="font-weight-bold">{{ new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(item.contractItemTotal) }}</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Annual'"> per year</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Monthly'"> per month</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Quarterly'"> per quarter</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Biannual'"> twice per year</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="mt-3" v-if="JSON.stringify(computedComponents) !== '[]'">
                                        <strong>On Demand Package</strong>
                                        <ul class="mt-2">
                                            <li v-for="item in computedComponents" :key="item.id">
                                                {{ item.contractItemName }}:
                                                <span class="font-weight-bold">{{ new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(item.contractItemSell) }}</span>
                                                virtual training place
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Annual'">per year</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Monthly'">per month</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Quarterly'">per quarter</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Biannual'">twice per year</span>
                                                for an aggregate of
                                                <span class="font-weight-bold">{{ new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(item.contractItemTotal) }}</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Annual'"> per year</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Monthly'"> per month</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Quarterly'"> per quarter</span>
                                                <span class="font-weight-bold" v-if="item.contractPaymentCycle === 'Biannual'"> twice per year</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <p class="mt-3">Initial Fee Payable: [1/12 or 1/4 of annual spend above depending on whether monthly or quarterly billing selected below].</p>
                                    <p class="mt-3">As such fees may be adjusted after the Initial Term in accordance with the Oomph Customer Standard Terms and Conditions.</p>
                                </td>
                            </tr>

                            <!-- Renewal and Termination -->
                            <tr>
                                <td width="30%" valign="top" class="font-weight-bold grey lighten-2">Renewal and Termination</td>
                                <td width="70%" valign="top" class="white">
                                    The Contract will be extended for a subsequent term of {{ contract.contractSubsequentTerm }} months on the expiry of the Initial Term (each such period being a “Subsequent Term”) and each Subsequent Term unless either party gives notice of termination in writing, such notice to be provided not less than three months before the end of the Initial Term or the Subsequent Term (as applicable) (subject to earlier termination in accordance with the Oomph Customer Standard Terms and Conditions).
                                </td>
                            </tr>
                            <!-- Payment Terms -->
                            <tr>
                                <td width="30%" valign="top" class="font-weight-bold grey lighten-2">Payment Terms</td>
                                <td width="70%" valign="top" class="white">
                                    Invoiced <strong>{{ contract.contractPaymentCycle }}</strong> in advance. Payment must be made within 30 days from date of invoice.
                                </td>
                            </tr>
                            <!-- Supplier Representatives -->
                            <tr>
                                <td width="30%" valign="top" class="font-weight-bold grey lighten-2">Supplier Representatives</td>
                                <td width="70%" valign="top" class="white font-weight-bold">
                                    {{ contract.ownerUserName }} - <a :href="'mailto:' + contract.ownerUserEmail" class="text-decoration-none black--text">{{ contract.ownerUserEmail }}</a>
                                </td>
                            </tr>
                            <!-- Customer Representatives -->
                            <tr>
                                <td width="30%" valign="top" class="font-weight-bold grey lighten-2">Customer Representatives</td>
                                <td width="70%" valign="top" class="white font-weight-bold">
                                    {{ contract.contactName }} - <a :href="'mailto:' + contract.contactEmail" class="text-decoration-none black--text">{{ contract.contactEmail }}</a>
                                </td>
                            </tr>
                            <!-- Email address for notices -->
                            <tr>
                                <td width="30%" valign="top" class="font-weight-bold grey lighten-2">Email address for notices</td>
                                <td width="70%" valign="top" class="white">
                                    In respect of Supplier:<br />
                                    Email: <a href="mailto:hello@oomph-wellness.org" class="text-decoration-none font-weight-bold black--text">hello@oomph-wellness.org</a><br />
                                    In respect of Customer:<br />
                                    Email: <a :href="'mailto:' + contract.contactEmail" class="text-decoration-none font-weight-bold black--text">{{ contract.contactEmail }}</a>
                                </td>
                            </tr>
                            <!-- Other Terms -->
                            <tr v-if="contract.contractOtherTerms">
                                <td width="30%" valign="top" class="font-weight-bold grey lighten-2">Other Terms</td>
                                <td width="70%" valign="top" class="white">{{ contract.contractOtherTerms }}</td>
                            </tr>
                        </table>
                        <!-- Contract Signatures -->
                        <div class="mt-5 body-2 font-weight-bold">
                            <p>This Contract is made up of this Customer Order and the Oomph Customer Standard Terms and Conditions.</p>
                        </div>
                        <div class="mt-5 body-2 font-weight-bold">
                            <p>Agreed for and on behalf of the Supplier:</p>
                        </div>
                        <div class="mt-5 body-2 font-weight-bold">
                            <table class="mt-5" id="signaturetable" width="100%">
                                <tr>
                                    <td>Signed:</td>
                                    <td>Date:</td>
                                </tr>
                                <tr>
                                    <td>Name:</td>
                                    <td>Position:</td>
                                </tr>
                            </table>
                        </div>

                        <!-- <pre>{{ contractItems }}</pre> -->
                        <!-- 
                        <div class="d-flex align-start">
                            <div class="flex-grow-1" style="width: 33%;">
                                <pre>{{ contract }}</pre>
                            </div>
                            <div class="flex-grow-1" style="width: 33%;">
                                <pre>{{ org }}</pre>
                            </div>
                            <div class="flex-grow-1" style="width: 33%;">
                            </div>
                        </div> -->
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="pagebreak"></div>
                        <!-- Terms and Conditions - Start -->
                        <div class="text-center">
                            <h5>Appendix to the Customer Order - Oomph Customer Standard Terms and Conditions <br />The Customer’s attention is particularly drawn to the provisions of clause 12</h5>
                        </div>
                        <div class="col">
                            GENERAL<br />
                            (1) These Standard Terms and Conditions of Oomph Wellness Training Limited, a company registered in England and Wales with company number 08603448 whose registered office is at 85 Great Portland Street, First Floor, London W1W 7LT (Supplier) shall apply exclusively. Supplier shall not accept any conflicting or deviating terms and conditions of Customer (as such term is defined in the Customer Order), whether delivered by the Customer before or after the date of this agreement and whether acceptance is expressly denied at the time or not.<br />
                            (2) This Agreement is made up of the Customer Order and these Standard Terms and Conditions.<br />
                            (3) This agreement has been entered into on the date set out in the Customer Order.<br />
                            AGREED TERMS<br />
                            1. INTERPRETATION<br />
                            1.1 The definitions and rules of interpretation in this clause apply in this Contract.<br />
                            Agreed Purposes: Purposes that are (i) necessary for the performance of this Contract, such as the provision and tracking of the use of the Services and contacting Customer’s employees in connection with the services provided under this Contract; and (ii) necessary for our legitimate business interests, such as contacting Customer’s employees to offer support in connection with the Services.<br />
                            Business Day: a day other than a Saturday, Sunday or public holiday in England when banks in London are open for business.<br />
                            Controller, data controller, processor, data processor, data subject, personal data, processing and appropriate technical and organisational measures: as set out in the Data Protection Legislation in force at the time.<br />
                            Data Protection Legislation: all applicable data protection and privacy legislation in force from time to time in the UK including the UK GDPR; the Data Protection Act 2018 (DPA 2018) (and regulations made thereunder); the Privacy and Electronic Communications Regulations 2003 (SI 2003/2426) as amended and all other legislation and regulatory requirements in force from time to time which apply to a party relating to the use of personal data (including, without limitation, the privacy of electronic communications); provided that the term UK GDPR has the meaning given to it in section 3(10) (as supplemented by section 205(4)) of the Data Protection Act 2018.<br />
                            Fees: the sum(s) outlined in the Customer Order, payable in accordance with the terms set forth in the Customer Order and clause 4.<br />
                            Initial Term: has the meaning set out in the Customer Order.<br />
                            Intellectual Property: patents, rights to inventions, copyright and related rights, trade marks, trade names and domain names, rights in get-up, rights in goodwill or to sue for passing off, rights in designs, rights in computer software, methodology, choreography, routines, exercises music, videos and media, database rights, rights in confidential information (including know-how and trade secrets) and any other intellectual property rights, in each case whether registered or unregistered and including all applications (or rights to apply) for, and
                            <!-- Column Break-->
                            renewals or extensions of, such rights and all similar or equivalent rights or forms of protection which may, now or in the future, subsist in any part of the world relating to any part of the Supplier’s business, including the Services and including on and offline content, materials, forms, data, products and services.<br />
                            Permitted Recipients: The parties to this Contract, the employees of each party, any third parties engaged to perform obligations in connection with this Contract.<br />
                            Services: the provision of the services set forth in the Customer Order.<br />
                            Shared Personal Data: the personal data that may be shared between the parties under clause 25 of this Contract. Shared Personal Data shall be confined to the following categories of information for employees of the Customer:<br />
                            a) full name;<br />
                            b) contact details; and<br />
                            c) in limited circumstances, health information that is provided by Customer employees relating to their ability to undertake the relevant training.<br />
                            Term: the Initial Term of this Contract as extended in accordance with the terms of the Customer Order (subject to earlier termination in accordance with clause 9).<br />
                            1.1 Clause, schedule and paragraph headings shall not affect the interpretation of this Contract.<br />
                            1.2 A person includes a natural person, corporate or unincorporated body (whether or not having separate legal personality) and that person's personal representatives, successors or permitted assigns. <br />
                            1.3 A reference to any party shall include that party's personal representatives, successors or permitted assigns.<br />
                            1.4 A reference to writing or written does not include faxes nor e-mail.<br />
                            1.5 Any phrase introduced by the terms including, include, in particular or any similar expression shall be construed as illustrative and shall not limit the sense of the words preceding those terms. <br />
                            1.6 Any obligation in this Contract not to do something includes an obligation not to agree or allow that thing to be done.<br />
                            2. SUPPLY OF SERVICES<br />
                            2.1 The Supplier shall supply the Services to the Customer during the Term in accordance with this Contract.<br />
                            3. TERM<br />
                            3.1 The Supplier shall provide the Services during the Term.<br />
                            4. FEES<br />
                            4.1 On the date of this Contract, the Customer shall pay the sum outlined in the Customer Order as the Initial Fee Payable. Thereafter, the Customer shall pay any additional Fees in accordance with the terms set out in the Customer Order.<br />
                            4.2 Unless the Supplier otherwise notifies the Customer in writing, the Customer shall make all payments electronically to the following bank account: <br />
                            Oomph Wellness Training Limited <br />
                            Sort Code: 09 02 22<br />
                            Account Number: 10350146<br />
                            4.3 If the Customer fails to pay any amount properly due and payable by it under this Contract, the Supplier shall have the right to charge interest on the overdue amount at the rate of 5 per cent above the Bank of England’s base rate for the time being, with interest
                            <!-- Column Break-->accruing on a daily basis from the due date up to the date of actual payment, whether before or after judgment. This clause shall not apply to payments that the Customer disputes in good faith. <br />
                            4.4 For each Subsequent Term (or, if applicable, each year following the Initial Term, in the event of an “open” extension of the Initial Term), unless the parties shall otherwise agree and provided that the Services remain consistent and similar to those previously provided in in the initial Term (or, if applicable, Subsequent Term), the total fee for all Services shall equal the fee that would be charged for the same services based on the fees currently being charged, increased by the percentage increase for the previous twelve-month period of the Consumer Prices Index or, in the event that publication of such index is terminated, any successor or substitute index, appropriately adjusted, reasonably acceptable to both parties. As used herein “Consumer Prices Index” shall mean the Consumer Prices Index (all items) (United Kingdom).<br />
                            4.5 All fees due under this Contract are exclusive of VAT and any similar replacement or additional tax, which shall, where applicable, be paid by the Customer at the prevailing rate on the due date for payment or receipt of the relevant invoice from the Supplier (as may be).<br />
                            4.6 The Customer shall pay all amounts due under the Contract in full without any set-off, counterclaim, deduction or withholding (except for any deduction or withholding required by law). The Supplier may at any time, without limiting its other rights or remedies, set off any amount owing to it by the Customer against any amount payable by the Supplier to the Customer.<br />
                            5. SUPPLIER'S OBLIGATIONS<br />
                            5.1 The Supplier shall at all times during the Term: <br />
                            a) supply the Services;<br />
                            b) provide the Customer with such know-how, advice and guidance relating to the Services as the Supplier, acting reasonably, thinks fit; and<br />
                            c) update the Services from time to time.<br />
                            5.2 The Customer acknowledges and agrees that none of the Services shall be provided by the Supplier at any Customer premises or in person unless otherwise agreed.<br />
                            6. CUSTOMER'S OBLIGATIONS<br />
                            6.1 The Customer shall not at any time during<br />
                            the Term and at any time thereafter, copy, distribute (in any format), transfer or permit the use of any Supplier content or materials or log in credentials provided by the Supplier to the Customer to or by any person or entity outside the Customer premise(s) to which this Contract relates, including for the avoidance of doubt to any other of the Customer’s premises. <br />
                            6.2 The Customer acknowledges that it is solely the Customer’s responsibility and that of its employees to:<br />
                            a) only provide the activities and/or exercises covered in the Services at a safe and suitable location at the Customer’s premises;<br />
                            b) determine whether the activities and/or exercises covered in the Services are appropriate and safe for the individuals in its care;<br />
                            c) determine whether individuals in its care are sufficiently fit and healthy to take part in the activities and/or exercises covered in the
                            <!-- Page Break -->Services including, but not limited to, ensuring the Customer employees ask, and obtain appropriate answers to, the question “Is everyone here fit and well and able to do the exercises today?” before any exercises are performed, and if any individuals are not fit, well or able to take part, then ensuring such individuals do not participate; and<br />
                            d) obtain necessary, adequate and appropriate professional insurance to cover any and all potential liabilities relating to the delivery of any activities and/or exercises covered in the Services.<br />
                            6.3 The Customer shall not, without the prior written consent of the Supplier, at any time during the Term and for 12 months following termination of this Contract for any reason, solicit or entice away from the Supplier or employ or attempt to employ any person who is, or has been, engaged as an employee of the Supplier or the Oomph group of companies. Any consent given by the Supplier in accordance with the preceding sentence shall be subject to the Customer paying to the Supplier a sum equivalent to the then current annual remuneration of such employee or, if higher, the annual remuneration to be paid by the Customer to that employee.<br />
                            7. INTELLECTUAL PROPERTY<br />
                            7.1 The Customer acknowledges that:<br />
                            a) it does not have any right, title or interest in the Intellectual Property or any updates or improvements to it, save as specifically set out in this Contract; and<br />
                            b) any goodwill (and any other rights) in the Intellectual Property which result from the use by the Customer shall vest in the Supplier. <br />
                            7.2 The Customer agrees not to carry out any service in or relating to the care homes or care operations it provides that compete with the Supplier’s business during the Term and for 12 months following termination of this Contract for any reason.<br />
                            8. CONFIDENTIALITY<br />
                            8.1 Each party undertakes that it shall not at any time during this Contract, and for a period of two years after termination of this Contract, copy, use or disclose to any person any confidential information concerning the business, affairs, customers, clients or suppliers of the other party, except as permitted by this Contract.<br />
                            8.2 A party may disclose such confidential information to its employees, officers, representatives or advisers who need to know such information for the purposes of carrying out its obligations under this Contract. Such party will ensure that those to whom such confidential information is disclosed comply with clause 8.1. In addition, a party may disclose such confidential information as may be required by law, court order or any governmental or regulatory authority.<br />
                            8.3 Neither party shall use such confidential information for any purpose other than to perform its obligations under this Contract.<br />
                            9. TERMINATION<br />
                            9.1 A party may terminate this Contract with immediate effect (or following such notice period as it sees fit) without prejudice to any of its rights or remedies, by giving written notice to the other party if:<br />
                            a) in the case of the Supplier, the Customer fails to pay any amount due under this Contract on the due date for payment and remains in default not less than seven days after being notified in writing to make such payment;<br />
                            b) the other party commits a material breach of any term of this Contract and (if such breach is remediable) fails to remedy that breach within a period of 14 days after being notified in writing to do so;<br />
                            c) the other party repeatedly breaches any of the terms of this Contract in such a manner as to reasonably justify the opinion that its<!-- Column Break --><br />
                            conduct is inconsistent with having the intention or ability to give effect to the terms of this Contract;<br />
                            d) the other party gives any false or misleading information, or makes any misrepresentation in connection with obtaining this Contract or during the Term, in connection with its business; <br />
                            e) in the case of the Supplier, persistent, valid complaints continue to be made to the Supplier about the quality of the service provided by the Customer and the Customer, having received notice of such complaints, fails to improve such service to the satisfaction of the Supplier;<br />
                            f) in the case of the Suppler, the Customer, in the reasonable opinion of the Supplier, does, or permits to be done, any act which might jeopardise or invalidate the registration of the Intellectual Property or does any act which might assist, or give rise to, an application to remove the Intellectual Property, or which might prejudice the right or title of the Supplier to the Intellectual Property;<br />
                            g) in the case of the Supplier, the Customer fails to meet the required standards expected by the Care Quality Commission;<br />
                            h) the other party brings itself into disrepute in the reasonable opinion of the terminating party;<br />
                            i) the other party suspends, or threatens to suspend, payment of its debts or is, or is deemed to be, insolvent (the other party is unable to pay its debts as they fall due for payment or admits inability to pay its debts);<br />
                            j) an order is made, a resolution is passed, or a notice is issued convening a meeting for the purpose of passing a resolution, or any analogous proceedings are taken, for the winding-up, administration or dissolution of the other party;<br />
                            k) any liquidator, trustee in bankruptcy, receiver, administrative receiver, administrator or similar officer is appointed over, or in respect of, the other party or any part of its business or assets; or <br />
                            l) the other party suspends or ceases, or threatens to suspend or cease, to carry on all or a substantial part of its business.<br />
                            10. CONSEQUENCES OF TERMINATION<br />
                            10.1 Any termination or expiry of this Contract shall not affect any rights, remedies, obligations or liabilities that have accrued prior to such termination. Clauses which expressly or by implication survive termination shall continue in full force and effect.<br />
                            10.2 On termination or expiry of this Contract for any reason, the Customer shall: <br />
                            a) immediately pay the Supplier the full amount of all sums due from the Customer to the Supplier together with any interest payable in accordance with clause 4.3; <br />
                            b) the Customer shall on request return any Supplier content and materials in its possession (in whatever format) and/or erase irretrievably any such content and materials from computer and communications systems owned or used by you; and<br />
                            c) Immediately stop using or accessing the Intellectual Property, including for the avoidance of doubt any Supplier content and materials forming part of the Services.<br />
                            11. INDEMNITY<br />
                            11.1 Subject to clause 12, the Customer shall indemnify the Supplier against all liabilities, costs, expenses, damages and losses (including all interest, penalties and legal and other professional costs and expenses) suffered or incurred by the Supplier arising out of or in connection with the Customer's breach or negligent performance or non-performance of this Contract.<!-- Column Break --><br />
                            12. LIMITATION OF LIABILITY: THE CUSTOMER’S ATTENTION IS PARTICULARLY DRAWN TO THIS CLAUSE<br />
                            12.1 Nothing in this Contract shall limit or exclude either party's liability for:<br />
                            a) death or personal injury caused by its negligence, or the negligence of its employees, agents or subcontractors;<br />
                            b) fraud or fraudulent misrepresentation; or<br />
                            c) breach of the terms implied by section 2 of the Supply of Goods and Services Act 1982 (title and quiet possession) or any other liability which cannot be limited or excluded by applicable law.<br />
                            12.2 Subject to clause 12.1, neither party shall be liable to the other party, whether in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising under or in connection with this Contract for:<br />
                            a) loss of profits;<br />
                            b) loss of sales or business;<br />
                            c) loss of contracts;<br />
                            d) loss of anticipated savings;<br />
                            e) loss of use or corruption of software, data or information; <br />
                            f) loss of damage to goodwill; and<br />
                            g) any indirect or consequential loss.<br />
                            12.3 Subject to clauses 12.1, the Supplier's total liability to the Customer, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with the Contract shall be limited to the aggregate of any Fees received by the Supplier pursuant to the terms of this Contract.<br />
                            12.4 The terms implied by sections 3 to 5 of the Supply of Goods and Services Act 1982 are, to the fullest extent permitted by law, excluded from the Contract. <br />
                            12.5 This clause 12 shall survive termination of the Contract.<br />
                            13. FORCE MAJEURE<br />
                            13.1 Neither party shall be in breach of the Contract nor liable for delay in performing, or failure to perform, any of its obligations under the Contract if such delay or failure result from events, circumstances or causes beyond its reasonable control. <br />
                            14. ENTIRE AGREEMENT<br />
                            14.1 This Contract and any documents referred to in it constitute the whole agreement between the parties and supersede any previous arrangement, understanding or agreement between them relating to the subject matter of this Contract. <br />
                            15. FURTHER ASSURANCE<br />
                            15.1 The Customer shall (and shall use all reasonable endeavours to procure that any necessary third party shall) (at their own expense) promptly execute and deliver all such documents, and perform such acts, as the Supplier may, from time to time, reasonably require for the purpose of giving full effect to this Contract.<br />
                            16. ASSIGNMENT<br />
                            16.1 This Contract is personal to the Customer, who may not, without the prior written consent of the Supplier, assign, transfer, sub-contract, delegate or deal in any other manner with this Contract or any of their rights and obligations under it (or any document referred to in it) or purport to do any of the same. <br />
                            16.2 The Supplier may, at any time, assign (absolutely or by way of security and in whole or in part), transfer or deal in any other manner with the benefit of any or all of any other party's obligations or any benefit arising under this Contract to another member of the Supplier’s corporate group.<br />
                            17. THIRD PARTY RIGHTS<br />
                            17.1 A person who is not a party to this Contract shall not have any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this Contract. <br />
                            17.2 The rights of the parties to terminate, rescind or agree any variation, waiver or<!--Page Break--><br />
                            settlement under this Contract is not subject to the consent of any person that is not a party to this Contract.<br />
                            18. NO PARTNERSHIP OR AGENCY<br />
                            18.1 Nothing in this Contract is intended to, or shall be deemed to, establish any partnership or joint venture between any of the parties, nor constitute any party the agent of another party, nor authorise any party to make or enter into any commitments for or on behalf of any other party.<br />
                            19. SEVERANCE<br />
                            19.1 If any provision or part-provision of this Contract is or becomes invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, the relevant provision or part-provision shall be deemed deleted. Any modification to or deletion of a provision or part-provision under this clause shall not affect the validity and enforceability of the rest of this Contract. <br />
                            20. VARIATION<br />
                            20.1 No variation of this Contract or of any document referred to in it shall be effective unless it is in writing and signed by the parties (or their authorised representatives).<br />
                            21. WAIVER<br />
                            21.1 A waiver of any right or remedy under this Contract or by law is only effective if given in writing and shall not be deemed a waiver of any subsequent breach or default. A failure or delay by a party to exercise any right or remedy provided under this Contract or by law shall not constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict any further exercise of that or any other right or remedy. No single or partial exercise of any right or remedy provided under the Contract or by law shall prevent or restrict the further exercise of that or any other right or remedy.<br />
                            22. NOTICES AND RESOLUTION<br />
                            22.1 A notice or other communication given to a party under or in connection with this Contract shall be in writing and shall be delivered by hand, commercial courier or by pre-paid first-class post or recorded delivery to the other party at its address set out in this Contract or sent by email to the other party’s email address for notices set out in the Customer Order.<br />
                            22.2 Any notice shall be deemed to have been received as follows:<br />
                            a) if delivered personally, at the time of delivery;<br />
                            b) if sent by commercial courier, at the time of signature of the courier's delivery receipt;<br />
                            c) if sent by pre-paid first class post or recorded delivery, 9.00 am on the second Business Day after posting or at the time recorded by the delivery service; and<br />
                            d) if sent by email, at the time of transmission (or if transmission is not in business hours, at 9 am on the first business day following transmission).<br />
                            22.3 This clause shall not apply to the service of any proceedings or other documents in any legal action.<br />
                            23. ALTERNATIVE DISPUTE RESOLUTION <br />
                            23.1 If any dispute arises in connection with this Contract, the parties shall attempt to settle it by mediation in accordance with the CEDR Model Mediation Procedure.<br />
                            23.2 Unless otherwise agreed between the parties, the mediator shall be nominated by CEDR. To initiate the mediation, a party shall give notice in writing ("ADR notice") to the other party to the dispute requesting a mediation. A copy of the request should be sent to CEDR.<br />
                            23.3 The mediation shall start not later than 10 Business Days after the date of the ADR notice. The commencement of a mediation shall not prevent the parties commencing or continuing court proceedings <br />
                            24. GOVERNING LAW AND JURISDICTION<br />
                            24.1 This Contract, and any dispute or claim arising out of or in connection with it or its subject<!-- Column Break -->matter or formation (including non-contractual disputes or claims), shall be governed by and construed in accordance with the law of England and Wales.<br />
                            24.2 The parties irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim that arises out of, or in connection with, this Contract or its subject matter or formation (including non-contractual disputes or claims).<br />
                            25. DATA PROTECTION<br />
                            25.1 Shared Personal Data. This clause sets out the framework for the sharing of personal data between the parties as data controllers. Each party acknowledges that one party (the Data Discloser) will regularly disclose to the other party (the Data Recipient) Shared Personal Data collected by the Data Discloser for the Agreed Purposes. <br />
                            25.2 Effect of non-compliance with Data Protection Legislation. Each party shall comply with all the obligations imposed on a controller under the Data Protection Legislation, and any material breach of the Data Protection Legislation by one party shall, if not remedied within 30 days of written notice from the other party, give grounds to the other party to terminate this Contract with immediate effect.<br />
                            25.3 Particular obligations relating to data sharing. Each party shall:<br />
                            a) ensure that it has all necessary notices and consents in place to enable lawful transfer of the Shared Personal Data to the Permitted Recipients for the Agreed Purposes;<br />
                            b) give full information to any data subject whose personal data may be processed under this Contract of the nature such processing. This includes giving notice that, on the termination of this Contract, personal data relating to them may be retained by or, as the case may be, transferred to one or more of the Permitted Recipients, their successors and assignees;<br />
                            c) process the Shared Personal Data only for the Agreed Purposes;<br />
                            d) not disclose or allow access to the Shared Personal Data to anyone other than the Permitted Recipients;<br />
                            e) ensure that all Permitted Recipients are subject to written contractual obligations concerning the Shared Personal Data (including obligations of confidentiality) which are no less onerous than those imposed by this Contract;<br />
                            f) ensure that it has in place appropriate technical and organisational measures, reviewed and approved by the other party, to protect against unauthorised or unlawful processing of personal data and against accidental loss or destruction of, or damage to, personal data;<br />
                            g) not transfer any personal data received from the Data Discloser outside the UK unless the transferor ensures that (i) the transfer is to a country approved under the applicable Data Protection Legislation as providing adequate protection; or (ii) there are appropriate safeguards or binding corporate rules in place pursuant to the applicable Data Protection Legislation; or (iii) the transferor otherwise complies with its obligations under the applicable Data Protection Legislation by providing an adequate level of protection to any personal data that is transferred; or (iv) one of the derogations for specific situations in the applicable Data Protection Legislation applies to the transfer.<br />
                            25.4 Mutual assistance. Each party shall assist the other in complying with all applicable requirements of the Data Protection Legislation. In particular, each party shall:<br />
                            a) consult with the other party about any notices given to data subjects in relation to the Shared Personal Data;<br />
                            <!-- Column Break --><br />
                            b) promptly inform the other party about the receipt of any data subject access request;<br />
                            c) provide the other party with reasonable assistance in complying with any data subject access request;<br />
                            d) not disclose or release any Shared Personal Data in response to a data subject access request without first consulting the other party wherever possible;<br />
                            e) assist the other party, at the cost of the other party, in responding to any request from a data subject and in ensuring compliance with its obligations under the Data Protection Legislation with respect to security, breach notifications, impact assessments and consultations with supervisory authorities or regulators;<br />
                            f) notify the other party without undue delay on becoming aware of any breach of the Data Protection Legislation;<br />
                            g) at the written direction of the Data Discloser, delete or return Shared Personal Data and copies thereof to the Data Discloser on termination of this Contract unless required by law to store the personal data;<br />
                            h) use compatible technology for the processing of Shared Personal Data to ensure that there is no lack of accuracy resulting from personal data transfers;<br />
                            i) maintain complete and accurate records and information to demonstrate its compliance with this clause 25; and<br />
                            j) provide the other party with contact details of at least one employee as point of contact and responsible manager for all issues arising out of the Data Protection Legislation, including the joint training of relevant staff, the procedures to be followed in the event of a data security breach, and the regular review of the parties' compliance with the Data Protection Legislation.<br />
                            25.5 Indemnity. Subject to clause 12, each party shall indemnify the other against all liabilities, costs, expenses, damages and losses (including but not limited to all interest, penalties and legal costs (calculated on a full indemnity basis) and all other reasonable professional costs and expenses) suffered or incurred by the indemnified party arising out of or in connection with the breach of the Data Protection Legislation by the indemnifying party, its employees or agents, provided that the indemnified party gives to the indemnifier prompt notice of such claim, full information about the circumstances giving rise to it, reasonable assistance in dealing with the claim and sole authority to manage, defend and/or settle it. <br />
                            <br />
                            This Contract has been entered into on the date set out in the Customer Order.
                        </div>
                        <!-- Terms and Conditions - END -->
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="$route.name === 'ContractPrint'">
                <tr>
                    <td>
                        <div class="footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>
        </table>
        <!-- Footer -->
        <footer v-if="$route.name === 'ContractPrint'">
            <!-- INSERT FOOTER CONTENT HERE -->
            <div class="grey py-5 px-5 d-flex align-center">
                <img height="20" :src="require('@/assets/logo.png')" />
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: "contract-print",
    // * Props
    props: {
        contractid: {
            type: String,
            default: "",
        },
    },
    // * Data
    data: () => ({
        loading: false,
        contractId: "",
        contract: {},
        contractItems: [],
        org: {},
    }),
    computed: {
        computedWellBeing() {
            let contractItems = [...this.contractItems];
            return contractItems.filter((x) => x.contractItemCategory === "Wellbeing" && x.contractItemPCType === "package");
        },
        computedOnDemand() {
            let contractItems = [...this.contractItems];
            return contractItems.filter((x) => x.contractItemCategory === "On Demand" && x.contractItemPCType === "package");
        },
        computedComponents() {
            let contractItems = [...this.contractItems];
            return contractItems.filter((x) => x.contractItemPCType === "component");
        },
    },
    methods: {
        print() {
            window.print();
        },
        async initialise() {
            this.loading = true;
            // Get Contract Details
            let contractResult = await this.MIX_readItem(this.contractId, "contract");
            console.log("contractResult", JSON.stringify(contractResult, null, 2));
            if (contractResult.code === 1) {
                this.contract = contractResult.data;
            }
            // Get Contract Items
            let contractItemsResult = await this.MIX_redisOrderedSearch("idx:contractItems", "*", 0, 9999, "contractItemOrder");
            if (contractItemsResult.code === 1) {
                this.contractItems = contractItemsResult.data.documents.map((x) => x.value);
            }
            // Get Organisation Details
            let orgResult = await this.MIX_readItem(this.contract.orgId, "org");
            if (orgResult.code === 1) {
                this.org = orgResult.data;
            }
            // Get Organisation Details
            // await this.MIX_readItem(thisits n.contract.orgId, "org").then((response) => {
            //     this.org = response.data;
            // });
            this.loading = false;
        },
        async getContract() {},
    },
    created() {
        if (this.contractid !== "") {
            this.contractId = this.contractid;
        } else {
            this.contractId = this.$route.params.id;
        }
        this.initialise();
    },
};
</script>

<style scoped>
#signaturetable > tr > td {
    padding: 20px;
    margin: 20px;
    border-bottom: 1px solid grey !important;
}

#contractable {
    border: 2px solid grey !important;
}

#contracttable > tr > td {
    border: 1px solid grey !important;
    font-size: 12px !important;
    border-collapse: collapse;
    padding: 10px;
    margin: 10px;
    border: 1px grey solid;
}

.col {
    /* margin-top: 5px !important; */
    -moz-column-count: 3;
    -moz-column-gap: 20px;
    -webkit-column-count: 3;
    -webkit-column-gap: 20px;
    padding: 5px !important;
    /* column-fill: auto;
    -moz-column-fill: auto;
    -webkit-column-fill: auto; */
    font-size: 8px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    /* align-content: start !important;
    justify-content: start !important; */
}
pre {
    font-size: 10px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.noprint {
    z-index: 999 !important;
    position: fixed;
}
header,
.header-space {
    background-color: #ffffff;
    height: 100px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 10px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
    /* padding-bottom: 15pt; */
}
footer,
.footer-space {
    background-color: #ffffff;
    height: 80px;
    font-weight: bold;
    width: 100%;
    border: 0px solid red;
    padding-left: 4px !important;
    padding-right: 4px !important;
    padding-top: 0px !important;
    /* margin-top: 100px !important;     */
    /* padding-top: 15pt;
    padding-bottom: 15pt; */
}

header {
    position: fixed;
    top: 0;
}

footer {
    position: fixed;
    bottom: 0;
}

.pagebreak {
    margin-top: 100px;
}

@media print {
    .pagebreak {
        clear: both;
        page-break-after: always;
    }
    .noprint {
        display: none;
    }
}
</style>
