<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important"
        :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- <div class=""
        style="width: 100% !important; padding: 0px !important; margin: 0px !important; overflow-y: scroll;   /* other browsers */
  overflow-y: overlay; overflow-x: hidden; /* Chrome */"> -->
        <!-- <v-row class="pa-4">
            <v-col cols="6">
                <div class="text-h5 font-weight-thin primary--text">Content Stats</div>
            </v-col>
        </v-row> -->
        <v-row class="pa-3">
            <v-col class="col-lg col-md-4 col-sm-6">
                <v-card class="elevation-0 primary d-flex flex-column align-center">
                    <v-icon class="pt-4 white--text">mdi-eye-arrow-right-outline</v-icon>
                    <div class="v-card__title text-h3 white--text pa-1">{{ this.contentViews || 0 }}</div>
                    <div class="text-xs-h5 white--text  pt-0 pb-4">VIEWS</div>
                </v-card>
            </v-col>
            <v-col class="col-lg col-md-4 col-sm-6">
                <v-card class="elevation-0 primary d-flex flex-column align-center">
                    <v-icon class="pt-4 white--text">mdi-thumb-up-outline</v-icon>
                    <div class="v-card__title text-h3 white--text pa-1">{{ this.contentLikes || 0 }}</div>
                    <div class="text-xs-h5 white--text pt-0 pb-4">LIKES</div>
                </v-card>
            </v-col>
            <v-col class="col-lg col-md-4 col-sm-6">
                <v-card class="elevation-0 primary d-flex flex-column align-center">
                    <v-icon class="pt-4 white--text">mdi-star</v-icon>
                    <div class="v-card__title text-h3 white--text pa-1"> {{ this.contentRating || 0 }}</div>
                    <div class="text-xs-h5 white--text pt-0 pb-4">RATING</div>
                </v-card>
            </v-col>
            <v-col class="col-lg col-md-4 col-sm-6">
                <v-card class="elevation-0 primary d-flex flex-column align-center">
                    <v-icon class="pt-4 white--text">mdi-comment-text-outline</v-icon>
                    <div class="v-card__title text-h3 white--text pa-1">{{ this.contentReviews || 0 }}</div>
                    <div class="text-xs-h5 white--text pt-0 pb-4">REVIEWS</div>
                </v-card>
            </v-col>
            <v-col class="col-lg col-md-4 col-sm-6">
                <v-card class="elevation-0 primary d-flex flex-column align-center">
                    <v-icon class="pt-4 white--text">mdi-download-circle-outline</v-icon>
                    <div class="v-card__title text-h3 white--text pa-1">{{ this.contentDownloads || 0 }}</div>
                    <div class="text-xs-h5 white--text pt-0 pb-4">DOWNLOADS</div>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="pa-3 pt-0 mt-0">
            <v-col class="col-lg-5 col-md-12 col-sm-12">
                <v-card class="d-flex flex-column align-center elevation-0 " style="min-height: 340px; max-height: 340px">
                    <!-- <v-col class="pa-0 pl-3 pt-3">
                        <div class="text-h8 primary--text"><b>Average </b>Rating</div>
                    </v-col> -->
                    <v-col cols="12" sm="12">
                        <div class="d-flex align-center">
                            <span
                                class="text-left primary--text  text--darken-2 text--darken-2 text-h6 font-weight-light">Average
                                Rating</span>
                            <v-spacer />
                            <!-- <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon> -->
                        </div>
                        <v-divider class="grey lighten-1 mb-1"></v-divider>
                    </v-col>
                    <apexchart ref="pie" type="pie" :options="pieOptions" :series="pieSeries" width="320px"
                        height="300px" />
                    <v-spacer></v-spacer>
                </v-card>
            </v-col>
            <v-col class="col-lg-7 col-md-12 col-sm-12">
                <v-card class="  elevation-0 d-flex flex-column align-center" style="min-height: 340px; max-height: 340px">
                    <!-- <v-col class="pa-0 pl-3 pt-3 primary--text">
                        <div class="text-h8"><b>Monthly </b>Views</div>
                    </v-col> -->
                    <v-col cols="12" sm="12">
                        <div class="d-flex align-center">
                            <span
                                class="text-left primary--text  text--darken-2 text--darken-2 text-h6 font-weight-light">Monthly
                                Views</span>
                            <v-spacer />
                            <!-- <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon> -->
                        </div>
                        <v-divider class="grey lighten-1 mb-1"></v-divider>
                    </v-col>
                    <apexchart ref="ts" type="area" :options="tsOptions" :series="tsSeries" width="350px" height="270px" />
                </v-card>
            </v-col>
        </v-row>
        <v-row class="pa-3 pt-0 mt-0">
            <v-col>
                <v-card class="d-flex flex-column align-center elevation-0">
                    <v-col cols="12" sm="12">
                        <div class="d-flex align-center">
                            <span
                                class="text-left primary--text  text--darken-2 text--darken-2 text-h6 font-weight-light">Reviews</span>
                            <v-spacer />
                            <!-- <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon> -->
                        </div>
                        <v-divider class="grey lighten-1 mb-1"></v-divider>
                    </v-col>
                    <v-col cols="12" class="d-flex flex-row" v-for="(item, index) in reviews" :key="'review-' + index">
                        <div class="review-img"></div>
                        <div class="d-flex flex-column">
                            <div>{{ item.reviewComment }}</div>
                            <div class="d-flex flex-row">
                                <div class="mr-6 mt-1" style="min-width: 140px"><b>Date:</b> {{ convertDate(item.datetime)
                                }}</div>
                                <div class="d-flex flex-row">
                                    <v-icon v-for="(i, idx) in formatRating(item.reviewRating)" :key="`star-${idx}`">{{ i
                                    }}</v-icon>
                                </div>
                            </div>
                        </div>
                        <v-spacer></v-spacer>
                        <div fill-height class="d-flex justify-center flex-wrap-reverse">
                            <v-btn width="105" plain class="m-1">{{ item.reviewQuote }}</v-btn>
                            <v-btn width="105" @click="reviewUpdate(item.reviewQuoteStatus, index)" color="success"
                                class="m-1">{{ (item.reviewQuoteStatus == 1) ? "HIDE" : "PUBLISH" }}</v-btn>
                        </div>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<style scoped>
.review-img {
    background-color: #808080;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}
</style>

<script>
import { mapGetters } from 'vuex';
import { TS, PIE } from "@/components/charts";
import moment from "moment";

const BASE_URL = process.env.VUE_APP_OOMPHSERVER || "https://oomph-server-ftqyigyrea-ew.a.run.app/api/v1"
export default {
    name: 'ContentStats',
    components: {},
    props: ['originalContentId', 'contentViews', 'contentLikes', 'contentDownloads', 'contentReviews', 'contentRating', 'content'],
    data: () => ({
        pieOptions: PIE.chartOptions,
        pieSeries: PIE.series,
        tsOptions: TS.chartOptions,
        tsSeries: TS.series,
        reviews: []
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_firebase_userAccess: 'firebase_auth_store/GET_firebase_userAccess',
            GET_FIREBASE_apiHeaderToken: 'firebase_auth_store/GET_FIREBASE_apiHeaderToken',
        }),
        apiOptions() {
            return {
                headers: {
                    Authorization: `Bearer ${this.GET_FIREBASE_apiHeaderToken}`,
                },
            };
        },
    },
    methods: {
        formatRating(rating) {
            let starsHTML = []
            let stars = Math.floor(rating / 1)
            let count = 0

            for (let i = 0; i < stars; i++) {
                starsHTML.push("mdi-star")
                count++
            }

            if (rating % 1 != 0) {
                starsHTML.push("mdi-star-half-full")
                count++
            }

            while (5 - count > 0) {
                starsHTML.push("mdi-star-outline")
                count++
            }

            // //console.log(starsHTML)
            return starsHTML

        },
        async reviewUpdate(reviewStatus, index) {
            let reviewsList = this.reviews

            // some reason it removes contentId????? so I add it back
            reviewsList[index].contentId = this.originalContentId

            // Live, when click we need to change to PENDING
            if (reviewStatus == 0) {
                reviewsList[index].reviewQuoteStatus = 1
                reviewsList[index].reviewQuote = "LIVE"
            }
            // PENDING, when clicked need to change to live
            else if (reviewStatus == 1) {
                reviewsList[index].reviewQuoteStatus = 0
                reviewsList[index].reviewQuote = "PENDING"
            }
            // update front end
            this.reviews = reviewsList

            // update database
            await this.$axios.post(`${BASE_URL}/stats/reviews/update/status`, reviewsList[index], this.apiOptions)

        },
        convertDate(date) {
            return moment(date).format("Do-MMM-YYYY")
        }
    },
    async created() {
        const values = await this.$axios.get(`${BASE_URL}/stats/content/all`, {
            params: {
                contentId: this.originalContentId
            }
        }, this.apiOptions)
        const res = values.data.data
        this.reviews = res.reviews

        // pie chart
        if (res.pie.yaxis.length > 0) {

            let options = {
                ...PIE.chartOptions,
                ...{
                    labels: res.pie.xaxis
                }
            }

            let series = res.pie.yaxis

            this.pieOptions = options
            this.pieSeries = series
        } else {
            this.pieOptions = PIE.chartOptions
            this.pieSeries = PIE.series
        }

        // timeseries 
        if (res.ts.ts.length > 0) {
            let to = TS.chartOptions
            let ts = TS.series

            ts = [{
                name: 'Views',
                data: res.ts.ts
            }]

            this.tsOptions = to
            this.tsSeries = ts
        } else {
            this.tsOptions = TS.chartOptions
            this.tsSeries = TS.series
        }
    }
}
</script>
