<template>
    <div class="px-5 appbackground" style="width: 100% !important; height: calc(100vh - 170px) !important;">
        <div class="d-flex align-center py-5">
            <div class="text-h5 font-weight-bold primary--text">Reviews</div>
            <v-spacer />
            <div>
                <v-btn class="primary white--text rounded" @click="downloadCSV()" depressed><v-icon class="mr-2">icons8-export-csv</v-icon>Download CSV</v-btn>
            </div>
        </div>
        <v-data-table 
            :headers="reviewHeaders"
            :items="reviewData"
            :items-per-page="10"
            class="elevation-0">
            <template v-slot:[`item.contentReview.createdDateTime`]="{ item }">
                {{ item.contentReview !== null ? MIX_formatDateTime(item.contentReview.createdDateTime, "x", "DD MMM YYYY HH:mm") : "" }}
            </template>
            <template v-slot:[`item.contentReview.reviewQ3A`]="{ item }">
                {{ item.contentReview !== null ? item.contentReview.reviewQ3A : "" }}
            </template>
            <template v-slot:[`item.contentReview.createdUserName`]="{ item }">
                {{ item.contentReview !== null ? item.contentReview.createdUserName : "" }}
            </template>
            <template v-slot:[`item.contentReview.userJobTitle`]="{ item }">
                {{ item.contentReview !== null ? item.contentReview.userJobTitle : "" }}
            </template>
            <template v-slot:[`item.contentReview.reviewComment`]="{ item }">
                {{ item.contentReview !== null ? item.contentReview.reviewComment : "" }}
            </template>
            <template v-slot:[`item.contentReview.reviewQ4A`]="{ item }" class="text-truncate">
                <v-rating
                    length="5"
                    readonly
                    size="20"
                    :value="Number(item.contentReview.reviewQ4A)"
                ></v-rating>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        selectedOrgGroup: {
            type: Object,
            default: () => {
                return {};
            },
        },
        selectedSubOrg: {
            type: Array,
            default: () => {
                return [];
            },
        },
        daterange: {
            type: Object,
            default: () => {
                return {
                    startDate: null,
                    endDate: null,
                };
            },
        },
    },    
    watch: {
        daterange: {
            handler: function () {
                let t = this;
                t.getReviewStats();
            },
            deep: true,
        },
    }, 
    data: () => ({
        reviewHeaders: [
            { text: "Content Title", value: "contentTitle", align: "start", sortable: false },
            { text: "Type", value: "contentAlias", align: "start" },
            { text: "Date / Time", value: "contentReview.createdDateTime", align: "start", sortable: false },
            { text: "Participants", value: "contentReview.reviewQ3A", align: "center", sortable: false },
            { text: "Reviewer", value: "contentReview.createdUserName", align: "start", sortable: false },
            { text: "Role", value: "contentReview.userJobTitle", align: "start", sortable: false },
            { text: "Comments", value: "contentReview.reviewComment", align: "start", sortable: false },
            { text: "Rating", value: "contentReview.reviewQ4A", align: "start" },
        ],
        reviewData: [],
    }),
    methods: {
        async getReviewStats() {
            let t = this;
            let query = "";
            let subOrgs = [];
            t.loading = true;

            const daterange = t.daterange.startDate && t.daterange.endDate ? `@createdDateTime:[${t.daterange.startDate} ${t.daterange.endDate}]` : '';
            if (t.selectedOrgGroup.id === '3RTRBM9dqrkC4WEsIvhx') {
                //* Returns all stats for all orgs
                query = daterange || ''
            } else {
                //* Returns stats for selected org
                query = `@createdUserOrgId:{${t.selectedOrgGroup.id}} ${daterange}`;
            }
            if (t.selectedSubOrg.length > 0) {
                //* Returns stats for selected sub orgs
                subOrgs = t.selectedSubOrg.map((org) => org.id).join('|');
                query = `@createdUserOrgId:{${subOrgs}} ${daterange}`;
            } 
            let result = await t.MIX_reviews({ searchQuery: query });
            if (result.code === 1) {
                //grab the data, filter out nulls, sort by date
                t.reviewData = result.data.filter((e) => e.contentTitle !== null && e.contentAlias !== null).filter((item) => {
                  if (item.contentReview !== null) {
                    return item.contentReview.reviewQ4A !== null;
                  }
                }).sort((a, b) => {
                  return b.contentReview.createdDateTime - a.contentReview.createdDateTime;
                });

                console.log(t.reviewData);
            }
        },
        downloadCSV() {
            let t = this;
            const dateFrom = t.$moment.unix(t.daterange.startDate).format('DDMMMYYYY');
            const dateTo = t.$moment.unix(t.daterange.endDate).format('DDMMMYYYY');
            const dateNow = t.$moment().format('DDMMMYYYY');
            const date = t.daterange.startDate && t.daterange.endDate ? `${dateFrom}_${dateTo}` : dateNow;
            var views = t.reviewData.map((e) => {
                return {
                    "Content Title": e.contentTitle,
                    "Content Alias": e.contentAlias,
                    "Date / Time": this.MIX_formatDateTime(e.contentReview.createdDateTime, "x", "DD MMM YYYY HH:mm"),
                    "Participants": e.contentReview.reviewQ3A,
                    "Reviewer": e.contentReview.createdUserName,
                    "Role": e.contentReview.userJobTitle,
                    "Comments": e.contentReview.reviewComment,
                    "Rating": e.contentReview.reviewQ4A,
                };
            });
            var loginCSV = t.$papa.unparse(views);
            this.$papa.download(loginCSV, `Report_Reviews_${date}.csv`, "text/csv");
        },
    },
    created() {
        let t = this;
        t.getReviewStats();
    },
};
</script>
