import moment from "moment";

let dates = []
for (let i = 1; i < 28; i++) {
    dates.push([moment(new Date()).subtract(i, "d"), 0])
}

export const TS = {
    series: [{
        name: 'Views',
        data: dates
    }],
    chartOptions: {
        chart: {
            type: 'area',
            stacked: false,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom'
            },
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return (val).toFixed(0);
                },
            },
        },
        xaxis: {
            type: 'datetime',
        },
    }
};


export const PIE = {
    series: [100],
    chartOptions: {
        plotOptions: {
            pie: {
                startAngle: 0,
                endAngle: 360,
                offsetX: 0,
                offsetY: 0,
                customScale: 1,
                dataLabels: {
                    offset: -9,
                    minAngleToShowLabel: 10
                },
            }
        },
        chart: {
            type: 'pie',
        },
        labels: ['None'],
        legend: {
            position: 'bottom'
        }
    },
}