<template>
    <div class="px-5 appbackground" style="width: 100% !important; height: calc(100vh - 170px) !important;">
        <div class="d-flex align-center py-5">
            <div class="text-h5 font-weight-bold primary--text">Logins</div>
            <v-spacer />
            <div>
                <v-btn class="primary white--text rounded" @click="downloadCSV()" depressed><v-icon
                        class="mr-2">icons8-export-csv</v-icon>Download CSV</v-btn>
            </div>
        </div>
        <v-data-table :loading="loading" must-sort :headers="loginHeaders" :items="computedLogins" :items-per-page="10"
            class="elevation-0">
            <template v-slot:[`item.userLoggedInTime`]="{ item }">
                <div v-if="item.userLoggedInTime !== ''" class="px-0 black--text">{{
                    MIX_formatDateTime(item.userLoggedInTime, "x", "DD MMM YYYY HH:mm") }}</div>
                <div v-else class="px-0 black--text">No last login</div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: 'Logins',
    data: () => ({
        logins: [],
        loginData: [],
        loginCount: 0,
        loginHeaders: [
            { text: "Name", value: "userName", align: "start" },
            { text: "Role", value: "userJobTitle", align: "start" },
            { text: "Group", value: "userGroup", align: "start" },
            { text: "Organisation", value: "orgName", align: "start" },
            { text: "On Demand Logins", value: "ondemand", align: "start" },
            { text: "TV Logins", value: "tv", align: "start" },
            { text: "Last Login", value: "userLoggedInTime", align: "start" },
            { text: "Total Logins", value: "total", align: "start" },
        ],
    }),
    props: {
        selectedOrgGroup: {
            type: Object,
            default: () => {
                return {};
            },
        },
        selectedSubOrg: {
            type: Array,
            default: () => {
                return [];
            },
        },
        daterange: {
            type: Object,
            default: () => {
                return {
                    startDate: null,
                    endDate: null,
                };
            },
        },
    },
    computed: {
        computedLogins() {
            let t = this;
            let login = [];

            if (t.daterange !== {}) {
                t.loginData.filter(date => {
                    if (date.userLoggedInTime.length > 1 && date.userLoggedInTime.length < 13) {
                        date.userLoggedInTime = date.userLoggedInTime + "000"
                    }
                    if (t.daterange.startDate && t.daterange.endDate) {
                        const from = t.$moment.unix(t.daterange.startDate).format('x');
                        const to = t.$moment.unix(t.daterange.endDate).format('x');
                        if ((date.userLoggedInTime >= from && date.userLoggedInTime <= to) || (date.userLoggedInTime == "")) {
                            login.push(date);
                        }
                    }
                    else {
                        login.push(date);
                    }

                })
            } else {
                login = t.loginData;
            }

            login.sort((a, b) => {
                return b.total - a.total;
            });

            // for (let i = 0; i < login.length; i++) {
            //     if (parseInt(login[i].total) == 0) login[i].userLoggedInTime = ""
            // }

            return login
        }
    },
    methods: {
        async getLoginUsers() {
            let t = this;
            let query = "";
            let subOrgs = [];
            t.loading = true;

            const daterange = t.daterange.startDate && t.daterange.endDate ? `@createdDateTime:[${t.daterange.startDate} ${t.daterange.endDate}]` : '';

            if (t.selectedOrgGroup.id === '3RTRBM9dqrkC4WEsIvhx') {
                //* Returns all stats for all orgs
                query = daterange || '*';
            } else {
                //* Returns stats for selected org
                query = `@createdUserOrgId:{${t.selectedOrgGroup.id}} ${daterange}`;

            }
            if (t.selectedSubOrg.length > 0) {
                //* Returns stats for selected sub orgs
                subOrgs = t.selectedSubOrg.map((org) => org.id).join('|');
                query = `@createdUserOrgId:{${subOrgs}} ${daterange}`;

            }

            let logins = await t.MIX_loginUsers({ searchQuery: query, orgId: t.selectedOrgGroup.id, subOrgs: subOrgs });
            console.log({ query, selectedOrgGroupID: t.selectedOrgGroup.id, subOrgs })

            let res = await Promise.all([logins]).then(data => {
                return {
                    logins: data[0].data,
                }
            })

            if (res) {
                t.loginData = res.logins;
            }
            t.loading = false;
        },
        downloadCSV() {
            let t = this;
            const dateFrom = t.$moment.unix(t.daterange.startDate).format('DDMMMYYYY');
            const dateTo = t.$moment.unix(t.daterange.endDate).format('DDMMMYYYY');
            const dateNow = t.$moment().format('DDMMMYYYY');
            const date = t.daterange.startDate && t.daterange.endDate ? `${dateFrom}_${dateTo}` : dateNow;
            let logins = t.loginData.map((e) => {
                return {
                    Name: e.userName,
                    Role: e.userJobTitle,
                    Organisation: e.orgName,
                    "On Demand Logins": e.ondemand,
                    "TV Logins": e.tv,
                    "Last Login": (e.userLoggedInTime) ? this.MIX_formatDateTime(e.userLoggedInTime, "x", "DD MMM YYYY HH:mm") : "No last login",
                    "Total Logins": e.total,
                };
            });

            let loginCSV = t.$papa.unparse(logins);
            this.$papa.download(loginCSV, `Report_Logins_${date}.csv`, "text/csv");
        },
    },
    watch: {
        daterange: {
            handler: function () {
                let t = this;
                t.getLoginUsers();
            },
            deep: true,
        },
    },
    created() {
        let t = this;
        t.getLoginUsers();
    },
};
</script>
