<template>
    <div class="px-5 appbackground" style="width: 100% !important; height: calc(100vh - 170px) !important;">
        <div class="d-flex align-center py-5">
            <div v-if="filterBy.length < 1" class="text-h5 font-weight-bold primary--text">Views</div>
            <div v-if="filterBy.length == 1" class="text-h5 font-weight-bold primary--text">{{ filterBy[0] }} Views</div>
            <div v-if="filterBy.length > 1" class="text-h5 font-weight-bold primary--text">Total Views</div>
            <v-spacer />
            <div>
                <v-btn class="primary white--text rounded" @click="downloadCSV()" depressed><v-icon class="mr-2">icons8-export-csv</v-icon>Download CSV</v-btn>
            </div>
        </div>
        <v-data-table :loading="loading" must-sort :headers="viewHeaders" :items="viewData" :items-per-page="10" class="elevation-0">
            <template v-slot:[`item.dementiaFriendly`]="{ item }">
                {{ item.dementiaFriendly == "1" ? "Yes" : "No" }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        selectedOrgGroup: {
            type: Object,
            default: () => {
                return {};
            },
        },
        selectedSubOrg: {
            type: Array,
            default: () => {
                return [];
            },
        },
        daterange: {
            type: Object,
            default: () => {
                return {
                    startDate: null,
                    endDate: null,
                };
            },
        },
        filterBy: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data: () => ({
        contentItem: [],
        contentItems: [],
        contentTemplateKey: 0,
        loading: false,
        showContentItem: false,
        views: [],
        viewData: [],
        viewCount: 0,
        viewHeaders: [
            { text: "Content Title", value: "contentTitle", align: "start" },
            { text: "Content Area", value: "contentArea", align: "start" },
            { text: "Content Alias", value: "contentAlias", align: "start" },
            { text: "Dementia Friendly", value: "dementiaFriendly", align: "center" },
            { text: "Number of Views", value: "viewsTotal", align: "center" },
        ],
    }),
    watch: {
        daterange: {
            handler: function () {
                let t = this;
                t.getViews();
            },
            deep: true,
        },
        selectedOrgGroup: {
            handler: function () {
                let t = this;
                t.getViews();
            },
            deep: true,
        },
    },   
    methods: {
        async getViews() {
            let t = this;
            let query = "";
            let subOrgs = [];
            t.loading = true;
            const daterange = t.daterange.startDate && t.daterange.endDate ? `@createdDateTime:[${t.daterange.startDate} ${t.daterange.endDate}]` : '';


            if (t.selectedOrgGroup.id === '3RTRBM9dqrkC4WEsIvhx') {
                //* Returns all stats for all orgs
                query = daterange || '*';
            } else {
                //* Returns stats for selected org
                query = `@createdUserOrgId:{${t.selectedOrgGroup.id}} ${daterange}`;
            }
            
            if (t.selectedSubOrg.length > 0) {
                //* Returns stats for selected sub orgs
                subOrgs = t.selectedSubOrg.map((org) => org.id).join('|');
                query = `@createdUserOrgId:{${subOrgs}} ${daterange}`;
            } 

            
            let result = await t.MIX_views({ searchQuery: query });
            if (result.code === 1) {
                let temp = result.data
                temp = temp.filter((e) => e.contentTitle !== null).filter((value, index, self) => index === self.findIndex((t) => (t.contentId === value.contentId)));
                t.viewData = temp
            }
            t.loading = false;
        },
        downloadCSV() {
            let t = this;
            const dateFrom = t.$moment.unix(t.daterange.startDate).format('DDMMMYYYY');
            const dateTo = t.$moment.unix(t.daterange.endDate).format('DDMMMYYYY');
            const dateNow = t.$moment().format('DDMMMYYYY');
            const date = t.daterange.startDate && t.daterange.endDate ? `${dateFrom}_${dateTo}` : dateNow;
            var views = t.viewData.map((e) => {
                return {
                    "Content Title": e.contentTitle,
                    "Content Area": e.contentArea,
                    "Content Alias": e.contentAlias,
                    'Dementia Friendly': e.dementiaFriendly === '1' ? 'Yes' : "No",
                    "Total Views": e.viewsTotal,
                };
            });

            var loginCSV = t.$papa.unparse(views);
            this.$papa.download(loginCSV, `Report_Views_${date}.csv`, "text/csv");
        },
    }, 
    created() {
        let t = this;
        t.getViews();
    },
};
</script>
