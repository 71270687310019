<template>
    <v-container class="pa-0 ma-0" fluid fill-height>
        <v-col class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'" class="ma-0 pa-0">
                <v-img :min-height="$vuetify.breakpoint.smAndUp ? '90vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage">
                    <v-col cols="6" class="pb-0 ml-6">
                        <v-btn v-if="$route.params.id" @click="$router.push(`/home`)" depressed small><v-icon>oomph-back</v-icon></v-btn>
                        <v-btn v-else @click="$emit('close')" depressed small><v-icon>oomph-back</v-icon></v-btn>
                    </v-col>
                    <v-row class="d-flex justify-center">
                        <v-img max-height="180" max-width="180" :src="require('@/assets/oomphlogoalt.png')" contain></v-img>
                    </v-row>
                </v-img>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? '6' : '12'" class="d-flex align-stretch pa-0 ma-0">
                <v-card class="elevation-0 rounded-0" width="100%">
                    <v-card-title class="text-h4 font-weight-thin">{{ contentItem.contentTitle }}</v-card-title>
                    <v-card-subtitle>{{ contentItem.contentSubtitle }}</v-card-subtitle>
                    <v-card-text class="py-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'circle' : 'circle-responsive'">
                            <div class="text-h5 font-weight-thin primary--text mt-4">{{ contentItem.contentDurationMins }} <br />mins</div>
                        </div>
                        <v-row class="d-flex align-center ml-2 pt-4">
                            <v-col cols="4">
                                <v-rating
                                    empty-icon="oomph-star"
                                    full-icon="oomph-star-2"
                                    length="5"
                                    readonly
                                    size="25"
                                    background-color="grey"
                                    color="primary"
                                    :value="parseInt(contentItem.contentDifficultyRating)"
                                    dense
                                ></v-rating>
                            </v-col>
                            <v-col cols="4">
                                <v-row class="d-flex justify-center align-center">
                                    <v-icon v-if="contentItem.contentDifficultyRating === 'Beginner'" size="28" color="success">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficultyRating === 'Intermediate'" size="28" color="warning">oomph-speedometer</v-icon>
                                    <v-icon v-if="contentItem.contentDifficultyRating === 'Advanced'" size="28" color="error">oomph-speedometer</v-icon>
                                    <div v-if="contentItem.contentDifficultyRating === 'Beginner'" class="body-1 px-2 success--text">{{ contentItem.contentDifficultyRating }}</div>
                                    <div v-if="contentItem.contentDifficultyRating === 'Intermediate'" class="body-1 px-2 warning--text">{{ contentItem.contentDifficultyRating }}</div>
                                    <div v-if="contentItem.contentDifficultyRating === 'Advanced'" class="body-1 px-2 error--text">{{ contentItem.contentDifficultyRating }}</div>
                                </v-row>
                            </v-col>
                            <v-col cols="4" class="d-flex justify-center align-center">
                                <v-icon size="28" color="success">oomph-good-quality</v-icon>
                                <div class="text-h6 px-2">{{ contentItem.contentLikes }}</div>
                                <v-icon size="28" color="error">oomph-unlike</v-icon>
                                <div class="text-h6 px-2">{{ contentItem.contentDislikes }}</div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text class="pt-0" v-if="!instuctionsInfoMore">
                        <div class="text-h6 primary--text mt-4 pt-3">Suitable for:</div>
                        <v-row class="d-flex align-center ml-2 pt-4">
                            <v-col cols="6" class="d-flex align-center pa-0">
                                <v-icon size="40" color="primary">oomph-learning</v-icon>
                                <div class="body-1 ml-2">Dementia Friendly</div>
                            </v-col>
                            <v-col cols="6" class="d-flex align-center pa-0">
                                <v-icon size="40" color="primary">oomph-eye</v-icon>
                                <div class="body-1 ml-2">Visual Impairment</div>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center ml-2 pt-4">
                            <v-col cols="6" class="d-flex align-center pa-0">
                                <v-icon size="40" color="primary">oomph-crowd</v-icon>
                                <div class="body-1 ml-2">Small Group</div>
                            </v-col>
                            <v-col cols="6" class="d-flex align-center pa-0">
                                <v-icon size="40" color="primary">oomph-listen</v-icon>
                                <div class="body-1 ml-2">Hearing Impairment</div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text class="pb-0">
                        <span class="body-1 primary--text">Description<br/></span>
                        {{ contentItem.contentDescription }}
                    </v-card-text>
                    <v-row class="d-flex pt-3">
                        <v-col cols="12" class="py-0">
                            <v-card-text v-show="instuctionsInfo" class="pb-2" v-html="instructions.substring(0, 1700)"></v-card-text>
                            <v-card-text v-show="instuctionsInfoMore" v-html="instructions.substring(1701, 2700)"></v-card-text>                           
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="d-flex pt-3">
                        <v-col cols="12">
                            <v-card height="700" class="elevation-0 white" style="overflow: scroll;">
                                <v-card-text v-show="instuctionsInfo" class="pb-2" v-html="instructions"></v-card-text>
                                <v-card-text class="d-flex justify-center">
                                    <mux-audio
                                        :playback-id="podcast.playbackId"
                                        :env-key="podcast.envKey"
                                        :metadata-video-title="podcast.title"
                                        stream-type="on-demand"
                                        controls
                                        preload
                                    ></mux-audio>
                                </v-card-text>
                                <v-card-text style="position: fixed !important; bottom: 80px !important">
                                    <span class="primary--text mr-2 pt-4">Tags:</span>
                                    <v-chip class="mr-1" v-for="item in tags" :key="item">{{ item }}</v-chip>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-col>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Podcast',
    props: {
        showContentItem: {
            type: Boolean,
            default: false,
        },
        contentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        contentItems: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data: () => ({
        coverImage: '',
        instructions: '',
        instuctionsInfo: true,
        instuctionsInfoMore: false,
        podcast: {
            title: '',
            playbackId: '',
            envKey: process.env.VUE_APP_MUX,
        },
        tags: [],
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
            GET_brand: 'GET_brand',
        }),
    },
    methods: {
        async getContentItems() {
            let t = this;
            let result = await t.MIX_redisSearch('idx:contentItems', `@itemContentId:{${t.contentItem.id}}`, 0, 9999);
            if (result.code === 1) {
                t.contentItems = result.data.documents;
            }
            //* Filter all content items
            t.contentItems.filter((item) => {
                //* Get audio only item
                if(item.value.itemType === 'Audio') {
                    t.podcast.playbackId = item.value.itemPlaybackId;
                    t.podcast.title = item.value.itemName;
                }
                //* Get cover images
                if (item.value.itemMarker === 'Cover Image') {
                    t.coverImage = item.value.itemURL;
                }
                //* Get text items
                if (item.value.itemType === 'Text') {
                    t.instructions = item.value.itemTextHTML;
                }
            });
        },
        getTags() {
            let t = this;
            const array = t.contentItem.contentTags.split(',');
            return (t.tags = array);
        }
    },
    mounted() {
        let t = this;
        t.getContentItems();
        t.getTags();
    },
};
</script>

<style>
.circle {
    position: absolute;
    text-align: center;
    margin-left: -65px;
    height: 100px;
    width: 100px;
    background-color: #ffffff;
    border-radius: 50%;
}
.circle-responsive{
    position: absolute;
    text-align: center;
    top: 0;
    right: 0;
    height: 100px;
    width: 100px;
    background-color: #ffffff;
    border-radius: 50%;
}
#overlay{
    position: absolute;
    top: 0;
    z-index: 10;
};
</style>