<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field label="Search" v-model="searchQuery" outlined dense hide-details="auto"
                    background-color="white" color="primary" clearable prepend-inner-icon="oomph-search"
                    @click:clear="clearSearchQuery()"></v-text-field>
            </div>
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important"
                class="ml-2 white grey--text text--darken-2" @click="refreshList">
                <v-icon>oomph-available-updates</v-icon>
            </v-btn>
            <div class="ml-2">
                <v-menu offset-y class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important"
                            class="white grey--text text--darken-2" v-bind="attrs"
                            v-on="on"><v-icon>oomph-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto"
                                v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false"
                                :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <v-row>
            <v-col cols="12">
                <v-btn-toggle dense multiple v-model="filter.itemEncodeStatus"
                    class="mt-4 appbackground d-flex align-center flex-wrap">
                    <v-btn value="ready" active-class="success white--text" depressed><v-icon
                            class="mr-2">oomph-ok</v-icon>Ready</v-btn>
                    <v-btn value="processing" active-class="warning white--text" depressed><v-icon
                            class="mr-2">oomph-box-important</v-icon>Processing</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row class="pa-3 mt-1">
            <!-- TABLE BASED LIST -->
            <v-data-table :item-class="itemRowBackground" sortable dense class="" style="width: 100%"
                :headers="computedHeaders" :items="computedQuizzes" :server-items-length="itemsTotal"
                :options.sync="datatableOptions">
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span>
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />

                        <!-- <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="Permanently Delete" value="destroy" small>
                                <v-icon class="error--text">oomph-delete-trash</v-icon>
                            </v-btn>
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                            <v-btn title="Undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle> -->
                    </div>
                </template>
                <template v-slot:[`item.itemEncodeStatus`]="{ item }">
                    <v-icon v-if="item.itemEncodeStatus === 'ready'"
                        class="font-weight-bold success--text">oomph-ok</v-icon>
                    <v-icon v-else class="font-weight-bold warning--text">oomph-box-important</v-icon>
                </template>
                <template v-slot:[`item.itemLengthMins`]="{ item }">
                    <span v-if="item.itemLengthMins !== ''">{{ Number(item.itemLengthMins).toFixed() }}</span>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.createdDateTime`]="{ item }">
                    <span v-if="item.createdDateTime !== ''">{{ MIX_formatDateTime(item.createdDateTime, 'x', 'DD-MMM-YY |
                                            HH: mm') }}</span>
                    <span v-else>-</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <!-- <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyQuiz(item.id)">oomph-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteQuiz(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreQuiz(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon> -->
                    <v-icon class="primary--text ml-5" @click="getVideoAssetInfo(item)">oomph-reboot</v-icon>
                    <v-icon class="primary--text ml-5" @click="openVideo(item)">oomph-tv-show</v-icon>
                </template>
            </v-data-table>
        </v-row>
        <v-dialog persistant v-model="dialogVideoPreview" max-width="1024px" class="primary">
            <mux-video :key="muxVideoKey" class="primary" style="max-width: 1024px !important;" v-if="!videoLoading"
                :playback-id="video.playbackId" :metadata-video-title="video.title" :poster="video.poster"
                stream-type="on-demand" preload prefer-mse controls></mux-video>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    // * PROPS
    props: {
        refreshlist: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        videoLoading: false,
        muxVideoKey: 0,
        videos: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 14,
        filter: {
            itemEncodeStatus: [],
        },
        // Toggles
        toggleFeatures: [],
        dialogVideoPreview: false,
        selectedVideo: {},
        video: {
            title: '',
            poster: '',
            playbackId: '',
            // envKey: process.env.VUE_APP_MUX,
        },
        API_URL: process.env.VUE_APP_OOMPHSERVER,
        // Headers to display in the Data Table

        headers: [
            { text: 'id', value: 'id', sortable: false, hidden: true, expanded: false },
            { text: 'Name', value: 'itemName', sortable: false, hidden: false, expanded: false },
            { text: 'ContentId', value: 'itemContentId', sortable: false, hidden: true, expanded: false },
            { text: 'AssetId', value: 'itemAssetId', sortable: false, hidden: true, expanded: false },
            { text: 'Upload Id', value: 'itemUploadId', sortable: false, hidden: true, expanded: false },

            { text: 'Type', value: 'itemType', sortable: false, hidden: false, expanded: false },
            { text: 'Marker', value: 'itemMarker', sortable: false, hidden: false, expanded: false },
            { text: 'File Size', value: 'itemFileSizeKb', sortable: false, hidden: false, expanded: false },
            { text: 'Duration (Mins)', value: 'itemLengthMins', align: 'center', sortable: false, hidden: false, expanded: false },

            { text: 'Encode Status', value: 'itemEncodeStatus', align: 'center', sortable: false, hidden: false, expanded: false },
            { text: 'Status', value: 'itemStatus', sortable: false, hidden: true, expanded: false },

            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: false, expanded: false },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Created Name', value: 'createdUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Name', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Name', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Email', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', width: '120px', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        sortBy: '@createdDateTime',
        datatableOptions: {
            page: 1,
            itemsPerPage: 15,
            sortBy: ['createdDateTime'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
    }),
    computed: {
        // Computed Search Query
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '* @itemType:Video';
                } else {
                    searchQuery = '@deleted:0 @itemType:Video';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}* @itemType:Video`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0 @itemType:Video`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}* @itemType:Video`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0 @itemType:Video`;
                    }
                }
            }
            if (this.filter.itemEncodeStatus !== null && this.filter.itemEncodeStatus.length > 0) {
                searchQuery = `${searchQuery} @itemEncodeStatus:{${this.filter.itemEncodeStatus.join('|')}}`;
            }
            //console.log(searchQuery)
            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (this.listshrunk) {
                headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            } else {
                headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            }
            return headers;
        },
        // Computed Quizzes
        computedQuizzes() {
            var videos = this.videos;
            return videos;
        },
        // Computed Fields
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_firebase_userAccess: 'firebase_auth_store/GET_firebase_userAccess',
            GET_FIREBASE_apiHeaderToken: 'firebase_auth_store/GET_FIREBASE_apiHeaderToken',
        }),
        apiOptions() {
            return {
                headers: {
                    Authorization: `Bearer ${this.GET_FIREBASE_apiHeaderToken}`,
                },
            };
        },
    },
    // * METHODS
    methods: {
        // Get Video Processing Status
        async getVideoAssetInfo(item) {
            let t = this;
            let videoResult = await t.MIX_readItem(item.id, 'contentItem');
            if (videoResult.code === 1) {
                let video = videoResult.data;
                const url = `${t.API_URL}/video/assets/${video.itemAssetId}`;
                return await t.$axios.get(url, this.apiOptions).then(async function (response) {
                    //console.log('' + JSON.stringify(response.data, null, 2));
                    if (response.data.data.status === 'ready') {
                        video.itemLengthMins = (Number(response.data.data.duration) / 60).toString();
                        video.itemPlaybackId = response.data.data['playback_ids'][0].id;
                        video.itemEncodeStatus = response.data.data.status;
                        video.itemURL = `https://stream.mux.com/${video.itemPlaybackId}.m3u8`;
                        await t.MIX_createItem(video, 'contentItem', false);
                        t.getVideos();
                    } else {
                        // do not update
                    }
                });
            }
        },
        // Refresh the List
        refreshList: function () {
            this.getVideos();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // Open
        async openVideo(item) {
            this.videoLoading = true;
            let videoResult = await this.MIX_readItem(item.id, 'contentItem');
            if (videoResult.code === 1) {
                this.selectedVideo = videoResult.data;
                //console.log(this.selectedVideo)
                this.video.playbackId = this.selectedVideo.itemPlaybackId;
                this.video.title = this.selectedVideo.itemName;
                this.video.poster = `https://image.mux.com/${this.selectedVideo.itemPlaybackId}/thumbnail.jpg`;
            }
            this.dialogVideoPreview = true;
            this.videoLoading = false;
        },
        // Get Videos
        async getVideos() {
            // if (this.searchQuery.length !== 1) {
            let videosResult = await this.MIX_redisAggregateSearch('idx:contentItems', this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, true);
            //console.log(videosResult.data)
            if (videosResult.code === 1) {
                this.itemsTotal = videosResult.data.total;
                this.videos = videosResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            }
            // }
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
    },
    // * WATCH
    watch: {
        // Watch Refresh List
        refreshlist() {
            this.refreshList();
        },
        // Watch Computed Search Query
        computedSearchQuery() {
            this.itemsFrom = 0;
            this.getVideos();
        },
        // Watch Computed Fields
        computedFields() {
            this.getVideos();
        },
        // Watch Data Table Options
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getVideos();
            },
            deep: true,
        },
        dialogVideoPreview: {
            handler() {
                let t = this;
                if (!t.dialogVideoPreview) {
                    t.$nextTick(() => {
                        t.video.playbackId = '';
                        t.video.title = '';
                        t.video.poster = '';
                        t.muxVideoKey++
                    });
                }
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        this.getVideos();
    },
};
</script>
