<template>
    <v-row no-gutters class="">
        <v-file-input dense outlined show-size counter v-bind:accept="accept" v-bind:label="label" v-model="file" :rules="rules"></v-file-input>
        <v-btn class="elevation-0" block v-bind:class="buttonClass" :loading="loading" :disabled="loading || file === null ||  Math.ceil(value) === 100" @click="uploadFile()">UPLOAD</v-btn>
        <v-col cols="12" class="py-3">
            <v-progress-linear indeterminate color="primary" v-model="value" :active="show" v-bind:class="progressClass">
                <strong v-if=" Math.ceil(value) !== 100">{{ Math.ceil(value) }}%</strong>
                <div v-if=" Math.ceil(value) === 100" class="success--text text-h7 font-weight-bold">File Uploaded Successfully
                    <v-icon class="success--text">oomph-done</v-icon>
                </div>
            </v-progress-linear>
        </v-col>
    </v-row>
</template>

<script>
import { getAuth, updateProfile } from 'firebase/auth';
import imageCompression from 'browser-image-compression';

export default {
    name: 'FileUpload',
    props: {
        accept: String,
        buttonClass: {
            type: String,
            default: () => {
                return 'primary';
            },
        },
        compressionMaxSizeMB: Number,
        compressionmaxWidthOrHeight: Number,
        fileName: String,
        folderLocation: String,
        isProfile: Boolean,
        label: String,
        private: Boolean,
        progressClass: String,
        selectedItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data: () => ({
        file: null,
        loading: false,
        loader: null,
        rules: [(value) => !value || value.size < 100000000 || 'Image size should be less than 10 MB!'],
        show: false,
        value: 0,
    }),
    methods: {
        async uploadFile() {
            let t = this;
            t.loading = true;
            if (t.file.type !== 'application/pdf') {
                //* For images
                const options = {
                    maxSizeMB: t.compressionMaxSizeMB,
                    maxWidthOrHeight: t.compressionmaxWidthOrHeight,
                    useWebWorker: true,
                };
                //* Compresses the image realtime
                const compressedFile = await imageCompression(t.file, options);
                //* Storage location in firebase
                const upload =
                    t.private === true
                        ? t.$firebase.privateStorage.ref(t.folderLocation + '/' + t.selectedItem.id + '/' + t.fileName + '.jpg').put(compressedFile)
                        : t.$firebase.publicStorage.ref(t.folderLocation + '/' + t.selectedItem.id + '/' + t.fileName + '.jpg').put(compressedFile);
                upload.on(
                    'state_changed',
                    (snapshot) => {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        t.value = progress;
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                t.loading = false;
                                break;
                        }
                    },
                    (error) => {
                        console.error(error);
                    },
                    () => {
                        if (t.isProfile === true) {
                            upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                const auth = getAuth();
                                updateProfile(auth.currentUser, {
                                    photoURL: downloadURL,
                                })
                                    .then(() => {
                                        t.$firebase.db.collection('users').doc(t.selectedItem.id).update({
                                            photoURL: downloadURL,
                                        });
                                        t.$emit('update');
                                        t.loading = false;
                                    })
                                    .catch((error) => {
                                        t.loading = false;
                                        console.error(error);
                                    });
                            });
                        } else {
                            upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                console.log("downloadUrl = " + downloadURL)
                                console.log("file = " + JSON.stringify(compressedFile, null, 2))
                                t.$emit('update', downloadURL, compressedFile);
                            });
                        }
                    }
                );
            } else {
                //* PDF Files
                //* Storage location in firebase
                const upload =
                    t.private === true
                        ? t.$firebase.privateStorage.ref(t.folderLocation + '/' + t.selectedItem.id + '/' + t.fileName + '.pdf').put(t.file)
                        : t.$firebase.publicStorage.ref(t.folderLocation + '/' + t.selectedItem.id + '/' + t.fileName + '.pdf').put(t.file);
                upload.on(
                    'state_changed',
                    (snapshot) => {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        t.value = progress;
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        console.error(error);
                        t.loading = false;
                    },
                    () => {
                        if (t.isProfile === false) {
                            upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                console.log("downloadUrl = " + downloadURL)
                                console.log("file = " + t.file)
                                t.$emit('update', downloadURL, t.file);
                                t.loading = false;
                            });
                        }
                    }
                )
            }
        },
    },
};
</script>