const firebase_auth_store = {
    namespaced: true,
    state: {
        userAuth: null,
        userAccessToken: null,
        userAccess: null,
        apiHeaderToken: null
    },
    mutations:{
        MUT_FIREBASE_userAuth (state, payload) { state.userAuth = payload },
        MUT_FIREBASE_userAccessToken (state, payload) { state.userAccessToken = payload },
        MUT_FIREBASE_userAccess(state, payload) { state.userAccess = payload },
        MUT_FIREBASE_apiHeaderToken(state, payload) { state.apiHeaderToken = payload },

    },
    actions: {
        ACT_FIREBASE_userAuth ( { commit }, payload) { commit('MUT_FIREBASE_userAuth', payload) },
        ACT_FIREBASE_userAccessToken ( { commit }, payload) { commit('MUT_FIREBASE_userAccessToken', payload) },
        ACT_FIREBASE_userAccess({ commit }, payload) { commit('MUT_FIREBASE_userAccess', payload) },
        ACT_FIREBASE_apiHeaderToken({ commit }, payload) { commit('MUT_FIREBASE_apiHeaderToken', payload) },
    },
    getters:{
        GET_FIREBASE_userAuth (state) { return state.userAuth },
        GET_FIREBASE_userAccessToken (state) { return state.userAccessToken },
        GET_FIREBASE_userAccess(state) { return state.userAccess },
        GET_FIREBASE_apiHeaderToken(state) {return state.apiHeaderToken},
    },
};
export default firebase_auth_store;
