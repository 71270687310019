<template>
    <div>
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field 
                    label="Search People" 
                    v-model="searchQuery" 
                    outlined 
                    dense 
                    hide-details="auto" 
                    background-color="white" 
                    color="primary" 
                    clearable 
                    prepend-inner-icon="oomph-search" 
                    @click:clear="clearSearchQuery()">
                </v-text-field>
            </div>
            <!-- TODO - RG - Test Filter by Tags -->
            <!-- Filter Tags -->
            <!-- <div v-if="!listshrunk && listexpanded" class="ml-2">
                <div><v-autocomplete multiple outlined auto-select-first v-model="filter.tags" label="Filter by Tags" :search-input.sync="tagsAutocomplete" @change="tagsAutocomplete = ''" :items="tags" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('tags', [])"></v-autocomplete></div>
            </div> -->
            <!-- Filter Organisations -->
            <div v-if="!listshrunk && orgid === '' && listexpanded" class="ml-2">
                <div><v-autocomplete outlined auto-select-first v-model="filter.orgId" label="Filter by Organisation" :items="organisations" item-text="orgName" item-value="id" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('orgId', '')"></v-autocomplete></div>
            </div>
            <!-- Filter Job Level -->
            <div v-if="!listshrunk && listexpanded" class="ml-2">
                <div><v-autocomplete multiple outlined auto-select-first v-model="filter.jobLevel" label="Filter by Job Levels" :search-input.sync="jobLevelAutocomplete" @change="jobLevelAutocomplete = ''" :items="jobLevels" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('jobLevel', [])"></v-autocomplete></div>
            </div>
            <!-- Filter Job Department -->
            <div v-if="!listshrunk && listexpanded" class="ml-2">
                <div><v-autocomplete multiple outlined auto-select-first v-model="filter.jobDepartment" label="Filter by Department" :search-input.sync="jobDepartmentAutocomplete" @change="jobDepartmentAutocomplete = ''" :items="jobDepartments" dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('jobDepartment', [])"></v-autocomplete></div>
            </div>
			<!-- Refresh Button -->
            <div v-if="!listshrunk" class="ml-2">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="refreshItems">
                    <v-icon>oomph-available-updates</v-icon>
                </v-btn>
            </div>
            <!-- Column Selector -->
            <div class="ml-2">
                <v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>oomph-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <!-- Alphabet Search -->
        <div class="my-2" v-if="orgid === '' && !listshrunk">
            <!-- // loop through each letter of the alphabet and create a button -->
            <v-btn-toggle v-model="filter.alpha" class="appbackground d-flex align-center flex-wrap">
                <v-btn v-for="letter in alphabet" :key="letter" :value="letter" class="my-1 mr-1" color="white" depressed small>{{ letter }}</v-btn>
                <v-btn value="" class="my-1" depressed small>All</v-btn>
            </v-btn-toggle>
        </div>
        <div class="mt-2">
            <!-- TABLE BASED LIST -->
            <v-data-table v-if="!listshrunk" sortable dense class="" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions"
                :loading="loading" loading-text="Loading People" >
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <!-- TODO - HIGH - SECURITY - Hide/Show Buttons -->
                        <!-- 
							Show Delete / Restore Button for Staff-Directors / StaffManagers / StaffAdmin / DeveloperAdmin
							Show Destroy Option Button DeveloperAdmin Only
						-->
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="Permanently Delete" value="destroy" small>
                                <v-icon class="error--text">oomph-delete-trash</v-icon>
                            </v-btn>
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                            <v-btn title="Undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">oomph-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>
                </template>
            </v-data-table>
            <!-- CARD BASED LIST -->
            <div v-if="listshrunk" style="height: 67vh" class="">
                <v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
                    <div class="d-flex align-center ml-1">
                        <div class="ml-3">
                            <div class="body-2 font-weight-bold" style="width: 180px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.orgName }}</div>
                            <!-- <span class="body-2">{{ item.productCode }}</span
							><br />
							<span class="body-2">{{ item.supplierName }}</span
							><br /> -->
                        </div>
                        <v-spacer></v-spacer>
                        <div class="text-right mr-3">
                            <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>
                        </div>
                    </div>
                </v-card>
            </div>
            <!-- LOAD MORE CARDS -->
            <v-btn v-if="listshrunk" class="mt-2 grey lighten-3" depressed block>Load More</v-btn>
        </div>
        <!-- <pre>{{ filter }}</pre> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "ContactList",
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: false,
        },
        orgType: {
            type: String,
            default: '',
        },
        orgid: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        key: 'contact',
        index: 'idx:contacts',
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', value: 'id', sortable: false, hidden: true, expanded: true },
            { text: 'Title', value: 'contactTitle', sortable: false, hidden: true, expanded: true },
            { text: 'Full Name', value: 'contactFullName', sortable: false, hidden: false, expanded: false },
            { text: 'First Name', value: 'contactFirstName', sortable: false, hidden: false, expanded: false },
            { text: 'Last Name', value: 'contactLastName', sortable: false, hidden: false, expanded: false },
            { text: 'Email', value: 'contactEmail', sortable: false, hidden: true, expanded: false },
            { text: 'Secondary Email', value: 'contactAlternateEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Marketing Preferences', value: 'contactMarketingOptOut', sortable: false, hidden: true, expanded: true },
            { text: 'Phone', value: 'contactMobilePhone', sortable: false, hidden: false, expanded: false },
            { text: 'Other Phone', value: 'contactDDIPhone', sortable: false, hidden: true, expanded: true },
            { text: 'Job Title', value: 'contactJobTitle', sortable: false, hidden: false, expanded: false },
            { text: 'Job Level', value: 'contactJobLevel', sortable: false, hidden: false, expanded: true },
            { text: 'Department', value: 'contactJobDepartment', sortable: false, hidden: false, expanded: true },

            { text: 'Address Type', value: 'contactAddressType', sortable: false, hidden: true, expanded: true },
            { text: 'Address Line 1', value: 'contactAddressLine1', sortable: false, hidden: true, expanded: true },
            { text: 'Address Line 2', value: 'contactAddressLine2', sortable: false, hidden: true, expanded: true },
            { text: 'Address Line 3', value: 'contactAddressLine3', sortable: false, hidden: true, expanded: true },
            { text: 'Town', value: 'contactTown', sortable: false, hidden: true, expanded: true },
            { text: 'County', value: 'contactCounty', sortable: false, hidden: true, expanded: true },
            { text: 'Postcode', value: 'contactPostcode', sortable: false, hidden: true, expanded: true },

            { text: 'Employees', value: 'contactLeadStatus', sortable: false, hidden: true, expanded: true },
            { text: 'Industry', value: 'contactSource', sortable: false, hidden: true, expanded: true },
            { text: 'Organisation Id', value: 'orgId', sortable: false, hidden: true, expanded: true },
            { text: 'Organisation', value: 'orgName', sortable: false, hidden: false, expanded: false },
            { text: 'Reporting To', value: 'contactReportingTo', sortable: false, hidden: true, expanded: true },

            { text: 'Twitter', value: 'contactTwitter', sortable: false, hidden: true, expanded: true },
            { text: 'Facebook', value: 'contactFacebook', sortable: false, hidden: true, expanded: true },
            { text: 'Linked In', value: 'contactLinkedIn', sortable: false, hidden: true, expanded: true },
            { text: 'Instagram', value: 'contactInstagram', sortable: false, hidden: true, expanded: true },
            { text: 'Instagram', value: 'contactInstagram', sortable: false, hidden: true, expanded: true },

            { text: 'Date Time (C)', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'User ID (C)', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'User Name (C)', value: 'createdUserName', sortable: false, hidden: true, expanded: true },
            { text: 'User Email (C)', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Date Time (M)', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'User ID (M)', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'User Name (M)', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'User Email (M)', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Date Time (D)', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'User ID (D)', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'User Name (D)', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'User Email (D)', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Action', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false, width: '155px' },
        ],
        loading: true,
        // Fields to Retrieve from Database
        //fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@contactFirstName',
        datatableOptions: {
            page: 1,
            itemsPerPage: 15,
            sortBy: ['orgName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        // productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
        // Dropdowns
        jobLevels: [],
        jobDepartments: [],
        organisations: [],
        tags: [],
        filter: {
            productCategories: [],
            alpha: '',
            jobLevel: [],
            jobDepartment: [],
            orgId: '',
            tags: [],
        },
		jobLevelAutocomplete: '',
		jobDepartmentAutocomplete: '',
		tagsAutocomplete: ''
    }),
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
            GET_dense: 'GET_dense',
            GET_destroy: 'GET_destroy',
        }),
        alphaOptions() {
            let alphaOptions = [];
            for (let i = 0; i < this.alphabet.length; i++) {
                alphaOptions.push(this.filter.alpha + this.alphabet[i] + '*');
            }
            return alphaOptions;
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:0';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }
            // Props Filter
            if (this.orgid !== '' && this.orgid !== null && this.orgid !== undefined) {
                searchQuery = `${searchQuery}  @orgId:{${this.orgid}}`;
            }
            // Alphabet Filter
            if (this.filter.alpha !== '' && this.filter.alpha !== null && this.filter.alpha !== undefined) {
                let alphaOptions = this.alphaOptions.join(',');
                alphaOptions = alphaOptions.replace(/,/g, '|');
                searchQuery = `${searchQuery}  @contactFirstName:${alphaOptions}`;
            }
            // Job Levels Filter
            if (JSON.stringify(this.filter.jobLevel) !== '[]') {
                let jobLevels = this.filter.jobLevel.join(',');
                jobLevels = jobLevels.replace(/,/g, '|');
                searchQuery = `${searchQuery}  @contactJobLevel:{${jobLevels}}`;
            }
            // Job Department Filter
            if (JSON.stringify(this.filter.jobDepartment) !== '[]') {
                let jobDepartments = this.filter.jobDepartment.join(',');
                jobDepartments = jobDepartments.replace(/,/g, '|');
                searchQuery = `${searchQuery}  @contactJobDepartment:{${jobDepartments}}`;
            }
            // Organisation Filter
            if (this.filter.orgId !== '') {
                searchQuery = `${searchQuery}  @orgId:{${this.filter.orgId}}`;
            }

            console.log(searchQuery);

            // Page Filters
            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expanded !== true); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedItems() {
            var items = this.items; //.filter(x => x.);
            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@orgId')) {
                computedFields.push('@orgId');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            //if (!computedFields.includes("@attSupplier")) { computedFields.push ("@attSupplier") }
            computedFields = computedFields.join(',');
            return computedFields;
        },
    },
    methods: {
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        clearSearchQuery() {
            this.searchQuery = ''
        },
        // Refresh Items
        refreshItems() {
            this.getItems();
        },
        // * GET ITEMS
        async getItems() {
            this.loading = true
            // if (this.searchQuery.length !== 1) {
                let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
                if (itemsResult.code === 1) {
                    this.itemsTotal = itemsResult.data.total;
                    this.items = itemsResult.data.results;
                    let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                    if (itemsTo < this.itemsTotal) {
                        this.itemsTo = itemsTo;
                    } else {
                        this.itemsTo = this.itemsTotal;
                    }
                }
            // }
            this.loading = false;
        },
		// * Clear Tag Filter
		clearFilter(key, value) {
			setTimeout(() => {
				this.filter[key] = value;
			}, 1);
		},		
    },
    watch: {
        filter: {
            handler: function () {
                this.getItems();
            },
            deep: true,
        },
        refreshitems: function () {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            //// console.log('imagesIndex = ' + imagesIndex)
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                //// console.log('organisationFrom=' + this.organisationFrom)
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        let t = this;
        t.loading = true
		// Get Organisations
        t.organisations = await t.MIX_organisations();
        // Job Levels
        let jobLevels = await t.MIX_redisReadSet('set:jobLevels', false);
        if (jobLevels.code === 1) {
            t.jobLevels = jobLevels.data;
        }
        // Departments
        let jobDepartments = await t.MIX_redisReadSet('set:jobDepartments', false);
        if (jobDepartments.code === 1) {
            t.jobDepartments = jobDepartments.data;
        }
        this.getItems();
    },
};
</script>
