<template>
	<v-row class="pa-0 ma-0" fill-height>
        <v-row no-gutters class="pb-3 px-3 pt-3 primary darken-2">
            <v-col class="font-weight-bold white--text text-h6 d-flex align-center" v-if="JSON.stringify(item) !== '{}'">
                <div class="mx-3 mb-1"><v-icon class="white--text">oomph-bill</v-icon></div>
				<div>
					<span class="font-weight-light"><strong v-if="item.type && item.type !== ''">{{ item.type }}</strong><strong> Ticket: </strong>{{ item.subject }}</span>
				</div>
				<div class="ml-2 font-weight-light" v-if="item.subject === ''">New</div>
				<v-spacer></v-spacer>
				<div>
					<v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
						<v-icon>oomph-multiply</v-icon>
					</v-btn>
				</div>
            </v-col>
        </v-row>
        <!-- <pre>{{ selectedOwner }}</pre> -->
		<v-col class="pa-0" fill-height cols="auto" style="width: 100% !important">
            <v-form ref="form" class="pa-0 ma-0">
                <v-row height="170" class="d-flex justify-start align-center pr-2 py-3">
                    <v-col cols="4" class="pl-6 pr-0">
                        <v-autocomplete 
                            label="Assigned To" 
                            v-model="selectedOwner" 
                            :items="users" 
                            :item-text="(item) => item.ownerUserName" 
                            :rules="rules.orgOwner" 
                            auto-select-first 
                            return-object 
                            outlined 
                            dense 
                            hide-details="auto" 
                            background-color="white" 
                            color="primary" 
                            clearable 
                            @change="changingItem()"
                            @click:clear="clearSelectedOwner()"> 
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="4" class="pr-0">
                        <v-select 
                            label="Ticket Type" 
                            v-model="item.type" 
                            :items="ticketTypes" 
                            outlined 
                            dense 
                            hide-details="auto" 
                            background-color="white" 
                            color="primary" 
                            clearable 
                            style="z-index: 1000 !important"
                            @click:clear="clearSelectedAccountTypes()" 
                            @change="changingItem()"
                            :menu-props="{ top: false, offsetY: true }" 
                            :attach="true" 
                            autocomplete="donotautocomplete">
                        </v-select>
                    </v-col>
                    <v-col cols="4" class="pr-4">
                        <v-select 
                            label="Ticket Status" 
                            v-model="item.status" 
                            :rules="rules.orgAccountType" 
                            :items="ticketStatus" 
                            outlined 
                            dense 
                            hide-details="auto" 
                            background-color="white" 
                            color="primary" 
                            clearable 
                            style="z-index: 1000 !important"
                            @click:clear="clearSelectedAccountTypes()" 
                            @change="changingItem()"
                            :menu-props="{ top: false, offsetY: true }" 
                            :attach="true" 
                            autocomplete="donotautocomplete">
                        </v-select>
                    </v-col>
                <!-- <v-btn @click="createItem(item)" class="elevation-0 mr-3">Save Status</v-btn> -->
                </v-row>
            </v-form>
            <!-- <pre>{{ item }}</pre> -->
			<v-tabs-items v-model="tab" fill-height>
				<v-tab-item value="messages" class="grey lighten-4">
					<ticket-messages :activeTicket="item" v-on:save="createItem" />
				</v-tab-item>
			</v-tabs-items>
		</v-col>
	</v-row>
</template>

<script>
	import ticketMessages from "@/views/tickets/TicketMessages.vue";
	import schema from "@/schema.js";
	export default {
		name: "Ticket",
		components: {
			"ticket-messages": ticketMessages,
		},
		// * PROPS
		props: {
			datadown: {
				type: Object,
				default: function () {
					return {};
				},
			},
		},
		// * DATA
		data: () => ({
			key: "ticket",
			tab: "messages",
			schema: null,
			// Item
			item: {},
			itemNew: false,
			editMode: false,

			// Validation
			rules: {},

            ticketStatus: ['Open', 'Closed', 'On Hold', 'Pending', 'Solved', 'Archived'],

            ticketTypes: ['Question', 'Feedback', 'Issue', 'Feature Request', 'Content Request'],


            // Form items
            selectedOwner: {
                ownerUserId: "",
                ownerUserName: "",
                ownerUserEmail: ""
            },
            users: [],


		}),
		// * COMPUTED
		computed: {
			computedFullName() {
				return this.item.contactFirstName + " " + this.item.contactLastName;
			},
		},
		// * METHODS
		methods: {
			// * INITIALISE ITEM
			async initialiseItem() {
				let t = this;
				if (JSON.stringify(t.datadown) !== "{}") {
					await this.readItem(t.datadown.id);

                    if (this.item.ownerUserId !== '') {
                        this.selectedOwner = {
                            ownerUserId: this.item.ownerUserId,
                            ownerUserName: this.item.ownerUserName,
                            ownerUserEmail: this.item.ownerUserEmail,
                        }
                    } else {
                        this.selectedOwner = { ownerUserId: '', ownerUserName: '', ownerUserEmail: '' };
                    }
				} else {
					t.itemNew = true;
					t.editMode = true;
					t.item = { ...t.schema };
					t.item.id = t.MIX_generateId();
				}
			},
			// * VALIDATION RULES
			// TODO - RG - I think this needs to be moved to contactDetails.vue
			validateItem() {
				let t = this;
				t.rules = {
					// GENERAL DETAILS
					orgName: [(v) => !!v || "Organisation Name Required"],
				};
				t.$nextTick(() => {
					if (t.$refs.form.validate()) {
						this.createItem();
						this.editMode = false;
					} else {
						this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
					}
				});
			},
			// * CREATE ITEM
			async createItem(item) {
				let t = this;            
				let result = await t.MIX_createItem(item, t.key, t.itemNew);
				if (result.code === 1) {
					t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
					t.readItem(item.id);
					t.$emit("refreshitems");
				}
			},
			// * READ ITEM
			async readItem(itemId) {
				let t = this;
				let result = await t.MIX_readItem(itemId, t.key);
				if (result.code === 1) {
					this.item = result.data;
					t.itemNew = false;
					t.loading = false;
				}
                return
			},
			// * DESTROY ITEM
			async destroyItem() {
				let result = await this.MIX_destroyItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * DELETE ITEM
			async deleteItem() {
				let result = await this.MIX_deleteItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// * RESTORE ITEM
			async restoreItem() {
				let result = await this.MIX_restoreItem(this.item.id, this.key);
				if (result.code === 1) {
					this.readItem(this.item.id);
				}
			},
			// Close Item
			closeItem() {
				this.$emit("closeitem");
			},
			cancelItem() {
				this.readItem(this.item.id);
			},
            clearSelectedOwner() {
                setTimeout(() => {
                    this.selectedOwner = { ownerUserId: '', ownerUserName: '', ownerUserEmail: '' };
                }, 1);
            },
            changingItem() {
                setTimeout(() => {
                //console.log("item changing")
                    this.createItem(this.item)
                }, 1)
            }
		},
		// * WATCH
		watch: {
			// Datadown Changes
			datadown() {
				this.initialiseItem();
			},
			computedOrgTags(val) {
				var t = this;
				var splitVal = [];
				splitVal = t.item.orgTags.split(",");

				// //console.log(JSON.stringify(val, null, 2));
				return splitVal;
			},
            selectedOwner: {
                handler: function () {
                    //console.log('changing owner = ' + JSON.stringify(this.selectedOwner, null, 2))
                    this.item.ownerUserId = this.selectedOwner.ownerUserId
                    this.item.ownerUserName = this.selectedOwner.ownerUserName
                    this.item.ownerUserEmail = this.selectedOwner.ownerUserEmail
                }
            },
		},
		// * CREATED
		async created() {
			let t = this;
			t.loading = true;
			t.schema = schema.contract;
			t.initialiseItem();

			let accountTypes = await t.MIX_redisReadSet("set:orgAccountTypes", false);
			if (accountTypes.code === 1) {
				t.accountTypes = accountTypes.data;
			}

			let addressTypes = await t.MIX_redisReadSortedSet("sset:addressTypes", 0, 9999);
			if (addressTypes.code === 1) {
				t.addressTypes = addressTypes.data;
			}

			let industries = await t.MIX_redisReadSet("set:industries", true);
			if (industries.code === 1) {
				t.industries = industries.data;
			}

            // Get Users for Contract Owners Dropdown
            let users = await this.MIX_users("Sales,Developers,Administration");
            this.users = users.map((x) => {
                return {
                    ownerUserId: x.id,
                    ownerUserName: x.userName,
                    ownerUserEmail: x.userEmail,
                };
            });
            this.users.sort((a, b) => a.ownerUserName.localeCompare(b.ownerUserName));
		},
		// * MOUNTED
		async mounted() {},
	};
</script>

<style scoped>
	.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
	.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
		height: 22px;
	}
</style>
