<template>
    <v-row class="pa-0 ma-0" fill-height>
        <v-row no-gutters class="pb-3 px-3 pt-3" :class="item.orgAccountOnHold === '1' ? 'warning' : 'primary darken-2'">
            <v-col class="font-weight-bold white--text text-h6 d-flex align-center" v-if="JSON.stringify(item) !== '{}'">
                <div class="mx-3 mb-1"><v-icon class="white--text">oomph-city</v-icon></div>
                <div v-if="item.orgName.length > 40">
                    <v-tooltip top :max-width="200">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="tooltiptext tooltip font-weight-light"
                                ><strong>{{ orgtype }}:</strong>{{ item.orgName.substring(0, 40) }}...</span
                            >
                        </template>
                        <span>{{ item.orgName }}</span>
                    </v-tooltip>
                </div>
                <div v-else-if="item.orgName.length <= 40 && item.orgName !== ''">
                    <span class="font-weight-normal"><strong v-if="orgtype">{{ orgtype }}: </strong>{{ item.orgName }}</span >
                </div>
                <div class="ml-2 font-weight-light" v-if="item.orgName === ''">New {{ orgtype }}</div>

                <v-spacer></v-spacer>
				<div class="ml-2" v-if="item.orgAccountOnHold === '1'">ON HOLD</div>
                <div>
                    <v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
                        <v-icon>oomph-multiply</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <!-- Tabs -->
        <v-col class="pa-0" cols="12" style="width: 100% !important">
            <v-tabs background-color="white" v-model="tab" show-arrows>
                <v-tab href="#details">Details</v-tab>
                <v-tab v-if="showGroupTab === true" href="#group">Group Structure</v-tab>
                <v-tab v-if="item.orgGroupStructure === 'Group'" href="#homes">Homes</v-tab>
                <v-tab v-if="item.orgGroupStructure === 'Parent Group'" href="#groups">Groups</v-tab>
                <v-tab v-if="item.orgGroupStructure === 'Group'" href="#regions">Regions</v-tab>
                <v-tab :disabled="itemNew" href="#notes">Notes</v-tab>
                <v-tab v-if="item.orgAccountTypes.includes('Partner')" :disabled="itemNew" href="#bio">Bio</v-tab>
                <v-tab :disabled="itemNew" href="#contacts">Contacts</v-tab>
                <v-tab v-if="item.orgAccountTypes.includes('Customer') || item.orgAccountTypes.includes('Partner')" :disabled="itemNew" href="#users">Users</v-tab>
                <v-tab href="#access">Access</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" fill-height>
                <!-- Details Tab -->
                <v-tab-item value="details" class="grey lighten-4">
                    <organisation-details dense :datadown="item" v-on:showGroupTab="groupTab" :itemnew="itemNew" v-on:save="saveItem" v-on:cancel="cancelItem" :listshrunk="listshrunk" :orgtype="orgtype" />
                </v-tab-item>
                <!-- Group Tab -->
                <v-tab-item value="group" class="grey lighten-4" fill-height>
                    <group :datadown="item" :itemnew="itemNew" :listshrunk="listshrunk" v-on:save="saveItem"/>
                </v-tab-item>
                <!-- Homes Tab -->
                <v-tab-item value="homes" class="grey lighten-4" fill-height>
                    <organisation-homes :datadown="item" :itemnew="itemNew" :listshrunk="listshrunk" v-on:save="saveItem"/>
                </v-tab-item>
                <!-- Organisation Groups Tab -->
                <v-tab-item value="groups" class="grey lighten-4" fill-height>
                    <organisation-groups :datadown="item" :itemnew="itemNew" :listshrunk="listshrunk" v-on:save="saveItem"/>
                </v-tab-item>
                <!-- Regions Tab -->
                <v-tab-item value="regions" class="grey lighten-4" fill-height>
                    <organisation-regions :datadown="item" v-on:save="saveItem"/>
                </v-tab-item>
                <!-- Notes Tab -->
                <v-tab-item value="notes" class="grey lighten-4" fill-height>
                    <notes :org="item" :listshrunk="listshrunk" />
                </v-tab-item>
                <!-- Notes Tab -->
                <v-tab-item value="bio" class="grey lighten-4" fill-height>
                    <notes :datadown="item" :bio="true" :listshrunk="listshrunk" v-on:save="saveItem" />
                </v-tab-item>
                <!-- Contacts Tab -->
                <v-tab-item value="contacts" class="grey lighten-4">
                    <organisation-contacts :org="item" :listshrunk="listshrunk" />
                </v-tab-item>
                <!-- Users Tab -->
                <v-tab-item value="users" class="grey lighten-4">
                    <organisation-users :org="item" :listshrunk="listshrunk" />
                </v-tab-item>
                <!-- More Addresses Tab -->
                <v-tab-item value="addresses" class="grey lighten-4">
                    <organisation-addresses :datadown="item" :listshrunk="listshrunk" />
                </v-tab-item>
                <!-- Access Tab -->
                <v-tab-item value="access" class="grey lighten-4">
                    <organisation-access :datadown="item" :listshrunk="listshrunk" v-on:save="saveItem" />
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import FullLead from '@/views/sales/FullLead.vue';
import group from '@/views/organisations/OrganisationGroup.vue';
import notes from '@/views/base/Notes.vue';
import organisationAccess from '@/views/organisations/OrganisationAccess.vue';
import organisationActions from '@/views/organisations/OrganisationActions.vue';
import organisationAddresses from '@/views/organisations/OrganisationAddresses.vue';
import organisationContacts from '@/views/organisations/OrganisationContacts.vue';
import organisationDetails from '@/views/organisations/OrganisationDetails.vue';
import organisationGroups from '@/views/organisations/OrganisationGroups.vue'
import organisationHomes from '@/views/organisations/OrganisationHomes.vue'
import organisationLicences from '@/views/organisations/OrganisationLicences.vue'
import organisationRegions from '@/views/organisations/OrganisationRegions.vue'
import organisationUsers from '@/views/organisations/OrganisationUsers.vue';

import schema from '@/schema.js';
export default {
    components: {
        'FullLead': FullLead,
        'organisation-access': organisationAccess,
        'organisation-actions': organisationActions,
        'organisation-addresses': organisationAddresses,
        'organisation-contacts': organisationContacts,
        'organisation-details': organisationDetails,
        'organisation-groups': organisationGroups,
        'organisation-homes': organisationHomes,
        'organisation-licences': organisationLicences,
        'organisation-regions': organisationRegions,
        'organisation-users': organisationUsers,
        group: group,
        notes: notes,
    },
    props: {
        datadown: {
            type: Object,
            default: function () {
                return {};
            },
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        orgtype: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        activeFullLead: {},
        editMode: false,
        fullLeadDialog: false,
        item: {},
        itemNew: false,
        key: 'org',
        rules: {},
        schema: null,
        showGroupTab: false,
        tab: 'details',
    }),
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
        }),
    },
    methods: {
        initialiseItem() {
            let t = this;
            if (JSON.stringify(t.datadown) !== '{}') {
                this.readItem(t.datadown.id);
            } else {
                t.itemNew = true;
                t.editMode = true;
                t.item = { ...t.schema };
                t.item.id = t.MIX_generateId();
            }
        },
        validateItem() {
            let t = this;
            t.rules = {
                orgName: [(v) => !!v || 'Organisation Name Required'],
            };
        },
        groupTab(tab) {
            this.showGroupTab = tab;
        },
        async createItem(item) {
            let t = this;
            item.id = this.MIX_generateId();
            if (item.id.length < 20) {
                item.id = item.id + new Array(19 - item.id.length).fill('0').join('') + '1';
            }
            let result = await this.MIX_createItem(item, this.key, this.itemNew);
            if (result.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                this.readItem(item.id);
                t.$emit('refreshitems');
            }
        },
        fullAccountView() {
            this.$emit('fullAccount', this.item)
        },
        closeLeadDialog() {
            let t = this;
            t.fullLeadDialog = false
        },
        async readItem(itemId) {
            let t = this;
            let result = await t.MIX_readItem(itemId, t.key);
            if (result.code === 1) {
                this.item = result.data;
                t.itemNew = false;
                t.loading = false;
            }
        },
        async destroyItem() {
            let result = await this.MIX_destroyItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        async deleteItem() {
            let result = await this.MIX_deleteItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        async restoreItem() {
            let result = await this.MIX_restoreItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        async saveItem(item) {
            if (this.itemNew) {
                this.createItem(item);
            } else {
                let result = await this.MIX_createItem(item, this.key, this.itemNew);
                if (result.code === 1) {
                    this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
                    this.readItem(item.id);
                }
                this.$emit('refreshitems');
            }
        },
        closeItem() {
            this.$emit('closeitem');
        },
        cancelItem() {
            this.readItem(this.item.id);
        },
    },
    watch: {
        datadown() {
            this.initialiseItem();
        },
    },
    async created() {
        let t = this;
        t.loading = true;
        t.schema = schema.organisation;
        t.initialiseItem();
    },
};
</script>

<style scoped>
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 22px;
}
</style>
