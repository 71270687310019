<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <v-row class="pa-3" dense>
            <v-col cols="12" class="d-flex justify-end align-center">
                <!-- <div class="ml-2">This Quiz has <strong>{{ reviewquestion.questionTotal }}</strong> questions</div>-->
                <v-switch class="ml-2" dense inset v-model="reorder" :label="`Reorder`" ></v-switch> 
                <v-spacer />
                <v-btn depressed class="primary" @click="newQuestion()">Add Question</v-btn>
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col cols="12">computedSearchQuery: <pre>{{ computedSearchQuery }}</pre></v-col>
        </v-row> -->
        <v-row dense class="px-3">
            <v-col cols="12">
            <drop-list :items="questions" class="" @insert="onInsert" @reorder="onReorder">
                <template v-slot:item="{ item, index }">
                    <drag :data="item" :key="index">
                        <div cols="auto" class="mb-2 d-flex align-start" style="width: 100% !important">
                            <div class="mt-3 mr-2" v-if="reorder"><v-icon class="grey--text">oomph-move-grabber</v-icon></div>
                            <div class="" style="width: 100% !important;">                            
                                <div class="white pa-3 elevation-0 d-flex align-start dnd-no-drag default" style="width: 100% !important; border-radius: 3px !important;">
                                    <div class="dnd-no-drag">
                                        <div>{{ item.questionOrder }}. {{ item.questionTitle }}</div>
                                        <div class="d-flex align-center mt-2">
                                            <div v-for="(answer, index) in JSON.parse(item.answers)" :key="index">
                                                <div class="d-flex align-start mr-2" v-if="answer.imageId !== ''">
                                                    <div v-if="item.answerAllowABC === '1'">{{ alphabet[index] }}. <span v-if="item.answerAllowImagesText === '1'">{{ answer.text }}</span></div>
                                                </div>                                                    
                                                <div><v-chip v-if="answer.imageId === ''" label class="mt-2 mr-1" :class="answer.correct ? 'success' : 'grey lighten-2'">
                                                    <span class="mr-2" v-if="item.answerAllowABC === '1'">{{ alphabet[index] }}. </span>
                                                    <span v-if="answer.value !== ''">{{ answer.value }}</span>
                                                    <span v-if="answer.text !== '' && answer.value !== ''"> - </span>
                                                    <span v-if="answer.text !== ''">{{ answer.text }}</span>
                                                </v-chip></div>
                                            </div>
                                        </div>
                                        <div class="mt-2 body-2">{{ item.answerNotes }}</div>
                                        <span class="caption grey--text">{{ item.questionType }}</span>
                                    </div>
                                    <v-spacer />
                                    <div class="d-flex justify-end dnd-no-drag" style="width: 100px !important">
                                        <v-icon @click="editQuestion(item.id)" class="ml-2">oomph-edit</v-icon>
                                        <!-- deleteQuestion(item.id)" -->
                                        <v-icon @click="confirmDestroy.id = item.id ;confirmDialog = confirmDestroy" class="ml-2">oomph-trash-can</v-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </drag>
                </template>
            </drop-list>
            </v-col>
        </v-row>
        <confirm-dialog :datadown="confirmDialog" v-on:method="confirmMethodHandler" />
        <!-- <pre class="caption">{{ questions }}</pre> -->
        <v-dialog v-model="dialogQuestion" max-width="1024px">
            <question-item :activequestion="activeQuestion" :newquestion="newquestion" :questionparent="reviewquestion" v-on:close="dialogQuestion = false" v-on:refresh="refreshQuestions" />
        </v-dialog>
    </div>
</template>

<script>
import { Drag, DropList } from 'vue-easy-dnd';
import QuizQuestionItem from '@/views/reviews/QuestionItem.vue';
export default {
    // * COMPONENTS
    components: {
        'question-item': QuizQuestionItem,
        Drag,
        DropList,
    },
    props: {
        refreshquestions: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        reviewquestion: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data: () => ({
        reorder: false,
        activeQuestion: {},
        type: 'reviewQuestions',
        newquestion: false,
        questions: [],
        itemsTotal: null,
        alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        searchQuery: '',
        // activeItem: {},
        dialogQuestion: false,
        // Toggles
        toggleFeatures: [],
        sortBy: '@questionOrder',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['questionOrder'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        reviewQuestions: {},
        confirmDialog: {},
        confirmDestroy: {
            show: true,
            title: 'WARNING - Delete Question',
            message: 'Are you sure you want to delete this question? <p class="mt-2 error--text font-weight-bold">This Action is Permanent and Cannot be Undone.</p>',
            color: 'error',
            actionText1: 'No',
            actionText2: 'Yes',
            id: '',
            method: 'destroyQuestion',
            action: '',
        },
    }),
    computed: {
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            searchQuery = `@questionParentId:{${this.reviewQuestions.id}}`;
            return searchQuery;
        },
        computedQuestions() {
            var questions = this.questions;
            if (this.type) {
                questions = this.questions.filter((item) => item.actionType === this.type);
            }
            return questions;
        },
        computedType() {
            const types = [];
            this.questions.filter((item) => {
                return types.push(item.actionType);
            });
            return types;
        },
    },
    methods: {
        // * CONFIRM METHOD HANDLER
        confirmMethodHandler(value) {
            this.confirmDialog = {};
            if (value.action === 2) {
                this[value.method](value.id);
            }
        },        
        initialise() {
            this.reviewQuestions = { ...this.reviewquestion };
        },
        newQuestion() {
            this.newquestion = true;
            this.activeQuestion = {};
            this.dialogQuestion = true;
        },
        // On Insert Drag and Drop
        onInsert(event) {
            this.questions.splice(event.index, 0, event.data);
        },
        async onReorder(event) {
            event.apply(this.questions);
            for (var i = 0; i < this.questions.length; i++) {
                this.questions[i].questionOrder = i + 1;
                let result = await this.MIX_createItem(this.questions[i], 'question', false);
            }
        },
        async editQuestion(id) {
            // Get the item from the database
            this.newquestion = false;
            let itemResult = await this.MIX_readItem(id, 'question');
            if (itemResult.code === 1) {
                this.activeQuestion = itemResult.data;
                this.dialogQuestion = true;
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true });
            }
        },
        refreshQuestions: function () {
            this.getQuestions();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * Destroy
        async destroyQuestion(questionId) {
            let result = await this.MIX_destroyItem(questionId, 'question');
            if (result.code === 1) {
                this.getQuestions();
            }
        },
        // * Delete
        async deleteQuestion(questionId) {
            let result = await this.MIX_deleteItem(questionId, 'question');
            if (result.code === 1) {
                this.getQuestions();
            }
        },
        // * Restore
        async restoreQuestion(questionId) {
            let result = await this.MIX_restoreItem(questionId, 'question');
            if (result.code === 1) {
                this.getQuestions();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
        },
        // * GET ITEMS
        async getQuestions() {
            let questionsResult = await this.MIX_redisOrderedSearch('idx:questions', this.computedSearchQuery, 0, 999, 'questionOrder');
            if (questionsResult.code === 1) {
                this.itemsTotal = questionsResult.data.total;
                this.reviewQuestions.questionTotal = this.itemsTotal;
                this.$emit('update', this.reviewQuestions);
                this.questions = questionsResult.data.documents.map((question) => question.value);
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            }
        },
    },
    watch: {
        reviewquestion() {
            this.initialise();
        },
        refreshquestions() {
            this.getQuestions();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getQuestions();
        },
        computedFields() {
            this.getQuestions();
        },
    },
    async created() {
        this.initialise();
        this.getQuestions();
    },
};
</script>

<style scoped>
    .pointer {cursor: pointer;}
    .default {cursor: default;}
</style>