<template>
    <div class="" style="padding: 0px !important; margin: 0px !important" >
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <!-- Main Content -->
            <v-col  cols="12" class=" align-start rounded">
                <v-row dense class="px-4 align-center pb-0 py-2 pl-3">
                    <!-- <v-col cols="2" class="d-flex justify-end">
                        <div class="pr-2 text--end font-weight-medium primary--text caption">SORT BY</div>
                    </v-col> -->
                    <v-col cols="12">
                        <v-select 
                            label="Sort By"
                            :menu-props="{ top: false, offsetY: true }" 
                            background-color="white" 
                            hide-details="auto" 
                            v-model="tasksSortBy" 
                            :items="sortByTypes" 
                            outlined 
                            dense 
                            @click:clear="clearSortByFilter()" 
                            clearable>
                        </v-select>
                    </v-col>
                    
                </v-row>
                <v-row class="px-3 pb-3 pt-0">
                    <v-col cols="12" class="pt-0" :class="dashboard ? 'pr-4' : ''">
                        <v-btn-toggle multiple v-model="type" class="  d-flex align-center flex-row">
                            <v-btn class="flex-grow-1" value="Call" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Call' : ''"  size="20" color="grey" class="oomph-call"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Call</span>
                            </v-btn>
                            <v-btn v-if="!dashboard" class="flex-grow-1" value="Call Back" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Call Back' : ''" size="20" color="grey" class="oomph-callback"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Call Back</span>
                            </v-btn>
                            <v-btn v-if="!dashboard" class="flex-grow-1" value="Case" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Case' : ''"  size="20" color="grey" class="oomph-complaint"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Case</span>
                            </v-btn>
                            <v-btn class="flex-grow-1" value="Email" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Email' : ''"  size="20" color="grey" class="oomph-mail"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Email</span>
                            </v-btn>
                            <v-btn v-if="!dashboard" class="flex-grow-1" value="Event" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Event' : ''"  size="20" color="grey" class="oomph-calendar"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Event</span>
                            </v-btn>
                            <v-btn v-if="!dashboard" class="flex-grow-1" value="Meeting" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Meeting' : ''"  size="20" color="grey" class="oomph-meeting"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Meeting</span>
                            </v-btn>
                            <v-btn v-if="!dashboard" class="flex-grow-1" value="Task" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Task' : ''"  size="20" color="grey" class="oomph-tasklist"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Task</span>
                            </v-btn>
                            <v-btn v-if="!dashboard" class="flex-grow-1" value="Note" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon :title="$vuetify.breakpoint.lgAndDown ? 'Note' : ''"  size="20" color="grey" class="oomph-note"></v-icon>
                                <span v-if="$vuetify.breakpoint.xlOnly">Note</span>
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <!-- //* VIEW MODE -->
                <v-row v-if="addTask === false" class="" style="width: 100% !important" no-gutters>
                    <v-col cols="12">
                        <v-card class="elevation-0 justify-center px-3" :class="dashboard ? 'appbackground' : 'grey lighten-4'" style="height: 80vh !important; overflow-y: scroll !important ">
                            <!-- <pre>{{ computedItems }}</pre> -->
                            <div v-for="(action, i) in computedItems" :key="i">
                                <action-card class="mb-2" :datadown="action" :orgIsGroup="orgIsGroup" v-on:saveItem="saveAction" v-on:deleteItem="deleteAction" :key="action.id"></action-card>
                            </div>
                            <div class="grey--text d-flex font-weight-bold text-h5 align-center justify-center" v-if="computedItems.length == 0">
                                NO TASKS
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import companiesHouse from '@/views/organisations/CompaniesHouse.vue';
import actionCard from '@/views/sales/TaskCard.vue'
import schema from "@/schema.js";
export default {
    name: 'OrganisationDetails',
    components: {
        'companies-house': companiesHouse,
        'action-card': actionCard,
    },
    props: {
        datadown: {
            type: Object,
			default: function () {
				return {};
			},
        },
        dense: {
            type: Boolean,
            default: false,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        refreshItems: {
            type: Number,
            default: 0,
        },
        account: {
            type: Boolean,
            default: false
        },
        orgIsGroup: {
            type: Boolean, 
            default: false,
        },
        organisations: {
            type: Array, 
            default: () => [],
        },
        dashboard: {
            type: Boolean,
            default: false
        }
    },
    // * DATA
    data: () => ({
        addTask: false,
        // Item
        actions: {},
        items: {},
        key: 'action',
        index: 'idx:actions',
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        datatableOptions: {
            page: 1,
            itemsPerPage: 200,
            sortBy: ['actionSubject'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        sortBy: '@actionSubject',
        tasksSortBy: 'Priority',
        sortByTypes: [ 'Date', 'Priority'],
        type: [],


        stages: [],
        stage: [],

        // Headers to display in the Data Table
        headers: [
            { text: 'Action', value: 'actionType', sortable: false, hidden: false, expanded: false },
            { text: 'Subject', value: 'actionSubject', sortable: false, hidden: false, expanded: false },
            { text: 'Description', value: 'actionDescription', sortable: false, hidden: false, expanded: false },
            { text: 'Status', value: 'actionStatus', sortable: false, hidden: false, expanded: false },
            { text: 'Priority', value: 'actionPriority', sortable: false, hidden: false, expanded: false },
            { text: 'Start Date', value: 'actionDate', sortable: false, hidden: false, expanded: true },
            { text: 'Organisation', value: 'actionTags', sortable: false, hidden: false, expanded: false },

            { text: 'Organisation', value: 'actionStageId', sortable: false, hidden: false, expanded: false },
            { text: 'Organisation', value: 'actionStageName', sortable: false, hidden: false, expanded: false },
            { text: 'Organisation', value: 'actionCompleted', sortable: false, hidden: false, expanded: false },
            { text: 'Organisation', value: 'actionCompletedDateTime', sortable: false, hidden: false, expanded: false },
            { text: 'Organisation', value: 'orgId', sortable: false, hidden: false, expanded: false },
            { text: 'Organisation', value: 'orgName', sortable: false, hidden: false, expanded: false },

            { text: 'Contact Tags', value: 'contactTags', sortable: false, hidden: true, expanded: true },
            { text: 'Opportunity', value: 'opportunityId', sortable: false, hidden: true, expanded: true },
            { text: 'Lead', value: 'leadId', sortable: false, hidden: true, expanded: false },
            { text: 'Call Type', value: 'actionCallType', sortable: false, hidden: true, expanded: false },
            { text: 'Call Duration', value: 'actionCallDuration', sortable: false, hidden: true, expanded: false },
            { text: 'Call Purpose', value: 'actionCallPurpose', sortable: false, hidden: true, expanded: false },
            { text: 'Call Result', value: 'actionCallResult', sortable: false, hidden: true, expanded: true },
            { text: 'Meeting Host', value: 'actionMeetingHost', sortable: false, hidden: true, expanded: false },
            { text: 'Meeting Participants', value: 'actionMeetingParticipants', sortable: false, hidden: true, expanded: false },
            { text: 'Meeting Type', value: 'actionMeetingType', sortable: false, hidden: true, expanded: false },
            { text: 'Meeting Location', value: 'actionMeetingLocation', sortable: false, hidden: true, expanded: true },
            { text: 'Event Host', value: 'actionEventHost', sortable: false, hidden: true, expanded: true },
            { text: 'Event Participants', value: 'actionEventParticipants', sortable: false, hidden: true, expanded: false },
            { text: 'Event Type', value: 'actionEventType', sortable: false, hidden: true, expanded: false },
            { text: 'Event Location', value: 'actionEventLocation', sortable: false, hidden: true, expanded: false },
            { text: 'Case Type', value: 'actionCaseType', sortable: false, hidden: true, expanded: false },

            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Created Name', value: 'createdUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Name', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Name', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Email', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],

        newAction: {},
        priority: ['Urgent', 'High', 'Medium', 'Low'],

        filterByActionTypes: [],
        actionTypes: [
            {
                name: 'Call',
                icon: 'oomph-call',
            },
            {
                name: 'Call Back',
                icon: 'oomph-callback',
            },
            {
                name: 'Case',
                icon: 'oomph-complaint',
            },
            {
                name: 'Email',
                icon: 'oomph-mail',
            },
            {
                name: 'Event',
                icon: 'oomph-calendar',
            },
            {
                name: 'Meeting',
                icon: 'oomph-meeting',
            },
            {
                name: 'Task',
                icon: 'oomph-tasklist',
            }
        ]

    }),
    computed: {
        ...mapGetters({
            GET_dense: 'GET_dense',
        }),
        inputCols() {
            if (this.listshrunk) {
                return '3';
            } else {
                return '6';
            }
        },
        computedSearchQuery() {
            var t = this;
            let searchQuery = this.searchQuery;
            var orgs = ''
            var id = {...this.datadown}
            id = id.id
            // // ////console.log("id = " + JSON.stringify(id, null, 2))

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                //     searchQuery = '*';
                // } else {
                    searchQuery = `@deleted:0 `;
                // }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}* `;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}* `;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }
            if (this.type !== null && this.type !== undefined && JSON.stringify(this.type) !== '[]') {
                searchQuery = `${searchQuery} @actionType:{${this.type.join('|')}}`;
            }
            // Group Level Filter
            if (this.orgIsGroup == true && this.organisations.length > 0) {
                orgs = this.organisations 
                orgs = orgs.map((y) => y.id).join("|")
                //console.log('orgs tasks = ' + JSON.stringify(orgs, null, 2))
                searchQuery = `${searchQuery} @orgId:${orgs}`;
            } else {
                searchQuery = `${searchQuery} @orgId:${id}` 
            }
            //console.log('searchQuery tasks = ' + searchQuery)

            return searchQuery;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            if (!computedFields.includes('@actionId')) {
                computedFields.push('@actionId');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedItems() {
            var t = this;

            var items = t.items

            items = items.filter((item) => {
                return item.deleted === '0'
            })

            items = items.filter((item) => {
                return item.actionCompleted === '0'
            })

            // items = items.filter((item) => {
            //     return item.actionType === 'Task'
            // })

            // items = items.filter((item) => {
            //     return item.orgId == this.datadown.id
            // })

            ////console.log("filterByActionTypes = " + JSON.stringify(t.filterByActionTypes, null, 2))

            if (t.filterByActionTypes.length > 0 ) {
                t.filterByActionTypes.forEach((filter) => {
                    ////console.log(JSON.stringify(filter, null, 2))
                    items = items.filter(function(item) {
                        return item.actionType === filter.name
                    })
                })
            }

            if (this.type !== null && this.type !== undefined && JSON.stringify(this.type) !== '[]') {
                items = items.filter((item) => this.type.includes(item.actionType));
            }
            
            ////console.log("items = " + JSON.stringify(items, null, 2))
            // // ////console.log(JSON.stringify(items, null, 2))
            // // ////console.log("item = " + JSON.stringify(this.datadown, null, 2))

            // if (t.account == true) {
            //     items = items.filter((item) => {
            //         return item.actionStageId == this.datadown.orgStageId
            //     })
            // }
            // // ////console.log(JSON.stringify(items, null, 2))

            // items.forEach((item) => {
            //     let result =  t.MIX_redisSearch('idx:stages', `@id:{${item.id}}`, 0, 9999);
            //     // // ////console.log("result = " + JSON.stringify(result, null, 2))
            // })


            items.forEach((item) => {
                switch (true) {
                    case item.actionType === 'Call':
                        item.icon = 'oomph-call'
                        break;
                    case item.actionType === 'Call Back':
                        item.icon = 'oomph-callback'
                        break;
                    case item.actionType === 'Case':
                        item.icon = 'oomph-complaint'
                        break;
                    case item.actionType === 'Email':
                        item.icon = 'oomph-mail'
                        break;
                    case item.actionType === 'Event':
                        item.icon = 'oomph-calendar'
                        break;
                    case item.actionType === 'Meeting':
                        item.icon = 'oomph-meeting'
                        break;
                    case item.actionType === 'Task':
                        item.icon = 'oomph-tasklist'
                        break;
                    case item.actionType === 'Note':
                        item.icon = 'oomph-note'
                        break;
                    case item.actionType === '':
                        item.icon = ''
                        break;

                }
            })



            if (t.tasksSortBy == 'Date') {
                items.sort((a, b) => a.actionDate.localeCompare(b.actionDate));
            } else if (t.tasksSortBy == 'Priority') {

                var order = ['Urgent', 'High', 'Medium', 'Low']
                items = items.sort((a,b) => {
                    return (
                        order.indexOf(a.actionPriority) - order.indexOf(b.actionPriority)
                    )
                })
            }


            return items
        },
    },
    // * METHODS
    methods: {
        addNewTask() {
            var t= this;
            t.newAction = schema.action
            t.newAction.actionType = 'Task',
            t.newAction.orgId = t.datadown.id
            t.newAction.orgName = t.datadown.orgName
            t.newAction.actionStageName = t.datadown.orgStageName
            t.newAction.actionStageId = t.datadown.orgStageId

            t.addTask = true
        },
        async saveNewTask() {
            var t = this;

            var action = t.newAction

            // // ////console.log("saving action + " + JSON.stringify(action, null, 2))
            
			let result = await t.MIX_createItem(action, t.key, true);
			if (result.code === 1) {
				// t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
				// t.readItem(item.id);
				// t.$emit("refreshItems");
                // t.closeDialog()
                // // ////console.log('saved action')
                t.newAction = []
                t.addTask = false
			}

        },
		// * READ ITEM
		async readItem() {
			let t = this;
            var id = {...t.datadown}
            // // // ////console.log("reading item " + JSON.stringify(id, null, 2))
			let result = await t.MIX_readItem(id.orgId, 'org');
			if (result.code === 1) {
				this.item = result.data;
                // // // ////console.log("item = " + JSON.stringify(t.item, null, 2))
				t.itemNew = false;
				t.loading = false;
			}
		},
        // Clear Selected Org Tags
        clearSortByFilter() {
            // // // ////console.log("clearing status filter")
            setTimeout(() => {
                this.tasksSortBy = [];
            }, 1);
        },
        cleartTypeFilter() {
            setTimeout(() => {
                this.filterByActionTypes = []
            }, 1)
        },
		// * DELETE ITEM
		async deleteAction(action) {
            let item = action
			let result = await this.MIX_deleteItem(item.id, this.key);
			if (result.code === 1) {
				// this.readItem(this.item.id);
				this.$emit("refreshItems");
				// t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
			}
		},
        async saveAction(action) {
            var t = this;

            var action = action

            ////console.log("saving action + " + JSON.stringify(action, null, 2))
            action.actionCompletedDateTime = t.$moment().format('X')
            ////console.log("saving action + " + JSON.stringify(action, null, 2))
            
			let result = await t.MIX_createItem(action, t.key, false);
			if (result.code === 1) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
				// t.readItem(item.id);
				t.$emit("refreshItems");
                // t.closeDialog()
                // t.getItems()
                // // ////console.log('saved action')
			}
        },
        // * GET ITEMS
        async getItems() {
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            } else {
                
            }
        },
    },
    // * WATCH
    watch: {
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
		datadown() {
            var t = this;
            t.getItems()
		},
        refreshItems() {
            this.getItems();
        },
        organisations() {
            var t = this;
            t.getItems()
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
    },
    // * CREATED
    async created() {
        let t = this;
        t.actions = { ...t.datadown };
		this.getItems();

        let stages = await this.MIX_redisSearch('idx:stages', `*`, 0, 9999);
        stages = stages.data.documents.map((x) => x.value);
        
        t.stages = stages.filter((item) => {
            return item.stagePipeline === 'Leads';
        })
        t.stages = t.stages.map((x) => {
            return {
                stageId: x.id,
                stageName: x.stageName,
                stageOutcome: x.stageOutcome
            };
        })

    },
};
</script>

<style>
/* .borderBottom {
    border-bottom: 5px solid #019EAF !important;
    border-color: #019EAF !important
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-bottom: 5px solid #019EAF !important;
    border-color: #019EAF !important
} */
</style>
