<template>
    <div>
        <div class="mt-2 d-flex align-start">
            <!-- <pre>{{ orgGroupStructure}}</pre> -->
            <div class="flex-grow-1">
                <v-text-field :label="orgtype ? 'Search ' + orgtype : 'Search Organisations'" v-model="searchQuery" outlined
                    dense hide-details="auto" background-color="white" color="primary" clearable
                    prepend-inner-icon="oomph-search" @click:clear="searchQuery = ''"></v-text-field>
            </div>
            <!-- <div v-if="!listshrunk && orgtype == 'Customer'" class="ml-2">
                <div>
                    <v-autocomplete 
                        multiple 
                        outlined 
                        auto-select-first 
                        v-model="filter.orgCounties" 
                        :search-input.sync="tagOrgAccountTypes" 
                        @change="tagOrgAccountTypes = ''" 
                        label="Filter by County" 
                        :items="computedCounties" 
                        dense 
                        hide-details="auto" 
                        background-color="white" 
                        color="primary" 
                        clearable 
                        @click:clear="clearFilter('orgCounty')">
                    </v-autocomplete>
                </div>
                <div class="ml-1 mt-1 body-2 app--text">
                    Filter is set to <strong class="primary--text" @click="changeAccountTypesOperator()" style="cursor: pointer">{{ filter.orgAccountTypesOperator }}</strong> of the above
                </div>
            </div> -->
            <div v-if="!listshrunk && orgtype !== 'Customer'" class="ml-2">
                <div>
                    <v-autocomplete multiple outlined auto-select-first v-model="filter.orgAccountTypes"
                        :search-input.sync="tagOrgAccountTypes" @change="tagOrgAccountTypes = ''" label="Account Type"
                        :items="orgAccountTypes" dense hide-details="auto" background-color="white" color="primary"
                        clearable @click:clear="clearSelectedAccountTypes()">
                    </v-autocomplete>
                </div>
                <div class="ml-1 mt-1 body-2 app--text">
                    Filter is set to <strong class="primary--text" @click="changeAccountTypesOperator()"
                        style="cursor: pointer">{{ filter.orgAccountTypesOperator }}</strong> of the above
                </div>
            </div>
            <div v-if="!listshrunk" class="ml-2">
                <div>
                    <v-autocomplete multiple outlined auto-select-first v-model="filter.orgCounty"
                        :search-input.sync="searchOrgCounties" @change="searchOrgCounties = ''" label="Filter by County"
                        :items="orgCounties" dense hide-details="auto" background-color="white" color="primary" clearable
                        @click:clear="clearSelectedOrgCounties()"></v-autocomplete>
                </div>
            </div>
            <div v-if="!listshrunk" class="ml-2">
                <div>
                    <v-select outlined auto-select-first :menu-props="{ top: false, offsetY: true }"
                        v-model="filter.orgGroupStructure" label="Group Structure" :items="orgGroupStructure" dense
                        hide-details="auto" background-color="white" color="primary" clearable
                        @click:clear="clearSelectedAccountTypes()">
                    </v-select>
                </div>
            </div>

            <div v-if="!listshrunk && filter.orgGroupStructure === 'Home'" class="ml-2">
                <div>
                    <v-select label="Group" v-model="filter.orgGroupId" :items="orgGroups" item-value="orgId"
                        item-text="orgName" outlined dense hide-details="auto" background-color="white" color="primary"
                        clearable @click:clear="clearSelectedOrgGroupStructure()"
                        :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete">
                    </v-select>
                </div>
            </div>

            <div v-if="!listshrunk && listexpanded" class="ml-2">
                <div>
                    <v-autocomplete multiple outlined auto-select-first v-model="filter.orgTags"
                        :search-input.sync="tagOrgTags" @change="tagOrgTags = ''" label="Filter by Tags" :items="orgTags"
                        dense hide-details="auto" background-color="white" color="primary" clearable
                        @click:clear="clearSelectedOrgTags()"></v-autocomplete>
                </div>
                <div class="ml-1 mt-1 body-2 app--text">
                    Filter is set to <strong class="primary--text" @click="changeTagsOperator()" style="cursor: pointer">{{
                        filter.orgTagsOperator }}</strong> of the above
                </div>
            </div>
            <div v-if="!listshrunk" class="ml-2">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important"
                    class="white grey--text text--darken-2" @click="refreshItems">
                    <v-icon>oomph-available-updates</v-icon>
                </v-btn>
            </div>
            <div v-if="!listshrunk" class="ml-2">
                <v-menu offset-y class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important"
                            class="white grey--text text--darken-2" v-bind="attrs"
                            v-on="on"><v-icon>oomph-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto"
                                v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false"
                                :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <!-- Alphabet Search -->
        <v-row v-if="!listshrunk" fill-width class="ma-0">
            <!-- // loop through each letter of the alphabet and create a button -->
            <v-btn-toggle v-model="filter.alpha" class="appbackground d-flex align-center flex-wrap">
                <v-btn v-for="letter in alphabet" :key="letter" :value="letter" class="my-1 mr-1" color="white" depressed
                    small>{{ letter }}</v-btn>
                <v-btn value="" class="my-1" depressed small>All</v-btn>
            </v-btn-toggle>
        </v-row>
        <div class="mt-2">
            <!-- TABLE BASED LIST -->
            <v-data-table v-if="!listshrunk" :headers="computedHeaders" :items="computedItems"
                :server-items-length="itemsTotal" :options.sync="datatableOptions" sortable dense>
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span>
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <!-- <pre>Toggle Features: {{ toggleFeatures }}</pre> -->
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                            <v-btn value="undelete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:item.orgTelephone="{ item }">
                    <div v-if="item.orgTelephone !== '' && item.orgTelephone !== undefined && item.orgTelephone !== null"
                        @click="MIX_goExternal('callto:' + item.orgTelephone)" class="d-flex align-end">
                        <div>{{ item.orgTelephone }}</div>
                        <div v-if="listexpanded"><v-icon class="primary--text ml-1">oomph-external-link</v-icon></div>
                    </div>
                </template>
                <template v-slot:item.orgWebsite="{ item }">
                    <div v-if="item.orgWebsite !== '' && item.orgWebsite !== null && item.orgWebsite !== undefined"
                        @click="MIX_goExternal(item.orgWebsite)" class="d-flex align-end">
                        <div>{{ item.orgWebsite }}</div>
                        <div v-if="listexpanded"><v-icon class="primary--text ml-1">oomph-external-link</v-icon></div>
                    </div>
                </template>
                <template v-slot:item.orgEmail="{ item }">
                    <div v-if="item.orgEmail?.trim()" @click="MIX_goExternal('mailto:' + item.orgEmail)"
                        class="d-flex align-end">
                        <div>{{ item.orgEmail }}</div>
                        <div v-if="listexpanded"><v-icon class="primary--text ml-1">oomph-secured-letter</v-icon></div>
                    </div>
                </template>

                <template v-slot:item.orgApprovedPartner="{ item }">
                    <v-chip small v-if="item.orgApprovedPartner === '1'" class="success ml-3" dark>Yes</v-chip>
                    <v-chip small v-else class="error ml-3" dark>No</v-chip>
                </template>

                <template v-slot:item.action="{ item }">
                    <v-icon class="error--text pa-1" v-if="toggleFeatures.includes('destroy')"
                        @click="destroyItem(item.id)">oomph-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)"
                        v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)"
                        v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>
                </template>
            </v-data-table>
            <!-- CARD BASED LIST -->
            <div v-if="listshrunk" class="">
                <v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
                    <div class="d-flex align-center ml-1">
                        <div class="ml-3">
                            <div class="body-1 font-weight-medium"
                                style="width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{
                                    item.orgName }}</div>
                            <div class="caption darkgrey--text">{{ item.orgAccountTypes }}</div>
                            <div class="body-2">{{ item.orgPostcode }}</div>
                            <!-- <br />
							<span class="body-2">{{ item.supplierName }}</span
							><br />  -->
                        </div>
                        <v-spacer></v-spacer>
                        <div class="text-right mr-3">
                            <v-icon class="primary--text" @click="openItem(item)">oomph-forward</v-icon>
                        </div>
                    </div>
                </v-card>
            </div>
            <!-- LOAD MORE CARDS -->
            <v-btn v-if="listshrunk" class="mt-2 grey lighten-3" depressed block @click="loadMore()">Load More</v-btn>
            <v-col cols="12" class="pt-0">
                <!-- App Copyright -->
                <appCopyright :colour="'white'" />
            </v-col>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listexpanded: {
            type: Boolean,
            default: true,
        },
        orgtype: {
            type: String,
            default: "",
        },
    },
    // DATA
    data: () => ({
        key: "org",
        index: "idx:orgs2",
        toggleDeletedItems: false,

        orgTags: [],
        orgAccountTypes: [],
        orgChannels: [],

        alphabet: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],

        // tag
        tagOrgAccountTypes: "",
        tagOrgTags: "",

        orgGroupStructure: [],
        orgGroups: [],

        filter: {
            orgAccountTypesOperator: "ANY",
            orgAccountTypes: [],
            orgTagsOperator: "ANY",
            orgTags: [],
            alpha: "",
            orgGroupStructure: "",
            orgGroupId: "",
            orgCounty: [],
        },
        // Toggles
        toggleFeatures: [],
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: "",
        itemsFrom: 0,
        itemsTo: 9,
        headers: [
            { text: "ID", value: "id", sortable: false, hidden: true, expandable: true },
            { text: "Name", value: "orgName", sortable: false, hidden: false, expandable: true },
            { text: "Registered Name", value: "orgRegistrationNumber", sortable: false, hidden: true, expandable: true },
            { text: "Telephone", value: "orgTelephone", sortable: false, hidden: false, expandable: true },
            { text: "Website", value: "orgWebsite", sortable: false, hidden: true, expandable: false },
            { text: "Contact Name", value: "orgContactName", sortable: false, hidden: false, expandable: true },
            { text: "Email", value: "orgEmail", sortable: false, hidden: false, expandable: false },
            { text: "Invoice Email", value: "orgInvoiceEmail", sortable: false, hidden: true, expandable: false },
            { text: "Purchasing Email", value: "orgPurchasingEmail", sortable: false, hidden: true, expandable: false },

            { text: "Address Line 1", value: "orgAddressLine1", sortable: false, hidden: true, expandable: true },
            { text: "Address Line 2", value: "orgAddressLine2", sortable: false, hidden: true, expandable: true },
            { text: "Address Line 3", value: "orgAddressLine3", sortable: false, hidden: true, expandable: true },
            { text: "Town", value: "orgTown", sortable: false, hidden: true, expandable: true },
            { text: "County", value: "orgCounty", sortable: false, hidden: false, expandable: false },
            { text: "Postcode", value: "orgPostcode", sortable: false, hidden: false, expandable: true },

            { text: "Employees", value: "orgEmployees", sortable: false, hidden: true, expandable: false },
            { text: "Industry", value: "orgIndustry", sortable: false, hidden: true, expandable: false },
            { text: "Sector", value: "orgSector", sortable: false, hidden: true, expandable: false },
            { text: "Account Number", value: "orgAccountNumber", sortable: false, hidden: true, expandable: false },
            { text: "Account Types", value: "orgAccountTypes", sortable: false, hidden: true, expandable: false },
            { text: "Group Structure", value: "orgGroupStructure", sortable: false, hidden: false, expandable: true },

            { text: "Turnover", value: "orgTurnover", sortable: false, hidden: true, expandable: false },
            { text: "Notes", value: "orgNotes", sortable: false, hidden: true, expandable: false },

            { text: "Account Owner", value: "orgAccountOwner", sortable: false, hidden: true, expandable: false },
            { text: "Source", value: "orgSource", sortable: false, hidden: true, expandable: false },
            { text: "Source Name", value: "orgSourceName", sortable: false, hidden: true, expandable: false },

            { text: "Twitter", value: "orgTwitter", sortable: false, hidden: true, expandable: false },
            { text: "Facebook", value: "orgFacebook", sortable: false, hidden: true, expandable: false },
            { text: "Linked In", value: "orgLinkedIn", sortable: false, hidden: true, expandable: false },
            { text: "Instagram", value: "orgInstagram", sortable: false, hidden: true, expandable: false },

            { text: "Approved Partner?", value: "orgApprovedPartner", sortable: false, hidden: true, expandable: false },
            { text: "Region Name", value: "orgRegionName", sortable: false, hidden: true, expandable: false },
            { text: "Division Name", value: "orgDivisionName", sortable: false, hidden: true, expandable: false },

            { text: "Date Time (C)", value: "createdDateTime", sortable: false, hidden: true, expandable: false },
            { text: "User ID (C)", value: "createdUserId", sortable: false, hidden: true, expandable: false },
            { text: "User Name (C)", value: "createdUserName", sortable: false, hidden: true, expandable: false },
            { text: "User Email (C)", value: "createdUserEmail", sortable: false, hidden: true, expandable: false },
            { text: "Date Time (M)", value: "modifiedDateTime", sortable: false, hidden: true, expandable: false },
            { text: "User ID (M)", value: "modifiedUserId", sortable: false, hidden: true, expandable: false },
            { text: "User Name (M)", value: "modifiedUserName", sortable: false, hidden: true, expandable: false },
            { text: "User Email (M)", value: "modifiedUserEmail", sortable: false, hidden: true, expandable: false },
            { text: "Deleted", value: "deleted", sortable: false, hidden: true, expandable: false },
            { text: "Date Time (D)", value: "deletedDateTime", sortable: false, hidden: true, expandable: false },
            { text: "User ID (D)", value: "deletedUserId", sortable: false, hidden: true, expandable: false },
            { text: "User Name (D)", value: "deletedUserName", sortable: false, hidden: true, expandable: false },
            { text: "User Email (D)", value: "deletedUserEmail", sortable: false, hidden: true, expandable: false },
            { text: "Action", value: "action", align: "end", sortable: false, hidden: false, expandable: true, width: "95px" },
        ],
        // Fields to Retrieve from Database
        //fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: "@orgName",
        datatableOptions: {
            page: 1,
            itemsPerPage: 15,
            sortBy: ["orgName"],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        orgCounties: [],
        searchOrgCounties: ""

    }),
    computed: {
        ...mapGetters({
            GET_taskBar: "GET_taskBar",
            GET_dense: "GET_dense",
            GET_destroy: "GET_destroy",
        }),
        computedCounties() {
            let counties = this.counties;

            // Sort the counties in alphabetical order
            counties.sort();

            return counties;
        },
        alphaOptions() {
            let alphaOptions = [];
            for (let i = 0; i < this.alphabet.length; i++) {
                alphaOptions.push(this.filter.alpha + this.alphabet[i] + "*");
            }
            return alphaOptions;
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            if (searchQuery === "" || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes("undelete")) {
                    searchQuery = "*";
                } else {
                    searchQuery = "@deleted:0";
                }
            } else {
                if (searchQuery.startsWith("@")) {
                    if (this.toggleFeatures.includes("undelete")) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.toggleFeatures.includes("undelete")) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }
            //}
            // Alphabet Filter
            if (this.filter.alpha !== "" && this.filter.alpha !== null && this.filter.alpha !== undefined) {
                let alphaOptions = this.alphaOptions.join(",");
                alphaOptions = alphaOptions.replace(/,/g, "|");
                searchQuery = `${searchQuery}  @orgName:${alphaOptions}`;
            }
            // Account Type Filter
            if (JSON.stringify(this.filter.orgAccountTypes) !== "[]") {
                if (this.filter.orgAccountTypesOperator === "ANY") {
                    searchQuery = `${searchQuery} @orgAccountTypes:{${this.filter.orgAccountTypes.join("|")}}`;
                } else {
                    let orgAccountTypes = [];
                    this.filter.orgAccountTypes.forEach((tag) => {
                        orgAccountTypes.push(`@orgAccountTypes:{${tag}}`);
                    });
                    searchQuery = `${searchQuery} ${orgAccountTypes.join(" ")}`;
                }
            }
            // Group Structure Filter
            if (this.filter.orgGroupStructure !== "" && this.filter.orgGroupStructure !== null && this.filter.orgGroupStructure !== undefined) {
                searchQuery = `${searchQuery}  @orgGroupStructure:{${this.filter.orgGroupStructure}}`;
            }
            // Organisation Groups
            if (this.filter.orgGroupId !== "" && this.filter.orgGroupId !== null && this.filter.orgGroupId !== undefined) {
                searchQuery = `${searchQuery}  @orgGroupId:{${this.filter.orgGroupId}}`;
            }
            // Org Tags Filter
            if (JSON.stringify(this.filter.orgTags) !== "[]") {
                if (this.filter.orgTagsOperator === "ANY") {
                    searchQuery = `${searchQuery} @orgTags:{${this.filter.orgTags.join("|")}}`;
                } else {
                    let orgTags = [];
                    this.filter.orgTags.forEach((tag) => {
                        orgTags.push(`@orgTags:{${tag}}`);
                    });
                    searchQuery = `${searchQuery} ${orgTags.join(" ")}`;
                }
            }

            if (JSON.stringify(this.filter.orgCounty) !== "[]") {
                searchQuery = `${searchQuery}  @orgCounty:{${this.filter.orgCounty.join(" | ")}}`;
            }

            return searchQuery;
        },

        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expandable !== false); // Return Headers based on Expanded State
            }
            return headers;
        },

        computedItems() {
            var items = this.items; //.filter(x => x.);
            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },

        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => "@" + y.value);
            // TODO - FILTERING - Check if all necessary fields are present
            if (!computedFields.includes("@id")) {
                computedFields.push("@id");
            }
            if (!computedFields.includes("@deleted")) {
                computedFields.push("@deleted");
            }
            //if (!computedFields.includes("@attSupplier")) { computedFields.push ("@attSupplier") }
            computedFields = computedFields.join(",");
            return computedFields;
        },
    },
    methods: {
        clearFilter(property) {
            setTimeout(() => {
                this.filter[property] = "";
            }, 1)
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit("openItem", item);
            //this.activeItem = item;
            // this.listexpanded = false;
        },
        // Refresh Items
        refreshItems() {
            this.getItems();
            this.exportItems()
        },
        // * GET ITEMS
        async getItems() {
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            }
            this.exportItems()
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getItems();
        },
        // * Change Org Account Types Tag Operator
        changeAccountTypesOperator() {
            if (this.filter.orgAccountTypesOperator === "ALL") {
                this.filter.orgAccountTypesOperator = "ANY";
            } else {
                this.filter.orgAccountTypesOperator = "ALL";
            }
        },
        // * Change Org Channels Tag Operator
        changeChannelsOperator() {
            if (this.filter.orgChannelsOperator === "ALL") {
                this.filter.orgChannelsOperator = "ANY";
            } else {
                this.filter.orgChannelsOperator = "ALL";
            }
        },
        // * Change Org Tags Operator
        changeTagsOperator() {
            if (this.filter.orgTagsOperator === "ALL") {
                this.filter.orgTagsOperator = "ANY";
            } else {
                this.filter.orgTagsOperator = "ALL";
            }
        },
        clearSelectedOrgCounties() {
            setTimeout(() => {
                this.filter.orgCounty = []
            })
        },
        // Clear Selected Account Types
        clearSelectedAccountTypes() {
            setTimeout(() => {
                this.filter.orgAccountTypes = [];
            }, 1);
        },
        // Clear Selected Account Types
        clearSelectedOrgChannels() {
            setTimeout(() => {
                this.filter.orgChannels = [];
            }, 1);
        },
        // Clear Selected Org Tags
        clearSelectedOrgTags() {
            setTimeout(() => {
                this.filter.orgTags = [];
            }, 1);
        },
        async exportItems() {
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, 9999, this.computedFields, this.sortBy, false);
            if (itemsResult.code === 1) {
                this.$emit("exportItems", itemsResult.data.results);
            }
        },
    },
    watch: {
        refreshitems() {
            this.refreshItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === "productMainImage");
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
        listshrunk() {
            if (this.listshrunk) {
                this.datatableOptions.itemsPerPage = 15;
                this.itemsFrom = 0;
                this.getItems();
            }
        },
        listexpanded() {
            if (this.listexpanded === false) {
                this.datatableOptions.itemsPerPage = 15;
                this.itemsFrom = 0;
                this.getItems();
            } else if (this.listexpanded === true) {
                this.datatableOptions.itemsPerPage = 15;
                this.itemsFrom = 0;
                this.getItems();
            }
        },
    },
    async created() {
        let t = this;
        // Get Organisation Account Types
        let orgAccountTypes = await t.MIX_redisReadSet("set:orgAccountTypes", false);
        if (orgAccountTypes.code === 1) {
            t.orgAccountTypes = orgAccountTypes.data;
        }

        // Get Group Structures
        let groupStructureResult = await this.MIX_redisReadSet("set:orgGroupStructure", false);
        if (groupStructureResult.code === 1) {
            this.orgGroupStructure = groupStructureResult.data;
            console.log(groupStructureResult)
        }

        // Get Counties 
        let counties = await this.MIX_redisReadSet("set:orgCounty", false);
        if (counties.code === 1) {
            this.orgCounties = counties.data;
        }

        // Get Groups and Parent Groups
        let orgGroups = await this.MIX_redisOrderedSearch("idx:orgs2", `@orgAccountTypes:{Customer} @orgGroupStructure:{Group}`, 0, 9999, "orgName");
        orgGroups = orgGroups.data.documents.map((x) => x.value);
        this.orgGroups = orgGroups.map((x) => {
            return {
                orgId: x.id,
                orgName: x.orgName,
                orgGroupStructure: x.orgGroupStructure,
                orgGroupId: x.orgGroupId,
                orgParentGroupId: x.orgParentGroupId,
                orgDivisions: x.orgDivisions,
                orgRegions: x.orgRegions,
            };
        });
        // Get Channels
        let orgChannels = await t.MIX_redisReadSet("set:channels", false);
        if (orgChannels.code === 1) {
            t.orgChannels = orgChannels.data;
        }
        // Get Organisation Tags
        let orgTags = await t.MIX_redisReadSet("set:orgTags", false);
        if (orgTags.code === 1) {
            t.orgTags = orgTags.data;
        }

        // TODO: t.orgtype doesn't exist so if statement is always true. Figure out if 
        //       orgTags was meant instead or is just redundant code
        if (t.orgtype !== "" && t.orgtype !== "Organisation") {
            t.filter.orgAccountTypes.push(t.orgtype);
        }
        // Get Items
        this.getItems();
    },
};
</script>
