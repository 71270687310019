<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode" @click="saveItem()"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text px-3" dense style="z-index: 0 !important">
            <v-col v-if="!loading" cols="12" class="d-flex pt-4 pb-4 align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- DETAILS EDIT -->
                        <!-- <pre>{{ item }}</pre> -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Title</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field label="Opportunity Title" v-model="opportunityTitle" :rules="rules.opportunityTitle" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('opportunityTitle')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field label="Opportunity Pipeline" v-model="item.opportunityPipeline" :rules="rules.opportunityPipeline" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('opportunityPipeline')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Opportunity Stage" v-model="selectedOpportunityStage" :rules="rules.opportunityStatus" :items="opportunityStages" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('opportunityStages')" :menu-props="{ top: false, offsetY: true }" :attach="true">
                                        <template v-slot:selection="data">
                                            {{ data.item.stageName }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.stageName }}
                                        </template>
                                    </v-select>
                                    <!-- <v-select label="Opportunity Stage" v-model="item.opportunityStage" :rules="rules.leadStatus" :items="opportunityStages" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadStatus')" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select> -->
                                </v-col>
                                <!-- <v-col cols="12" xs="12" md="6">
                                    <v-text-field label="Opportunity Stage" v-model="item.opportunityStage" :rules="rules.opportunityStage" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('opportunityStage')"></v-text-field>
                                </v-col> -->
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field label="Estimated Value" v-model="item.opportunityValue" :rules="rules.opportunityValue" outlined dense hide-details="auto" type="number" prefix="£" background-color="white" color="primary" clearable @click:clear="clearItem('opportunityValue')"></v-text-field>
                                </v-col>


                                <v-col cols="12" xs="12">
                                    <v-autocomplete label="Prospect/ Customer" v-model="selectedPartnerCustomerTags" :rules="rules.opportunityOrgTags" :items="partnersCustomer" multiple outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('selectedPartnerCustomerTags')">
                                        <template v-slot:selection="data">
                                            {{ data.item.orgName }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.orgName }}
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" xs="12">
                                    <v-autocomplete label="Cost Consultant" v-model="selectedContactTags" :rules="rules.opportunityContactTags" :items="contacts" multiple outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('opportunityContactTags')">
                                        <template v-slot:selection="data">
                                            {{ data.item.contactName }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.contactName }}
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Probability</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-slider
                                        v-model="item.opportunityProbability"
                                        class="align-center mt-8"
                                        thumb-label="always"
                                        hide-details
                                    ></v-slider>
                                </v-col>


                                <v-col cols="12" class="my-1 mb-0">
                                    <v-textarea label="Short Description" :rules="descriptionRules" counter=280  rows="2" background-color="white" v-model="item.opportunityDescription"  outlined dense color="primary" hide-details="auto" clearable @click:clear="clearItem('leadDescription', '')"></v-textarea>
                                </v-col>


                                <!-- <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Country and Local Authority</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="12" lg="12" xl="8">
                                    <v-btn-toggle class="flex-wrap " style="width: 100% !important" v-model="countryFilter" dense>
                                        <v-btn :width="$vuetify.breakpoint.mdAndUp ? '25%' : '50%'" v-for="country in countries" :key="country" :value="country" height="44" active-class="primary white--text" depressed
                                            ><div class="mx-3">{{ country }}</div></v-btn
                                        >
                                    </v-btn-toggle>
                                </v-col>
                                <v-col cols="12" xs="12" md="12" lg="12" xl="4">
                                    <v-autocomplete label="Local Authority" v-model="item.opportunityLocalAuthority" :rules="rules.opportunityLocalAuthority" :items="computedLocalAuthority" item-text="authority" auto-select-first item-value="authority" outlined dense hide-details="auto" background-color="white" color="primary" no-data-text="Please select a Country first" clearable @click:clear="clearItem('localAuthorities')"></v-autocomplete>
                                </v-col> -->



                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Source Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-autocomplete label="Source" v-model="item.opportunitySource" :rules="rules.opportunitySource" :items="sources" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgOtherAddressType')" auto-select-first></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <!-- <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Source Name</span> -->
                                    <v-text-field label="Source Name" v-model="item.opportunitySourceName" :rules="rules.opportunitySourceName" required outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('opportunitySourceName')"></v-text-field>
                                </v-col>



                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Tag Organisations</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12">
                                    <v-autocomplete label="Tag Organisations" v-model="selectedOrgTags" :rules="rules.opportunityOrgTags" :items="organisations" multiple outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('opportunityOrgTags')">
                                        <template v-slot:selection="data">
                                            {{ data.item.orgName }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.orgName }}
                                        </template>
                                    </v-autocomplete>
                                </v-col>





                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Opportunity Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Opportunity Status" v-model="item.opportunityStatus" :rules="rules.opportunityStatus" :items="opportunityStatuses" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('opportunityStatus')" :menu-props="{ top: true, offsetY: true }" :attach="true"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <!-- <span class="text-left primary--text text-h6 font-weight-light" colspan="12">Owner</span> -->
                                    <v-select label="Opportunity Owner" v-model="selectedOpportunityOwner" :rules="rules.opportunityOwner" :items="users" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('opportunityStatus')" :menu-props="{ top: true, offsetY: true }" :attach="true">
                                        <template v-slot:selection="data">
                                            {{ data.item.ownerUserName }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.ownerUserName }}
                                        </template>
                                    </v-select>
                                </v-col>


                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';
import moment from 'moment';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        activeitem: {
            Type: Object,
            Default: null,
        },
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,

        opportunityTitle: '',
        countries: [],
        countryFilter: '',
        sources: [],
        partnersCustomer: [],
        organisations: [],

        selectedOpportunityStatus: [],
        selectedOrgTags: [],
        selectedPartnerCustomerTags: [],
        selectedContactTags: [],
        selectedOpportunityOwner: [],
        selectedOpportunityStage: [],

        // dropdowns
        packageTypes: [],
        billingCycles: [],
        packageStatus: [],
        descriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],


        chosenOneTime: '',
        chosenOneType: '',

        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],

        // rules
        packageCustomerDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],
        packageInternalDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],



        // new address object
        item: {},
        loading: true,
        rules: {},

    }),
    computed: {  
        computedLocalAuthority() {
            if (this.countryFilter === '') {
                return [];
            } else {
                return this.localAuthority.filter((item) => {
                    return item.country === this.countryFilter;
                });
            }
            // 	return this.localAuthority.filter(function (item) {
            // 		return item.country === this.countryFilter;
            // 	});
            // } else {
            //}
        },
    },
    methods: {
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.item);
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.item = { ...this.datadown };
                if (this.item.ownerUserId !== '' && this.item.ownerUserId !== undefined && this.item.ownerUserId !== null) {
                    this.selectedOpportunityOwner = { ownerUserId: this.datadown.ownerUserId, ownerUserName: this.datadown.ownerUserName, ownerUserEmail: this.datadown.ownerUserEmail };
                } else {
                    this.selectedOpportunityOwner = this.MIX_getCurrentOwner();
                }
            },
            deep: true,
        },
        activeitem: {
            handler: function () {
                let t = this;
                t.item = { ...t.activeitem };
            }, deep: true
        },
        opportunityTitle: {
            handler: function () {
                this.item.opportunityTitle = this.opportunityTitle;
                // //console.log('opportunity title = ' + this.item.opportunityTitle);
            },
        },
        selectedPartnerCustomerTags: {
            handler: function () {
                this.item.opportunityPartnerTags = '';
                for (let i = 0; i < this.selectedPartnerCustomerTags.length; i++) {
                    this.item.opportunityPartnerTags = this.item.opportunityPartnerTags + this.selectedPartnerCustomerTags[i].orgName + ',';
                }
                // //console.log('selectedPartnerCustomerTags = ' + JSON.stringify(this.selectedPartnerCustomerTags, null, 2));
            },
            deep: true,

        },
        selectedOrgTags: {
            handler: function () {
                this.item.opportunityOrgTags = '';
                for (let i = 0; i < this.selectedOrgTags.length; i++) {
                    this.item.opportunityOrgTags = this.item.opportunityOrgTags + this.selectedOrgTags[i].orgName + ',';
                }
                // //console.log('selectedOrgTags = ' + JSON.stringify(this.selectedOrgTags, null, 2));
            },
            deep: true,
        },
        selectedContactTags: {
            handler: function () {
                this.item.opportunityContactTags = '';
                for (let i = 0; i < this.selectedContactTags.length; i++) {
                    this.item.opportunityContactTags = this.item.opportunityContactTags + this.selectedContactTags[i].contactName + ',';
                }
                // //console.log('selectedContactTags = ' + JSON.stringify(this.selectedContactTags, null, 2));
            },
            deep: true,
        },
        selectedOpportunityStatus: {
            handler: function () {
                this.item.opportunityStatus = this.selectedOpportunityStatus.join(',');
            },
        },
        selectedIndustry: {
            handler: function () {
                this.item.orgIndustry = this.selectedIndustry.join(',');
            },
        },
        selectedChannel: {
            handler: function () {
                this.item.orgChannel = this.selectedChannel.join(',');
            },
        },
        selectedOpportunityOwner: {
            handler: function () {
                this.item.ownerUserId = this.selectedOpportunityOwner.ownerUserId;
                this.item.ownerUserName = this.selectedOpportunityOwner.ownerUserName;
                this.item.ownerUserEmail = this.selectedOpportunityOwner.ownerUserEmail;
                // //console.log('selectedOpportunityOwner = ' + JSON.stringify(this.selectedOpportunityOwner.userName, null, 2));
            },
        },
        selectedOpportunityStage: {
            handler: function () {
                this.item.opportunityStageName = this.selectedOpportunityStage.stageName;
                this.item.opportunityStageId = this.selectedOpportunityStage.stageId;
            },
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.package;
        t.loading = true
        t.item = { ...t.datadown };

        // Get Local Authority
        let localAuthoritiesResult = await this.MIX_redisSearch('idx:localAuthority', `*`, 0, 9999);
        t.localAuthority = localAuthoritiesResult.data.documents.map((x) => x.value);
        // Get Countries
        t.countries = Array.from(new Set(t.localAuthority.map((x) => x.country)));

        
        let sources = await t.MIX_redisReadSet('set:sources', true);
        if (sources.code === 1) {
            t.sources = sources.data;
        }
        
        let organisations = await t.MIX_organisations();
        t.organisations = organisations;
        let partnersCustomer = organisations.filter((item) => {
            return item.orgAccountType === 'Customer' || item.orgAccountType === 'Prospect';
        });
        t.partnersCustomer = partnersCustomer;

        let contacts = await t.MIX_contacts();
        t.contacts = contacts;

        if (this.item.ownerUserId !== '' && this.item.ownerUserId !== undefined && this.item.ownerUserId !== null) {
            this.selectedOpportunityOwner = { ownerUserId: this.datadown.ownerUserId, ownerUserName: this.datadown.ownerUserName, ownerUserEmail: this.datadown.ownerUserEmail };
        } else {
            this.selectedOpportunityOwner = this.MIX_getCurrentOwner();
        }
        let users = await t.MIX_users();
        t.users = users.map((x) => {
            return {
                ownerUserId: x.id,
                ownerUserName: x.userName,
                ownerUserEmail: x.userEmail,
            };
        });
        // //console.log("users = " + JSON.stringify(users, null, 2));

        let opportunityStatuses = await t.MIX_redisReadSortedSet('sset:leadStatus', 0, 9999);
        if (opportunityStatuses.code === 1) {
            t.opportunityStatuses = opportunityStatuses.data;
        }
        let opportunityStages = await this.MIX_redisSearch('idx:stages', `*`, 0, 9999);
        opportunityStages = opportunityStages.data.documents.map((x) => x.value);
        // //console.log("opportunityStages = " + JSON.stringify(opportunityStages, null, 2));
        t.opportunityStages = opportunityStages.filter((item) => {
            return item.stagePipeline === 'Opportunities';
        })
        t.opportunityStages = t.opportunityStages.map((x) => {
            return {
                stageId: x.id,
                stageName: x.stageName,
            };
        })
        // this.selectedOpportunityStatus = this.item.opportunityStatus.split(',');
        t.loading = false
    },
    mounted() {},
};
</script>