<template>
    <v-card>
        <v-toolbar class="pa-0 ma-0" color="primary" elevation="0">
            <div >
                <v-icon class="white--text">oomph-map</v-icon>
            </div>
            <div class="text-left white--text text-h5 pl-2 font-weight-light"><span v-if="itemNew == false">Edit</span><span v-else>Add</span> New Component</div>
            <v-spacer />
            <div>
                <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="cancelItem" depressed>
                    <v-icon>oomph-multiply</v-icon>
                </v-btn>
            </div>
        </v-toolbar>
        <v-card-text class="grey lighten-3 px-4">
            <v-row class="py-2 pt-4 px-0" dense>
                <v-col cols="12">
                    <form>
                        <v-row dense>
                            <v-col cols="12" class="d-flex align-center">
                                <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                    <v-autocomplete
                                        label="Component"
                                        v-model="selectedComponent"
                                        :rules="rules.contactOrganisation"
                                        :items="components"
                                        item-text="componentName"
                                        return-object
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        clearable
                                        @click:clear="clearSelectedComponent()"
                                    ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-text-field label="Number of Credits" v-model="item.componentCredits" type="number" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('addressLine1')"></v-text-field>
                            </v-col>
                            
                        </v-row>
                    </form>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="grey lighten-1 pa-3 pr-4 d-flex justify-right align-center">
                <v-spacer />
                <div>
                    <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="cancelItem"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>
                <div class="ml-2">
                    <v-btn class="primary white--text font-weight-bold" depressed @click="saveItem(item)"> <v-icon class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
                </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import schema from '@/schema.js';

export default {
    name: 'componentItem',
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        activeitem: {
            Type: Object,
            Default: null,
        },
    },
    // * DATA
    data: () => ({
        assignedTo: [],
        key: 'componentItem',
        itemNew: true,
        schema: {},
        package: {},

        //org dropdown
        index: 'idx:componentItems',

        selectedComponent: null,

        //meeting dropdowns
        components: [],

        // new action object
        item: {},

        rules: {},
    }),
    methods: {
        updateItem() {
            this.$emit('update', this.item);
        },
        clearSelectedComponent() {
            setTimeout(() => {
                this.selectedComponent = { id: '', componentName: '', componentCode: '', componentType: '', componentId: '' };
            }, 1);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem(item) {
            let t = this;
            // // if no id then generate one
            // if (item.id !== undefined && item.id !== null && item.id !== '') {
            //     // do nothing
            // } else {
            //     item.id = t.MIX_generateId();
            // }
            let componentItem = {
                id: t.MIX_generateId(),
                componentId: item.componentId,
	            componentName: item.componentName,
                componentCategory: item.componentCategory,
                componentType: item.componentType,
                componentCredits: item.componentCredits,
                packageId: t.package.id, 
                deleted: 0,
            };
            let result = await this.MIX_createItem(componentItem, this.key, this.itemNew);
            if (result.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Saved', show: true });
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Error - Please retry', show: true });
            }
            this.item = this.schema;
            this.$emit('close');
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.package = { ...this.datadown };
                if (JSON.stringify(t.activeitem) === '{}') {
                    t.item = { ...t.schema };
                    this.item.packageCode = this.package.packageCode;
                    this.item.packageId = this.package.id;
                } else {
                    t.item = { ...t.activeitem };
                    this.selectedComponent = {
                        id: this.item.componentId,
                        componentCategory: this.item.componentCategory,
                        componentName: this.item.componentName,
                        componentType: this.item.componentType,
                    };
                    t.itemNew = false
                }
            },
            deep: true,
        },
        selectedComponent: {
            handler: function () {
                this.item.componentCategory = this.selectedComponent.componentCategory;
                this.item.componentName = this.selectedComponent.componentName;
                this.item.componentType = this.selectedComponent.componentType;
                this.item.componentId = this.selectedComponent.id;
            },
            deep: true,
        },
        activeitem: {
            handler: function () {
                let t = this;
                t.item = { ...t.activeitem };
                this.selectedComponent = {
                    id: this.item.componentId,
                    componentName: this.item.componentName,
                    componentCategory: this.item.componentCategory,
                    componentType: this.item.componentType,
                };
                t.itemNew = false
            }, deep: true
        },
    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.componentItem;
        t.package = { ...t.datadown };
        if (JSON.stringify(t.activeitem) === '{}') {
            t.item = { ...t.schema };
            this.item.packageCode = this.package.packageCode;
            this.item.packageId = this.package.id;
        } else {
            t.item = { ...t.activeitem };
            this.selectedComponent = {
                id: this.item.componentId,
                componentName: this.item.componentName,
                componentCategory: this.item.componentCategory,
                componentType: this.item.componentType,
            };
            t.itemNew = false
        }

        // Get Components
        let components = await this.MIX_components(''); // Add Users to Dropdown for Owner
        this.components = components.map((x) => {
            return {
                id: x.id,
                componentName: x.componentName,
                componentCategory: x.componentCategory,
                componentType: x.componentType,
            };
        });
    },
    mounted() {
        let t = this;
    },
};
</script>