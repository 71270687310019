<template>
    <v-row fluid fill-height class="d-flex flex-column align-start appbackground" style="height: 100% !important" no-gutters>
		<v-col :cols="$vuetify.breakpoint.mdAndUp ? 9 : 12" class="mt-5 px-2">
			<v-row fill-height class="" dense>
				<v-col cols="12" class="rounded text-left pb-0">
					<v-card :class="$vuetify.breakpoint.mdAndUp ? 'white' : 'white'" class="pa-3 mb-0 pb-1 elevation-0">
						<div class="">
							<div :class="$vuetify.breakpoint.smAndDown ? 'text-h6' : 'text-h6 px-1'" class="primary--text text-h5">Lets Get Started</div>
							<div :class="$vuetify.breakpoint.smAndDown ? 'body-2' : 'body-2 px-1'" class="my-3">
								Hi <strong>{{ GET_FIREBASE_userAuth.displayName }}</strong>
								and welcome to the Oomph Portal.
								<div v-if="$vuetify.breakpoint.mdAndUp"  class="mt-1">This App has been custom built for Oomph, and we are just getting started, so expect more features as we continue to build this software. Below you will find some areas to get started, and you can use the menu on the left hand side to navigate through the app.</div>
							</div>
						</div>
					</v-card>
				</v-col>

                <!-- <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="3" class="pt-0 mt-0">
                    <v-row class="mt-1" dense>
                        <v-col class="" cols="12">
                            <v-card style="height: 100% !important" class="pa-3 white elevation-0">
                                <div class="d-flex align-center">
                                    <div><v-icon class="primary--text" medium>oomph-business-network</v-icon></div>
                                    <div class="text-h6 font-weight-light primary--text ml-2">Sales Pipeline</div>
                                    <v-spacer />
                                    <v-btn small class="primary white--text " @click="MIX_go('/sales')" depressed>Go <v-icon>oomph-forward</v-icon></v-btn>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" v-for="card in cards" :key="card.id">
                            <v-card v-if="card.userGroup.includes(userGroup)" class="pa-3  elevation-0">
                                <div class="d-flex align-center">
                                    <div><v-icon class="primary--text" medium>{{ card.icon }}</v-icon></div>
                                    <div class="text-h6 font-weight-light primary--text ml-2">{{ card.title }}</div>
                                    <v-spacer />
                                    <v-btn :disabled="card.disabled" @click="MIX_go(card.link)" small class="primary white--text " depressed>Go <v-icon>oomph-forward</v-icon></v-btn>
                                </div>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-col> -->

                <!-- <v-col fill-height v-if="$vuetify.breakpoint.mdAndUp && ( userGroup == 'Sales' )" cols="9" class=" pt-0 mt-0">
                    <v-row fill-height class="mt-1" dense>
                        <v-col cols="6" class="d-flex align-start justify-start">
                            <v-card class=" pa-3" elevation="0" style="width: 100% !important; height: 300px !important">
                                <div class="d-flex align-center">
                                    <div><v-icon class="primary--text" medium>oomph-business-network</v-icon></div>
                                    <div class="text-h6 font-weight-light primary--text ml-2">Leads by Source</div>
                                </div>
                                <v-card-text class="d-flex align-start justify-start flex-column px-0" style="width: 100% !important">
                                    <donut-chart  :seriesdata="leadSources" :color="$vuetify.theme.themes.light.primary" seriestext="Leads" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="6" class="d-flex align-start justify-start">
                            <v-card class=" pa-3" elevation="0" style="width: 100% !important; height: 300px !important">
                                <div class="d-flex align-center">
                                    <div><v-icon class="primary--text" medium>oomph-business-network</v-icon></div>
                                    <div class="text-h6 font-weight-light primary--text ml-2">Leads by Stage</div>
                                </div>
                                <v-card-text class="d-flex align-start justify-start flex-column px-0 " style="width: 100% !important">
                                    <donut-chart  :seriesdata="leadStages" :color="$vuetify.theme.themes.light.primary" seriestext="Leads" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card style="height: 100% !important" class="pa-3 primary elevation-0">
                                <div class="d-flex align-center">
                                    <div><v-icon class="white--text" medium>oomph-bill</v-icon></div>
                                    <div class="text-h6  white--text ml-2">Contracts</div>
                                </div>

                                <v-card-text class="d-flex flex-column">
                                    <div class="text-h3 d-flex  white--text font-weight-bold" >
                                        <div class="d-flex flex-column align-center">
                                            <number :from="0" :to="contractYTD" :duration="1" :delay="0" easing="Power1.easeOut" />
                                            <div class="body-1 pt-2 white--text">YTD</div>
                                        </div>
                                        <v-spacer />
                                        <div class="d-flex flex-column align-center">
                                            <number :from="0" :to="contractMTD" :duration="1" :delay="0" easing="Power1.easeOut" />
                                            <div class="body-1 pt-2 white--text">MTD</div>
                                        </div>
                                        <v-spacer />
                                        <div class="d-flex flex-column align-center">
                                            <number :from="0" :to="contractTotal"  :duration="1" :delay="0" easing="Power1.easeOut" />
                                            <div class="body-1 pt-2 white--text">Overall</div>
                                        </div>
                                        <v-spacer />
                                        <div class="d-flex flex-column align-center">
                                            <number :from="0" :to="contractUser" :duration="1" :delay="0" easing="Power1.easeOut" />
                                            <div class="body-1 pt-2 white--text">Me</div>
                                        </div>
                                        <v-spacer />
                                        <div class="d-flex flex-column align-center">
                                            <number :from="0" :to="contractTarget"  :duration="1" :delay="0" easing="Power1.easeOut" />
                                            <div class="body-1 pt-2 white--text">Target</div>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>


                    </v-row>
                </v-col> -->


                <!-- <v-col fill-height v-else-if="$vuetify.breakpoint.mdAndUp && ( userGroup == 'Accounts' || userGroup == 'Developers' )" cols="9" class=" pt-0 mt-0">
                    <v-row fill-height class="mt-1" dense>
                        <v-col cols="6" class="d-flex align-start justify-start">
                            <v-card class=" pa-3" elevation="0" style="width: 100% !important; height: 100% !important">
                                <div class="d-flex align-center">
                                    <div><v-icon class="primary--text" medium>oomph-business-network</v-icon></div>
                                    <div class="text-h6 font-weight-light primary--text ml-2">Accounts</div>
                                </div>
                                <v-card-text class="d-flex align-start justify-start flex-column px-0" style="width: 100% !important; height: 100% !important">
                                    <donut-chart  :seriesdata="accountStageData" :color="$vuetify.theme.themes.light.primary" seriestext="Leads" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="6">
                            <v-card style="height: 100% !important" class="pa-3 primary elevation-0">
                                <div class="d-flex align-center">
                                    <div><v-icon class="white--text" medium>oomph-bill</v-icon></div>
                                    <div class="text-h6 font-weight-light white--text ml-2">Upcoming Renewals</div> 
                                    <v-spacer />
                                    <div class="text-h4 d-flex  white--text font-weight-bold" >
                                        <number :from="0" :to="expiringContracts.length" :duration="1" :delay="0" easing="Power1.easeOut" />
                                    </div>

                                </div>

                                <v-card-text class="d-flex flex-column pa-0">
                                    <div v-for="(contract, index) in expiringContracts" :key="index" class="d-flex flex-column white mb-2 pa-2 rounded" >
                                        <div class="d-flex">
                                            <div class="font-weight-bold primary--text">{{ contract.orgName }}</div>
                                        </div>
                                            <div class="primary--text">{{ contract.contractTitle }}</div>
                                        <div class="primary--text">
                                            {{ $moment(contract.contractExpiryDate, 'X').format('DD-MMM-YYYY')}}
                                        </div>
                                    </div>
                                </v-card-text>



                            </v-card>
                        </v-col>

                    </v-row>
                </v-col> -->



            </v-row>
        </v-col>
                <!-- <pre>{{ sources }}</pre> -->


        <!-- // dashboard  -->
		<!-- <v-col :cols="$vuetify.breakpoint.mdAndUp ? 3 : 12" class=" mt-5 pr-2">
            <v-card  class="pa-3 primary elevation-0">
                <div class="d-flex align-center">
                    <div><v-icon class="white--text" medium>oomph-business-network</v-icon></div>
                    <div class="text-h6 font-weight-light white--text ml-2">Upcoming Events</div>
                </div>

                <v-card-text class="d-flex flex-column pa-0">
                    <div v-for="(event, index) in events" :key="index" class="d-flex white mb-2 pa-2 rounded" >
                        <v-icon 
                            :class="
                                event.eventType == 'Activity' ? 'green--text text--lighten-2' : 
                                event.eventType == 'Event' ? 'orange--text' : 
                                event.eventType == 'Exercise' ? 'blue--text text--darken-4' : 
                                event.eventType == 'Live Stream' ? 'blue--text' : 
                                event.eventType == 'Misc' ? 'brown--text' : 
                                event.eventType == 'Oomph TV' ? 'pink--text' : 
                                'grey--text' " 
                                class="mr-1"
                            >oomph-filled-circle</v-icon>
                        <div class="primary--text">{{ event.eventTitle }}: </div>
                        <div v-if="event.eventAllDay !== '1'" class="primary--text pl-2">
                            {{ $moment(event.eventStartDateTime, 'X').format('HH:mm') }} - {{ $moment(event.eventEndDateTime, 'X').format('HH:mm') }}, {{ $moment(event.eventStartDateTime, 'X').format('DD-MMM-YYYY')}}
                        </div>
                        <div v-else-if="event.eventAllDay === '1'" class="primary--text pl-2">
                            {{ $moment(event.eventStartDateTime, 'X').format('DD-MMM-YYYY')}}
                        </div>
                    </div>
                </v-card-text>
            </v-card> -->

            <!-- <leadCustomerTasks :dashboard="true" :datadown="user"/> -->
            <!-- <div class="font-weight-bold primary--text pt-2" >Actions</div>
            <v-btn v-if="itemsFrom > 0" class="mb-2 grey lighten-3" depressed block @click="goBack()">Go Back</v-btn>
            <div v-for="(action, i) in actionItems" :key="i">
                <action-card 
                    class="mb-2" 
                    :datadown="action" 
                    :key="action.id"
                ></action-card> -->
                    <!-- v-on:saveItem="saveAction"
                    v-on:deleteItem="deleteAction"  -->
            <!-- </div>
            <v-btn v-if="itemsTotal !== null && itemsTotal > itemsTo && itemsTotal " class="mt-2 grey lighten-3 mb-2" depressed block @click="loadMore()">Load More</v-btn> -->
            <!-- itemsTo: {{ itemsTo }}
            itemsTotal: {{ itemsTotal }}
            itemsFrom: {{ itemsFrom }} -->


        </v-col>


    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
// import leadCustomerTasks from "@/views/sales/LeadCustomerTasks.vue";
import actionCard from '@/views/sales/TaskCard.vue'
import DonutChart from "@/components/echarts/DonutChart.vue";
export default {
    components: {
		// "leadCustomerTasks": leadCustomerTasks,
        'action-card': actionCard,
        DonutChart: DonutChart,
    },
    data: () => ({
        cards: [
            { icon: 'oomph-versions', title: 'Content', subtitle: 'Looking to create new Content, click New Content or use the menu on the left.', link: '/contents', buttonText: 'New Content', disabled: false, userGroup: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers'] },
            { icon: 'oomph-user-groups', title: 'Contact', subtitle: 'Looking to create a new Contact, click New Contact or use the menu on the left.', link: '/people', buttonText: 'New Contact', disabled: false, userGroup: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers'] },
            { icon: 'oomph-calendar', title: 'Calendar', subtitle: 'Looking to create a new Event, click New Event or use the menu on the left.', link: '/events', buttonText: 'New Event', disabled: false, userGroup: ['Administration', 'Purchasing', 'Marketing', 'Design', 'Developers'] },
            { icon: 'oomph-factory', title: 'Supplier', subtitle: 'Looking to create a new Supplier, click New Supplier or use the menu on the left.', link: '/suppliers', buttonText: 'New Supplier', disabled: false, userGroup: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers'] },
            { icon: 'oomph-hotel-building', title: 'Customer', subtitle: 'Looking to create a new Customer, click New Customer or use the menu on the left.', link: '/customers', buttonText: 'New Customer', disabled: false, userGroup: ['Accounts', 'Sales', 'Administration', 'Purchasing', 'Marketing', 'Installation', 'Production', 'Design', 'Board', 'Developers'] },
            { icon: 'oomph-city', title: 'Organisation', subtitle: 'Looking to create a new Organisation, click New Organisation or use the menu on the left.', link: '/organisations', buttonText: 'New Organisation', disabled: false, userGroup: ['Sales', 'Administration', 'Purchasing', 'Marketing', 'Board', 'Developers'] },


            { icon: 'oomph-news', title: 'News', subtitle: 'Looking to create a new News item, click New News or use the menu on the left.', link: '/news', buttonText: 'New News Item', disabled: false, userGroup: ['Customer'] },
            { icon: 'oomph-poll', title: 'Polls', subtitle: 'Looking to create a new Poll, click New Poll or use the menu on the left.', link: '/polls', buttonText: 'New Polls', disabled: false, userGroup: ['Customer'] },
        ],
        user: {},

        // Contract Data
        contractYTD: 0,
        contractMTD: 0,
        contractTotal: 0,
        contractUser: 0,
        contractTarget: 0,

        // Lead Data
        leadsNew: 0,
        leadsContacted: 0,
        leadsQualified: 0,
        leadsNotQualified: 0,
        leadsTotal: 0,

        // Event Data
        events: [],

        stages: [],
        sources: [],

        accountStages: [],
        accountStageData: [],
        leadStages: [],
        leadSources: [],

        expiringContracts: [],

        actionItems: [],
        itemsFrom: 0,
        itemsTo: 9,
        itemsTotal: null,
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["orgName"],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        userLevel() { return this.GET_FIREBASE_userAccessToken.user_level },
        userGroup() { return this.GET_FIREBASE_userAccessToken.user_group },
        userStatus() { return this.GET_FIREBASE_userAccessToken.user_status },
    },
    methods: {
        async getUser() {
            var t = this;

            var itemId = t.GET_FIREBASE_userAccessToken.user_id 

            ////console.log('user id  = ' + itemId)


			let result = await t.MIX_readItem(itemId, 'users');
			if (result.code === 1) {
				this.user = result.data;
			}
        },

        async getContractData() {
            var t = this; 

            var startOfYear = this.$moment().startOf('year').format('X')
            var startOfMonth = this.$moment().startOf('month').format('X')
            var fourMonthsTime = this.$moment().startOf('month').add(5, 'months').format('X')
            var now = this.$moment().format('X')

            // Get Organisation Contracts
            let contractsResult = await this.MIX_redisOrderedSearch("idx:contracts", `@deleted:0`, 0, 9999, "contractTitle");
            var contracts = contractsResult.data.documents.map((x) => {
                return {
                    id: x.value.id,
                    contractTitle: x.value.contractTitle,
                    contractCommencementDate: x.value.contractCommencementDate,
                    orgName: x.value.orgName,
                    contractExpiryDate: x.value.contractExpiryDate,
                    ownerUserId: x.value.ownerUserId,
                };
            });

            ////console.log('contracts = ' + JSON.stringify(contracts, null, 2))


            contracts.forEach((contract) => {
                this.contractTotal++
                if (contract.contractCommencementDate && contract.contractCommencementDate > startOfYear) {
                    this.contractYTD++
                }
                if (contract.contractCommencementDate && contract.contractCommencementDate > startOfMonth) {
                    this.contractMTD++
                }
                if (contract.ownerUserId && contract.ownerUserId == t.GET_FIREBASE_userAccessToken.user_id) {
                    this.contractUser++
                }

                if (contract.contractExpiryDate > now && contract.contractExpiryDate < fourMonthsTime ) {
                    this.expiringContracts.push(contract)
                }
            })
        },

        async getEventsData() {
            var t = this; 

            // Get Organisation Contracts
            let itemResult = await this.MIX_redisOrderedSearch("idx:myEvents", `@orgId:${t.GET_FIREBASE_userAccessToken.org_id}`, 0, 2, 'eventStartDateTime');
            if (itemResult.code === 1) {
                var events = itemResult.data.documents.map((x) => x.value);
                t.events = events
                
            }


            ////console.log('events = ' + JSON.stringify(events, null, 2))


            // leads.forEach((contract) => {
            //     this.leadsTotal++
            //     if (contract.salesStageId == 'y8DE68kT74AOMR21pRsk') {
            //         this.leadsNew++
            //     }
            //     if (contract.salesStageId == '4Cv6BRjukFBiNJw175FJ') {
            //         this.leadsContacted++
            //     }
            //     if (contract.salesStageId == 'QAfoG4NvoGbTxyDZauNS') {
            //         this.leadsQualified++
            //     }
            //     if (contract.salesStageId == '4l8Vh7nL1x5BrvNKCDsp') {
            //         this.leadsNotQualified++
            //     }
            // })
        },
        async getOrgData() {
            var t = this;


            // Get Organisation Contracts
            let itemResult = await this.MIX_redisSearch("idx:orgs", `@deleted:0  @orgGroupStructure:{Group}`, 0, 9999);
            if (itemResult.code === 1) {
                var accounts = itemResult.data.documents.map((x) => x.value);
                
            }

            t.accountStages.forEach((stage) => {
                var stageLeads = accounts.filter((item) => {
                    return item.orgStageId == stage.id
                })

                stage.stageTotal = stageLeads.length
                console.log(stage.stageName + ' = ' + stage.stageTotal)
            })

            var stages = t.accountStages.filter((item) => {
                return item.stageTotal > 0
            })

            t.accountStageData = stages.map((x) => {
                return {
                    value: parseInt(x.stageTotal),
                    name: x.stageName
                };
            })


        },
        async getLeadsData() {
            var t = this; 

            // Get Organisation Contracts
            let itemResult = await this.MIX_redisSearch("idx:leads", `@deleted:0`, 0, 9999);
            if (itemResult.code === 1) {
                var leads = itemResult.data.documents.map((x) => x.value);
                
            }

            //console.log('leads = ' + JSON.stringify(leads, null, 2))

            t.stages.forEach((stage) => {
                var stageLeads = leads.filter((item) => {
                    return item.salesStageId == stage.id
                })

                stage.stageTotal = stageLeads.length
                //console.log(stage.stageName + ' = ' + stage.stageTotal)
            })

            var stages = t.stages.filter((item) => {
                return item.stageTotal > 0
            })

            t.leadStages = stages.map((x) => {
                return {
                    value: parseInt(x.stageTotal),
                    name: x.stageName
                };
            })

            var sources = []

            t.sources.forEach((source) => {
                var sourceLeads = leads.filter((item) => {
                    return item.salesSource == source
                })

                if (sourceLeads.length > 0) {
                    sources.push(
                        { 
                            name: source,
                            value: sourceLeads.length 
                        }
                    )

                }
            })

            t.leadSources = sources
            // //console.log('sources = ' + JSON.stringify(sources, null, 2))

            


            leads = leads.filter((item) => {
                return item.ownerUserId == t.GET_FIREBASE_userAccessToken.user_id
            })

            leads.forEach((contract) => {
                this.leadsTotal++
                if (contract.salesStageId == 'y8DE68kT74AOMR21pRsk') {
                    this.leadsNew++
                }
                if (contract.salesStageId == '4Cv6BRjukFBiNJw175FJ') {
                    this.leadsContacted++
                }
                if (contract.salesStageId == 'QAfoG4NvoGbTxyDZauNS') {
                    this.leadsQualified++
                }
                if (contract.salesStageId == '4l8Vh7nL1x5BrvNKCDsp') {
                    this.leadsNotQualified++
                }
            })
        },

        async getUserActions() {
            var t = this; 

            // var userId = t.GET_FIREBASE_userAccessToken.user_id

            var searchQuery = ``
            if (this.GET_FIREBASE_userAccessToken.user_group == 'Accounts' || this.GET_FIREBASE_userAccessToken.user_group == 'Developers') {
                searchQuery = `@deleted:0`
            } else if (this.GET_FIREBASE_userAccessToken.user_group == 'Sales') {
                searchQuery = `@actionType:Call|Email @deleted:0 `;
            }
            console.log('searchQuery = ' + searchQuery)

            let itemsResult = await this.MIX_redisOrderedSearch('idx:actions', searchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, 'actionEndDate');
            if (itemsResult.code === 1) {
                ////console.log('got user actions')
                
                this.itemsTotal = itemsResult.data.total;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                itemsResult = itemsResult.data.documents.map((x) => x.value);

                itemsResult.forEach((item) => {
                    switch (true) {
                        case item.actionType === 'Call':
                            item.icon = 'oomph-call'
                            break;
                        case item.actionType === 'Call Back':
                            item.icon = 'oomph-callback'
                            break;
                        case item.actionType === 'Case':
                            item.icon = 'oomph-complaint'
                            break;
                        case item.actionType === 'Email':
                            item.icon = 'oomph-mail'
                            break;
                        case item.actionType === 'Event':
                            item.icon = 'oomph-calendar'
                            break;
                        case item.actionType === 'Meeting':
                            item.icon = 'oomph-meeting'
                            break;
                        case item.actionType === 'Task':
                            item.icon = 'oomph-tasklist'
                            break;
                        case item.actionType === '':
                            item.icon = ''
                            break;

                    }
                })
                itemsResult = itemsResult.filter((item) => {
                    return item.actionCompleted !== '1'
                })
                this.actionItems = itemsResult;
                ////console.log('user actions = ' + JSON.stringify(this.actionItems, null, 2))
            } else {
                
            }
        },
        async loadMore() {
            this.itemsFrom = this.itemsFrom + this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo + this.datatableOptions.itemsPerPage;
            this.getUserActions();
        },
        async goBack() {
            this.itemsFrom = this.itemsFrom - this.datatableOptions.itemsPerPage;
            this.itemsTo = this.itemsTo - this.datatableOptions.itemsPerPage;
            this.getUserActions();

        }

    },
    async mounted() {
        var t = this
        let stages = await this.MIX_redisOrderedSearch('idx:stages', `@deleted:0`, 0, 9999, 'stageValue');
        stages = stages.data.documents.map((x) => x.value);
        // // // // ////console.log("stages = " + JSON.stringify(stages, null, 2));
        t.stages = stages.filter((item) => {
            return item.stagePipeline === 'Leads';
        }) 
        t.accountStages = stages.filter((item) => {
            return item.stagePipeline === 'Accounts';
        })

        let sources = await t.MIX_redisReadSet('set:sources', true);
        if (sources.code === 1) {
            // //console.log('sources = ' + JSON.stringify(sources, null, 2))
            t.sources = sources.data;
        }
        // ////console.log(JSON.stringify(this.GET_FIREBASE_userAccessToken));
        this.getUser()
        this.getUserActions()
        this.getContractData()
        this.getLeadsData()
        this.getEventsData()
        this.getOrgData()
        
        console.log('BASE URL: ' + process.env.VUE_APP_OOMPHSERVER);
    }
}
</script>
