<template>
    <v-card flat outlined>
        <v-col cols="12" class="align-start">
            <v-row class="pa-2 pb-0">
                <v-col cols="12" class="d-flex ma-0 pa-0 pl-1 align-start" >
                    <div >
                        <div class="font-weight-bold text-start body-2 text-truncate primary--text" style="max-width: 180px !important">
                            {{ opportunity.orgName }}
                        </div>
                        <div class="font-weight-bold text-start body-2 text-truncate grey--text" style="max-width: 180px !important">
                            {{ opportunity.salesTitle }}
                        </div>
                        <!-- <div v-if="opportunity.salesDescription" class="font-weight-regular body-2 text-start text-truncate grey--text" style="max-width: 150px !important">
                            {{ opportunity.salesDescription }}
                        </div> -->
                        <div v-if="opportunity.salesValue" class="font-weight-regular grey--text body-2 pr-3 text-start text-uppercase">
                            {{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(opportunity.salesValue) }}
                        </div>

                    </div>
                    <v-spacer />
                    <div>
                        <v-avatar v-if="opportunity.ownerUserName" color="caption primary white--text font-weight-regular" size="30">{{ opportunity.ownerUserName.match(/\b(\w)/g).join('') }}</v-avatar>
                    </div>
                </v-col>
            </v-row>

            <v-row class="pa-2 pt-2" :class="reveal ? 'pb-2' : ''">
                <v-col cols="12" class="d-flex ma-0 pa-0 pr-3 align-center">
                    <div v-if="opportunity.salesPackages !== '' && opportunity.salesPackages !== undefined && opportunity.salesPackages !== null">
                        <div class="d-flex align-center">
                            <div v-for="item in opportunity.salesPackages.split(',')" :key="item">
                                <PackageIcon :packagedata="getPackageDetails(item)" />
                            </div>
                        </div>
                    </div>
                    <v-spacer />
                    <div v-if="opportunity.salesProbability" class="font-weight-regular body-2 pr-3 text-start text-uppercase" :class="opportunity.salesProbability <= '25' ? 'error--text' : opportunity.salesProbability >= '26' && opportunity.salesProbability <= '74' ? 'warning--text' : opportunity.salesProbability > '74' ? 'success--text' : 'grey--text'">{{ opportunity.salesProbability }}%</div>
                    <div><v-icon class="grey--text" size="15" @click="reveal = !reveal">oomph-expand-arrow</v-icon></div>
                </v-col>
            </v-row>
            
            <v-card v-if="reveal === true" class="mt-3 elevation-0" style="height: 100% !important; width: 100% !important">
                <v-divider />
                    <table class="ma-0 pa-0 mt-3 caption grey--text text--darken-2 " border="0" width="100%">
                        <tr>
                            <td class="font-weight-bold" width="40%" align="left">Exp Close:</td>
                            <td class="" width="60%" align="left">{{ MIX_formatDateTime(opportunity.salesEstimatedCloseDate, 'X', 'MMM-YY') }}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold" width="40%" align="left">Owner:</td>
                            <td class="" width="60%" align="left">{{ opportunity.ownerUserName }}</td>
                        </tr>
                    </table>
                    <div class="d-flex justify-stretch pt-3" style="width: 100% !important;">
                        <div v-if="!actionLead" style="width: 48% !important;"><v-btn block depressed @click="openOpportunity" class="caption font-weight-bold primary--text">Account</v-btn></div>
                        <v-spacer />
                        <div style="width: 48% !important;"><v-btn block depressed @click="openOpportunityItem" class="caption font-weight-bold primary--text">Edit</v-btn></div>
                    </div>
            </v-card>
        </v-col>
    </v-card>
</template>

<script>
import PackageIcon from '@/views/sales/PackageIcon.vue'

export default {
    data: () => ({
        opportunity: {},
        reveal: false,
        orgType: '',
    }),
    components: {
        PackageIcon,
    },
    props: {
        datadown: {
            type: Object,
            default: () => ({}),
        },
        actionLead: {
            type: Boolean,
            default: false,
        },
        packages: {
            type: Array,
            default: () => [],
        },
    },
    computed: {},
    methods: {
        openOpportunity() {
            // ////console.log('openOpportunity');
            this.$emit('openopportunity', this.opportunity);
        },
        openOpportunityItem() {
            this.$emit('openopportunityitem', this.opportunity);
        },
        getPackageDetails(id) {
            let itemPackage = this.packages.find((item) => item.id === id);
            if (itemPackage !== '' && itemPackage !== undefined && itemPackage !== null) {
                return itemPackage;
            }
            // return this.packages.find((item) => {
            //     if (item.id === id) {
            //         return item
            //     } else {
            //         return '';
            //     }
            // });
        },
    },
    async created() {
        let t = this;
        this.opportunity = { ...this.datadown };
        // ////console.log('packages : ', this.packages);
        // // ////console.log("opportunity = " + JSON.stringify(this.opportunity, null, 2));
        let itemResult = await this.MIX_readItem(this.opportunity.orgId, 'org');
        if (itemResult.code === 1) {
            // this.$emit('edit', itemResult.data);
            // // ////console.log("result = " + JSON.stringify(itemResult.data))
            this.orgType = itemResult.data.orgAccountTypes
        }
    },
};
</script>
