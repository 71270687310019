<template>
    <div class=""
        style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey lighten-1 py-4">
                <div class="mx-3 d-flex align-center justify-end" style="width: 100% !important">
                    <v-btn text class="primary white--text" @click="saveItem"><v-icon class="mr-2">oomph-map</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <!-- Main view -->
        <v-row dense class="mx-2">
            <v-col cols="12">
                <v-col cols="12" sm="12" class="">
                    <div class="text-left primary--text text-h6 font-weight-light">Access to platform</div>
                    <div class="text-left text-body-2 font-weight-bold">Check to disable access</div>
                    <v-checkbox v-model="item.orgOnDemandDisableAccess" label="Disable access to On Demand" color="primary" true-value="1" false-value="0" hide-details></v-checkbox>
                    <!-- <v-checkbox v-model="item.orgLMSAccess" label="Access to LMS" color="primary" true-value="1" false-value="0" hide-details></v-checkbox> -->
                    <v-checkbox v-model="item.orgResidentsDisableAccess" label="Disable access to Residents" color="primary" true-value="1" false-value="0" hide-details></v-checkbox>
                    <v-checkbox v-model="item.orgOomphCalDisableAccess" label="Disable access to Oomph Calendar" color="primary" true-value="1" false-value="0" hide-details></v-checkbox>
                    <v-checkbox v-model="item.orgNewsDisableAccess" label="Disable access to News" color="primary" true-value="1" false-value="0" hide-details></v-checkbox>
                </v-col>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'OrganisationAccess',
    props: {
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data: () => ({
        dialogAction: false,
        dialogActionKey: 0,
        item: {},
        key: 'access',
        refreshitems: 0
    }),
    methods: {
        saveItem() {
            let t = this;
            t.$emit('save', t.item);
            t.editmode = false;
        },
    },
    async created() {
        let t = this;
        t.item = { ...t.datadown };
    },
};
</script>