<template>
    <div class="" style="padding: 0px !important; margin: 0px !important" >
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <!-- Main Content -->
            <v-col  cols="12" class=" align-start rounded">
                <v-row dense class="d-flex align-end">
                    <v-spacer />
                    <v-btn text @click="MIX_go('/tickets')" class="primary--text font-weight-bold elevation-0">Go To Tickets <v-icon>oomph-forward</v-icon></v-btn>
                </v-row>
                <!-- <div v-if="items.length > 0" class="grey--text pl-3 align-center d-flex">
                    Here are all other Tickets linked with this Customer
                </div> -->
                <v-row v-if="items.length > 0" class="pa-6 pt-0 pb-0">
                    <div class="flex-grow-1">
                        <v-text-field
                            label="Search Tickets "
                            v-model="searchQuery"
                            outlined
                            dense
                            hide-details="auto"
                            background-color="white"
                            color="primary"
                            clearable
                            prepend-inner-icon="oomph-search"
                            @click:clear="searchQuery = ''"
                        ></v-text-field>
                    </div>
                </v-row>

                <v-row v-if="items.length > 0" class="px-3 pb-0 pt-3">
                    <v-col cols="12" class="pt-0">
                        <v-btn-toggle multiple v-model="status" class="  d-flex align-center flex-row">
                            <v-btn class="flex-grow-1" value="Open" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon title="Open"  size="20" color="grey" class="oomph-open-message"></v-icon>
                                <!-- <span v-if="$vuetify.breakpoint.xlOnly">Open</span> -->
                            </v-btn>
                            <v-btn class="flex-grow-1" value="Closed" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon title="Closed" size="20" color="grey" class="oomph-cancel"></v-icon>
                                <!-- <span v-if="$vuetify.breakpoint.xlOnly">Closed</span> -->
                            </v-btn>
                            <v-btn class="flex-grow-1" value="Archived" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon title="Archived"  size="20" color="grey" class="oomph-archive"></v-icon>
                                <!-- <span v-if="$vuetify.breakpoint.xlOnly">Archived</span> -->
                            </v-btn>
                            <v-btn class="flex-grow-1" value="Solved" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon title="Solved"  size="20" color="grey" class="oomph-checkmark-yes"></v-icon>
                                <!-- <span v-if="$vuetify.breakpoint.xlOnly">Solved</span> -->
                            </v-btn>
                            <v-btn class="flex-grow-1" value="Pending" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon title="Pending"  size="20" color="grey" class="oomph-clock-2"></v-icon>
                                <!-- <span v-if="$vuetify.breakpoint.xlOnly">Pending</span> -->
                            </v-btn>
                            <v-btn class="flex-grow-1" value="On Hold" active-class="grey lighten-1 white--text" depressed small>
                                <v-icon title="On Hold"  size="20" color="grey" class="oomph-pause-button"></v-icon>
                                <!-- <span v-if="$vuetify.breakpoint.xlOnly">On Hold</span> -->
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>

                <!-- //* VIEW MODE -->
                <v-row class="pt-1" style="width: 100% !important" no-gutters>
                    <v-col cols="12">
                        <v-card v-if="items.length > 0" class="elevation-0 grey lighten-4 px-3 pt-3" style="height: 80vh !important; overflow-y: scroll !important ">
                            <!-- <pre>{{ items }}</pre> -->
                            <div v-for="(item, i) in items" :key="i">
                                <ticketCard v-on:openticketitem="openItem" class="mb-2" style="" :actionTicket="true" :datadown="item"></ticketCard>
                            </div>
                        </v-card>
                        <div class="grey--text d-flex font-weight-bold text-h5 align-center justify-center" v-if="items.length == 0">
                            NO TICKETS
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            
            <v-dialog v-if="activeTicket" v-model="ticketDialog" width="1366px" style="overflow: hidden !important;" persistent>
                <!-- <div class="d-flex flex-column ma-0 pa-0"> -->
                    <div class="px-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
                        <div class="mb-1">
                            <v-icon class="white--text" large>oomph-bill</v-icon>
                        </div>
                        <div class="white--text text-h6 d-flex align-center">
                            <span class="font-weight-normal ml-2">
                                <span v-if="activeTicket.ticketTitle === ''">New</span>
                                Ticket
                                <!-- <span v-if="item.salesTitle !== ''">: {{ item.salesTitle.substring(0, 40) }}...</span> -->
                            </span>
                        </div>
                        <v-spacer />
                        <div>
                            <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeTicket()" depressed>
                                <v-icon>oomph-multiply</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div class="pa-0 ma-0 white">
                        <Ticket v-on:refreshItems="refreshitems" :pu="true" :key="dialogTicketKey"  :activeticket="activeTicket" v-on:close="closeTicket" v-on:save="createItem" :orgtype="orgtype"/>
                    </div>
                <!-- </div> -->
                <!-- <Ticket v-on:refreshItems="refreshitems" :key="dialogTicketKey"  :datadown="activeTicket" v-on:closeitem="closeTicket" :orgtype="orgtype"/> -->
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import companiesHouse from '@/views/organisations/CompaniesHouse.vue';
import ticketCard from '@/views/sales/TicketCard.vue'
import Ticket from '@/views/tickets/TicketDetails.vue';
export default {
    name: 'OrganisationDetails',
    components: {
        'companies-house': companiesHouse,
        'ticketCard': ticketCard,
        'Ticket': Ticket,
    },
    props: {
        datadown: {
            type: Object,
			default: function () {
				return {};
			},
        },
        dense: {
            type: Boolean,
            default: false,
        },
        itemnew: {
            type: Boolean,
            default: false,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        orgtype: {
            type: String,
            default: '',
        },
        orgIsGroup: {
            type: Boolean, 
            default: false,
        },
        organisations: {
            type: Array, 
            default: () => [],
        }
    },
    // * DATA
    data: () => ({
        // Item
        actions: {},
        items: {},
        key: 'ticket',
        index: 'idx:tickets',
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        datatableOptions: {
            page: 1,
            itemsPerPage: 999,
            sortBy: ['message'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        sortBy: '@message',

        stages: [],
        stage: [],
        activeTicket: {},
        ticketDialog: false,
        dialogTicketKey: 0,
        // Headers to display in the Data Table
        headers: [
            { text: 'Status', value: 'status', sortable: false, hidden: false, expanded: false },
            { text: 'Subject', value: 'subject', sortable: false, hidden: false, expanded: false },
            { text: 'Message', value: 'message', sortable: false, hidden: false, expanded: false },
            { text: 'Type', value: 'type', sortable: false, hidden: false, expanded: false },
            { text: 'Org', value: 'orgName', sortable: false, hidden: false, expanded: false },
            { text: 'Owner Id', value: 'ownerUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Assigned To', value: 'ownerUserName', sortable: false, hidden: false, expanded: false },
            { text: 'Owner Email', value: 'ownerUserEmail', sortable: false, hidden: true, expanded: true },

            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: false, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Created Name', value: 'createdUserName', sortable: false, hidden: false, expanded: false },
            { text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Name', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Name', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Email', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        status: [],

    }),
    computed: {
        ...mapGetters({
            GET_dense: 'GET_dense',
        }),
        inputCols() {
            if (this.listshrunk) {
                return '3';
            } else {
                return '6';
            }
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            var id = {...this.datadown}
            id = id.id
            var orgs = {}
            // ////console.log("id = " + JSON.stringify(id, null, 2))

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                //     searchQuery = '*';
                // } else {
                    searchQuery = `@deleted:0 `;
                // }
            } else {
                if (searchQuery.startsWith('@')) {
                    // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    //     searchQuery = `${searchQuery}* @orgId:${id}}`;
                    // } else {
                        searchQuery = `${searchQuery}* @deleted:0`;
                    // }
                } else {
                    // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    //     searchQuery = `${searchQuery}* @orgId:{${id}}`;
                    // } else {
                        searchQuery = `${searchQuery}* @deleted:0`;
                    // }
                }
            }
            if (this.status !== null && this.status !== undefined && JSON.stringify(this.status) !== '[]') {
                searchQuery = `${searchQuery} @status:${this.status.join('|')}`;
            }


            if (this.orgIsGroup == true && this.organisations.length > 0) {
                orgs = this.organisations 
                orgs = orgs.map((y) => y.id).join("|")
                //console.log('orgs = ' + JSON.stringify(orgs, null, 2))
                searchQuery = `${searchQuery} @orgId:${orgs}`;
            } else {
                searchQuery = `${searchQuery} @orgId:${id}` 
            }


            return searchQuery;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            if (!computedFields.includes('@actionId')) {
                computedFields.push('@actionId');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
    },
    // * METHODS
    methods: {
        // async computedItems() {
        //     var t = this;

        //     var items = t.items

        //     items.forEach((item) => {
        //         let result =  t.MIX_redisSearch('idx:stages', `@id:{${item.id}}`, 0, 9999);
        //         // ////console.log("result = " + JSON.stringify(result, null, 2))
        //     })

        //     return items
        // },
        // * GET ITEMS
        async getItems() {
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            } else {
                // // ////console.log("items = " + JSON.stringify(itemsResult, null, 2));
            }
        },
		// * CREATE ITEM
		async createItem(item) {
			let t = this;
            // var item = t.item
            // ////console.log("creating lead + " + JSON.stringify(item, null, 2))
            
			let result = await t.MIX_createItem(item, t.key, t.itemNew);
			if (result.code === 1) {
				t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
				// t.readItem(item.id);
				// t.$emit("refreshItems");
                t.closeTicket()
			}
		},
        refreshitems() {
            ////console.log("refreshing items")
            this.refreshItems++
        },
        refreshItems: function () {
            this.getItems();
        },

        // * OPEN ITEM
        async openItem(item) {
            ////console.log("open item = " + JSON.stringify(item, null, 2))
            // this.activeTicket = item;
            // this.readItem(this.activeTicket.id)
			// let result = await this.MIX_readItem(item.id, 'ticket');
			// if (result.code === 1) {
			// 	this.activeTicket = result.data;
            // }
            // this.ticketDialog = true
            this.MIX_go('/tickets/' + item.id)
        },
        closeTicket() {
            this.activeTicket = {}
            this.ticketDialog = false
            this.dialogTicketKey++
            this.getItems()
        },

    },
    // * WATCH
    watch: {
		datadown() {
            var t = this;
            // t.actions = { ...t.datadown };
            t.getItems()
            // // ////console.log("item customer = " + JSON.stringify(t.item, null, 2))
		},
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        organisations() {
            var t = this;
            t.getItems()
        }
    },
    // * CREATED
    async created() {
        let t = this;
        t.actions = { ...t.datadown };
		this.getItems();

        // let stages = await this.MIX_redisSearch('idx:stages', `*`, 0, 9999);
        // stages = stages.data.documents.map((x) => x.value);
        // // // ////console.log("stages = " + JSON.stringify(stages, null, 2));
        // t.stages = stages.filter((item) => {
        //     return item.stagePipeline === 'Tickets';
        // })
        // t.stages = t.stages.map((x) => {
        //     return {
        //         stageId: x.id,
        //         stageName: x.stageName,
        //         stageOutcome: x.stageOutcome
        //     };
        // })

    },
};
</script>

<style>
/* .borderBottom {
    border-bottom: 5px solid #019EAF !important;
    border-color: #019EAF !important
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-bottom: 5px solid #019EAF !important;
    border-color: #019EAF !important
} */
</style>
