<template>
    <div class="px-5 appbackground" style="width: 100% !important; height: calc(100vh - 170px) !important;">
        <div class="d-flex align-center py-5">
            <div class="text-h5 font-weight-bold primary--text">Content Interactions</div>
            <v-spacer />
            <div>
                <v-btn class="primary white--text rounded" @click="downloadCSV()" depressed><v-icon class="mr-2">icons8-export-csv</v-icon>Download CSV</v-btn>
            </div>
        </div>
        <v-data-table :loading="loading" must-sort :headers="computedInteractionHeaders" :items="computedInteractionsData" :items-per-page="10" class="elevation-0">
            <template v-slot:[`item.dementiaFriendly`]="{ item }">
                {{ item.dementiaFriendly == "1" ? "Yes" : "No" }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        selectedOrgGroup: {
            type: Object,
            default: () => {
                return {};
            },
        },
        selectedSubOrg: {
            type: Array,
            default: () => {
                return [];
            },
        },
        daterange: {
            type: Object,
            default: () => {
                return {
                    startDate: null,
                    endDate: null,
                };
            },
        },
        filterBy: {
            type: Array,
            default: () => {
                return [];
            },
        },
        divisions: {
            type: Boolean,
            default: () => {
                return false;
            },
        }
    },    
    data: () => ({
        contentItem: [],
        contentItems: [],
        contentTemplateKey: 0,
        loading: false,
        showContentItem: false,
        interactionData: [],
        interactionHeaders: [],
    }),
    watch: {
        daterange: {
            handler: function () {
                let t = this;
                t.getInteractionsStats();
            },
            deep: true,
        },
        filterBy: {
            handler: function () {
                let t = this;
                t.getInteractionsStats();
            },
            deep: true,
        },
    }, 
    computed: {
        computedInteractionsData() {
            return this.interactionData
        },
        computedInteractionHeaders() {
            let headers = [
                { text: "Division", value: "division", align: "start" },
                { text: "Home", value: "orgName", align: "start" },
                { text: "Activities Views", value: "activitiesViews", align: "center" },
                { text: "Activities Downloads", value: "activitiesDownloads", align: "center" },
                { text: "Exercise Views", value: "exerciseViews", align: "center" },
                { text: "Exercise Downloads", value: "exerciseDownloads", align: "center" },
                { text: "Oomph TV Plays", value: "oomphTVPlays", align: "center" },
                { text: "Total", value: "total", align: "center" },
            ]

            if (!this.divisions || this.selectedSubOrg < 1) headers.shift()
            return headers
        }
    },
    methods: {
        async getInteractionsStats() {
            let t = this;
            let query = "";
            let org = ""
            let subOrgs = [];
            t.loading = true;

            let normalizedDownloadFilters = []
            let normalizedViewFilters = []
            
            for (let i = 0; i < t.filterBy.length; i++) {
                const filter = t.filterBy[i].split("_");
                if (filter[0] === "downloads") normalizedDownloadFilters.push(filter[1])
                else if (filter[0] === "views") normalizedViewFilters.push(filter[1])
            }

            let downloadFilters = normalizedDownloadFilters.join("|")
            let viewFilters = normalizedViewFilters.join("|")

            const daterange = t.daterange.startDate && t.daterange.endDate ? `@createdDateTime:[${t.daterange.startDate} ${t.daterange.endDate}]` : '';
            if (t.selectedOrgGroup.id === '3RTRBM9dqrkC4WEsIvhx') {
                //* Returns all stats for all orgs
                query = daterange || ""
                org = ""
            } else {
                //* Returns stats for selected org
                query = `@createdUserOrgId:{${t.selectedOrgGroup.id}} ${daterange}`;
                org = `@id:(${t.selectedOrgGroup.id})`
            }
            if (t.selectedSubOrg.length > 0) {
                //* Returns stats for selected sub orgs
                subOrgs = t.selectedSubOrg.map((org) => org.id).join('|');
                query = `@createdUserOrgId:{${subOrgs}} ${daterange}`;
                org = `@id:(${subOrgs})`
            } 

            let downloadQuery = `${query} ${(downloadFilters) ? ` @contentArea:(${downloadFilters})` : "*"}`
            let viewQuery = `${query} ${(viewFilters) ? ` @contentArea:(${viewFilters})` : ""}`

            let orgs = t.MIX_organisations(null, org);
            let downloads = t.MIX_downloadsOrgs({ searchQuery: downloadQuery });
            let views = t.MIX_viewsOrgs({ searchQuery: viewQuery });

            const res = await Promise.all([downloads, views, orgs]).then(data => {
                return {
                    downloads: data[0].data,
                    views: data[1].data,
                    orgs: data[2]
                }
            })

            console.log(`downloads count: ` + res.downloads.length);
            console.log(`views count: ` + res.views.length);
            console.log(`orgs count: ` + res.orgs.length);

            if (res) {
                // loop through res1 and res2 and add downloads and views together by id
                let temp = {}

                downloads = res.downloads
                views = res.views
                orgs = res.orgs

                for (let i = 0; i < orgs.length; i++) {
                    const org = orgs[i].id
                    if (temp[org] == undefined) {
                        temp[org] = {}
                        temp[org]["orgId"] = org
                        temp[org]["orgName"] = orgs[i].orgName
                        temp[org]["division"] = orgs[i].orgDivision || ""
                        temp[org]["activitiesViews"] = 0
                        temp[org]["exerciseViews"] = 0
                        temp[org]["oomphTVPlays"] = 0
                        temp[org]["activitiesDownloads"] = 0
                        temp[org]["exerciseDownloads"] = 0
                    }     
                }

                for (let i = 0; i < downloads.length; i++) {
                    const org = downloads[i]?.orgId || downloads[i]?.orgName || null 
                    if (!org || temp[org] == undefined) continue

                    if (downloads[i].contentArea.includes('Activities')) temp[org]["activitiesDownloads"] += this.isNumber(downloads[i]['downloadsTotal']);
                    if (downloads[i].contentArea.includes('Exercise')) temp[org]["exerciseDownloads"] += this.isNumber(downloads[i]['downloadsTotal']);
                }

               for (let i = 0; i < views.length; i++) {
                    const org = views[i]?.orgId || views[i]?.orgName || null
                    if (!org || temp[org] == undefined) continue

                    if (views[i].contentArea.includes('Activities')) temp[org]["activitiesViews"] += this.isNumber(views[i]['viewsTotal']);
                    if (views[i].contentArea.includes('Exercise')) temp[org]["exerciseViews"] += this.isNumber(views[i]['viewsTotal']);
                    if (views[i].contentArea.includes('Oomph TV')) temp[org]["oomphTVPlays"] += this.isNumber(views[i]['viewsTotal']);
                }

                let result = Object.values(temp);
                result.forEach(e => e["total"] = e["activitiesViews"] + e["exerciseViews"] + e["oomphTVPlays"] + e["activitiesDownloads"] + e["exerciseDownloads"])
                result.sort((a, b) => b.total - a.total); 
                t.interactionData = result;
            }
            t.loading = false;
        },
        isNumber(n) {
            const num = parseInt(n);
            return !isNaN(num) ? num : 0;
        },
        downloadCSV() {
            let t = this;
            const dateFrom = t.$moment.unix(t.daterange.startDate).format('DDMMMYYYY');
            const dateTo = t.$moment.unix(t.daterange.endDate).format('DDMMMYYYY');
            const dateNow = t.$moment().format('DDMMMYYYY');
            const date = t.daterange.startDate && t.daterange.endDate ? `${dateFrom}_${dateTo}` : dateNow;
            var downloads = t.computedInteractionsData.map(e => {
                let final =  {
                    'Division': e.division,
                    'Home': e.orgName,
                    'Activities Views': e.activitiesViews,
                    'Activities Downloads': e.activitiesDownloads,
                    'Exercise Views': e.exerciseViews,
                    'Exercise Downloads': e.exerciseDownloads,
                    'Oomph TV Plays': e.oomphTVPlays,
                    'Total': e.total,
                }

                if (!this.divisions || this.selectedSubOrg < 1)  delete final.Division
                return final
            })
            var interactionsCSV = t.$papa.unparse(downloads);
            this.$papa.download(interactionsCSV, `Report_Interactions_${date}.csv`, "text/csv");
        },
        createOrg(item){
            const org = item.orgId || item.orgName
            let def = {
                orgId: org,
                orgName: item.orgName,
                division: item?.orgDivision || "",
                activitiesViews: 0,
                exerciseViews: 0,
                oomphTVPlays: 0,
                activitiesDownloads: 0,
                exerciseDownloads: 0,
            }
            return def
        },
    },
    created() {
        let t = this;
        t.getInteractionsStats();
    },
};
</script>
