<template>
    <v-card flat outlined :style="action.actionDate !== '' && action.actionDate < $moment().format('X') ? 'border: 1px solid red !important' : ''">
        <v-col cols="12" class="align-start">
		    <v-row v-if="!editMode" class="pa-2">
               
		    	<v-col cols="2" class="d-flex ma-0 pa-0 pr-3 justify-center align-center">
                    <div >
                        <v-icon :class="action.icon" color="grey" size="40"></v-icon>
                    </div>
                </v-col>
		    	<v-col cols="10" class="ma-0 pa-0 pl-1   d-flex align-start">
                    <div>
                        <div class="font-weight-bold text-start body-2 primary--text">
                            {{ action.orgName }}
                        </div>
                        <div class="font-weight-medium text-start body-2 grey--text">
                            <span class="error--text caption font-weight-bold" v-if="action.actionDate !== '' && action.actionDate < $moment().format('X')">OVERDUE</span>
                            {{ action.actionSubject }}
                        </div>
                    </div>
                    <v-spacer />
                    <div class="font-weight-bold text-end body-2 grey--text">
                        <span class="pr-3">{{ MIX_formatDateTime(action.actionDate, 'X', 'DD-MMM-YYYY') }} </span>
                        <!-- <v-spacer /> -->
                        <span 
                            class="font-weight-regular caption pr-3 text-start text-uppercase"
                            :class="action.actionPriority === 'Urgent' ? 'error--text font-weight-bold' : action.actionPriority === 'High' ? 'error--text' : action.actionPriority === 'Medium' ? 'warning--text' : action.actionPriority === 'Low' ? 'info--text' : 'grey--text'"
                            >{{ action.actionPriority }}</span>
                    </div>
                    <div class="pt-0 pr-2">
                        <!-- <v-btn icon> -->
                            <v-icon @click="changeActionCompleted()" :color="action.actionCompleted == '0' ? 'grey' : action.actionCompleted === '1' ? 'primary' : ''">oomph-ok</v-icon>
                        <!-- </v-btn> -->
                    </div>
                </v-col>
            </v-row>
		    <v-row v-if="editMode" class="pa-2">
                
		    	<v-col cols="2" class="d-flex ma-0 pa-0 pr-3 justify-center align-center">
                    <div >
                        <v-icon :class="action.icon" color="grey" size="40"></v-icon>
                    </div>
                </v-col>
		    	<v-col cols="9" class="ma-0 pa-0 pl-1 pt-1 pr-3 align-start">
                    <v-row dense>
                        <!-- <div class="font-weight-medium text-start body-2 grey--text">
                            {{ action.actionSubject }}
                        </div> -->
                        <v-col cols="12" xs="12">
                            <v-text-field 
                                label="Subject *" 
                                v-model="action.actionSubject" 
                                outlined 
                                dense 
                                hide-details="auto" 
                                background-color="white" 
                                color="primary" 
                                clearable 
                                @click:clear="clearItem('actionSubject')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field label="Action Date *" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" clearable @click:clear="clearItem('actionStartDate')"></v-text-field>
                                </template>
                                <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-select 
                                label="Priority *" 
                                v-model="action.actionPriority"  
                                :items="priority" 
                                required 
                                outlined 
                                dense 
                                hide-details="auto" 
                                background-color="white" 
                                color="primary" 
                                clearable 
                                :menu-props="{ top: false, offsetY: true }" 
                                :attach="true" 
                                @click:clear="clearItem('actionPriority')">
                            </v-select>
                        </v-col>
                        <!-- <div v-if="action.actionDescription" class="font-weight-regular body-2 text-start text-truncate grey--text" style="max-width: 250px !important">
                            {{ action.actionDescription }} 
                        </div> -->
                        <!-- <div class="d-flex font-weight-bold text-start body-2 grey--text">
                            <span>{{ MIX_formatDateTime(action.actionDate, 'X', 'DD-MMM-YYYY') }} </span>
                            <v-spacer />
                            <span 
                                class="font-weight-regular caption pr-3 text-start text-uppercase"
                                :class="action.actionPriority === 'Urgent' ? 'error--text font-weight-bold' : action.actionPriority === 'High' ? 'error--text' : action.actionPriority === 'Medium' ? 'warning--text' : action.actionPriority === 'Low' ? 'info--text' : 'grey--text'"
                                >{{ action.actionPriority }}</span>
                        </div> -->
                    </v-row>
                </v-col>
		    	<v-col cols="1" class="ma-0 pa-0 pr-1 d-flex justify-center align-center">
                    <div class="d-flex flex-column justify-center align-center">
                        <!-- <span class="error--text caption font-weight-bold" v-if="action.actionDate < $moment().format('X')">OVERDUE</span> -->
                        <v-btn icon @click="changeActionCompleted()" :color="action.actionCompleted == '0' ? 'grey' : action.actionCompleted === '1' ? 'primary' : ''" size="30"><v-icon>oomph-ok</v-icon></v-btn>
                    </div>
                </v-col>
            </v-row>

		    <v-row class="pa-2 pt-0" :class="reveal ? 'pb-2' : ''">
		    	<v-col cols="6" class="d-flex ma-0 pa-0 pl-1 align-start">
                    <div>
                    </div>
                </v-col>
		    	<v-col cols="6" class="d-flex ma-0 pa-0 pr-3 justify-end align-center">
                    <!-- <div v-if="action.actionPriority" class="font-weight-regular body-2 pr-3 text-start text-uppercase" :class="action.actionPriority === 'Urgent' || action.actionPriority === 'High' ? 'error--text' : action.actionPriority === 'Medium' ? 'warning--text' : action.actionPriority === 'Low' ? 'info--text' : 'grey--text'">
                        {{ action.actionPriority }}
                    </div> -->
                    <v-icon class="grey--text" size="15" @click="reveal = !reveal">oomph-expand-arrow</v-icon>
                </v-col>
            </v-row>

            <v-card v-if="reveal === true" class="mt-3 elevation-0" style="height: 100% !important; width: 100% !important">
                <v-divider />
                <div class="d-flex justify-stretch pt-3" style="width: 100% !important;">
                    <div v-if="editMode" style="width: 48% !important;"><v-btn block depressed @click="deleteItem" class="caption font-weight-bold primary--text">Delete</v-btn></div>
                    <v-spacer />
                    <div v-if="!editMode" style="width: 48% !important;"><v-btn block depressed @click="editItem" class="caption font-weight-bold primary--text">Edit</v-btn></div>
                    <div v-if="editMode" style="width: 48% !important;"><v-btn block depressed @click="saveItem" class="caption font-weight-bold primary--text">Save</v-btn></div>
                </div>
            </v-card>
        </v-col>

    </v-card>
</template>

<script>
import moment from 'moment';
export default {
    data: () => ({
        action: {},
        reveal: false,
        editMode: false,
        date1: moment().format("YYYY-MM-DD"),
        dateMenu1: false,
        priority: ['Urgent', 'High', 'Medium', 'Low'],
    }),
	props: {
        datadown: { 
            type: Object, 
            default: () => ({})
        }
	},
    computed: {
        computedDate1() {
            return this.$moment(this.date1, "YYYY-MM-DD").format("DD-MMM-YYYY");
        },
    },
    watch: {
        dateMenu1() {
            this.action.actionDate = this.$moment(this.date1, 'YYYY-MM-DD').format('X');
        },
    },
    methods: {
        openLead() {
            // ////console.log('openLead');
            this.$emit('openlead', this.action);
        },
        changeActionCompleted() {
            var t = this;

            if (t.action.actionCompleted == '0') {
                t.action.actionCompleted = '1'
            } else {
                t.action.actionCompleted = '0'
            }

            t.$emit('saveItem', t.action)
        },
        saveItem() {
            this.$emit('saveItem', this.action)
            this.editMode = false
        },
        deleteItem() {
            this.$emit('deleteItem', this.action)
        },
        editItem() {
            this.editMode = true
        }
    },
    created() {
        this.action = { ...this.datadown };
        if (this.action.actionDate !== '') {
            this.date1 = this.$moment(this.action.actionDate, 'X').format('YYYY-MM-DD')
        } else {
            this.date1 = this.$moment().format('YYYY-MM-DD')
        }
        // ////console.log("action = " + JSON.stringify(this.action, null, 2));
    },

}
</script>
