
<template>
    <v-container class="pa-0 ma-0" fluid fill-height>
        <v-row>
            <v-col cols="12" class="d-flex align-center white pa-6">
                <v-col cols="6">
                    <div class="text-h4 font-weight-thin">{{ contentItem.contentTitle }}</div>
                </v-col>
                <v-col cols="2">
                    <v-rating
                        empty-icon="oomph-star"
                        full-icon="oomph-star-2"
                        length="5"
                        readonly
                        size="25"
                        background-color="grey"
                        color="primary"
                        :value="parseInt(contentItem.contentDifficultyRating)"
                        dense
                    ></v-rating>
                </v-col>
                <v-col cols="2">
                    <v-row class="d-flex justify-center align-center">
                        <v-icon v-if="contentItem.contentDifficultyRating === 'Beginner'" size="28" color="success">oomph-speedometer</v-icon>
                        <v-icon v-if="contentItem.contentDifficultyRating === 'Intermediate'" size="28" color="warning">oomph-speedometer</v-icon>
                        <v-icon v-if="contentItem.contentDifficultyRating === 'Advanced'" size="28" color="error">oomph-speedometer</v-icon>
                        <div v-if="contentItem.contentDifficultyRating === 'Beginner'" class="body-1 px-2 success--text">{{ contentItem.contentDifficultyRating }}</div>
                        <div v-if="contentItem.contentDifficultyRating === 'Intermediate'" class="body-1 px-2 warning--text">{{ contentItem.contentDifficultyRating }}</div>
                        <div v-if="contentItem.contentDifficultyRating === 'Advanced'" class="body-1 px-2 error--text">{{ contentItem.contentDifficultyRating }}</div>
                    </v-row>
                </v-col>
                <v-col cols="2" class="d-flex justify-center align-center">
                    <v-icon size="28" color="success">oomph-good-quality</v-icon>
                    <div class="text-h6 px-2">{{ contentItem.contentLikes }}</div>
                    <v-icon size="28" color="error">oomph-unlike</v-icon>
                    <div class="text-h6 px-2">{{ contentItem.contentDislikes }}</div>
                </v-col>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col class="d-flex pa-0 ma-0" :class="$vuetify.breakpoint.smAndUp ? 'd-flex pa-0 ma-0' : 'd-flex flex-column pa-0 ma-0'">
                <v-col :cols="$vuetify.breakpoint.smAndUp ? '4' : '12'" class="ma-0 pa-0">
                    <v-img :min-height="$vuetify.breakpoint.smAndUp ? '80vh' : '120'" :max-height="$vuetify.breakpoint.smAndUp ? '' : '90'" :src="coverImage"></v-img>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndUp ? '8' : '12'" class="d-flex align-stretch pa-0 ma-0">
                    <v-card height="780" elevation="0" style="overflow: scroll;" width="100%">
                        <v-divider></v-divider>
                        <v-card-text class="pb-0">
                            <v-col cols="12" class="d-flex justify-end align-center py-0 pb-2">
                                <v-row>
                                    <v-col cols="6" class="d-flex justify-start">
                                        <v-btn @click="$emit('close')" depressed small><v-icon>oomph-back</v-icon></v-btn>
                                    </v-col>
                                    <v-col cols="6" class="d-flex justify-end align-center">
                                        <div class="body-1 font-weight-thin">{{ page }} / {{ pageCount }}</div>
                                        <v-btn :disabled="page <= 1" @click="page--" text><v-icon>oomph-back</v-icon></v-btn>
                                        <v-btn :disabled="page >= pageCount" @click="page++" text><v-icon>oomph-forward</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-card-text>
                        <v-card-text class="d-flex justify-center grey lighten-3">
                            <VuePdfEmbed ref="pdfRef" :source="pdfURL" :page="page" height="1000" @rendered="handleDocumentRender" />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Exercise',
    props: {
        showContentItem: {
            type: Boolean,
            default: false,
        },
        contentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        contentItems: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data: () => ({
        coverImage: '',
        instructions: '',
        page: 1,
        pageCount: 1,
        pdfURL: '',
        player: null,
        tags: [],
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'GET_FIREBASE_userAuth',
            GET_brand: 'GET_brand',
        }),
    },
    methods: {
        async getContentItems() {
            let t = this;
            let result = await t.MIX_redisSearch('idx:contentItems', `@itemContentId:{${t.contentItem.id}}`, 0, 9999);
            if (result.code === 1) {
                t.contentItems = result.data.documents;
            }
            //* Filter all content items
            t.contentItems.filter((item) => {
                //* Get cover images
                if (item.value.itemMarker === 'Cover Image') {
                    t.coverImage = item.value.itemURL;
                }
                //* Get PDF items
                if (item.value.itemType === 'PDF') {
                    t.pdfURL = item.value.itemURL;
                }
            });
        },
        getTags() {
            let t = this;
            const array = t.contentItem.contentTags.split(',');
            return (t.tags = array);
        },
        handleDocumentRender() {
            this.isLoading = false;
            this.pageCount = this.$refs.pdfRef.pageCount;
        },
    },
    mounted() {
        let t = this;
        // console.log('mounted')
        t.getContentItems();
        t.getTags();
    },
};
</script>