<template>
    <div class="d-flex flex-column" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">

        <v-row class="apptext--text px-3" dense >
            <!-- FORM -->
            <v-col cols="12" class="d-flex pt-4 align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- DETAILS EDIT -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="12" class="d-flex py-0 body-2 grey--text pb-2 text--darken-2 text-left">
                                    <div>* Required Fields</div>
                                    <v-spacer />
                                    <div class="caption text-right"><strong>ID:</strong> {{ item.id }}</div>
                                </v-col>
                                <!-- TODO - EB - Google autocomplete -->
                                <v-col cols="12" sm="12" class="pb-0" v-if="itemnew">
                                    <vuetify-google-autocomplete prepend-icon="oomph-google" autocomplete="donotcomplete" dense outlined background-color="white" label="Find a Business" name="myapp-name" ref="addressInput" types="" id="map" classname="form-control" v-on:placechanged="getAddressData" country="gb" clearable> </vuetify-google-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" class="d-flex align-center">
                                    <div class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="12">
                                    <v-text-field label="Name" v-model="item.orgName" :rules="rules.orgName" required outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgName')" autocomplete="donoautocomplete"></v-text-field>
                                </v-col>

                                <v-col cols="12" xs="12" :md="inputCols">
                                    <v-select label="Account Type" multiple v-model="selectedAccountType" :rules="rules.orgAccountType" :items="accountTypes" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedAccountTypes()" :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-select>
                                </v-col>

                                <v-col cols="12" :md="inputCols">
                                    <v-select label="Status" v-model="item.orgStatus" :rules="rules.orgStatus" :items="orgStatus" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearFilter('orgStatus')" :menu-props="{ top: false, offsetY: true }" :attach="true" autocomplete="donotautocomplete"></v-select>
                                </v-col>

                                <!-- <v-col cols="12" xs="12" :md="inputCols" class="">
                                    <v-autocomplete label="Country" v-model="filter.country" :items="countries" auto-select-first item-value="authority" outlined dense hide-details="auto" background-color="white" color="primary" no-data-text="Please select a Country first" clearable @click:clear="clearFilter('country')" autocomplete="donotautocomplete"></v-autocomplete>
                                </v-col>

                                <v-col cols="12" xs="12" :md="inputCols" class="">
                                    <v-autocomplete label="Local Authority" v-model="item.orgLocalAuthority" :rules="rules.orgLocalAuthority" :items="computedLocalAuthority" item-text="authority" auto-select-first item-value="authority" outlined dense hide-details="auto" background-color="white" color="primary" no-data-text="Please select a Country first" clearable @click:clear="clearItem('localAuthorities')" autocomplete="donotautocomplete"></v-autocomplete>
                                </v-col> -->
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" xs="12" :md="inputCols" class="">
                                    <v-btn height="40" block @click="dialogCompaniesHouse = true" class="grey--text text--lighten-2" depressed color="primary" :disabled="item.orgName === ''">Companies House Lookup</v-btn>
                                </v-col>
                                <v-col cols="12" xs="12" :md="inputCols" class="">
                                    <v-text-field label="Registered Name" v-model="item.orgRegisteredName" disabled :rules="rules.orgRegisteredName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgRegisteredName')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" :md="inputCols" class="">
                                    <v-text-field label="Registration Number" v-model="item.orgRegistrationNumber" disabled :rules="rules.orgRegistrationNumber" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgRegistrationNumber')"></v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" xs="12" :md="inputCols" class="">
                                    <v-btn height="40" block @click="dialogXero = true" class="grey--text text--lighten-2" depressed color="primary" :disabled="item.orgName === ''">Xero Lookup</v-btn>
                                </v-col> -->
                                <v-col cols="12" xs="12" :md="inputCols">
                                    <v-select label="Account Stage" v-model="selectedStage" :rules="rules.orgStage" :items="orgStages" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadStages')" :menu-props="{ top: false, offsetY: true }" :attach="true">
                                        <template v-slot:selection="data">
                                            {{ data.item.stageName }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item.stageName }}
                                        </template>
                                    </v-select>
                                    <!-- <v-select label="Opportunity Stage" v-model="item.opportunityStage" :rules="rules.leadStatus" :items="opportunityStages" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('leadStatus')" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select> -->
                                </v-col>
                            </v-row>
                            <!-- GROUP STRUCTURE  -->
                            <v-row dense>
                                <v-col cols="12" sm="12" class="d-flex align-center">
                                    <div class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Group Structure</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text  text--darken-2 font-weight-bold" depressed x-small @click="toggleGroupStructure = !toggleGroupStructure">
                                        <span v-if="!toggleGroupStructure">Show</span>
                                        <span v-if="toggleGroupStructure">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleGroupStructure">
                                    <v-divider class="grey lighten-1"></v-divider>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="toggleGroupStructure" class="d-flex align-start">
                                <v-col cols="12" class="d-flex">
                                    <div class="text-left pt-6 pr-3 grey--text body-2 font-weight-regular">Is this Organisation a Group or part of a Group Structure? </div>
                                <!-- </v-col>
                                <v-col cols="4" class="pa-0 ma-0" > -->
                                    <v-radio-group v-model="item.orgGroupStructure"  hide-details="auto" row dense>
                                        <v-radio class="grey--text" label="Yes" value="1"></v-radio>
                                        <v-radio class="grey--text" label="No" value="0"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <!-- CONTACT DETAILS -->
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Contact Details</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text  text--darken-2 font-weight-bold" depressed x-small @click="toggleContactDetails = !toggleContactDetails">
                                        <span v-if="!toggleContactDetails">Show</span>
                                        <span v-if="toggleContactDetails">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleContactDetails">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>

                                <v-col cols="12" xs="12" :md="inputCols" v-if="toggleContactDetails">
                                    <v-text-field label="Telephone" v-model="item.orgTelephone" :rules="rules.orgTelephone" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgTelephone')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" :md="inputCols" v-if="toggleContactDetails">
                                    <v-text-field label="Other Telephone" v-model="item.orgOtherTelephone" :rules="rules.orgTelephone" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgTelephone')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" :md="inputCols" v-if="toggleContactDetails">
                                    <v-text-field label="Email" v-model="item.orgEmail" :rules="rules.orgEmail" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgEmail')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" :md="inputCols" v-if="toggleContactDetails">
                                    <v-text-field label="Website" v-model="item.orgWebsite" :rules="rules.orgWebsite" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgWebsite')"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- ADDRESS EDIT-->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Address</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text  text--darken-2 font-weight-bold" depressed x-small @click="toggleAddress = !toggleAddress">
                                        <span v-if="!toggleAddress">Show</span>
                                        <span v-if="toggleAddress">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="6" class="text-right"> </v-col>
                                <v-col cols="12" sm="12" v-if="toggleAddress">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Address Line 1" v-model="item.orgAddressLine1" :rules="rules.orgAddressLine1" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgAddressLine1')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Address Line 2" v-model="item.orgAddressLine2" :rules="rules.orgAddressLine2" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgAddressLine2')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Address Line 3" v-model="item.orgAddressLine3" :rules="rules.orgAddressLine3" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgAddressLine3')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Town" v-model="item.orgTown" :rules="rules.orgTown" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgTown')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="County" v-model="item.orgCounty" :rules="rules.orgCounty" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgCounty')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleAddress" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Postcode" v-model="item.orgPostcode" :rules="rules.orgPostcode" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgPostcode')"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- ASSIGNED TO EDIT -->
                        <v-col cols="12" sm="12" class="mt-2">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Assigned to</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text font-weight-bold" depressed x-small @click="toggleOwner = !toggleOwner">
                                        <span v-if="!toggleOwner">Show</span>
                                        <span v-if="toggleOwner">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleOwner">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col v-if="toggleOwner" cols="12" xs="12" :md="inputCols">
                                    <v-autocomplete label="Assigned to" v-model="selectedOwner" :items="users" :item-text="item => item.userName" :rules="rules.orgOwner" auto-select-first return-object outlined :dense="dense" hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedOwner()">
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- ORGANISATOIN INFO EDIT -->
                        <v-col v-if="item.orgAccountTypes.includes('Customer') || item.orgAccountTypes.includes('Prospect')" cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Organisation Info</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text  text--darken-2 font-weight-bold" depressed x-small @click="toggleOrganisationInfo = !toggleOrganisationInfo">
                                        <span v-if="!toggleOrganisationInfo">Show</span>
                                        <span v-if="toggleOrganisationInfo">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="6" class="text-right"> </v-col>
                                <v-col cols="12" sm="12" v-if="toggleOrganisationInfo">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <!-- <v-col v-if="toggleOrganisationInfo" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="No. of Employees" v-model="item.orgEmployees" :rules="rules.orgEmployees" type="number" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgEmployees')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleOrganisationInfo" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="No. of Residents" v-model="item.orgResidents" :rules="rules.orgResidents" type="number" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgResidents')"></v-text-field>
                                </v-col> -->
                                <v-col v-if="toggleOrganisationInfo" cols="12" xs="12" :md="inputCols">
                                    <v-select label="Sectors" multiple v-model="selectedIndustry" :rules="rules.orgIndustry" :items="industries" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearSelectedIndustries()" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
                                </v-col>
                                <v-col v-if="toggleOrganisationInfo" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Turnover" v-model="item.orgTurnover" :rules="rules.orgTurnover" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgTurnover')"></v-text-field>
                                </v-col>
                                <!-- TODO - EB - Change to Autocomplete Dropdown -->
                                <v-col v-if="toggleOrganisationInfo" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Source" v-model="item.orgSource" :rules="rules.orgSource" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgSource')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleOrganisationInfo" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Name of Source" v-model="item.orgSourceName" :rules="rules.orgSourceName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgSourceName')"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- FINANCE & ACCOUNTING EDIT -->
                        <v-col v-if="item.orgAccountTypes.includes('Customer')" cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Account Info</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text  text--darken-2 font-weight-bold" depressed x-small @click="toggleFinance = !toggleFinance">
                                        <span v-if="!toggleFinance">Show</span>
                                        <span v-if="toggleFinance">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleFinance">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" :md="inputCols" v-if="toggleFinance">
                                    <v-text-field label="Account System Number" v-model="item.orgAccountNumber" :rules="rules.orgAccountNumber" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgAccountNumber')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" :md="inputCols" v-if="toggleFinance">
                                    <v-text-field label="Account Rate / Fee" v-model="item.orgAccountCreditLimit" :rules="rules.orgAccountCreditLimit" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgAccountCreditLimit')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleFinance" cols="12" xs="12" :md="inputCols">
                                    <v-select label="Account Frequency" v-model="item.orgAccountFrequency"  :items="frequencies" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgAccountFrequency')" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
                                </v-col>

                                <v-col cols="12" xs="12" :md="inputCols" v-if="toggleFinance">
                                    <v-text-field label="Invoice Email" v-model="item.orgInvoiceEmail" :rules="rules.orgInvoiceEmail" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgInvoiceEmail')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" :md="inputCols" v-if="toggleFinance">
                                    <v-text-field label="Purchasing Email" v-model="item.orgPurchasingEmail" :rules="rules.orgPurchasingEmail" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgPurchasingEmail')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="12" v-if="toggleFinance" class="d-flex align-center">
                                    <div class="">Account On Hold:</div>
                                    <div class="ml-2">
                                        <v-radio-group v-model="item.orgAccountOnHold" row dense> <v-radio label="Yes" value="1"></v-radio> <v-radio label="No" value="0"></v-radio> </v-radio-group>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- //* SOCIAL INFO EDIT-->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Social</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text  text--darken-2 font-weight-bold" depressed x-small @click="toggleSocial = !toggleSocial">
                                        <span v-if="!toggleSocial">Show</span>
                                        <span v-if="toggleSocial">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleSocial">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>

                                <v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Twitter" v-model="item.orgTwitter" :rules="rules.orgTwitter" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgTwitter')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Facebook Link" v-model="item.orgFacebook" :rules="rules.orgFacebook" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgFacebook')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="LinkedIn Profile" v-model="item.orgLinkedIn" :rules="rules.orgLinkedIn" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgLinkedIn')"></v-text-field>
                                </v-col>
                                <v-col v-if="toggleSocial" cols="12" xs="12" :md="inputCols">
                                    <v-text-field label="Instagram Handle" v-model="item.orgInstagram" :rules="rules.orgInstagram" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('orgInstagram')"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Tags Edit -->
                        <v-col v-if="item.orgAccountTypes.includes('Customer') || item.orgAccountTypes.includes('Prospect')" cols="12" sm="12" md="12" class="">
                            <v-row dense>
                                <v-col cols="12" sm="6" class="d-flex align-center">
                                    <div class="text-left primary--text  text--darken-2 text-h6 font-weight-light">Tags</div>
                                    <v-btn class="ml-2 grey lighten-5 primary--text  text--darken-2 font-weight-bold" depressed x-small @click="toggleTags = !toggleTags">
                                        <span v-if="!toggleTags">Show</span>
                                        <span v-if="toggleTags">Hide</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" v-if="toggleTags">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col v-if="toggleTags" cols="12" xs="12" md="12">
                                    <v-combobox multiple small-chips hide-selected label="Tags" v-model="selectedTags" :search-input.sync="tagAutocomplete" @change="tagAutocomplete = ''" :items="orgTags" outlined dense hide-details="auto" background-color="white" color="primary" :menu-props="{ top: false, offsetY: true }" :attach="true" clearable @click:clear="clearItem('quoteChannel')">
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                                <span class="pr-2">
                                                    {{ formatTag(item) }}
                                                </span>
                                                <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                                            </v-chip>
                                        </template>
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <span class="subheading">Create</span>
                                                <v-chip color="grey lighten-3" label small>
                                                    {{ formatTag(tagAutocomplete) }}
                                                </v-chip>
                                            </v-list-item>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>

        <v-spacer />
        <div class="d-flex align-center" elevation="0" style="height: 60px;">
            <v-col cols="12" class="z-index: 100; d-flex mt-1 grey lighten-2">
                <v-col cols="6" class="d-flex justify-start pa-0">
                    <!-- <v-btn v-if="showAddLink" class="grey darken-1 white--text font-weight-bold" depressed @click="showAddLink = false; contentListTable = true"> <v-icon class="mr-2">oomph-back</v-icon><span>Back</span> </v-btn> -->
                </v-col>
                <v-col cols="6" class="d-flex justify-end pa-0">
                    
                    <v-btn @click="closeItem" class="grey darken-1 white--text font-weight-bold" depressed > <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                    <v-btn class="primary white--text font-weight-bold ml-2" depressed @click="saveItem"> <v-icon class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
                </v-col>
            </v-col>
        </div>

        <v-dialog v-model="dialogCompaniesHouse" width="1024">
            <v-card class="mx-auto rounded">
                <companies-house :datadown="item.orgName" :key="dialogCompaniesHouseKey" v-on:updatech="updateCHDetails" v-on:closech="closeCH" />
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogXero" width="1024">
            <v-card class="mx-auto rounded">
                <xero :datadown="item.orgName" :key="dialogXeroKey" v-on:updatech="updateXeroDetails" v-on:closech="closeCH" />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import companiesHouse from '@/views/organisations/CompaniesHouse.vue';
import xero from '@/views/organisations/Xero.vue';
export default {
    name: 'OrganisationDetails',
    components: {
        'companies-house': companiesHouse,
        'xero': xero,
    },
    props: {
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
        dense: {
            type: Boolean,
            default: false,
        },
        itemnew: {
            type: Boolean,
            default: false,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        orgtype: {
            type: String,
            default: '',
        },
    },
    // * DATA
    data: () => ({
        editmode: false,
        users: [],

        // Item
        item: {},

        address: {},

        // Dropdowns
		orgStatus: [],		
        orgStages: [],
        selectedStage: [],
        localAuthority: [],
        countries: [],
        filter: {
			orgStatus: 'Active',
            country: '',
        },
        loading: true,

        // Validation
        rules: {},

        // dialogs
        dialogCompaniesHouse: false,
        dialogCompaniesHouseKey: 0,

        dialogXero: false,
        dialogXeroKey: 0,

        // tags
        orgTags: [], // Required for orgs tags dropdown

        tagAutocomplete: '',

        // Toggles
        toggleFinance: false,
        toggleContactDetails: true,
        toggleGroupStructure: true,
        toggleAddress: true,
        addManualAddress: false,
        toggleOtherAddress: false,
        toggleOrganisationInfo: false,
        addNotesToggle: false,
        toggleOwner: false,
        toggleSocial: false,
        toggleTags: false,
        toggleLogo: false,

        // Form items
        selectedOwner: {},
        selectedAccountType: [],
        selectedIndustry: [],
        selectedFrequency: [],
        selectedTags: [],
        selectedChannel: [],
        accountTypes: [],
        industries: [],
        channels: [],
        frequencies: ['Daily', 'Weekly', 'Fortnightly', 'Monthly', 'Bi-Monthly', 'Quarterly', 'Yearly'],

        showGroup: '',
        showRegion: '',
        showGroupTab: false,
    }),
    computed: {
        ...mapGetters({
            GET_dense: 'GET_dense',
        }),
        inputCols() {
            if (this.listshrunk) {
                return '3';
            } else {
                return '6';
            }
        },
        computedLocalAuthority() {
            if (this.filter.country === '') {
                return [];
            } else {
                return this.localAuthority.filter((item) => {
                    return item.country === this.filter.country;
                });
            }
        },
    },
    // * METHODS
    methods: {
        validateForm() {
            this.rules = {
                orgName: [(v) => !!v || 'Organisation Name Required'],
                orgAccountType: [(v) => !!v || 'Organisation Account Type Required'],
                orgStatus: [(v) => !!v || 'Organisation Status Required'],
                orgTelephone: [(v) => !!v || 'Telephone Required'],
                // orgLocalAuthority: [(v) => !!v || 'Local Authority Required'],
                // orgEmail: [(v) => !!v || 'Email Required'],
                // orgAddressLine1: [(v) => !!v || 'Address Required'],
                // orgAddressLine2: [(v) => !!v || 'Address Required'],
                // orgTown: [(v) => !!v || 'Town Required'],
                // orgCounty: [(v) => !!v || 'County Required'],
                // orgOwner: [(v) => !!v || 'Owner Required'],
            };
            this.$nextTick(() => {
                if (this.$refs.form.validate()) {
                    this.saveItem();
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Please fill in all required fields', show: true });
                }
            });
        },
        getAddressData: function (addressData, placeResultData) {
            let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData));
            // console.log(JSON.stringify(placeResult, null, 2));
            this.item.orgName = placeResult.name;
            this.item.orgAddressLine1 = placeResult.addressLine1;
            this.item.orgAddressLine2 = placeResult.addressLine2;
            this.item.orgAddressLine3 = placeResult.addressLine3;
            this.item.orgTown = placeResult.town;
            this.item.orgCounty = placeResult.county;
            this.item.orgPostcode = placeResult.postcode;
            this.item.orgCountry = placeResult.country;
            this.item.orgLatitude = placeResult.lat;
            this.item.orgLongitude = placeResult.lng;
            this.item.orgTelephone = placeResult.elephone;
            this.item.orgWebsite = placeResult.website;
            // this.showMeetingAddress = true
        },
        // getAddressData: function (placeResultData) {
        //     this.item.orgName = placeResultData.name;
        //     // console.log(JSON.stringify(placeResultData, null, 2))
        //     let premise = placeResultData.address_components.filter((component) => component.types.includes('premise'));
        //     let street_number = placeResultData.address_components.filter((component) => component.types.includes('street_number'));
        //     let route = placeResultData.address_components.filter((component) => component.types.includes('route'));
        //     let sublocality_level_1 = placeResultData.address_components.filter((component) => component.types.includes('sublocality_level_1'));
        //     let sublocality_level_2 = placeResultData.address_components.filter((component) => component.types.includes('sublocality_level_2'));
        //     let locality = placeResultData.address_components.filter((component) => component.types.includes('locality'));
        //     let administrative_area_level_2 = placeResultData.address_components.filter((component) => component.types.includes('administrative_area_level_2'));
        //     let country = placeResultData.address_components.filter((component) => component.types.includes('country'));
        //     let postal_code = placeResultData.address_components.filter((component) => component.types.includes('postal_code'));
        //     let postal_town = placeResultData.address_components.filter((component) => component.types.includes('postal_town'));
        //     let formatted_phone_number = placeResultData.formatted_phone_number;
        //     var addressLine1 = '';
        //     var addressLine2 = '';
        //     // Address Line 1
        //     if (JSON.stringify(premise) !== '[]') {
        //         addressLine1 = premise[0]['long_name'];
        //     }
        //     if (JSON.stringify(street_number) !== '[]') {
        //         addressLine1 = addressLine1 + ' ' + street_number[0]['long_name'];
        //     }
        //     if (JSON.stringify(route) !== '[]') {
        //         addressLine1 = addressLine1 + ' ' + route[0]['long_name'];
        //     }
        //     if (addressLine1 !== null && addressLine1 !== undefined && addressLine1 !== '') {
        //         this.item.orgAddressLine1 = addressLine1.trim();
        //     } else {
        //         this.item.orgAddressLine1 = placeResultData.formatted_address.split(',')[0];
        //     }
        //     // Address Line 2
        //     if (JSON.stringify(sublocality_level_1) !== '[]') {
        //         addressLine2 = sublocality_level_1[0]['long_name'];
        //     }
        //     if (JSON.stringify(sublocality_level_2) !== '[]') {
        //         addressLine2 = addressLine2 + ' ' + sublocality_level_2[0]['long_name'];
        //     }
        //     if (JSON.stringify(locality) !== '[]') {
        //         addressLine2 = addressLine2 + ' ' + locality[0]['long_name'];
        //     }
        //     if (addressLine2 !== null && addressLine2 !== undefined && addressLine2 !== '') {
        //         this.item.orgAddressLine2 = addressLine2.trim();
        //     }
        //     // Address Line 3
        //     this.item.orgAddressLine3 = '';
        //     // Check for Postal Town
        //     if (JSON.stringify(postal_town) !== '[]') {
        //         this.item.orgTown = postal_town[0]['long_name'];
        //     }
        //     // Check for County
        //     if (JSON.stringify(administrative_area_level_2) !== '[]') {
        //         this.item.orgCounty = administrative_area_level_2[0]['long_name'];
        //     }
        //     // Check for Country
        //     if (JSON.stringify(country) !== '[]') {
        //         this.item.orgCountry = country[0]['long_name'];
        //     }
        //     // Check for postcode
        //     if (JSON.stringify(postal_code) !== '[]') {
        //         this.item.orgPostcode = postal_code[0]['long_name'];
        //     }
        //     // Add LAttitude and Longitude
        //     this.item.orgLat = placeResultData.geometry.location.lat;
        //     this.item.orgLng = placeResultData.geometry.location.lng;
        //     // Check for Phone Number
        //     if (formatted_phone_number !== null && formatted_phone_number !== undefined && formatted_phone_number !== '') {
        //         this.item.orgTelephone = formatted_phone_number.replace(/\s/g, '');
        //     }
        //     // Add Website
        //     this.item.orgWebsite = placeResultData.website;
        // },

        cancelItem() {
            this.item = { ...this.datadown };
            this.editmode = false;
            this.$emit('cancel');
        },
        // Save Item
        saveItem() {
            if (JSON.stringify(this.selectedAccountType) !== '[]') {
                this.selectedAccountType = this.selectedAccountType.filter((item) => {
                    return item !== '';
                });
                this.item.orgAccountTypes = this.selectedAccountType.join(',');
            }
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Clear Selected
        clearSelectedAccountTypes() {
            setTimeout(() => {
                this.selectedAccountType = [];
            }, 1);
        },
        // Clear Selected Channel
        clearSelectedChannel() {
            setTimeout(() => {
                this.selectedChannel = [];
            }, 1);
        },
        // Clear Selected Industry
        clearSelectedIndustries() {
            setTimeout(() => {
                this.selectedIndustry = [];
            }, 1);
        },
        // Clear Selected Industry
        clearSelectedFrequencies() {
            setTimeout(() => {
                this.selectedFrequency = [];
            }, 1);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // Clear Item Property
        clearFilter(property) {
            setTimeout(() => {
                this.filter[property] = '';
            }, 1);
        },
		updateCHDetails(value) {
			this.item.orgRegisteredName = value.registeredName
			this.item.orgRegistrationNumber = value.registrationNumber
			this.dialogCompaniesHouse = false;
			this.dialogCompaniesHouseKey++
		},
        updateXeroDetails(value) {
            // console.log(value)
        },
		closeCH() {
			this.dialogCompaniesHouse = false;
			this.dialogCompaniesHouseKey++
		},
        formatTag: function (item) {
            if (item !== null) {
                return item.toLowerCase(); //.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            } else {
                return '';
            }
        },
        getUsers() {
            let t = this;
            t.MIX_firestore_readMany('users', 1).then((users) => {
                t.users = users.data;
            });
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.item);
        },
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.item = { ...this.datadown };
                if (this.item.orgAccountTypes !== '') {
                    this.selectedAccountType = this.item.orgAccountTypes.split(',');
                }
                if (this.item.orgTags !== '') {
                    this.selectedTags = this.item.orgTags.split(',');
                }
                if (this.item.orgIndustry !== '') {
                    this.selectedIndustry = this.item.orgIndustry.split(',');
                }
                if (this.item.orgChannel !== '' && this.item.orgChannel !== null && this.item.orgChannel !== undefined) {
                    // console.log("orgChannel", this.item.orgChannel);
                    this.selectedChannel = this.item.orgChannel.split(',');
                }
                if (this.item.orgStageId !== '' && this.item.orgStageId !== null && this.item.orgStageId !== undefined) {
                    this.selectedStage.stageId = this.item.orgStageId;
                    this.selectedStage.stageName = this.item.orgStageName;
                }
            },
        },
        selectedStage: {
            handler: function () {
                this.item.orgStageName = this.selectedStage.stageName
                this.item.orgStageId = this.selectedStage.stageId
            },
        },
        item: {
            handler: function () {
                if (this.item.orgGroupStructure === '1' && this.item.orgAccountTypes.includes('Customer') || this.item.orgGroupStructure === '1' && this.item.orgAccountTypes.includes('Prospect') ) {
                    this.showGroupTab = true;
                } else {
                    this.showGroupTab = false;
                }
                this.$emit('showGroupTab', this.showGroupTab);
            },
            deep: true,
        },
        selectedAccountType: {
            handler: function () {
                this.item.orgAccountTypes = this.selectedAccountType.join(',');
            },
        },
        selectedIndustry: {
            handler: function () {
                this.item.orgIndustry = this.selectedIndustry.join(',');
            },
        },
        selectedChannel: {
            handler: function () {
                this.item.orgChannel = this.selectedChannel.join(',');
            },
        },
        // Selected Tags
        selectedTags: {
            handler: async function () {
                // Force Title Case on Tags
                for (let i = 0; i < this.selectedTags.length; i++) {
                    this.selectedTags[i] = this.selectedTags[i].toLowerCase(); //replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
                    this.selectedTags[i] = this.selectedTags[i].replace(/[\W_]+/g, ' ');
                    if (this.selectedTags[i] === ' ') {
                        this.selectedTags.splice(i, 1);
                    } else {
                        this.selectedTags[i] = this.selectedTags[i].trim();
                    }
                    if (this.orgTags.indexOf(this.selectedTags[i]) === -1) {
                        let addToSetResult = await this.MIX_redisAddToSet(`set:orgTags`, this.selectedTags[i]);
                        if (addToSetResult.code === -1) {
                            return;
                        }
                    }
                }
                // Update Quote Tags and split out array with commas
                this.item.orgTags = this.selectedTags.join(',');
            },
            deep: true,
        },
        itemnew: {
            handler: function () {
                if (this.itemnew) {
                    this.editmode = true;
                }
            },
        },
    },
    // * CREATED
    async created() {
        let t = this;
        t.item = { ...t.datadown };
        t.loading = true;
        if (t.item.createdDateTime === '') {
            t.editmode = true;
        }
        if (t.orgtype !== 'Organisation') {
            t.item.orgAccountTypes = t.orgtype;
        }
        // Get Local Authority
        let localAuthoritiesResult = await this.MIX_redisSearch('idx:localAuthority', `*`, 0, 9999);
        t.localAuthority = localAuthoritiesResult.data.documents.map((x) => x.value);
        // // Get Countries
        t.countries = Array.from(new Set(t.localAuthority.map((x) => x.country)));
        // Get Account Types
        let accountTypes = await t.MIX_redisReadSet('set:orgAccountTypes', false);
        if (accountTypes.code === 1) {
            t.accountTypes = accountTypes.data;
        }
        // Get Organisation Status SortedSet
        let orgStatus = await t.MIX_redisReadSortedSet('sset:orgStatus', 0, 9999);
        if (orgStatus.code === 1) {
            t.orgStatus = orgStatus.data;
        }

        let orgTags = await t.MIX_redisReadSet('set:orgTags', false);
        if (orgTags.code === 1) {
            t.orgTags = orgTags.data;
        }

        let industries = await t.MIX_redisReadSet('set:orgIndustry', false);
        if (industries.code === 1) {
            t.industries = industries.data;
        }

        let organisations = await t.MIX_organisations();
        if (organisations.code === 1) {
            t.organisations = organisations.data;
        }

        let channels = await t.MIX_redisReadSet('set:channels', false);
        if (channels.code === 1) {
            t.channels = channels.data;
        }


        let orgStages = await this.MIX_redisSearch('idx:stages', `*`, 0, 9999);
        orgStages = orgStages.data.documents.map((x) => x.value);

        t.orgStages = orgStages.filter((item) => {
            return item.stagePipeline === 'Accounts';
        })
        t.orgStages = t.orgStages.map((x) => {
            return {
                stageId: x.id,
                stageName: x.stageName,
            };
        })

        if (t.itemnew) {
            t.editmode = true;
        }
        t.loading = false
        t.item.orgWebsite = '';
        t.getUsers();
    },
};
</script>

<style></style>
