<template>
    <v-card :key="cardKey">
        <v-toolbar class="pa-0 ma-0" color="primary" elevation="0">
            <div class="text-left white--text text-h5 pl-2 font-weight-light">{{ imageEditMode === true ? 'Add Image Item' : 'Edit Image Item' }}</div>
            <v-spacer />
            <div>
                <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeOverlay()" depressed>
                    <v-icon>oomph-multiply</v-icon>
                </v-btn>
            </div>
        </v-toolbar>
        <v-card-text class="grey lighten-3 px-4">
            <v-row class="py-2 pt-4 px-0" dense>
                <v-col cols="12">
                    <form>
                        <v-row dense v-if="showPreview === false">
                            <v-col cols="12" class="d-flex align-center">
                                <div class="text-left primary--text text-h7">Please use the form below to add a Image item:</div>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" class="pb-0 mb-0">
                                <v-text-field label="Name" class="primary--text" background-color="white" v-model="item.itemName" outlined hide-details="auto" dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" class="pb-0 mb-0">
                                <v-select
                                    label="Marker"
                                    class=""
                                    background-color="white"
                                    v-model="item.itemMarker"
                                    :items="markers"
                                    item-text="name"
                                    item-value="type"
                                    hide-details="auto"
                                    outlined
                                    :menu-props="{ top: false, offsetY: true }"
                                    dense
                                ></v-select>
                            </v-col>
                            <v-col v-if="showPreview === false" cols="12" sm="12" md="4" class="pb-0 mb-0">
                                <v-combobox
                                    class=""
                                    small-chips
                                    hide-selected
                                    label="Content Source"
                                    v-model="item.contentSource"
                                    :search-input.sync="contentSourceAutocomplete"
                                    @change="
                                        contentSourceAutocomplete = '';
                                        updateContentSources(item.contentSource);
                                    "
                                    :items="contentSources"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    background-color="white"
                                    color="primary"
                                    :menu-props="{ top: false, offsetY: true }"
                                    :attach="true"
                                    clearable
                                >
                                    <template v-slot:selection="{ attrs, item, selected }">
                                        <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                            <span class="pr-2">
                                                {{ item }}
                                            </span>
                                        </v-chip>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <span class="subheading">Create</span>
                                            <v-chip color="grey lighten-3" label small>
                                                {{ contentSourceAutocomplete }}
                                            </v-chip>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </v-col>
                            <!-- Upload section -->
                            <v-col v-if="imageUploadPanel && showPreview === false" cols="12" class="pa-4 py-6">
                                <v-row>
                                    <v-card v-show="imageUploadPanel" style="width: 100% !important" v-model="panel" class="white elevation-0">
                                        <v-row class="pt-3 px-3">
                                            <v-col cols="6">
                                                <div class="grey--text text--darken-1 pl-1">Please use the box below to upload your Image.</div>
                                            </v-col>
                                        </v-row>
                                        <v-card-text>
                                            <FileUpload
                                                accept="image/jpeg, image/jpg"
                                                label="Select file"
                                                buttonClass="primary"
                                                progressClass="pa-2"
                                                folderLocation="content/images"
                                                :fileName="MIX_generateId()"
                                                :selectedItem="contentItem"
                                                :compressionMaxSizeMB="1"
                                                :compressionmaxWidthOrHeight="1920"
                                                :private="true"
                                                :isProfile="false"
                                                @update="uploadImage"
                                            />
                                        </v-card-text>
                                    </v-card>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="showPreview === true">
                            <v-col cols="12" class="d-flex align-end">
                                <v-spacer />
                                <v-btn class="primary white--text font-weight-bold" @click="showPreview = !showPreview" outlined depressed>
                                    <div v-if="!showPreview">Show Image</div>
                                    <div v-else-if="showPreview">Edit Image</div>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row dense class="white rounded pa-3 fill-height" v-if="showPreview === true">
                            <v-col cols="12" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold white">Name:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.itemName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold white">Marker:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.itemMarker }}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top" class="pl-0 apptext--text font-weight-bold white">Status:</td>
                                                <td width="50%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.itemStatus }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="showPreview === true">
                            <v-col cols="12" class="d-flex justify-center mt-">
                                <v-img max-height="400" :src="item.itemURL" class="greyscale" contain></v-img>
                            </v-col>
                        </v-row>
                    </form>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="grey lighten-1 pa-3 pr-4 d-flex justify-right align-center">
            <v-spacer />
            <div>
                <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="closeOverlay"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
            </div>
            <div class="ml-2">
                <v-btn v-if="showPreview === true" class="primary font-weight-bold white--text" @click="showPreview = !showPreview" outlined depressed>
                    <div v-if="showPreview">Show Image</div>
                    <div v-else-if="!showPreview">Edit Image</div>
                </v-btn>
                <v-btn depressed v-if="showPreview === false" class="primary font-weight-bold" @click="saveContentItem()">
                    <v-icon size="30" class="white--text√">oomph-save</v-icon>
                    Save Content
                </v-btn>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'AddEditImageOverlay',
    props: {
        addImageOverlay: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        markers: {
            type: Array,
            default: () => {
                return [];
            },
        },
        contentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        originalContentId: {
            type: String,
            default: () => {
                return '';
            },
        },
        editMode: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        extendWidth: {
            type: Number,
            default: () => {
                return 400;
            },
        },
        newItem: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
    },
    data: () => ({
        imageUploadPanel: true,
        item: {
            id: '',
            key: '',
            itemContentId: '',
            itemAssetId: '',
            itemName: '',
            itemMarker: '',
            itemStatus: 'Live',
            itemType: '',
            itemURL: '',
            itemFileSizeKb: '',
            itemFormat: '',
            itemWidth: '',
            itemHeight: '',
            itemLengthMins: '',
            itemEncodeStatus: '',
            itemTextHTML: '',
            itemUploadId: '',
            createdDateTime: '',
            createdUserId: '',
            createdUserName: '',
            createdUserEmail: '',
            modifiedDateTime: '',
            modifiedUserId: '',
            modifiedUserName: '',
            modifiedUserEmail: '',
            deletedDateTime: '',
            deletedUserId: '',
            deletedUserName: '',
            deletedUserEmail: '',
            deleted: '',
        },
        contentSourceAutocomplete: '',
        contentSources: [],
        cardKey: 0,
        showPreview: false,
        itemNew: false,
        imageEditMode: false,
        markerItems: ['VIDEO', 'AUDIO', 'IMAGE', 'PDF', 'DOCUMENT', 'OTHER'],
        panel: [0],
        statusItems: ['Draft', 'Live', 'Deleted', 'Archived'],
    }),
    watch: {
        contentItem: {
            handler: function () {
                let t = this;
                t.item = t.contentItem;
                t.showPreview = false;
                t.imageEditMode = true;
            },
            deep: true,
        },
        imageUploadPanel() {
            let t = this;
            if (t.imageUploadPanel === false) {
                t.item.itemURL = '';
            }
        },
    },
    async created() {
        var t = this;
        let sources = await t.MIX_redisReadSet('set:contentSources', true);
        if (sources.code === 1) {
            t.contentSources = sources.data;
        }
        if (t.contentItem.id !== null && t.contentItem.id !== '' && t.contentItem.id !== undefined) {
            if (this.contentItem.contentSources !== '' && this.contentItem.contentSources !== undefined && this.contentItem.contentSources !== null) {
                this.contentSources = this.contentItem.contentSources.split(',');
            }
            t.item = t.contentItem;
            t.showPreview = true;
            t.imageEditMode = true;
        }
    },
    methods: {
        async updateContentSources(group) {
            // console.log("### EventContentAddLink ### updateContentSources ### group", group);
            // If Tag does not exist in array then add it
            if (this.contentSources.indexOf(group) === -1) {
                this.contentSources.push(group);
            }
            // Remove any blank groups from array
            this.contentSources.forEach((item, index) => {
                if (item === '') {
                    this.contentSources.splice(index, 1);
                }
            });
            // // console.log('contentSources = ' + JSON.stringify(this.contentSources, null, 2))
            this.item.contentSources = this.contentSources.join(',');

            let addToSetResult = await this.MIX_redisAddToSet(`set:contentSources`, group);
            if (addToSetResult.code === -1) {
                // return;
            }

            // this.$emit('updateevent', this.event); // Parent - EventContentLinks.vue
        },
        uploadImage(url, file) {
            let t = this;
            if (t.imageEditMode === true) {
                t.item.itemFormat = file.type;
                t.item.itemFileSizeKb = t.MIX_bytesToSize(file.size);
            }
            t.item.itemURL = url;
            t.showPreview = true;
        },
        async saveContentItem() {
            let t = this;
            t.item.key = 'contentItem';
            t.item.itemType = 'Image';
            t.item.itemContentId = t.originalContentId;
            if (t.imageEditMode === true) {
                t.itemNew = false;
                t.item.modifiedDateTime = t.$moment().format('x');
                let updateContentItemResult = await this.MIX_redisUpdateHash('contentItem:' + t.item.id, t.item);
                if (updateContentItemResult.code === 1) {
                    t.closeOverlay();
                    t.$emit('updateItems');
                    t.MIX_alertBox({ color: 'success', message: 'Created content item successfully', timeout: 4000, show: true });
                }
            } else {
                t.itemNew = true;
                t.item.id = t.MIX_generateId();
                t.item.createdDateTime = t.$moment().format('x');
                //* Saves file data to redis
                const update = await this.MIX_createItem(t.item, t.item.key, t.itemNew);
                if (update.code === 1) {
                    t.closeOverlay();
                    t.$emit('updateItems');
                    t.MIX_alertBox({ color: 'success', message: 'Created content item successfully', timeout: 4000, show: true });
                }
            }
            //* Saves file data to redis
            const update = await this.MIX_createItem(t.item, t.item.key, t.itemNew);
            if (update.code === 1) {
                t.closeOverlay();
                t.$emit('updateItems');
                t.MIX_alertBox({ color: 'success', message: 'Created content item successfully', timeout: 4000, show: true });
            }
            t.imageUploadPanel = false;
        },
        closeOverlay() {
            let t = this;
            t.$emit('closeOverlay', false);
            t.cardKey++;
        },
    },
};
</script>