<template>
    <v-card flat outlined rounded-lg>
        <v-col cols="12" class="align-start">
		    <v-row class="pa-2">
		    	<v-col cols="12" class=" ma-0 pa-0 pl-1 align-start">
                        <div v-if="orgIsGroup == true" class="font-weight-bold text-start body-2 primary--text">
                            {{ action.orgName }} 
                        </div>
                        <div class="font-weight-medium text-start body-2 grey--text">
                            {{ action.actionSubject }} 
                        </div>
                        <div class="d-flex font-weight-bold text-start body-2 grey--text">
                            <span>{{ MIX_formatDateTime(action.actionCompletedDateTime, 'X', 'DD-MMM-YYYY') }} </span>
                            <v-spacer />
                            <span 
                                class="font-weight-regular caption pr-3 text-start text-uppercase"
                                :class="action.actionPriority === 'Urgent' ? 'error--text font-weight-bold' : action.actionPriority === 'High' ? 'error--text' : action.actionPriority === 'Medium' ? 'warning--text' : action.actionPriority === 'Low' ? 'info--text' : 'grey--text'"
                                >{{ action.actionPriority }}</span>
                        </div>
                </v-col>
            </v-row>
        </v-col>

    </v-card>
</template>

<script>
export default {
    data: () => ({
        action: {},
        reveal: false,
    }),
	props: {
        orgIsGroup: {
            type: Boolean,
            default: false,
        },
        datadown: { 
            type: Object, 
            default: () => ({})
        }
	},
    computed: {

    },
    methods: {
        openLead() {
            // ////console.log('openLead');
            this.$emit('openlead', this.action);
        },
    },
    created() {
        this.action = { ...this.datadown };
        // ////console.log("action = " + JSON.stringify(this.action, null, 2));
    },

}
</script>
