<template>
    <v-row class="pa-0 ma-0" fill-height>
        <v-row no-gutters class="pb-3 px-3 pt-3 primary darken-2">
            <v-col class="font-weight-bold white--text text-h6 d-flex align-center" v-if="JSON.stringify(item) !== '{}'">
                <div class="mx-3 mb-1"><v-icon class="white--text">oomph-info</v-icon></div>
                <div>
                    <span class="font-weight-light"><strong>Release: </strong>{{ item.releaseVersion }}</span>
                </div>
                <div class="ml-2 font-weight-light" v-if="item.releaseVersion === ''">New</div>
                <v-spacer></v-spacer>
                <div>
                    <v-btn class="ml-2 white--text" plain @click="closeItem" depressed>
                        <v-icon>oomph-multiply</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-col class="pa-0" fill-height cols="auto" style="width: 100% !important">
            <v-tabs background-color="white" v-model="tab" show-arrows>
                <v-tab href="#details">Details</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" fill-height>
                <v-tab-item value="details" class="grey lighten-4">
                    <release-details :newItem="itemNew" dense :datadown="item" v-on:save="createItem" />
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import releaseDetails from '@/views/releasenotes/ReleaseDetails.vue';
import schema from '@/schema.js';
export default {
    name: "Release",
    components: {
        releaseDetails: releaseDetails,
    },
    props: {
        datadown: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data: () => ({
        accountTypes: [],
        addAddressToggle: false,
        addLeadStatusToggle: false,
        addManualAddress: false,
        addOrgInfoToggle: false,
        addOtherAddressToggle: false,
        addressTypes: [],
        addSocialInfoToggle: false,
        computedOrgTags: [],
        editMode: false,
        industries: [],
        item: {},
        itemNew: false,
        key: 'releaseNote',
        listshrunk: false,
        rules: {},
        schema: null,
        tab: 'details',
    }),
    computed: {
        ...mapGetters({
            GET_taskBar: 'GET_taskBar',
            GET_destroy: 'GET_destroy',
        }),
    },
    methods: {
        initialiseItem() {
            let t = this;
            if (JSON.stringify(t.datadown) !== '{}') {
                t.readItem(t.datadown.id);
            } else {
                t.itemNew = true;
                t.editMode = true;
                t.item = { ...t.schema };
                t.item.id = t.MIX_generateId();
            }
        },
        validateItem() {
            let t = this;
            t.rules = {
                releaseVersion: [(v) => !!v || 'Version Required'],
            };
            t.$nextTick(() => {
                if (t.$refs.form.validate()) {
                    this.createItem();
                    this.editMode = false;
                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 2000, message: 'Fill out all the fields correctly in order to proceed.', show: true });
                }
            });
        },
        async createItem(item) {
            let t = this;
            let result = await t.MIX_createItem(item, t.key, t.itemNew);
            if (result.code === 1) {
                t.MIX_alertBox({ color: "success", timeout: 2000, message: "Saved", show: true });
                t.readItem(item.id);
                t.$emit("refreshitems");
            }
        },
        async readItem(itemId) {
            let t = this;
            let result = await t.MIX_readItem(itemId, t.key);
            if (result.code === 1) {
                t.item = result.data;
                t.itemNew = false;
                t.loading = false;
            }
        },
        async destroyItem() {
            let result = await this.MIX_destroyItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        async deleteItem() {
            let result = await this.MIX_deleteItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        async restoreItem() {
            let result = await this.MIX_restoreItem(this.item.id, this.key);
            if (result.code === 1) {
                this.readItem(this.item.id);
            }
        },
        closeItem() {
            this.$emit('closeitem');
        },
        cancelItem() {
            this.readItem(this.item.id);
        },
    },
    watch: {
        datadown() {
            this.initialiseItem();
        },
        computedOrgTags(val) {
            var t = this;
            var splitVal = [];
            splitVal = t.item.orgTags.split(',');
            return splitVal;
        },
    },
    async created() {
        let t = this;
        t.loading = true;
        t.schema = schema.releaseNote;
        t.initialiseItem();
        t.loading = false;
    },
};
</script>

<style scoped>
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 22px;
}
</style>
