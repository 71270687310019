<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field label="Search Stages " v-model="searchQuery" outlined dense hide-details="auto" background-color="white" color="primary" clearable prepend-inner-icon="oomph-search" @click:clear="searchQuery = ''"></v-text-field>
            </div>
            <div class="ml-2">
                <v-select label="Filter by Pipeline" background-color="white" :menu-props="{ top: false, offsetY: true }" :attach="true" v-model="filter.pipeline" :items="pipelines" hide-details="auto" outlined dense clearable></v-select>
            </div>
            <!-- <div class="ml-2">
                <v-select label="Filter by Status" background-color="white" v-model="status" :items="computedStatus" outlined dense clearable></v-select>
            </div> -->
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>oomph-available-updates</v-icon>
            </v-btn>
            <div class="ml-2">
                <v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>oomph-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <v-row class="pa-3 mt-1">
            <!-- TABLE BASED LIST -->
            <v-data-table :item-class="itemRowBackground" sortable dense class="" style="width: 100%"   :footer-props="{ 'items-per-page-options': [5, 10, 20, 30, 40, 50] }" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions"
                >>
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="Edit" value="edit" small>
								<v-icon class="grey--text text--darken-3">oomph-edit</v-icon>
							</v-btn>
                            <v-btn title="Permanently Delete" value="destroy" small>
                                <v-icon class="error--text">oomph-delete-trash</v-icon>
                            </v-btn>
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                            <v-btn title="Undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                        <!-- <div class="mb-5 mx-5"><v-switch v-model="showImages" color="primary" label="Images" hide-details="auto" inset></v-switch></div>
					<div class="mb-5"><v-switch v-model="toggleDeletedItems" color="error" label="Deleted" hide-details="auto" inset></v-switch></div>-->
                    </div>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-icon class="grey--text text--darken-2 ml-5" @click="editItem(item.id)" v-if="toggleFeatures.includes('edit')">oomph-edit</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="showDeleteDialog(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon>
                    <v-icon style="border: 1px red solid; border-radius: 3px" class="error--text ml-5" @click="destroyItem(item.id)" v-if="toggleFeatures.includes('destroy')">oomph-delete-trash</v-icon>
                </template>
            </v-data-table>
        </v-row>

        <v-dialog v-model="deleteDialog" width="700px" height="400px" style="overflow: hidden !important">
            <v-card height="400px" class="d-flex flex-column grey lighten-3">
                <div class="pa-3 py-8 ma-0 primary d-flex align-center px-4" elevation="0" style="height: 50px;">
                    <div class="mx-3 mb-1">
                        <v-icon class="white--text">oomph-business-network</v-icon>
                    </div>
                    <div class="font-weight-bold white--text text-h6 d-flex align-center">
                        <span class="font-weight-light">
                            <strong>Delete Stage </strong>
                            <!-- <span v-if="item.leadTitle !== ''">{{ item.leadTitle.substring(0, 40) }}...</span> -->
                        </span>
                        <!-- <div class="ml-2 font-weight-light" v-if="item.leadTitle === ''">New</div> -->
                    </div>
                    <v-spacer />
                    <div>
                        <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="deleteDialog = false" depressed>
                            <v-icon>oomph-multiply</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-card-text class="grey lighten-3 pa-0">
                    <v-row class="pa-0 ma-0" fill-height>
                        <v-col class="pa-0" fill-height cols="auto" style="width: 100% !important">
                            <!-- <lead-details :datadown="item" v-on:save="createItem" /> -->
                            <div class="font-weight-normal pa-3 text-h7 grey--text">
                                Please select which stage any Leads / Opportunities / Accounts in this stage will be moved to
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <v-select  label="New Stage" v-model="newStage" :items="stages" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('newStage')" :menu-props="{ top: false, offsetY: true }" :attach="true">
                                <template v-slot:selection="data">
                                    {{ data.item.stageName }}
                                </template>
                                <template v-slot:item="data">
                                    {{ data.item.stageName }}
                                </template>
                            </v-select>
                        </v-col>
                        <pre>{{ newStage }}</pre>
                    </v-row>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="grey lighten-1 pa-2">
                    <v-col cols="12" class="d-flex">
                        <v-col cols="6" class="d-flex justify-start pa-0">
                            <!-- <v-btn v-if="showAddLink" class="grey darken-1 white--text font-weight-bold" depressed @click="showAddLink = false; contentListTable = true"> <v-icon class="mr-2">oomph-back</v-icon><span>Back</span> </v-btn> -->
                        </v-col>
                        <v-col cols="6" class="d-flex justify-end pa-0">
                            <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="deleteDialog = false"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                            <v-btn class="primary white--text font-weight-bold ml-2" :disabled="!newStage" depressed @click="moveStages"> <v-icon class="mr-2">oomph-save</v-icon><span>Save</span> </v-btn>
                        </v-col>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        datadown: {
            type: Object,
            default: () => ({}),
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        key: 'stage',
        index: 'idx:stages',
        org: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 5,

        pipeline: '',
        pipelines: ['Leads', 'Opportunities', 'Accounts'],
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'Name', value: 'stageName', sortable: false, hidden: false, expanded: false },
            { text: 'Pipeline', value: 'stagePipeline', sortable: false, hidden: false, expanded: false },
            { text: 'Value', value: 'stageValue', sortable: false, hidden: false, expanded: false },
            { text: 'Outcome', value: 'stageOutcome', sortable: false, hidden: false, expanded: false },
            

            { text: 'Created Date', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Created Name', value: 'createdUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Name', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Name', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Email', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
        ],
        // Fields to Retrieve from Database
        //fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@stageValue',
        datatableOptions: {
            page: 1,
            itemsPerPage: 8,
            sortBy: ['stageValue'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        // productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
        filter: {
            pipeline: [],
        },

        deleteDialog: false,
        deleteStage: '',
        newStage: {},
        stages: {},
    }),
    computed: {
        computedSearchQuery() {
            let searchQuery = this.searchQuery;

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:0';
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                }
            }


            // Pipeline Filter
            if (JSON.stringify(this.filter.pipeline) !== '[]' && this.filter.pipeline !== null) {
                // let pipeline = this.filter.pipeline.join(',');
                // pipeline = pipeline.replace(/,/g, '|');
                searchQuery = `${searchQuery}  @stagePipeline:{${this.filter.pipeline}}`;
            }
            // Page Filters
            return searchQuery;
        },
        // Computed Headers
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (this.listshrunk) {
                headers = headers.filter((header) => header.expanded === false || header.expanded === true); // Return Headers based on Expanded State
            } else {
                headers = headers.filter((header) => header.expanded === false); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedItems() {
            var t = this;
            var items = this.items; //.filter(x => x.);
            // ////console.log('items = ' + JSON.stringify(items, null, 2))

            // if (JSON.stringify(this.filter.pipeline) !== '[]' && this.filter.pipeline !== null) {
            //     // ////console.log("filter.pipeline = " + JSON.stringify(this.filter.pipeline, null, 2))
            //     items = items.filter((item) => t.filter.pipeline.includes(item.stagePipeline))
            // }

            return items; //.slice(this.organisationsFrom, this.organisationsTo);
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            if (!computedFields.includes('@orgId')) {
                computedFields.push('@orgId');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
    },
    methods: {
        async moveStages() {
            var t = this;

            var stageDeleting = t.deleteStage[0]
            var newStage = t.newStage

            if (stageDeleting.stagePipeline == 'Leads') {
                // Get the leads in the stage from the database 
                let itemResult = await this.MIX_redisSearch("idx:leads", `@deleted:0 @salesStageId:{${stageDeleting.id}}`, 0, 9999);
                if (itemResult.code === 1) {
                    var leads = itemResult.data.documents.map((x) => x.value);
                    
                    leads.forEach((lead) => {
                        lead.salesStageName = newStage.stageName
                        lead.salesStageId = newStage.id
                        t.MIX_createItem(lead, 'lead', false)
                    })

                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Getting Leads', show: true });
                }
            } else if (stageDeleting.stagePipeline === 'Opportunities') {
                // Get the opportunities in the stage from the database 
                let itemResult = await this.MIX_redisSearch("idx:opportunities", `@deleted:0 @salesStageId:{${stageDeleting.id}}`, 0, 9999);
                if (itemResult.code === 1) {
                    var opportunities = itemResult.data.documents.map((x) => x.value);
                    
                    opportunities.forEach((opportunity) => {
                        opportunity.salesStageName = newStage.stageName
                        opportunity.salesStageId = newStage.id
                        t.MIX_createItem(opportunity, 'opportunity', false)
                    })

                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Getting Opportunities', show: true });
                }
            } else if (stageDeleting.stagePipeline === 'Accounts') {
                // Get the opportunities in the stage from the database 
                let itemResult = await this.MIX_redisSearch("idx:orgs", `@deleted:0 @orgStageId:{${stageDeleting.id}}`, 0, 9999);
                if (itemResult.code === 1) {
                    var orgs = itemResult.data.documents.map((x) => x.value);
                    
                    orgs.forEach((org) => {
                        org.orgStageName = newStage.stageName
                        org.orgStageId = newStage.id
                        t.MIX_createItem(org, 'org', false)
                    })

                } else {
                    this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Getting Organisations', show: true });
                }
            }
            t.deleteItem(stageDeleting.id)
            t.closeDeleteDialog()

        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, "stage");
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data);
            } else {
                this.MIX_alertBox({ color: "error", timeout: 4000, message: "Error Editing Stage", show: true });
            }
            //alert(JSON.stringify(itemFullResult));
            // Emit the item to the parent component edit
        },
        refreshItems: function () {
            this.getItems();
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : '';
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        showDeleteDialog(itemId) {
            var t = this;
            t.deleteStage = t.items.filter((item) => {
                return item.id === itemId
            })
            // ////console.log("delete stage = " + JSON.stringify(t.deleteStage, null, 2))
            t.stages = t.items.filter((item) => {
                return item.stagePipeline == t.deleteStage[0].stagePipeline && item.id !== t.deleteStage[0].id
            })
            // ////console.log("stages = " + JSON.stringify(t.stages, null, 2))

            t.deleteDialog = true
            // let result = await this.MIX_deleteItem(itemId, this.key);
            // if (result.code === 1) {
            //     this.getItems();
            // }
        },
        // * DELETE ITEM FROM ITEMS
        closeDeleteDialog() {
            var t = this;
            t.deleteStage = {}
            t.deleteDialog = false
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
            //this.activeItem = item;
            // this.listexpanded = false;
        },
        // * GET ITEMS
        async getItems() {
            // // ////console.log('addressList.vue > getItems Running');
            // // ////console.log('index: ' + this.index + '\n computedSearchQuery: ' + this.computedSearchQuery + '\n itemsFrom: ' + this.itemsFrom + '\n datatableOptions.itemsPerPage: ' + this.datatableOptions.itemsPerPage + '\n computedFields: ' + this.computedFields + '\n sortBy: ' + this.sortBy);
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
            // // ////console.log('itemsResult: ' + JSON.stringify(itemsResult));
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            } else {
                //// ////console.log("items = " + JSON.stringify(itemsResult, null, 2));
            }
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.org = { ...this.datadown };
            },
            deep: true,
        },
        refreshitems() {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        computedFields() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            //// ////console.log('imagesIndex = ' + imagesIndex)
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                //// ////console.log('organisationFrom=' + this.organisationFrom)
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        this.org = { ...this.datadown };
        this.getItems();
    },
};
</script>