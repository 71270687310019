<template>
    <v-row fluid fill-height class="d-flex flex-column align-start appbackground" style="height: 100% !important" no-gutters>
        <!-- <v-col cols="12" style="flex: 0 1 10%"> -->
		<v-col class="align-center pa-3 mt-1" :cols="itemsCols.cols" :xs="itemsCols.xs" :sm="itemsCols.sm" :md="itemsCols.md" :lg="itemsCols.lg" :xl="itemsCols.xl">
			<div class="d-flex align-end mb-1">
				<div class="">
                    <div>
                        <v-btn text color="primary" class="pl-0"  @click="MIX_go('/events')" >
                            <v-icon>oomph-back</v-icon>
                            <span v-if="!listShrunk" class="">Return to Events</span>
                        </v-btn>
                    </div>
                    <div class="d-flex align-center">
                        <div><v-icon class="primary--text" x-large>oomph-schedule</v-icon></div>
                        <div class="text-left primary--text pl-1 text-h4 font-weight-thin " >Recurring Events</div>
                    </div>
				</div>
				<v-spacer></v-spacer>
				<div class="ml-1">
                    <v-btn depressed block class="primary white--text darken-2" @click="openItem({})" >
                        <v-icon>oomph-user-groups</v-icon>
                        <span v-if="!listShrunk" class="ml-3">New</span>
                    </v-btn>
                </div>
				<div>
					<v-btn class="white--text primary ml-2" @click="listShrunk = false" depressed v-if="listShrunk">
						<v-icon color="white">oomph-show-sidepanel</v-icon>
					</v-btn>
					<v-btn class="white--text primary ml-2" @click="listShrunk = true" depressed v-if="!listExpanded && !listShrunk">
						<v-icon color="white">oomph-hide-sidepanel</v-icon>
					</v-btn>
					<!-- TODO - HIGH - SECURITY - RG - Allow Import to DeveloperAdmin only -->
					<v-btn disabled class="primary--text ml-2" depressed v-if="!listShrunk">
						<v-icon color="primary">oomph-import-csv</v-icon>
					</v-btn>
					<!-- TODO - HIGH - SECURITY - RG - Allow Export to Staff-Directors only -->
					<v-btn disabled class="primary--text ml-2" depressed v-if="!listShrunk">
						<v-icon color="primary">oomph-export-csv</v-icon>
					</v-btn>
				</div>
			</div>
			<!-- Recurring List Component -->			
			<recurring-list :listshrunk="listShrunk" :listexpanded="listExpanded" v-on:openItem="openItem" :refreshitems="refreshItems"/>
			<v-col cols="12" class="pt-0">
				<!-- App Copyright -->
				<appCopyright :colour="'white'" />
			</v-col>
		</v-col>
		<!-- recurring Component -->
		<transition v-if="activeItem" name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" mode="out-in">
			<v-col style="border-left: #146a7b solid 1px !important" v-if="!listExpanded" class="grey lighten-3" cols="12" :xs="itemCols.xs" :sm="itemCols.sm" :md="itemCols.md" :lg="itemCols.lg" :xl="itemCols.xl">
				<recurring v-if="!listExpanded" v-on:refreshitems="refreshItemsMethod" :datadown="activeItem" v-on:closeitem="closeItem" :listShrunk="listShrunk" />
			</v-col>
		</transition>
	</v-row>
</template>

<script>
	// import taskBar from "@/views/tasks/taskbar.vue";
	import recurring from "@/views/recurring/RecurringEvent.vue";
    import recurringList from "@/views/recurring/RecurringList.vue";
	import { mapGetters } from "vuex";
	export default {
		// * COMPONENTS
		components: {
			'recurring': recurring,
            'recurring-list': recurringList,
			// 'taskbar': taskBar,
		},
		// * DATA
		data: () => ({
			listExpanded: true,
			listShrunk: false,
			list: "recurrings",
			itemsCols: { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 },
			itemCols: { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
			taskCols: { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
			refreshItems: 0,
			activeItem: {},
			routeId: ""
		}),
		// * COMPUTED
		computed: {
			...mapGetters({
				GET_taskBar: "GET_taskBar",
				GET_dense: "GET_dense",
				GET_destroy: "GET_destroy",
			}),
		},
		// * METHODS
		methods: {
			// Open Item
			openItem(item) {
				// console.log('recurrings.vue -> openItem')			
				// console.log('recurrings.vue -> openItem ' + item.id)	
				this.activeItem = item;
				this.listExpanded = false;
			},
			// Refresh Columns
			refreshCols() {
				switch (true) {
					// Expanded List with No Task Bar
					case this.listExpanded && !this.listShrunk && !this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }; // 12
						this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						break;
					// Expanded List with Task Bar
					case this.listExpanded && !this.listShrunk && this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 10, sm: 10, md: 10, lg: 10, xl: 10 }; // 10
						this.itemCols = { cols: 12, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						break;
					// List with Product Page (No Task Bar)
					case !this.listExpanded && !this.listShrunk && !this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
						this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						break;
					// List with Product Page and Task Bar
					case !this.listExpanded && !this.listShrunk && this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 4
						this.itemCols = { cols: 12, xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }; // 6
						this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						break;
					// Shrunk List with Product Page (No Task Bar)
					case !this.listExpanded && this.listShrunk && !this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }; // 2
						this.itemCols = { cols: 12, xs: 9, sm: 9, md: 9, lg: 9, xl: 9 }; // 10
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
						break;
					// Shrunk List with Product Page and Task Bar)
					case !this.listExpanded && this.listShrunk && this.GET_taskBar:
						this.itemsCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
						this.taskCols = { cols: 12, xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }; // 2
						break;
					default:
						this.itemsCols = { cols: 12, xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }; // 12
						this.itemCols = { cols: 12, xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }; // 8
						this.taskCols = { cols: 0, xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }; // 0
				}
			},
			// Refresh Items Method
			refreshItemsMethod() {
				// console.log('recurrings.vue -> refreshItemsMethod')
				this.refreshItems++;
			},
			closeItem() {
				this.listExpanded = true;
				this.listShrunk = false;
			},
		},
		// * WATCH
		watch: {
			listExpanded() { this.refreshCols() },
			listShrunk() { this.refreshCols() },
			GET_taskBar() { this.refreshCols() }
		},
		// * MOUNTED
		mounted() {
			this.refreshCols()
			// Dynamic Route for New or Existing Item
			this.routeId = this.$route.params.id;
			if (this.routeId === "new") {
				this.openItem({})
			} else if (this.routeId !== "new" && this.routeId !== "" && this.routeId !== undefined) {
				this.openItem({ id: this.routeId })
			} 
		},
	};
</script>
