<template>
    <div class="" style="width: 100% !important">
        <div class="mt-2 d-flex align-start">
            <div class="flex-grow-1">
                <v-text-field
                    label="Search Reviews "
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="oomph-search"
                    @click:clear="searchQuery = ''"
                ></v-text-field>
            </div>
            <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="ml-2 white grey--text text--darken-2" @click="refreshItems">
                <v-icon>oomph-available-updates</v-icon>
            </v-btn>
            <div class="ml-2">
                <v-menu offset-y class="white" style="z-index: 99999 !important; width: 200px !important; height: 200px !important" :close-on-content-click="false" origin="top right" left transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"><v-icon>oomph-table-properties</v-icon></v-btn>
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <div class="pa-5 white" style="width: 100% !important">
                            <div class="body-2 font-weight-bold">Show / hide Columns</div>
                            <v-checkbox v-model="headers[index].hidden" dense hide-details="auto" v-for="(item, index) in headers" :key="index" :label="item.text" :true-value="false" :false-value="true"></v-checkbox>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
        <!-- Content Status -->
        <v-row class="mb-4 px-3 align-center justify-start">
            <v-btn-toggle multiple v-model="filter.status" class="mt-4 appbackground d-flex align-center flex-wrap" @change="datatableOptions.page = 1">
                <v-btn value="Approved" active-class="info white--text" depressed small>Approved</v-btn>
                <v-btn value="Live" active-class="success white--text" depressed small>Live</v-btn>
                <v-btn value="Pending" active-class="warning white--text" depressed small>Pending</v-btn>
                <v-btn value="Rejected" active-class="error white--text" depressed small>Rejected</v-btn>
            </v-btn-toggle>
        </v-row>
        <v-row class="pa-3 mt-1">
            <!-- TABLE BASED LIST -->
            <v-data-table :item-class="itemRowBackground" sortable dense class="" style="width: 100%" :headers="computedHeaders" :items="computedItems" :server-items-length="itemsTotal" :options.sync="datatableOptions">
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="Permanently Delete" value="destroy" small>
                                <v-icon class="error--text">oomph-delete-trash</v-icon>
                            </v-btn>
                            <v-btn title="Delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                            <v-btn title="Undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:[`item.reviewStatus`]="{ item }">
                    <v-icon title="Approved" class="info--text ml-5" v-if="item.reviewStatus === 'Approved'">oomph-in-progress</v-icon>
                    <v-icon title="Live" class="success--text ml-5" v-if="item.reviewStatus === 'Live'">oomph-ok</v-icon>
                    <v-icon title="Pending" class="warning--text ml-5" v-if="item.reviewStatus === 'Pending'">oomph-box-important</v-icon>
                    <v-icon title="Rejected" class="error--text ml-5" v-if="item.reviewStatus === 'Rejected'">oomph-cancel</v-icon>
                </template>
                <template v-slot:[`item.reviewQ4A`]="{ item }">
                    <v-rating empty-icon="oomph-star" full-icon="oomph-star-2" length="5" size="20" background-color="grey" color="primary" class="mr-6" :value="parseInt(item.reviewQ4A)" readonly dense></v-rating>
                </template>
                <template v-slot:[`item.createdDateTime`]="{ item }">
                    {{ MIX_formatDateTime(item.createdDateTime, 'x', 'DD-MMM-YY HH:mm') }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">oomph-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon>
                </template>
                <template v-slot:[`item.approvereject`]="{ item }">
                    <v-btn @click="rejectReview(item)" class="my-1 ml-1" depressed><v-icon class="error--text">oomph-cancel</v-icon></v-btn>
                    <v-btn @click="approveReview(item)" class="my-1 ml-1" depressed><v-icon class="info--text">oomph-in-progress</v-icon></v-btn>
                    <v-btn @click="publishReview(item)" class="my-1 ml-1" depressed><v-icon class="success--text">oomph-ok</v-icon></v-btn>
                </template>
            </v-data-table>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        refreshitems: {
            type: Number,
            default: 0
        },
        datadown: {
            type: Object,
            default: () => ({})
        },
        listshrunk: {
            type: Boolean,
            default: false
        },
        listexpanded: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        type: '',
        status: '',
        key: 'review',
        index: 'idx:reviews',
        component: {},
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'Area', value: 'contentArea', sortable: false, hidden: false, expanded: false },
            { text: 'Title', value: 'contentTitle', sortable: false, hidden: false, expanded: false },
            { text: 'Review', value: 'reviewComment', sortable: false, hidden: false, expanded: false },
            { text: 'Rating', value: 'reviewQ4A', align: 'center', sortable: false, hidden: false, expanded: false },
            { text: 'Status', value: 'reviewStatus', align: 'center', sortable: false, hidden: false, expanded: false },

            { text: 'Date', value: 'createdDateTime', sortable: false, hidden: false, expanded: false },
            { text: 'Created Id', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Reviewer', value: 'createdUserName', sortable: false, hidden: false, expanded: false },
            { text: 'Created Email', value: 'createdUserEmail', sortable: false, hidden: true, expanded: false },
            { text: 'Modified Date', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Id', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Name', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Modified Email', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Date', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Id', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Name', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted Email', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: '', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false },
            { text: 'Reject / Approve / Publish', value: 'approvereject', width: '250px', align: 'end', sortable: false, hidden: false, expanded: false }
        ],
        // Fields to Retrieve from Database
        sortBy: '@reviewTitle',
        datatableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['reviewTitle'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        filter: {
            productCategories: [],
            status: []
        }
    }),
    computed: {
        computedSearchQuery() {
            let searchQuery = this.searchQuery

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                    searchQuery = '*'
                } else {
                    searchQuery = '@deleted:0'
                }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`
                    }
                }
            }
            if (JSON.stringify(this.filter.status) !== '[]') {
                let status = this.filter.status.join(',')
                status = status.replace(/,/g, '|')
                searchQuery = `${searchQuery}  @reviewStatus:{${status}}`
            }
            return searchQuery
        },
        computedHeaders() {
            let headers = this.headers
            headers = headers.filter((header) => !header.hidden) // Only return headers that are not hidden
            if (this.listshrunk) {
                headers = headers.filter((header) => header.expanded === false || header.expanded === true) // Return Headers based on Expanded State
            } else {
                headers = headers.filter((header) => header.expanded === false) // Return Headers based on Expanded State
            }
            return headers
        },
        computedItems() {
            var items = this.items
            if (this.type) {
                items = this.items.filter((item) => item.actionType === this.type)
            }
            if (this.status) {
                items = this.items.filter((item) => item.actionStatus === this.status)
            }
            return items
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value)
            if (!computedFields.includes('@id')) {
                computedFields.push('@id')
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted')
            }
            computedFields = computedFields.join(',')
            return computedFields
        },
        computedType() {
            const types = []
            this.items.filter((item) => {
                return types.push(item.actionType)
            })
            return types
        },
        computedStatus() {
            const status = []
            this.items.filter((item) => {
                return status.push(item.actionStatus)
            })
            return status
        }
    },
    methods: {
        async approveReview(item) {
            item.reviewStatus = 'Approved'
            let approveReview = await this.MIX_createItem(item, 'review', false)
            if (approveReview.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Review Approved', show: true })
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Approving Review', show: true })
            }
        },
        async rejectReview(item) {
            item.reviewStatus = 'Rejected'
            let rejectReview = await this.MIX_createItem(item, 'review', false)
            if (rejectReview.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Review Rejected', show: true })
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Rejecting Review', show: true })
            }
        },
        async publishReview(item) {
            item.reviewStatus = 'Live'
            let publishReview = await this.MIX_createItem(item, 'review', false)
            if (publishReview.code === 1) {
                this.MIX_alertBox({ color: 'success', timeout: 2000, message: 'Review Published', show: true })
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Publishing Review', show: true })
            }
        },
        async editItem(id) {
            // Get the item from the database
            let itemResult = await this.MIX_readItem(id, 'component')
            if (itemResult.code === 1) {
                this.$emit('edit', itemResult.data)
            } else {
                this.MIX_alertBox({ color: 'error', timeout: 4000, message: 'Error Editing Component', show: true })
            }
        },
        refreshItems: function () {
            this.getItems()
        },
        itemRowBackground: function (item) {
            return item.deleted === '1' ? 'purple lighten-5' : ''
        },
        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key)
            if (result.code === 1) {
                this.getItems()
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key)
            if (result.code === 1) {
                this.getItems()
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key)
            if (result.code === 1) {
                this.getItems()
            }
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)))
        },
        // * GET ITEMS
        async getItems() {
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false)
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total
                this.items = itemsResult.data.results
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo
                } else {
                    this.itemsTo = this.itemsTotal
                }
            }
            this.items.sort((a, b) => (b.createdDateTime > a.createdDateTime ? 1 : -1))
        }
    },
    watch: {
        datadown: {
            handler: function () {
                this.component = { ...this.datadown }
            },
            deep: true
        },
        refreshitems() {
            this.getItems()
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems()
        },
        computedFields() {
            this.getItems()
        },
        toggleDeletedItems() {
            this.getItems()
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage')
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false
            } else {
                this.headers[imagesIndex].hidden = true
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo
                } else {
                    this.itemsTo = this.itemsTotal
                }
                this.getItems()
            },
            deep: true
        }
    },
    async created() {
        this.component = { ...this.datadown }
        this.getItems()
    }
}
</script>
