<template>
    <div class="white" style="width: 100% !important" fluid>
        <CreateReport />
    </div>
</template>

<script>
import CreateReport from '@/components/pdf/createReport.vue';

export default {
    components: { CreateReport },
};
</script>