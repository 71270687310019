<template>
    <div>
        <div class="mt-2 d-flex align-center">
            <div class="flex-grow-1">
                <v-text-field
                    label="Search Calendar"
                    v-model="searchQuery"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                    prepend-inner-icon="oomph-search"
                    @click:clear="clearSearchQuery()"
                ></v-text-field>
            </div>
            <!-- Start Date -->
            <div v-if="!listshrunk && listExpanded && searchHistoric" class="ml-2">
                <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field label="Start Date" v-model="computedDate1" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" @click:clear="date1 = ''" clearable></v-text-field>
                    </template>
                    <v-date-picker v-model="date1" full-width no-title color="secondary" :first-day-of-week="1" @input="dateMenu1 = false"></v-date-picker>
                </v-menu>
            </div>
            <!-- End Date -->
            <div v-if="!listshrunk && listExpanded && searchHistoric" class="ml-2">
                <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field label="End Date" v-model="computedDate2" outlined dense hide-details="auto" background-color="white" color="primary" v-bind="attrs" v-on="on" @click:clear="date2 = ''" clearable></v-text-field>
                    </template>
                    <v-date-picker v-model="date2" full-width no-title color="secondary" :first-day-of-week="2" @input="dateMenu2 = false"></v-date-picker>
                </v-menu>
            </div>
            <div>
                <v-checkbox class="mx-3" v-model="searchHistoric" label="Search Previous?"></v-checkbox>
            </div>
            <!-- Filter Status -->
            <div v-if="!listshrunk && listExpanded" class="ml-2">
                <v-autocomplete
                    outlined
                    auto-select-first
                    v-model="filterStatus"
                    label="Filter by Status"
                    :search-input.sync="tagsAutocomplete"
                    @change="tagsAutocomplete = ''"
                    :items="computedStatus"
                    dense
                    hide-details="auto"
                    background-color="white"
                    color="primary"
                    clearable
                ></v-autocomplete>
            </div>
            <!-- Refresh Button -->
            <div v-if="!listshrunk" class="ml-2">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="refreshItems">
                    <v-icon>oomph-available-updates</v-icon>
                </v-btn>
            </div>
        </div>
        <div class="mt-2 d-flex align-center">
            <!-- Content Status -->
            <div class="my-2">
                <v-btn-toggle multiple v-model="filterType" class="appbackground d-flex align-center flex-wrap">
                    <v-btn value="Exercise Session" active-class="grey lighten-1 white--text" depressed small>
                        <v-icon size="20" color="#146a7b" class="oomph-filled-circle"></v-icon>
                        Exercise Session
                    </v-btn>
                    <v-btn value="Activity" active-class="grey lighten-1 white--text" depressed small>
                        <v-icon size="20" color="pink" class="oomph-filled-circle"></v-icon>
                        Activity
                    </v-btn>
                    <v-btn value="Event" active-class="grey lighten-1 white--text" depressed small>
                        <v-icon size="20" color="orange" class="oomph-filled-circle"></v-icon>
                        Event
                    </v-btn>
                    <v-btn value="" active-class="grey lighten-1 white--text" depressed small>
                        <v-icon size="20" color="grey" class="oomph-filled-circle"></v-icon>
                        No Type
                    </v-btn>
                </v-btn-toggle>
            </div>
            <!-- <div
                :class="listshrunk || !listExpanded ? 'flex-grow-1' : ''"
                style="border: 1px solid #9e9e9e !important; height: 40px !important; border-radius: 5px !important"
                class="d-flex align-center px-2 white grey--text text--darken-2"
            >
                <span class="grey--text text--darken-2 body-2 font-weight-medium">Key: </span>
                <v-icon size="20" color="#146a7b" class="oomph-filled-circle ml-2"></v-icon>
                <span class="grey--text caption font-weight-medium">Exercise Session</span>
                <v-icon size="20" color="pink" class="oomph-filled-circle ml-2"></v-icon>
                <span class="grey--text caption font-weight-medium">Activity</span>
                <v-icon size="20" color="orange" class="oomph-filled-circle ml-2"></v-icon>
                <span class="grey--text caption font-weight-medium">Event</span>
                <v-icon size="20" color="grey" class="oomph-filled-circle ml-2"></v-icon>
                <span class="grey--text caption font-weight-medium">No Type</span>
            </div> -->
            <!-- Refresh Button -->
            <v-spacer v-if="!listshrunk && listExpanded" />
            <div v-if="!searching && !showTable && !listshrunk && listExpanded && $refs.calendar" class="font-weight-medium text-h5 px-4 grey--text"> {{ $refs.calendar.title }} </div>
            <v-spacer />
            <div v-if="!searching && !showTable && !listshrunk && listExpanded" class="">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="$refs.calendar.prev()">
                    <v-icon>oomph-back</v-icon>
                </v-btn>
            </div>
            <div v-if="!searching && !showTable && !listshrunk && listExpanded" class="ml-2">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="$refs.calendar.next()">
                    <v-icon>oomph-forward</v-icon>
                </v-btn>
            </div>
            <!-- Column Selector -->
            <div v-if="!listshrunk" class="ml-2">
                <v-menu
                    offset-y
                    class="white"
                    style="z-index: 99999 !important; width: 200px !important; height: 200px !important"
                    :close-on-content-click="false"
                    origin="top right"
                    left
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" v-bind="attrs" v-on="on"
                            ><v-icon>oomph-calendar-week</v-icon></v-btn
                        >
                    </template>
                    <div class="white" style="width: 250px; max-height: 300px">
                        <v-list>
                            <v-list-item @click="type = 'day'">
                                <v-list-item-title :class="type === 'day' && !showTable ? 'primary--text' : ''">Day</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title :class="type === 'week' && !showTable ? 'primary--text' : ''">Week</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                                <v-list-item-title :class="type === 'month' && !showTable ? 'primary--text' : ''">Month</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = '4day'">
                                <v-list-item-title :class="type === '4day' && !showTable ? 'primary--text' : ''">4 days</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'table'">
                                <v-list-item-title>Table View</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-menu>
            </div>
        </div>
            <!-- <pre>{{ filterType }}</pre>
            <pre>{{ computedItems }}</pre> -->
        <div v-if="!listshrunk && !listExpanded" class="mt-2 d-flex align-center">
            <div v-if="!searching && !showTable" class="">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="$refs.calendar.prev()">
                    <v-icon>oomph-back</v-icon>
                </v-btn>
            </div>
            <v-spacer />
            <div v-if="!searching && !showTable && $refs.calendar" class="font-weight-medium text-h5 px-4 grey--text"> {{ $refs.calendar.title }} </div>
            <v-spacer />
            <div v-if="!searching && !showTable" class="ml-2">
                <v-btn depressed style="border: 1px solid grey !important; height: 40px !important" class="white grey--text text--darken-2" @click="$refs.calendar.next()">
                    <v-icon>oomph-forward</v-icon>
                </v-btn>
            </div>
        </div>
        <!-- <pre>{{ computedItems }}</pre> -->
        <div v-if="!searching && !showTable" class="my-2" style="height: 70vh !important">
            <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="computedItems"
                :event-color="getEventColor"
                :type="type"
                @click:event="openItemCalendar"
                @click:more="viewDay"
                @change="updateRange"
                :weekdays="weekdays"
            >
                <!-- <template v-slot:day-header="day">
                    Test {{ day }}
                </template> -->
            </v-calendar>
        </div>
        <div v-if="searching || showTable" class="my-2 mt-3" style="height: 700px !important">
            <!-- TABLE BASED LIST -->
            <v-data-table v-if="!listshrunk" sortable dense class="" :headers="computedHeaders" :items="computedItems"  :options.sync="datatableOptions">
                <template v-slot:top>
                    <div class="d-flex align-center" style="width: 100% !important">
                        <div class="py-5 pl-3 body-2">
                            Showing <strong>{{ itemsFrom + 1 }}</strong>
                            <span v-if="itemsTo !== itemsTotal">
                                to <strong>{{ itemsTo }}</strong></span
                            >
                            of <strong>{{ itemsTotal }}</strong>
                        </div>
                        <v-spacer />
                        <v-spacer />
                        <!-- <pre>Toggle Features: {{ toggleFeatures }}</pre> -->
                        <v-btn-toggle v-model="toggleFeatures" multiple class="ma-2">
                            <v-btn title="destroy" value="destroy" small>
                                <v-icon class="error--text">oomph-delete-trash</v-icon>
                            </v-btn>
                            <v-btn title="delete" value="delete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-can</v-icon>
                            </v-btn>
                            <v-btn title="undelete" value="undelete" small>
                                <v-icon class="grey--text text--darken-3">oomph-trash-restore</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </template>
                <template v-slot:item.eventDateOne="{ item }">
                    <span class="font-weight-medium text-h5 text--darken-2 grey--text">{{ $moment(item.start).format('D') }}</span>
                </template>
                <template v-slot:item.eventDateTwo="{ item }">
                    <span class="font-weight-medium body-2 text--darken-2 grey--text">{{ $moment(item.start).format('MMM YYYY, ddd') }}</span>
                </template>
                <template v-slot:item.eventTitle="{ item }">
                    <span class="font-weight-medium body-2 text--darken-2 grey--text">{{ item.name }}</span>
                </template>
                <template v-slot:item.eventType="{ item }">
                    <!-- <v-chip x-small v-if="item.type == 'Exercise Session'" color="#146a7b"></v-chip> -->
                    <v-row>
                        <v-icon v-if="item.type == 'Exercise Session'" size="20" color="#146a7b" class="oomph-filled-circle"></v-icon>
                        <v-icon v-else-if="item.type == 'Activity'" size="20" color="pink" class="oomph-filled-circle"></v-icon>
                        <v-icon v-else-if="item.type == 'Event'" size="20" color="orange" class="oomph-filled-circle"></v-icon>
                        <v-icon v-else size="20" color="grey" class="oomph-filled-circle"></v-icon>
                        <div v-if="item.timed === true" class="font-weight-medium body-2 grey--text pl-2">{{ $moment(item.start).format('HH:mm') }} - {{ $moment(item.end).format('HH:mm') }}</div>
                        <div v-else class="font-weight-medium body-2 grey--text pl-2">All Day</div>
                    </v-row>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-icon class="error--text pa-1" v-if="item.deleted === '1' && toggleFeatures.includes('destroy')" @click="destroyItem(item.id)">oomph-delete-trash</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="deleteItem(item.id)" v-if="item.deleted === '0' && toggleFeatures.includes('delete')">oomph-trash-can</v-icon>
                    <v-icon class="grey--text text--darken-2 ml-5" @click="restoreItem(item.id)" v-if="item.deleted === '1' && toggleFeatures.includes('undelete')">oomph-trash-restore</v-icon>
                    <v-icon class="primary--text ml-5" @click="openItem(item)">oomph-forward</v-icon>
                </template>
            </v-data-table>
            <!-- CARD BASED LIST -->
            <div v-if="listshrunk" class="">
                <v-card elevation="0" v-for="(item, index) in computedItems" :key="index" class="my-2 mx-0 py-2">
                    <v-row class="d-flex align-center ml-2">
                        <v-col cols="2" class="d-flex align-center">
                            <v-icon v-if="item.type == 'Exercise Session'" size="20" color="#146a7b" class="oomph-filled-circle"></v-icon>
                            <v-icon v-else-if="item.type == 'Activity'" size="20" color="pink" class="oomph-filled-circle"></v-icon>
                            <v-icon v-else-if="item.type == 'Event'" size="20" color="orange" class="oomph-filled-circle"></v-icon>
                            <v-icon v-else size="20" color="grey" class="oomph-filled-circle"></v-icon>
                            <span class="font-weight-normal align-center text-h3 text--darken-2 grey--text px-2">{{ $moment(item.start).format('D') }}</span>
                        </v-col>
                        <v-col cols="10" class="d-flex align-center">
                            <div class="pl-5">
                                <div class="pt-0 mt-0">
                                    <span class="pt-0 mt-0 font-weight-medium body-1 text--darken-2 grey--text">{{ item.name }}</span>
                                </div>
                                <div class="d-flex pb-0 mb-0">
                                    <span class="font-weight-medium body-2 text--darken-1 grey--text">{{ $moment(item.start).format('MMM YYYY') }}, </span>
                                    <div v-if="item.timed === true" class="font-weight-medium body-2 grey--text pl-2">
                                        {{ $moment(item.start).format('HH:mm') }} - {{ $moment(item.end).format('HH:mm') }}
                                    </div>
                                    <div v-else class="font-weight-medium body-2 grey--text pl-2">All Day</div>
                                </div>
                            </div>

                            <v-spacer></v-spacer>
                            <div class="text-right mr-3">
                                <v-icon class="primary--text" @click="openItem(item)">oomph-forward</v-icon>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
        </div>
        <v-col cols="12" class="pt-0">
            <!-- App Copyright -->
            <appCopyright :colour="'white'" />
        </v-col>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    name: 'Calendar',
    props: {
        refreshitems: {
            type: Number,
            default: 0,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        listExpanded: {
            type: Boolean,
            default: false,
        },
        orgType: {
            type: String,
            default: '',
        },
        orgid: {
            type: String,
            default: '',
        },
        recurringid: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        focus: '',
        type: 'month',
        typeToLabel: {
            month: 'Month',
            week: 'Week',
            day: 'Day',
            '4day': '4 Days',
        },
        weekdays: [1, 2, 3, 4, 5, 6, 0],
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],

        searching: false,
        showTable: false,
        searchHistoric: false,

		date1: '',
		dateMenu1: false,
		date2: '',
		dateMenu2: false,

        key: 'event',
        index: 'idx:events',
        toggleDeletedItems: false,
        showImages: false,
        items: [],
        itemsTotal: null,
        searchQuery: '',
        alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        itemsFrom: 0,
        itemsTo: 9,
        // activeItem: {},
        // Toggles
        toggleFeatures: [],
        // Headers to display in the Data Table
        headers: [
            { text: 'ID', value: 'id', sortable: false, hidden: false, expanded: true },
            { text: 'Date', value: 'eventDateOne', align: 'center', sortable: false, hidden: false, expanded: false, width: '30px' },
            { text: '', value: 'eventDateTwo', sortable: false, hidden: false, expanded: false, width: '190px' },
            { text: 'Time', value: 'eventType', sortable: false, hidden: false, expanded: false, width: '170px' },
            { text: 'Title', value: 'eventTitle', sortable: false, hidden: false, expanded: false },
            { text: 'Description', value: 'eventDescription', sortable: false, hidden: false, expanded: true },
            { text: 'Start Date', value: 'eventStartDateTime', sortable: false, hidden: false, expanded: true },
            { text: 'End Date', value: 'eventEndDateTime', sortable: false, hidden: false, expanded: true },
            { text: 'All Day', value: 'eventAllDay', sortable: false, hidden: false, expanded: true },
            { text: 'Status', value: 'eventStatus', sortable: false, hidden: false, expanded: false },
            { text: 'Live Stream', value: 'eventLiveStream', sortable: true, hidden: false, expanded: true },
            { text: 'OrgId', value: 'orgId', sortable: false, hidden: true, expanded: true },
            { text: 'Organisation Name', value: 'orgName', sortable: false, hidden: false, expanded: false },
            { text: 'Partner Logo', value: 'eventShowPartnerLogo', sortable: false, hidden: true, expanded: true },
            { text: 'Partner Logo Id', value: 'eventPartnerImageId', sortable: false, hidden: true, expanded: true },
            { text: 'Content Group', value: 'eventContentGroups', sortable: false, hidden: true, expanded: true },
            { text: 'Residents', value: 'eventResidentTags', sortable: false, hidden: true, expanded: true },

            { text: 'Date Time (C)', value: 'createdDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'User ID (C)', value: 'createdUserId', sortable: false, hidden: true, expanded: true },
            { text: 'User Name (C)', value: 'createdUserName', sortable: false, hidden: true, expanded: true },
            { text: 'User Email (C)', value: 'createdUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Date Time (M)', value: 'modifiedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'User ID (M)', value: 'modifiedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'User Name (M)', value: 'modifiedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'User Email (M)', value: 'modifiedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Deleted', value: 'deleted', sortable: false, hidden: true, expanded: false },
            { text: 'Date Time (D)', value: 'deletedDateTime', sortable: false, hidden: true, expanded: true },
            { text: 'User ID (D)', value: 'deletedUserId', sortable: false, hidden: true, expanded: true },
            { text: 'User Name (D)', value: 'deletedUserName', sortable: false, hidden: true, expanded: true },
            { text: 'User Email (D)', value: 'deletedUserEmail', sortable: false, hidden: true, expanded: true },
            { text: 'Action', value: 'action', align: 'end', sortable: false, hidden: false, expanded: false, width: '155px' },
        ],
        // Fields to Retrieve from Database
        //fields: "@id,@productCode,@productName,@supplierName,@deleted,@productMainImage",
        sortBy: '@eventStartDateTime',
        datatableOptions: {
            page: 1,
            itemsPerPage: 15,
            sortBy: ['eventTitle'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },

        // productCategories: ['Chair', 'Table', 'Sofa', 'Bed', 'Wardrobe', 'Cabinet', 'Dresser', 'Other'],
        // Dropdowns
        jobLevels: [],
        jobDepartments: [],
        organisations: [],
        tags: [],
        filter: {
            productCategories: [],
            alpha: '',
            jobLevel: [],
            jobDepartment: [],
            orgId: '',
            tags: [],
            type: []
        },
        jobLevelAutocomplete: '',
        jobDepartmentAutocomplete: '',
        tagsAutocomplete: '',
        filterType: [],
        filterStatus: '',
    }),
    computed: {
        ...mapGetters({

        }),
        alphaOptions() {
            let alphaOptions = [];
            for (let i = 0; i < this.alphabet.length; i++) {
                alphaOptions.push(this.filter.alpha + this.alphabet[i] + '*');
            }
            return alphaOptions;
        },
		computedDate1() {
			return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
		},
		computedDate2() {
			return this.MIX_formatDateTime(this.date2, "YYYY-MM-DD", "DD-MMM-YYYY");
		},   
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            var today = this.$moment().startOf('day').format('X')
            var yearFromNow = this.$moment().startOf('day').add(1, 'years').format('X')
            var yearAgo = this.$moment().startOf('day').subtract(1, 'years').format('X')
            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                this.notSearching();
                if (this.toggleFeatures.includes('undelete')) {
                    searchQuery = '*';
                } else {
                    searchQuery = '@deleted:0';
                }
            } else {
                this.startSearching();
                if (this.searchHistoric == true) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}*  @deleted:0`;
                    }
                } else {
                    var today = this.$moment().startOf('day').format('X')
                    var yearFromNow = this.$moment().startOf('day').add(1, 'years').format('X')
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}* @eventStartDateTime:[${today} ${yearFromNow}]`;
                    } else {
                        searchQuery = `${searchQuery}* @eventStartDateTime:[${today} ${yearFromNow}] @deleted:0`;
                    }
                }
            }
            if (this.computedDate1 != '') {
                var date = this.MIX_formatDateTime(this.computedDate1, "DD-MMM-YYYY", "X");
                searchQuery = `${searchQuery} @eventStartDateTime:[${date} ${yearFromNow}]`
            } 
            if (this.computedDate2 != '') {
                var date2 = this.MIX_formatDateTime(this.computedDate2, "DD-MMM-YYYY", "X");
                searchQuery = `${searchQuery} @eventStartDateTime:[${yearAgo} ${date2}]`
            }

            // Status Filter
            if (JSON.stringify(this.filter.type) !== '[]') {
                let type = this.filter.type.join(',');
                type = type.replace(/,/g, '|');
                searchQuery = `${searchQuery}  @eventType:{${type}}`;
            }

            if (this.recurringid !== '') {
                searchQuery = `${searchQuery} @eventRecurringId:${this.recurringid}`;
            }
            console.log(searchQuery);

            return searchQuery;
        },
        computedItems() {
            var t = this;
            var items = this.items;
            // //console.log("items = " + JSON.stringify(items, null, 2))
            if (JSON.stringify(this.filterType) !== '[]') {
                items = this.items.filter((item) => this.filterType.includes(item.eventType));
            }
            if (this.filterStatus) {
                items = this.items.filter((item) => item.eventStatus === this.filterStatus);
            }
            items = items.filter(function (item) {
                // // //console.log(JSON.stringify(item, null, 2))
                if (item.eventAllDay === '0') {
                    item.timed = true;
                    item.start = t.$moment(item.eventStartDateTime, 'X').format('YYYY-MM-DD HH:mm');
                    item.end = t.$moment(item.eventEndDateTime, 'X').format('YYYY-MM-DD HH:mm');
                } else if (item.eventAllDay === '1') {
                    item.timed = false;
                    item.start = t.$moment(item.eventStartDateTime, 'X').format('YYYY-MM-DD');
                    item.end = t.$moment(item.eventEndDateTime, 'X').format('YYYY-MM-DD');
                }
                return item;
            });
            items = items.map((e) => {
                return {
                    id: e.id,
                    name: e.eventTitle,
                    start: e.start,
                    end: e.end,
                    timed: e.timed,
                    type: e.eventType,
                    eventStatus: e.eventStatus,
                    deleted: e.deleted
                };
            });
            return items;
        },
        computedHeaders() {
            let headers = this.headers;
            headers = headers.filter((header) => !header.hidden); // Only return headers that are not hidden
            if (!this.listexpanded) {
                headers = headers.filter((header) => header.expanded !== true); // Return Headers based on Expanded State
            }
            return headers;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes("@deleted")) {
                computedFields.push("@deleted");
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },
        computedTypes() {
            const types = [];
            this.items.filter((item) => {
                return types.push(item.eventType);
            });
            return types;
        },
        computedStatus() {
            const status = [];
            this.items.filter((item) => {
                if (item.eventStatus !== '') {
                    return status.push(item.eventStatus);
                }
            });
            return status;
        },
    },
    methods: {
        startSearching() {
            this.searching = true;
        },
        notSearching() {
            this.searching = false;
        },

        viewDay({ date }) {
            this.focus = date;
            this.type = 'day';
        },
        getEventColor(event) {
            var colour = '';
            if (event.type === 'Activity') {
                colour = 'pink';
            } else if (event.type === 'Event') {
                colour = 'orange';
            } else if (event.type === 'Exercise Session') {
                colour = '#146a7b';
            } else {
                colour = 'grey';
            }
            return colour;
        },
        setToday() {
            this.focus = '';
        },
        prev() {
            this.$refs.calendar.prev();
        },
        next() {
            this.$refs.calendar.next();
        },
        showEvent({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event;
                this.selectedElement = nativeEvent.target;
                requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
            };

            if (this.selectedOpen) {
                this.selectedOpen = false;
                requestAnimationFrame(() => requestAnimationFrame(() => open()));
            } else {
                open();
            }

            nativeEvent.stopPropagation();
        },
        updateRange({ start, end }) {
            const events = [];

            const min = new Date(`${start.date}T00:00:00`);
            const max = new Date(`${end.date}T23:59:59`);
            const days = (max.getTime() - min.getTime()) / 86400000;
            const eventCount = this.rnd(days, days + 20);

            for (let i = 0; i < eventCount; i++) {
                const allDay = this.rnd(0, 3) === 0;
                const firstTimestamp = this.rnd(min.getTime(), max.getTime());
                const first = new Date(firstTimestamp - (firstTimestamp % 900000));
                const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
                const second = new Date(first.getTime() + secondTimestamp);

                events.push({
                    name: this.names[this.rnd(0, this.names.length - 1)],
                    start: first,
                    end: second,
                    color: this.colors[this.rnd(0, this.colors.length - 1)],
                    timed: !allDay,
                });
            }
            // // //console.log("events = " + JSON.stringify(events, null, 2))

            this.events = events;
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a;
        },


        clearSearchQuery() {
            this.searchQuery = ''
        },


        // * DESTROY ITEM FROM ITEMS
        async destroyItem(itemId) {
            let result = await this.MIX_destroyItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * DELETE ITEM FROM ITEMS
        async deleteItem(itemId) {
            let result = await this.MIX_deleteItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * RESTORE ITEM FROM ITEMS
        async restoreItem(itemId) {
            let result = await this.MIX_restoreItem(itemId, this.key);
            if (result.code === 1) {
                this.getItems();
            }
        },
        // * OPEN ITEM CALENDAR
        openItemCalendar(event) {
            // // //console.log('calendar.vue -> openItem');

            // // //console.log("item = " + JSON.stringify(event, null, 2))
            var item = event.eventParsed.input;
            // // //console.log("item = " + JSON.stringify(item, null, 2))
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
            //this.activeItem = item;
            // this.listexpanded = false;
        },
        // * OPEN ITEM
        openItem(item) {
            this.$emit('openItem', JSON.parse(JSON.stringify(item)));
            //this.activeItem = item;
            // this.listexpanded = false;
        },
        // Refresh Items
        refreshItems() {
            this.getItems();
        },
        // * GET ITEMS
        async getItems() {
            // if (this.searchQuery.length !== 1) {
                let itemsResult = await this.MIX_redisAggregateSearch(this.index, this.computedSearchQuery, 0, 9999, this.computedFields, this.sortBy, false);
                if (itemsResult.code === 1) {
                    console.log("items = " + JSON.stringify(itemsResult, null, 2));
                    this.itemsTotal = itemsResult.data.total;
                    this.items = itemsResult.data.results;
                    let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                    if (itemsTo < this.itemsTotal) {
                        this.itemsTo = itemsTo;
                    } else {
                        this.itemsTo = this.itemsTotal;
                    }
                }
            // }
        },
        // * Clear Tag Filter
        clearFilter(key, value) {
            setTimeout(() => {
                this.filter[key] = value;
            }, 1);
        },
    },
    watch: {
        filter: {
            handler: function () {
                this.getItems();
            },
            deep: true,
        },
        listshrunk: function () {
            if (this.listshrunk) {
                this.showTable = true;
            } else {
                this.showTable = false;
            }
        },
        type: {
            handler: function () {
                if (this.type === 'table') {
                    this.showTable = true;
                } else {
                    this.showTable = false;
                }
            },
            deep: true,
        },
        searchHistoric: {
            handler: function () {
                this.getItems();
                if (this.searchHistoric == true) {
                    this.type = 'table';
                }
                if (this.searchHistoric == false) {
                    this.date1 = '';
                    this.date2 = ''
                }
            },
            deep: true,
        },
        computedDate1: function () {
            this.getItems();
        },
        computedDate2: function () {
            this.getItems();
        },
        refreshitems: function () {
            this.getItems();
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
        toggleDeletedItems() {
            this.getItems();
        },
        showImages() {
            let imagesIndex = this.headers.findIndex((x) => x.value === 'productMainImage');
            //// //console.log('imagesIndex = ' + imagesIndex)
            if (this.showImages) {
                this.headers[imagesIndex].hidden = false;
            } else {
                this.headers[imagesIndex].hidden = true;
            }
        },
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                //// //console.log('organisationFrom=' + this.organisationFrom)
                this.getItems();
            },
            deep: true,
        },
    },
    async created() {
        let t = this;
        // Get Organisations
        t.organisations = await t.MIX_organisations();
        // Job Levels
        let jobLevels = await t.MIX_redisReadSet('set:jobLevels', false);
        if (jobLevels.code === 1) {
            t.jobLevels = jobLevels.data;
        }
        // Departments
        let jobDepartments = await t.MIX_redisReadSet('set:jobDepartments', false);
        if (jobDepartments.code === 1) {
            t.jobDepartments = jobDepartments.data;
        }
        this.getItems();
    },
};
</script>

<style scoped>
div .theme--light .v-calendar-weekly__head-weekday {
    height: 70px !important;
    font-size: 16px !important;
    /* background-color: #146a7b !important */
}
</style>