<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>

                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="!editmode" @click="editmode = true"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editmode" @click="saveItem()"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <v-col v-if="!editmode && !loading" cols="12" class="d-flex pa-2 align-center">

                <!-- //* VIEW MODE -->
                <v-row style="width: 100% !important" no-gutters>
                    <!-- //* GENERAL DETAILS VIEW -->
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12" sm="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text--darken-2 text-h6 font-weight-light">Package Details</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Name:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.packageName }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Package Type:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.packageType }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Package Code:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.packageCode }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Package Status:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">{{ item.packageStatus }}</td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold body-2 white">Package Icon:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    <v-icon class="mr-2" :class="item.packageIcon"></v-icon>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* CONTACTS VIEW -->
                    <v-col cols="12" sm="12" md="6" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Finance</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-contact</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Billing Cycle:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.packageBillingCycle }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Package Cost:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.packageCost }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Package Sell:</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.packageSell }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Minimum Contract Term (Months):</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.packageMinTerm }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Minimum Notice Period (Months):</td>
                                                <td width="60%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.packageNoticePeriod }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* CUSTOMER DESCRIPTION VIEW -->
                    <v-col cols="12" sm="12" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Customer Description</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-hierarch</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <!-- <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Description:</td> -->
                                                <td width="100%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.packageCustomerDescription}}
                                                </td>
                                            </tr> 
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- //* INTERNAL DESCRIPTION VIEW -->
                    <v-col cols="12" sm="12" class="my-2 px-2">
                        <!-- VIEW VERSION -->
                        <v-row class="white rounded pa-3 fill-height" no-gutters>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <span class="text-left primary--text text--darken-2 text-h6 font-weight-light">Internal Description</span>
                                    <v-spacer />
                                    <v-icon medium class="grey--text text--darken-2">oomph-hierarch</v-icon>
                                </div>
                                <v-divider class="grey lighten-1 mb-1"></v-divider>
                            </v-col>
                            <v-col cols="12" class="" style="height: 100% !important">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <!-- <td width="40%" valign="top" class="pl-0 apptext--text font-weight-bold white">Description:</td> -->
                                                <td width="100%" valign="top" class="apptext--text font-weight-normal body-1 white pl-2">
                                                    {{ item.packageInternalDescription}}
                                                </td>
                                            </tr> 
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="editmode && !loading" cols="12" class="d-flex pa-4 pt-4 align-center">
                <v-form ref="form" lazy-validation>
                    <v-row style="width: 100% !important" no-gutters>
                        <!-- DETAILS EDIT -->
                        <!-- <v-col cols="6">
                            <pre class="caption">{{ item }}</pre>
                        </v-col> -->
                        <!-- <v-col cols="6">
                            <pre class="caption">{{ selectedPackageIcon }}</pre>
                        </v-col> -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field label="Package Name" v-model="item.packageName" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('packageName')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Package Category" v-model="item.packageCategory" :rules="rules.packageCategory" :items="packageCategories" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('packageCategory')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field :disabled="item.packageCode === ''" label="Package Code" v-model="item.packageCode" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('packageCode')"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Package Status" v-model="item.packageStatus" :rules="rules.packageStatus" :items="packageStatus" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('packageStatus')"></v-select>
                                </v-col>
                                <!-- <pre>{{ item.packageIcon }}</pre> -->
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Package Icon" v-model="selectedPackageIcon" :rules="rules.packageStatus" :items="icons" return-object required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('packageStatus')">
                                        <template v-slot:selection="data">
                                            <v-icon class="mr-2" :class="data.item.icon"></v-icon>
                                            <span class="grey--text text--darken-1">{{ data.item.name }}</span>
                                        </template>
                                        <template v-slot:item="data">
                                            <v-icon class="mr-2" :class="data.item.icon"></v-icon>
                                            <span class="grey--text text--darken-1">{{ data.item.name }}</span>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Billing Cycle" v-model="item.packageBillingCycle" :rules="rules.packageBillingCycle" :items="billingCycles" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('packageBillingCycle')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="3">
                                    <div><v-text-field label="Package Cost" prefix="£" v-model="item.packageCost" type="number" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('packageCost')"></v-text-field></div>
                                    <div class="ml-1 mt-1 body-2 grey--text">
                                        What does this package cost for Oomph to deliver
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" md="3">
                                    <v-text-field label="Package Sell (Rack Rate)" prefix="£" v-model="item.packageSell" type="number" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('packageSell')"></v-text-field>
                                    <div class="ml-1 mt-1 body-2 grey--text">
                                        What does this package cost to Oomph Customers individually
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" md="3">
                                    <v-text-field label="Managers Discount" suffix="%" v-model="item.packageManagersDiscount" type="number" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('packageManagersDiscount')"></v-text-field>
                                    <div class="ml-1 mt-1 body-2 grey--text">
                                        Maxmium Discount Managers can approve
                                    </div>
                                </v-col>

                                <v-col cols="12" xs="12" md="3">
                                    <v-text-field label="Directors Discount" suffix="%" v-model="item.packageDirectorsDiscount" type="number" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('packageManagersDiscount')"></v-text-field>
                                    <div class="ml-1 mt-1 body-2 grey--text">
                                        Maxmium Discount Directors can approve
                                    </div>
                                </v-col>

                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Minimum Contract Term (Months)" v-model="item.packageMinTerm" :rules="rules.packageMinTerm" :items="months" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('packageMinTerm')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select label="Minimum Notice Period (Months)" v-model="item.packageNoticePeriod" :rules="rules.packageNoticePeriod" :items="months" required outlined dense hide-details="auto" background-color="white" color="primary" clearable :menu-props="{ top: false, offsetY: true }" :attach="true" @click:clear="clearItem('packageNoticePeriod')"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" md="12">
                                    <div class="ml-1 mt-1 body-2 grey--text">
                                        Billing Cycle, Min Contract Term and Notice Period can be changed at a Contract level, these are just the default values. Authorisation may be required to change these values.
                                    </div>
                                </v-col>



                                <v-col cols="12" class="pt-2 d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Customer Description</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" class="my-1">
                                    <v-textarea label="Customer Description" :rules="packageCustomerDescriptionRules" counter=280  rows="2" background-color="white" v-model="item.packageCustomerDescription"  outlined dense color="primary" hide-details="auto" clearable @click:clear="clearItem('packageCustomerDescription')"></v-textarea>
                                </v-col>
                                <!-- <v-col cols="12" xs="12">
                                    <v-text-field label="Customer Description" v-model="item.packageCustomerDescription" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearItem('packageCustomerDescription')"></v-text-field>
                                </v-col> -->
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Internal Description</div>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <v-col cols="12" class="my-1">
                                    <v-textarea label="Internal Description" :rules="packageInternalDescriptionRules" counter=280  rows="2" background-color="white" v-model="item.packageInternalDescription"  outlined dense color="primary" hide-details="auto" clearable @click:clear="clearItem('packageInternalDescription')"></v-textarea>
                                </v-col>

                            </v-row>



                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </div>  
</template>

<script>
// TODO - RG - Add Paging to the results
import schema from '@/schema.js';

export default {
    props: {
        datadown: {
            Type: String,
            Default: '',
        },
        // activeitem: {
        //     Type: Object,
        //     Default: null,
        // },
    },
    // * DATA
    data: () => ({
        key: 'action',
        itemNew: true,
        schema: {},
        org: {},
        editmode: false,
        // dropdowns
        packageTypes: [],
        billingCycles: [],
        packageStatus: [],
        selectedPackageIcon: {
            name: '',
            icon: ''
        },  
        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],

        // rules
        packageCustomerDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],
        packageInternalDescriptionRules: [ v => v.length <= 280 || 'Max 280 characters'],

        icons: [
            {
                name: 'On Demand',
                icon: 'oomph-video-playlist'
            },
            {
                name: 'Training',
                icon: 'oomph-literature'
            },
            {
                name: 'Exercises',
                icon: 'oomph-dumbbell'
            },
            {
                name: 'Activities',
                icon: 'oomph-list'
            },
            {
                name: 'Assessment',
                icon: 'oomph-clipboard-list'
            },
            {
                name: 'Wellbeing',
                icon: 'oomph-lotus'
            }
        ],

        // new address object
        item: {},
        loading: true,
        rules: {},

    }),
    computed: {  
    },
    methods: {
        initialise() {
            this.item = { ...this.datadown };
            //console.log('item', JSON.stringify(this.item, null, 2));
            let iconResult = this.icons.find(icon => icon.icon === this.item.packageIcon);
            if (iconResult !== undefined) {
                this.selectedPackageIcon = iconResult;
            }
            if (this.item.packageName !== '') {
                this.itemNew = false;
                this.editmode = false;
            } else {
                this.itemNew = true;
                this.editmode = true;
            }
        },
        updateItem() {
            this.$emit('update', this.item);
        },
        // Clear Item Property
        clearItem(property) {
            setTimeout(() => {
                this.item[property] = '';
            }, 1);
        },
        // * CREATE ITEM
        async saveItem() {
            this.$emit('save', this.item);
            this.editmode = false;
        },
        // Cancel Item
        cancelItem() {
            this.item = this.schema;
            this.$emit('close');
        },
        // Close Item
        closeItem() {
            this.item = this.schema;
            this.$emit('close');
        },
    },
    watch: {
        datadown: {
            handler: function () {
                this.initialise();
            },
            deep: true,
        },
        // activeitem: {
        //     handler: function () {
        //         let t = this;
        //         t.item = { ...t.activeitem };
        //     }, deep: true
        // },
        selectedPackageIcon: {
            handler: function () {
                this.item.packageIcon = this.selectedPackageIcon.icon;
            }, deep: true
        },

    },
    // * CREATED
    async created() {
        let t = this;
        t.schema = schema.package;
        t.loading = true
        t.initialise();


        let packageCategories = await t.MIX_redisReadSet('set:packageCategory', false);
        if (packageCategories.code === 1) {
            t.packageCategories = packageCategories.data;
        }
        let packageStatus = await t.MIX_redisReadSet('set:packageStatus', false);
        if (packageStatus.code === 1) {
            t.packageStatus = packageStatus.data;
        }
        let billingCycles = await t.MIX_redisReadSet('set:billingCycles', false);
        if (billingCycles.code === 1) {
            t.billingCycles = billingCycles.data;
        }
        t.loading = false
    },
    mounted() {},
};
</script>