<template>
    <div class="" style="padding: 0px !important; margin: 0px !important" >
        <v-row class="apptext--text" dense style="z-index: 0 !important">
            <!-- Main Content -->
            <v-col  cols="12" class=" align-start rounded">
                <v-row dense class="d-flex align-end">
                    <v-spacer />
                    <v-btn text @click="MIX_go('/sales/Opportunities')" class="primary--text font-weight-bold elevation-0">Go To Opportunities <v-icon>oomph-forward</v-icon></v-btn>
                </v-row>
                <!-- <pre>{{ datadown }}</pre> -->
                <!-- <div v-if="items.length > 0" class="grey--text pl-3 align-center d-flex">
                    Here are all other Opportunities linked with this Customer
                </div> -->
                <v-row v-if="computedItems.length > 0" class="pa-6 pt-0 pb-2">
                    <div class="flex-grow-1">
                        <v-text-field
                            label="Search Opportunities"
                            v-model="searchQuery"
                            outlined
                            dense
                            hide-details="auto"
                            background-color="white"
                            color="primary"
                            clearable
                            prepend-inner-icon="oomph-search"
                            @click:clear="searchQuery = ''"
                        ></v-text-field>
                    </div>
                </v-row>
                <!-- //* VIEW MODE -->
                <v-row class="pt-3" style="width: 100% !important" no-gutters>
                    <v-col cols="12">
                        <v-card v-if="items.length > 0" 
                            class="elevation-0 grey lighten-4 px-3 pb-3" >
                            <!-- style="height: 80vh !important; overflow-y: scroll !important "> -->
                            <div v-for="(item, i) in computedItems" :key="i">
                                <lead-card  class="mb-2" style="" :actionLead="true"
                                    v-on:openopportunityitem="openItem" :datadown="item"></lead-card>
                            </div>
                        </v-card>
                        <div class="grey--text d-flex font-weight-bold text-h5 align-center justify-center" v-if="computedItems.length == 0">
                            NO OPPORTUNITIES
                        </div>
                    </v-col>
                </v-row>
            </v-col>

            <v-dialog v-if="activeOpportunity" v-model="opportunityDialog" width="1366px" style="overflow: hidden !important;" persistent>
                <Opportunity v-on:refreshItems="refreshitems" :key="dialogOpportunityKey"  :datadown="activeOpportunity" v-on:closeitem="closeOpportunity" :orgtype="orgtype"/>
            </v-dialog>
            
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import companiesHouse from '@/views/organisations/CompaniesHouse.vue';
import leadCard from '@/views/sales/OpportunityCard.vue'
import Opportunity from '@/views/sales/Opportunity.vue';
export default {
    name: 'OrganisationDetails',
    components: {
        'companies-house': companiesHouse,
        'leadCard': leadCard,
        'Opportunity': Opportunity,
    },
    props: {
        datadown: {
            type: Object,
			default: function () {
				return {};
			},
        },
        dense: {
            type: Boolean,
            default: false,
        },
        itemnew: {
            type: Boolean,
            default: false,
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
        orgtype: {
            type: String,
            default: '',
        },
        orgIsGroup: {
            type: Boolean, 
            default: false,
        },
        organisations: {
            type: Array, 
            default: () => [],
        },
    },
    // * DATA
    data: () => ({
        // Item
        actions: {},
        items: {},
        key: 'opportunity',
        index: 'idx:opportunities',
        items: [],
        itemsTotal: null,
        searchQuery: '',
        itemsFrom: 0,
        itemsTo: 9,
        refreshItems: 0,
        datatableOptions: {
            page: 1,
            itemsPerPage: 9999,
            sortBy: ['salesTitle'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        sortBy: '@salesTitle',
        dialogOpportunityKey: 0,

        stages: [],
        stage: [],
        activeOpportunity: {},
        opportunityDialog: false,

        // Headers to display in the Data Table
		headers: [
			{ text: "ID", value: "id", sortable: false, hidden: true, expandable: true },
			{ text: "Opportunity Title", value: "salesTitle", sortable: false, hidden: false, expandable: true },
			{ text: "Opportunity Description", value: "salesDescription", sortable: true, hidden: false, expandable: true },
			{ text: "Opportunity Pipeline", value: "salesPipeline", sortable: false, hidden: false, expandable: true },
			{ text: "Opportunity Packages", value: "salesPackages", sortable: false, hidden: false, expandable: true },

			{ text: "Opportunity Type", value: "salesType", sortable: false, hidden: false, expandable: false },
			{ text: "Opportunity Stage Id", value: "salesStageId", sortable: false, hidden: false, expandable: false },
			{ text: "Opportunity Stage Name", value: "salesStageName", sortable: false, hidden: false, expandable: false },
			
            { text: "Opportunity Status", value: "salesStatus", sortable: false, hidden: false, expandable: true },
			{ text: "Opportunity Probability", value: "salesProbability", sortable: false, hidden: false, expandable: false },
			{ text: "Opportunity Value", value: "salesValue", sortable: false, hidden: false, expandable: false },
			{ text: "Opportunity Estimated Close Date", value: "salesEstimatedCloseDate", sortable: false, hidden: false, expandable: false },
			{ text: "Opportunity Actual Close Date", value: "salesActualCloseDate", sortable: false, hidden: false, expandable: false },

			{ text: "Opportunity Source", value: "salesSource", sortable: false, hidden: false, expandable: false },
			{ text: "Opportunity Source Name", value: "salesSourceName", sortable: false, hidden: true, expandable: false },
			{ text: "Opportunity Organisation", value: "orgName", sortable: false, hidden: false, expandable: false },
			{ text: "Opportunity Organisation Id", value: "orgId", sortable: false, hidden: false, expandable: false },
			{ text: "Opportunity Contact", value: "contactName", sortable: false, hidden: true, expandable: false },

			{ text: "Owner Time (C)", value: "ownerDateTime", sortable: false, hidden: true, expandable: false },
			{ text: "Owner ID (C)", value: "ownerUserId", sortable: false, hidden: false, expandable: false },
			{ text: "Owner Name (C)", value: "ownerUserName", sortable: false, hidden: false, expandable: false },

			{ text: "Date Time (C)", value: "createdDateTime", sortable: false, hidden: false, expandable: false },
			{ text: "User ID (C)", value: "createdUserId", sortable: false, hidden: true, expandable: false },
			{ text: "User Name (C)", value: "createdUserName", sortable: false, hidden: true, expandable: false },
			{ text: "User Email (C)", value: "createdUserEmail", sortable: false, hidden: true, expandable: false },
			{ text: "Date Time (M)", value: "modifiedDateTime", sortable: false, hidden: true, expandable: false },
			{ text: "User ID (M)", value: "modifiedUserId", sortable: false, hidden: true, expandable: false },
			{ text: "User Name (M)", value: "modifiedUserName", sortable: false, hidden: true, expandable: false },
			{ text: "User Email (M)", value: "modifiedUserEmail", sortable: false, hidden: true, expandable: false },
			{ text: "Deleted", value: "deleted", sortable: false, hidden: true, expandable: false },
			{ text: "Date Time (D)", value: "deletedDateTime", sortable: false, hidden: true, expandable: false },
			{ text: "User ID (D)", value: "deletedUserId", sortable: false, hidden: true, expandable: false },
			{ text: "User Name (D)", value: "deletedUserName", sortable: false, hidden: true, expandable: false },
			{ text: "User Email (D)", value: "deletedUserEmail", sortable: false, hidden: true, expandable: false },
			{ text: "Action", value: "action", align: "end", sortable: false, hidden: false, expandable: false, width: "155px" },
		],

    }),
    computed: {
        ...mapGetters({
            GET_dense: 'GET_dense',
        }),
        inputCols() {
            if (this.listshrunk) {
                return '3';
            } else {
                return '6';
            }
        },
        computedMonths() {
            var t = this;


            const months = [];
            this.items.filter((item) => {
                var month = []
                month = t.$moment(item.actionStartDate, 'X').format('MMM')
                if (month !== 'Invalid date') {
                    return months.push(month);
                }
            });
            let filteredMonths = [...new Set(months)];
            
            return filteredMonths
        },
        computedSearchQuery() {
            let searchQuery = this.searchQuery;
            var id = {...this.datadown}
            id = id.id
            var orgs = {}

            if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
                // if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                //     searchQuery = '*';
                // } else {
                    searchQuery = `@deleted:0`;
                // }
            } else {
                if (searchQuery.startsWith('@')) {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}* @deleted:0`;
                    }
                } else {
                    if (this.toggleFeatures.includes('undelete') || this.toggleFeatures.includes('destroy')) {
                        searchQuery = `${searchQuery}*`;
                    } else {
                        searchQuery = `${searchQuery}* @deleted:0`;
                    }
                }
            }

            // Group Level Filter
            if (this.orgIsGroup == true && this.organisations.length > 0) {
                orgs = this.organisations 
                orgs = orgs.map((y) => y.id).join("|")
                searchQuery = `${searchQuery} @orgId:{${orgs}}`;
            } else {
                searchQuery = `${searchQuery} @orgId:{${id}}` 
            }

            return searchQuery;
        },
        computedFields() {
            let computedFields = this.headers.filter((x) => x.hidden === false).map((y) => '@' + y.value);
            if (!computedFields.includes('@id')) {
                computedFields.push('@id');
            }
            if (!computedFields.includes('@deleted')) {
                computedFields.push('@deleted');
            }
            if (!computedFields.includes('@actionId')) {
                computedFields.push('@actionId');
            }
            computedFields = computedFields.join(',');
            return computedFields;
        },

        computedItems() {
            var t = this;

            var items = t.items

            items = items.filter((item) => {
                return item.orgId == this.datadown.id
            })

            return items
        },

        // computedItems() {
        //     var t = this;

        //     var items = t.items

        //     var id = {...this.datadown}
        //     id = id.id

        //     ////console.log(id)

        //     items = items.filter(function (item) {
        //         return item.
        //     })
        // }
    },
    // * METHODS
    methods: {
        refreshitems() {
            ////console.log("refreshing items")
            this.refreshItems++
        },
        refreshItems: function () {
            this.getItems();
        },

        // * OPEN ITEM
        openItem(item) {
            // // ////console.log("open item = " + JSON.stringify(item, null, 2))
            this.activeOpportunity = item;
            this.opportunityDialog = true
        },
        closeOpportunity() {
            this.activeOpportunity = {}
            this.opportunityDialog = false
            this.dialogOpportunityKey++
        },
        // * GET ITEMS
        async getItems() {
            let itemsResult = await this.MIX_redisAggregateSearch(this.index, '*', this.itemsFrom, this.datatableOptions.itemsPerPage, this.computedFields, this.sortBy, false);
            if (itemsResult.code === 1) {
                this.itemsTotal = itemsResult.data.total;
                this.items = itemsResult.data.results;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
            } else {
                //// ////console.log("items = " + JSON.stringify(itemsResult, null, 2));
            }
        },
    },
    // * WATCH
    watch: {
        datatableOptions: {
            handler() {
                this.itemsFrom = this.datatableOptions.page * this.datatableOptions.itemsPerPage - this.datatableOptions.itemsPerPage;
                let itemsTo = this.datatableOptions.page * this.datatableOptions.itemsPerPage;
                if (itemsTo < this.itemsTotal) {
                    this.itemsTo = itemsTo;
                } else {
                    this.itemsTo = this.itemsTotal;
                }
                this.getItems();
            },
            deep: true,
        },
        // If the search query changes then get updated dataset based on search query
        computedSearchQuery() {
            this.getItems();
        },
		datadown() {
            var t = this;
            // t.actions = { ...t.datadown };
            t.getItems()
            // // ////console.log("item customer = " + JSON.stringify(t.item, null, 2))
		},
        organisations() {
            var t = this;
            t.getItems()
        }
    },
    // * CREATED
    async created() {
        let t = this;
        t.actions = { ...t.datadown };
		this.getItems();

        let stages = await this.MIX_redisSearch('idx:stages', `*`, 0, 9999);
        stages = stages.data.documents.map((x) => x.value);
        // // ////console.log("stages = " + JSON.stringify(stages, null, 2));
        t.stages = stages.filter((item) => {
            return item.stagePipeline === 'Opportunities';
        })
        t.stages = t.stages.map((x) => {
            return {
                stageId: x.id,
                stageName: x.stageName,
                stageOutcome: x.stageOutcome
            };
        })

    },
};
</script>

<style>
/* .borderBottom {
    border-bottom: 5px solid #019EAF !important;
    border-color: #019EAF !important
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-bottom: 5px solid #019EAF !important;
    border-color: #019EAF !important
} */
</style>
