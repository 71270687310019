<template>
    <div class="" style="padding: 0px !important; margin: 0px !important; width: 100% !important" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(100vh - 150px) !important; overflow: scroll !important' : 'height: calc(100vh - 120px) !important; '">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey darken-1 py-4">
                <div class="ml-3 d-flex align-center" v-if="loading">
                    <v-progress-circular indeterminate color="white"></v-progress-circular>
                    <div class="ml-3 text-h6 white--text font-weight-light animate__animated animate__flash animate__infinite animate__slow">Loading</div>
                </div>
                <div class="" v-if="!loading">
                    <v-btn text class="grey lighten-2 grey--text text--darken-2 font-weight-bold ml-3" v-if="editMode" @click="cancelQuiz"><v-icon class="mr-2">oomph-multiply</v-icon>Cancel</v-btn>
                </div>
                <v-spacer />
                <div class="mr-3" :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''" v-if="!loading">
                    <v-btn text class="primary white--text" v-if="!editMode" @click="editMode = true"><v-icon class="mr-2">oomph-edit</v-icon>Edit</v-btn>
                    <v-btn text class="primary white--text" v-if="editMode" @click="validateReviewQuestions()"><v-icon class="mr-2">oomph-save</v-icon>Save</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="apptext--text px-3" dense style="z-index: 0 !important">
            <v-col v-if="!loading" cols="12" class="d-flex pt-4 align-center"  style="width: 100% !important">
                <v-form ref="form" lazy-validation style="width: 100% !important">
                    <v-row style="width: 100% !important" no-gutters class="">
                        <!-- DETAILS EDIT -->
                        <v-col cols="12" sm="12" class="mb-3">
                            <v-row dense>
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h6 font-weight-light">Details</div>
                                </v-col>
                                <!-- editMode: {{ editMode }}<br />
                                newreviewquestions: {{ newreviewquestions }}<br /> -->
                                <v-col cols="12" sm="12">
                                    <v-divider class="grey lighten-1 mb-1"></v-divider>
                                </v-col>
                                <!-- <v-col cols="12" xs="12" md="12">
                                    <v-text-field :disabled="!editMode" label="Name" v-model="reviewQuestions.reviewQuestionsTitle" :rules="rules.reviewQuestionsTitle" outlined dense hide-details="auto" background-color="white" color="primary" clearable @click:clear="clearQuiz('reviewQuestionsTitle')"></v-text-field>
                                </v-col> -->
                                <v-col cols="12" xs="12" md="12">
                                    <v-select :disabled="!editMode" label="Content Template" background-color="white" class="pr-1" v-model="reviewQuestions.reviewQuestionsTemplate" :items="computedTemplateNames" outlined dense color="primary" hide-details="auto" clearable @click:clear="clearContactName('contentTemplate', '')" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>

                                </v-col>                                
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col>
                reviewQuestions<br />
                <pre>{{ reviewQuestions }}</pre>
            </v-col>
        </v-row> -->
    </div>  
</template>

<script>
import schema from '@/schema.js';

export default {
    props: {
        reviewquestion: {
            Type: Object,
            Default: null
        },
        newreviewquestions: {
            Type: Boolean,
            Default: false
        }
    },
    // * DATA
    data: () => ({
        loading: true,
        schema: {},
        editMode: false,

        templates: [],

        // rules
        rules: {},

        // new reviewQuestions object
        reviewQuestions: {},
    }),
    // * COMPUTED
    computed: {  
        computedTemplateNames() {

            if (JSON.stringify(this.template) !== '[]' ) {
                let templates = this.templates.map((template) => template.name);
                templates.push('Standard')
                return templates
            } else {
                return [];
            }
        },
    },
    // * METHODS
    methods: {
        // Initialise Quiz
        initialiseReviewQuestions() {
            // //console.log('newreviewquestions = ', this.newreviewquestions);
            if (this.newreviewquestions === false) {
                this.reviewQuestions = this.reviewquestion; 
                this.editMode = false;
            } else {
                // //console.log('create from schema')
                this.reviewQuestions = this.schema;
                this.reviewQuestions.id = this.MIX_generateId();
                this.editMode = true;
            }
            this.loading = false;
        },
        // * Validate Quiz
		validateReviewQuestions() {
			this.rules = {
				// List all validation rules for form
                reviewQuestionsTitle: [(v) => !!v || "Quiz Title Required"],
			};
			this.$nextTick(() => {
				if (this.$refs.form.validate()) {
                    // //console.log('validateReviewQuestions')
                    // //console.log('this.reviewQuestions = ', JSON.stringify(this.reviewQuestions, null, 2))
                    // //console.log('newreviewquestions ' + this.newreviewquestions)
                    // alert('newreviewquestions = ' + this.newreviewquestions)
                    if (this.newreviewquestions) {                        
                        // remove spaces from template title
                        this.reviewQuestions.id = this.reviewQuestions.reviewQuestionsTemplate.replace(/\s/g, '');
                        this.reviewQuestions.reviewQuestionsTitle = this.reviewQuestions.reviewQuestionsTemplate;
                        // //console.log('reviewQuestions = ' + JSON.stringify(this.reviewQuestions, null, 2))
                        this.$emit('save', this.reviewQuestions); // save reviewQuestions
                    } else {
                        this.reviewQuestions.id = this.reviewQuestions.reviewQuestionsTemplate.replace(/\s/g, '');
                        this.reviewQuestions.reviewQuestionsTitle = this.reviewQuestions.reviewQuestionsTemplate;
                        // //console.log('update reviewQuestions')
                        this.$emit('update', this.reviewQuestions); // update reviewQuestions
                    }
					this.editMode = false;
				} else {
					this.MIX_alertBox({ color: "error", timeout: 2000, message: "Fill out all the fields correctly in order to proceed.", show: true });
				}
			});
        },
        // Clear Quiz Property
        clearQuiz(property) {
            setTimeout(() => {
                this.reviewQuestions[property] = '';
            }, 1);
        },
        // Cancel Quiz
        cancelQuiz() {
            this.$emit('cancel');
        },
        // Close Quiz
        closeQuiz() {
            this.reviewQuestions = this.schema;
            this.$emit('close');
        },
    },
    // * WATCH
    watch: {
        // Watch for Changes
        reviewquestion: {
            handler: function () {
                // //console.log('reviewquestion changed')
                this.initialiseReviewQuestions(); // Initialise Form
            }, deep: true
        },

    },
    // * CREATED
    async created() {
        this.schema = schema.reviewQuestions; // Load Schema
        // //console.log('reviewQuestions', JSON.stringify(this.schema, null, 2));
        this.loading = true; // Start Loading Spinner
         // Get Content Template - JSON
         let templatesResult = await this.MIX_redisReadJSON("settings:contentTemplates", ".");
            if (templatesResult.code === 1) {
               this.templates = templatesResult.data;
            }
        this.initialiseReviewQuestions(); // Initialise Form
    },
};
</script>