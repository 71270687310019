<template>
    <!-- <v-overlay v-model="addTextOverlay" opacity="0.8"> -->
        <v-card :key="cardKey">
            <v-toolbar class="pa-0 ma-0" color="primary" elevation="0">
                <div class="text-left white--text text-h5 pl-2 font-weight-light">{{ textEditMode === false ? 'Add Text Item' : 'Edit Text Item'}}</div>
                <v-spacer />
                <div>
                    <v-btn class="ml-2 white--text mr-0 pr-0" plain @click="closeOverlay()" depressed>
                        <v-icon>oomph-multiply</v-icon>
                    </v-btn>
                </div>
            </v-toolbar>
            <v-card-actions class="grey lighten-1 pa-4 pt-3 pr-4 d-flex justify-right align-center">
                <v-spacer />
                <div>
                    <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="closeOverlay"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                </div>
                <div class="ml-2">
                    <v-btn v-if="showPreview === true" class="primary font-weight-bold white--text" @click="showPreview = !showPreview" outlined depressed>
                        <div v-if="!showPreview">Show Text</div>
                        <div v-else-if="showPreview">Edit Text</div>
                    </v-btn>
                    <v-btn
                        depressed
                        v-if="showPreview === false"
                        class="primary font-weight-bold"
                        @click="
                            saveContentItem();
                        "
                    >
                        <v-icon size="30" class="white--text√">oomph-save</v-icon>
                        Save Content
                    </v-btn>
                <!-- <v-btn depressed v-else class="primary" @click="imageEditMode = false"><v-icon size="30" class="white--text" depressed>oomph-edit</v-icon>Edit Content</v-btn> -->
                </div>
            </v-card-actions>
            <!-- <pre>{{ textEditMode }}</pre> -->
            <v-card-text class="grey lighten-3 px-4">
                <!-- <v-card-text>Preview</v-card-text> -->
                <v-row class="py-2 pt-4 px-0" dense>
                    <v-col cols="12">
                        <form>
                            <v-row dense v-if="showPreview === false">
                                <v-col cols="12" class="d-flex align-center">
                                    <div class="text-left primary--text text-h7">Please use the form below to add a Text item:</div>
                                </v-col>
                                <v-col cols="12" sm="12" md="4" class="pb-0 mb-0">
                                    <v-text-field label="Name" class="primary--text" background-color="white" v-model="item.itemName" :menu-props="{ top: false, offsetY: true }" outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4" class="pb-0 mb-0">
                                    <v-select label="Marker" class="" background-color="white" v-model="item.itemMarker" :items="markers" item-text="name" :menu-props="{ top: false, offsetY: true }" outlined dense return-object></v-select>
                                </v-col>
                                <v-col v-if="showPreview === false" cols="12" sm="12" md="4" class="pb-0 mb-0">
                                    <v-combobox
                                        class=""
                                        small-chips
                                        hide-selected
                                        label="Content Source"
                                        v-model="item.contentSource"
                                        :search-input.sync="contentSourceAutocomplete"
                                        @change="
                                            contentSourceAutocomplete = '';
                                            updateContentSources(item.contentSource);
                                        "
                                        :items="contentSources"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        background-color="white"
                                        color="primary"
                                        :menu-props="{ top: false, offsetY: true }"
                                        :attach="true"
                                        clearable
                                    >
                                        <template v-slot:selection="{ attrs, item, selected }">
                                            <v-chip v-bind="attrs" class="grey lighten-3" :input-value="selected" label small>
                                                <span class="pr-2">
                                                    {{ item }}
                                                </span>
                                            </v-chip>
                                        </template>
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <span class="subheading">Create</span>
                                                <v-chip color="grey lighten-3" label small>
                                                    {{ contentSourceAutocomplete }}
                                                </v-chip>
                                            </v-list-item>
                                        </template>
                                    </v-combobox>
                                    <!-- <v-select label="Status" background-color="white" v-model="item.itemStatus" :items="statusItems" hide-details="auto" outlined :menu-props="{ top: false, offsetY: true }" dense></v-select> -->
                                </v-col>
                                <!-- <v-col cols="12" sm="12" md="4" class="pb-0 mb-0">
                                    <v-select label="Status" background-color="white" v-model="item.itemStatus" :items="statusItems" :menu-props="{ top: false, offsetY: true }" outlined dense></v-select>
                                </v-col> -->
                                <v-col cols="12">
                                    <vue-editor id="simpleEditor" style="max-height: 370px; min-height: 200px; overflow-y: scroll" v-model="item.itemTextHTML" :editorToolbar="customToolbar" class="white"></vue-editor>
                                </v-col>
                            </v-row>
                            <!-- <v-row dense v-if="showPreview === true">  
                                <v-col cols="12" class="d-flex align-end">
                                    <v-spacer />
                                    <v-btn class="primary white--text" @click="showPreview = !showPreview" outlined depressed>
                                        <div v-if="!showPreview">Show Image</div>
                                        <div v-else-if="showPreview">Edit Text</div>
                                    </v-btn>
                                </v-col>
                            </v-row> -->
                            <v-row dense v-if="showPreview === true">  
                                <v-col cols="12" style="height: 100% !important;">
                                    <v-simple-table class="white rounded pa-3 fill-height" dense>
                                        <template v-slot:default>
                                            <tbody>
                                                <tr>
                                                    <td width="30%" valign="top" class="pl-0 apptext--text font-weight-bold white">Name:</td>
                                                    <td width="70%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.itemName }}</td>
                                                </tr>
                                                <tr>
                                                    <td width="30%" valign="top" class="pl-0 apptext--text font-weight-bold white">Marker:</td>
                                                    <td width="70%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.itemMarker }}</td>
                                                </tr>
                                                <tr>
                                                    <td width="30%" valign="top" class="pl-0 apptext--text font-weight-bold white">Status:</td>
                                                    <td width="70%" valign="top" class="apptext--text font-weight-normal body-1 white">{{ item.itemStatus }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="showPreview === true">  
                                <v-col cols="12" class="d-flex align-end">
                                    <v-card height="400" width="100%" style="overflow-y: scroll; overflow-x: hidden" class="elevation-0 pa-3">
                                        <div v-html="item.itemTextHTML"></div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </form>
                    </v-col>
                </v-row>
                <!-- <v-col cols="12" class="d-flex" v-if="item.itemTextHTML">
                    <span v-html="item.itemTextHTML"></span>
                </v-col> -->
                <!-- <v-card-subtitle class="primary--text">Please use the form below to add a Text item:</v-card-subtitle> -->
                
            </v-card-text>
            <v-card-actions class="grey lighten-1 pa-3 pr-4 d-flex justify-right align-center">
                    <v-spacer />
                    <div>
                        <v-btn class="grey darken-1 white--text font-weight-bold" depressed @click="closeOverlay"> <v-icon class="mr-2">oomph-multiply</v-icon><span>Cancel</span> </v-btn>
                    </div>
                    <div class="ml-2">
                        <v-btn v-if="showPreview === true" class="primary font-weight-bold white--text" @click="showPreview = !showPreview" outlined depressed>
                            <div v-if="!showPreview">Show Text</div>
                            <div v-else-if="showPreview">Edit Text</div>
                        </v-btn>
                        <v-btn
                            depressed
                            v-if="showPreview === false"
                            class="primary font-weight-bold"
                            @click="
                                saveContentItem();
                            "
                        >
                            <v-icon size="30" class="white--text√">oomph-save</v-icon>
                            Save Content
                        </v-btn>
                    <!-- <v-btn depressed v-else class="primary" @click="imageEditMode = false"><v-icon size="30" class="white--text" depressed>oomph-edit</v-icon>Edit Content</v-btn> -->
                    </div>
            </v-card-actions>
        </v-card>
        <!-- <v-col cols="12" class="grey darken-1 rounded-bl rounded-br">
            <v-row>
                <v-col cols="12" class=" py-4 px-4 d-flex justify-end">
                    <v-btn depressed class="secondary mr-2" @click="closeOverlay"><v-icon size="30" class="white--text" depressed>oomph-multiply</v-icon>Cancel</v-btn>
                    <v-btn
                        depressed
                        v-if="!textEditMode"
                        class="primary"
                        @click="
                            textEditMode = false;
                            saveContentItem();
                        "
                        :disabled="item.itemName === '' || item.itemMarker === '' || item.itemStatus === ''"
                    >
                        <v-icon size="30" class="white--text">oomph-save</v-icon>
                        Save Content
                    </v-btn>
                    <v-btn depressed v-else class="primary" @click="textEditMode = false"><v-icon size="30" class="white--text" depressed>oomph-edit</v-icon>Edit Content</v-btn>
                </v-col>
            </v-row>
        </v-col> -->
    <!-- </v-overlay> -->
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
    name: 'AddEditTextOverlay',
    components: {
        VueEditor,
    },
    props: {
        addTextOverlay: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        markers: {
            type: Array,
            default: () => {
                return [];
            },
        },        
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
        originalContentId: {
            type: String,
            default: () => {
                return '';
            },
        },
        editMode: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        extendWidth: {
            type: Number,
            default: () => {
                return 400;
            },
        },
    },
    data: () => ({
        customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'align': ''},{ 'align': 'justify'}, { 'align': 'right' }],
            [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],     
            ['link'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean'],
        ],
        previewToolbar: [],
        showPreview: false,
        item: {
            id: '',
            key: '',
            itemContentId: '',
            itemPlaybackId: '',
            itemAssetId: '',
            itemName: '',
            itemMarker: '',
            itemStatus: 'Live',
            itemType: '',
            itemURL: '',
            itemFileSizeKb: '',
            itemFormat: '',
            itemWidth: '',
            itemHeight: '',
            itemLengthMins: '',
            itemEncodeStatus: '',
            itemTextHTML: '',
            itemUploadId: '',
            createdDateTime: '',
            createdUserId: '',
            createdUserName: '',
            createdUserEmail: '',
            modifiedDateTime: '',
            modifiedUserId: '',
            modifiedUserName: '',
            modifiedUserEmail: '',
            deletedDateTime: '',
            deletedUserId: '',
            deletedUserName: '',
            deletedUserEmail: '',
            deleted: '',
        },
        contentSourceAutocomplete: '',
        contentSources: [],
        cardKey: 0,
        panel: [0],
        markerItems: ['VIDEO', 'AUDIO', 'IMAGE', 'PDF', 'DOCUMENT', 'OTHER'],
        statusItems: ['Draft', 'Live', 'Deleted', 'Archived'],
        textEditMode: false,
    }),
    watch: {
        editMode() {
            let t = this;
            if (t.editMode === true) {
                // t.item = t.contentItem;
                // t.showPreview = true
                t.textEditMode = t.editMode;
            }
        },
        datadown: {
            handler: function () {
                let t = this;
                t.item = t.datadown;
                // t.showPreview = true
                t.textEditMode = true
            },
            deep: true,
        }
    },
    async created() {
        var t = this;
        let sources = await t.MIX_redisReadSet('set:contentSources', true);
        if (sources.code === 1) {
            t.contentSources = sources.data;
        }
        if (t.datadown.id !== null && t.datadown.id !== '' && t.datadown.id !== undefined) {
            if (this.datadown.contentSources !== '' && this.datadown.contentSources !== undefined && this.datadown.contentSources !== null) {
                this.contentSources = this.datadown.contentSources.split(',');
            }
            t.item = t.datadown;
            t.showPreview = true
            t.textEditMode = true
        }
    },
    methods: {
        // Update Event Groups
        async updateContentSources(group) {
            // console.log("### EventContentAddLink ### updateContentSources ### group", group);
            // If Tag does not exist in array then add it
            if (this.contentSources.indexOf(group) === -1) {
                
                this.contentSources.push(group);
            }
            // Remove any blank groups from array
            this.contentSources.forEach((item, index) => {
                if (item === '') {
                    this.contentSources.splice(index, 1);
                }
            });
            // // console.log('contentSources = ' + JSON.stringify(this.contentSources, null, 2))
            this.item.contentSources = this.contentSources.join(',');

            let addToSetResult = await this.MIX_redisAddToSet(`set:contentSources`, group);
            if (addToSetResult.code === -1) {
                // return;
            }

            // this.$emit('updateevent', this.event); // Parent - EventContentLinks.vue
        },
        async saveContentItem() {
            let t = this;
            t.item.key = 'contentItem';
            t.item.itemType = "Text";
            t.item.itemMarker = t.item.itemMarker.name;
            t.item.itemContentId = t.originalContentId;
            if (t.textEditMode === true) {
                t.itemNew = false;
                t.item.modifiedDateTime = t.$moment().format('x');
                let updateContentItemResult = await this.MIX_redisUpdateHash('contentItem:' + t.item.id, t.item);
                if (updateContentItemResult.code === 1) {
                    t.closeOverlay();
                    t.$emit('updateItems');
                    t.MIX_alertBox({ color: 'success', message: 'Created content item successfully', timeout: 4000, show: true });
                }
            } else {
                t.itemNew = true;
                t.item.id = t.MIX_generateId();
                t.item.createdDateTime = t.$moment().format('x');
                //* Saves file data to redis
                const update = await this.MIX_createItem(t.item, t.item.key, t.itemNew);
                if (update.code === 1) {
                    t.closeOverlay();
                    t.$emit('updateItems');
                    t.MIX_alertBox({ color: 'success', message: 'Created content item successfully', timeout: 4000, show: true });
                }
            }
        },
        closeOverlay() {
            let t = this;
            t.$emit('closeOverlay', false);
            t.cardKey++
        },
    },
};
</script>

<style>
p {
    margin: 0px !important
}
</style>