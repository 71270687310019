import { render, staticRenderFns } from "./Organisation.vue?vue&type=template&id=58f716f3&scoped=true&"
import script from "./Organisation.vue?vue&type=script&lang=js&"
export * from "./Organisation.vue?vue&type=script&lang=js&"
import style0 from "./Organisation.vue?vue&type=style&index=0&id=58f716f3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58f716f3",
  null
  
)

export default component.exports