<template>
    <div class="" style="width: 100% !important; padding: 0px !important; margin: 0px !important; height: calc(100vh - 150px) !important">
        <!-- Menu Buttons -->
        <v-row class="apptext--text mt-0" dense>
            <v-col cols="12" class="d-flex align-center justify-start grey lighten-1 py-4">
                <div class="mx-3 d-flex align-center justify-end" style="width: 100% !important">
                    <v-btn text class="primary white--text" @click="dialogAction = true"><v-icon class="mr-2">oomph-map</v-icon>Add Action</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row dense class="mx-2">
            <v-col cols="12">
                <action-list :org="org" v-on:openItem="openItem" :listshrunk="listshrunk" :refreshitems="refreshitems" />
            </v-col>
        </v-row>
        <v-dialog v-model="dialogAction" max-width="1024px" >
            <action-item :datadown="org" :activeitem="activeItem" :key="dialogActionKey" v-on:close="closeAction"/>
        </v-dialog>
    </div>
</template>

<script>
import action from "@/views/action/ActionItemPU.vue";
import actionList from "@/views/action/ActionList.vue";
export default {
    // * COMPONENTS
    components: {
        "action-item": action,
        "action-list": actionList
    },
    // * PROPS
    props: {
        datadown: {
            type: Object,
            default: () => {
                return {};
            },
        },
        listshrunk: {
            type: Boolean,
            default: false,
        },
    },
    // * DATA
    data: () => ({
        key: 'action',
        // toggles

        activeItem: {},

        // dialogs
        dialogAction: false,
        dialogActionKey: 0,

        org: {},

        // dropdowns
        actionTypes: [],

        refreshitems: 0

    }),
    // * COMPUTED
    computed: {
    },
    // * METHODS
    methods: {
        closeAction() { 
            this.activeItem = {};
            this.dialogActionKey++
            this.dialogAction = false; 
            this.refreshitems++
        },
        openItem(value) {
            this.activeItem = value;
            this.dialogAction = true;
        },
    },
    // * WATCH
    watch: {
        datadown: {
            handler: function () {
                this.org = { ...this.datadown };
            },
            deep: true,
        },
    },
    // * CREATED
    async created() {
        this.org = { ...this.datadown };
    },
};
</script>