<template>
    <v-app>
        <v-navigation-drawer app expand-on-hover :touchless="true" clipped permanent v-if="!$route.meta.hideNavbar" >
            <Navigation />
        </v-navigation-drawer>
        <v-app-bar app clipped-left clipped-right color="primary" height="50" elevation="0" v-if="!$route.meta.hideAppBar">
            <v-col cols="4"></v-col>
            <v-col cols="4">
                <v-img max-height="30" :src="require('@/assets/logo.png')" class="greyscale" contain></v-img>
            </v-col>
            <v-col cols="4" class="d-flex justify-end pa-0">
                <UserMenu />
            </v-col>
        </v-app-bar>
        <v-main :class="$route.meta.hideNavbar ? 'white':'appbackground'" :style="$route.path !== '/' && $route.path !== '/register' && $route.path !== '/reset' && $route.name !== 'ContractPrint' && $route.path !== '/reporting/print' ? 'padding-left: 56px !important' : ''">
            <div class="d-flex fill-height align-start pa-0" style="overflow-y: auto !important">
                <router-view :key="$route.fullPath" />
            </div>
        </v-main>
        <AppUpdate />
        <AlertBox />
    </v-app>
</template>

<script>
import Navigation from '@/components/navigation/Navigation.vue';
import UserMenu from '@/components/navigation/UserMenu.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'App',
    metaInfo: {
        title: 'Oomph Wellness Portal',
    },
    components: {
         Navigation,
         UserMenu,
    },
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
            GET_FIREBASE_userAccess: 'firebase_auth_store/GET_FIREBASE_userAccess',
            GET_appUpdate: 'GET_appUpdate',
        }),
        userLevel() { return this.GET_FIREBASE_userAccessToken.user_level },
        userGroup() { return this.GET_FIREBASE_userAccessToken.user_group },
        userStatus() { return this.GET_FIREBASE_userAccessToken.user_status },
        containerStyle() {
            if (this.$route.meta.hideNavbar) {
                return {
                    position: 'fixed !important',
                    scrolling: 'none !important',
                    maxWidth: 'calc(100vw - 55px) !important',
                };
            } else {
                return '';
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.appbackground {
    background: var(--v-primary-base);
}
.greyscale {
    filter: grayscale(1) !important;
}
.circle {
    z-index: 1 !important;
    position: absolute !important;
    text-align: center !important;
    top: 150px !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
    height: 70px !important;
    width: 70px !important;
    background: var(--v-primary-base) !important;
    border-radius: 50% !important;
}

</style>